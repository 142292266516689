import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/core/providers/data.service';

@Component({
  selector: 'app-reservation-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  @Input() reservation:any;
  @Input() todayAdmissions:any;
  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();

  objectKeys = Object.keys;
  allAdmissions
  

  constructor(public dataService:DataService) {

  }

  ngOnInit(): void {

  }


  convertToInt(a:string) {
    return parseInt(a);
  }

}
