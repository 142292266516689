import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DataService } from 'src/app/core/providers/data.service';
import { SettingsService } from 'src/app/core/providers/settings.service';
import { ModalExtraComponent } from '../modal-extra/modal-extra.component';

@Component({
  selector: 'app-reservation-recap',
  templateUrl: './recap.component.html',
  styleUrls: ['./recap.component.scss']
})
export class RecapComponent implements OnChanges {

  @Input() reservation:any;
  @Input() managerData:any;
  @Input() resortData:any;
  @Input() refundsAvailable:any;
  @Input() useResortRefund:boolean;
  @Input() mainCustomer:any;
  @Input() specialSeasonPrice:any;
  @Input() dailyPrice:any;
  @Input() settled:any;
  @Input() paymentType:any;
  @Input() optionalsByIds:any;
  @Output() updateCartEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateDailyPriceEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCustomerBillEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatePaymentTypeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateSpecialSeasonPrice: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateUseResortRefundEmitter: EventEmitter<any> = new EventEmitter<any>();
  

  coupons;
  objectKeys = Object.keys;

  constructor(public dialog: MatDialog, public dataService:DataService, public settingsService: SettingsService) {

  }

  ngOnChanges(): void {
    console.log(this.reservation.dates)
    console.log(this.useResortRefund)
    this.coupons = this.reservation.order.products.find(res => res.type === 'coupon');
  }

  checkIfBonus(label:string) {
    if (label.indexOf('bonus') > -1) {
      return true;
    }

    return false;
  }

  
  checkIfFirstBonus(id:string) {
    let filterBonus = this.reservation.discounts.filter((d:any)=>d.id && d.id.indexOf('bonus') > -1);

    if (filterBonus.length > 1) {
      if (filterBonus.findIndex((b:any) => b.id === id) > 0) {
        return false;
      }
    }

    return true;

  }

  getRefundTooltip() {
    if (this.useResortRefund) {
        return 'Elimina prima i buoni utilizzati';
    }
    return ''
  }

  updatePaymentType() {
    for (const bill of this.reservation.order.customerBill) {
      if (bill.type === 'settlement') {
        bill.paymentType = this.paymentType;
      }
    }

    this.updatePaymentTypeEmitter.emit(this.paymentType);
  }

  updateCart(reservation,fromReservedPrice) {
    this.updateUseResortRefundEmitter.emit(this.useResortRefund);
    this.updateSpecialSeasonPrice.emit(this.specialSeasonPrice);
    this.updateDailyPriceEmitter.emit(this.dailyPrice);
    console.log(this.useResortRefund);
    this.updateCartEmitter.emit({reservation,fromReservedPrice});
  }

  updateCustomerBill(reservation, settled) {
    this.updateCustomerBillEmitter.emit({reservation, settled});
  }

  updateForcedPrice() {
    if (this.reservation.order.forcedPrice) {
      this.reservation.order.reservedPrice = this.reservation.order.total;
    } else {
      this.reservation.order.reservedPrice = null;
      this.updateCart(this.reservation,false);
    }
  }


  removeDiscount(discount,index) {
    if (discount.id && discount.id.indexOf('bonus') > -1) {
      let filteredDiscounts = this.reservation.discounts.filter((d:any)=>d.id && d.id.indexOf('bonus') > -1);
      for (let refundDiscount of filteredDiscounts) {
        let refund = this.refundsAvailable.find((r:any)=>r.id === refundDiscount.id);
        if (refund) {
          refund.amount+= refundDiscount.amount;
        }
      }
      this.useResortRefund = false;
    }

    this.reservation.discounts.splice(index,1);
    this.updateCart(this.reservation,false);
  }

  removeExtra(extra,index) {
    this.reservation.extras.splice(index,1);
    this.updateCart(this.reservation,false);
  }

  updateExtra(extra, index) {
    if (extra.type !== 'reserved-extra') {
        
      let dialogRef = this.dialog.open(ModalExtraComponent, {
        width:'900px',
        data: {
          reservation:this.reservation,
          extra: extra
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        
        if (result) {
          this.reservation.extras[index] = result;
          console.log(this.reservation);
          this.updateCart(this.reservation,false);
        }
      });
    }
  }   
}
