import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/core/providers/data.service';

@Component({
  selector: 'app-reservation-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent implements OnInit {

  @Input() reservation:any;
  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dataService:DataService) {

  }

  ngOnInit(): void {

  }

}
