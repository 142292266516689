import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import it from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { DataService } from './core/providers/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent {
  title = 'coco-manager';
  data;
  online$: Observable<boolean>;
  isOnline = false;

  constructor( public dialog: MatDialog, private translate: TranslateService, private dataService:DataService) {
    translate.setDefaultLang('it');
    registerLocaleData( it );
  }

}
