import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/core/providers/data.service';

@Component({
  selector: 'app-availability-row',
  templateUrl: './availability-row.component.html',
  styleUrls: ['./availability-row.component.scss']
})
export class AvailabilityRowComponent implements OnInit {

  @Input() startTimestamp:number;
  @Input() endTimestamp:number;
  @Input() package:any;
  @Input() type:any;
  @Output() emitSelectedPackage: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitCheckAvailabilityEmitter: EventEmitter<any> = new EventEmitter<any>();

  selected:boolean = false;
  constructor(public dataService:DataService) { }

  ngOnInit() {
    this.selected = this.package.selected;
  }

  emitCheckAvailability(pack, zone:any, timeSlot) {
    let obj = {
      pack,
      zone,
      timeSlot
    }
    this.emitCheckAvailabilityEmitter.emit(obj);
  }

  emitCheckSelectedPackages(evt:any) {
    this.selected = evt.checked;
    this.emitSelectedPackage.emit(this.selected);
  }

}
