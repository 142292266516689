import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { importExpr } from '@angular/compiler/src/output/output_ast';
import { DataService } from 'src/app/core/providers/data.service';
import { AuthService } from 'src/app/core/providers/auth.service';

@Component({
  selector: 'app-quotation-license',
  templateUrl: './quotation-license.component.html',
  styleUrls: ['./quotation-license.component.scss']
})
export class QuotationLicenseComponent implements OnInit {

  public resortData:any;
  public licenseData: any;
  public licenseId:any;
  public resortId:any;
  public loading:boolean = false;
  public subtotal:number = 0;
  public total:number = 0;
  public vat:number = 0;
  public stripeCheckout:string = '';
  public error?:string = '';
  public amountToPay = 0;

  constructor( public snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router, public dataService:DataService, public authService:AuthService) { }

  async ngOnInit() {
    this.loading = true;
    this.licenseId = this.route.snapshot.paramMap.get('licenseId');
    this.resortId = this.route.snapshot.paramMap.get('resortId');
    this.error = this.route.snapshot.paramMap.get('error');
    console.log(this.licenseId);

    if (this.error) {
      this.openSnackBar("C'è stato un errore nel pagamento",'ok');
    }
    // GET LICENSE DATA
    this.licenseData = await this.dataService.getSingleSubscription(this.licenseId);

    console.log(this.licenseData);
    // GET RESORT DATA
    this.stripeCheckout = '';

    for (let item of this.licenseData.movements) {
      if (!item.paid) {
        this.total += item.amount;
        item.amountNoVat =  item.amount - Math.trunc(item.amount/122 * 22 *100)/100;
        this.vat += Math.trunc(item.amount/122 * 22 *100)/100;
        this.subtotal +=  item.amountNoVat;
        this.stripeCheckout = item.stripe.url;
      } else {
        this.total -= item.amount;
        this.vat -= Math.trunc(item.amount/122 * 22 *100)/100;
        this.subtotal -=  (item.amount - Math.trunc(item.amount/122 * 22 *100)/100);
      }
    }

    if (!this.dataService.resortData) {
      this.resortData =  await this.dataService.getResortData();
    } else {
      this.resortData = this.dataService.resortData;
    }

    this.loading = false;
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }


  pay() {
    this.router.navigate(['']);
  }


}
