import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges } from '@angular/core';
import { DataService } from '../../../core/providers/data.service';
import { AuthService } from '../../../core/providers/auth.service';
import { SettingsService } from '../../../core/providers/settings.service';
import { Subject } from 'rxjs/Subject';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

@Component({
  selector: 'booking-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() managerData;

  // GLOBAL VARIABLES
  showUserSubmenu = false; size = 100; showStatistics = false;

  // FILTERS VARIABLES
  term$ = new Subject<string>(); searchString = null; reservations; customers;

  constructor(public dataService: DataService, public authService: AuthService) {


  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

  }


}
