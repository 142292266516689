import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { StripeService } from '../../../core/providers/stripe.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ModalTransferComponent } from '../../../shared/components/modal-transfer/modal-transfer.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {


  resortData;managerData;pageClicked;prices;submenu;password;stripeData;managers; invoices;
  selectedPackage = null; selectedSettlement = null; selectedPaymentType = null;
  selectedOptionals = null; selectedStatus = null; onlyArrivals = null;
  selectedType = null; selectedToday = null; onlyDepartures = null; transfers= [];
  selectedState = null; selectedNoSlots = null; selectedZone = null;lang;
  selectedFilter = null; searchString = ''; selectedCabins = null; pageSize = 300;
  prevPageFlag = null; pages=[]; pendingTotal = 0; doneTotal = 0

  // DATE VARIABLES
  startTimestamp; endTimestamp;
  startDate; startDay; startMonth; startYear;
  endDate; endDay; endMonth; endYear;
  startSerializedDate; endSerializedDate;
  loader = false;
  balance;
  now = new Date().getTime();
  selectedMonth = new Date().getMonth();
  selectedYear = new Date().getFullYear();


  constructor(
    private http: HttpClient,
    private storage: AngularFireStorage,
    public snackBar: MatSnackBar, private settingsService: SettingsService, private route: ActivatedRoute, private dataService: DataService, private stripeService: StripeService, public dialog: MatDialog ) {


    // SETUP DATA
    this.lang = this.dataService.lang;

    this.submenu = 0;
    this.route.params.subscribe(params =>{
      if (params['menuIndex']) {
        this.submenu = params['menuIndex'];
      }
    });

    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.prices = this.dataService.prices;
  }

  async ngOnInit() {


    // SETUP DATES
    /* this.startTimestamp = (new Date(new Date().getFullYear(), 0, 1));
    this.endTimestamp = (new Date(new Date().getFullYear(), 11, 31));
    console.log(this.startTimestamp);
    const startDate = new Date(this.startTimestamp);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    this.endSerializedDate = endDate; */

    if (!this.resortData) {
      await this.dataService.initResortData();
    }


    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;

    console.log(this.resortData)
    console.log(this.managerData);

    //await this.getPayouts();
    await this.changeMonth();
    this.balance = await this.stripeService.getMerchantBalance(this.resortData.stripe.id);
    console.log(this.balance);
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    const startTS = (new Date(event.value)).getTime() - offset;
    const startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.endTimestamp = this.startTimestamp;
    }

    const endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);
    this.pages = [];

    await this.getPayouts();

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.loader = true;

    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now)) {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    const endTS = (new Date(event.value)).getTime() - offset;
    const endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.startTimestamp = this.endTimestamp;
    }

    const startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);
    this.pages = [];

    await this.getPayouts();
  }

  async setTodayDate() {
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0) ;
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startDate = startDate.getDate();

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endDate = endDate.getDate();

    this.startSerializedDate = new Date(this.startTimestamp);
    this.endSerializedDate = new Date(this.endTimestamp);
    this.pages = [];
    await this.getPayouts();

  }

  async changeMonth() {

    let monthDays = new Date(this.selectedYear, this.selectedMonth+1, 0).getDate();
    console.log(this.selectedMonth, monthDays);
    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(this.selectedYear,this.selectedMonth,1,0,0,0)).getTime() - offset;
    let endTS = (new Date(this.selectedYear,this.selectedMonth,monthDays,0,0,0)).getTime() - offset;

    this.startTimestamp = (new Date(startTS)).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date(endTS)).setUTCHours(0, 0, 0, 0);

    console.log(this.startTimestamp, this.endTimestamp);

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startDate = startDate.getDate();

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endDate = endDate.getDate();

    this.startSerializedDate = new Date(this.startTimestamp);
    this.endSerializedDate = new Date(this.endTimestamp);
    this.pages = [];
    await this.getPayouts();
  }

  async getPayouts() {
      this.loader = true;
      this.doneTotal = 0;
      this.pendingTotal = 0;
      this.invoices = await this.dataService.getInvoiceList(this.startTimestamp/1000, this.endTimestamp/1000+24*60*60);
      console.log(this.invoices);
      /* this.payouts = await this.stripeService.getPayoutsList(this.resortData.stripe.id, this.startTimestamp/1000, this.endTimestamp/1000+24*60*60, this.pageSize, null); */

      for (const invoice of this.invoices.results) {
        if (invoice.status === 'paid') {
            this.doneTotal += invoice.amount/100;
        }

        if (invoice.status === 'in_transit') {
          this.pendingTotal += invoice.amount/100;
        }
      }
      console.log(this.invoices);
      this.loader = false;
  }

  async nextPage() {
  }

  async prevPage() {
  }

  async getDetail(payout) {

    const charges = await this.stripeService.getChargesList(this.resortData.stripe.id, payout.created, payout.created+24*60*60, 100, null);
    console.log("CHARGES")
    console.log(charges);
    console.log(payout);

    //const refunds = await this.stripeService.getRefundsList(this.resortData.stripe.id, payout.created, payout.created+24*60*60, 100, null);
    const refunds = null;
    console.log("REFUNDS")
    console.log(refunds);

    let start = 0;
    let end = 0;

    for (let c of charges.data) {
      if (!start) {
        start = c.created;
      }

      if (!end) {
        end = c.created;
      }

      if (c.created < start) {
        start = c.created;
      }

      if (c.created > end) {
        end = c.created;
      }
    }

    let dialogRef = this.dialog.open(ModalTransferComponent, {
      width: '800px',
      data: {
        charges,
        refunds,
        transfer: payout,
        start,
        end
      }
    })

    const self = this;

    dialogRef.afterClosed().subscribe(result => {
    });

  }

  downloadCSV(invoice) {
    console.log(invoice);
    this.loader = true;
    this.dataService.getCSVlist(invoice.payoutId).then(url=>{
      window.open(url.url);
      this.loader = false;
    })
  }

  downloadPDF(invoice) {
    this.loader = true;
    console.log(invoice);
    this.dataService.getPDFlist(invoice.payoutId).then(data=>{
      console.log(data);
      window.open(data.url);
      this.loader = false;
    })
  }

  async downloadXML(invoice) {

    let self = this;
    const ref = this.storage.ref('invoices/'+this.resortData.id+'/'+invoice.payoutId+'.xml');
    let sub = ref.getDownloadURL().subscribe(async function(url) {

      try {
        const res = await self.http.get(url, { responseType: 'blob' }).toPromise();
        self.downloadFile(res,invoice.invoiceId);
      } catch (e) {
        console.log(e);
      }
        
      sub.unsubscribe();
    })
  }

  
  downloadFile(data,name) {
    const url = window.URL.createObjectURL(data);
    const e = document.createElement('a');
    e.href = url;
    e.download =name;
    document.body.appendChild(e);
    e.click();
    document.body.removeChild(e);
  }


}
