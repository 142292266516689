import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/providers/data.service';
import { ReservationService } from 'src/app/core/providers/reservation.service';

@Component({
  selector: 'app-modal-refund-detail',
  templateUrl: './modal-refund-detail.component.html',
  styleUrls: ['./modal-refund-detail.component.scss']
})
export class ModalRefundDetailComponent implements OnInit {
  amount;
  totalAmount;
  refundNotes:string='Tessera Buono';
  step = 0;
  refundExpiration;
  serializedDate;
  refundObj;
  clicked=false;
  new:boolean = false;
  paid:boolean = false;
  paymentType:string = 'cash';
  reservationCode:string|null = null;
  fiscalPrintFlag:boolean = false;
  reservation:any = null;

  constructor(
    public router: Router,
    private dataService: DataService,
    public dialogRef: MatDialogRef<ModalRefundDetailComponent>,
    public reservationService: ReservationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

        console.log(data)
        this.new = data.new;
        this.amount = data.amount;
        this.totalAmount = data.totalAmount;
        this.refundNotes = data.refundNotes;
        this.refundExpiration = data.end;
        this.serializedDate = new Date(this.refundExpiration * 1000);

        if (data.reservationCode) {
          this.reservationCode = data.reservationCode;
        }
  }

  async ngOnInit() {
    if (this.reservationCode) {

      let reservation = await this.dataService.getSingleReservationByCode(this.reservationCode);
      if (reservation) {
        console.log(reservation);
        this.reservation = reservation;
        this.checkFiscalPrintFlag();
      } 
    }
    this.paid = this.data.paid;

  }

  
  checkFiscalPrintFlag () {
    this.fiscalPrintFlag = false;
    let noPayed = true;

    for (const customerBill of this.reservation.order.customerBill) {
      if (customerBill.payed) {
        noPayed = false;
      }

      if (customerBill.payed && !customerBill.fiscalPrinted){
        this.fiscalPrintFlag = true;
        break;
      }
    }

    if (noPayed || this.reservation.order.customerBillTotal > 0) {
      this.fiscalPrintFlag = true;
    }

    console.log(this.fiscalPrintFlag);
  }


  
  changeExpirationDate() {
    const offset= new Date(this.serializedDate).getTimezoneOffset() * 60000;
    this.refundExpiration = ((new Date(this.serializedDate)).setHours(0, 0, 0) - offset ) / 1000;
    console.log(this.refundExpiration);
  }

  checkAmount() {
  }

  
  close(save:boolean): void {
    if (save) {
      if (this.totalAmount < this.amount) {
        this.totalAmount = this.amount;
      }
      let output = {
        amount: this.amount,
        totalAmount: this.totalAmount,
        expiration: this.refundExpiration,
        refundNotes: this.refundNotes,
        paid: this.paid,
        paymentType: this.paymentType,
        print:false
      }
      this.dialogRef.close(output);
    } else {
      this.dialogRef.close(null);
    }
  }

  print() {
    if (this.totalAmount < this.amount) {
      this.totalAmount = this.amount;
    }
    this.paid = true;
    let output = {
      amount: this.amount,
      totalAmount: this.totalAmount,
      expiration: this.refundExpiration,
      refundNotes: this.refundNotes,
      paid: this.paid,
      paymentType: this.paymentType,
      print:true,
      reservation:this.reservation
    }
    this.dialogRef.close(output);
  }

}
