import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DataStatisticsService } from '../../../core/providers/data-statistics.service';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts/highstock';
require('highcharts/modules/exporting')(Highcharts);


@Component({
  selector: 'app-statistics-attendance',
  templateUrl: './statistics-attendance.component.html',
  styleUrls: ['./statistics-attendance.component.scss']
})
export class StatisticsAttendanceComponent implements OnInit {

  resortData; managerData; pageClicked; prices; submenu; lang = 'it';elements; features;optionals;services; dataLang='it'; lightLoading = false;
  loader = false; selectedZone = null;chart; page=1; maxOccupation; dailyOccupation; occupation; occupationRate; stockData;
  showTip3 = false;

  objectKeys = Object.keys;

  // DATE VARIABLES
  startTimestamp; endTimestamp; startSerializedDate; endSerializedDate;
  startDate; startDay; startMonth; startYear;
  endDate; endDay; endMonth; endYear;statistics;

  totalAttendance;dayWithMoreAttendance;averageStay;

  constructor(private statisticsData: DataStatisticsService, public dataService: DataService, private settingsService: SettingsService) {

    let currentMonth = new Date().getMonth()+1;
    let currentYear = new Date().getFullYear();
    let endDay = this.settingsService.daysInMonth(currentMonth,currentYear);
    this.dataLang = this.dataService.lang;

    // SETUP DATES
   /*  const offsetStart = new Date(currentYear+'-'+currentMonth+'-01').getTimezoneOffset() * 60000;
    const offsetEnd = new Date(currentYear+'-'+currentMonth+'-'+endDay).getTimezoneOffset() * 60000;

    this.startTimestamp = (new Date(currentYear+'-'+currentMonth+'-01').setHours(0, 0, 0, 0) - offsetStart);
    this.endTimestamp = (new Date(currentYear+'-'+currentMonth+'-'+endDay).setHours(0, 0, 0, 0) - offsetEnd);

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate; */
    this.resortData = this.dataService.resortData;


  }

  async ngOnInit() {
    this.lightLoading = true;
    if (!this.resortData) {
      await this.dataService.initResortData();
      this.resortData = this.dataService.resortData;
    }

    this.startTimestamp = this.resortData.openingDate * 1000;
    this.endTimestamp = this.resortData.closingDate * 1000;

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate;

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;


    await this.getStatistics();
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout( () => {
      this.pageClicked = false;
    }, 1000);
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.lightLoading = true;
    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp) {

      this.endTimestamp = this.startTimestamp;
    }

      this.dailyOccupation = 0;



    let endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);

    await this.getStatistics();
  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.lightLoading = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp)
      this.startTimestamp = this.endTimestamp;

    let startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    await this.getStatistics();
  }

  async getStatistics() {
    this.lightLoading = true;

    /* let attendances;
    let averageStay;
    let employmentRate;

    [attendances, averageStay, employmentRate] = await Promise.all([this.statisticsData.getAttendance(this.startTimestamp/1000, this.endTimestamp/1000,this.selectedZone,null,null,null,null), this.statisticsData.getAverageStay(this.startTimestamp/1000, this.endTimestamp/1000,this.selectedZone,null,null,null,null), this.statisticsData.getEmploymentRate(this.startTimestamp/1000, this.endTimestamp/1000, 'slot', null, null, null,null, null, this.selectedZone)]); */

    let attendances = await this.statisticsData.getAttendance(this.startTimestamp/1000, this.endTimestamp/1000,this.selectedZone,null,null,null,null);
    let averageStay = await this.statisticsData.getAverageStay(this.startTimestamp/1000, this.endTimestamp/1000,this.selectedZone,null,null,null,null);
    let employmentRate = await this.statisticsData.getEmploymentRate(this.startTimestamp/1000, this.endTimestamp/1000, 'slot', null, null, null,null, null, this.selectedZone);

    this.totalAttendance = 0;
    this.dayWithMoreAttendance = 0;

    let max = -1;

    this.averageStay = averageStay.average_stay;

    console.log(employmentRate);
    let totPacks = 0;

    for (const pack in employmentRate.sellingPackages) {
      if (employmentRate.sellingPackages[pack]) {
        totPacks += employmentRate.sellingPackages[pack];
      }
    }


    const data = [];

    for (const dayAttendance in attendances) {
      if (attendances[dayAttendance] !== null) {
        if (attendances[dayAttendance] > max) {
          this.dayWithMoreAttendance = dayAttendance;
          max = attendances[dayAttendance];
        }
        this.totalAttendance += attendances[dayAttendance];

        data.push(attendances[dayAttendance]);
      }
    }


    console.log(this.totalAttendance);
    console.log(this.dayWithMoreAttendance);
    console.log(totPacks);

    this.dailyOccupation = 0;

    for (const slot of this.dataService.map) {
      if (slot.packagesIds.length > 0 && slot.zoneId) {
        this.dailyOccupation++;
      }
    }

    console.log("DAILY OCCUPATION", this.dailyOccupation)

    this.maxOccupation = 0;
    const length = (this.endTimestamp - this.startTimestamp) / (24*60*60*1000) +1;
    this.maxOccupation = this.dailyOccupation * length;
    this.occupation = 0;

    this.occupationRate = totPacks / this.maxOccupation;
    console.log(this.occupationRate);

    const self = this;

    const plotBands = [];

    for (let i = this.startTimestamp; i <= this.endTimestamp; i += 24*60*60*1000) {
      const day = new Date(i).getDay();
      const year = new Date(i).getFullYear();
      const month = new Date(i).getMonth();
      const date = new Date(i).getDate();
      if (day === 6) {
        plotBands.push({
          color: '#dcdcdc',
          from: Date.UTC(year, month, date),
          to: Date.UTC(year, month, date + 1)
        })
      }
    }

    console.log(plotBands);

    this.chart = new Chart({
      chart: {
        type: 'areaspline'
      },
      exporting: {
        enabled: true
      },
      title: {
        text: 'Presenze'
      },
      credits: {
        enabled: false
      },
      xAxis: {
          type: 'datetime',
          min: self.startTimestamp,
          max: self.endTimestamp,
          tickInterval: 24 * 3600 * 1000,
          labels: {
              overflow: 'justify',
              formatter: function() {
                const date = (new Date(this.value)).getDate();
                const month = (new Date(this.value)).getMonth();
                const monthString = self.statisticsData.shortMonths[month]['it']

                const dateString = date + "<br>" + monthString;
                return dateString;

              }
          },
          dateTimeLabelFormats: {
              day: '%e<br>%b'
          },
          plotBands
      },
      yAxis: [

        { // Primary yAxis
            labels: {
                format: '{value:.,0f}',
                style: {
                    color: '#11A1FF'
                }
            },
            title: {
                text: 'Presenze'
            },
            opposite: false

        }
      ],
      series: [
        {
          type:'areaspline',
          name: 'Presenze',
          data,
          pointInterval:  24 * 3600 * 1000, // one day
          pointStart: self.startTimestamp
        }
      ],
      plotOptions: {
        areaspline: {
            fillOpacity: 0.1,
            marker: {
                enabled: false
            }
        }
      },
    });

    this.lightLoading = false;
  }

  exportPDF() {

  }

  getOccupationRate() {
    console.log(this.selectedZone)
    this.dailyOccupation = 0;
    for (const slot of this.dataService.map) {
      if (slot.packagesIds.length > 0 && slot.zoneId && (!this.selectedZone || parseInt(this.selectedZone) == slot.zoneId)) {
        this.dailyOccupation++;
      }
    }

    console.log(this.dailyOccupation);

    this.maxOccupation = 0;
    const length = (this.endTimestamp-this.startTimestamp) / (24*60*60*1000) +1;
    console.log(length);
    this.maxOccupation = this.dailyOccupation*length
    this.occupation = JSON.parse(JSON.stringify(this.maxOccupation));

    for (const packId in this.stockData.sellingPackagesZones) {
      if (this.stockData.sellingPackagesZones[packId]) {

        if (!this.selectedZone || packId.indexOf(this.selectedZone) > -1 ) {
          this.occupation -= this.stockData.sellingPackagesZones[packId];
        }
      }
    }

    console.log(this.maxOccupation)

    this.occupationRate = 1 - Math.round(this.occupation/this.maxOccupation * 100) / 100;
  }

}
