import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailabilityBoxComponent } from '../home/components/availability-box/availability-box.component';
import { ReservationSingleBoxComponent } from '../home/components/reservation-single-box/reservation-single-box.component';
import { ReservationMultiBoxComponent } from '../home/components/reservation-multi-box/reservation-multi-box.component';
import { ReservationQuickComponent } from '../home/components/reservation-quick/reservation-quick.component';
import { HomeComponent } from '../home/pages/suite_home/home.component';
import { HomeComponent as BookingHomeComponent} from '../home/pages/booking_home/home.component';
import { SharedModule } from '../shared/shared.module';
import { HomeRoutingModule } from './home-routing.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PlannerComponent } from './pages/planner/planner.component';

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PlannerNewComponent } from './pages/planner-new/planner-new.component';
import { ScheduleModule, RecurrenceEditorModule, TimelineViewsService, TimelineMonthService, MonthAgendaService, WorkWeekService, WeekService, DayService, DragAndDropService, ResizeService } from '@syncfusion/ej2-angular-schedule';
import { MonthService, AgendaService } from '@syncfusion/ej2-angular-schedule';
import { ReservationSmartComponent } from './components/reservation-smart/reservation-smart.component';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

@NgModule({
  declarations: [
    HomeComponent,
    PlannerComponent,
    BookingHomeComponent,
    AvailabilityBoxComponent,
    ReservationSingleBoxComponent,
    ReservationMultiBoxComponent,
    ReservationQuickComponent,
    SafePipe,
    PlannerNewComponent,
    ReservationSmartComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    HomeRoutingModule,
    FullCalendarModule,
    ScheduleModule,RecurrenceEditorModule
  ],
  providers: [DragAndDropService, DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService, ResizeService],
  bootstrap:[ReservationSmartComponent]
})
export class HomeModule { }
