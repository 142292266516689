import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../core/providers/data.service';
import { SettingsService } from '../../../core/providers/settings.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-modal-quotation',
  templateUrl: './modal-quotation.component.html',
  styleUrls: ['./modal-quotation.component.scss']
})
export class ModalQuotationComponent implements OnInit {
  reservation;serializedDate; alreadyPaid = false; noCustomers = false;
  phonePrefix=''; phone='';name='';quotationTimeExpiration=18*60*60;

  constructor(public snackBar: MatSnackBar, public router: Router, private settingsService: SettingsService, private dataService: DataService, public dialogRef: MatDialogRef<ModalQuotationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.reservation = data.reservation;
      this.name = data.name;
      this.phonePrefix = data.phonePrefix;
      this.phone = data.phone;

      if (!this.reservation.quotation) {
        let ts = new Date().setUTCHours(0,0,0,0);
        ts += 30*24*60*60*1000
        this.reservation.quotation = {
          enabled: false,
          onlinePayment: false,
          onlineDeposit: this.reservation.order.total,
          expiration: ts/1000,
          notes: {
            it: '',
            en: '',
            de: ''
          },
          deleteReservationOnExpiration: false
        }
      } else if (this.reservation.quotation.expiration) {
        let dateInput = new Date(this.reservation.quotation.expiration*1000);
        let time = dateInput.getHours()*60*60 + dateInput.getMinutes()*60 + dateInput.getSeconds();
        console.log(time);
        this.quotationTimeExpiration = time;
      }

      this.serializedDate = new Date(this.reservation.quotation.expiration*1000);
      console.log(this.reservation);
      console.log(this.reservation.quotation);

      if (this.reservation.order.customerBill && this.reservation.order.customerBill.find(c => c.paymentType === 'credit-card' && c.payed)) {
        this.alreadyPaid = true;
      }

      if (!this.reservation.customerId) {
        this.noCustomers = true;
      }
    }



  async ngOnInit() {
  }

  close(save): void {
    
    console.log(this.quotationTimeExpiration);
    console.log(this.reservation.quotation.expiration)
    
    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now)) {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }
    console.log(offset);
    let finalTS = parseInt(this.reservation.quotation.expiration)+parseInt(this.quotationTimeExpiration.toString())+offset/1000;
    console.log(finalTS);
    this.reservation.quotation.expiration = finalTS;
    this.dialogRef.close(save);
  }

  updateExpiration() {
  }

  checkDeposit() {
    console.log("CHECK")
    if (this.reservation.quotation.onlineDeposit < 1 ) {
      this.reservation.quotation.onlineDeposit = 1;
    }

    if (this.reservation.quotation.onlineDeposit > this.reservation.order.total) {
      this.reservation.quotation.onlineDeposit = Math.trunc(this.reservation.order.total*100)/100;
    }
  }
  async changeDate(type: string, event: MatDatepickerInputEvent<Date>)  {
    console.log(event);
    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now)) {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    const startTS = (new Date(event.value)).getTime() - offset;
    this.serializedDate = new Date(startTS);
    this.reservation.quotation.expiration = startTS/1000;

  }


  copyMessage(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = 'https://www.cocobuk.com/quotes/it/'+this.reservation.reservationCode;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openSnackBar('URL copiato', 'OK');
  }


  goTo(url) {
    this.router.navigate([url]);
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }


  sendWhatsapp() {
    console.log(this.reservation);
    let text = "Ciao "+this.name+", a questo link puoi trovare l'offerta a te dedicata da "+this.dataService.resortData.name+": https://cocobuk.com/quotes/it/"+this.reservation.reservationCode;
    window.open(`https://wa.me/`+this.phonePrefix + this.phone+`?text=`+text, '_blank');
  }

}
