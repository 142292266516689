import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { DataService } from 'src/app/core/providers/data.service';
import { SettingsService } from 'src/app/core/providers/settings.service';
import { ReservationService } from 'src/app/core/providers/reservation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  resortData; managerData; data; reservations; optionals;optionalsByIds={}; optionalsByKeys={};

  // FILTERS VARIABLES
  term$ = new Subject<string>();
  selectedPackage = null; selectedSettlement = null; selectedPaymentType = null;
  selectedOptionals = null; selectedStatus = null; onlyArrivals = null;
  selectedType = null; selectedToday = null; onlyDepartures = null;
  selectedState = null; selectedNoSlots = null; selectedZone = null;
  selectedFilter = null; searchString = ''; selectedCabins = null; size = null;

  // DATE VARIABLES
  startTimestamp; endTimestamp;
  startDate; startDay; startMonth; startYear;
  endDate; endDay; endMonth; endYear;
  startSerializedDate; endSerializedDate;

  // GLOBAL VARIABLES
  loader = true; nextPage; prevPage; totalReservations = 0; pageSize = 25; page = 1;
  objectKeys = Object.keys; lang = 'it'; totalAmount = 0; intervalAmount=0;pageClicked = false;
  overlayLoader = false; percentage = 0; orderBy = null; orderTotalAsc = null;
  orderNameAsc = null; orderDateAsc = null; reservationsOrderType = 'name';
  showRightBarList = false;showRightBarPeople = false;

  attendanceOrderType = 'name'; savePreferencesAttendance; savePreferencesReservations;

  listFields = {
    name: true,
    slots: true,
    date: false,
    paid: false,
    type: false,
    notes: false,
    extras: false,
    customerInfos: false,
    refunds: false,
    amount: false
  }

  attendanceFields = {
    name: true,
    phone: true,
    email: true,
    fiscalCode: true,
    date: true,
    notes: true
  }

  constructor(public router: Router,public dataService: DataService, private settingsService: SettingsService, private reservationService: ReservationService) {

    // SETUP DATES
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    if (this.dataService.startTimestamp) {
      this.startTimestamp = this.dataService.startTimestamp;
    }

    if (this.dataService.endTimestamp) {
      this.endTimestamp = this.dataService.endTimestamp;
    }

    const startDate = new Date(this.startTimestamp);

    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate;

    // SETUP DATA
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.lang = this.dataService.lang;

    // SEARCH STRING

    this.term$
        .debounceTime(1000)
        .distinctUntilChanged()
        .subscribe(term => {
          this.searchString = term;

          if (term === '') {
            this.searchString = null;
          }

          this.page = 1;

          this.getReservations();
        });

  }

  async ngOnInit() {

    if (!this.resortData) {
      this.data = await this.dataService.initResortData();
    }

    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.optionals =  this.dataService.optionals;

    if (this.managerData && this.managerData.settingsManager) {
      if (this.managerData.settingsManager.reservation_list) {
        this.reservationsOrderType = this.managerData.settingsManager.reservation_list.sortBy;
        this.listFields = this.managerData.settingsManager.reservation_list.fields;
      }

      if (this.managerData.settingsManager.attendance_list) {
        this.attendanceOrderType = this.managerData.settingsManager.attendance_list.sortBy;
        this.attendanceFields = this.managerData.settingsManager.attendance_list.fields;
      }

    } else if (this.managerData) {
      this.managerData.settingsManager = {
        reservation_list: {
          sortBy: '',
          fields: {}
        },
        attendance_list: {
          sortBy: '',
          fields: {}
        }
      }
    }

    for (const opt of this.optionals) {
      this.optionalsByIds[opt.id] = opt;
      this.optionalsByKeys[opt.optionalId] = opt;
    }

    console.log(this.resortData)
    console.log(this.managerData);

    await this.getReservations();
  }

  async changePage(url) {
    this.loader = true;
    const data = await this.dataService.getReservationsByUrl(url);
    this.nextPage = data.next;
    this.prevPage = data.previous;
    this.reservations = this.processReservations(data.results);
    this.totalReservations = data.count;
    this.loader = false;
  }

  resetFilters() {
    this.selectedCabins = null;
    this.selectedFilter = null;
    this.selectedOptionals = null;
    this.searchString = null;
    this.selectedPackage = null;
    this.selectedState = null;
    this.selectedPaymentType = null;
    this.page = 1;
    this.selectedZone = null;
    this.selectedSettlement = null;


    this.getReservations();
  }

  openReservation(url) {
    this.dataService.startTimestamp = this.startTimestamp;
    this.dataService.endTimestamp = this.endTimestamp;
    this.dataService.previousPage = 'reservations';
    this.router.navigateByUrl(url);
  }

  async getReservations() {

    console.log(new Date(), 'GET RESERVATIONS START');
    const start = this.startTimestamp / 1000;
    const end = this.endTimestamp / 1000;
    const data = await this.dataService.getReservations(start, end, this.onlyArrivals, this.onlyDepartures, this.searchString, this.selectedState, this.selectedZone, this.selectedPackage, this.selectedOptionals, this.selectedToday, this.selectedSettlement, this.selectedCabins, this.selectedPaymentType, null, null, this.size, 1, true, null,null, null, null, null, null,null,null,null, null, null);


    console.log(new Date(), 'RESERVATIONS READY');
    this.reservations = this.processReservations(data.results);
    this.nextPage = data.next;
    this.prevPage = data.previous;
    this.totalReservations = data.count;
    this.loader = false;
    console.log(new Date(), 'RESERVATIONS READY PROCESS');
    console.log(this.reservations);
    let amounts = await this.dataService.getTotalAmount(start, end, this.onlyArrivals, this.onlyDepartures, this.searchString, this.selectedState, this.selectedZone, this.selectedPackage, this.selectedOptionals, this.selectedToday, this.selectedSettlement, this.selectedCabins, this.selectedPaymentType,null,null, null,null, null, null, null,null,null,null)
    this.totalAmount = amounts.total_order;
    this.intervalAmount = amounts.interval_order;
  }

  processReservations(data) {
    let output = [];

    console.log(data);

    for (const reservation of data) {

      for (const singleInterval of reservation.dates) {
        if ((singleInterval.start <= this.startTimestamp / 1000 && singleInterval.end >= this.startTimestamp / 1000 && singleInterval.end <= this.endTimestamp / 1000) || (singleInterval.start >= this.startTimestamp / 1000 && singleInterval.end <= this.endTimestamp / 1000) || (singleInterval.start <= this.endTimestamp / 1000 && singleInterval.end >= this.endTimestamp / 1000)) {

            if (reservation.version === 3) {
              for (const interval of reservation.dates) {
                for (const order of interval.order) {
                    for (const optionalId in order.optionalsKeys) {
                      if (order.optionalsKeys[optionalId] && this.optionalsByKeys[optionalId]) {
                        order.optionalsKeys[this.optionalsByKeys[optionalId].id] = order.optionalsKeys[optionalId];
                        delete order.optionalsKeys[optionalId];
                      }
                    }
                }
              }
            }


            const obj = {
              noSlots: reservation.noSlots,
              name: reservation.name,
              surname: reservation.surname,
              order: reservation.order,
              start: singleInterval.start,
              end: singleInterval.end,
              phone: reservation.phonePrefix + ' '+ reservation.phone,
              notes: reservation.notes,
              groupByPacks: {},
              extras: reservation.extras,
              amountPayed: 0,
              amountTotal: 0,
              id: reservation.id,
              seasonTicket: reservation.seasonTicket,
              cocoBooking: reservation.cocoBooking,
              refundState: reservation.refundState,
              reservationCode: reservation.reservationCode
            };

            if (reservation.order.customerBill) {

              for (const billObj of reservation.order.customerBill) {
                if (billObj.payed) {
                    obj.amountPayed += parseFloat(billObj.price);
                } else {
                    obj.amountTotal += parseFloat(billObj.price);
                }
              }
            }


            if (obj.amountTotal === 0) {
              obj.amountTotal = reservation.order.total;
            }

            if (reservation.version === 3 && reservation.order.settled && obj.amountPayed === 0) {
              obj.amountPayed = obj.amountTotal;
            }

            for (const product of singleInterval.order) {

              if (!obj.groupByPacks[product.packageKey]) {
                obj.groupByPacks[product.packageKey] = {
                  products: [],
                  optionals: {},
                  quantity: 0
                };
              }

              obj.groupByPacks[product.packageKey].products.push(product);
              obj.groupByPacks[product.packageKey].quantity+=product.quantity;
              if (product.optionalsKeys) {
                for (let optKey in product.optionalsKeys) {

                  if (product.optionalsKeys[optKey]) {
                    if (!this.optionals[optKey]) {
                      for (let optId in this.optionals ) {
                        if (this.optionals[optId].id == optKey) {
                          optKey = this.optionals[optId].id;
                          break;
                        }
                      }
                    }

                    if (!obj.groupByPacks[product.packageKey].optionals[optKey]) {
                      obj.groupByPacks[product.packageKey].optionals[optKey] = 0;
                    }

                    obj.groupByPacks[product.packageKey].optionals[optKey] += product.optionalsKeys[optKey];
                  }

                }

              }
            }

            output.push(obj);
        }
      }
    }

    return output;
  }

  async exportPeople() {
    this.overlayLoader = true;
    this.percentage = 0;

    let reservationsToSave = [];

    const start = this.startTimestamp / 1000;
    const end = this.endTimestamp / 1000;

    console.log(this.totalReservations);

    let nextPage = null;
    this.size = 500;
    for (let i = 0; i < this.totalReservations; i += 500) {

      let data = null;
      if (i==0) {

        data = await this.dataService.getReservations(start, end, null, null, null, null, null, null, null, null, null, null, null, null, null, this.size, this.page, true, null, null, null, 'start' , null, null, null, null,null,null,null);
        nextPage = data.next;
      } else if (nextPage) {
        data = await this.dataService.getReservationsByUrl(nextPage);
        nextPage = data.next;
      } else {
        break;
      }

      console.log(data);

      if (data && data.results) {
        const partialReservations = this.processReservations(data.results);
        for (const res of partialReservations) {
          reservationsToSave.push(res);
        }
      }
    }

    this.reservationService.exportPeople(reservationsToSave,this.startTimestamp,this.endTimestamp,null,null);

    this.size = null;
    this.overlayLoader = false;
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.endTimestamp = this.startTimestamp;
    }

    let endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);

    let setReservations = await this.getReservations();

    this.dataService.startTimestampList = this.startTimestamp;
    this.dataService.endTimestampList = this.endTimestamp;

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.loader = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.startTimestamp = this.endTimestamp;
    }

    let startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    await this.getReservations();

    this.dataService.startTimestampList = this.startTimestamp;
    this.dataService.endTimestampList = this.endTimestamp;
  }

  async setTodayDate() {
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    let startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startDate = startDate.getDate();

    let endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endDate = endDate.getDate();

    this.startSerializedDate = new Date(this.startTimestamp);
    this.endSerializedDate = new Date(this.endTimestamp);

    await this.getReservations();

    this.dataService.startTimestampList = this.startTimestamp;
    this.dataService.endTimestampList = this.endTimestamp;

  }



  async exportPDF() {

    if (this.savePreferencesReservations) {
        this.managerData.settingsManager.reservation_list.sortBy = this.reservationsOrderType;
        this.managerData.settingsManager.reservation_list.fields = this.listFields;
        await this.dataService.updateManager(this.managerData);
    }

    this.overlayLoader = true;
    this.percentage = 0;

    let reservationsToSave = [];

    const start = this.startTimestamp / 1000;
    const end = this.endTimestamp / 1000;

    console.log(this.totalReservations);

    let nextPage = null;
    this.size = 500;
    for (let i = 0; i < this.totalReservations; i += 500) {

      let data = null;
      if (i==0) {

        data = await this.dataService.getReservations(start, end, this.onlyArrivals, this.onlyDepartures, this.searchString, this.selectedState, this.selectedZone, this.selectedPackage, this.selectedOptionals, this.selectedToday, this.selectedSettlement,null, this.selectedPaymentType, null, null, this.size, this.page, true, null, null, null, 'start', null ,null, null,null,null,null,null);
        nextPage = data.next;
      } else if (nextPage) {
        data = await this.dataService.getReservationsByUrl(nextPage);
        nextPage = data.next;
      } else {
        break;
      }

      console.log(data);

      if (data && data.results) {
        const partialReservations = this.processReservations(data.results);
        for (const res of partialReservations) {
          reservationsToSave.push(res);
        }
      }
    }

    const filters = {
      packages: this.selectedPackage,
      settlement: this.selectedSettlement,
      zones: this.selectedZone,
      cabins: this.selectedCabins,
      state: this.selectedState,
      searchString: this.searchString
    }

    this.reservationService.exportPDF(reservationsToSave,this.startTimestamp,this.endTimestamp, filters, this.reservationsOrderType, this.listFields, true);

    this.size = null;
    this.overlayLoader = false;

  }

  async exportXLS() {

    let outputObjs = [];

    this.size = this.totalReservations;

    this.overlayLoader = true;
    this.percentage = 0;

    this.size = this.totalReservations;
    let reservationsToSave = [];

    for (let i = 0; i < this.totalReservations; i += 500){
      this.size = 500;

      if (i > 0 && (i + 500) >= this.totalReservations) {
        this.size = this.totalReservations % i;
      }

      this.page = i / 500 + 1;

      await this.getReservations();
      reservationsToSave = reservationsToSave.concat(this.reservations);
    }

    reservationsToSave.map(reservation => {

      if ((!reservation.refundState && !this.listFields.refunds) || this.listFields.refunds) {

          const obj = {
            id: reservation.id,
            nome: reservation.name + ' ' + reservation.surname,
            note: reservation.notes,
            inizio: new Date(reservation.start * 1000),
            fine: new Date(reservation.end * 1000),
            tipo: '',
            pagato: 'SI',
            totale: reservation.amountTotal.toFixed(2).replace(',',';').replace('.',',').replace(';',''),
            incassato: reservation.amountPayed.toFixed(2).replace(',',';').replace('.',',').replace(';',''),
            pacchetti: '',
            extras: ''
          };


          for (const packKey in reservation.groupByPacks) {


            if (reservation.groupByPacks[packKey]) {
              if (obj.pacchetti != '') {
                obj.pacchetti += '\n';
              }

              if (this.resortData.packagesByIds[packKey]) {
                if (obj.pacchetti == '') {
                  obj.pacchetti += reservation.groupByPacks[packKey].quantity + ' x ' + this.resortData.packagesByIds[packKey].name[this.dataService.lang] ;
                } else {
                  obj.pacchetti += ', ' + reservation.groupByPacks[packKey].quantity + ' x ' + this.resortData.packagesByIds[packKey].name[this.dataService.lang] ;
                }
              } else {
                if (obj.pacchetti == '') {
                  obj.pacchetti += reservation.groupByPacks[packKey].quantity + ' x Pacchetto non più esistente';
                } else {
                  obj.pacchetti += ', ' + reservation.groupByPacks[packKey].quantity + ' x Pacchetto non più esistente';
                }
              }

              for (const optionalKey in reservation.groupByPacks[packKey].optionals) {
                if (reservation.groupByPacks[packKey].optionals[optionalKey]) {
                  const foundOptional = this.optionals.find(opt => opt.id == optionalKey);
                  if (foundOptional) {
                    obj.pacchetti += ', ' + foundOptional.name[this.dataService.lang] + ' x ' + reservation.groupByPacks[packKey].optionals[optionalKey];
                  }
                }
              }

            }

          }

          if (reservation.extras && this.listFields.extras) {
            for (const extra of reservation.extras) {
              if (obj.extras != '') {
                obj.extras += ', ';
              }

              if (extra.name && typeof extra.name === 'string') {
                obj.extras += extra.quantity + ' x ' + extra.name;
              } else if (this.dataService.resortData.extrasByIds[extra.id]) {
                obj.extras += extra.quantity + ' x ' + this.dataService.resortData.extrasByIds[extra.id].name[this.dataService.lang];
              }  else {
                obj.extras += extra.quantity + ' x ' + 'Extra non più esistente';
              }
            }
          }

          if (reservation.cocoBooking) {
            obj.tipo = 'COCO BOOKING';
          } else {
            obj.tipo = 'MANAGER';
          }

          let outObj = {};


          if (this.listFields.name) {
            let name = obj.nome;
            if (reservation.email && reservation.email !== '') {
              name = name+'\n'+reservation.email;
            }

            if (reservation.phone && reservation.phone !== ''  && reservation.phone.trim() !== '+39') {
              name = name+'\n'+reservation.phone;
            }

            if (reservation.fiscalCode && reservation.fiscalCode !== '') {
              name = name+'\n'+reservation.fiscalCode;
            }

            outObj['nome'] = name;
          }

          if (this.listFields.notes) {
            outObj['note'] = obj.note;
          }

          if (this.listFields.type) {
            outObj['tipo'] = obj.tipo;
          }

          if (this.listFields.paid) {
            outObj['pagato'] = obj.pagato;
          }

          if (this.listFields.amount) {
            outObj['totale'] = obj.totale;
          }

          if (this.listFields.date) {
            outObj['inizio'] = obj.inizio;
            outObj['fine'] = obj.fine;
          }

          if (this.listFields.slots) {
            outObj['pacchetti'] = obj.pacchetti;
          }

          if (this.listFields.extras) {
            outObj['extras'] = obj.extras;
          }

          if (reservation.refundState) {
            obj.tipo = 'RIMBORSATA';
          } else if (reservation.cocoBooking) {
            obj.tipo = 'COCO BOOKING';
          } else {
            obj.tipo = 'MANAGER';
          }

          outputObjs.push(outObj);
        }
    });

    console.log(outputObjs)


    if (this.reservationsOrderType === 'name') {
      outputObjs.sort(this.sortByName);
    }

    if (this.reservationsOrderType === 'dateAsc') {
      outputObjs.sort(this.sortByDateAsc);
    }

    if (this.reservationsOrderType === 'dateDesc') {
      outputObjs.sort(this.sortByDateDesc);
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(outputObjs);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    var startDate = new Date(this.startTimestamp);
    var endDate = new Date(this.endTimestamp);

    var startDay = startDate.getDate();
    var startMonth = startDate.getMonth() + 1;

    var endDay = endDate.getDate();
    var endMonth = endDate.getMonth() + 1;


    XLSX.writeFile(wb, 'Prenotazioni_' + startDay + '-' + startMonth + '_' + endDay + '-' + endMonth + '.xlsx');

    this.size = null;
    this.overlayLoader = false;
  }

  sortBySlotsAsc(a, b) {
    return a.postazioni.trim().localeCompare(b.postazioni.trim(), undefined, {numeric: true, sensitivity: 'base'});
  }

  sortBySlotsDesc(a, b) {
    return b.postazioni.trim().localeCompare(a.postazioni.trim(), undefined, {numeric: true, sensitivity: 'base'});
  }

  sortByName( a, b ) {
    if ( a.nome.toLowerCase() < b.nome.toLowerCase() ) {
      return -1;
    }
    if ( a.nome.toLowerCase() > b.nome.toLowerCase() ) {
      return 1;
    }
    return 0;
  }

  sortByDateAsc( a, b ) {
    if ( a.inizio < b.inizio ) {
      return -1;
    }
    if ( a.inizio > b.inizio ) {
      return 1;
    }
    return 0;
  }

  sortByDateDesc( a, b ) {
    if ( a.inizio < b.inizio ) {
      return 1;
    }
    if ( a.inizio > b.inizio ) {
      return -1;
    }
    return 0;
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout(() => {
      this.pageClicked = false;
    }, 1000);
  }

  exportXLSpeople() {

  }

}
