import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../core/providers/data.service';

@Component({
  selector: 'app-reservation-multi-box',
  templateUrl: './reservation-multi-box.component.html',
  styleUrls: ['./reservation-multi-box.component.scss']
})
export class ReservationMultiBoxComponent implements OnChanges {

  @Input() selectedReservations:any;
  @Input() selectedSlot:any;
  @Input() availableTimeslots:any;
  @Input() resortData:any;
  @Input() timeSlots:any;
  @Input() startTimestamp:any;
  @Input() endTimestamp:any;
  @Output() resetDataEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() openReservationDetailEmitter: EventEmitter<any> = new EventEmitter<any>();
  dailyReserved: boolean = false;
  selectedReservation:any = null;
  clicked:boolean = false;
  objectKeys = Object.keys;
  optionals:any;
  optionalsByIds: any = {};

  constructor(
    public dataService:DataService,
    public router: Router
  ) { }

  ngOnChanges() {

    if (this.dataService.optionals) {

      this.optionals = this.dataService.optionals;

      if (this.optionals) {
        this.optionals.map(opt => {
          this.optionalsByIds[opt.id] = opt;
        });
      }
    }
    this.dailyReserved = false;

    if (this.selectedReservations.length === 1) {
      this.selectedReservation = this.selectedReservations[0];
    }

    // DISABILITA NUOVA PRENOTAZIONE SE c'è UNA SOLA PRENOTAZIONE ED E' GIORNALIERA
    if (this.selectedReservation && this.selectedReservation.selectedInterval && this.selectedReservation.selectedInterval.timeSlots.length === 0) {
      //let slotFound = this.selectedReservation.selectedInterval.order.find(o => o.slot.slot_key === (this.selectedSlot.row + '_' + this.selectedSlot.col));
      this.dailyReserved = true;
    }

    console.log(this.availableTimeslots);
    
    // DISABILITA NUOVA PRENOTAZIONE SE CI SONO PIU' PRENOTAZIONI DI CUI UNA GIORNALIERA O CHE COPRONO TUTTE LE FASCE ORARIE NON GIORNALIERE
    let timeslotsSelected = [];
    for (let reservation of this.selectedReservations) {
      if (reservation.selectedInterval.timeSlots.length === 0) {
        this.dailyReserved = true;
        break;
      } else {
        timeslotsSelected = timeslotsSelected.concat(reservation.selectedInterval.timeSlots);
      }

      console.log(timeslotsSelected);

      if (this.availableTimeslots.length === timeslotsSelected.length) {
        this.dailyReserved = true;
        break;
      }
    }

    if (this.startTimestamp === this.endTimestamp) {
      this.selectedReservations = this.selectedReservations.sort(this.sortByTimeslot);
    }

    console.log(this.selectedReservations);
  }

  sortByTimeslot(a: any, b: any) {

    if (a.selectedInterval.timeSlots && a.selectedInterval.timeSlots.length && b.selectedInterval.timeSlots && b.selectedInterval.timeSlots.length) {
      if (a.selectedInterval.timeSlots[0].startSeconds < b.selectedInterval.timeSlots[0].startSeconds){
        return -1;
      }
  
      if (a.selectedInterval.timeSlots[0].startSeconds > b.selectedInterval.timeSlots[0].startSeconds){
        return 1;
      }
    }

    return 0;
  }

  
  compareByStart( a: any, b: any ) {
    if ( a.start > b.start ){
      return -1;
    }
    if ( a.start < b.start ){
      return 1;
    }
    return 0;
  }

  openReservation(url) {
    this.dataService.previousPage = 'home';
    this.router.navigateByUrl(url);
  }

  resetData() {
    this.resetDataEmitter.emit();
  }


  newReservation() {
    this.dataService.previousPage = 'home';
    const reservation = this.dataService.initReservation(this.startTimestamp, this.endTimestamp, false);
    const order = [];

    const length = (this.endTimestamp - this.startTimestamp) / (24 * 60 * 60 * 1000) + 1;


    order.push({
      col: this.selectedSlot.col,
      row: this.selectedSlot.row,
      type: 'slot',
      coords: this.selectedSlot.label,
      zoneKey: this.selectedSlot.zoneId,
      slot_key: this.selectedSlot.row+'_'+this.selectedSlot.col,
      packageKey: parseInt(this.selectedSlot.defaultPackId),
      optionalsKeys: {},
    });

    let timeSlots = [];

    //IMPOSTA PRIMO TIMESLOT LIBERO
    let availableTimeslots = this.timeSlots.filter(t =>{
      if (t.id !== this.selectedReservation.selectedInterval.timeSlots[0].id) {
        return t;
      } else {
        return null;
      }
    })

    const dateInterval = {
      date_key: (this.startTimestamp / 1000) + '_' + (this.endTimestamp / 1000),
      start: this.startTimestamp / 1000,
      end: this.endTimestamp / 1000,
      length: length,
      intervalAmount: 0,
      optionalsAmount: 0,
      order: order,
      timeSlots: [availableTimeslots[0]]
    };
    reservation.dates = [];
    reservation.dates.push(dateInterval);

    console.log(reservation)
    this.dataService.selectedReservation = reservation;
    this.dataService.mainCustomer = null;
    this.dataService.associatedCustomers = null;

    this.router.navigate(['/suite/reservation/new']);
  }

  showReservationDetail(reservation:any) {
    console.log(this.selectedReservation);
    if (this.selectedReservation) {
      this.openReservationDetailEmitter.emit(this.selectedReservation);
    } else {
      this.router.navigate(['/suite/reservation/'+reservation.id]);
    }
  }

}
