import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/providers/data.service';

@Component({
  selector: 'app-price-grid',
  templateUrl: './price-grid.component.html',
  styleUrls: ['./price-grid.component.scss']
})
export class PriceGridComponent implements OnInit {

  @Input() price:any
  @Input() isOptional:boolean = false;
  @Input() timeslot:string='';

  constructor(public dataService:DataService) {

  }

  ngOnInit(): void {

  }


  checkPrice(type) {
    if (!this.price[type] || this.price[type] < 0) {
      this.price[type] = 0;
    }
  }
}
