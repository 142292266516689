import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthguardService } from '../core/providers/authguard.service';
import { CustomersComponent as SuiteCustomersComponent} from './pages/customers/customers.component';
import { CustomerDetailComponent as SuiteCustomerDetailComponent} from './pages/customer-detail/customer-detail.component';


const routes: Routes = [

  { path: 'common/customers', component: SuiteCustomersComponent , canActivate: [AuthguardService]},
  { path: 'common/customer/:id', component: SuiteCustomerDetailComponent , canActivate: [AuthguardService]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule {}
