import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reservation-quick',
  templateUrl: './reservation-quick.component.html',
  styleUrls: ['./reservation-quick.component.scss']
})
export class ReservationQuickComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
