import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/core/providers/data.service';

@Component({
  selector: 'app-reservation-cabins',
  templateUrl: './cabins.component.html',
  styleUrls: ['./cabins.component.scss']
})
export class CabinsComponent implements OnInit {

  @Input() reservation:any;
  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dataService:DataService) {

  }

  ngOnInit(): void {

  }

}
