import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorComponent implements OnInit {

  remove = false;
  error;

  constructor(private translate: TranslateService, public router: Router, private dataService: DataService, public dialogRef: MatDialogRef<ModalErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      if (data.error === 'reservation/slots-not-available') {
        this.error = this.translate.instant('slotsNotAvailable');
      } else if (data.error === 'users/manager-already-exists') {
        this.error = this.translate.instant('removeManagerError');
      } else if (data.error === 'reservations') {
        this.error = this.translate.instant('removePackageError');
      } else if (data.error === 'zones') {
        this.error = this.translate.instant('removeZoneError');
      } else if (data.error){
        this.error = data.error;
      } else {
        this.error = this.translate.instant('errorGenericLabel');
      }
    }

  ngOnInit() {

  }

  close(): void {
    this.dialogRef.close();
  }

}
