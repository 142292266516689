import { S } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/providers/auth.service';
import { DataService } from 'src/app/core/providers/data.service';
import { ReservationService } from 'src/app/core/providers/reservation.service';
import { SettingsService } from 'src/app/core/providers/settings.service';
import { ModalDatesComponent } from 'src/app/shared/components/modal-dates/modal-dates.component';
import { ModalErrorComponent } from 'src/app/shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-reservation-interval-detail',
  templateUrl: './interval-detail.component.html',
  styleUrls: ['./interval-detail.component.scss']
})
export class IntervalDetailComponent implements OnInit {

  @Input() reservation:any;
  @Input() intervalIndex:any
  @Input() previousIntervals:any;
  @Input() interval:any;
  @Input() mainCustomer:any;
  @Input() resortData:any;
  @Input() mapData:any;
  @Input() managerData:any;
  @Input() associatedCustomers:any;
  @Input() loading:any;
  @Input() clicked:any;
  @Output() updateCartEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() getUpdatedReservationEmitter: EventEmitter<any> = new EventEmitter<any>();
  today = (new Date()).setUTCHours(0, 0, 0, 0) / 1000;
  showFreeSlotModal = false;
  freeSlotNotes; availablePackages;showPackageModal = false; selectedPackageIndex = null;selectedPackage;
  timeslots:any[] = []; selectedTimeslot:any = 0;
  disableDailyTimeslot:boolean = false;
  savedPackageId = null;
  showOptionals = false;
  showTodayStatus = false;

  constructor(private ngZone: NgZone, public snackBar: MatSnackBar, private router: Router, public dataService:DataService,  public dialog:MatDialog, public reservationService: ReservationService, public settingsService: SettingsService , public translateService: TranslateService, public authProvider: AuthService) {

  }

  ngOnInit(): void {
    this.getAndCheckTimeslots();
    for (let order of this.interval.order) {
      if (order.type === 'slot') {
        this.showTodayStatus = true;
      }
      for (let opt of this.resortData.packagesByIds[order.packageKey].optionals) {
        this.showOptionals = true;
        break;
      }
    }
  }

  getAndCheckTimeslots() {
    this.disableDailyTimeslot = false;
    let seasonsFound = this.dataService.getSeasonsInReservation(this.interval.start, this.interval.length,null);
    let daysInInterval = this.settingsService.getDaysFromInterval(this.interval.start, this.interval.end);
    if (seasonsFound && Object.keys(seasonsFound).length === 1) {
      for (let seasonKey in seasonsFound) {
        if (this.settingsService.checkIfTimeslotsAreEnabled(seasonsFound[seasonKey], daysInInterval)) {
          this.timeslots = this.resortData.timeSlotsBySeason[seasonKey];
        } else {
          this.timeslots = [];
        }
      }
    }

    if (this.interval.timeSlots && this.interval.timeSlots.length) {
      let selectedTimeslot = this.interval.timeSlots[0];
      let now = new Date();
      let offset = now.getTimezoneOffset();
      let startSeconds = parseInt(selectedTimeslot.startHour.split(":")[0])*60*60 + parseInt(selectedTimeslot.startHour.split(":")[1])*60 + offset*60;
      let endSeconds = parseInt(selectedTimeslot.endHour.split(":")[0])*60*60 + parseInt(selectedTimeslot.endHour.split(":")[1])*60 + offset*60;
      selectedTimeslot.startSeconds = startSeconds;
      selectedTimeslot.endSeconds = endSeconds;
      selectedTimeslot.utcOffset = -offset/60;
      // CERCA TRA I TIMESLOTS DISPONIBILI SE ESISTE ALTRIMENTI PUSHALO (potrebbe essere stato salvato e non più disponibile)
      if (this.timeslots.find(t => t.id === selectedTimeslot.id)) {

      } else {
        this.timeslots.push(selectedTimeslot);
      }

      this.selectedTimeslot = selectedTimeslot.id;
    }

    for (let timeslot of this.timeslots) {
      timeslot.disabled = false;
      if (this.interval.disableTimeSlots && this.interval.disableTimeSlots.find(t => t.id === timeslot.id)) {
        timeslot.disabled = true;
        this.disableDailyTimeslot = true;
      }
    }

  }

  updateTimeslot() {
    console.log(this.selectedTimeslot)
    this.interval.timeSlots = []
    let now = new Date();

    if (this.selectedTimeslot) {
      let findTS = JSON.parse(JSON.stringify(this.timeslots.find(t => t.id === this.selectedTimeslot)));

      let offset = now.getTimezoneOffset();
      let startSeconds = parseInt(findTS.startHour.split(":")[0])*60*60 + parseInt(findTS.startHour.split(":")[1])*60 + offset*60;
      let endSeconds = parseInt(findTS.endHour.split(":")[0])*60*60 + parseInt(findTS.endHour.split(":")[1])*60 + offset*60;
      findTS.startSeconds = startSeconds;
      findTS.endSeconds = endSeconds;
      findTS.utcOffset = -offset/60;
      delete findTS.disabled
      this.interval.timeSlots.push(findTS);
    }
    console.log(this.reservation)
    this.updateCartEmitter.emit(this.reservation);
  }

  disableDatesFilter = (disableDates, d: Date ): boolean => {
    const offset = new Date(d).getTimezoneOffset() * 60000;
    const ts = ((new Date(d)).getTime() - offset)  / 1000;
    if (disableDates)
      return (disableDates.indexOf(ts) === -1) ;
    else
      return true;
  }


  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>, interval)  {
    // console.log(interval);

    // PRENDI NUOVO INTERVALLO

    const offsetStart = new Date(interval.startDate).getTimezoneOffset() * 60000;
    const offsetEnd = new Date(interval.endDate).getTimezoneOffset() * 60000;
    const startTS = ((new Date(interval.startDate)).setHours(0, 0, 0, 0) - offsetStart)  / 1000;
    let endTS = ((new Date(interval.endDate)).setHours(0, 0, 0, 0) - offsetEnd)  / 1000;

    if (startTS > endTS) {
      endTS = startTS;
      interval.endDate = interval.startDate;
    }

    // AGGIORNA GLI INTERVALLI CON EVENTUALI BUCHI
    this.reservation = this.settingsService.checkOtherReservationsAndSplitIntervals(this.reservation, startTS, endTS, interval);

    // CONTROLLA LA CONSISTENZA DELLE FASCE ORARIE
    this.getAndCheckTimeslots();

    this.updateCartEmitter.emit(this.reservation);
  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>, interval)  {
    // console.log(interval);
    // console.log(JSON.parse(JSON.stringify(this.reservation.dates)));
    const offsetStart = new Date(interval.startDate).getTimezoneOffset() * 60000;
    const offsetEnd = new Date(interval.endDate).getTimezoneOffset() * 60000;
    let startTS = ((new Date(interval.startDate)).setHours(0, 0, 0, 0)  - offsetStart)  / 1000;
    const endTS = ((new Date(interval.endDate)).setHours(0, 0, 0, 0)  - offsetEnd)  / 1000;

    if (endTS < startTS) {
      startTS = endTS;
      interval.startDate = interval.endDate;
    }



    // AGGIORNA GLI INTERVALLI CON EVENTUALI BUCHI
    this.reservation = this.settingsService.checkOtherReservationsAndSplitIntervals(this.reservation, startTS, endTS, interval);

    // CONTROLLA LA CONSISTENZA DELLE FASCE ORARIE
    this.getAndCheckTimeslots();

    this.updateCartEmitter.emit(this.reservation);
  }

  async removeInterval(selectedInterval) {
    let intervalIndex = this.reservation.dates.findIndex(i => i.date_key === selectedInterval.date_key);
    for (const interval of this.reservation.dates) {
      if (interval.date_key !== selectedInterval.date_key) {
        for (let ts = selectedInterval.start; ts <= selectedInterval.end; ts += 24 * 60 * 60) {
          const dateIndex = interval.disableDates.indexOf(ts);
          if (dateIndex > -1) {
            interval.disableDates.splice(dateIndex, 1);
          }
        }
      }
    }
    this.reservation.dates.splice(intervalIndex, 1);
    await this.settingsService.checkDatesIntervalsAndUpdateDisableDates(this.reservation);
  }

  scheduledAbsence(interval) {
    let intervalIndex = this.reservation.dates.findIndex(i => i.date_key === interval.date_key);
    const dialogRef = this.dialog.open(ModalDatesComponent, {
      data: {
        interval,
        type: 'absence'
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
        let reservation = JSON.parse(JSON.stringify(this.reservation));
        if (result && result.dates) {
          // console.log(result);

          this.loading = true;
          const intervals = [];
          let counter = 0;
          let start = 0;
          let end = 0;

          for (const ts of result.dates) {
            if (!start) {
              start = ts / 1000;
            }

            if (!end) {
              end = ts / 1000;
            }

            if (!(result.dates[counter + 1] && (result.dates[counter + 1] - ts ) === (24 * 60 * 60 * 1000))) {
              end = ts / 1000;

              intervals.push({
                start,
                end,
                date_key: start + '_' + end,
                length: (end - start) / (24 * 60 * 60) + 1,
                intervalAmount: 0,
                optionalsAmount: interval.optionalsAmount,
                optionalsCounter: interval.optionalsCounter,
                order: interval.order,
                disableDates: interval.disableDates,
                startDate: new Date(start * 1000),
                endDate: new Date(end * 1000)
              });

              start = 0;
              end = 0;
            }
            counter++;
          }

          reservation.dates.splice(intervalIndex, 1);

          for (const newInterval of intervals) {
            reservation.dates.push(newInterval);
          }

          this.reservation = reservation;
          console.log(reservation);
          await this.settingsService.checkDatesIntervalsAndUpdateDisableDates(this.reservation);
          this.updateCartEmitter.emit(this.reservation);
          this.loading = false;
        }
    });
  }

  map(interval, mode, slot) {
    // mode = 2  -> NEW
    // mode = 1  -> MOVE
    // mode = 0 -> ADD

    this.dataService.previousPage = 'reservation';
    this.dataService.selectedReservation = this.reservation;
    this.dataService.mainCustomer = this.mainCustomer;
    this.dataService.associatedCustomers = this.associatedCustomers;

    if (!this.reservation.id) {
      this.reservation.id = 'new';
    }

    if (interval) {
      this.router.navigate(['suite/reservation/' + this.reservation.id + '/map/' + interval.start + '/' + interval.end + '/' + mode + '/' + slot]);
    } else {
      this.router.navigate(['suite/reservation/' + this.reservation.id + '/map']);
    }
  }

  async checkInSlot(interval, slot) {
    this.loading = true;
    //await this.saveReservation(false,false,false);
    const slotKeys = [slot.slot_key];
    const httpCall = await this.dataService.checkInSlot(this.reservation.id, interval.date_key, slotKeys, this.today, this.today, this.settingsService.getCurrentUserData() )
    // RECUPERA NUOVI DATI PRENOTAZIONE - AGGIORNA
    this.manageHttpError(httpCall);
    this.getUpdatedReservationEmitter.emit();
  }

  async checkOutSlot(interval, slot) {
    this.loading = true;
    const slotKeys = [slot.slot_key];

    const httpCall = await this.dataService.checkOutSlot(this.reservation.id, interval.date_key, slotKeys, this.today, this.today, this.settingsService.getCurrentUserData() );


    this.manageHttpError(httpCall);
    this.getUpdatedReservationEmitter.emit();
  }

  quantityUp(order) {
    order.quantity += 1;

    this.updateCartEmitter.emit(this.reservation);
  }

  quantityDown(order) {
    order.quantity -= 1;

    if (order.quantity < 1) {
      order.quantity = 1;
    }


    this.updateCartEmitter.emit(this.reservation);
  }

  manageHttpError(httpCall) {

    if (httpCall.error) {

      let error = httpCall.error;

      if (httpCall.error.error) {
        error = httpCall.error.error;
      }

      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'reservation-error',
          error: error
        }
      });

      return false;
    } else {

      if (httpCall.id) {
        this.reservation.id = httpCall.id;
        this.reservation.reservationCode = httpCall.reservationCode;
      }

      this.openSnackBar("DATI SALVATI CORRETTAMENTE", "OK");
      this.clicked = false;
      return true;
    }
  }

  showPackages(interval, order, i) {

    if (!this.showPackageModal) {
      this.availablePackages = [];

      if (!this.showPackageModal) {
        for (const packId of this.mapData[order.row][order.col].packagesIds) {
          if (order.packageKey === packId) {
            this.selectedPackage = packId;
          }
          this.availablePackages.push(packId);
        }
      }

      this.showPackageModal = true;
      this.selectedPackageIndex = i + '_' + interval.start + '_' + interval.end;
    }
  }

  getAvailablePackages(order) {
    let availablePackages = [];
    if (this.mapData[order.row] && this.mapData[order.row][order.col]) {

      for (const packId of this.mapData[order.row][order.col].packagesIds) {
        if (order.packageKey === packId) {
          this.selectedPackage = packId;
        }
        availablePackages.push(packId);
      }

      if (availablePackages.length > 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  async setPackage(order, i) {
    order.packageKey = parseInt(this.savedPackageId);
    order.optionalsKeys = {};
    order.optionalsPrices = {};
    this.interval.order[i] = order;
    this.updateCartEmitter.emit(this.reservation);
    this.settingsService.setDateIntervalAvailabilityAndSlotStates(this.reservation,this.dataService.mapByRowCol);
    this.closePackageModal();
  }

  checkPackage(evt) {
    this.ngZone.run( () => {
      this.savedPackageId = evt;
    });
  }

  closePackageModal() {
    setTimeout(() => {
      this.showPackageModal = false;
      this.selectedPackageIndex = null;
    }, 100);
  }


  async freeSlot2(interval,slot) {
      const dialogRef = this.dialog.open(ModalDatesComponent, {
        data: {
          interval,
          slot,
          type: 'free'
        }
      });

      dialogRef.afterClosed().subscribe(async result => {

        if (result && result.dates) {

          let arrayTS = [];

          for (let ts of result.dates) {
              arrayTS.push(ts/1000);
          }


          let intervals = this.settingsService.getIntervalsFromSingleTs(arrayTS);

          this.loading = true;
          const slotKeys = [slot.slot_key];
          console.log(intervals);
          for (let intDates of intervals) {

            const httpCall = await this.dataService.freeSlot(this.reservation.id, interval.date_key, slotKeys, intDates.start, intDates.end, this.settingsService.getCurrentUserData(), result.notes );
            console.log(httpCall);
            this.manageHttpError(httpCall);
          }

          /* this.reservation = await this.dataService.getSingleReservation(this.reservation.id); */

          await this.settingsService.setDateIntervalAvailabilityAndSlotStates(this.reservation,this.dataService.mapByRowCol);
          this.getUpdatedReservationEmitter.emit();

        }
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  plusOptional(order, optionalId) {
    let findOrder = this.interval.order.find(o => o.slot_key === order.slot_key && order.packageKey === o.packageKey);
    if (findOrder) {
      if (!findOrder.optionalsKeys[optionalId]) {
        findOrder.optionalsKeys[optionalId] = 1;
      } else {
        findOrder.optionalsKeys[optionalId] += 1;
      }
    }

    this.updateCartEmitter.emit(this.reservation);
  }

  minusOptional(order, optionalId) {
    if (order.optionalsKeys[optionalId]) {
      let quantity = JSON.parse(JSON.stringify(order.optionalsKeys[optionalId]))
      quantity -= 1;

      if (quantity <= 0) {
        quantity = 0;
      }

      if (quantity == 0) {
        delete order.optionalsKeys[optionalId];
      } else {
        order.optionalsKeys[optionalId] = quantity;
      }

      this.updateCartEmitter.emit(this.reservation);
    }

  }

  removeOrder(interval, orderIndex) {
    interval.order.splice(orderIndex, 1);
    this.updateCartEmitter.emit(this.reservation);
  }

  checkIfTimeslotIsUnavailable(interval, timeslot) {

  }

}
