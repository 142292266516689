import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

// ROUTING
import { SettingsRoutingModule } from './settings-routing.module';

// COMPONENTS
import { SettingsAdvancedComponent } from './pages/settings-advanced/settings-advanced.component';
import { SettingsBillingComponent } from './pages/settings-billing/settings-billing.component';
import { SettingsCocobookComponent } from './pages/settings-cocobook/settings-cocobook.component';
import { SettingsPackagesComponent } from './pages/settings-packages/settings-packages.component';
import { SettingsProfileComponent } from './pages/settings-profile/settings-profile.component';
import { SettingsResortComponent } from './pages/settings-resort/settings-resort.component';
import { AvailabilitiesComponent } from './pages/booking_availabilities/availabilities.component';
import { ExtraAvailabilitiesComponent } from './pages/extra-availabilities/extra-availabilities.component';
import { ZoneDetailComponent } from './pages/settings-packages/zone-detail/zone-detail.component';
import { SeasonDetailComponent } from './pages/settings-packages/season-detail/season-detail.component';
import { PackageDetailComponent } from './pages/settings-packages/package-detail/package-detail.component';
import { ExtraDetailComponent } from './pages/settings-packages/extra-detail/extra-detail.component';
import { DiscountDetailComponent } from './pages/settings-packages/discount-detail/discount-detail.component';
import { MapDetailComponent } from './pages/settings-packages/map-detail/map-detail.component';
import { CabinDetailComponent } from './pages/settings-packages/cabin-detail/cabin-detail.component';
import { OfferDetailComponent } from './pages/settings-cocobook/offer-detail/offer-detail.component';
import { CouponDetailComponent } from './pages/settings-cocobook/coupon-detail/coupon-detail.component';
import { MapBookingComponent } from './pages/settings-cocobook/map-booking/map-booking.component';
import { MapOfferComponent } from './pages/settings-cocobook/map-offer/map-offer.component';
import { PrinterDetailComponent } from './pages/settings-advanced/printer-detail/printer-detail.component';
import { MapAreaDetailComponent } from './pages/settings-advanced/map-area-detail/map-area-detail.component';
import { ManagerDetailComponent } from './pages/settings-profile/manager-detail/manager-detail.component';
import { PriceGridComponent } from './components/price-table/price-grid.component';
import { PriceTableComponent } from './components/price-table/price-table.component';
import { AvailabilityRowComponent } from './components/availability-row/availability-row.component';
import { TileModalComponent } from './components/tile-modal/tile-modal.component';

@NgModule({
  declarations: [
    SettingsAdvancedComponent,
    SettingsBillingComponent,
    SettingsCocobookComponent,
    SettingsPackagesComponent,
    SettingsProfileComponent,
    SettingsResortComponent,
    AvailabilitiesComponent,
    ExtraAvailabilitiesComponent,
    ZoneDetailComponent,
    SeasonDetailComponent,
    PackageDetailComponent,
    ExtraDetailComponent,
    DiscountDetailComponent,
    MapDetailComponent,
    CabinDetailComponent,
    MapOfferComponent,
    MapBookingComponent,
    CouponDetailComponent,
    OfferDetailComponent,
    PrinterDetailComponent,
    MapAreaDetailComponent,
    ManagerDetailComponent,
    PriceGridComponent,
    PriceTableComponent,
    AvailabilityRowComponent,
    TileModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SettingsRoutingModule
  ]
})
export class SettingsModule { }
