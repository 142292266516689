import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {
  constructor(
      private router: Router,
      private authenticationService: AuthService,
      public afAuth: AngularFireAuth,
      private http: HttpClient,
  ) {}

  /* canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this.authenticationService.user;

      console.log(currentUser)
      if (currentUser) {
          // authorised so return true
          return true;
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
  } */

  canActivate(suite): Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.authenticationService.getCurrentUser()
      .then(async user => {
        let subscription = null;

        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const api = environment.apiURL + 'users_managers/manager_subscriptions/';
        const token = firebaseUser.token;
        const data = await this.http.get(api, { headers: {'Authorization': token}}).toPromise();

        if (data[`result`] && data[`result`].subscriptions && data[`result`].is_active) {

          data[`result`].subscriptions = data[`result`].subscriptions.sort(this.compareByEnd);
          subscription = data[`result`].subscriptions.find(l => l.active);
        }


        let nowTS = Math.trunc(new Date().getTime() / 1000);

        if (!subscription || subscription.planId === null || !subscription.active  || subscription.end < nowTS) {
          return resolve(false);
        } else {
          return resolve(true);
        }

      }, err => {
        return resolve(false);
      })
    })

  }


  compareByEnd( a: any, b: any ) {
    if ( a.end > b.end ){
      return -1;
    }
    if ( a.end < b.end ){
      return 1;
    }
    return 0;
  }

  async setExpiredLicense() {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/resort_expired_license/';

      const token = firebaseUser.token;

      const data = await this.http.put(api, {}, { headers: { Authorization : token}}).toPromise();

      return data;
    } catch(err) {
      console.log(err);
      return err;
    }
  }

}
