import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AngularFireDatabase } from '@angular/fire/database';
import { DataService } from 'src/app/core/providers/data.service';


@Component({
  selector: 'app-modal-broadcast',
  templateUrl: './modal-broadcast.component.html',
  styleUrls: ['./modal-broadcast.component.scss']
})
export class ModalBroadcastComponent implements OnInit {

  message: any;
  read:boolean = false;

  constructor(private db: AngularFireDatabase,  public dialogRef: MatDialogRef<ModalBroadcastComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dataService:DataService) {
    this.message = data;
    console.log(this.message);
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  async setAsRead() {
    await this.db.object('/resorts_messages/broadcast/receivers/'+this.dataService.userData.id).set({
      readAt: Math.trunc(new Date().getTime()/1000),
      role: 'manager'
    });
  }
}
