import { tokenName } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepickerInputEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/providers/data.service';
import { SettingsService } from 'src/app/core/providers/settings.service';

@Component({
  selector: 'app-modal-order',
  templateUrl: './modal-order.component.html',
  styleUrls: ['./modal-order.component.scss']
})
export class ModalOrderComponent implements OnInit {

  resortData; selectedPackage=null; selectedZone=null; quantity = null;
  objectKeys = Object.keys; lang = 'it'; loader = false;
  startTimestamp; endTimestamp; optionalsByIds={}; optionalsByKeys= {};
  startDate; endDate; startMonth; startYear;startSerializedDate;endSerializedDate; packages;
  quantities = [1,2,3,4,5,6,7,8,9,10];selectedTimeslot=0; timeslots = [];intervalTimeSlots=[];
  noPackageAvailable = false; availabilities; residual = 0;noSlotsPackages;
  reservation;
  today = new Date().getTime()/1000;

  constructor(public settingsService:SettingsService, public router: Router, public dataService: DataService, public dialogRef: MatDialogRef<ModalOrderComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      console.log(data);
      this.resortData = this.dataService.resortData;
      // this.lang = this.resortData.lang;

      this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
      this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
      this.reservation = this.data.reservation;
      if (this.reservation && !this.reservation.id) {
        this.startTimestamp = data.reservation.start*1000;
        this.endTimestamp = data.reservation.start*1000;
      }

      const startDate = new Date(this.startTimestamp);
      startDate.setUTCHours(0, 0, 0, 0);
      this.startSerializedDate = startDate;

      const endDate = new Date(this.endTimestamp);
      endDate.setUTCHours(0, 0, 0, 0);
      this.endSerializedDate = endDate;

  }

  async ngOnInit() {
    await this.initPackages();
  }

  async initPackages() {
    this.getTimeslots();
    this.packages = [];
    this.noSlotsPackages = this.resortData.packages.filter((p)=> {
      if (p.type === 'order') {
        return p;
      }
    })

    this.availabilities = await this.dataService.getAvailabilitiesV2(this.startTimestamp/1000,this.endTimestamp/1000);
    this.resetData();
    this.checkAvailability();

  }

  resetData() {

    this.selectedTimeslot = 0;
    this.selectedPackage = null;
    this.selectedZone = null;
    this.quantity = null;
    this.packages = JSON.parse(JSON.stringify(this.noSlotsPackages));
    this.noPackageAvailable = true;
  }

  checkAvailability() {
    this.quantity = null;
    for (let pack of this.packages) {
      pack.residual = 1000000;
      pack.available = true;
      let packAvailabilities = this.availabilities.results.filter((p)=> {
        if (p.packageId === pack.id) {
          return p;
        }
      })
      /* if (packAvailabilities.length && this.selectedZone) { */
        for (let ts = this.startTimestamp/1000; ts <= this.endTimestamp/1000; ts+=24*60*60) {

          if (ts > (this.today - 24*60*60) && packAvailabilities.length && this.selectedZone) {
            let timeSlotId = null;
            if (this.selectedTimeslot) {
              timeSlotId = this.selectedTimeslot;
            }

            let tsAva = packAvailabilities.find(p => p.day_timestamp === ts && p.zoneId === this.selectedZone && p.timeSlotId === timeSlotId);

            if (tsAva) {
              if (tsAva.availability < pack.residual) {
                pack.residual = tsAva.availability;
              }
            } else {
              pack.residual = 0;
              break;
            }
          } else if (ts > (this.today - 24*60*60) && !packAvailabilities.length){
            pack.available = false;
            break;
          }
        /* } */
      }
      if (pack.available) {
        this.noPackageAvailable = false;
      }

    }

    if (this.selectedPackage) {
      let findPack = this.packages.find(p => p.id === this.selectedPackage);

      if (findPack)
        this.residual = findPack.residual;
    }

    // SCORPORA DA RESIDUAL I PACCHETTI ALL'INTERNO DELLA PRENOTAZIONE NON ANCORA SALVATI CHE HANNO STESSE DIMENSIONI.
    // PER OGNI GIORNO SELEZIONATO CONTROLLA SE ESISTONO DEGLI INTERVALLI CHE COPRONO QUEL GG
    // E SCALA LA QUANTITA' MAGGIORE TRA TUTTI GLI INTERVALLI SU QUEL PACCHETTO ZONA TIMESLOT
    let maxCounter = 0;

    for (let ts = this.startTimestamp/1000; ts <= this.endTimestamp/1000; ts+=24*60*60) {
      let intervals = this.reservation.dates.filter(i => (i.start <= ts && i.end >= ts))

      console.log("INTERVALS ", intervals);
      let dailyCounter = 0;

      for (let interval of intervals) {
        for (let order of interval.order) {
          let productAlreadySaved = this.reservation.order.products.find(p => {
            let sameTimeslot = ((p.timeSlots && p.timeSlots.length === 0 && this.selectedTimeslot === 0) || (p.timeSlots && p.timeSlots.length && p.timeSlots[0].id === this.selectedTimeslot))
            if (p.id && p.type === 'package' && p.packageId === this.selectedPackage && p.zoneId === this.selectedZone && p.startDate === interval.start && p.endDate === interval.end && sameTimeslot) {
              return p;
            } else {
              return null;
            }
          } );
          if (!productAlreadySaved && order.type === 'order' && order.packageKey === this.selectedPackage && order.zoneKey === this.selectedZone && (interval.timeSlots.length === 0 || interval.timeSlots[0].id === this.selectedTimeslot)) {

            dailyCounter+= order.quantity;
            break;
          }
        }

      }

      if (dailyCounter > maxCounter) {
        maxCounter = dailyCounter;
      }

      //packAvailabilities.find(p => p.day_timestamp === ts && p.zoneId === this.selectedZone && p.timeSlotId === timeSlotId);
    }

    if (this.residual > 0)
      this.residual-= maxCounter;


    console.log(this.packages);
    console.log(this.selectedPackage)

  }

  close(confirm): void {
    if (confirm) {
      console.log(this.intervalTimeSlots);
      const obj = {
        packageId: this.selectedPackage,
        zoneId: this.selectedZone,
        quantity: this.quantity,
        timeSlot: this.intervalTimeSlots,
        start: this.startTimestamp/1000,
        end: this.endTimestamp/1000,
        type: 'add',
        reservation:null
      };

      let findSamePackCombo = null;
      let max = 1;
      for (let date of this.data.reservation.dates) {
        console.log(date.timeSlots, obj.timeSlot);
        let findSameTimeslot = (date.timeSlots.length === 0 && obj.timeSlot.length === 0) || (date.timeSlots.length && obj.timeSlot.length && date.timeSlots[0].id === obj.timeSlot[0].id);

        if (date.start === obj.start && date.end === obj.end && findSameTimeslot) {
          for (let order of date.order) {
            if (order.type === 'order' && order.packageKey === this.selectedPackage && order.zoneKey === this.selectedZone) {
              max++;
              findSamePackCombo = true;
              order.quantity+=this.quantity;
            }
          }
        }
      }



      if(findSamePackCombo) {
        obj.type = 'update';
        obj.reservation = this.data.reservation;
      } 

      console.log(obj);

      this.dialogRef.close(obj);
    } else {
      this.dialogRef.close(null);
    }

  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp)
      this.endTimestamp = this.startTimestamp;

    let endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);

    this.availabilities = await this.dataService.getAvailabilitiesV2(this.startTimestamp/1000,this.endTimestamp/1000);
    this.resetData();
    this.checkAvailability();
    //let setReservations = await this.getReservations();

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.loader = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.startTimestamp = this.endTimestamp;
    }

    let startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    this.availabilities = await this.dataService.getAvailabilitiesV2(this.startTimestamp/1000,this.endTimestamp/1000);
    this.resetData();
    this.checkAvailability();
    //let setReservations = await this.getReservations();
  }

  async setTodayDate() {
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    let startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startDate = startDate.getDate();

    let endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endDate = endDate.getDate();

    this.startSerializedDate = new Date(this.startTimestamp);
    this.endSerializedDate = new Date(this.endTimestamp);

    //let setReservations = await this.getReservations();

    this.availabilities = await this.dataService.getAvailabilitiesV2(this.startTimestamp/1000,this.endTimestamp/1000);
    this.resetData();
    this.checkAvailability();
  }

  updateTimeslot() {
    console.log(this.selectedTimeslot,typeof this.selectedTimeslot, this.timeslots);
    this.intervalTimeSlots = []
    let now = new Date();
    let offset = now.getTimezoneOffset() * 60;

    if (this.selectedTimeslot) {
      let findTS = this.timeslots.find(t => t.id === this.selectedTimeslot);
      if (findTS) {
        console.log(findTS);
        let offset = now.getTimezoneOffset();
        let startSeconds = parseInt(findTS.startHour.split(":")[0])*60*60 + parseInt(findTS.startHour.split(":")[1])*60 + offset*60;
        let endSeconds = parseInt(findTS.endHour.split(":")[0])*60*60 + parseInt(findTS.endHour.split(":")[1])*60 + offset*60;
        findTS.startSeconds = startSeconds;
        findTS.endSeconds = endSeconds;
        findTS.utcOffset = -offset/60;
        this.intervalTimeSlots.push(findTS);
      }
    } else {
    }

    this.checkAvailability();
  }

  getTimeslots() {
    let length = (this.endTimestamp/1000 - this.startTimestamp/1000)/(24*60*60) + 1;
    let seasonsFound = this.dataService.getSeasonsInReservation(this.startTimestamp/1000, length,null);
    console.log(seasonsFound);
    let daysInInterval = this.settingsService.getDaysFromInterval(this.startTimestamp/1000, this.endTimestamp/1000);
    console.log(daysInInterval)
    if (seasonsFound && Object.keys(seasonsFound).length === 1) {
      for (let seasonKey in seasonsFound) {

        if (this.settingsService.checkIfTimeslotsAreEnabled(seasonsFound[seasonKey], daysInInterval)) {
          this.timeslots = this.resortData.timeSlotsBySeason[seasonKey];
        } else {
          this.timeslots = [];
        }
      }
    }

    if (this.intervalTimeSlots && this.intervalTimeSlots.length) {
      let selectedTimeslot = this.intervalTimeSlots[0];
      if (this.timeslots.find(t => t.id === selectedTimeslot.id)) {
      } else {
        this.timeslots.push(selectedTimeslot);
      }

      this.selectedTimeslot = selectedTimeslot.id;
    }

    console.log(this.timeslots);
  }

}
