import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service'
import { SettingsService } from '../../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { ModalErrorComponent } from '../../../../shared/components/modal-error/modal-error.component';
import { finalize } from 'rxjs/operators';
import { ModalConfirmComponent } from '../../../../shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
  styleUrls: ['./package-detail.component.scss']
})
export class PackageDetailComponent implements OnInit {

  resortData; managerData; pageClicked; prices; packageId; lang = 'it'; clicked = false; loading = false;
  selectedPackage; optionals; dataLang='it'; rule1=null; elements; features;services; rules;
  objectKeys = Object.keys; uploadPercents = []; uploadPercentNew = null; allPrices; showLanguageModal1=false; showLanguageModal2=false;
  coverIcons = []; elementsFlag = false; showTip = false; showTipRemove = false;

  constructor(private storage: AngularFireStorage,public router: Router, public dialog: MatDialog,private route: ActivatedRoute, public dataService: DataService, private settingsService: SettingsService) {
      this.resortData = this.dataService.resortData;
      this.managerData = this.dataService.userData;
      this.prices = this.dataService.prices;
      this.optionals = this.dataService.optionals;
      this.elements = this.dataService.elements;
      this.features =  this.dataService.features;
      this.rules = this.dataService.rules;
      this.services =  this.dataService.packagesServices;
      this.dataLang = this.dataService.lang;
  }

  async ngOnInit() {

    this.loading = true;
    if (!this.resortData) {
      await this.dataService.initResortData();
    } else {
      //[this.prices, this.resortData] = await Promise.all([this.dataService.getPrices(), this.dataService.getResortData()]);
    }

    this.resortData = this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.prices =  this.dataService.prices;
    this.rules = this.dataService.rules;

    this.optionals =  this.dataService.optionals;
    this.elements =  this.dataService.elements;
    this.features =  this.dataService.features;
    this.services =  this.dataService.packagesServices;

    this.loading = false;
    this.initPackage();
  }

  initPackage() {
    this.packageId = this.route.snapshot.paramMap.get('packageId');
    let type = this.route.snapshot.paramMap.get('type');

    if (this.resortData.packagesByIds[this.packageId]) {
      this.selectedPackage = JSON.parse(JSON.stringify(this.resortData.packagesByIds[this.packageId]));
      console.log("PACKAGE");
      console.log(JSON.parse(JSON.stringify(this.resortData.packagesByIds[this.packageId])));

      if (!this.selectedPackage.description) {
        this.selectedPackage.description = JSON.parse(JSON.stringify(this.dataService.languages));
      }

      if (!this.selectedPackage.type) {
        this.selectedPackage.type = 'slot';
      }

      if (!this.selectedPackage.bookingDailyPrice) {
        this.selectedPackage.bookingDailyPrice = false;
      }

      this.elements.map(ele =>{
        ele.quantity = 0;
        if (this.selectedPackage.elements.find(e => e.element.id === ele.id)) {
          const found = this.selectedPackage.elements.find(e => e.element.id === ele.id);
          ele.quantity = 1;
          if (found.quantity) {
            ele.quantity = found.quantity;
          }
        }
      });

      this.features.map(feature =>{
        feature.selected = false;
        if (this.selectedPackage.package_features.find(e => e.id === feature.id)) {
          feature.selected = true;
        }
      });

      this.services.map(service =>{
        service.selected = false;
        if (this.selectedPackage.package_services.find(e => e.id === service.id)) {
          service.selected = true;
        }
      });


      this.optionals.map(optional =>{
        optional.selected = false;
        if (this.selectedPackage.optionals && this.selectedPackage.optionals.find(e => e.optional.id === optional.id)) {
          optional.selected = true;
        }
      });

      if (this.selectedPackage.rules[0] && this.selectedPackage.rules[0].rule.id == 1) {
        this.rule1 = this.selectedPackage.rules[0].value;
      }

      this.elementsFlag = true;

    } else {

      if (!type) {
        type = 'slot';
      }
      this.packageId = null;
      this.selectedPackage = {
        cocoBooking: false,
        default: false,
        bookingDailyPrice: false,
        description: JSON.parse(JSON.stringify(this.dataService.languages)),
        name: JSON.parse(JSON.stringify(this.dataService.languages)),
        id: null,
        lastMinuteTime: '13:30',
        map: false,
        package_features: [],
        package_services: [],
        images: [],
        icon: null,
        resort: this.resortData.id,
        rules: [],
        seats: 1,
        elements: [],
        type:type
      };

      this.elements.map(ele =>{
        ele.quantity = 0;
      });

      this.features.map(feature =>{
        feature.selected = false;
      });

      this.services.map(service =>{
        service.selected = false;
      });

      this.optionals.map(optional =>{
        optional.selected = false;
      });
    }

    if (this.managerData && !this.managerData.suite) {
      this.selectedPackage.type = 'order';
    }

    if (!this.selectedPackage.bookingSpecialSeasons) {
      this.selectedPackage.bookingSpecialSeasons = {};
    }

    for (const season of this.resortData.seasonsPackages) {
      if (season.special) {
        if (!this.selectedPackage.bookingSpecialSeasons[season.id]) {
          this.selectedPackage.bookingSpecialSeasons[season.id] = false;
        }
      }
    }

    if (!this.selectedPackage.lastMinuteTime) {
      this.selectedPackage.lastMinuteTime = '13:30';
      this.selectedPackage.lastMinute = false;
    }


    this.resortData.zones.map(zone => {

      if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + zone.id +  '_0_0']) {
        this.setPriceZero(this.selectedPackage.id, zone.id, 0, 0, null);
      }

      if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + zone.id +  '_0_1']) {
        this.setPriceZero(this.selectedPackage.id, zone.id, 0, 1, null);
      }

      if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + zone.id + '_0_2']) {
        this.setPriceZero(this.selectedPackage.id, zone.id, 0, 2, null);
      }
      this.resortData.seasonsPackages.map(season => {

        if (season.special === true) {
          if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + zone.id +  '_' + season.id +'_0']) {
            this.setPriceZero(this.selectedPackage.id, zone.id, season.id, 0, null);
          }

          if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + zone.id +  '_' + season.id +'_1']) {
            this.setPriceZero(this.selectedPackage.id, zone.id, season.id, 1, null);
          }

          if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id +'_2']) {
            this.setPriceZero(this.selectedPackage.id, zone.id, season.id, 2, null);
          }
        }

        if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_0']) {
          this.setPriceZero(this.selectedPackage.id, zone.id, season.id, 0, null);
        }

        this.optionals.map(opt =>{

          if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + opt.id + '_' + zone.id +  '_0_0']) {
            this.setPriceZero(this.selectedPackage.id, zone.id, 0, 0, opt.id);
          }

          if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + opt.id + '_' + zone.id +  '_0_1']) {
            this.setPriceZero(this.selectedPackage.id, zone.id, 0, 1, opt.id);
          }

          if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + opt.id + '_' + zone.id + '_0_2']) {
            this.setPriceZero(this.selectedPackage.id, zone.id, 0, 2, opt.id);
          }

          if (season.special === true) {

            if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + opt.id + '_' + zone.id + season.id + '_0']) {
              this.setPriceZero(this.selectedPackage.id, zone.id, season.id, 0, opt.id);
            }

            if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + opt.id + '_' + zone.id + season.id + '_1']) {
              this.setPriceZero(this.selectedPackage.id, zone.id, season.id, 1, opt.id);
            }

            if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + opt.id + '_' + zone.id + season.id + '_2']) {
              this.setPriceZero(this.selectedPackage.id, zone.id, season.id, 2, opt.id);
            }
          }

          if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + opt.id + '_' + zone.id + '_' + season.id+'_0']) {
            this.setPriceZero(this.selectedPackage.id, zone.id, season.id, 0, opt.id);
          }
        });

        if (this.resortData.timeSlotsBySeason[season.id].length) {
          for (let timeslot of this.resortData.timeSlotsBySeason[season.id]) {
            if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_'+ timeslot.id]) {
              this.setPriceZero(this.selectedPackage.id, zone.id, season.id, timeslot.id,null);
            }

            this.optionals.map(opt =>{

              if (!this.selectedPackage.id || !this.prices[this.selectedPackage.id + '_' + opt.id + '_' + zone.id + '_' + season.id+'_'+ timeslot.id]) {
                this.setPriceZero(this.selectedPackage.id, zone.id, season.id, timeslot.id, opt.id);
              }
            })
          }
        }
      });
    });

    console.log(this.selectedPackage);
    //console.log(this.prices);

    this.uploadPercents = [];
    for (const image of this.selectedPackage.images) {
      this.uploadPercents.push(0);
    }

    for (const e of this.elements) {
        this.coverIcons.push(e.icon);
    }

  }


  setPriceZero(packId, zoneId, seasonId, timeslotId, optId) {
    if (!optId) {
      this.prices[packId + '_' + zoneId + '_'+seasonId+'_'+timeslotId] = {
        seasonal: 0,
        daily: 0,
        lastMinute: 0,
        weekend: 0,
        weekly: 0,
        biweekly: 0,
        triweekly: 0,
        monthly: 0,
        specialSeason: 0,
        type:'package'
      }
    } else {
      this.prices[packId + '_' + optId + '_' + zoneId + '_'+seasonId+'_'+timeslotId] = {
        seasonal: 0,
        daily: 0,
        lastMinute: 0,
        weekend: 0,
        weekly: 0,
        biweekly: 0,
        triweekly: 0,
        monthly: 0,
        specialSeason: 0,
        type:'package'
      }
    }
  }



  resetHeader() {
    this.pageClicked = true;
    setTimeout( () => {
      this.pageClicked = false;
    }, 1000);
  }

  async savePackage() {
    this.selectedPackage.elementsAvailabilities = [];
    this.selectedPackage.packageFeaturesAvailabilities = [];
    this.selectedPackage.packageServicesAvailabilities = [];
    this.selectedPackage.rulesAvailabilities = [];
    this.selectedPackage.optionalsAvailabilities = [];
    this.selectedPackage.pricesAvailabilities = [];
    this.selectedPackage.optionals = [];
    this.optionals.map(ele =>{
      if (ele.selected) {
        let pricesAvailabilities = [];

        this.resortData.zones.map(zone => {
          this.resortData.seasonsPackages.map(season => {
            const priceObj = {
              season_id: season.id,
              zone_id: zone.id,
              optional_id: ele.id,
              weekly: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_0'].weekly,
              biweekly: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_0'].biweekly,
              triweekly: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_0'].triweekly,
              daily: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_0'].daily,
              monthly: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_0'].monthly,
              weekend: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_0'].weekend,
              lastMinute: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_0'].lastMinute,
              specialSeason: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_0'].specialSeason,
              version: 4
            }
            pricesAvailabilities.push(priceObj);

            if (season.special) {
              const priceObj1 = {
                season_id: season.id,
                optional_id: ele.id,
                zone_id: zone.id,
                timeSlot_id: 1,
                specialSeason: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_1'].specialSeason,
                version: 4
              }
              pricesAvailabilities.push(priceObj1);


              const priceObj2 = {
                season_id: season.id,
                optional_id: ele.id,
                zone_id: zone.id,
                timeSlot_id: 2,
                specialSeason: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_2'].specialSeason,
                version: 4
              }
              pricesAvailabilities.push(priceObj2);

            }

            if (this.resortData.timeSlotsBySeason[season.id].length) {
              for (let timeslot of this.resortData.timeSlotsBySeason[season.id]) {

                const priceObj = {
                  season_id: season.id,
                  zone_id: zone.id,
                  optional_id: ele.id,
                  timeSlot_id: timeslot.id,
                  weekly: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].weekly,
                  biweekly: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].biweekly,
                  triweekly: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].triweekly,
                  daily: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].daily,
                  monthly: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].monthly,
                  weekend: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].weekend,
                  lastMinute: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].lastMinute,
                  specialSeason: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].specialSeason,
                  version: 4
                }
                pricesAvailabilities.push(priceObj);

              }
            }
          });

          let priceObj1 = {
            season_id: 0,
            timeSlot_id: 0,
            zone_id: zone.id,
            seasonal: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_0_0'].seasonal,
            version: 4
          };

          pricesAvailabilities.push(priceObj1);


          let priceObj2  = {
            season_id: 0,
            timeSlot_id: 1,
            zone_id: zone.id,
            seasonal: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_0_1'].seasonal,
            version: 4
          };

          pricesAvailabilities.push(priceObj2);


          let priceObj3 = {
            season_id: 0,
            timeSlot_id: 2,
            zone_id: zone.id,
            seasonal: this.prices[this.selectedPackage.id + '_' + ele.id + '_' + zone.id + '_0_2'].seasonal,
            version: 4
          };

          pricesAvailabilities.push(priceObj3);
        });

        this.selectedPackage.optionalsAvailabilities.push({
          optionalAvailable : { optional: ele.id },
          pricesAvailables : pricesAvailabilities
        });

        this.selectedPackage.optionals.push({
          optional: ele
        });

      }
    });

    this.resortData.zones.map(zone => {
      this.resortData.seasonsPackages.map(season => {
        const priceObj = {
          season_id: season.id,
          zone_id: zone.id,
          weekly: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_0'].weekly,
          biweekly: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_0'].biweekly,
          triweekly: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_0'].triweekly,
          daily: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_0'].daily,
          monthly: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_0'].monthly,
          weekend: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_0'].weekend,
          lastMinute: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_0'].lastMinute,
          specialSeason: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_0'].specialSeason,
          version: 4
        }
        this.selectedPackage.pricesAvailabilities.push(priceObj);

        if (season.special) {
          const priceObj1 = {
            season_id: season.id,
            zone_id: zone.id,
            timeSlot_id: 1,
            specialSeason: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_1'].specialSeason,
            version: 4
          }
          this.selectedPackage.pricesAvailabilities.push(priceObj1);


          const priceObj2 = {
            season_id: season.id,
            zone_id: zone.id,
            timeSlot_id: 2,
            specialSeason: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_2'].specialSeason,
            version: 4
          }
          this.selectedPackage.pricesAvailabilities.push(priceObj2);

        }

        if (this.resortData.timeSlotsBySeason[season.id].length) {
          for (let timeslot of this.resortData.timeSlotsBySeason[season.id]) {
            const priceObj = {
              season_id: season.id,
              zone_id: zone.id,
              timeSlot_id: timeslot.id,
              weekly: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].weekly,
              biweekly: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].biweekly,
              triweekly: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].triweekly,
              daily: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].daily,
              monthly: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].monthly,
              weekend: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].weekend,
              lastMinute: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].lastMinute,
              specialSeason: this.prices[this.selectedPackage.id + '_' + zone.id + '_' + season.id+'_'+timeslot.id].specialSeason,
              version: 4
            }
            this.selectedPackage.pricesAvailabilities.push(priceObj);

          }
        }

      });

      let priceObj1 = {
        season_id: 0,
        timeSlot_id: 0,
        zone_id: zone.id,
        seasonal: this.prices[this.selectedPackage.id + '_' + zone.id + '_0_0'].seasonal,
        version: 4
      };

      this.selectedPackage.pricesAvailabilities.push(priceObj1);

      let priceObj2 = {
        season_id: 0,
        timeSlot_id: 1,
        zone_id: zone.id,
        seasonal: this.prices[this.selectedPackage.id + '_' + zone.id + '_0_1'].seasonal,
        version: 4
      };

      this.selectedPackage.pricesAvailabilities.push(priceObj2);

      let priceObj3 = {
        season_id: 0,
        timeSlot_id: 2,
        zone_id: zone.id,
        seasonal: this.prices[this.selectedPackage.id + '_' + zone.id + '_0_2'].seasonal,
        version: 4
      };

      this.selectedPackage.pricesAvailabilities.push(priceObj3);
    });


    this.selectedPackage.package_services = [];
    this.selectedPackage.package_features = [];
    this.selectedPackage.elements = [];

    this.elements.map(ele =>{

      if (ele.quantity) {
        this.selectedPackage.elements.push(
          {
            element:ele,
            quantity:ele.quantity
          }
        )
        this.selectedPackage.elementsAvailabilities.push({
          element : ele.id,
          quantity : ele.quantity
        });

       /*  if (ele.cover) {
          this.selectedPackage.icon = ele.icon;
        } */
      }

    });

    this.services.map(ele => {
      if (ele.selected) {
        this.selectedPackage.package_services.push(ele);
        this.selectedPackage.packageServicesAvailabilities.push({
          package_service : ele.id
        });
      }
    });

    this.features.map(ele => {
      if (ele.selected) {
        this.selectedPackage.package_features.push(ele);
        this.selectedPackage.packageFeaturesAvailabilities.push({
          package_feature : ele.id
        });
      }
    });

    if (this.rule1 !== null) {

      if (!this.selectedPackage.rules || !this.selectedPackage.rules[0]) {
        this.selectedPackage.rules = [];
        this.selectedPackage.rules.push(this.rules[0])
      }


      this.selectedPackage.rules[0].value = this.rule1;
      this.selectedPackage.rulesAvailabilities.push({
        rule : 1,
        value : this.rule1
      });
    } else {
      this.selectedPackage.rules = [];
    }

    if (this.selectedPackage.type === 'order') {
      this.selectedPackage.map = false;
    }

    const packageToSave = JSON.parse(JSON.stringify(this.selectedPackage));
    /* delete packageToSave.package_features;
    delete packageToSave.package_services;
    delete packageToSave.rules;
    delete packageToSave.elements; */

    let response = null;

    if (this.selectedPackage.id) {
      this.resortData.packagesByIds[this.packageId] = this.selectedPackage;
      const foundIndex = this.resortData.packages.findIndex(disc => disc.id === this.selectedPackage.id);
      this.resortData.packages[foundIndex] = this.selectedPackage;
      response = await this.dataService.updatePackage(packageToSave);
    } else {
      response = await this.dataService.createPackage(packageToSave);
    }

    if (response.status && response.status != 200) {

      let dialogRef = this.dialog.open(ModalConfirmComponent, {
        width: '340px',
        height: '110px',
        data: {
          error: response.error
        }
      });

      dialogRef.afterClosed().subscribe(result => {
            console.log(result);
      });
    } else if (!this.selectedPackage.id) {
      packageToSave.id = response.id;
      this.resortData.packages.push(packageToSave);
      this.resortData.packagesByIds[packageToSave.id] = packageToSave;
      this.router.navigate(['settings/offer/4']);
    } else {

      this.router.navigate(['settings/offer/4']);
    }

  }

  plusElements(element) {
    element.quantity+=1;

    let eleIcon = null;

    this.elements.map(ele =>{

      if (ele.quantity) {
        if (!eleIcon) {
            eleIcon = ele.icon;
        }

        if (ele.cover) {
          eleIcon = ele.icon;
        }
      }
    });

    if (!this.selectedPackage.icon) {
      this.selectedPackage.icon = eleIcon;
    }

    this.elementsFlag = true;
  }

  async removePackage() {
    this.loading = true;
    const data = await this.dataService.getReservations(null, null, null, null, null, null,null, this.selectedPackage.id, null, null, null, null, null, null, null, 1, 1, true,null,null, null, null, null, null , null, null,null,null,null);

    this.loading = false;
    if (data.count) {
      let dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'remove-error',
          error: 'reservations'
        }
      })

      const self = this;

      dialogRef.afterClosed().subscribe(result => {
            self.clicked = false;
      });

    } else {

      let dialogRef = this.dialog.open(ModalRemoveComponent, {
        width: '340px',
        height: '130px',
        data: {
          type: "packages",
          package: this.selectedPackage
        }
      });

      dialogRef.afterClosed().subscribe(result => {
            this.clicked = false;
      });
    }
    /*  */
  }

  minusElements(element) {

    element.quantity-=1;

    if (element.quantity <= 0) {
      element.quantity = 0;
    }


    this.elementsFlag = false;

    this.elements.map(ele =>{
      if (ele.quantity) {
        this.elementsFlag = true
      }
    });

  }

  moveLeft(index) {
    this.insertAndShift(this.selectedPackage.images,index,index-1);
  }

  moveRight(index) {
    this.insertAndShift(this.selectedPackage.images,index,index+1);
  }

  insertAndShift(arr, from, to) {
      const cutOut = arr.splice(from, 1) [0]; // cut the element at index 'from'
      arr.splice(to, 0, cutOut);            // insert it at index 'to'
  }

  async uploadFile(event,index) {
    const file = event.target.files[0];

    if (file) {
      const filePath = 'resorts/' + this.dataService.resortData.id + '/packages/' + file.name;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      // observe percentage changes
      if (index >=0 ) {
        this.uploadPercents[index] = task.percentageChanges();
      } else {
        this.uploadPercentNew = task.percentageChanges();
      }
      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(async () => {

            if (index >=0) {
              this.selectedPackage.images[index] = await fileRef.getDownloadURL().toPromise();
            } else {
              this.uploadPercentNew = null;
              this.selectedPackage.images.push(await fileRef.getDownloadURL().toPromise());
            }

            this.uploadPercents = [];
            for (const image of this.selectedPackage.images) {
              this.uploadPercents.push(0);
            }
          })
       )
      .subscribe();
    }
  }

  removeImage(index) {
    this.selectedPackage.images.splice(index,1);
    this.uploadPercents = [];
    for (const image of this.selectedPackage.images) {
      this.uploadPercents.push(0);
    }
  }
}
