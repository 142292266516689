import { Injectable } from '@angular/core';
import { DataService, Customer } from './data.service';
import { MatSnackBar } from '@angular/material';
declare var epson: any;

@Injectable({
  providedIn: 'root'
})
export class PrintersService {

  printed;

  constructor(public snackBar: MatSnackBar, public dataService: DataService) { }

  async epsonFiscalClosing(printer) {
    const epos = new epson.fiscalPrint();

    const fiscalData = `<printerFiscalReport>
    <printXZReport  operator="1" />
    </printerFiscalReport>`;

    const self = this;

    epos.send('https://' + printer.ip + '/cgi-bin/fpmate.cgi', fiscalData, 60000);
    return new Promise((resolve, reject)=> {
      epos.onreceive = (res, tag_list_names, add_info, res_add) => {
        console.log(res, tag_list_names, add_info, res_add);
        self.printed = true;
        resolve(res);
      };


      epos.onerror = (err) =>{
        console.log(err);
        reject(err)
      }
    })
  }

  async epsonFiscalPrintTest(printer, xml) {

    let fiscalData = xml;

    console.log(fiscalData);

    const epos = new epson.fiscalPrint();
    const self = this;

    epos.send('https://' + printer.ip + '/cgi-bin/fpmate.cgi', fiscalData, 60000);
    return new Promise((resolve, reject)=> {
      epos.onreceive = (res, tag_list_names, add_info, res_add) => {
        console.log(res, tag_list_names, add_info, res_add);
        self.printed = true;
        resolve(res);
      };

      epos.onerror = (err) =>{
        console.log(err);
        reject(err)
      }
    })
  }

  async customFiscalClosing(printer) {
    const fiscalData = `<?xml version="1.0" encoding="utf-8"?><printZReport description=""></printZReport>`;
    return this.customFiscalPrintExec(printer,fiscalData);
  }


  async customFiscalPrintExec(printer, xml) {

    let fiscalData = xml;

    console.log(fiscalData);


    let headers = new Headers();
    // I documenti XML vengono trattati come text/plain
    headers.append('Content-Type', 'text/plain');
    // Autorizzazione con Username e Password. Username e Password corrispondono sempre alla matricola della macchina
    headers.append('authorization', 'Basic ' + btoa(printer.fiscalID + ':' + printer.fiscalID));

    const fetchURL = 'http://' + printer.ip + '/xml/printer.htm';
    console.log(fetchURL);
    console.log(printer.fiscalID);
    // Utilizzare metodo POST
    const init = {
        method: 'POST',
        headers,
        body: xml
    };

    return new Promise((resolve, reject)=> {
      fetch(fetchURL, init)
        .then(response => {
            console.log(response.text());
            resolve(response);
        })
        .then(body => {
            // body è il corpo della risposta
            console.log('Risposta:' + body);
        })
        .catch(err => {
            console.log(err);
            reject(err);
        });
    })
  }

  async rchFiscalPrintExec(printer, xml) {

    let fiscalData = xml;

    console.log(fiscalData);


    let headers = new Headers();
    // I documenti XML vengono trattati come text/plain
    headers.append('Content-Type', 'application/xml');

    const fetchURL = 'http://' + printer.ip + '/service.cgi';
    // Utilizzare metodo POST
    const init:RequestInit = {
        method: 'POST',
        headers,
        body: xml,
    };

    return new Promise((resolve, reject)=> {
      fetch(fetchURL, init)
        .then(response => {
            console.log(response.text());
            resolve(response);
        })
        .then(body => {
            // body è il corpo della risposta
            console.log('Risposta:' + body);
        })
        .catch(err => {
            console.log(err);
            reject(err);
        });
    })
  }

  async customFiscalPrint(reservation,printer, paymentType) {

    console.log(paymentType);
    let fiscalData = this.getCustomXMLfiscalData(reservation, paymentType, printer);
    return this.customFiscalPrintExec(printer,fiscalData);
  }

  async rchFiscalPrint(reservation,printer,paymentType) {
    let fiscalData = this.getRCHxmlFiscalData(reservation,paymentType,printer);
    return this.rchFiscalPrintExec(printer,fiscalData);
  }

  async rchFiscalPrintSingle(reservation,printer, paymentType, bill) {

    let department = '1';
    let paymentCode = '0';
    let paymentDescription = 'Contanti';
    
    if (printer.department) {
      department = printer.department
    }

    if (paymentType == 'cash') {
      paymentCode= '1';
      paymentDescription = 'Contanti';
    } else if (paymentType == 'cheque') {
      paymentCode= '2';
      paymentDescription = 'Assegno';
    } else if (paymentType == 'credit-card') {
      paymentCode= '3';
      paymentDescription = 'Carta di credito';
    } else if (paymentType == 'coupon') {
      paymentCode= '5';
      paymentDescription = 'Coupon';
    } else {
      paymentCode= '4';
      paymentDescription = 'Pagamento Generico';
    }

    let printerText = `<cmd>=K</cmd>`;

   /*  let printerText = `
      <cmd>=R`+department+`/$200/(DOLCE)</cmd>
      <cmd>=%-/*12.15/(SCONTO EXTRA)</cmd>
    `+paymentCode; */

    printerText += '<cmd>="/?A/(COD. PRENOTAZIONE: '+reservation.reservationCode+')</cmd>';
    if (reservation.name != '') {
      printerText += '<cmd>="/?A/('+reservation.name+')</cmd>'; 
    }

    let billName = bill.name.substring(0, 38);
    billName = billName.quantity + ' x '+billName;
    printerText+='<cmd>=R'+department+'/$' + (Math.round(bill.price*100)) + '/*1/(' + billName+')</cmd>';

    printerText+=' <cmd>=S</cmd> ';
    let fiscalData = `
                        <?xml version="1.0" encoding="UTF-8"?>
                        <Service>`
                              + printerText +
                        `<cmd>=T`+paymentCode+`</cmd></Service>`;

    console.log(fiscalData);

    return this.rchFiscalPrintExec(printer,fiscalData);


  }

  async customFiscalPrintSingle(reservation,printer, paymentType, bill) {

    let paymentCode = '0';
    let paymentDescription = 'Contanti';

    if (paymentType == 'cash') {
      paymentCode= '1';
      paymentDescription = 'Contanti';
    } else if (paymentType == 'cheque') {
      paymentCode= '2';
      paymentDescription = 'Assegno';
    } else if (paymentType == 'credit-card') {
      paymentCode= '3';
      paymentDescription = 'Carta di credito';
    } else if (paymentType == 'coupon') {
      paymentCode= '5';
      paymentDescription = 'Coupon';
    } else {
      paymentCode= '4';
      paymentDescription = 'Pagamento Generico';
    }

    let printerText = '';

    reservation.fiscalInfo = true;

    let department = '1';

    // 0 = Cash
    // 1 = Check
    // 2 = Credit/Credit Card
    // 3 = Ticket
    // 4 = Foreign Currency


    if (printer.department) {
      department = printer.department
    }

    let lastDate = null;

    let billName = bill.name.substring(0, 38);
    billName = billName.quantity + ' x '+billName;

    printerText += '<printRecMessage operator="1" messageType="1" font="1" message="COD. PRENOTAZIONE: '+reservation.reservationCode+'" />';
    printerText += '<printRecMessage operator="1" messageType="1" font="1" message="'+reservation.name+'" />';
    printerText+='<printRecItem operator="1" description="'+billName+'" quantity="1000" unitPrice="'+(Math.round(bill.price*100))+'" department="'+department+'" idVat="0" />';
    printerText += '<printRecTotal operator="1" description="' + paymentDescription + '" payment="' +(Math.round(bill.price*100)) + '" paymentType="' + paymentCode + '"  />'


    /* if (reservation.reservationCode) {

      printerText += '<printBarCode operator="1" qRCodeErrorCorrection="1" qRCodeAlignment="1" qRCodeSize ="7" codeType="QRCODE2" code="'+reservation.reservationCode+'" />'
    } */

    const fiscalData = `<?xml version="1.0" encoding="utf-8"?>
                        <printerFiscalReceipt>
                          <beginFiscalReceipt  operator="1" />`
                              + printerText.replace(/&/g, 'and')  +
                        `<endFiscalReceipt operator="1" />
                        <endFiscalReceiptCut />
                       </printerFiscalReceipt>`;

    console.log(fiscalData);

    return this.customFiscalPrintExec(printer,fiscalData);


  }

  async epsonFiscalprint(reservation,printer, paymentType) {

    return new Promise((resolve, reject)=> {
        
      let fiscalData = this.getEpsonXMLfiscalData(reservation, paymentType, printer);
      console.log(fiscalData);

      const epos = new epson.fiscalPrint();
      const self = this;

      epos.send('https://' + printer.ip + '/cgi-bin/fpmate.cgi', fiscalData, 20000);
      epos.onreceive = (res, tag_list_names, add_info, res_add) => {
        console.log(res, tag_list_names, add_info, res_add);
        self.printed = true;
        resolve(res);
      };

      epos.onerror = (err) =>{
        console.log(err);
        reject(err)
      }
    })

  }

  async epsonFiscalprintSingle(reservation,printer, paymentType, bill) {

    let paymentCode = '0';
    let paymentDescription = 'Contanti'
    let index = '0';

    if (paymentType == 'cash') {
      paymentCode= '0';
      paymentDescription = 'Contanti';
    } else if (paymentType == 'cheque') {
      paymentCode= '1';
      paymentDescription = 'Assegno';
    } else if (paymentType == 'credit-card') {
      paymentCode= '2';
      index = '1';
      paymentDescription = 'Carta di credito';
    } else if (paymentType == 'transfer') {
      paymentCode= '2';
      paymentDescription = 'Bonifico';
      index = '1';
    } else if (paymentType == 'coupon') {
      paymentCode= '3';
      paymentDescription = 'Coupon';
    } else if (paymentType == 'forex') {
      paymentCode= '4';
      paymentDescription = 'Valuta straniera';
    }

    let printerText = '';

    reservation.fiscalInfo = true;

    let department = '1';

    // 0 = Cash
    // 1 = Check
    // 2 = Credit/Credit Card
    // 3 = Ticket
    // 4 = Foreign Currency


    if (printer.department) {
      department = printer.department
    }

    let lastDate = null;

    
    let billName = bill.name.substring(0, 38);
    billName = billName.quantity + ' x '+billName;

    printerText += '<printRecMessage operator="1" messageType="4" message="COD. PRENOTAZIONE: '+reservation.reservationCode+'" />';
    printerText += '<printRecMessage operator="1" messageType="4" font="1" message="'+reservation.name+'" />';
    printerText+='<printRecItem operator="1" description="'+billName+'" quantity="1" unitPrice="'+bill.price.toFixed(2).toString().replace('.',',')+'" department="'+department+'" justification="1" />';

    if (paymentType != '1') {
      printerText += '<printRecTotal operator="1" description="' + paymentDescription + '" payment="' + bill.price.toFixed(2).toString().replace('.', ',') + '" paymentType="'+ paymentCode + '" index="' + index + '" justification="2" />';
    } else {
      printerText += '<printRecTotal operator="1" description="' + paymentDescription + '" payment="' + bill.price.toFixed(2).toString().replace('.', ',') + '" paymentType="' + paymentCode + '" justification="2" />'
    }

    if (reservation.reservationCode) {

      printerText += '<printBarCode operator="1" qRCodeErrorCorrection="1" qRCodeAlignment="1" qRCodeSize ="7" codeType="QRCODE2" code="'+reservation.reservationCode+'" />'
    }

    const fiscalData = `<printerFiscalReceipt>
                          <beginFiscalReceipt  operator="1" />`
                              + printerText.replace(/&/g, 'and')  +
                        `<endFiscalReceipt operator="1" />
                       </printerFiscalReceipt>`;

    console.log(fiscalData);

    const epos = new epson.fiscalPrint();

    const self = this;

    epos.send('https://' + printer.ip + '/cgi-bin/fpmate.cgi', fiscalData, 20000);


    return new Promise((resolve, reject)=> {
      epos.onreceive = (res, tag_list_names, add_info, res_add) => {
        console.log(res, tag_list_names, add_info, res_add);
        self.printed = true;
        resolve(res);
      };

      epos.onerror = (err) =>{
        console.log(err);
        reject(err)
      }
    })

    /*
    setTimeout(()=>{

        if (!this.printed) {
            this.openSnackBar('ERRORE. La stampante potrebbe essere offline.','OK')
        }

    }, 10000) */

  }

  getEpsonXMLfiscalData(reservation, paymentType, printer) {

    console.log(paymentType);

    let paymentCode = '0';
    let paymentDescription = 'Contanti';
    let index = '0';

    if (paymentType == 'cash') {
      paymentCode= '0';
      paymentDescription = 'Contanti';
    } else if (paymentType == 'cheque') {
      paymentCode= '1';
      paymentDescription = 'Assegno';
    } else if (paymentType == 'credit-card') {
      paymentCode= '2';
      index = '1';
      paymentDescription = 'Carta di credito';
    } else if (paymentType == 'transfer') {
      paymentCode= '2';
      index = '1';
      paymentDescription = 'Bonifico';
    } else if (paymentType == 'coupon') {
      paymentCode= '3';
      paymentDescription = 'Coupon';
    } else if (paymentType == 'forex') {
      paymentCode= '4';
      paymentDescription = 'Valuta straniera';
    }

    console.log(paymentCode);

    const optionalsByIds = {};
    this.dataService.optionals.map(opt => {
      optionalsByIds[opt.id] = opt;
    });

    for (const product of reservation.order.products) {
      if (product.type == 'extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'reserved-extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'discount') {
        product.startDate = 1897496437+1;
      } else if (product.type == 'reserved-discount') {
        product.startDate = 1897496437+1;
      }
    }

    reservation.order.products = reservation.order.products.sort(this.sortByStartDate);

    let printerText = '';

    reservation.fiscalInfo = true;

    let department = '1';

    if (printer.department) {
      department = printer.department
    }


    // 0 = Cash
    // 1 = Check
    // 2 = Credit/Credit Card
    // 3 = Ticket
    // 4 = Foreign Currency
    console.log(paymentType);
    console.log(paymentDescription);
    console.log(reservation.order);

    let lastDate = null;

    for (const product of reservation.order.products) {

      if (product.startDate && product.startDate != lastDate && product.type != 'extra' && product.type != 'discount' && product.type != 'reserved-extra' && product.type != 'reserved-discount') {

        lastDate = product.startDate;

        let name = '';
        if (reservation.name && reservation.name.trim() !== '') {
          name = reservation.name;
        }

        if (reservation.surname && reservation.surname.trim() !== '') {
          name += ' '+reservation.surname;
        }

        if (lastDate != null) {
          printerText += '<printRecMessage operator="1" messageType="4" message="COD. PRENOTAZIONE: '+reservation.reservationCode+'" />';
          printerText += '<printRecMessage operator="1" messageType="4" font="1" message="'+name+'" />';
        }

        const startDate = new Date(product.startDate*1000)
        const startDay = startDate.getDate();
        const startMonth = startDate.getMonth()+1;

        let startDayString = startDay.toString();
        let startMonthString = startMonth.toString();

        if (startDay < 10) {
          startDayString = '0'+startDay.toString();
        }

        if (startMonth < 10) {
          startMonthString = '0'+startMonth.toString();
        }

        const endDate = new Date(product.endDate*1000)
        const endDay = endDate.getDate();
        const endMonth = endDate.getMonth() + 1;

        let endDayString = endDay.toString();
        let endMonthString = endMonth.toString();

        if (endDay < 10) {
          endDayString = '0' + endDay.toString();
        }

        if (endMonth < 10) {
          endMonthString = '0' + endMonth.toString();
        }

        let dateString = '';

        if ((startDate != endDate) || (!endDate)) {
          dateString = 'dal '+ startDayString + '/' + startMonthString  + ' al ' + endDayString + '/' + endMonthString;
        } else {
          dateString = startDayString + '/' + startMonthString;
        }

        printerText+= '<printRecMessage operator="1" messageType="4" message="'+dateString+'" />';

      }

      if (product.type == 'package') {

        if (typeof product.quantity === 'undefined' || !product.quantity) {
          product.quantity = 1;
        }


        let description = product.name[this.dataService.lang].replace(/\(|\)/g, "") + ' x '+ product.length +  ' gg';
        if (product.col != null) {
          printerText+= '<printRecMessage operator="1" messageType="4" message="POSTAZIONE ' + product.label + '" />';
        } else {
          printerText+= '<printRecMessage operator="1" messageType="4" message="' + product.label + '" />';
        }
        printerText+='<printRecItem operator="1" description="' + description.substring(0, 38) + '" quantity="' + product.quantity + '" unitPrice="' + (product.price).toFixed(2).toString().replace('.', ',') + '" department="' + department + '" justification="1" />';

      } else if (product.type == 'optional') {

        let description = optionalsByIds[product.optionalId].name[this.dataService.lang].replace(/\(|\)/g, "") + ' x '+product.length + ' gg';

        printerText+='<printRecItem operator="1" description="' + description.substring(0, 38) + '" quantity="'+product.quantity+'" unitPrice="'+(product.price).toFixed(2).toString().replace('.',',')+'" department="'+department+'" justification="1" />';
      } else if (product.type== 'extra') {

        printerText+='<printRecItem operator="1" description="'+this.dataService.resortData.extrasByIds[product.extraId].name[this.dataService.lang].replace(/\(|\)/g, "") +'" quantity="'+product.quantity+'" unitPrice="'+(product.price).toFixed(2).toString().replace('.',',')+'" department="'+department+'" justification="1" />';
      } else  if (product.type == 'reserved-extra') {
        printerText+='<printRecItem operator="1" description="Extra" quantity="1" unitPrice="'+(Math.round(product.price*100)/100).toString().replace('.',',')+'" department="'+department+'" justification="1" />'
      }

    }

    if (reservation.cabins && reservation.cabins.length > 0) {

      //printerText+= '<printRecMessage operator="1" messageType="4" message="" />';
      let cabins = '';

      for (const cabin of reservation.cabins) {
        if (cabins != '') {
          cabins += ', '+this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
        } else {
          cabins = this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
        }
      }

      printerText +=  '<printRecMessage operator="1" messageType="4" message="CABINE: '+cabins+'" />';

    }

    let total = reservation.order.total;

    for (const customerBill of reservation.order.customerBill) {

      let dayS = new Date(customerBill.date*1000).getDate();
      let monthS = new Date(customerBill.date*1000).getMonth()+1;

      let dayString2 = dayS.toString();
      let monthString2 = monthS.toString();

      if (dayS < 10) {
        dayString2 = '0' + dayString2;
      }

      if (monthS < 10) {
        monthString2 = '0' + monthString2;
      }

        
      let billName = customerBill.name.substring(0, 38);
      billName = customerBill.quantity+ ' x '+billName;


      if (customerBill.payed && customerBill.fiscalPrinted && customerBill.price !== 0 ) {
        total-=customerBill.price;
        printerText+='<printRecSubtotalAdjustment Ope="1" Text="' + dayString2 + '/' + monthString2 + ' ' + billName + '" Type="1" Amount="' + customerBill.price.toFixed(2).toString().replace('.',',')+'" Dep="7" Just="2" />';
      } else if (customerBill.type != "reservation-total" && !customerBill.payed && customerBill.price !== 0 ) {
        total+=customerBill.price;
        printerText+='<printRecItem operator="1" description="' + dayString2 + '/' + monthString2 + ' ' + billName + '" quantity="1" unitPrice="' + customerBill.price.toFixed(2).toString().replace('.',',') + '" department="' + department + '" justification="1" />'
      }
    }

    if (reservation.lotteryCode) {
      //printerText += '<directIO command="1135" data="01'+reservation.lotteryCode+'        0000"  comment="operatore = 01, codice = 12345678 seguito da otto spazi e 0000 sono zeri fissi ignorati ma necessari" />';
      printerText += '<printRecLotteryID Ope="1" code="'+reservation.lotteryCode+'" />';
    }
    for (const product of reservation.order.products) {
      if (product.type == 'reserved-discount' && product.price !== 0) {
        printerText+='<printRecSubtotalAdjustment Ope="1" Text="SCONTO RISERVATO" Type="1" Amount="'+(Math.round(product.price*100)/100).toString().replace('.',',')+'" Dep="7" Just="2"  />'
      } else if (product.type == 'discount' && product.percentage !== 0) {
        let discAmount = (product.percentage*reservation.order.subtotal/100).toFixed(2).toString().replace('.',',')
        printerText+='<printRecSubtotalAdjustment Ope="1" Text="SCONTO '+this.dataService.resortData.discountsByIds[product.discountId].name[this.dataService.lang].replace(/\(|\)/g, "") +' -'+product.percentage+'%" Type="1" Amount="'+discAmount+'" Dep="7" Just="2"  />'
      } else if (product.type == 'coupon' && product.price !== 0) {
        let discAmount = (product.price).toFixed(2).toString().replace('.',',');
        printerText+='<printRecSubtotalAdjustment Ope="1" Text="SCONTO '+product.name[this.dataService.lang].replace(/\(|\)/g, "") +' -'+product.price+'€" Type="1" Amount="'+discAmount+'" Dep="7" Just="2"  />'
      } else if (product.type == 'refund' && product.price !== 0) {
        let discAmount = (product.price).toFixed(2).toString().replace('.',',');
        printerText+='<printRecSubtotalAdjustment Ope="1" Text="'+product.name[this.dataService.lang].replace(/\(|\)/g, "") +' ' +reservation.reservationCode +' -'+product.price+'€" Type="1" Amount="'+discAmount+'" Dep="7" Just="2"  />'
      }
    }

    let findFiscalPrinter =reservation.order.customerBill.find((c:any)=>{ c.fiscalPrinted});
    if (!findFiscalPrinter && reservation.bookingInfo && reservation.bookingInfo.technicalFeeReverse) {
      let discAmount = (reservation.bookingInfo.technicalFeeReverse).toFixed(2).toString().replace('.',',');
      printerText+='<printRecSubtotalAdjustment Ope="1" Text="Fee Tecnica Online" Type="1" Amount="'+discAmount+'" Dep="7" Just="2"  />'
    }

    printerText += '<printRecTotal operator="1" description="' + paymentDescription + '" payment="' + total.toFixed(2).toString().replace('.', ',') + '" paymentType="'+ paymentCode + '" index="' + index + '" justification="2" />';


    if (reservation.reservationCode) {
      printerText += '<printBarCode operator="1" qRCodeErrorCorrection="1" qRCodeAlignment="1" qRCodeSize ="7" codeType="QRCODE2" code="'+reservation.reservationCode+'" />'
    }

    let fiscalData = `<printerFiscalReceipt>
                          <beginFiscalReceipt  operator="1" />`
                              + printerText.replace(/&/g, 'and') +
                        `<endFiscalReceipt operator="1" />
                       </printerFiscalReceipt>`;

    return fiscalData;
  }

  getCustomXMLfiscalData(reservation, paymentType, printer) {

    console.log(paymentType);

    let paymentCode = '0';
    let paymentDescription = 'Contanti';

    if (paymentType == 'cash') {
      paymentCode= '1';
      paymentDescription = 'Contanti';
    } else if (paymentType == 'cheque') {
      paymentCode= '2';
      paymentDescription = 'Assegno';
    } else if (paymentType == 'credit-card') {
      paymentCode= '3';
      paymentDescription = 'Carta di credito';
    } else if (paymentType == 'coupon') {
      paymentCode= '5';
      paymentDescription = 'Coupon';
    } else {
      paymentCode= '4';
      paymentDescription = 'Pagamento Generico';
    }

    console.log(paymentCode);

    const optionalsByIds = {};
    this.dataService.optionals.map(opt => {
      optionalsByIds[opt.id] = opt;
    });

    for (const product of reservation.order.products) {
      if (product.type == 'extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'reserved-extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'discount') {
        product.startDate = 1897496437+1;
      } else if (product.type == 'reserved-discount') {
        product.startDate = 1897496437+1;
      }
    }

    reservation.order.products = reservation.order.products.sort(this.sortByStartDate);

    let printerText = '';

    reservation.fiscalInfo = true;

    let department = '1';

    if (printer.department) {
      department = printer.department
    }


    // 0 = Cash
    // 1 = Check
    // 2 = Credit/Credit Card
    // 3 = Ticket
    // 4 = Foreign Currency
    console.log(paymentType);
    console.log(paymentDescription);
    console.log(reservation.order);

    let lastDate = null;

    for (const product of reservation.order.products) {

      if (product.startDate && product.startDate != lastDate && product.type != 'extra' && product.type != 'discount' && product.type != 'reserved-extra' && product.type != 'reserved-discount') {

        lastDate = product.startDate;

        let name = '';
        if (reservation.name && reservation.name.trim() !== '') {
          name = reservation.name;
        }

        if (reservation.surname && reservation.surname.trim() !== '') {
          name += ' '+reservation.surname;
        }

        if (lastDate != null) {
          printerText += '<printRecMessage operator="1" messageType="1" font="1" message="COD. PRENOTAZIONE: '+reservation.reservationCode+'" />';
          printerText += '<printRecMessage operator="1" messageType="1" font="1" message="'+name+'" />';
        }

        const startDate = new Date(product.startDate*1000)
        const startDay = startDate.getDate();
        const startMonth = startDate.getMonth()+1;

        let startDayString = startDay.toString();
        let startMonthString = startMonth.toString();

        if (startDay < 10) {
          startDayString = '0'+startDay.toString();
        }

        if (startMonth < 10) {
          startMonthString = '0'+startMonth.toString();
        }

        const endDate = new Date(product.endDate*1000)
        const endDay = endDate.getDate();
        const endMonth = endDate.getMonth() + 1;

        let endDayString = endDay.toString();
        let endMonthString = endMonth.toString();

        if (endDay < 10) {
          endDayString = '0' + endDay.toString();
        }

        if (endMonth < 10) {
          endMonthString = '0' + endMonth.toString();
        }

        let dateString = '';

        if ((startDate != endDate) || (!endDate)) {
          dateString = 'dal '+ startDayString + '/' + startMonthString  + ' al ' + endDayString + '/' + endMonthString;
        } else {
          dateString = startDayString + '/' + startMonthString;
        }

        printerText+= '<printRecMessage operator="1" messageType="1" font="1" message="'+dateString+'" />';

      }

      if (product.type == 'package') {

        if (typeof product.quantity === 'undefined' || !product.quantity) {
          product.quantity = 1;
        }


        let description = product.name[this.dataService.lang].replace(/\(|\)/g, "") + ' x '+ product.length +  ' gg';
        if (product.col != null) {
          printerText+= '<printRecMessage operator="1" messageType="1" font="1" message="POSTAZIONE ' + product.label + '" />';
        } else {
          printerText+= '<printRecMessage operator="1" messageType="1" font="1" message="' + product.label + '" />';
        }
        printerText+='<printRecItem operator="1" description="' + description.substring(0, 38) + '" quantity="' + product.quantity*1000 + '" unitPrice="' + (Math.round(product.price*100)) + '" department="' + department + '" idVat="0" />';

      } else if (product.type == 'optional') {

        let description = optionalsByIds[product.optionalId].name[this.dataService.lang].replace(/\(|\)/g, "") + ' x '+product.length + ' gg';

        printerText+='<printRecItem operator="1" description="' + description.substring(0, 38) + '" quantity="'+product.quantity*1000+'" unitPrice="'+(Math.round(product.price*100))+'" department="'+department+'" idVat="0"/>';
      } else if (product.type== 'extra') {

        printerText+='<printRecItem operator="1" description="'+this.dataService.resortData.extrasByIds[product.extraId].name[this.dataService.lang].replace(/\(|\)/g, "") +'" quantity="'+product.quantity*1000+'" unitPrice="'+Math.round(product.price*100)+'" department="'+department+'"  idVat="0"/>';
      } else  if (product.type == 'reserved-extra') {
        printerText+='<printRecItem operator="1" description="Extra" quantity="1000" unitPrice="'+(Math.round(product.price*100))+'" department="'+department+'" idVat="0"/>'
      }

    }

    if (reservation.cabins && reservation.cabins.length > 0) {

      //printerText+= '<printRecMessage operator="1" messageType="4" message="" />';
      let cabins = '';

      for (const cabin of reservation.cabins) {
        if (cabins != '') {
          cabins += ', '+this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
        } else {
          cabins = this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
        }
      }

      printerText +=  '<printRecMessage operator="1" messageType="1" font="1" message="CABINE: '+cabins+'" />';

    }

    let total = reservation.order.total;

    for (const customerBill of reservation.order.customerBill) {

      let dayS = new Date(customerBill.date*1000).getDate();
      let monthS = new Date(customerBill.date*1000).getMonth()+1;

      let dayString2 = dayS.toString();
      let monthString2 = monthS.toString();

      if (dayS < 10) {
        dayString2 = '0' + dayString2;
      }

      if (monthS < 10) {
        monthString2 = '0' + monthString2;
      }
      
      
      let billName = customerBill.name.substring(0, 38);
      billName = customerBill.quantity+ ' x '+billName;

      if (customerBill.payed && customerBill.fiscalPrinted && customerBill.price !== 0 ) {
        total-=customerBill.price;
        printerText+='<printRecSubtotalAdjustment adjustmentType="3" description="' + dayString2 + '/' + monthString2 + ' ' + billName + '" amount="' + Math.round(customerBill.price*100) +'"  />';
      } else if (customerBill.type != "reservation-total" && !customerBill.payed && customerBill.price !== 0 ) {
        total+=customerBill.price;
        console.log(customerBill.quantity);
        printerText+='<printRecItem operator="1" description="' + dayString2 + '/' + monthString2 + ' ' + billName + '" quantity="1000" unitPrice="' + Math.round(customerBill.price*100)  + '" department="' + department + '"  />'
      }
    }

    if (reservation.lotteryCode) {
      //printerText += '<directIO command="1135" data="01'+reservation.lotteryCode+'        0000"  comment="operatore = 01, codice = 12345678 seguito da otto spazi e 0000 sono zeri fissi ignorati ma necessari" />';
      printerText += '<printRecLotteryID Ope="1" code="'+reservation.lotteryCode+'" />';
    }

    for (const product of reservation.order.products) {
      if (product.type == 'reserved-discount') {
        printerText+='<printRecSubtotalAdjustment adjustmentType="3" description="SCONTO RISERVATO" amount="'+(Math.round(product.price*100)).toString().replace('.',',')+'" />'
      } else if (product.type == 'discount') {
        printerText+='<printRecSubtotalAdjustment adjustmentType="3" description="SCONTO '+this.dataService.resortData.discountsByIds[product.discountId].name[this.dataService.lang].replace(/\(|\)/g, "") +' -'+product.percentage+'%" amount="'+Math.round(product.percentage*reservation.order.subtotal)+'" />'
      } else if (product.type == 'coupon') {
        let discAmount = (product.price).toFixed(2).toString().replace('.',',');
        printerText+='<printRecSubtotalAdjustment adjustmentType="3" description="SCONTO '+product.name[this.dataService.lang].replace(/\(|\)/g, "") +' -'+discAmount+'€" amount="'+Math.round(product.price*100)+'"  />'
      } else if (product.type == 'refund') {
        let discAmount = (product.price).toFixed(2).toString().replace('.',',');
        printerText+='<printRecSubtotalAdjustment adjustmentType="3" description="'+product.name[this.dataService.lang].replace(/\(|\)/g, "") +' ' +reservation.reservationCode +' -'+discAmount+'€" amount="'+Math.round(product.price*100)+'"  />'
      }
    }
    printerText += '<printRecTotal operator="1" description="' + paymentDescription + '" payment="' + Math.round(total*100) + '" paymentType="'+ paymentCode + '"/>';


    if (reservation.reservationCode) {
      /* printerText += '<printBarCode codeType="QRCODE" barCodeHeigth="4" code="'+reservation.reservationCode+'"></printBarCode>' */
    }


    let fiscalData = `
                        <?xml version="1.0" encoding="utf-8"?>
                        <printerFiscalReceipt>
                          <beginFiscalReceipt  operator="1" />`
                              + printerText.replace(/&/g, 'and') +
                        `<endFiscalReceipt operator="1" />
                        <endFiscalReceiptCut />
                       </printerFiscalReceipt>`;

    return fiscalData;
  }

  getRCHxmlFiscalData(reservation,paymentType,printer) {
    
    let paymentCode = '0';
    let paymentDescription = 'Contanti';

    if (paymentType == 'cash') {
      paymentCode= '1';
      paymentDescription = 'Contanti';
    } else if (paymentType == 'cheque') {
      paymentCode= '2';
      paymentDescription = 'Assegno';
    } else if (paymentType == 'credit-card') {
      paymentCode= '3';
      paymentDescription = 'Carta di credito';
    } else if (paymentType == 'coupon') {
      paymentCode= '5';
      paymentDescription = 'Coupon';
    } else {
      paymentCode= '4';
      paymentDescription = 'Pagamento Generico';
    }

    let printerText = `<cmd>=K</cmd>`;

    const optionalsByIds = {};
    this.dataService.optionals.map(opt => {
      optionalsByIds[opt.id] = opt;
    });

    for (const product of reservation.order.products) {
      if (product.type == 'extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'reserved-extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'discount') {
        product.startDate = 1897496437+1;
      } else if (product.type == 'reserved-discount') {
        product.startDate = 1897496437+1;
      }
    }

    reservation.order.products = reservation.order.products.sort(this.sortByStartDate);


    reservation.fiscalInfo = true;

    let department = '1';

    if (printer.department) {
      department = printer.department
    }


    // 0 = Cash
    // 1 = Check
    // 2 = Credit/Credit Card
    // 3 = Ticket
    // 4 = Foreign Currency
    console.log(paymentType);
    console.log(paymentDescription);
    console.log(reservation.order);

    let lastDate = null;

    for (const product of reservation.order.products) {

      if (product.startDate && product.startDate != lastDate && product.type != 'extra' && product.type != 'discount' && product.type != 'reserved-extra' && product.type != 'reserved-discount') {

        lastDate = product.startDate;

        let name = '';
        if (reservation.name && reservation.name.trim() !== '') {
          name = reservation.name;
        }

        if (reservation.surname && reservation.surname.trim() !== '') {
          name += ' '+reservation.surname;
        }

        if (lastDate != null) {
          printerText += '<cmd>="/?A/(COD. PRENOTAZIONE: '+reservation.reservationCode+')</cmd>';
          if (name !='')
            printerText += '<cmd>="/?A/('+name+')</cmd>';
        }

        const startDate = new Date(product.startDate*1000)
        const startDay = startDate.getDate();
        const startMonth = startDate.getMonth()+1;

        let startDayString = startDay.toString();
        let startMonthString = startMonth.toString();

        if (startDay < 10) {
          startDayString = '0'+startDay.toString();
        }

        if (startMonth < 10) {
          startMonthString = '0'+startMonth.toString();
        }

        const endDate = new Date(product.endDate*1000)
        const endDay = endDate.getDate();
        const endMonth = endDate.getMonth() + 1;

        let endDayString = endDay.toString();
        let endMonthString = endMonth.toString();

        if (endDay < 10) {
          endDayString = '0' + endDay.toString();
        }

        if (endMonth < 10) {
          endMonthString = '0' + endMonth.toString();
        }

        let dateString = '';

        if ((startDate != endDate) || (!endDate)) {
          dateString = 'dal '+ startDayString + '/' + startMonthString  + ' al ' + endDayString + '/' + endMonthString;
        } else {
          dateString = startDayString + '/' + startMonthString;
        }

        printerText+= '<cmd>="/?A/('+dateString+')</cmd>';

      }

      if (product.type == 'package') {

        if (typeof product.quantity === 'undefined' || !product.quantity) {
          product.quantity = 1;
        }

        let description = product.name[this.dataService.lang].replace(/\(|\)/g, "") + ' x '+ product.length +  ' gg';
        printerText+='<cmd>=R'+department+'/$' + (Math.round(product.price*100)) + '/*' + product.quantity + '/(' + description.substring(0, 38)+')</cmd>';

        if (product.col != null) {
          printerText+= '<cmd>="/?A/(POSTAZIONE ' + product.label + ')</cmd>';
        } else {
          printerText+= '<cmd>="/?A/(' + product.label + ')</cmd>';
        }

      } else if (product.type == 'optional') {

        let description = optionalsByIds[product.optionalId].name[this.dataService.lang].replace(/\(|\)/g, "") + ' x '+product.length + ' gg';

        printerText+='<cmd>=R'+department+'/$' + (Math.round(product.price*100)) + '/*' + product.quantity + '/(' + description.substring(0, 38)+')</cmd>';
        
      } else if (product.type== 'extra') {

        printerText+='<cmd>=R'+department+'/$' + (Math.round(product.price*100)) + '/*' + product.quantity + '/(' + this.dataService.resortData.extrasByIds[product.extraId].name[this.dataService.lang].replace(/\(|\)/g, "")+')</cmd>';

      } else  if (product.type == 'reserved-extra') {
        
        printerText+='<cmd>=R'+department+'/$' + (Math.round(product.price*100)) + '/*1/(Extra)</cmd>';
      }

    }


    let total = reservation.order.total;

    for (const customerBill of reservation.order.customerBill) {

      let dayS = new Date(customerBill.date*1000).getDate();
      let monthS = new Date(customerBill.date*1000).getMonth()+1;

      let dayString2 = dayS.toString();
      let monthString2 = monthS.toString();

      if (dayS < 10) {
        dayString2 = '0' + dayString2;
      }

      if (monthS < 10) {
        monthString2 = '0' + monthString2;
      }

      if (customerBill.type != "reservation-total" && !customerBill.payed && customerBill.price !== 0 ) {
        total+=customerBill.price;

          
        let billName = customerBill.name.substring(0, 38);
        billName = customerBill.quantity+ ' x '+billName;
        
        printerText+='<cmd>=R'+department+'/$' + (Math.round(customerBill.price*100)) + '/*1/(' + dayString2 + '/' + monthString2 + ' ' + billName + ')</cmd>';

      }
    }

    
   
    if (reservation.cabins && reservation.cabins.length > 0) {

      //printerText+= '<printRecMessage operator="1" messageType="4" message="" />';
      let cabins = '';

      for (const cabin of reservation.cabins) {
        if (cabins != '') {
          cabins += ', '+this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
        } else {
          cabins = this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
        }
      }

      printerText += '<cmd>="/?A/(CABINE: '+cabins+')</cmd>';

    }

    printerText+=' <cmd>=S</cmd> ';

    
    for (const customerBill of reservation.order.customerBill) {

      let dayS = new Date(customerBill.date*1000).getDate();
      let monthS = new Date(customerBill.date*1000).getMonth()+1;

      let dayString2 = dayS.toString();
      let monthString2 = monthS.toString();

      if (dayS < 10) {
        dayString2 = '0' + dayString2;
      }

      if (monthS < 10) {
        monthString2 = '0' + monthString2;
      }

      
      let billName = customerBill.name.substring(0, 38);
      billName = customerBill.quantity+ ' x '+billName;

      if (customerBill.payed && customerBill.fiscalPrinted && customerBill.price !== 0 ) {
        total-=customerBill.price;
        
        printerText+='<cmd>=V'+department+'-/$' + (Math.round(customerBill.price*100)) + '/*1/(' + dayString2 + '/' + monthString2 + ' ' + billName + ')</cmd>';
      }
    }

    if (reservation.lotteryCode) {
      /* printerText += '<printRecLotteryID Ope="1" code="'+reservation.lotteryCode+'" />'; */
    }

    for (const product of reservation.order.products) {
      if (product.type == 'reserved-discount') {
        printerText+='<cmd>=V'+department+'-/$' + (Math.round(product.price*100)) + '/*1/(SCONTO RISERVATO)</cmd>';
      } else if (product.type == 'discount') {
        
        printerText+='<cmd>=%'+department+'-/$' + (Math.round(product.percentage*reservation.order.subtotal)) + '/*1/(SCONTO '+this.dataService.resortData.discountsByIds[product.discountId].name[this.dataService.lang].replace(/\(|\)/g, "") +' -'+product.percentage+'%)</cmd>';

      } else if (product.type == 'coupon') {
        let discAmount = (product.price).toFixed(2).toString().replace('.',',');
        
        printerText+='<cmd>=V'+department+'-/$' + (Math.round(product.price)) + '/*1/(SCONTO '+product.name[this.dataService.lang].replace(/\(|\)/g, "") +' -'+discAmount+'€)</cmd>';

      } else if (product.type == 'refund') {
        let discAmount = (product.price).toFixed(2).toString().replace('.',',');
        printerText+='<cmd>=V'+department+'-/$' + (Math.round(product.price)) + '/*1/(SCONTO '+product.name[this.dataService.lang].replace(/\(|\)/g, "")+' ' +reservation.reservationCode +' -'+discAmount+'€)</cmd>';
      }
    }
   /*  printerText += '<printRecTotal operator="1" description="' + paymentDescription + '" payment="' + Math.round(total*100) + '" paymentType="'+ paymentCode + '"/>'; */


    if (reservation.reservationCode) {
      /* printerText += '<printBarCode codeType="QRCODE" barCodeHeigth="4" code="'+reservation.reservationCode+'"></printBarCode>' */
    }

    
    let fiscalData = `
                        <?xml version="1.0" encoding="UTF-8"?>
                        <Service>`
                              + printerText +
                        `<cmd>=T`+paymentCode+`</cmd></Service>`;

    return fiscalData;
  }

  sortByStartDate ( a, b ) {
    if ( a.startDate < b.startDate ) {
      return -1;
    }
    if ( a.startDate > b.startDate ) {
      return 1;
    }
    return 0;
  }

  sortByDate(a,b) {
    if ( a.date < b.date ) {
      return -1;
    }
    if ( a.date > b.date ) {
      return 1;
    }
    return 0;
  }

  async epsonPrint(reservation, printer) {

    console.log(reservation);

    const optionalsByIds = {};
    this.dataService.optionals.map(opt => {
      optionalsByIds[opt.id] = opt;
    });

    for (const product of reservation.order.products) {
      if (product.type == 'extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'reserved-extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'discount') {
        product.startDate = 1897496437+1;
      } else if (product.type == 'reserved-discount') {
        product.startDate = 1897496437+1;
      }
    }

    reservation.order.products = reservation.order.products.sort(this.sortByStartDate);

    let name = '';

    if (reservation.name && reservation.name.trim() !== '') {
      name = reservation.name;
    }

    if (reservation.surname && reservation.surname.trim() !== '') {
      name += ' '+reservation.surname;
    }

    let printerText = '';
    printerText += '<printNormal font="4" operator="1" data="CODICE PRENOTAZIONE: '+reservation.reservationCode+'" />';
    printerText +=  '<printNormal font="4" operator="1" data="' + name + '" />';

    let lastDate = null;

    for (const product of reservation.order.products) {

      if (product.startDate && product.startDate != lastDate && product.type != 'extra' && product.type != 'discount' && product.type != 'reserved-extra' && product.type != 'reserved-discount') {

        lastDate = product.startDate;

        if (lastDate != null) {
          printerText += '<printNormal operator="1" font="1" data="" comment=" " />'
        }

        const startDate = new Date(product.startDate*1000)
        const startDay = startDate.getDate();
        const startMonth = startDate.getMonth()+1;

        let startDayString = startDay.toString();
        let startMonthString = startMonth.toString();

        if (startDay < 10) {
          startDayString = '0'+startDay.toString();
        }

        if (startMonth < 10) {
          startMonthString = '0'+startMonth.toString();
        }

        const endDate = new Date(product.endDate*1000)
        const endDay = endDate.getDate();
        const endMonth = endDate.getMonth() + 1;

        let endDayString = endDay.toString();
        let endMonthString = endMonth.toString();

        if (endDay < 10) {
          endDayString = '0' + endDay.toString();
        }

        if (endMonth < 10) {
          endMonthString = '0' + endMonth.toString();
        }

        let dateString = '';

        if ((startDate != endDate) || (!endDate)) {
          dateString = 'dal '+ startDayString + '/' + startMonthString  + ' al ' + endDayString + '/' + endMonthString;
        } else {
          dateString = startDayString + '/' + startMonthString;
        }

        printerText  +=  '<printNormal font="2" operator="1" data="' + dateString + '" />';

      }

      if (product.type == 'package') {

        if (typeof product.quantity === 'undefined' || !product.quantity) {
          product.quantity = 1;
        }

        if (product.col != null) {
          printerText  +=  '<printNormal operator="1" data="POSTAZIONE ' + product.label+'" />';
        } else {
          printerText  +=  '<printNormal operator="1" data="' + product.label+'" />';
        }
        printerText  +=  '<printNormal operator="1" data="  ' + product.name[this.dataService.lang] +' x '+ product.length+' gg"  />';
        printerText  +=  '<printNormal operator="1" font="2" data="    ' + product.quantity + ' x ' + product.price.toFixed(2) + ' euro"  />';

      } else if (product.type == 'optional') {

        printerText += '<printNormal operator="1" data="  +' + product.quantity + ' ' + optionalsByIds[product.optionalId].name[this.dataService.lang] + '"  />';
        printerText += '<printNormal operator="1" font="2" data="    '+ product.quantity + ' x ' + product.price.toFixed(2) + ' euro"  />';

      } else if (product.type== 'extra') {

        printerText += '<printNormal operator="1" font="1" data="" comment=" " />'
        printerText +=  '<printNormal operator="1" data="EXTRA" />';
        printerText += '<printNormal operator="1" data="  ' + this.dataService.resortData.extrasByIds[product.extraId].name[this.dataService.lang]+'"  />';
        printerText += '<printNormal operator="1" font="2" data="    '+ product.quantity + ' x ' + product.price.toFixed(2) + ' euro"  />';

      } else if (product.type == 'discount') {

        printerText += '<printNormal operator="1" font="1" data="" comment=" " />'
        printerText +=  '<printNormal operator="1" data="SCONTO" />';
        printerText += '<printNormal operator="1" data="  '+ this.dataService.resortData.discountsByIds[product.discountId].name[this.dataService.lang]+'"  />';
        printerText += '<printNormal operator="1" font="2" data="    -' + product.percentage.toFixed(2) + '  %"  />';

      } else if (product.type == 'reserved-discount') {

        printerText += '<printNormal operator="1" font="1" data="" comment=" " />'
        printerText +=  '<printNormal operator="1" data="SCONTO RISERVATO" />';
        printerText += '<printNormal operator="1" font="2" data="    -'+product.price.toFixed(2)+' euro"  />';

      } else if (product.type == 'reserved-extra') {

        printerText += '<printNormal operator="1" font="1" data="" comment=" " />'
        printerText +=  '<printNormal operator="1" data="EXTRA" />';
        printerText += '<printNormal operator="1" font="2" data="    '+product.price.toFixed(2)+'  euro"  />';

      }

    }

    if (reservation.cabins && reservation.cabins.length > 0) {
      printerText += '<printNormal operator="1" font="1" data="" comment=" " />';
      let cabins = '';

      for (const cabin of reservation.cabins) {
        if (cabins != '') {
          cabins += ', '+this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
        } else {
          cabins = this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
        }
      }

      printerText +=  '<printNormal operator="1" data="CABINE: '+cabins+'" />';

    }


    if (reservation.notes) {
      printerText += '<printNormal operator="1" font="1" data="" comment=" " />'
      printerText += '<printNormal operator="1" data="NOTE: '+reservation.notes+' " comment=" " />'
    }

    printerText += '<printNormal operator="1" font="1" data="" comment=" " />'

    const total = (parseFloat(reservation.order.total).toFixed(2)).toString().replace('.',',');

    printerText += '<printNormal operator="1" font="4" data="TOTALE ' + total + ' euro" comment=" " />';

    let customerBillTotalFlag = false;

    reservation.order.customerBill = reservation.order.customerBill.sort(this.sortByDate);

    for (const customerBill of reservation.order.customerBill) {
        let dayS = new Date(customerBill.date*1000).getDate();
        let monthS = new Date(customerBill.date*1000).getMonth()+1;

        let dayString2 = dayS.toString();
        let monthString2 = monthS.toString();

        if (dayS < 10) {
          dayString2 = '0' + dayString2;
        }

        if (monthS < 10) {
          monthString2 = '0' + monthString2;
        }

          
        let billName = customerBill.name.substring(0, 38);
        billName = customerBill.quantity+ ' x '+billName;

        if (customerBill.type != 'reservation-total') {
          if (customerBill.payed) {
            printerText += '<printNormal operator="1" font="1" data="" comment=" " />'
            printerText +=  '<printNormal operator="1" data="'+dayString2+'/'+monthString2+' '+billName+' -'+customerBill.price.toFixed(2)+' euro" />';

          } else  {
            printerText += '<printNormal operator="1" font="1" data="" comment=" " />'
            printerText +=  '<printNormal operator="1" data="'+dayString2+'/'+monthString2+' '+ billName +' '+customerBill.price.toFixed(2)+' euro" />';
          }

          customerBillTotalFlag = true;
        }
    }


    if (customerBillTotalFlag) {
      printerText += '<printNormal operator="1" font="1" data="" comment=" " />'
      printerText += '<printNormal operator="1" font="4" data="RESIDUO '+reservation.order.customerBillTotal.toFixed(2)+' euro" comment=" " />'
    }

    if (reservation.reservationCode) {
      printerText+='<printBarCode operator="1" qRCodeErrorCorrection="1" qRCodeAlignment="1" qRCodeSize ="7" codeType="QRCODE2" code="'+reservation.reservationCode+'" />'
    }



    const noFiscalData =
    `<printerNonFiscal>
      <beginNonFiscal operator="1" />`
        + printerText +
      `<endNonFiscal operator="1" />
    </printerNonFiscal>`;


    console.log(noFiscalData);

    const epos = new epson.fiscalPrint();

    const self = this;

    epos.send("https://"+printer.ip+"/cgi-bin/fpmate.cgi", noFiscalData, 20000);

    return new Promise((resolve, reject)=> {

        epos.onreceive = (res, tag_list_names, add_info, res_add) => {
          console.log(res, tag_list_names, add_info, res_add);
          self.printed = true;
          resolve(res);
        };

        epos.onerror = (err) =>{
          console.log(err);
          if (err && err !== undefined) {
            reject(err)
          }
        }
    })

  }

  async customPrint(reservation, printer) {

    console.log(reservation);

    const optionalsByIds = {};
    this.dataService.optionals.map(opt => {
      optionalsByIds[opt.id] = opt;
    });

    for (const product of reservation.order.products) {
      if (product.type == 'extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'reserved-extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'discount') {
        product.startDate = 1897496437+1;
      } else if (product.type == 'reserved-discount') {
        product.startDate = 1897496437+1;
      }
    }

    reservation.order.products = reservation.order.products.sort(this.sortByStartDate);

    let name = '';

    if (reservation.name && reservation.name.trim() !== '') {
      name = reservation.name;
    }

    if (reservation.surname && reservation.surname.trim() !== '') {
      name += ' '+reservation.surname;
    }

    let printerText = '';
    printerText += '<printNormal font="4" data="CODICE PRENOTAZIONE: '+reservation.reservationCode+'" />';

    if(name !== '')
    printerText +=  '<printNormal font="4" data="' + name + '" />';

    let lastDate = null;

    for (const product of reservation.order.products) {

      if (product.startDate && product.startDate != lastDate && product.type != 'extra' && product.type != 'discount' && product.type != 'reserved-extra' && product.type != 'reserved-discount') {

        lastDate = product.startDate;

        if (lastDate != null) {
          printerText += '<printNormal font="1" data=""/>'
        }

        const startDate = new Date(product.startDate*1000)
        const startDay = startDate.getDate();
        const startMonth = startDate.getMonth()+1;

        let startDayString = startDay.toString();
        let startMonthString = startMonth.toString();

        if (startDay < 10) {
          startDayString = '0'+startDay.toString();
        }

        if (startMonth < 10) {
          startMonthString = '0'+startMonth.toString();
        }

        const endDate = new Date(product.endDate*1000)
        const endDay = endDate.getDate();
        const endMonth = endDate.getMonth() + 1;

        let endDayString = endDay.toString();
        let endMonthString = endMonth.toString();

        if (endDay < 10) {
          endDayString = '0' + endDay.toString();
        }

        if (endMonth < 10) {
          endMonthString = '0' + endMonth.toString();
        }

        let dateString = '';

        if ((startDate != endDate) || (!endDate)) {
          dateString = 'dal '+ startDayString + '/' + startMonthString  + ' al ' + endDayString + '/' + endMonthString;
        } else {
          dateString = startDayString + '/' + startMonthString;
        }

        printerText  +=  '<printNormal font="2" data="' + dateString + '" />';

      }

      if (product.type == 'package') {

        if (typeof product.quantity === 'undefined' || !product.quantity) {
          product.quantity = 1;
        }
        if (product.col != null) {
          printerText  +=  '<printNormal font="1" data="POSTAZIONE ' + product.label+'" />';
        } else {
          printerText  +=  '<printNormal font="1" data="' + product.label+'" />';
        }
        printerText  +=  '<printNormal font="1" data="  ' + product.name[this.dataService.lang] +' x '+ product.length+' gg"  />';
        printerText  +=  '<printNormal font="2" data="    ' + product.quantity + ' x ' + product.price.toFixed(2) + ' euro"  />';

      } else if (product.type == 'optional') {

        printerText += '<printNormal font="1" data="  +' + product.quantity + ' ' + optionalsByIds[product.optionalId].name[this.dataService.lang] + '"  />';
        printerText += '<printNormal font="2" data="    '+ product.quantity + ' x ' + product.price.toFixed(2) + ' euro"  />';

      } else if (product.type== 'extra') {

        printerText += '<printNormal font="1" data=""/>';
        printerText += '<printNormal font="1" data="' + this.dataService.resortData.extrasByIds[product.extraId].name[this.dataService.lang]+'"  />';
        printerText += '<printNormal font="2" data="  '+ product.quantity + ' x ' + product.price.toFixed(2) + ' euro"  />';

      } else if (product.type == 'discount') {

        printerText +=  '<printNormal font="1" data="SCONTO" />';
        printerText += '<printNormal font="1" data="  '+ this.dataService.resortData.discountsByIds[product.discountId].name[this.dataService.lang]+'"  />';
        printerText += '<printNormal font="2" data="    -' + product.percentage.toFixed(2) + '  %"  />';

      } else if (product.type == 'reserved-discount') {

        printerText += '<printNormal font="1" data=""/>';
        printerText +=  '<printNormal font="1" data="SCONTO RISERVATO" />';
        printerText += '<printNormal font="2" data="    -'+product.price.toFixed(2)+' euro"  />';

      } else if (product.type == 'reserved-extra') {
        printerText += '<printNormal font="1" data=""/>';
        printerText +=  '<printNormal font="1" data="EXTRA" />';
        printerText += '<printNormal font="2" data="    '+product.price.toFixed(2)+'  euro"  />';

      }

    }

    if (reservation.cabins && reservation.cabins.length > 0) {
      printerText += '<printNormal font="1" data=""/>';
      let cabins = '';

      for (const cabin of reservation.cabins) {
        if (cabins != '') {
          cabins += ', '+this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
        } else {
          cabins = this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
        }
      }

      printerText +=  '<printNormal font="1"  data="CABINE: '+cabins+'" />';

    }


    if (reservation.notes) {
      printerText += '<printNormal font="1" data=""/>'
      printerText += '<printNormal font="1" data="NOTE: '+reservation.notes+' "  />'
    }

    printerText += '<printNormal font="1" data="" />'

    const total = (parseFloat(reservation.order.total).toFixed(2)).toString().replace('.',',');

    printerText += '<printNormal font="4" data="TOTALE ' + total + ' euro"/>';
    printerText += '<printNormal font="1" data="" />'

    let customerBillTotalFlag = false;

    reservation.order.customerBill = reservation.order.customerBill.sort(this.sortByDate);

    for (const customerBill of reservation.order.customerBill) {
        let dayS = new Date(customerBill.date*1000).getDate();
        let monthS = new Date(customerBill.date*1000).getMonth()+1;

        let dayString2 = dayS.toString();
        let monthString2 = monthS.toString();

        if (dayS < 10) {
          dayString2 = '0' + dayString2;
        }

        if (monthS < 10) {
          monthString2 = '0' + monthString2;
        }

        
        let billName = customerBill.name.substring(0, 38);
        billName = customerBill.quantity+ ' x '+billName;

        if (customerBill.type != 'reservation-total') {
          if (customerBill.payed) {
            printerText +=  '<printNormal font="1" data="'+dayString2+'/'+monthString2+' '+billName+' -'+customerBill.price.toFixed(2)+' euro" />';

          } else  {
            printerText +=  '<printNormal font="1" data="'+dayString2+'/'+monthString2+' '+billName +' '+customerBill.price.toFixed(2)+' euro" />';
          }

          customerBillTotalFlag = true;
        }
    }


    if (customerBillTotalFlag) {
      printerText += '<printNormal font="1" data=""/>'
      printerText += '<printNormal font="4" data="RESIDUO '+reservation.order.customerBillTotal.toFixed(2)+' euro"/>'
      printerText += '<printNormal font="1" data=""/>';
    }

    if (reservation.reservationCode) {
      /* printerText += '<printBarCodeTrailer codeType="QRCODE" code="'+reservation.reservationCode+'" barCodeHeigth="4"></printBarCodeTrailer>' */
    }



    const noFiscalData =
    `<?xml version="1.0" encoding="utf-8"?>
    <printerNotFiscal>
      <beginNotFiscal />`
        + printerText +
      `<endNotFiscal />
    </printerNotFiscal>`;


    console.log(noFiscalData);


    /* return new Promise((resolve, reject)=> { */

      return this.customFiscalPrintExec(printer,noFiscalData);
   /*  }) */

  }

  async resetCustomPrinter(printer) {
    let data = '<?xml version="1.0" encoding="utf-8"?><printerCommand><resetPrinter /></printerCommand>';
    return this.customFiscalPrintExec(printer,data);
  }


  async epsonTM_M30_II(reservation,printer) {
    console.log(reservation);

    const optionalsByIds = {};
    this.dataService.optionals.map(opt => {
      optionalsByIds[opt.id] = opt;
    });

    for (const product of reservation.order.products) {
      if (product.type == 'extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'reserved-extra') {
        product.startDate = 1897496437;
      } else if (product.type == 'discount') {
        product.startDate = 1897496437+1;
      } else if (product.type == 'reserved-discount') {
        product.startDate = 1897496437+1;
      }
    }

    reservation.order.products = reservation.order.products.sort(this.sortByStartDate);



    const ePosDev = new epson.ePOSDevice();

    return new Promise((resolve, reject)=> {
		  ePosDev.connect(printer.ip, 8043, (data)=> {
        var options  = {'crypto' : true, 'buffer' : false};

        if (data.indexOf('OK') > -1 ) {
          console.log("connected to ePOS Device Service Interface.", true);
          ePosDev.createDevice("local_printer", ePosDev.DEVICE_TYPE_PRINTER, options, (data,code)=>{
            console.log(data);
            if (data == null) {
              reject({success:false,code: "stampante non trovata"})
            }

		        printer = data;

            printer.addTextStyle(false, false, true, printer.COLOR_1);
            printer.addTextSize(1,2);
            printer.addText('CODICE\tPRENOTAZIONE:\t'+ reservation.reservationCode+'\n');

            let mainCustomer = reservation.customers.find(o => o.principal === true);

            if (mainCustomer) {

              printer.addText(mainCustomer.customer.name+' '+mainCustomer.customer.surname+'\n');
            }

            printer.addTextSize(1,1);
            printer.addTextStyle(false, false, false, printer.COLOR_1);

            let lastDate = null;

            for (const product of reservation.order.products) {

              if (product.startDate && product.startDate != lastDate && product.type != 'extra' && product.type != 'discount' && product.type != 'reserved-extra' && product.type != 'reserved-discount') {

                lastDate = product.startDate;

                if (lastDate != null) {
                  printer.addText('\n');
                }

                const startDate = new Date(product.startDate*1000)
                const startDay = startDate.getDate();
                const startMonth = startDate.getMonth()+1;

                let startDayString = startDay.toString();
                let startMonthString = startMonth.toString();

                if (startDay < 10) {
                  startDayString = '0'+startDay.toString();
                }

                if (startMonth < 10) {
                  startMonthString = '0'+startMonth.toString();
                }

                const endDate = new Date(product.endDate*1000)
                const endDay = endDate.getDate();
                const endMonth = endDate.getMonth() + 1;

                let endDayString = endDay.toString();
                let endMonthString = endMonth.toString();

                if (endDay < 10) {
                  endDayString = '0' + endDay.toString();
                }

                if (endMonth < 10) {
                  endMonthString = '0' + endMonth.toString();
                }

                let dateString = '';

                if ((startDate != endDate) || (!endDate)) {
                  dateString = 'dal '+ startDayString + '/' + startMonthString  + ' al ' + endDayString + '/' + endMonthString;
                } else {
                  dateString = startDayString + '/' + startMonthString;
                }


                printer.addText(dateString + '\n');
              }

              if (product.type == 'package') {

                if (typeof product.quantity === 'undefined' || !product.quantity) {
                  product.quantity = 1;
                }
                
                if (product.col != null) {
                  printer.addText('POSTAZIONE ' + product.label+'\n');
                } else {
                  printer.addText('' + product.label+'\n');
                }

                printer.addText('  ' + product.name[this.dataService.lang] +' x '+ product.length+' gg\n');
                printer.addTextStyle(false, false, true, printer.COLOR_1);
                printer.addText('    ' + product.quantity+' x '+ product.price.toFixed(2)+' euro\n');
                printer.addTextStyle(false, false, false, printer.COLOR_1);

              } else if (product.type == 'optional') {

                printer.addText('  +' + product.quantity+' '+optionalsByIds[product.optionalId].name[this.dataService.lang]+'\n');

                printer.addTextStyle(false, false, true, printer.COLOR_1);
                printer.addText('    ' + product.quantity+' x '+product.price.toFixed(2)+' euro\n');
                printer.addTextStyle(false, false, false, printer.COLOR_1);

              } else if (product.type== 'extra') {

                printer.addText('\nEXTRA\n');
                printer.addText('  ' + this.dataService.resortData.extrasByIds[product.extraId].name[this.dataService.lang]+'\n');
                printer.addTextStyle(false, false, true, printer.COLOR_1);
                printer.addText('    '+ product.quantity + ' x ' + product.price.toFixed(2) + ' euro\n');
                printer.addTextStyle(false, false, false, printer.COLOR_1);

              } else if (product.type == 'discount') {

                printer.addText('SCONTO\n');
                printer.addText('  '+ this.dataService.resortData.discountsByIds[product.discountId].name[this.dataService.lang]+'\n');
                printer.addTextStyle(false, false, true, printer.COLOR_1);
                printer.addText('    -' + product.percentage.toFixed(2) + '  %\n');
                printer.addTextStyle(false, false, false, printer.COLOR_1);

              } else if (product.type == 'reserved-discount') {

                printer.addText('\nSCONTO RISERVATO\n');
                printer.addTextStyle(false, false, true, printer.COLOR_1);
                printer.addText('    -'+product.price.toFixed(2)+' euro\n');
                printer.addTextStyle(false, false, false, printer.COLOR_1);

              } else if (product.type == 'reserved-extra') {

                printer.addText('\nEXTRA\n');
                printer.addTextStyle(false, false, true, printer.COLOR_1);
                printer.addText('    '+product.price.toFixed(2)+'  euro\n');
                printer.addTextStyle(false, false, false, printer.COLOR_1);

              }

            }


            if (reservation.notes) {
              printer.addTextStyle(false, false, true, printer.COLOR_1);
              printer.addText('\NOTE:\n');
              printer.addText(reservation.notes);
              printer.addTextStyle(false, false, true, printer.COLOR_1);
            }

            const total = (parseFloat(reservation.order.total).toFixed(2)).toString().replace('.',',');
            printer.addTextStyle(false, false, true, printer.COLOR_1);
            printer.addTextSize(1,2);
            printer.addText('\nTOTALE '+total+' euro\n\n');
            printer.addTextStyle(false, false, false, printer.COLOR_1);
            printer.addTextSize(1,1);

            let customerBillTotalFlag = false;
            reservation.order.customerBill = reservation.order.customerBill.sort(this.sortByDate);

            for (const customerBill of reservation.order.customerBill) {
                let dayS = new Date(customerBill.date*1000).getDate();
                let monthS = new Date(customerBill.date*1000).getMonth()+1;

                let dayString2 = dayS.toString();
                let monthString2 = monthS.toString();

                if (dayS < 10) {
                  dayString2 = '0' + dayString2;
                }

                if (monthS < 10) {
                  monthString2 = '0' + monthString2;
                }

                if (customerBill.type != 'reservation-total') {

                            
                  let billName = customerBill.name.substring(0, 38);
                  billName = customerBill.quantity+ ' x '+billName;

                  if (customerBill.payed) {
                    printer.addTextStyle(false, false, true, printer.COLOR_1);
                    printer.addText(dayString2+'/'+monthString2+' '+billName+' -'+customerBill.price.toFixed(2)+' euro\n');
                    printer.addTextStyle(false, false, true, printer.COLOR_1);

                  } else  {

                    printer.addTextStyle(false, false, true, printer.COLOR_1);
                    printer.addText(dayString2+'/'+monthString2+' '+ billName +' '+customerBill.price.toFixed(2)+' euro\n');
                    printer.addTextStyle(false, false, true, printer.COLOR_1);
                  }

                  customerBillTotalFlag = true;
                }
            }



            if (customerBillTotalFlag) {
              let cTotal = reservation.order.customerBillTotal.toFixed(2);
              printer.addTextStyle(false, false, true, printer.COLOR_1);
              printer.addTextSize(1,2);
              printer.addText('\nRESIDUO '+cTotal+' euro\n\n');
              printer.addTextStyle(false, false, false, printer.COLOR_1);
              printer.addTextSize(1,1);
            }


            printer.addTextPosition(220);
            printer.addSymbol(reservation.reservationCode, printer.SYMBOL_QRCODE_MODEL_2, printer.LEVEL_DEFAULT, 7, 0, 0);
            printer.addCut(printer.CUT_FEED);
            printer.send();
            console.log("PRINTED")
            resolve({success:true, message:"connected to ePOS Device Service Interface with SSL."})
          });
        } else {
          console.log("connected to ePOS Device Service Interface is failed. [" + data + "]", true);
          reject({success:false,code: "connected to ePOS Device Service Interface is failed. [" + data + "]"})
        }
      });
    })

    /* return new Promise((resolve, reject)=> {

      ePosDev.onreceive = (res, tag_list_names, add_info, res_add) => {
          console.log(res, tag_list_names, add_info, res_add);
          self.printed = true;
          resolve(res);
        };

      ePosDev.onerror = (err) =>{
        console.log(err);
        if (err && err !== undefined) {
          reject(err)
        }
      }
    }) */
  }

  async customTest() {
    let print = `<printerNotFiscal>.
        <beginNotFiscal>
        </beginNotFiscal>
        Testo
        <endNotFiscal></endNotFiscal>
    </printerNotFiscal>`
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }


}
