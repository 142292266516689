import { Component, OnInit,Input, OnChanges } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-settings-sidenav',
  templateUrl: './settings-sidenav.component.html',
  styleUrls: ['./settings-sidenav.component.scss']
})
export class SettingsSidenavComponent implements OnInit {

  @Input() menuIndex;
  @Input() submenuIndex;

  resortData; managerData; menuShowed;

  constructor(private router: Router,private route: ActivatedRoute,public dataService: DataService, private settingsService: SettingsService) {
    this.submenuIndex = 0;
    this.route.params.subscribe(params =>{
      if (params['menuIndex']) {
        this.submenuIndex = params['menuIndex'];
      }
    });
  }

  async ngOnInit() {
    this.menuShowed = this.menuIndex;
    this.managerData = this.dataService.userData;

    if (!this.managerData) {
      this.managerData = await this.dataService.getUserData();
    }

  }

}
