import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CustomersService } from 'src/app/core/providers/customer.service';
import { DataService } from 'src/app/core/providers/data.service';
import { ReservationService } from 'src/app/core/providers/reservation.service';
import { SettingsService } from 'src/app/core/providers/settings.service';
import { ModalErrorComponent } from 'src/app/shared/components/modal-error/modal-error.component';
import { ModalPrintersComponent } from 'src/app/shared/components/modal-printers/modal-printers.component';
import { ModalQuotationComponent } from 'src/app/shared/components/modal-quotation/modal-quotation.component';
import { ModalRefundComponent } from 'src/app/shared/components/modal-refund/modal-refund.component';
import { ModalRemoveComponent } from 'src/app/shared/components/modal-remove/modal-remove.component';
import { ModalStatesComponent } from 'src/app/shared/components/modal-states/modal-states.component';

@Component({
  selector: 'app-reservation-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnChanges {

  @Input() reservation:any;
  @Input() changes:any;
  @Input() resortData:any;
  @Input() lightLoading:any;
  @Input() fiscalPrintFlag:any;
  @Input() loading:any;
  @Input() mainCustomer:any;
  @Input() associatedCustomers:any;
  @Input() paymentType:any;
  @Input() clicked:any;
  @Input() refundsAvailable:any;
  @Input() managerData:any;
  @Output() updateReservationEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCustomerEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() initReservationEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCustomerBillEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitLightLoading: EventEmitter<any> = new EventEmitter<any>();
  showQuotationButtons = false;quotationTooltip = false;
  today = (new Date()).setUTCHours(0, 0, 0, 0) / 1000;
  showExtraOptions = false;
  alreadyPaid = false;

  constructor(
    private router: Router,
    public dialog:MatDialog,
    public dataService:DataService,
    public settingsService: SettingsService,
    public reservationService: ReservationService,
    private storage: AngularFireStorage,
    private customerService:CustomersService
    ) {

  }

  ngOnChanges() {
    console.log(this.paymentType);
    if (this.reservation) {
      this.checkFiscalPrintFlag();
      if (this.reservation.order.customerBill && this.reservation.order.customerBill.find(c => c.paymentType === 'credit-card' && c.payed)) {
        this.alreadyPaid = true;
      }
    }
  }

  checkFiscalPrintFlag () {
    this.fiscalPrintFlag = false;
    let noPayed = true;

    for (const customerBill of this.reservation.order.customerBill) {
      if (customerBill.payed) {
        noPayed = false;
      }

      if (customerBill.payed && !customerBill.fiscalPrinted){
        this.fiscalPrintFlag = true;
        break;
      }
    }

    if (noPayed || this.reservation.order.customerBillTotal > 0) {
      this.fiscalPrintFlag = true;
    }

    console.log(this.fiscalPrintFlag);
  }


  async getDigitalQuotation() {

    try {

      this.updateCustomerEmitter.emit();
      this.updateReservationEmitter.emit();
      const dialogRef = this.dialog.open(ModalQuotationComponent, {
        width: '800px',
        data: {
          reservation: this.reservation,
          phone: this.mainCustomer.phone,
          phonePrefix: this.mainCustomer.phonePrefix,
          name: this.mainCustomer.name
        }
      });

      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          this.emitLightLoading.emit(true);
          await this.saveReservation(0, false, false);
          this.initReservationEmitter.emit(this.reservation)
          this.emitLightLoading.emit(false);
        }
      })
    }
    catch(err) {
      // console.log(err);
    }
  }

  async sendWhatsapp() {
    let res = await this.generatePdfQuotation();
    let url = res.url.replace('/'+this.dataService.resortData.id+'/', '%2F'+this.dataService.resortData.id+'%2F')
    url = url.replace(/%2F/g, "%252F")
    let text = "Ciao "+this.mainCustomer.name+", a questo link puoi trovare l'offerta a te dedicata da "+this.dataService.resortData.name+": "+url;
    console.log(text);
    window.open(`https://wa.me/`+this.mainCustomer.phonePrefix + this.mainCustomer.phone+`?text=`+text, '_blank');
  }

  async errorWhatsapp() {

    const dialogRef = this.dialog.open(ModalErrorComponent, {
      width: '400px',
      data: {
        type: 'reservation-error',
        error: "Verifica di aver inserito nome e numero di telefono"
      }
    });
  }


  async saveReservation(allCheckIn, print, redirect) {

    try {
      this.emitLightLoading.emit(true)
      this.clicked = true;
      // CHECK-IN = 1 -> PRIMO GIORNO PRENOTAZIONE - SOLO PER NUOVE PRENOTAZIONI
      // CHECK-IN = 2 -> CHECK-IN PER IL GIORNO ATTUALE
      // CHECK-IN = 3 -> CHECK-IN PER TUTTI I GIORNI

      // SAVE CUSTOMER

      await this.updateCustomers();

      // SAVE RESERVATIONS MAPS

      await this.settingsService.checkDatesIntervalsAndUpdateDisableDates(this.reservation);
      let httpCall = null;

      if (this.refundsAvailable.length) {
        console.log(this.refundsAvailable);

        for (let refund of this.refundsAvailable) {
          console.log(refund);
          await this.customerService.updateRefund(refund);
        }
      }

      if (!this.reservation.id || this.reservation.id === 'new') {

        this.reservation.createdAt = Math.trunc(new Date().getTime() / 1000);
        this.reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
        this.reservation.createdBy = this.settingsService.getCurrentUserData();
        this.reservation.updatedBy = this.settingsService.getCurrentUserData();
        let ipraticoSetting = this.resortData.settings.manager.externalIntegrations.find((o:any)=>o.type === 'i-pratico');

        if (ipraticoSetting && ipraticoSetting.enabled && ipraticoSetting.productId && ipraticoSetting.key_test && this.reservation.length >= 2 && this.reservation.customerId) {
          // SALVA SU IPRATICO
          console.log("SALVA SU IPRATICO")
          console.log(this.mainCustomer);
          console.log(ipraticoSetting);
          //await this.dataService.saveIpraticoReservation(this.reservation,this.mainCustomer,ipraticoSetting.productId,ipraticoSetting.key_test);
        }
        console.log(JSON.stringify(this.reservation));
        if (!allCheckIn) {
          httpCall = await this.dataService.setReservation(this.reservation).catch(err => {
            return err;
          });
        } else {
          httpCall = await this.dataService.saveAndCheckInReservation(this.reservation).catch(err => {
            return err;
          });
        }

      } else {
        this.reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
        this.reservation.updatedBy = this.settingsService.getCurrentUserData();

        this.reservation.version = 4;

        console.log(JSON.stringify(this.reservation));
        httpCall = await this.dataService.updateReservation(this.reservation).catch(err => {
          console.log(err)
          return err;
        });

        console.log(httpCall)
      }

      this.emitLightLoading.emit(false);
      this.dataService.selectedReservation = null;
      this.dataService.mainCustomer = null;
      this.dataService.associatedCustomers = null;

      if (!httpCall.error) {
        // console.log(httpCall);
        this.reservation = httpCall;
      }
      this.clicked = false;

      return this.manageHttpError(httpCall, print, redirect);

    } catch (err) {
      console.log(err);
      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'reservation-error',
          error: "C'è stato un errore nel salvataggio. Verifica che i dati siano coerenti. In caso di stampa fiscale, verifica che questa sia stata tracciata nella prenotazione."
        }
      });
      return err;
    }

  }

  async updateCustomers() {
    this.reservation.searchKey = '';
    this.reservation.relatedCustomers = [];
    console.log("UPDATE CUSTOMER");
    console.log(this.reservation.customerId);
    console.log(this.mainCustomer);

    if (!this.reservation.customerId && (this.mainCustomer.name.trim() !== '' || this.mainCustomer.surname.trim() !== '')) {
      // console.log("SALVA NUOVO CLIENTE")
      let nC = JSON.parse(JSON.stringify(this.mainCustomer));
      console.log(nC);
      nC.resortId = this.resortData.id;

      if (!nC.uid) {
        delete nC.uid;
      }
      const newCustomer = await this.dataService.setCustomer(nC);
      // console.log(newCustomer);
      this.reservation.customerId = newCustomer.id;
    } else if (this.reservation.customerId) {

      let customerToSave = {
        id: this.mainCustomer.id,
        name: this.mainCustomer.name,
        surname:this.mainCustomer.surname,
        phone:this.mainCustomer.phone,
        phonePrefix:this.mainCustomer.phonePrefix,
        email: this.mainCustomer.email
      }
      await this.dataService.updateCustomer(customerToSave);
    }

    if (this.reservation.customerId ) {
      this.reservation.relatedCustomers.push({
        customer: this.reservation.customerId,
        principal: true
      });
    }

    if (this.mainCustomer.name !== '' || this.mainCustomer.surname !== '') {
      this.reservation.searchKey += this.mainCustomer.name + ' ' + this.mainCustomer.surname + '_';
    }

    console.log(this.associatedCustomers)

    for (const relatedCustomer of this.associatedCustomers) {

      if (!relatedCustomer.id && (relatedCustomer.name !== '' || relatedCustomer.surname !== '')) {
        relatedCustomer.resortId = this.resortData.id;
        const newCustomer = await this.dataService.setCustomer(relatedCustomer);
        relatedCustomer.id = newCustomer.id;
      } else if (relatedCustomer.id) {

        let customerToSave = {
          id: relatedCustomer.id,
          name: relatedCustomer.name,
          surname:relatedCustomer.surname,
          phone:relatedCustomer.phone,
          phonePrefix:relatedCustomer.phonePrefix,
          email: relatedCustomer.email
        }
        console.log(customerToSave);
        await this.dataService.updateCustomer(customerToSave);
      }

      if (relatedCustomer.id) {
        this.reservation.searchKey += relatedCustomer.name + ' ' + relatedCustomer.surname + '_';

        this.reservation.relatedCustomers.push({
          customer: relatedCustomer.id,
          principal: false
        });
      }
    }

    if (this.reservation.searchKey.length > 254)
      this.reservation.searchKey = this.reservation.searchKey.substring(0,254);
  }

  async totalCheckin(reservation) {
    this.emitLightLoading.emit(true);
    for (const date of reservation.dates) {
      for (const slot of date.order) {

        let findCheckIn = false;
        let findFree = false;

        for (let ts = date.start; ts <= date.end; ts += 24 * 60 * 60) {
          const slotKeys = [];
          if (slot.checkin) {
            findCheckIn = slot.checkin.find(ts1 => ts1.start >= ts && ts1.end <= ts);
          }

          if (slot.free) {
            findFree = slot.free.find(ts1 => ts1.start >= ts && ts1.end <= ts);
          }

          if (!findCheckIn && !findFree && slot.type === 'slot') {
            slotKeys.push(slot.slot_key);
          }

          if (slotKeys.length) {
            console.log(ts);
            const httpCall = await this.dataService.checkInSlot(reservation.id, date.date_key, slotKeys, ts, ts, this.settingsService.getCurrentUserData() );

            this.manageHttpError(httpCall, false, false);
          }
        }

      }

      this.reservation = await this.dataService.getSingleReservation(this.reservation.id);
      this.initReservationEmitter.emit(this.reservation)
    }

    await this.settingsService.setDateIntervalAvailabilityAndSlotStates(this.reservation,this.dataService.mapByRowCol);
    this.emitLightLoading.emit(false);
  }

  async todayCheckin(reservation) {
    this.emitLightLoading.emit(true);
    const slotKeys = [];
    const selectedInterval = reservation.dates.find(date => date.start <= this.today && this.today <= date.end);
    for (const slot of selectedInterval.order) {

      let findCheckIn = false;
      let findFree = false;

      if (slot.checkin) {
        findCheckIn = slot.checkin.find(ts => ts.start >= this.today && ts.end <= this.today);
      }

      if (slot.free) {
        findFree = slot.free.find(ts => ts.start >= this.today && ts.end <= this.today);
      }

      if (!findCheckIn && !findFree && slot.type == 'slot') {
        slotKeys.push(slot.slot_key);
      }
    }


    if (slotKeys.length) {
      const httpCall = await this.dataService.checkInSlot(reservation.id, selectedInterval.date_key, slotKeys, this.today, this.today, this.settingsService.getCurrentUserData() );

      this.manageHttpError(httpCall, false, false);
      this.reservation = await this.dataService.getSingleReservation(this.reservation.id);
      this.initReservationEmitter.emit(this.reservation)
    }

    await this.settingsService.setDateIntervalAvailabilityAndSlotStates(this.reservation,this.dataService.mapByRowCol);
    this.emitLightLoading.emit(false);
  }

  async todayCheckout(reservation) {
    this.emitLightLoading.emit(true);
    const slotKeys = [];
    const selectedInterval = reservation.dates.find(date => date.start <= this.today && this.today <= date.end);
    for (const slot of selectedInterval.order) {

      let findCheckout = false;
      let findFree = false;
      let findCheckin = false;

      if (slot.checkin) {
        findCheckin = slot.checkin.find(ts => ts.start >= this.today && ts.end <= this.today);
      }

      if (slot.checkout) {
        findCheckout = slot.checkout.find(ts => ts.start >= this.today && ts.end <= this.today);
      }

      if (slot.free) {
        findFree = slot.free.find(ts => ts.start >= this.today && ts.end <= this.today);
      }

      if (findCheckin && !findCheckout && !findFree) {
        slotKeys.push(slot.slot_key);
      }
    }

    // console.log(slotKeys);

    if (slotKeys.length) {
      const httpCall =  await this.dataService.checkOutSlot(reservation.id, selectedInterval.date_key, slotKeys, this.today, this.today, this.settingsService.getCurrentUserData() )
      this.manageHttpError(httpCall, false, false);
      this.reservation = await this.dataService.getSingleReservation(this.reservation.id);
      this.initReservationEmitter.emit(this.reservation)
    }
    await this.settingsService.setDateIntervalAvailabilityAndSlotStates(this.reservation,this.dataService.mapByRowCol);

    this.emitLightLoading.emit(false);
  }

  manageHttpError(httpCall, print, redirect) {

    if (httpCall.error) {

      let error = httpCall.error;

      if (httpCall.error.error) {
        error = httpCall.error.error;
      }

      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'reservation-error',
          error: error
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (redirect) {
          if (!this.dataService.previousPage || this.dataService.previousPage === 'reservation') {
            this.router.navigate(['/suite/home']);
          } else {
            this.router.navigate(['/suite/' + this.dataService.previousPage]);
          }
        }
      });
      return false;
    } else {

      if (httpCall.id) {
        this.reservation.id = httpCall.id;
        this.reservation.reservationCode = httpCall.reservationCode;
      }


      if (!print && redirect) {
        if (!this.dataService.previousPage || this.dataService.previousPage === 'reservation') {
          this.router.navigate(['/suite/home']);
        } else {
          this.router.navigate(['/suite/' + this.dataService.previousPage]);
        }
      } else {
        this.clicked = false;
        return true;
      }
    }
  }


  async refundReservation() {
    const dialogRef = this.dialog.open(ModalRefundComponent, {
      width: '340px',
      disableClose: true,
      data: {
        reservation: this.reservation
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        this.reservation.refundState = result.refundState;
        this.reservation.refundAmount = result.refundAmount;
        this.reservation.refundExpiration = result.refundExpiration;
      }
    });
  }

  async removeReservation() {

      const dialogRef = this.dialog.open(ModalRemoveComponent, {
        width: '340px',
        height: '130px',
        data: {
          type: 'reservations',
          reservation: this.reservation
        }
      });

      dialogRef.afterClosed().subscribe(result => {
            // console.log(result);
            this.clicked = false;
      });
  }

  async fiscalPrintOnlyCustomerBillSaved() {
    try {

      if (this.mainCustomer.lotteryCode && this.mainCustomer.lotteryCode.trim() !== '') {
        this.reservation.lotteryCode = this.mainCustomer.lotteryCode;
      }

      if (this.reservation.id && this.reservation.id !== 'undefined' && this.reservation.id !== null) {

        const dialogRef = this.dialog.open(ModalPrintersComponent, {
          width: '400px',
          data: {
            fiscalPrint: true,
            reservation: this.reservation,
            paymentType: this.paymentType
          }
        });

        dialogRef.afterClosed().subscribe(async result => {

          if (result) {
            this.emitLightLoading.emit(true);
            this.updateCustomerBillEmitter.emit({reservation:this.reservation, settled:true});

            for (const bill of this.reservation.order.customerBill) {
              if (bill.payed && bill.type == 'settlement') {
                bill.type = 'movement'
                bill.settledFlag = false;
                bill.fiscalPrinter = result;
                bill.fiscalPrinted = true;
                bill.printedBy = this.settingsService.getCurrentUserData();
              } else if (bill.payed && bill.type === 'movement' && bill.name === 'Pagamento online') {
                bill.settledFlag = false;
                bill.fiscalPrinter = result;
                bill.fiscalPrinted = true;
                bill.printedBy = this.settingsService.getCurrentUserData();
              }

              if (bill.id) {
                await this.dataService.updateSingleCustomerBill(this.reservation.id, bill);
              } else {
                let newBill = await this.dataService.insertSingleCustomerBill(this.reservation.id,bill);
                bill.id = newBill.id;
              }

            }
            await this.settingsService.setDateIntervalAvailabilityAndSlotStates(this.reservation,this.dataService.mapByRowCol);
            this.emitLightLoading.emit(false);
          }
        });
      } else {

        const dialogRef = this.dialog.open(ModalErrorComponent, {
          width: '400px',
          data: {
            type: 'reservation-error',
            error: "C'è stato un errore nel salvataggio. Verifica che i dati siano coerenti."
          }
        });

        this.emitLightLoading.emit(false);
      }
    } catch(err) {

      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'reservation-error',
          error: "C'è stato un errore nel salvataggio. Verifica che i dati siano coerenti."
        }
      });
      return err;
    }
  }

  async fiscalPrint() {

    try {

      this.reservation.order.settled = false;
      await this.saveReservation(0, true, false);

      if (this.mainCustomer.lotteryCode && this.mainCustomer.lotteryCode.trim() !== '') {
        this.reservation.lotteryCode = this.mainCustomer.lotteryCode;
      }

      if (this.reservation.id && this.reservation.id !== 'undefined' && this.reservation.id !== null) {

        const dialogRef = this.dialog.open(ModalPrintersComponent, {
          width: '400px',
          data: {
            fiscalPrint: true,
            reservation: this.reservation,
            paymentType: this.paymentType
          }
        });

        dialogRef.afterClosed().subscribe(async result => {
          console.log("RESULT FROM MODAL");
          console.log(result);
          if (result) {

            this.updateCustomerBillEmitter.emit({reservation:this.reservation, settled:true});
            console.log(JSON.parse(JSON.stringify(this.reservation.order.customerBill)));
            for (const bill of this.reservation.order.customerBill) {
              if (bill.payed && bill.type == 'settlement') {
                bill.type = 'movement'
                bill.settledFlag = false;
                bill.fiscalPrinter = result;
                bill.fiscalPrinted = true;
                bill.printedBy = this.settingsService.getCurrentUserData();
              } else if (bill.payed && bill.type === 'movement' && bill.name === 'Pagamento online') {
                bill.settledFlag = false;
                bill.fiscalPrinter = result;
                bill.fiscalPrinted = true;
                bill.printedBy = this.settingsService.getCurrentUserData();
              }

            }
            console.log(JSON.parse(JSON.stringify(this.reservation.order.customerBill)));
            // console.log(JSON.parse(JSON.stringify(this.reservation.order)));
            await this.saveReservation(false, false, false);
            await this.settingsService.setDateIntervalAvailabilityAndSlotStates(this.reservation,this.dataService.mapByRowCol);
            this.initReservationEmitter.emit(this.reservation);
          } 
        });
      } else {

        const dialogRef = this.dialog.open(ModalErrorComponent, {
          width: '400px',
          data: {
            type: 'reservation-error',
            error: "C'è stato un errore nel salvataggio. Verifica che i dati siano coerenti."
          }
        });
      }
    } catch(err) {

      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'reservation-error',
          error: "C'è stato un errore nel salvataggio. Verifica che i dati siano coerenti."
        }
      });
      return err;
    }
  }

  async fiscalPrintNoSave() {

    try {

      await this.saveReservation(0, true, false);

      if (this.mainCustomer.lotteryCode && this.mainCustomer.lotteryCode.trim() !== '') {
        this.reservation.lotteryCode = this.mainCustomer.lotteryCode;
      }

      if (this.reservation.id && this.reservation.id !== 'undefined' && this.reservation.id !== null) {

        const dialogRef = this.dialog.open(ModalPrintersComponent, {
          width: '400px',
          data: {
            fiscalPrint: true,
            reservation: this.reservation,
            paymentType: this.paymentType
          }
        });

        dialogRef.afterClosed().subscribe(async result => {

          if (result) {

            this.updateCustomerBillEmitter.emit({reservation:this.reservation, settled:true});

            for (const bill of this.reservation.order.customerBill) {
              if (bill.payed && bill.type == 'settlement') {
                bill.type = 'movement'
                bill.settledFlag = false;
                bill.fiscalPrinter = result;
                bill.fiscalPrinted = true;
                bill.printedBy = this.settingsService.getCurrentUserData();
               }else if (bill.payed && bill.type === 'movement' && bill.name === 'Pagamento online') {
                bill.settledFlag = false;
                bill.fiscalPrinter = result;
                bill.fiscalPrinted = true;
                bill.printedBy = this.settingsService.getCurrentUserData();
              }
            }
            // console.log(JSON.parse(JSON.stringify(this.reservation.order)));
            await this.saveReservation(false, false, false);
            await this.settingsService.setDateIntervalAvailabilityAndSlotStates(this.reservation,this.dataService.mapByRowCol);
          }
        });
      } else {

        const dialogRef = this.dialog.open(ModalErrorComponent, {
          width: '400px',
          data: {
            type: 'reservation-error',
            error: "C'è stato un errore nel salvataggio. Verifica che i dati siano coerenti."
          }
        });
      }
    } catch(err) {

      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'reservation-error',
          error: "C'è stato un errore nel salvataggio. Verifica che i dati siano coerenti."
        }
      });
      return err;
    }
  }

  async noFiscalPrint() {
    await this.saveReservation(0, true, false);

    const dialogRef = this.dialog.open(ModalPrintersComponent, {
      width: '400px',
      data: {
        fiscalPrint: false,
        reservation: this.reservation,
        paymentType: this.paymentType
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      // console.log(result);
      await this.settingsService.setDateIntervalAvailabilityAndSlotStates(this.reservation,this.dataService.mapByRowCol);
      await this.settingsService.checkDatesIntervalsAndUpdateDisableDates(this.reservation);
    });
  }

  async getQuotation() {

    this.emitLightLoading.emit(true);
    let res = await this.generatePdfQuotation();
    setTimeout(() => {
      window.open(res.url);
      this.emitLightLoading.emit(false);
    }, 2000);

  }

  async generatePdfQuotation() {
    this.emitLightLoading.emit(true);
    const self = this;

    let logo = null;

    if (this.dataService.resortData.logo) {
      logo = this.dataService.resortData.logo;
    }

    let cabins = null;

    if (this.dataService.resortData.cabins) {
      cabins = this.dataService.resortData.cabins;
    }

    console.log(cabins);

    const resort = {
      id: this.dataService.resortData.id,
      address: this.dataService.resortData.address,
      city: this.dataService.resortData.city,
      email: this.dataService.resortData.email,
      generalManager: this.dataService.resortData.generalManager,
      name: this.dataService.resortData.name,
      region: this.dataService.resortData.region,
      phone: this.dataService.resortData.phone,
      zipCode: this.dataService.resortData.zipCode,
      logo,
      cabins
    };

    // console.log(JSON.parse(JSON.stringify(this.reservation)));

    let reservationSnapshot = JSON.parse(JSON.stringify(this.reservation));

    if (this.mainCustomer.name !== '' || this.mainCustomer.surname !== '' ) {
      reservationSnapshot.name = this.mainCustomer.name;
      reservationSnapshot.surname = this.mainCustomer.surname;
      reservationSnapshot.phone = this.mainCustomer.phone;
      reservationSnapshot.email = this.mainCustomer.email;
    }

    const postData = {
      reservation: reservationSnapshot,
      resort
    };

    if (this.reservation.seasonTicket) {
      postData.reservation.seasonTicket = true;
    }

    /* postData.reservation.name = this.name; */
    console.log(postData);
    const res = await this.dataService.getQuotation(postData);
    console.log(res);
    this.emitLightLoading.emit(false);
    this.clicked = false;

    return res;
  }


  async oneDayCheckIn() {
    await this.saveReservation(0, false, false);
    await this.totalCheckin(this.reservation);
    if (!this.dataService.previousPage || this.dataService.previousPage === 'reservation') {
      this.router.navigate(['/suite/home']);
    } else {
      this.router.navigate(['/suite/' + this.dataService.previousPage]);
    }
  }

  exportQRcodePDF() {
    this.reservationService.exportQRcodePDF(this.reservation,this.reservation.reservationCode);
  }

  async oneDayCheckOut() {
    await this.todayCheckout(this.reservation);

    if (!this.dataService.previousPage || this.dataService.previousPage === 'reservation') {
      this.router.navigate(['/suite/home']);
    } else {
      this.router.navigate(['/suite/' + this.dataService.previousPage]);
    }
  }


  async openStatesModal() {
    const dialogRef = this.dialog.open(ModalStatesComponent, {
      width: '850px',
      data: {
        reservation: this.reservation
      }
    });

    dialogRef.afterClosed().subscribe(async state => {
      if (state === 'total-checkin') {
        await this.totalCheckin(this.reservation);
      } else if (state === 'today-checkin') {
        await this.todayCheckin(this.reservation);
      } else if (state === 'total-checkout'){

      } else if (state === 'today-checkout'){
        await this.todayCheckout(this.reservation);
      }

      this.clicked = false;

    });
  }


  async printReceipt() {

    try {
      this.showExtraOptions = false;
      this.emitLightLoading.emit(true);

      let data = await this.dataService.getReceipt(this.reservation.reservationCode);
      console.log(data)
      if (data.results) {
        let receipt = data.results[0];
        const filePath = '/receipts/' + receipt.date + '/' + receipt.receiptId.replace('/', '_') + '.pdf';
        const ref = this.storage.ref(filePath);

        const url = await ref.getDownloadURL().toPromise();

        console.log(url);
        window.open(url);
      } else {

      }

      this.emitLightLoading.emit(false);

    } catch (err){
      if (err.code_ && err.code_ === 'storage/object-not-found') {
        console.log("GENERA NUOVO PDF");

        //let url = await this.dataService.generateReceipt(this.receipt);

        setTimeout(() => {
          this.emitLightLoading.emit(false);
        }, 200);

      } else {
        console.log(err);
      }
    }
  }


  async updateReservation() {
    try {
      this.reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
      this.reservation.updatedBy = this.settingsService.getCurrentUserData();

      this.reservation.version = 4;

      let httpCall = await this.dataService.updateReservation(this.reservation);
      if (!httpCall.error) {
        this.reservation = httpCall;
      }
    } catch(err) {
      // console.log(err);
    }
}


}
