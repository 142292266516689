import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service'
import { SettingsService } from '../../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';
import { ModalErrorComponent } from '../../../../shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-manager-detail',
  templateUrl: './manager-detail.component.html',
  styleUrls: ['./manager-detail.component.scss']
})
export class ManagerDetailComponent implements OnInit {

  resortData; managerData; pageClicked; prices; managerId; lang = 'it';managers;
  selectedManager; optionals; dataLang='it'; rule1=0; elements; features;services; mapAreas;
  objectKeys = Object.keys;uploadPercent; clicked= false; selectedAreas = []; selectAllAreasFlag;
  selectedZones = []; selectedAllZonesFlag;
  hide = false;

  permissions = {
    all: false,
    payments: false,
    statistics: false,
    export: false,
    planner: false,
    notifications: false,
    settings: {
      access: false,
      profile: false,
      offer: false,
      cocobuk: false,
      advanced: false
    },
    customers: {
      read: false,
      create: false,
      update: false,
      delete: false
    },
    reservations: {
      read: false,
      create: false,
      update: false,
      delete: false,
      checkin: true,
      checkout: true,
      reserve: false,
      free: false,
      refund: false,
      stats: false
    }
  }

  zones;

  constructor(public router: Router, public dialog: MatDialog,private route: ActivatedRoute, private dataService: DataService, private settingsService: SettingsService) {
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.dataLang = this.dataService.lang;
  }

  async ngOnInit() {

    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;
    this.prices = this.dataService.prices;

    this.managers = await this.dataService.getResortManagers();
    this.mapAreas = (await this.dataService.getMapAreas()).results;

    this.mapAreas.sort(this.sortByCol);
    console.log(this.managers)
    this.initManager();

  }

  initManager() {
    this.managerId = this.route.snapshot.paramMap.get('managerId');
    let foundManager = this.managers.find(manag => manag.id == this.managerId);
    if (foundManager) {
      this.selectedManager = JSON.parse(JSON.stringify(foundManager));
    } else {
      this.managerId = null;
      this.selectedManager = {
        first_name: '',
        last_name:  '',
        email: '',
        password: '',
        role: 'assistant',
        resortId: this.resortData.id,
        permissions:{
          all: false
        },
        defaultMapArea: 0,
        mapAreas: null,
        is_active: true,
        supermanager: false,
        id: null,
        zonesDisabled: []
      }
    }


    if (!this.selectedManager.zonesDisabled) {
      this.selectedManager.zonesDisabled = [];
    }

    this.zones = JSON.parse(JSON.stringify(this.resortData.zones));

    for (let zone of this.zones) {
      zone.selected = true;

      if (this.selectedManager.zonesDisabled.indexOf(zone.id) > -1) {
        zone.selected = false;
      }
    }


    for (let area of this.mapAreas) {
      area.selected = false;
    }

    if (!this.selectedManager.mapAreas) {
      this.selectAllAreasFlag = true;
      for (let area of this.mapAreas) {
        area.selected = true;
        this.selectedAreas.push(area);
      }

      if (!this.selectedManager.defaultMapArea) {
        this.selectedManager.defaultMapArea = 0;
      }
    } else {
      for (let userArea of this.selectedManager.mapAreas) {
        let findArea = this.mapAreas.find(area => area.id === userArea);
        findArea.selected = true;
        this.selectedAreas.push(findArea);
      }

      if (!this.selectedManager.defaultMapArea) {
        this.selectedManager.defaultMapArea = this.selectedAreas[0].id;
      }
    }

    if (!this.selectedManager.settingsManager) {
      this.selectedManager.settingsManager = {
        reservation_list: {
          sortBy: '',
          fields: {}
        },
        attendance_list: {
          sortBy: '',
          fields: {}
        },
        printers: {
          defaultFiscal: null,
          defaultNoFiscal: null
        }
      }
    } else if (!this.selectedManager.settingsManager.printers) {
      this.selectedManager.settingsManager.printers = {
        defaultFiscal: null,
        defaultNoFiscal: null
      }
    }

    console.log(this.selectedManager);
  }

  selectAllAreas() {

    if (this.selectAllAreasFlag) {
      this.selectedAreas = [];

      for (let area of this.mapAreas) {
        area.selected = true;
        this.selectedAreas.push(area);
      }

      if (!this.selectedManager.defaultMapArea) {
        this.selectedManager.defaultMapArea = this.mapAreas[0].id;
      }

      this.selectedManager.mapAreas = null;
    }

  }

  updateDefaultAreas() {
    this.selectedAreas = [];
    for (let area of this.mapAreas) {
      if (area.selected) {
        this.selectedAreas.push(area);
      }
    }
  }

  updateZones(zone) {
    console.log(zone);
  }

  resetHeader() {

  }

  sortByCol( a, b ) {
    if ( a.startColumn < b.startColumn ) {
      return -1;
    }
    if ( a.startColumn > b.startColumn ) {
      return 1;
    }
    return 0;
  }

  async saveManager() {

    try {
      console.log(this.selectedManager);

      this.selectedManager.permissions = JSON.parse(JSON.stringify(this.permissions));

      if (this.selectAllAreasFlag) {
        this.selectedManager.mapAreas = null;
      } else {
        this.selectedManager.mapAreas = [];
        for (const area of this.selectedAreas) {
          this.selectedManager.mapAreas.push(area.id)
        }

        if (!this.selectedManager.defaultMapArea) {
          this.selectedManager.defaultMapArea = this.selectedAreas[0].id;
        }

      }

      if (this.selectedManager.role === 'admin' || this.selectedManager.role === 'demo' || this.selectedManager.role === 'owner' || this.selectedManager.role === 'superowner' || this.selectedManager.role === 'supermanager') {
        this.selectedManager.permissions = {
          all: true,
          payments: true,
          statistics: true,
          export: true,
          planner: true,
          notifications: true,
          settings: {
            access: true,
            profile: true,
            offer: true,
            cocobuk: true,
            advanced: true
          },
          customers: {
            read: true,
            create: true,
            update: true,
            delete: true
          },
          reservations: {
            read: true,
            create: true,
            update: true,
            delete: true,
            checkin: true,
            checkout: true,
            reserve: true,
            free: true,
            refund: true,
            stats: true
          }
        }
      } else if (this.selectedManager.role === 'manager') {

        this.selectedManager.permissions = this.permissions;
        this.selectedManager.permissions.planner = true;
        this.selectedManager.permissions.payments = true;
        this.selectedManager.permissions.statistics = true;
        this.selectedManager.permissions.export = true;
        this.selectedManager.permissions.settings.advanced = true;
        this.selectedManager.permissions.settings.offer = true;
        this.selectedManager.permissions.settings.cocobuk = true;
        this.selectedManager.permissions.settings.access = true;

        this.selectedManager.permissions.customers.create = true;
        this.selectedManager.permissions.customers.update = true;
        this.selectedManager.permissions.customers.delete = true;
        this.selectedManager.permissions.customers.read = true;

        this.selectedManager.permissions.reservations.create = true;
        this.selectedManager.permissions.reservations.update = true;
        this.selectedManager.permissions.reservations.delete = true;
        this.selectedManager.permissions.reservations.checkin = true;
        this.selectedManager.permissions.reservations.checkout = true;
        this.selectedManager.permissions.reservations.reserve = true;
        this.selectedManager.permissions.reservations.free = true;
        this.selectedManager.permissions.reservations.refund = true;
        this.selectedManager.permissions.reservations.stats = true;
        this.selectedManager.permissions.reservations.read = true;

      } else if (this.selectedManager.role === 'operator') {

        this.selectedManager.permissions = this.permissions;
        this.selectedManager.permissions.planner = true;

        this.selectedManager.permissions.reservations.create = true;
        this.selectedManager.permissions.reservations.update = true;
        this.selectedManager.permissions.customers.create = true;
        this.selectedManager.permissions.customers.update = true;
        this.selectedManager.permissions.customers.read = true;
        this.selectedManager.permissions.reservations.read = true;
        this.selectedManager.permissions.reservations.reserve = true;
        this.selectedManager.permissions.reservations.free = true;

      } else if (this.selectedManager.role === 'assistant') {

        this.selectedManager.permissions = this.permissions;

        this.selectedManager.permissions.reservations.checkin = true;
        this.selectedManager.permissions.reservations.checkout = true;
        this.selectedManager.permissions.reservations.read = true;
      } else if (this.selectedManager.role === 'booking-manager') {

        this.selectedManager.permissions = this.permissions;

        this.selectedManager.permissions.reservations.checkin = true;
        this.selectedManager.permissions.reservations.checkout = true;
        this.selectedManager.permissions.reservations.read = true;
        this.selectedManager.permissions.reservations.reserve = true;
        this.selectedManager.permissions.reservations.free = true;
        this.selectedManager.permissions.settings.cocobuk = true;
      } else if (this.selectedManager.role === 'minimal-assistant') {

        this.selectedManager.permissions = this.permissions;

        this.selectedManager.permissions.reservations.checkin = true;
        this.selectedManager.permissions.reservations.checkout = true;
        this.selectedManager.permissions.reservations.read = false;
      }


      let response = null;


      this.selectedManager.zonesDisabled = [];

      for (let zone of this.zones) {
        if (!zone.selected && this.selectedManager.zonesDisabled.indexOf(zone.id) === -1) {
          this.selectedManager.zonesDisabled.push(zone.id);
        }
      }



      if (this.selectedManager.permissions.all || this.selectedManager.supermanager || this.selectedManager.role === 'owner' || this.selectedManager.role === 'manager' || this.selectedManager.role === 'demo' || this.selectedManager.role.toLowerCase() === 'admin' || this.selectedManager.role.toLowerCase() === 'superowner') {
        this.selectedManager.zonesDisabled = [];
      }

      this.selectedManager.email = this.selectedManager.email.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      if (!this.selectedManager.id) {
        response = await this.dataService.createManager(this.selectedManager);
        if (response && !response.error) {
          this.dataService.managers.push(this.selectedManager);
        }
      } else {

        if (this.selectedManager.password && this.selectedManager.password !== '') {
          await this.dataService.changeManagerPassword(this.selectedManager);
        }

        response = await this.dataService.updateManager(this.selectedManager);
        await this.dataService.getUserData();
        if (response && !response.error) {
          const index = this.dataService.managers.findIndex(man => man.id == this.selectedManager.id);
          this.dataService.managers[index] = this.selectedManager;
        }
      }

      console.log(response);

      if (response && response.error) {

        let error = 'Controlla che tutti i dati siano corretti';

        if (response.error.error) {
          error = response.error.error;
        }

        let dialogRef = this.dialog.open(ModalErrorComponent, {
          width: '400px',
          data: {
            type: 'reservation-error',
            error: error
          }
        })

        const self = this;

        dialogRef.afterClosed().subscribe(result => {
              console.log(result);
              self.clicked = false;
        });
      } else {
        this.router.navigate(['/settings/profiles-and-payments/1']);
      }
    } catch(err) {
      console.log(err);
    }

  }

  removeManager() {
    let dialogRef = this.dialog.open(ModalRemoveComponent, {
      width: '340px',
      height: '130px',
      data: {
        type:"managers",
        manager: this.selectedManager
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result) {
          const index = this.dataService.managers.findIndex(man => man.id == this.selectedManager.id);
          this.dataService.managers.splice(index,1);
          this.router.navigate(['/settings/profiles-and-payments/1']);
        } else {
          this.clicked = false;
        }
    });
  }



}
