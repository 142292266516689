import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(
    private http: HttpClient,
    public afAuth: AngularFireAuth
  ) { }

  async getMerchantData(merchantId) {

      try {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'users/get_merchant_account/?merchantId=' + merchantId;
        const token = firebaseUser.token;

        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        //data[`result`][0];

        return data;
      } catch (err) {
        return err;
      }
  }

  async getPayoutsList(merchantId,start,end,size,type) {

      try {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        let api = environment.apiURL + 'users/merchant_payouts/?merchantId=' + merchantId + '&start=' + start + '&end=' + end + '&size=' + size ;

        if (type) {
          api += '&type=' + type;
        }

        const token = firebaseUser.token;

        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();


        return data;
      } catch (err) {
        return err;
      }
  }

  async getMerchantBalance(merchantId) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      let api = environment.apiURL + 'users/merchant_balance/';


      const token = firebaseUser.token;

      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();
      return data;
    } catch (err) {
      return err;
    }
  }

  async getChargesList(merchantId,start,end,size,type) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      console.log(start)
      console.log(end);

      const now = new Date();
      let offset = now.getTimezoneOffset();
      console.log(offset);
      /* let startTS = new Date(start*1000).setUTCHours(0,0,0,0) - 48*60*60*1000;
      let endTS = new Date(end*1000).setUTCHours(0,0,0,0) - 48*60*60*1000; */

      let startTS = start - offset*60 - 48*60*60;
      let endTS = end - offset*60 - 48*60*60;

      console.log(startTS)
      console.log(endTS);

      let api = environment.apiURL + 'users/merchant_charges/?merchantId=' + merchantId + '&start=' + startTS + '&end=' + endTS + '&size=' + size ;

      if (type) {
        api += '&type=' + type;
      }

      const token = firebaseUser.token;

      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();


      return data;
    } catch (err) {
      return err;
    }
  }

  async getRefundsList(merchantId,start,end,size,type) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      console.log(start)
      console.log(end);

      let startTS = new Date(start*1000).setUTCHours(0,0,0,0) - 48*60*60*1000;
      let endTS = new Date(end*1000).setUTCHours(0,0,0,0) - 48*60*60*1000;

      console.log(startTS)
      console.log(endTS);

      let api = environment.apiURL + 'users/merchant_refunds/?merchantId=' + merchantId + '&start=' + startTS/1000 + '&end=' + endTS/1000 + '&size=' + size ;

      if (type) {
        api += '&type=' + type;
      }

      const token = firebaseUser.token;

      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();


      return data;
    } catch (err) {
      return err;
    }
  }

  async getPayoutDetail(payoutId) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      let api = environment.apiURL + 'users/merchant_payouts/' + payoutId +'/';

      const token = firebaseUser.token;

      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();


      return data;
    } catch (err) {
      return err;
    }
  }

  async getTransactionDetail(transactionId) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      let api = environment.apiURL + 'users/merchant_balance_transactions/' + transactionId +'/';

      const token = firebaseUser.token;

      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();


      return data;
    } catch (err) {
      return err;
    }
  }

}
