import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ChartModule } from 'angular-highcharts';
import { StatisticsHomeComponent } from './pages/statistics-home/statistics-home.component';
import { StatisticsCocobookComponent } from './pages/statistics-cocobook/statistics-cocobook.component';
import { StatisticsDiscountsComponent } from './pages/statistics-discounts/statistics-discounts.component';
import { StatisticsExtrasComponent } from './pages/statistics-extras/statistics-extras.component';
import { StatisticsRevenuesComponent } from './pages/statistics-revenues/statistics-revenues.component';
import { StatisticsAttendanceComponent } from './pages/statistics-attendance/statistics-attendance.component';

// ROUTING
import { StatsRoutingModule } from './stats-routing.module';

// COMPONENTS
@NgModule({
  declarations: [
    StatisticsHomeComponent,
    StatisticsCocobookComponent,
    StatisticsDiscountsComponent,
    StatisticsExtrasComponent,
    StatisticsRevenuesComponent,
    StatisticsAttendanceComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    StatsRoutingModule,
    ChartModule
  ]
})
export class StatsModule { }
