import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/providers/data.service';
import { SettingsService } from 'src/app/core/providers/settings.service';
import { DayService, WeekService, WorkWeekService, MonthService, AgendaService, EventSettingsModel, GroupModel, ScheduleComponent, EventRenderedArgs, PopupOpenEventArgs, DragEventArgs, ResizeEventArgs, SelectEventArgs, ActionEventArgs } from '@syncfusion/ej2-angular-schedule';
import { resourceData } from './datasource';
import { extend } from '@syncfusion/ej2-base';
import { MatDialog } from '@angular/material';
import { ModalErrorComponent } from 'src/app/shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-planner-new',
  templateUrl: './planner-new.component.html',
  styleUrls: ['./planner-new.component.scss']
})
export class PlannerNewComponent implements OnInit {
  objectKeys = Object.keys;
  @ViewChild('scheduleObj', { static:false })
  public planner: ScheduleComponent;
  loading = true;
  lightLoading = true;selectedInterval;selectedSlot;
  data; reservationMapData; optionalsByIds = {}; optionalsByKeys= {};optionals; resortData; managerData; mapData; mapByRowCol; prices; elements; features; services;
  selectedResources = [];selectedReservation=null;startTimestamp=0;endTimestamp=0;selectedSlots=[];reservations=[];

  public selectedDate: Date = new Date();
  public currentView: string = 'TimelineMonth';
  public views: Array<string> = ['TimelineMonth'];
  public eventSettings: EventSettingsModel = {
      dataSource: []
  };
  public group: GroupModel = {
      resources: ['Zones', 'Slots']
  };
  public zoneDataSource: Object[] = [];
  public allowMultipleOwner: Boolean = true;
  public slotDataSource: Object[] = [];

  constructor(
    public router: Router,
    public dataService: DataService,
    private settingsService: SettingsService,
    public dialog:MatDialog,
    private db: AngularFireDatabase) { }

  async ngOnInit() {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const currentDay = new Date().getDate();
    const date = new Date();
    /* const startTS = new Date(currentYear,currentMonth,1).setUTCHours(0, 0, 0, 0);
    const endTS = new Date(date.setMonth(date.getMonth()+1)).setUTCHours(0, 0, 0, 0); */
    const startTS = new Date(currentYear,currentMonth,1).getTime();
    const endTS = new Date(currentYear,currentMonth+1, 0).getTime();

    console.log(startTS, endTS);

    this.startTimestamp = startTS;
    this.endTimestamp = endTS;
    
    if (!this.dataService.resortData ) {
      [ this.data, this.reservationMapData] = await Promise.all([this.dataService.initResortData(), this.dataService.getMapReservations(startTS/1000, endTS/1000)]);
    } else {
      this.reservationMapData = await this.dataService.getMapReservations(startTS/1000, endTS/1000);
    }


    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.mapData = this.dataService.map;
    this.mapByRowCol = this.dataService.mapByRowCol;
    this.prices = this.dataService.prices;
    this.optionals = this.dataService.optionals;
    this.elements = this.dataService.elements;
    this.features = this.dataService.features;
    this.services = this.dataService.services;

    this.optionals.map(opt => {
      this.optionalsByIds[opt.id] = opt;
      this.optionalsByKeys[opt.optionalId] = opt;
    });

    this.setupGrid();
    this.getData();
  }

  setupGrid() {
    for (const slot of this.mapData) {
      let zone = this.resortData.zonesByIds[slot.zoneId];
      if (!this.zoneDataSource.find((z:any)=>z.Id === zone.id)) {
        let zoneGroup = {
          ZoneText: zone.name[this.dataService.lang],
          Id: zone.id,
          ZoneColor:'#cb6bb2'
        }

        this.zoneDataSource.push(zoneGroup);
      }

      let slotResource = {
        SlotText: slot.label,
        Id: slot.id,
        Row: slot.row,
        Col: slot.col,
        ZoneGroupId: zone.id,
        SlotColor:'#cb6bb2'
      }
      this.slotDataSource.push(slotResource);
    }

    this.lightLoading = false;
  }

  getData() {
    console.log(this.reservationMapData);
    let slotResources = [];
    this.reservations = [];
    for (const slot of this.mapData) {
      /* const conditionOnReservations = (foundSlots.indexOf(slot.row + '_' + slot.col) > -1 );
      const conditionOnPackages = (!this.selectedPackage || (this.selectedPackage && slot.packagesIds.indexOf( parseInt(this.selectedPackage) ) > -1));
      const conditionOnString = (this.searchString && slot.label.toLowerCase().replace(/\s/g, '').indexOf(this.searchString.toLowerCase().replace(/\s/g, '')) > -1); */

      /* if ( (!this.searchString && conditionOnPackages) || (this.searchString && (conditionOnReservations || conditionOnString) && conditionOnPackages)) { */
        if (this.reservationMapData[slot.row] && this.reservationMapData[slot.row][slot.col]) {
          
            this.reservationMapData[slot.row][slot.col].reservationsKeys.sort( this.sortByTimestamp );
            

            for (const reservationMap of this.reservationMapData[slot.row][slot.col].reservationsKeys) {

              let alreadyPushed = slotResources.find((res:any)=>res.Id === reservationMap.reservation+'_'+slot.id+'_'+reservationMap.interval.start.toString() && res.SlotId === slot.id);
              let name = '---';
              if (reservationMap.name !== '') {
                name = reservationMap.name.toUpperCase();
              }
              if (!alreadyPushed) {
                let start = reservationMap.interval.start*1000;
                let end = reservationMap.interval.end*1000;

                /* if (start < this.startTimestamp) {
                  start = this.startTimestamp;
                }
                if (end > this.endTimestamp) {
                  end = this.endTimestamp;
                } */
                let eventColor = this.getStateColor(reservationMap);
                let eventObj = {
                    Id: reservationMap.reservation+'_'+slot.id+'_'+reservationMap.interval.start.toString(),
                    ReservationId: reservationMap.reservation,
                    Subject: name,
                    StartTime: new Date(start),
                    EndTime: new Date(end),
                    StartTs: reservationMap.interval.start,
                    EndTs: reservationMap.interval.end,
                    IsAllDay: true,
                    SlotId: slot.id,
                    ZoneId: slot.zoneId,
                    EventColor: eventColor.bgColor,
                    State: reservationMap.state,
                    Slot: slot
                }

                slotResources.push(eventObj);
              }
            }
        }
      /* } */

    }
    /* setTimeout(()=>{
      this.scheduleObj.eventSettings.dataSource = slotResources;
    },1000) */
    this.reservations = slotResources;
    console.log(slotResources);
    this.eventSettings.dataSource = <Object[]>extend([], this.reservations, null, true); 
  }

  sortByTimestamp( a, b ) {
    if ( a.day_timestamp < b.day_timestamp ) {
      return -1;
    }
    if ( a.day_timestamp > b.day_timestamp ) {
      return 1;
    }
    return 0;
  }

  oneventRendered(args: EventRenderedArgs): void {
    let eventColor: string = args.data.EventColor as string;
    if (!args.element || !eventColor) {
        return;
    }
    args.element.style.backgroundColor = eventColor;
  }

  async eventClick(args: PopupOpenEventArgs) {
    this.selectedReservation = null;
    console.log("EVENT CLICK");
    args.cancel = true;
    if (args.data && args.data.Id && args.data.State !== 6) {

      console.log(args.data);
      const reservation = await this.dataService.getSingleReservation(args.data.ReservationId);
      reservation.state = args.data.State;
      this.processReservationDetail(reservation,args.data.StartTs, args.data.EndTs,args.data.Slot);
    }

    return;
  }

  dragStartData = null;
  async startDrag(args:DragEventArgs) {
    this.selectedReservation = null;
    this.dragStartData = args;
  }

  async drag(args:DragEventArgs) {
    console.log(args);
  }

  async updateReservationAfterDrag(args:DragEventArgs) {
    console.log("ON DRAG END")
    console.log(args);
    if (args.data) {
      this.planner.showSpinner();
      let reservation = await this.dataService.getSingleReservation(args.data.ReservationId);
      let oldReservation = JSON.parse(JSON.stringify(reservation));
      let initialStartTS = (new Date(this.dragStartData.data.StartTime).getTime())/1000;
      let initialEndTS = (new Date(this.dragStartData.data.EndTime).getTime())/1000;
      let finalStartTS = (new Date(args.data.StartTime).getTime())/1000;
      let finalEndTS = (new Date(args.data.EndTime).getTime())/1000;
      let selectedInterval = reservation.dates.find((d:any)=>d.date_key === initialStartTS+'_'+initialEndTS);

      // CHECK SE LE DATE INIZIALI SONO PERFETTAMENTE UGUALI A QUELLE FINALI

      // ALTRIMENTI SE NON LO SONO CHECK SE L'INTERVALLO CONTIENE SOLO UNA POSTAZIONE DA SPOSTARE

      // ALTRIMENTI TOGLI LA POSTAZIONE DALL'INTERVALLO SELEZIONATO E CREATI UN NUOVO INTERVALLO


      if (selectedInterval) {
        selectedInterval.date_key = finalStartTS+'_'+finalEndTS;
        selectedInterval.start = finalStartTS;
        selectedInterval.end = finalEndTS;
        selectedInterval.length = (finalEndTS-finalStartTS)/(24*60*60) +1;

        let selectedOrder = selectedInterval.order.find((s:any)=>s.col === this.dragStartData.data.Slot.col && s.row === this.dragStartData.data.Slot.row);
        
        let slotTarget = args.data.Slot;
        if (selectedOrder && slotTarget.packagesIds.indexOf(selectedOrder.packageKey) > -1) {
          selectedOrder.coords =slotTarget.label;
          selectedOrder.row = slotTarget.row;
          selectedOrder.col = slotTarget.col;
          selectedOrder.slot_key = slotTarget.row+'_'+slotTarget.col;
          selectedOrder.zoneKey = slotTarget.zoneId;
          console.log(selectedOrder);
        } else {

          const dialogRef = this.dialog.open(ModalErrorComponent, {
            width: '400px',
            data: {
              type: 'reservation-error',
              error: "Non è possibile spostare questa prenotazione perché la postazione di destinazione non prevede lo stesso pacchetto"
            }
          });
        }

      }
      console.log(reservation);
      //await this.saveReservation(reservation,oldReservation);
      this.planner.hideSpinner();
    }
      
  }

  resizeStartData = null;
  async startResize(args:ResizeEventArgs) {
    this.selectedReservation = null;
    this.resizeStartData = args;
  }

  async updateReservationAfterResize(args:ResizeEventArgs) {
    console.log(args);
    if (args.data) {
      this.planner.showSpinner();
      let reservation = await this.dataService.getSingleReservation(args.data.ReservationId);
      let oldReservation = JSON.parse(JSON.stringify(reservation));
      let initialStartTS = (new Date(this.resizeStartData.data.StartTime).getTime())/1000;
      let initialEndTS = (new Date(this.resizeStartData.data.EndTime).getTime())/1000;
      let finalStartTS = (new Date(args.data.StartTime).getTime())/1000;
      let finalEndTS = (new Date(args.data.EndTime).getTime())/1000;
      let selectedInterval = reservation.dates.find((d:any)=>d.date_key === initialStartTS+'_'+initialEndTS);
      if (selectedInterval) {
        selectedInterval.date_key = finalStartTS+'_'+finalEndTS;
        selectedInterval.start = finalStartTS;
        selectedInterval.end = finalEndTS;
        selectedInterval.length = (finalEndTS-finalStartTS)/(24*60*60) +1;
      }

      await this.saveReservation(reservation,oldReservation);
      this.planner.hideSpinner();
    }

  }

  async selectCells(args:SelectEventArgs) {
    this.selectedReservation = null;
    console.log("SELECT CLICK");
    console.log(args);
    if (args.data) {
      let startTS = (new Date(args.data['StartTime']).getTime())/1000;
      let endTS = (new Date(args.data['EndTime']).getTime())/1000;
      let slot = this.mapData.find((slot:any)=> slot.id == args.data['SlotId']);
      let reservationMap = (this.reservationMapData[slot.row] && this.reservationMapData[slot.row][slot.col] && this.reservationMapData[slot.row][slot.col].reservationsKeys.length);

      // -> FIND RESERVATION IN THIS SLOT 

      console.log(reservationMap);
      let clickOnReservation = false;
      if (reservationMap) {
        console.log(this.reservationMapData[slot.row][slot.col].reservationsKeys);
        let findReservation = this.reservationMapData[slot.row][slot.col].reservationsKeys.find((t:any)=>startTS < t.day_timestamp && t.day_timestamp < endTS);
        if (findReservation) {
          clickOnReservation = true;
        }
      }

      if (args.data['Id'] && args.data['Id'].indexOf('temp') > -1) {
        console.log(args.data['Id']);
        this.planner.deleteEvent(args.data['Id']);
        let findEvent = this.selectedResources.findIndex((e:any)=>e.Id === args.data['Id']);
        if (findEvent > -1)
          this.selectedResources.splice(findEvent,1)

        let findSlot = this.selectedResources.find((e:any)=>e.SlotId === args.data['SlotId']);

        if (!findSlot) {
          let findSlotIndex = this.selectedSlots.findIndex((s:any)=>s.id === slot.id);
          this.selectedSlots.splice(findSlotIndex,1);
        }
      } else if (!clickOnReservation && !args.data['Id']) {
        console.log(this.mapData,args.data['SlotId']);
        let start = new Date(args.data['StartTime']).getTime();
        let end = new Date(args.data['EndTime']).getTime();
        let tempEvent = {
          Id: 'temp_'+slot.id+'_'+start.toString(),
          ReservationId: null,
          Subject: "NUOVA PRENOTAZIONE",
          StartTime: args.data['StartTime'],
          EndTime: args.data['EndTime'],
          StartTs: start,
          EndTs: end,
          IsAllDay: true,
          SlotId: args.data['SlotId'],
          ZoneId: args.data['ZoneId'],
          EventColor: "#e0e0e0",
          State: 6,
          Slot: slot
        }
        this.selectedResources.push(tempEvent);
        if (!this.selectedSlots.find((s:any)=>s.id === slot.id))
          this.selectedSlots.push(slot);

        this.planner.addEvent(tempEvent);
        this.planner.removeSelectedClass();
      } else {
        this.planner.removeSelectedClass();
      }
    } else {
      this.planner.removeSelectedClass();
    }
  }

  processReservationDetail(reservation:any,start:any, end:any, selectedSlot:any) {
    this.selectedSlot = selectedSlot;
    this.selectedSlots = [];
    const startTS = new Date(start).setUTCHours(0,0,0);
    const endTS = new Date(end).setUTCHours(0,0,0);
    for (const interval of reservation.dates) {
      if (( startTS / 1000 <= interval.start &&  endTS / 1000 <= interval.end  ) || ( startTS / 1000 >=  interval.start && endTS / 1000 <= interval.end  ) || ( startTS / 1000 <= interval.end && endTS / 1000 >= interval.end)) {

        reservation.selectedInterval = interval;
        this.selectedInterval = interval;

        let flagCounter = false;

        for (const order of interval.order) {
          if (!order.optionalsKeys) {
            order.optionalsKeys = {};
          }

          order.selected = false;
          if (!(selectedSlot.row == order.row && selectedSlot.col == order.col)) {
            this.selectedSlots.push(order);

            if (!flagCounter) {
              order.selected = true;
              flagCounter = true;
            }
          }

        }

        break;
      }
    }
    this.selectedReservation = reservation;
    this.selectedReservation.payed = 0;
    this.selectedReservation.notPayed = 0;

    for (let interval of this.selectedReservation.dates) {

      interval.groupByCombo = {};
      for (const product of interval.order) {

        if (!interval.groupByCombo[product.packageKey + '_' + product.zoneKey]) {
          interval.groupByCombo[product.packageKey + '_' + product.zoneKey] = {
            products: [],
            quantity: 0,
            optionals: {},
            packageId: product.packageKey,
            zoneId: product.zoneKey
          };
        }

        if (product.quantity)
          interval.groupByCombo[product.packageKey + '_' + product.zoneKey].quantity += product.quantity;
        else
          interval.groupByCombo[product.packageKey + '_' + product.zoneKey].quantity += 1

        interval.groupByCombo[product.packageKey + '_' + product.zoneKey].products.push(product);

        if (product.optionalsKeys) {
          for (let optKey in product.optionalsKeys) {
            if (product.optionalsKeys[optKey]) {
              if (!this.optionals[optKey]) {
                for (const optId in this.optionals ) {
                  if (this.optionals[optId].id == optKey) {
                    optKey = this.optionals[optId].id;
                    break;
                  }
                }
              }

              if (!interval.groupByCombo[product.packageKey + '_' + product.zoneKey].optionals[optKey]) {
                interval.groupByCombo[product.packageKey + '_' + product.zoneKey].optionals[optKey] = 0;
              }

              interval.groupByCombo[product.packageKey + '_' + product.zoneKey].optionals[optKey] += product.optionalsKeys[optKey];
            }

          }

        }
      }
    }

    for (const customerBill of this.selectedReservation.order.customerBill) {
      if (customerBill.payed) {
        this.selectedReservation.payed += customerBill.price;
      } else {
        this.selectedReservation.notPayed += customerBill.price;
      }
    }

    console.log(this.selectedReservation);
  }

  resetPlanner() {
    
   /*  let tempReservations = this.reservations.filter((r:any)=>r.Id.indexOf('temp') > -1);
    console.log(tempReservations); */
    for (let res of this.selectedResources) {
      this.planner.deleteEvent(res.Id);
    }
    this.selectedReservation = null;
    this.selectedInterval = null;
    this.selectedSlot = null;
    this.selectedReservation = null;
    this.selectedResources = []; 
    this.selectedSlots = []; 
    this.planner.removeSelectedClass();
  }

  detectAction(args: ActionEventArgs) {
    this.planner.showSpinner();
    console.log(args);
    if (args.requestType === 'dateNavigate') {
      let currentDates = this.planner.getCurrentViewDates();
      this.startTimestamp = new Date(currentDates[0]).getTime();
      this.endTimestamp = new Date(currentDates[currentDates.length-1]).getTime()+24*60*60;
      this.getData();
    }
    this.planner.hideSpinner();
  }

  getStateColor(reservation) {
    let bgColor = '#FF902A';
    let bgLightColor = 'rgba(255, 144, 42,0.5);'

    if (reservation.state == 4) {
      bgColor = '#b490e5';
      bgLightColor = 'rgba(180, 144, 229,0.5);'
    } else if (reservation.state == 0) {
      bgColor = '#FF5453';
      bgLightColor = 'rgba(255, 84, 83,0.5);'
    } else if (reservation.state == 5) {
      bgColor = '#05214c';
      bgLightColor = 'rgba(5, 33, 76,0.5);'
    } else if (reservation.state == 3) {
      bgColor = '#0f9fff';
      bgLightColor = 'rgba(15, 159, 255,0.5);'
    } else if (reservation.state == 6) {
      bgColor = '#aeaeae';
      bgLightColor = 'rgba(255, 144, 42,0.5);'
    }

    return {bgColor,bgLightColor}

  }

  async saveReservation(reservation,oldReservation) {

    this.settingsService.updateCart(reservation,false);
    let settled = oldReservation.order.settled;
    if (oldReservation.order.total < reservation.order.total) {
      settled = false;
    }

    const totalReservationBill = reservation.order.customerBill.findIndex(bill => bill.type === 'settlement');

    for (const customerBill of reservation.order.customerBill) {
      if (customerBill.type === 'settlement') {
        customerBill.type = 'movement';
      }
    }

    this.settingsService.updateCustomerBill(reservation, settled, 'cash');
    // AGGIORNA PRENOTAZIONE

    reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
    reservation.updatedBy = this.settingsService.getCurrentUserData();

    let totalLength = 0;
    for (let interval of reservation.dates) {
      totalLength+= interval.length;
      if (!reservation.start) {
        reservation.start = interval.start;
        reservation.end = interval.end;
      }

      if (interval.start < reservation.start) {
        reservation.start = interval.start;
      }

      if (interval.end > reservation.end) {
        reservation.end = interval.end;
      }
    }

    await this.dataService.updateReservation(reservation);
  }

  openNewReservation() {
    console.log("NUOVA PRENOTAZIONE")
    console.log(this.selectedResources);
    console.log(this.selectedSlots);
    this.dataService.reservedSlots = null;
    const reservation = this.dataService.initReservation(this.startTimestamp, this.endTimestamp, false);
    reservation.dates = [];
    for (let resource of this.selectedResources) {
      // SE ESISTE GIA' INTERVALLO CON STESSE DATE PUSHA POSTAZIONE LI'   

      let interval:any = {
        start: resource.StartTs/1000,
        end: resource.EndTs/1000,
        date_key: resource.StartTs/1000+'_'+resource.EndTs/1000,
        timeSlots:[],
        order:[],
        length: (resource.EndTs/1000 - resource.StartTs/1000)/(24*60*60) + 1,
        optionalsAmount:0,
        optionalsCounter:0,
        intervalAmount:0
      }

      let findInterval = reservation.dates.find((i:any)=>i.start === resource.StartTs/1000 && i.end === resource.EndTs/1000);

      if (findInterval) {
        interval = findInterval;
      }

      interval.order.push({
        col:resource.Slot.col,
        row:resource.Slot.row,
        coords: resource.Slot.label,
        optionalsKeys:{},
        optionalsPrices:{},
        packageKey: resource.Slot.defaultPackId,
        price:0,
        slot_key:resource.Slot.row+ '_' + resource.Slot.col,
        type:'slot',
        zoneKey:resource.Slot.zoneId
      })

      if (!findInterval)
        reservation.dates.push(interval);
    }

    this.dataService.selectedReservation = reservation;
    this.dataService.previousPage = 'planner';
    this.router.navigate(['/suite/reservation/new']);
  }
}
