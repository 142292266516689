import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../core/providers/data.service';
import { SettingsService } from '../../../core/providers/settings.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss']
})
export class PlannerComponent implements OnInit {

  @ViewChild('calendar',null) calendarComponent: FullCalendarComponent;

  objectKeys = Object.keys;

  resortData; managerData; mapData; mapByRowCol; prices; optionals; elements; features; services;
  reservationMapData; data; unsettledReservations; newLoad; startTimestamp; endTimestamp; mapSubscription;
  pageClicked = false; rows; dates; selectedReservation; selectedSlots; optionalsByIds = {}; optionalsByKeys= {};
  selectedSlot;selectedPackage = null; selectedResources = []; detachUnselect=false; resourcesCounter;
  term$ = new Subject<string>(); searchString = null; showBottomBar=false; lightLoading=false;
  firstLoading = true; lastRealtimePushed;


  resources;
  calendarPlugins = [interactionPlugin, resourceTimelinePlugin]; // important!
  schedulerLicenseKey = 'GPL-My-Project-Is-Open-Source';
  header = {
    left: 'prev,next',
    center: 'title',
    right: 'resourceTimelineMonth'
  };
  events = [];

  slotWidth= '70'

  buttonText = {
    today:    'oggi',
    month:    'mese',
    week:     'settimana',
    day:      'giorno',
    list:     'lista'
  }

  views = {
    resourceTimelineCustom: {
      type: 'resourceTimeline',
      duration: { days: 31}
    }
  }


  businessHours: {
    // days of week. an array of zero-based day of week integers (0=Sunday)
    daysOfWeek: [ 6,0 ], // Monday - Thursday

    startTime: '00:00', // a start time (10am in this example)
    endTime: '24:00', // an end time (6pm in this example)
  }

  lang = 'it';

  constructor(public router: Router, public dataService: DataService, private settingsService: SettingsService, private db: AngularFireDatabase) {

    this.term$
        .debounceTime(500)
        .distinctUntilChanged()
        .subscribe(async term => {
          console.log('BOOM');
          console.log(term);

          if (term === '') {
            this.searchString = null;
          }

          await this.updateResources();
        });

  }

  drop(event: CdkDragDrop<string[]>) {
   /*  moveItemInArray(this.movies, event.previousIndex, event.currentIndex); */
   console.log(event);
  }

  daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
  }

  sortByResourceId( a, b ) {
    if ( a.resourceId < b.resourceId ){
      return -1;
    }
    if ( a.resourceId > b.resourceId ){
      return 1;
    }
    return 0;
  }

  sortByStart( a, b ) {
    if ( a.start < b.start ){
      return -1;
    }
    if ( a.start > b.start ){
      return 1;
    }
    return 0;
  }

  openNewReservation() {
    console.log("NUOVA PRENOTAZIONE")
    this.dataService.reservedSlots = null;
    const reservation = this.dataService.initReservation(this.startTimestamp, this.endTimestamp, false);

    reservation.dates = [];
    this.selectedResources = this.selectedResources.sort(this.sortByResourceId);
    let newEvents = this.events.find(evt => evt.state == -1);
    console.log(newEvents);
    const resourcesById = {};

    for (const resource of this.selectedResources) {
      if (!resourcesById[resource.id]) {
          resourcesById[resource.id] = [];
      }

      resourcesById[resource.id].push(resource);
      resourcesById[resource.id].sort(this.sortByStart);
    }


    let resources = [];

    for (const rId in resourcesById) {
      if (resourcesById[rId]) {
        for (const resource of resourcesById[rId]) {
          const foundRes = resources.slice().reverse().find(res => res.id === resource.id );

          if (!foundRes) {
            resources.push(resource);
          } else {
            if ((foundRes.end - resource.start) == 0) {
              foundRes.end = resource.end;
              foundRes.eventEnd = resource.eventEnd;
            } else {
              resources.push(resource);
            }
          }
        }
      }
    }

    console.log(resources);

    for (let i = 0; i < resources.length; i++) {
      const rowIndex = resources[i].row;
      const colIndex = resources[i].col;
      const start = resources[i].start;
      const end = resources[i].end-24*60*60;
      const length = (end - start) / (24 * 60 * 60) + 1;
      let selected = false;

      if (i === 0) {
        selected = true;
      }

      const order = [];

      order.push({
        selected,
        col: parseInt(colIndex),
        row: parseInt(rowIndex),
        type: 'slot',
        coords: this.mapByRowCol[rowIndex][colIndex].label,
        zoneKey: this.mapByRowCol[rowIndex][colIndex].zoneId,
        slot_key: rowIndex + '_' + colIndex,
        packageKey: parseInt(this.mapByRowCol[rowIndex][colIndex].defaultPackId),
        optionalsKeys: {},
      });

      const dateInterval = {
        date_key: start + '_' + end,
        start,
        end,
        length,
        intervalAmount: 0,
        optionalsAmount: 0,
        order
      };

      reservation.dates.push(dateInterval);
    }

    console.log(JSON.parse(JSON.stringify(reservation.dates)));

    let newIntervals = [];

    for (const interval1 of reservation.dates) {
      let newInterval = JSON.parse(JSON.stringify(interval1));
      for (const interval2 of reservation.dates) {
        if (interval1['date_key'] === interval2['date_key'] && interval1['order'] != interval2['order']) {
          //newInterval.order = interval1['order'].concat(interval2['order']);

          for (const order of interval2['order']) {
            newInterval.order.push(order);
          }
        }
      }

      if (!newIntervals.find(int => int.date_key == newInterval.date_key)) {
        newIntervals.push(newInterval);
      }
    }


    console.log(JSON.parse(JSON.stringify(reservation.dates)));

    reservation.dates = newIntervals;

    this.dataService.selectedReservation = reservation;
    this.dataService.previousPage = 'planner';
    this.router.navigate(['/suite/reservation/new']);
  }

  async ngOnInit() {

    this.newLoad = true;
    this.lightLoading = true;
    this.firstLoading = true;

    if (!this.resortData) {
      const currentMonth = new Date().getMonth()+1;
      const currentYear = new Date().getFullYear();
      const currentDay = new Date().getDate();
      const startTS = new Date().setUTCHours(0, 0, 0, 0);
      const date = new Date();
      const endTS = new Date(date.setMonth(date.getMonth()+1)).setUTCHours(0, 0, 0, 0);

      console.log(startTS, endTS);

      this.startTimestamp = startTS;
      this.endTimestamp = endTS;

      [ this.data, this.reservationMapData] = await Promise.all([this.dataService.initResortData(), this.dataService.getMapReservations(startTS/1000, endTS/1000)]);

      this.resortData = this.dataService.resortData;
      this.managerData = this.dataService.userData;
      this.mapData = this.dataService.map;
      this.mapByRowCol = this.dataService.mapByRowCol;
      this.prices = this.dataService.prices;
      this.optionals = this.dataService.optionals;
      this.elements = this.dataService.elements;
      this.features = this.dataService.features;
      this.services = this.dataService.services;

    }

    this.optionals.map(opt => {
      this.optionalsByIds[opt.id] = opt;
      this.optionalsByKeys[opt.optionalId] = opt;
    });

    await this.updateResources();

    /* setTimeout(()=>{
      this.enableRealTime();
    },200) */

  }

  enableRealTime() {
    this.mapSubscription = this.db.object('realtimeMap/' + this.resortData.id).valueChanges().subscribe( async rtData => {
      let array = JSON.parse(JSON.stringify(rtData));
      if (array && array.length) {
        for (const data of array) {

          this.firstLoading = false;

          console.log("REALTIME DATA");
          console.log(data);
          console.log(this.events);

          if (data != this.lastRealtimePushed) {
            this.lastRealtimePushed = data;

            if (data['state'] == 4) {
              let slot = data['slot'];
              const row = parseInt(slot.split("_")[0]);
              const col = parseInt(slot.split("_")[1]);

              let bgColor = '#b490e5';

              for (const ts of data['timestamps']) {
                const day = new Date(ts*1000).getDate();
                const month = new Date(ts*1000).getMonth() + 1;
                const year = new Date(ts*1000).getFullYear();

                let monthString = month.toString();
                let dayString = day.toString();

                if (month < 10) {
                  monthString = '0' + monthString;
                }

                if (day < 10 ) {
                  dayString = '0' + dayString;
                }

                let borderColor = 'white';

                if (!ts.settled) {
                  borderColor = 'white';
                }

                const selectedResource = this.resources.find(res => res.row == row && res.col == col);

                const foundEvent = this.events.find(evt => evt.row == row && evt.col == col && evt.start == year + '-' + monthString + '-' + dayString + 'T00:00:00+00:00');


                let className = 'daily';

                if (ts.timeSlot && ts.timeSlot.id) {
                  className = 'partial'

                }

                if (!foundEvent) {
                  const newEvent = {
                    id: row + '_' + col + '_' + ts,
                    resourceId: selectedResource.id.toString(),
                    title: '',
                    reservationId: '',
                    label: '',
                    row,
                    col,
                    editable: true,
                    start: year + '-' + monthString + '-' + dayString + 'T00:00:00+00:00',
                    end: year + '-' + monthString + '-' + dayString + 'T24:00:00+00:00',
                    backgroundColor: bgColor,
                    borderColor,
                    state: 4,
                    allDay: true,
                    className
                  };
                  console.log(newEvent);
                  this.events.push(newEvent);

                  const calendarApi = this.calendarComponent.getApi();
                  calendarApi.addEvent(newEvent);
                }
              }

            } else if (data['state'] != 1) {
              const firstElement = data['timestamps'][0];
              const lastElement = data['timestamps'][data['timestamps'].length-1];
              const slotIds = data['slot'];
              const reservations = await this.dataService.getReservations(firstElement, lastElement, null, null, null, null, null, null, null, null, null, null, null, slotIds, null, 30, 1,null,null,null, null, null, null, null, null, null,null,null,null)

              console.log(reservations.results);
              console.log(this.startTimestamp);
              console.log(this.endTimestamp);

              for (const reservation of reservations.results) {
                for (const interval of reservation.dates) {
                  if ((interval.start >= this.startTimestamp/1000 && interval.start <= this.endTimestamp/1000) || (interval.end >= this.startTimestamp/1000 && interval.end <= this.endTimestamp/1000)) {

                      const day = new Date(interval.start*1000).getDate();
                      const month = new Date(interval.start*1000).getMonth() + 1;
                      const year = new Date(interval.start*1000).getFullYear();

                      let monthString = month.toString();
                      let dayString = day.toString();

                      if (month < 10) {
                        monthString = '0' + monthString;
                      }

                      if (day < 10 ) {
                        dayString = '0' + dayString;
                      }


                      const dayEnd = new Date(interval.end*1000).getDate();
                      const monthEnd = new Date(interval.end*1000).getMonth() + 1;
                      const yearEnd = new Date(interval.end*1000).getFullYear();

                      let monthStringEnd = monthEnd.toString();
                      let dayStringEnd = dayEnd.toString();

                      if (month < 10) {
                        monthStringEnd = '0' + monthStringEnd;
                      }

                      if (day < 10 ) {
                        dayStringEnd = '0' + dayStringEnd;
                      }

                      for (const slot of interval.order) {

                        let bgColor = '#FF902A';
                        let bgLightColor = 'rgba(255, 144, 42,0.5);'

                        let state = 2;

                        if (reservation.state == 6) {
                          state = 6
                        } else if (reservation.checkInDates.length && reservation.checkInDates[0] === reservation.start) {
                          state = 0
                        } else if (reservation.cocoBooking) {
                          state = 3;
                        } else if (reservation.seasonTicket) {
                          state = 5;
                        }

                        if (state == 0) {
                          bgColor = '#FF5453';
                          bgLightColor = 'rgba(255, 84, 83,0.5);'
                        } else if (state == 5) {
                          bgColor = '#05214c';
                          bgLightColor = 'rgba(5, 33, 76,0.5);'
                        } else if (state == 3) {
                          bgColor = '#0f9fff';
                          bgLightColor = 'rgba(15, 159, 255,0.5);'
                        } else if (state == 6) {
                          bgColor = '#aeaeae';
                          bgLightColor = 'rgba(255, 144, 42,0.5);'
                        }

                        const borderColor = 'white';

                        const slotFound = this.mapData.find(slo => slot.row == slo.row && slot.col == slo.col);
                        let title =reservation.name + ' ' + reservation.surname  ;

                        if (reservation.notes && reservation.notes !== '' && this.resortData.id !== 119) {
                          title += ' - Note: ' +reservation.notes
                        }

                        const newEvent = {
                          id: slot.row + '_' + slot.col + '_' + interval.start,
                          resourceId: slotFound.id.toString(),
                          title: title,
                          reservationId: reservation.id,
                          label: slot.coords,
                          row: slot.row,
                          col: slot.col,
                          editable: true,
                          start: year + '-' + monthString + '-' + dayString + 'T00:00:00+00:00',
                          end: yearEnd + '-' + monthStringEnd + '-' + dayStringEnd + 'T24:00:00+00:00',
                          backgroundColor: bgColor,
                          borderColor,
                          state,
                          allDay: true,
                          className:'test'
                        };

                        console.log(JSON.parse(JSON.stringify(this.events)));
                        console.log(newEvent);

                        let foundEvent = this.events.find(evt => evt.id == newEvent.id);

                        if (!foundEvent) {
                          foundEvent = this.events.find(evt => evt.start === newEvent.start && evt.end === newEvent.end && evt.resourceId === newEvent.resourceId);
                        }


                        console.log(foundEvent);

                        if (!foundEvent) {
                          this.events.push(newEvent);
                          const calendarApi = this.calendarComponent.getApi();
                          calendarApi.addEvent(newEvent);
                          console.log(JSON.parse(JSON.stringify(this.events)));
                        } else if (!this.firstLoading) {
                          const calendarApi = this.calendarComponent.getApi();
                          const evtFound = calendarApi.getEventById(foundEvent.id);
                          if (evtFound) {

                            evtFound.setProp('backgroundColor', bgColor);
                            evtFound.setProp('borderColor', borderColor);
                            evtFound.setExtendedProp('state', state);
                          }
                        }
                      }
                  }
                }
              }

            } else {

              let slot = data['slot'];
              const row = parseInt(slot.split("_")[0]);
              const col = parseInt(slot.split("_")[1]);

              for (const ts of data['timestamps']) {

                const day = new Date(ts*1000).getDate();
                const month = new Date(ts*1000).getMonth() + 1;
                const year = new Date(ts*1000).getFullYear();

                let monthString = month.toString();
                let dayString = day.toString();

                if (month < 10) {
                  monthString = '0' + monthString;
                }

                if (day < 10 ) {
                  dayString = '0' + dayString;
                }

                const calendarApi = this.calendarComponent.getApi();
                const evtFound = calendarApi.getEventById(row + '_' + col + '_' + ts);
                if (evtFound) {
                  evtFound.remove();
                }

              }

            }
          }

        }
      }
    });
  }

  naturalCompare(a, b) {
    return a.title.trim().localeCompare(b.title.trim(), undefined, {numeric: true, sensitivity: 'base'});
  }


  disableRealTime() {
    this.mapSubscription.unsubscribe();
  }

  ngOnDestroy() {
    if (this.mapSubscription) {
      this.mapSubscription.unsubscribe();
    }
  }

  getWidth() : any {
    if (document.body.offsetWidth < 850) {
      return '90%';
    }
    return document.body.clientWidth - 2;
  }

  getHeight(): any {
    return document.body.clientHeight-72;
  }

  async generateRows() {
    const slots = new Array();
    const counter = 0;

    let foundSlots = [];

    if (this.searchString) {
      foundSlots = await this.getReservationsFromSearch();
    } else {
      this.events = [];
    }
    console.log(this.mapData);

    for (const slot of this.mapData) {
      let lastSlot = null;
      let newEvent = null;
      let lastStart = null;
      let lastEnd = null;
      let lastState = null;
      let lastColor = null;

      if (slot.packagesIds.length && slot.zoneId && slot.defaultPackId) {

        const conditionOnReservations = (foundSlots.indexOf(slot.row + '_' + slot.col) > -1 );
        const conditionOnPackages = (!this.selectedPackage || (this.selectedPackage && slot.packagesIds.indexOf( parseInt(this.selectedPackage) ) > -1));
        const conditionOnString = (this.searchString && slot.label.toLowerCase().replace(/\s/g, '').indexOf(this.searchString.toLowerCase().replace(/\s/g, '')) > -1);

        if ( (!this.searchString && conditionOnPackages) || (this.searchString && (conditionOnReservations || conditionOnString) && conditionOnPackages)) {

            const outputObj = {
              id: slot.id.toString(),
              title: slot.label,
              zone: this.resortData.zonesByIds[slot.zoneId].name[this.dataService.lang],
              eventOverlap: false,
              row: slot.row,
              col: slot.col,
              order: 0
            };

            if (this.reservationMapData[slot.row] && this.reservationMapData[slot.row][slot.col]) {

              this.reservationMapData[slot.row][slot.col].reservationsKeys.sort( this.sortByTimestamp );

              let index = 0;

              for (const ts of this.reservationMapData[slot.row][slot.col].reservationsKeys) {

                let className = 'daily';

                if (ts.timeSlot && ts.timeSlot.id) {
                  className = 'partial'
                  if ((ts.timeSlot.startSeconds + ts.timeSlot.utcOffset*60*60) > 43200) {
                    className = 'partial afternoon';
                  }
                }

                let bgColor = '#FF902A';
                let bgLightColor = 'rgba(255, 144, 42,0.5);'

                if (ts.state == 4) {
                  bgColor = '#b490e5';
                  bgLightColor = 'rgba(180, 144, 229,0.5);'
                } else if (ts.state == 0) {
                  bgColor = '#FF5453';
                  bgLightColor = 'rgba(255, 84, 83,0.5);'
                } else if (ts.state == 5) {
                  bgColor = '#05214c';
                  bgLightColor = 'rgba(5, 33, 76,0.5);'
                } else if (ts.state == 3) {
                  bgColor = '#0f9fff';
                  bgLightColor = 'rgba(15, 159, 255,0.5);'
                } else if (ts.state == 6) {
                  bgColor = '#aeaeae';
                  bgLightColor = 'rgba(255, 144, 42,0.5);'
                }

                const day = new Date(ts.day_timestamp*1000).getDate();
                const month = new Date(ts.day_timestamp*1000).getMonth() + 1;
                const year = new Date(ts.day_timestamp*1000).getFullYear();

                let monthString = month.toString();
                let dayString = day.toString();

                if (month < 10) {
                  monthString = '0' + monthString;
                }

                if (day < 10 ) {
                  dayString = '0' + dayString;
                }

                let borderColor = 'white';

                if (!ts.settled) {
                  borderColor = 'white';
                }

                let title = ts.name;

                if (ts.notes && ts.notes !== ''  && this.resortData.id !== 119) {
                  title += ' - Note: ' +ts.notes
                }

                if (!lastSlot) {
                  newEvent = {
                    id: slot.row+'_'+slot.col+'_'+ts.day_timestamp,
                    resourceId: slot.id.toString(),
                    title: title,
                    reservationId: ts.reservation,
                    label: slot.label,
                    row: slot.row,
                    col: slot.col,
                    editable: true,
                    start: year + '-' + monthString + '-' + dayString + 'T00:00:00+00:00',
                    end: year + '-' + monthString + '-' + dayString + 'T24:00:00+00:00',
                    backgroundColor: bgColor,
                    borderColor,
                    state: ts.state,
                    allDay: true,
                    className:className
                  };

                  lastStart = year + '-' + monthString + '-' + dayString + 'T00:00:00+00:00';
                  lastEnd = year + '-' + monthString + '-' + dayString + 'T24:00:00+00:00';
                  lastState = ts.state;
                  lastColor = bgLightColor;
                } else if (newEvent.reservationId == ts.reservation && (( new Date(newEvent.end).getTime()) - ts.day_timestamp*1000 == 0)) {
                  newEvent.end = year + '-' + monthString + '-' + dayString + 'T24:00:00+00:00';
                } else {

                  if (newEvent.state != 6) {
                    this.events.push(newEvent);
                  }
                  let title = ts.name;

                  if (ts.notes && ts.notes !== ''   && this.resortData.id !== 119) {
                    title += ' - Note: ' +ts.notes
                  }

                  newEvent = {
                    id: slot.row + '_' + slot.col + '_' + ts.day_timestamp,
                    resourceId: slot.id.toString(),
                    title: title,
                    label: slot.label,
                    row: slot.row,
                    col: slot.col,
                    reservationId: ts.reservation,
                    start: year + '-' + monthString + '-' + dayString + 'T00:00:00+00:00',
                    end: year + '-' + monthString + '-' + dayString + 'T24:00:00+00:00',
                    backgroundColor: bgColor,
                    borderColor,
                    editable: true,
                    state: ts.state,
                    allDay: true,
                    className
                  };
                }

                lastSlot = slot;
                index++;

              }

              //const elementFound = this.events.find(evt => evt.reservationId == newEvent.reservationId && evt.start == newEvent.start && evt.end == newEvent.end);
              const elementFound = this.events.find(evt => evt.reservationId == newEvent.resourceId && evt.start == newEvent.start && evt.end == newEvent.end);
              /* if (slot.label === '430') {
                console.log(elementFound)
              } */
              //&& (!this.lastRealtimePushed || (this.lastRealtimePushed && this.lastRealtimePushed.slots.indexOf(slot.row + '_' + slot.col) === -1) && this.lastRealtimePushed.timestamps.indexOf(this.reservationMapData[slot.row][slot.col].reservationsKeys[0].day_timestamp) === -1)
              if (!elementFound && newEvent.state !== 6) {
                this.events.push(newEvent);
              }
            }

            slots.push(outputObj);
        }
      }

    }
    console.log("EVENTS");
    console.log(this.events);
    console.log(JSON.parse(JSON.stringify(slots)));
    slots.sort(this.naturalCompare);
    let index = 1;
    for (const slot of slots) {
      slot.order = index;
      index++;
    }
    console.log(JSON.parse(JSON.stringify(slots)));

    if (this.firstLoading) {
      this.enableRealTime();
    }

    return slots;
  }

  columnHeaderHtml(date) {
    console.log(date)
    return '<i>other day</i>'
  }

  async getReservationsFromSearch() {

    try {
      const start = this.startTimestamp / 1000;
      const end = this.endTimestamp / 1000;

      const data = await this.dataService.getReservations(start, end, false, false, this.searchString.replace(/ /g, ''), null, null, null, null, null, null, null, null, null, null, 100, 1, true ,null,null, null, null,null, null, null, null,null,null,null);

      console.log(data);
      const slots = [];

      for (const reservation of data[`results`]) {

        for (const interval of reservation.dates) {
          for (const slot of interval.order) {
            if (slots.indexOf(slot.slot_key) == -1) {
              slots.push(slot.slot_key);
            }
          }
        }
      }
      return slots;
    } catch (err) {

    }


  }


  async eventDrop(info) {

    console.log(info)
    console.log(info.oldEvent);
    this.disableRealTime();
    this.selectedReservation = null;
    this.lightLoading = true;

    const oldStart = new Date(info.oldEvent.start).setUTCHours(0, 0, 0);
    const oldEnd = new Date(info.oldEvent.end).setUTCHours(0, 0, 0) - (24 * 60 * 60 * 1000);
    const newStart = new Date(info.event.start).setUTCHours(0, 0, 0);
    const newEnd = new Date(info.event.end).setUTCHours(0, 0, 0) - (24 * 60 * 60 * 1000);
    const slot = info.event.extendedProps;

    console.log(oldStart);
    console.log(oldEnd);

    let eventsFound = this.events.filter(evt => evt.row == slot.row && evt.col == slot.col && evt.reservationId == slot.reservationId);

    console.log(eventsFound);

    let eventFound;
    let oldEventId;

    for (const evt of eventsFound) {
      if (evt.id) {

        const index = this.events.findIndex(eventA => evt.id == eventA.id);
        this.events.splice(index, 1);

        const calendarApi = this.calendarComponent.getApi();
        eventFound = calendarApi.getEventById(evt.id);
        oldEventId = evt.id;
        if (eventFound) {
          eventFound.remove();
        }

      }
    }


    // RECUPERA PRENOTAZIONE

    if (slot.reservationId) {

      const reservation = await this.dataService.getSingleReservation(slot.reservationId);
      const oldReservation = JSON.parse(JSON.stringify(reservation));

      // ALLUNGA-ACCORCIA POSTAZIONE E INTERVALLO SELEZIONATO

      let newInterval = null;

      console.log(reservation);

      for (const interval of reservation.dates) {
        if (interval.start == oldStart/1000 && interval.end == oldEnd/1000) {

          if (interval.order.length == 1 && interval.order[0].col == slot.col && interval.order[0].row == slot.row) {
            interval.start = newStart/1000;
            interval.end = newEnd/1000;
            interval.date_key = newStart/1000 + '_' + newEnd/1000;
            interval.length = (interval.end - interval.start) / (24 * 60 * 60) + 1;

          } else {
            newInterval = {
              start: newStart/1000,
              end: newEnd/1000,
              date_key: newStart/1000 + '_' + newEnd/1000,
              length: (interval.end - interval.start) / (24 * 60 * 60) + 1,
              intervalAmount: 0,
              optionalsAmount: 0,
              optionalsCounter: 0,
              order: []
            }

            const findOrder = interval.order.findIndex(ord => ord.col == slot.col && ord.row == slot.row);

            if (findOrder > -1) {
              newInterval.order.push(interval.order[findOrder]);
              interval.order.splice(findOrder, 1);
              reservation.dates.push(newInterval);
            }
          }

          break;
        }
      }

      this.settingsService.updateCart(reservation,false);

      let settled = oldReservation.order.settled;
      if (oldReservation.order.total < reservation.order.total) {
        settled = false;
      }

      const totalReservationBill = reservation.order.customerBill.findIndex(bill => bill.type === 'settlement');

      for (const customerBill of reservation.order.customerBill) {
        if (customerBill.type === 'settlement') {
          customerBill.type = 'movement';
        }
      }

      this.settingsService.updateCustomerBill(reservation, settled, 'cash');
      // AGGIORNA PRENOTAZIONE

      reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
      reservation.updatedBy = this.settingsService.getCurrentUserData();


      this.updateStartEndDate(reservation);

      await this.dataService.updateReservation(reservation);
      console.log(JSON.parse(JSON.stringify(reservation)));

    } else if (slot.state == 4) {
      console.log("SPOSTA RISERVA")
      // LIBERA VECCHI SLOTS

      let timestamps = [];

      let length = (oldEnd - oldStart) / (24 * 60 * 60 * 1000) + 1;

      for (let i = 0; i < length ; i++) {
        const ts = oldStart / 1000 + 24 * 60 * 60 * i;
        timestamps.push(ts);
      }
      let selectedSlots = []
      selectedSlots.push(slot.row+'_'+slot.col)
      let apiObj = this.settingsService.setupReservationsMaps(selectedSlots, 4, false, timestamps,this.reservationMapData, oldStart, oldEnd,null);

      console.log(apiObj);
      await this.dataService.removeReservationsMaps(apiObj);

      length = (newEnd - newStart) / (24 * 60 * 60 * 1000) + 1;
      timestamps = [];
      for (let i = 0; i < length ; i++) {
        const ts = newStart / 1000 + 24 * 60 * 60 * i;
        timestamps.push(ts);
      }

      apiObj = this.settingsService.setupReservationsMaps(selectedSlots, 4, false, timestamps,this.reservationMapData, newStart, newEnd,null);

      await this.dataService.setReservationsMaps(apiObj);
    }

    this.enableRealTime();
    this.lightLoading = false;
  }

  selectDate(event) {

    this.showBottomBar = true;
    const startTS = new Date(event.start).setUTCHours(0,0,0)/1000;
    const endTS = new Date(event.end).setUTCHours(0,0,0)/1000;

    const newResource = {
      drawn: true,
      id: event.resource.id,
      row: event.resource.extendedProps.row,
      col: event.resource.extendedProps.col,
      start: startTS,
      end: endTS,
      eventStart: event.start,
      eventEnd: event.end
    }

    this.selectedResources.push(newResource);

    const selectedResource = this.resources.find(res => res.id == newResource.id);

    let  id = selectedResource.id+'_'+newResource.start+'_'+newResource.end;

    const newEvent = {
      id,
      resourceId: selectedResource.id,
      title: '',
      reservationId: null,
      label: selectedResource.title,
      row: newResource.row,
      col: newResource.col,
      start: newResource.eventStart,
      end: newResource.eventEnd,
      allDay: true,
      state: -1,
      classNames: 'planner-slot'
    };

    this.events.push(newEvent);

    const calendarApi = this.calendarComponent.getApi();
    calendarApi.addEvent(newEvent);

    this.resourcesCounter = 0;

    const resArray = [];
    for (const res of this.selectedResources ) {
      if (resArray.indexOf(res.id) == -1) {
        resArray.push(res.id);
        this.resourcesCounter++;
      }
    }

    console.log(this.selectedResources);

    calendarApi.unselect();
  }

  unselect(event) {

  }

  async dateClick(event) {
    console.log("DATE CLICK")
    console.log(event)
    this.selectedReservation = null;
  }

  async selectEnd(event) {
    console.log("END SELECT")
    /* let slot = event.resource.extendedProps;
    let date = new Date(event.dateStr).setUTCHours(0,0,0,0);
    console.log(slot, date); */
  }

  async eventClick(event) {
    if (event.event.extendedProps.reservationId && event.event.extendedProps.state != 6) {

      this.selectedSlots = [];
      this.selectedSlot = event.event.extendedProps;

      const startTS = new Date(event.event.start).setUTCHours(0,0,0);
      const endTS = new Date(event.event.end).setUTCHours(0,0,0);

      const reservation = await this.dataService.getSingleReservation(event.event.extendedProps.reservationId);

      for (const interval of reservation.dates) {
        if (( startTS / 1000 <= interval.start &&  endTS / 1000 <= interval.end  ) || ( startTS / 1000 >=  interval.start && endTS / 1000 <= interval.end  ) || ( startTS / 1000 <= interval.end && endTS / 1000 >= interval.end)) {

          reservation.selectedInterval = interval;

          let flagCounter = false;

          for (const order of interval.order) {
            if (!order.optionalsKeys) {
              order.optionalsKeys = {};
            }

            order.selected = false;
            if (!(this.selectedSlot.row == order.row && this.selectedSlot.col == order.col)) {
              this.selectedSlots.push(order);

              if (!flagCounter) {
                order.selected = true;
                flagCounter = true;
              }
            }

          }

          break;
        }
      }

      this.resetSelectedSlots();
      this.selectedReservation = reservation;
      this.selectedReservation.state = event.event.extendedProps.state;
      this.selectedReservation.payed = 0;
      this.selectedReservation.notPayed = 0;

      for (let interval of this.selectedReservation.dates) {

        interval.groupByCombo = {};
        for (const product of interval.order) {

          if (!interval.groupByCombo[product.packageKey + '_' + product.zoneKey]) {
            interval.groupByCombo[product.packageKey + '_' + product.zoneKey] = {
              products: [],
              quantity: 0,
              optionals: {},
              packageId: product.packageKey,
              zoneId: product.zoneKey
            };
          }

          if (product.quantity)
            interval.groupByCombo[product.packageKey + '_' + product.zoneKey].quantity += product.quantity;
          else
            interval.groupByCombo[product.packageKey + '_' + product.zoneKey].quantity += 1

          interval.groupByCombo[product.packageKey + '_' + product.zoneKey].products.push(product);

          if (product.optionalsKeys) {
            for (let optKey in product.optionalsKeys) {
              if (product.optionalsKeys[optKey]) {
                if (!this.optionals[optKey]) {
                  for (const optId in this.optionals ) {
                    if (this.optionals[optId].id == optKey) {
                      optKey = this.optionals[optId].id;
                      break;
                    }
                  }
                }

                if (!interval.groupByCombo[product.packageKey + '_' + product.zoneKey].optionals[optKey]) {
                  interval.groupByCombo[product.packageKey + '_' + product.zoneKey].optionals[optKey] = 0;
                }

                interval.groupByCombo[product.packageKey + '_' + product.zoneKey].optionals[optKey] += product.optionalsKeys[optKey];
              }

            }

          }
        }
      }

      for (const customerBill of this.selectedReservation.order.customerBill) {
        if (customerBill.payed) {
          this.selectedReservation.payed += customerBill.price;
        } else {
          this.selectedReservation.notPayed += customerBill.price;
        }
      }
    } else if (event.event.extendedProps.state == -1) {
      const target = (event.jsEvent.target);

      const dayIndex = Math.floor(event.jsEvent.offsetX / parseInt(this.slotWidth));
      console.log(dayIndex);
      const startTS = new Date(event.event.start).setUTCHours(0, 0, 0);
      const endTS = new Date(event.event.end).setUTCHours(0, 0, 0);


      // DAY TO REMOVE
      const dayToRemove = startTS + dayIndex * 24 * 60 * 60 * 1000;

      const eventFound = this.events.find(evt => evt.id == event.event.id);
      const eventFoundIndex =  this.events.findIndex(evt => evt.id == event.event.id);

      const resourceFound = this.selectedResources.find(res => res.start === startTS/1000 && res.end === endTS/1000 && res.row == event.event.extendedProps.row && res.col == event.event.extendedProps.col);
      const resourceFoundIndex = this.selectedResources.findIndex(res => res.start === startTS/1000 && res.end === endTS/1000 && res.row == event.event.extendedProps.row && res.col == event.event.extendedProps.col);
      const calendarApi = this.calendarComponent.getApi();

      if (startTS == (endTS-24*60*60*1000)) {
        // EVENTO DI UN SOLO GG -> ELIMINA EVENTO
        this.events.splice(eventFoundIndex,1);
        this.selectedResources.splice(resourceFoundIndex,1)
        const evtFound = calendarApi.getEventById(eventFound.id);
        evtFound.remove();
      } else {

        for (let ts = startTS; ts < endTS; ts+=24*60*60*1000 ) {
           // SE HAI TROVATO IL GIORNO DA RIMUOVERE
           if (ts == dayToRemove) {
            if (ts == startTS) {
              const evtFound = calendarApi.getEventById(eventFound.id);
              const day = new Date(ts+24*60*60*1000).getDate();
              const month = new Date(ts+24*60*60*1000).getMonth() + 1;
              const year = new Date(ts+24*60*60*1000).getFullYear();

              let monthString = month.toString();
              let dayString = day.toString();

              if (month < 10) {
                monthString = '0' + monthString;
              }

              if (day < 10 ) {
                dayString = '0' + dayString;
              }

              eventFound.start = year + '-' + monthString + '-' + dayString + 'T00:00:00+00:00';
              eventFound.id = eventFound.id.split("_")[0] + '_' + (ts+24*60*60*1000) + '_' + eventFound.id.split("_")[2];
              resourceFound.eventStart = new Date(eventFound.start);
              resourceFound.start = (ts+24*60*60*1000)/1000;

              evtFound.remove();
              calendarApi.addEvent(eventFound);
            } else if (ts == (endTS-24*60*60*1000)) {
              const evtFound = calendarApi.getEventById(eventFound.id);
              const day = new Date(ts).getDate();
              const month = new Date(ts).getMonth() + 1;
              const year = new Date(ts).getFullYear();

              let monthString = month.toString();
              let dayString = day.toString();

              if (month < 10) {
                monthString = '0' + monthString;
              }

              if (day < 10 ) {
                dayString = '0' + dayString;
              }

              eventFound.end = year + '-' + monthString + '-' + dayString + 'T00:00:00+00:00';
              eventFound.id = eventFound.id.split("_")[0] + '_' + (ts) + '_' + eventFound.id.split("_")[2];

              resourceFound.eventEnd = new Date(eventFound.end);
              resourceFound.end = ts/1000;
              evtFound.remove();
              calendarApi.addEvent(eventFound);
            } else {

              // SE LA DATA E' IN MEZZO

              const evtFound = calendarApi.getEventById(eventFound.id);

              const newEvent = JSON.parse(JSON.stringify(eventFound));
              const day = new Date(ts).getDate();
              const month = new Date(ts).getMonth() + 1;
              const year = new Date(ts).getFullYear();

              const dayNew = new Date(ts+24*60*60*1000).getDate();
              const monthNew = new Date(ts+24*60*60*1000).getMonth() + 1;
              const yearNew = new Date(ts+24*60*60*1000).getFullYear();

              let monthString = month.toString();
              let dayString = day.toString();

              let monthStringNew = monthNew.toString();
              let dayStringNew = dayNew.toString();

              if (month < 10) {
                monthString = '0' + monthString;
              }

              if (day < 10 ) {
                dayString = '0' + dayString;
              }

              if (monthNew < 10) {
                monthStringNew = '0' + monthStringNew;
              }

              if (dayNew < 10 ) {
                dayStringNew = '0' + dayStringNew;
              }

              // OLD EVENT

              eventFound.end = year + '-' + monthString + '-' + dayString + 'T00:00:00+00:00';
              eventFound.id = eventFound.id.split("_")[0] + '_' + eventFound.id.split("_")[1] + '_' + ts;

              resourceFound.end = ts/1000;
              resourceFound.eventEnd = new Date(eventFound.end);

              // NEW EVENT

              newEvent.start = yearNew + '-' + monthStringNew + '-' + dayStringNew + 'T00:00:00+00:00';
              newEvent.id = newEvent.id.split("_")[0] + '_' + (ts+24*60*60*1000) + '_' + eventFound.id.split("_")[2];

              this.selectedResources.push({
                drawn: true,
                id: eventFound.resourceId,
                row: eventFound.row,
                col: eventFound.col,
                start: (ts+24*60*60*1000)/1000,
                end: endTS/1000,
                eventStart: new Date(newEvent.start),
                eventEnd: new Date(newEvent.end)
              });

              this.resourcesCounter = 0;

              const resArray = [];
              for (const res of this.selectedResources ) {
                if (resArray.indexOf(res.id) == -1) {
                  resArray.push(res.id);
                  this.resourcesCounter++;
                }
              }

              this.events.push(newEvent);
              evtFound.remove();
              calendarApi.addEvent(eventFound);
              calendarApi.addEvent(newEvent);

            }

            break;
          }
        }
      }

    }

    console.log(this.selectedResources);
    console.log(new Date().getMinutes() + ':' + new Date().getSeconds() + ':' + new Date().getMilliseconds());
    console.log();
  }

  selectOrder(order) {

    for (const orderInterval of this.selectedReservation.selectedInterval.order) {
      orderInterval.selected = false;
    }

    order.selected = true;
  }

  sortByTimestamp( a, b ) {
    if ( a.day_timestamp < b.day_timestamp ) {
      return -1;
    }
    if ( a.day_timestamp > b.day_timestamp ) {
      return 1;
    }
    return 0;
  }

  async eventResize(info) {
    console.log(info)
    console.log(info.prevEvent);

    this.lightLoading = true;
    this.selectedReservation = null;

    const oldStart = new Date(info.prevEvent.start).setUTCHours(0, 0, 0);
    const oldEnd = new Date(info.prevEvent.end).setUTCHours(0, 0, 0) - (24 * 60 * 60 * 1000);
    const newStart = new Date(info.event.start).setUTCHours(0, 0, 0);
    const newEnd = new Date(info.event.end).setUTCHours(0, 0, 0) - (24 * 60 * 60 * 1000);
    const slot = info.event.extendedProps;
    console.log(oldStart);
    console.log(slot);

    let bgLightColor = ''

    if (slot.state == 0) {
      bgLightColor = 'rgba(255, 84, 83,0.5);'
    } else if (slot.state == 4) {
      bgLightColor = 'rgba(180, 144, 229,0.5);'
    } else if (slot.state == 5) {
      bgLightColor = 'rgba(5, 33, 76,0.5);'
    } else if (slot.state == 3) {
      bgLightColor = 'rgba(15, 159, 255,0.5);'
    } else if (slot.state == 2) {
      bgLightColor = 'rgba(255, 144, 42,0.5);'
    }


    // RECUPERA PRENOTAZIONE

    if (slot.reservationId) {

      const startDifference = (newStart - oldStart)/1000;
      const endDifference = (newEnd - oldEnd)/1000;

      console.log(startDifference);
      console.log(startDifference/(24*60*60));
      console.log(endDifference);
      console.log(endDifference/(24*60*60));

      const reservation = await this.dataService.getSingleReservation(slot.reservationId);
      const oldReservation = JSON.parse(JSON.stringify(reservation));
      // ALLUNGA-ACCORCIA POSTAZIONE E INTERVALLO SELEZIONATO

      for (const interval of reservation.dates) {
        if (interval.start == oldStart/1000 && interval.end == oldEnd/1000) {
          if (interval.order.length == 1 && interval.order[0].col == slot.col && interval.order[0].row == slot.row) {
            console.log(interval.order[0]);
            interval.start = newStart / 1000;
            interval.end = newEnd / 1000;
            interval.date_key = newStart + '_' + newEnd;
            interval.length = (interval.end - interval.start) / ( 24 * 60 * 60) + 1;
          } else {
            const newInterval = {
              start: 0,
              date_key: '',
              end: 0,
              intervalAmount: 0,
              length: 5,
              optionalsAmount: 0,
              optionalsCounter: 0,
              order: []
            };

            newInterval.start = newStart / 1000;
            newInterval.end = newEnd / 1000;
            newInterval.date_key = newStart + '_' + newEnd;
            newInterval.length = (newInterval.end - newInterval.start) / (24 * 60 * 60) + 1;

            const orderFound = interval.order.findIndex(ord => ord.col == slot.col && ord.row == slot.row) ;
            console.log(orderFound);
            newInterval.order.push(interval.order[orderFound]);
            interval.order.splice(orderFound, 1);
            reservation.dates.push(newInterval);
          }

          break;
        }
      }

      this.settingsService.updateCart(reservation,false);

      let settled = oldReservation.order.settled;
      if (oldReservation.order.total < reservation.order.total) {
        settled = false;
      }

      const totalReservationBill = reservation.order.customerBill.findIndex(bill => bill.type === 'settlement');

      for (const customerBill of reservation.order.customerBill) {
        if (customerBill.type === 'settlement') {
          customerBill.type = 'movement';
        }
      }

      this.settingsService.updateCustomerBill(reservation, settled, 'cash');
      // AGGIORNA PRENOTAZIONE

      reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
      reservation.updatedBy = this.settingsService.getCurrentUserData();

      this.updateStartEndDate(reservation);
      await this.dataService.updateReservation(reservation);

      console.log(JSON.parse(JSON.stringify(reservation)));

    } else if (slot.state == 4) {
      console.log("SPOSTA RISERVA")
      // LIBERA VECCHI SLOTS

      let timestamps = [];

      let length = (oldEnd - oldStart) / (24 * 60 * 60 * 1000) + 1;

      for (let i = 0; i < length ; i++) {
        const ts = oldStart / 1000 + 24 * 60 * 60 * i;
        timestamps.push(ts);
      }
      let selectedSlots = []
      selectedSlots.push(slot.row+'_'+slot.col)
      let apiObj = this.settingsService.setupReservationsMaps(selectedSlots, 4, false, timestamps,this.reservationMapData, oldStart, oldEnd,null);

      console.log(apiObj);
      await this.dataService.removeReservationsMaps(apiObj);

      length = (newEnd - newStart) / (24 * 60 * 60 * 1000) + 1;
      timestamps = [];
      for (let i = 0; i < length ; i++) {
        const ts = newStart / 1000 + 24 * 60 * 60 * i;
        timestamps.push(ts);
      }

      apiObj = this.settingsService.setupReservationsMaps(selectedSlots, 4, false, timestamps,this.reservationMapData, newStart, newEnd,null);

      await this.dataService.setReservationsMaps(apiObj);
    }

    this.lightLoading = false;
  }

  openReservation(id) {
    this.selectedReservation = null;
    this.dataService.previousPage = 'planner'
    this.router.navigateByUrl('/suite/reservation/'+id);
  }

  updateStartEndDate(reservation) {
    reservation.start = null;

    for (const interval1 of reservation.dates) {
      interval1.date_key = interval1.start + '_' + interval1.end;
      if (interval1.id === null) {
        delete interval1.id;
      }

      if (!reservation.start) {
        reservation.start = interval1.start;
        reservation.end = interval1.end;
      }

      if (interval1.start < reservation.start) {
        reservation.start = interval1.start;
      }

      if (interval1.end > reservation.end) {
        reservation.end = interval1.end;
      }
    }
  }

  setSelectedReservation(reservation) {
    console.log(reservation);
  }

  resetSelectedSlots() {
    let copyEvents = JSON.parse(JSON.stringify(this.events));
    for (let i=0; i<copyEvents.length; i++) {
      if (copyEvents[i].state == -1) {
          this.events.splice(i,1)

          let calendarApi = this.calendarComponent.getApi();
          let dropEvent = calendarApi.getEventById(copyEvents[i].id);

          if (dropEvent) {
            dropEvent.remove();
          }
      }
    }

    this.selectedResources = [];
    this.resourcesCounter = 0;
  }

  resetPlanner() {
    this.selectedReservation = null;
    this.resetSelectedSlots();
    this.selectedSlots = [];
    this.selectedSlot = null;
    this.selectedResources = [];
    this.resourcesCounter = 0;
  }

  async datesRender(evt) {

    console.log("DATES RENDER")
    console.log(evt);
    console.log(this.firstLoading);
    if (!this.firstLoading) {
      this.events = [];

      const startTS = new Date(evt.view.activeStart).setUTCHours(0,0,0) / 1000;
      const endTS = new Date(evt.view.activeEnd).setUTCHours(0,0,0) / 1000;

      this.startTimestamp = startTS*1000;
      this.endTimestamp = endTS*1000;

      this.reservationMapData = await this.dataService.getMapReservations(startTS, endTS);

      await this.generateRows();

      if (!this.firstLoading) {
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.addEventSource(this.events);
      }

      this.firstLoading = false;
    }


  }

  async updateResources() {
    this.lightLoading = true;
    this.resources = await this.generateRows();
    this.lightLoading = false;
  }


}
