import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service';
import { SettingsService } from '../../../core/providers/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import Map from 'ol/Map.js';
import XYZ from 'ol/source/XYZ';
import { defaults as defaultInteractions, DragRotateAndZoom} from 'ol/interaction.js';
import TileLayer from 'ol/layer/Tile.js';
import View from 'ol/View';
import * as olProj from 'ol/proj';
import { Feature } from 'ol';
import VectorSource from 'ol/source/Vector.js';
import {  Translate } from 'ol/interaction';
import Collection from 'ol/Collection';
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from 'ol/style';
import { LineString, Point} from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import Geolocation from 'ol/Geolocation';
import { unByKey } from 'ol/Observable';
import {} from 'googlemaps';

@Component({
  selector: 'app-settings-packages',
  templateUrl: './settings-packages.component.html',
  styleUrls: ['./settings-packages.component.scss']
})
export class SettingsPackagesComponent implements OnInit {

  resortData; managerData; pageClicked; prices; submenu; lang = 'it'; elements; features; optionals; services; dataLang = 'it'; map; geolocation; targetFeature;
  startTimestamp; endTimestamp; startSerializedDate; endSerializedDate; uploadPercents; uploadPercentNew; uploadPercentLogo; mapListener; mapClickListener;
  showLanguageModal1 = false; showLanguageModal2=false; loading;showLanguageModal3=false; showTip = false; showTipRemove = false;
  packagesSlot:any=[]; packagesOrder:any=[];lightLoading=false;

  constructor(public snackBar: MatSnackBar, private storage: AngularFireStorage, private route: ActivatedRoute, public dataService: DataService, private settingsService: SettingsService) {

    this.submenu = 0;
    this.route.params.subscribe(params => {
      if (params['menuIndex']) {
        this.submenu = params['menuIndex'];
      }
    });

    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.prices = this.dataService.prices;
    this.dataLang = this.dataService.lang;
  }

  async ngOnInit() {

    this.loading = true;
    this.lightLoading = true;
    console.log("START");

    if (!this.resortData) {
      await this.dataService.initResortData();
      this.loading = false;
    } else {
      this.resortData = await this.dataService.getResortData();
      this.loading = false;
      //[this.resortData, this.prices] = await Promise.all([this.dataService.getResortData(), this.dataService.getPrices()]);
      this.prices = await this.dataService.getPrices()
    }

    this.resortData =  this.dataService.resortData;
    this.prices =   this.dataService.prices;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.services = this.dataService.services;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;

    this.services.map(service => {
      service.selected = false;
      if (this.resortData.services.indexOf(service.id) > -1) {
        service.selected = true;
      }
    });

    this.uploadPercents = [];

    for (const image of this.resortData.images) {
      this.uploadPercents.push(0);
    }

    console.log(this.uploadPercents);

    this.packagesSlot = this.resortData.packages.filter(p=> { return (p.type === 'slot')?true:false;  });
    this.packagesOrder = this.resortData.packages.filter(p=> { return (p.type === 'order')?true:false;  });

    if (this.resortData.openingDate) {
      this.startTimestamp = this.resortData.openingDate * 1000;
      this.startSerializedDate = new Date(this.startTimestamp);
      const timezone = new Date(this.resortData.openingDate * 1000).getTimezoneOffset();

      if (timezone !== 0) {
        const start = (new Date(this.resortData.openingDate * 1000).getTime() - timezone * 60000) / 1000;
        this.resortData.openingDate = start;
      }
    } else {
      const offsetStart = new Date().getTimezoneOffset() * 60000;
      const startDate = ((new Date()).setHours(0, 0, 0, 0) - offsetStart ) / 1000 ;
      this.startSerializedDate = new Date(startDate * 1000);
    }

    if (this.resortData.closingDate) {
      this.endTimestamp = this.resortData.closingDate * 1000;
      this.endSerializedDate = new Date(this.endTimestamp);
      const timezone = new Date(this.resortData.closingDate * 1000).getTimezoneOffset();

      if (timezone !== 0) {
        const end = (new Date(this.resortData.closingDate * 1000).getTime() - timezone * 60000) / 1000;
        this.resortData.closingDate = end;
      }
    } else {
      const offsetEnd = new Date().getTimezoneOffset() * 60000;
      const endDate = ((new Date()).setHours(0, 0, 0, 0) - offsetEnd ) / 1000 ;
      this.endSerializedDate = new Date(endDate * 1000);
    }

    for (const element of this.elements) {
      const eleIndex = this.resortData.elementsThreshold.findIndex(ele => ele.id === element.id);
      element.dailyQuantity = 0;
      element.threshold = 0;
      element.selected = false;

      if (eleIndex > -1) {
        element.selected = true;
        element.threshold = this.resortData.elementsThreshold[eleIndex].threshold;
        element.dailyQuantity = this.resortData.elementsThreshold[eleIndex].dailyQuantity;
      }
    }

    const self = this;
    setTimeout(() => {
      this.map = new Map({
        keyboardEventTarget: document.getElementById('map'),
        interactions: defaultInteractions().extend([
          new DragRotateAndZoom()
        ]),
        layers: [
          new TileLayer({
            source: new XYZ({
                url: 'http://mt1.google.com/vt/lyrs=m@113&hl=en&&x={x}&y={y}&z={z}',
                attributions: '© ' + new Date().getFullYear() + ' Google Maps'
            })
          })
        ],
        target: 'map',
        fractionalZoom: true,
        view: new View({
          center:  olProj.fromLonLat([self.resortData.coords.longitude, self.resortData.coords.latitude]),
          zoom: 16
        })
      });

      this.geolocation =  new Geolocation({
        trackingOptions: {
          enableHighAccuracy: true
        }
      });

      this.geolocation.setTracking(true);


      this.map.getView().setCenter(olProj.transform([self.resortData.coords.longitude, self.resortData.coords.latitude], 'EPSG:4326', 'EPSG:3857'));

      const center = this.map.getView().getCenter();
      this.targetFeature = new Feature(new Point(center));
      const markerCenterLayer = new VectorLayer ({
        interactions: defaultInteractions().extend([
          new DragRotateAndZoom()
        ]),
        source: new VectorSource ({
          features: [this.targetFeature]
        }),
        style: new Style ({
          image: new Icon({
            src: 'assets/media/marker.png',
            anchor: [0.5, 40],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
          })
        })
      });

      const moveEndFeature = new Translate({
          features: new Collection([this.targetFeature])
      });
      this.targetFeature.setId(10);


      const markerListener = this.targetFeature.on('change', (evt) => {
        const point = evt.target.getGeometry().getCoordinates();
        const coords = olProj.transform(point, 'EPSG:3857', 'EPSG:4326');
        const lon = coords[0];
        const lat = coords[1];
        self.resortData.coords.latitude = lat;
        self.resortData.coords.longitude = lon;
      });

      self.map.addLayer(markerCenterLayer);
      self.map.addInteraction(moveEndFeature);
    }, 500);
    this.lightLoading = false;

  }

  centerMap() {

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({  address : this.resortData.address + ' ' + this.resortData.zipCode + ' ' + this.resortData.city },  (results, status) => {

      if (results && results[0]) {
        this.map.getView().setCenter(olProj.transform([results[0].geometry.location.lng(), results[0].geometry.location.lat()], 'EPSG:4326', 'EPSG:3857'));
        const center = this.map.getView().getCenter();
        this.targetFeature.getGeometry().setCoordinates(center);
      }

    });
  }

  checkTicketPrice() {
    if (this.resortData.childrenTicketPrice < 0) {
      this.resortData.childrenTicketPrice = 0;
    }
  }

  checkChildrenAges() {
    this.resortData.childrenAgeMax = parseInt(this.resortData.childrenAgeMax);
    this.resortData.childrenAgeMin = parseInt(this.resortData.childrenAgeMin);
  }


  resetHeader() {
    this.pageClicked = true;
    setTimeout( () => {
      this.pageClicked = false;
    }, 1000);
  }

  async saveResort() {
    this.resortData.elementsThreshold = [];

    for (const element of this.elements) {
      if (element.selected) {
        this.resortData.elementsThreshold.push({
          id: element.id,
          threshold: element.threshold,
          dailyQuantity: element.dailyQuantity
        });
      }
    }

    this.resortData.services = [];

    this.services.map(service => {
      if (service.selected) {
        this.resortData.services.push(service.id);
      }
    });

    const offsetStart = new Date(this.startSerializedDate).getTimezoneOffset() * 60000;
    const offsetEnd = new Date(this.endSerializedDate).getTimezoneOffset() * 60000;
    this.resortData.openingDate = ( (new Date(this.startSerializedDate)).setHours(0, 0, 0) - offsetStart ) / 1000;
    this.resortData.closingDate = ( (new Date(this.endSerializedDate)).setHours(0, 0, 0) - offsetEnd ) / 1000;

    console.log(this.resortData.openingDate);

    try {
      await this.dataService.setResort(this.resortData);

      this.openSnackBar('INFORMAZIONI SALVATE CORRETTAMENTE', 'OK');

    } catch (err) {
      console.log(err);
    }
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    if (this.startSerializedDate > this.endSerializedDate) {
      this.endSerializedDate = this.startSerializedDate;
    }

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    if (this.endSerializedDate < this.startSerializedDate) {
      this.startSerializedDate = this.endSerializedDate;
    }

  }

  moveLeft(index) {
    this.insertAndShift(this.resortData.images, index, index - 1);
  }

  moveRight(index) {
    this.insertAndShift(this.resortData.images, index, index + 1);
  }

  insertAndShift(arr, from, to) {
      const cutOut = arr.splice(from, 1) [0]; // cut the element at index 'from'
      arr.splice(to, 0, cutOut);            // insert it at index 'to'
  }

  async uploadFile(event, index) {
    const file = event.target.files[0];
    console.log(file);

    if (file) {
      const filePath = 'resorts/' + this.dataService.resortData.id + '/gallery/' + file.name;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      // observe percentage changes
      if (index >= 0 ) {
        this.uploadPercents[index] = task.percentageChanges();
      } else {
        this.uploadPercentNew = task.percentageChanges();
      }
      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(async () => {

            if (index >= 0) {
              this.resortData.images[index] = await fileRef.getDownloadURL().toPromise();
            } else {
              this.uploadPercentNew = null;
              this.resortData.images.push(await fileRef.getDownloadURL().toPromise());
            }

            this.uploadPercents = [];
            for (const image of this.resortData.images) {
              this.uploadPercents.push(0);
            }
          })
       )
      .subscribe();
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  async uploadLogo(event) {
    const file = event.target.files[0];
    console.log(file);

    if (file) {
      const filePath = 'resorts/' + this.dataService.resortData.id + '/' + file.name;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      this.uploadPercentLogo =  task.percentageChanges();

      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(async () => {

            this.resortData.logo = await fileRef.getDownloadURL().toPromise();
            this.uploadPercentLogo = 0;
          })
       )
      .subscribe();
    }
  }

  removeImage(index) {
    this.resortData.images.splice(index, 1);
    this.uploadPercents = [];
    for (const image of this.resortData.images) {
      this.uploadPercents.push(0);
    }
  }

  removeLogo() {
    this.resortData.logo = null;
    this.uploadPercentLogo = 0;
  }

  async moveUp(index, array, type) {
    console.log(array);
    let packArray = [];
    this.arraymove(array, index, index - 1);

    let counter = 0;

    for (const ele of array) {
      ele.sequence = counter;
      counter++;

      if (type === 'extras') {
        await this.dataService.updateExtra(ele);
      } else if (type === 'zones') {
        await this.dataService.updateZone(ele);
      } else if (type === 'packages') {
        packArray.push({id:ele.id, sequence: ele.sequence});
      }
    }

    if (packArray.length) {
      await this.dataService.updatePackageSequence(packArray);
    }
  }

  async moveDown(index, array, type) {
    console.log(array);
    let packArray = [];
    this.arraymove(array, index, index + 1);

    let counter = 0;

    for (const ele of array) {
      ele.sequence = counter;
      counter++;

      if (type === 'extras') {
        await this.dataService.updateExtra(ele);
      } else if (type === 'zones') {
        await this.dataService.updateZone(ele);
      } else if (type === 'packages') {
        packArray.push({id:ele.id, sequence: ele.sequence});
      }
    }

    if (packArray.length) {
      await this.dataService.updatePackageSequence(packArray);
    }
  }

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

}
