import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ReservationService } from 'src/app/core/providers/reservation.service';
import { SettingsService } from 'src/app/core/providers/settings.service';

@Component({
  selector: 'app-free-dates-box',
  templateUrl: './free-dates-box.component.html',
  styleUrls: ['./free-dates-box.component.scss']
})
export class FreeDatesBoxComponent implements OnChanges {

  @Input() reservation:any;
  @Input() resortData:any;
  @Input() mainCustomer:any;
  @Input() lang:any;
  @Output() emitLoader: EventEmitter<boolean> = new EventEmitter<boolean>();
  slotsReleased;
  objectKeys = Object.keys;

  constructor( public reservationService: ReservationService, public settingsService: SettingsService) { }

  ngOnChanges() {
    this.processFreeSlots();
  }

  processFreeSlots() {
    this.slotsReleased = {};
    for (const interval of this.reservation.dates) {
      for (const slot of interval.order) {
        if (slot.free && slot.free.length) {
          for (const free of slot.free) {

            for (let ts = free.start; ts <= free.end; ts+=24*60*60) {
              let date = new Date(ts*1000).getDate();
              let day = new Date(ts*1000).getDay();
              let month = new Date(ts*1000).getMonth();
              let year = new Date(ts*1000).getFullYear();

              if (!this.slotsReleased[ts]) {
                this.slotsReleased[ts] = {
                  createdBy: free.createdBy,
                  createdAt: free.createdAt,
                  day,
                  ts,
                  date,
                  month,
                  year,
                  slots:[]
                }
              }
              this.slotsReleased[ts].slots.push({
                label: slot.coords,
                key: slot.slot_key,
                row: slot.row,
                col: slot.col,
                infos: free.infos
              })
            }
          }
        }
      }
    }

    // console.log(this.slotsReleased);
  }


  async freeDatesPdf() {
    this.emitLoader.emit(true);
    await this.reservationService.exportFreeDates(this.slotsReleased, this.reservation, this.mainCustomer);
    this.emitLoader.emit(false);
  }
}
