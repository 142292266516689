import { Injectable } from '@angular/core';
import { DataService, Customer } from './data.service';
import { MatSnackBar } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
declare var epson: any;

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(
    public snackBar: MatSnackBar,
    public dataService: DataService,
    public http: HttpClient
    ) { }

    async searchCocobukUser(email:string) {
      const api = environment.firebaseApiUrl + 'findUserByEmail?email='+email;
      console.log(api);
      const data = await this.http.get(api).toPromise();
      return data;
    }

    async getRefunds(uid:string, resortId:number) {
      const api = environment.firebaseApiUrl + 'getUserRefundCoupon?uid='+uid;
      const data = await this.http.get(api).toPromise();
      let output = data['data'].filter((d:any)=>d.resortId === resortId);
      return output;
    }

    
    async updateRefund(refundData:any) {
        const api = environment.firebaseApiUrl + 'updateRefundCoupon';
        const data = await this.http.post(api,refundData).toPromise();
        return data;
    }

    async pushRefund(refundData:any) {
        const api = environment.firebaseApiUrl + 'pushRefundCoupon';
        const data = await this.http.post(api,refundData).toPromise();
        return data;
    }

    
    async removeRefund(refundData:any) {
        const api = environment.firebaseApiUrl + 'removeRefundCoupon';
        const data = await this.http.post(api,refundData).toPromise();
        return data;
    }

    
    async pushFirebaseUser(customerData:any) {
        const api = environment.firebaseApiUrl + 'pushFirebaseUser';
        console.log(customerData);
        console.log(api);
        const data:any = await this.http.post(api,customerData).toPromise();
        return data.customer;
    }
}
