import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsAdvancedComponent } from './pages/settings-advanced/settings-advanced.component';
import { PrinterDetailComponent } from './pages/settings-advanced/printer-detail/printer-detail.component';
import { MapAreaDetailComponent } from './pages/settings-advanced/map-area-detail/map-area-detail.component';
import { SettingsProfileComponent } from './pages/settings-profile/settings-profile.component';
import { ManagerDetailComponent } from './pages/settings-profile/manager-detail/manager-detail.component';

import { SettingsPackagesComponent } from './pages/settings-packages/settings-packages.component';
import { ZoneDetailComponent } from './pages/settings-packages/zone-detail/zone-detail.component';
import { SeasonDetailComponent } from './pages/settings-packages/season-detail/season-detail.component';
import { PackageDetailComponent } from './pages/settings-packages/package-detail/package-detail.component';
import { ExtraDetailComponent } from './pages/settings-packages/extra-detail/extra-detail.component';
import { DiscountDetailComponent } from './pages/settings-packages/discount-detail/discount-detail.component';
import { MapDetailComponent } from './pages/settings-packages/map-detail/map-detail.component';
import { CabinDetailComponent } from './pages/settings-packages/cabin-detail/cabin-detail.component';

import { SettingsCocobookComponent } from './pages/settings-cocobook/settings-cocobook.component';
import { OfferDetailComponent } from './pages/settings-cocobook/offer-detail/offer-detail.component';
import { CouponDetailComponent } from './pages/settings-cocobook/coupon-detail/coupon-detail.component';
import { MapBookingComponent } from './pages/settings-cocobook/map-booking/map-booking.component';
import { MapOfferComponent } from './pages/settings-cocobook/map-offer/map-offer.component';
import { AuthguardService } from '../core/providers/authguard.service';
import { AvailabilitiesComponent } from './pages/booking_availabilities/availabilities.component';
import { ExtraAvailabilitiesComponent } from './pages/extra-availabilities/extra-availabilities.component';


const routes: Routes = [

  // COMMON SETTINGS
  { path: 'settings/offer/map-detail', component: MapDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/:menuIndex', component: SettingsPackagesComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/zones/:zoneId', component: ZoneDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/seasons/:seasonId', component: SeasonDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/discounts/:discountId', component: DiscountDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/extras/:extraId', component: ExtraDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/packages/:packageId', component: PackageDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/packages/:packageId/:type', component: PackageDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/cabins/:cabinId', component: CabinDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/cocobuk/:menuIndex', component: SettingsCocobookComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/cocobuk/coupons/:couponId', component: CouponDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/cocobuk/offers/:offerId', component: OfferDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/cocobuk/map-booking/:intervalId/:seasonal', component: MapBookingComponent , canActivate: [AuthguardService]},
  { path: 'settings/offer/cocobuk/map-offer/:offerId/:intervalId', component: MapOfferComponent , canActivate: [AuthguardService]},
  { path: 'settings/profiles-and-payments/:menuIndex', component: SettingsProfileComponent , canActivate: [AuthguardService]},

  // SUITE SETTINGS
  { path: 'settings/advanced/:menuIndex', component: SettingsAdvancedComponent , canActivate: [AuthguardService]},
  { path: 'settings/advanced/printers/:printerId', component: PrinterDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/advanced/map-areas/:areaId', component: MapAreaDetailComponent , canActivate: [AuthguardService]},
  { path: 'settings/profiles-and-payments/manager/:managerId', component: ManagerDetailComponent , canActivate: [AuthguardService]},

  { path: 'booking/availabilities', component: AvailabilitiesComponent, canActivate: [AuthguardService] },
  { path: 'settings/packages/availabilities', component: AvailabilitiesComponent, canActivate: [AuthguardService] },
  { path: 'settings/extra/availabilities', component: ExtraAvailabilitiesComponent , canActivate: [AuthguardService]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule {}
