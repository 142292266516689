import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { StripeService } from '../../../core/providers/stripe.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-settings-profile',
  templateUrl: './settings-profile.component.html',
  styleUrls: ['./settings-profile.component.scss']
})
export class SettingsProfileComponent implements OnInit {

  resortData;managerData;pageClicked;prices;submenu;password;stripeData;managers;currentPassword;newPassword;repeatPassword;
  showTip = false;

  constructor(public snackBar: MatSnackBar, private route: ActivatedRoute, private dataService: DataService, private stripeService: StripeService ) {
    this.submenu = 0;
    this.route.params.subscribe(params =>{
      if (params['menuIndex']) {
        this.submenu = params['menuIndex'];
      }
    });

    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.prices = this.dataService.prices;

  }

  async ngOnInit() {

    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.managers = await this.dataService.getResortManagers();
    console.log(this.managers);
    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.stripeData = await this.stripeService.getMerchantData(this.resortData.stripe.id);
    console.log(this.stripeData);
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout(() => {
      this.pageClicked = false;
    }, 1000);
  }

  async saveData() {
    try {

      await this.dataService.updateManager(this.managerData);
      await this.dataService.setResort(this.resortData);
      this.openSnackBar('INFORMAZIONI SALVATE CORRETTAMENTE', 'OK');
    } catch (err) {
      console.log(err);
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  saveStripe() {

  }
}
