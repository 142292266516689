import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-modal-refund',
  templateUrl: './modal-refund.component.html',
  styleUrls: ['./modal-refund.component.scss']
})
export class ModalRefundComponent implements OnInit {

  refundAmount; refundNotes=''; step = 0; refundExpiration;serializedDate;refundObj; clicked=false;

  constructor(public router: Router, private dataService: DataService, public dialogRef: MatDialogRef<ModalRefundComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

        console.log(data.reservation)
        this.refundAmount = data.reservation.order.total;
        this.refundExpiration = data.reservation.end + 24*60*60*60;
        this.serializedDate = new Date(this.refundExpiration * 1000);
    }


  ngOnInit() {
  }

  close(): void {
    if (this.refundObj) {
      this.dialogRef.close(this.refundObj);
    } else {
      this.dialogRef.close(null);
    }
  }

  async refund() {

    if (!this.refundNotes) {
      this.refundNotes = '';
    }

    const obj = {
        refundType: 2,
        reservationCode: this.data.reservation.reservationCode,
        refundAmount: this.refundAmount,
        refundNotes: this.refundNotes,
        refundState: 2,
        refundExpiration: this.refundExpiration
    }

    this.refundObj = obj;

    console.log(obj);

    const response = await this.dataService.refundReservation(obj);

    console.log(response);
    if (response.result && response.result == 'reservations/reservation-refunded') {
      this.step = 10;
    } else {
      this.refundObj = null;
      this.step = 20;
    }
  }

  async refundNoMoney() {
    const obj = {
        refundType: 2,
        reservationCode: this.data.reservation.reservationCode,
        refundAmount: 0,
        refundNotes: this.refundNotes,
        refundState: 3,
        refundExpiration: this.refundExpiration
    }

    this.refundObj = obj;

    console.log(obj);

    const response = await this.dataService.refundReservation(obj);
    console.log(response);
    console.log(JSON.stringify(response.error));

    if (response.result && response.result == 'reservations/reservation-refunded') {
      this.step = 10;
    } else {
      this.refundObj = null;
      this.step = 20;
    }

  }

  changeExpirationDate() {
    const offset= new Date(this.serializedDate).getTimezoneOffset() * 60000;
    this.refundExpiration = ((new Date(this.serializedDate)).setHours(0, 0, 0) - offset ) / 1000;
    console.log(this.refundExpiration);
  }

  checkAmount() {
    if (this.refundAmount > this.data.reservation.order.total) {
      this.refundAmount = this.data.reservation.order.total;
    } else if (this.refundAmount < 0) {
      this.refundAmount = 0;
    }
  }
}
