import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service';
import { SettingsService } from '../../../core/providers/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog} from '@angular/material';
import { ModalErrorComponent } from '../../../shared/components/modal-error/modal-error.component';
import { ModalRefundComponent } from '../../../shared/components/modal-refund/modal-refund.component';
import { ModalRemoveComponent } from '../../../shared/components/modal-remove/modal-remove.component';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.scss']
})
export class ReservationDetailComponent implements OnInit {

  resortData;managerData;lang;data;optionals;lightLoading = false;optionalsByIds={};paymentType;settled;optionalsByKeys={};
  reservation; dailyPrice; name; today; prices; elements; features; services; objectKeys = Object.keys; clicked =false; loading=false;


  constructor(private route: ActivatedRoute, public dialog: MatDialog,public router: Router,public dataService: DataService, private settingsService: SettingsService) {

    this.resortData = this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.managerData = this.dataService.userData;
    this.lang = this.dataService.lang;
    this.today = (new Date()).setUTCHours(0, 0, 0, 0) / 1000;

  }

  async ngOnInit() {

    this.lightLoading = true;
    if (!this.prices) {

      await this.dataService.initResortData();

    }

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;
    this.prices = this.dataService.prices;

    this.optionals.map(opt => {
      this.optionalsByIds[opt.id] = opt;
      this.optionalsByKeys[opt.optionalId] = opt;
    });

    const resId = this.route.snapshot.paramMap.get('id');

    this.reservation = await this.dataService.getSingleReservation(resId);
    console.log(this.reservation);

    if (this.reservation) {
      if (typeof this.reservation.order.dailyPrice !== 'undefined' ) {
        this.dailyPrice = this.reservation.order.dailyPrice;
      } else {
        this.dailyPrice = false;
      }

      if (this.reservation.version === 3) {

        if (this.reservation.dates[0].order.length === 0) {
          let selectedIndex = -1;
          this.reservation.noSlots = true;
          for (const prod of this.reservation.order.products) {
            const selectedPackage = this.resortData.packages.find(pack => pack.name.it === prod.name.it);
            const selectedOptional = this.optionals.find(opt => opt.name.it === prod.name.it);
            const selectedZone = this.resortData.zones.find(zone => zone.name.it === prod.zone.name.it);

            if (selectedPackage) {
              prod.type = 'package';
              prod.packageId = selectedPackage.id;
              prod.zoneId = selectedZone.id;

              this.reservation.dates[0].order.push({
                col: null,
                coords: "",
                optionalsKeys: {},
                optionalsPrices: {},
                packageKey: selectedPackage.id,
                price: prod.price,
                quantity: prod.quantity,
                row: null,
                slot_key: null,
                type: "order",
                zoneKey: selectedZone.id
              });

              this.reservation.dates[0].intervalAmount += prod.price;
              selectedIndex++;
            } else if (selectedOptional) {

              prod.type = 'optional';
              prod.optionalId = selectedOptional.id;
              prod.zoneId = selectedZone.id;

              this.reservation.dates[0].order[selectedIndex].optionalsKeys[selectedOptional.id] = selectedOptional.quantity;
              this.reservation.dates[0].order[selectedIndex].optionalsPrices[selectedOptional.id] = selectedOptional.price;
              this.reservation.dates[0].optionalsAmount += prod.price;
              this.reservation.dates[0].optionalsCounter += prod.quantity;
            }
          }

          let httpCall = await this.dataService.updateReservation(this.reservation).catch(err =>{
            return err;
          });

        } else {
          for (const interval of this.reservation.dates) {
            for (const order of interval.order) {
              const selectedPackage = this.reservation.order.products.find(pack => pack.packageId === order.packageKey);
              if (selectedPackage) {
                order.quantity = selectedPackage.quantity;
              }

              for (const optionalId in order.optionalsKeys) {
                if (order.optionalsKeys[optionalId] && this.optionalsByKeys[optionalId]) {
                  order.optionalsKeys[this.optionalsByKeys[optionalId].id] = order.optionalsKeys[optionalId];
                  delete order.optionalsKeys[optionalId];
                }
              }
            }
          }
        }
      }


      let name = '';
      let surname = '';

      if (this.reservation.surname.trim() === '') {
        name = this.reservation.name.trim().split(' ')[0];
        if (this.reservation.name.trim().split(' ')[1]) {
          for (let i=1; i < this.reservation.name.trim().split(' ').length; i++) {
            surname += ' ' + this.reservation.name.trim().split(' ')[i];
          }
        }

        this.name = name + surname;
      } else if (this.reservation.surname.trim() !== '') {
        this.name = this.reservation.name.trim() + ' ' + this.reservation.surname.trim();
      } else {
        this.name = this.reservation.name.trim();
      }

      this.setupDates();
      this.updateStartEndDate();
      this.settled = this.reservation.order.settled;
      for (const bill of this.reservation.order.customerBill) {
        if (bill.type === 'settlement') {
          this.paymentType = bill.paymentType;
        }
      }

      this.lightLoading = false;
      for (const extra of this.reservation.extras) {
        const extraFound = this.resortData.extras.find(ext => ext.id === extra.id);
        if (extraFound) {
          extraFound.quantity = extra.quantity;
        }
      }

      for (const discount of this.reservation.discounts) {
        const discountFound = this.resortData.discounts.find(disc => disc.id === discount.id);
        if (discountFound) {
          discountFound.checked = true;
        }
      }


    }  else {
      this.router.navigate(['/booking/home']);
    }


  }

  async removeReservation() {

      const dialogRef = this.dialog.open(ModalRemoveComponent, {
        width: '340px',
        height: '130px',
        data: {
          type: 'reservations',
          reservation: this.reservation
        }
      });

      dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.clicked = false;
      });
  }

  setupDates() {

    for (const interval of this.reservation.dates) {
      interval.startDate = new Date(interval.start * 1000);
      interval.endDate = new Date(interval.end * 1000);
    }


  }

  updateStartEndDate() {
    this.reservation.start = null;

    for (const interval of this.reservation.dates) {

      if (!this.reservation.start) {
        this.reservation.start = interval.start;
        this.reservation.end = interval.end;
      }

      if (interval.start < this.reservation.start) {
        this.reservation.start = interval.start;
      }

      if (interval.end > this.reservation.end) {
        this.reservation.end = interval.end;
      }
    }
  }

  async refundReservation() {
    const dialogRef = this.dialog.open(ModalRefundComponent, {
      width: '340px',
      disableClose: true,
      data: {
        reservation: this.reservation
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.reservation.refundState = result.refundState;
        this.reservation.refundAmount = result.refundAmount;
        this.reservation.refundExpiration = result.refundExpiration;
      }
    });
  }

  async checkIn() {

      try {

      this.lightLoading = true;

      const today = (new Date()).setUTCHours(0, 0, 0, 0) / 1000;

      if (this.reservation.checkOutDates.indexOf(today) == -1) {
        this.reservation.checkInDates.push(today);
      }

      this.reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
      this.reservation.updatedBy = this.settingsService.getCurrentUserData();

      const httpCall = await this.dataService.updateReservation(this.reservation).catch(err =>{
        return err;
      });

      this.lightLoading = false;
      this.dataService.selectedReservation = null;
      this.dataService.associatedCustomers = null;
      this.dataService.mainCustomer = null;

      console.log(httpCall);
      console.log(this.dataService.previousPage);

      if (httpCall.error) {

        let error = httpCall.error;

        if (httpCall.error.error) {
          error = httpCall.error.error;
        }

        const dialogRef = this.dialog.open(ModalErrorComponent, {
          width: '400px',
          data: {
            type: 'reservation-error',
            error
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!this.dataService.previousPage ) {
            this.router.navigate(['/booking/home']);
          } else {
            this.router.navigate(['/booking/' + this.dataService.previousPage]);
          }
        });
      } else {

        if (httpCall) {
          this.reservation.id = httpCall.id;
          this.reservation.reservationCode = httpCall.reservationCode;
        }

        if (!this.dataService.previousPage ) {
          this.router.navigate(['/booking/home']);
        } else {
          this.router.navigate(['/booking/' + this.dataService.previousPage]);
        }
      }


    } catch(err) {
      console.log(err);
    }
  }


}
