import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../../core/providers/data.service';
import { MatDatepickerInputEvent, MatCalendarCellCssClasses } from '@angular/material/datepicker';

@Component({
  selector: 'app-modal-dates',
  templateUrl: './modal-dates.component.html',
  styleUrls: ['./modal-dates.component.scss']
})
export class ModalDatesComponent implements OnInit {

  interval; selectedDates=[]; calendarFlag=true; initialDates=[]; dates = []; start; weekendDays = []; weekDays = [];
  step = 1; noWeekDays; noWeekEndDays; type = 'absence'; slot; freeSlotNotes= ''; datesToRemove = []; clicked=false;

  constructor(public router: Router, private dataService: DataService, public dialogRef: MatDialogRef<ModalDatesComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.interval = data.interval;
      this.noWeekEndDays = false;
      this.noWeekDays = false;
      this.type = data.type;

      this.slot = data.slot;

      for (let i=0; i < this.interval.length; i++ ) {
        const ts = this.interval.start + i*24*60*60;
        this.selectedDates.push(ts*1000);
        this.initialDates.push(ts*1000);

        let day = new Date(ts*1000).getDay();

        if (day === 0 || day === 6) {
          this.weekendDays.push(ts*1000)
        } else {
          this.weekDays.push(ts*1000);
        }
      }

      if (this.weekendDays.length === 0) {
        this.noWeekEndDays = true;
      }

      if (this.weekDays.length === 0) {
        this.noWeekDays = true;
      }

      if (this.type == 'free' && this.slot.free) {

        for (let interval of this.slot.free) {
          for (let ts = interval.start; ts <= interval.end; ts += 24*60*60) {
            if (this.selectedDates.indexOf(ts*1000) > -1) {
              this.selectedDates.splice(this.selectedDates.indexOf(ts*1000),1);
            }

            if (this.initialDates.indexOf(ts*1000) > -1) {
              this.initialDates.splice(this.initialDates.indexOf(ts*1000),1);
            }
          }
        }
      }

      this.start = new Date(this.interval.start*1000);
  }

  ngOnInit() {
  }

  close(confirm): void {
    if (confirm) {

      if (this.type === 'absence') {
        for (const date of this.selectedDates) {
          this.dates.push(new Date(date))
        }

        this.dialogRef.close({
          dates:this.selectedDates
        });
      } else {
        this.dialogRef.close({
          dates: this.datesToRemove,
          notes: this.freeSlotNotes
        })
      }
    } else {
      this.dialogRef.close(null);
    }

  }

  onSelect(evt) {

    this.calendarFlag = false;

    const ts = new Date(evt).getTime() - new Date(evt).getTimezoneOffset()*60*1000;
    const removeDateIndex = this.selectedDates.indexOf(ts);

    if (removeDateIndex > -1) {
      this.selectedDates.splice(removeDateIndex, 1);
    } else if (this.initialDates.indexOf(ts) > -1) {
      this.selectedDates.push(ts);
    }

    if (this.initialDates.indexOf(ts) > -1) {
      if (this.datesToRemove.indexOf(ts) > -1) {
        this.datesToRemove.splice(this.datesToRemove.indexOf(ts),1);
      } else {
        this.datesToRemove.push(ts);
      }
    }

    setTimeout(()=>{
      this.start = new Date(ts);
      this.calendarFlag = true;
    },10)
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {

      const ts = date.getTime() - date.getTimezoneOffset()*60*1000;

      if (this.selectedDates.indexOf(ts) > -1) {
        return 'special-date';
      } else if (this.initialDates.indexOf(ts) > -1) {
        return 'blue-date';
      } else {
        return 'disable-date';
      }
    };
  }

  freeWeekDays()  {
    const dates = [];

    for (const date of this.initialDates) {
      if (this.weekDays.indexOf(date) === -1) {
        dates.push(new Date(date));
      }
    }

    this.dialogRef.close({dates:dates});
  }

  freeWeekEndDays() {
    const dates = [];

    for (const date of this.initialDates) {
      if (this.weekendDays.indexOf(date) === -1) {
        dates.push(new Date(date));
      }
    }

    this.dialogRef.close({dates:dates});
  }

}
