import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DataService } from 'src/app/core/providers/data.service';

@Component({
  selector: 'app-quotation-confirmed',
  templateUrl: './quotation-confirmed.component.html',
  styleUrls: ['./quotation-confirmed.component.scss']
})
export class QuotationConfirmedComponent implements OnInit {


  public resortData:any;
  public licenseData: any;
  public licenseId:any;
  public resortId:any;
  public loading:boolean = false;
  public error:string | null = null;

  constructor(private route: ActivatedRoute, private router: Router, private dataService:DataService) { }

  async ngOnInit() {
    this.loading = true;
    this.licenseId = this.route.snapshot.paramMap.get('licenseId');
    this.resortId = this.route.snapshot.paramMap.get('resortId');
    this.licenseData= await this.dataService.getSingleSubscription(this.licenseId);
    console.log(this.licenseData);
    if (this.licenseData) {
      await this.dataService.confirmLicensePayment(this.licenseData.id);
    } else {
      this.error = 'La licenza cercata non esiste';
    }

    this.loading = false;

   /*  API CONFERMA PAGAMENTO LICENZA DI RINNOVO


    PUT https://api.cocomanager.com/v1/users_subscriptions_confirm_payment/<int:pk>/
    Authorization: <firebase_token>

    Content-Type: application/json */


  }

}
