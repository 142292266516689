import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service'
import { SettingsService } from '../../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatChipInputEvent } from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ENTER, COMMA } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-coupon-detail',
  templateUrl: './coupon-detail.component.html',
  styleUrls: ['./coupon-detail.component.scss']
})
export class CouponDetailComponent implements OnInit {


  resortData; managerData; pageClicked; prices; couponId; lang = 'it';
  selectedCoupon; optionals; dataLang='it'; rule1=0; elements; features;services;
  objectKeys = Object.keys;startSerializedDate;endSerializedDate;
  startTimestamp;endTimestamp;disableDates=[];removable = true; addOnBlur = true;
  separatorKeysCodes = [ENTER, COMMA];

  constructor(public router: Router, public dialog: MatDialog,private route: ActivatedRoute, private dataService: DataService, private settingsService: SettingsService) {
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.dataLang = this.dataService.lang;
    if (this.resortData) {
      this.initCoupon();
    }
  }

  async ngOnInit() {

    if (!this.resortData) {
      console.log("LOAD DATA")
      await this.dataService.initResortData();
    }

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;

    this.initCoupon();
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    console.log(this.startSerializedDate);
    if (this.startSerializedDate > this.endSerializedDate) {
      this.endSerializedDate = this.startSerializedDate;
    }

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    if (this.endSerializedDate < this.startSerializedDate) {
      this.startSerializedDate = this.endSerializedDate;
    }

  }

  resetHeader() {

  }

  initCoupon() {
    this.couponId = this.route.snapshot.paramMap.get('couponId');
    const foundCoupon = this.dataService.coupons.find(coup => coup.id == this.couponId);

    if (foundCoupon) {
      this.selectedCoupon = JSON.parse(JSON.stringify(foundCoupon));
      this.startTimestamp = this.selectedCoupon.startDate * 1000;
      this.startSerializedDate = new Date(this.startTimestamp);
      this.endTimestamp = this.selectedCoupon.endDate * 1000;
      this.endSerializedDate = new Date(this.endTimestamp);

      if (!this.selectedCoupon.weeklyActivation) {
        this.selectedCoupon.weeklyActivation = {
          mo: true,
          tu: true,
          we: true,
          th: true,
          fr: true,
          sa: true,
          su: true,
        }

        this.selectedCoupon.max = 0;
        this.selectedCoupon.emails = [];
      }

    } else {

      const offsetStart = new Date().getTimezoneOffset() * 60000;
      const offsetEnd = new Date().getTimezoneOffset() * 60000;

      const endDate = ((new Date()).setHours(0, 0, 0, 0) - offsetEnd )/1000 ;
      const startDate = ((new Date()).setHours(0, 0, 0, 0) - offsetStart )/1000 ;

      this.startSerializedDate = new Date(startDate * 1000);
      this.endSerializedDate = new Date(endDate * 1000);

      this.couponId = null;
      this.selectedCoupon = {
        name: '',
        id: null,
        start: startDate,
        end: endDate,
        code: '',
        emails: [],
        max: 0,
        weeklyActivation: {
          mo: true,
          tu: true,
          we: true,
          th: true,
          fr: true,
          sa: true,
          su: true,
        },
        active: false,
        percentage: true,
        amount: 0
      }

    }

    console.log(this.selectedCoupon);
  }

  async saveCoupon() {

    const offsetStart = new Date(this.startSerializedDate).getTimezoneOffset() * 60000;
    const offsetEnd = new Date(this.endSerializedDate).getTimezoneOffset() * 60000;
    this.selectedCoupon.endDate = ((new Date(this.endSerializedDate)).setHours(0, 0, 0) - offsetEnd ) / 1000;
    this.selectedCoupon.startDate = ((new Date(this.startSerializedDate)).setHours(0, 0, 0) - offsetStart ) / 1000;

    if (this.selectedCoupon.max === null) {
      this.selectedCoupon.max = 0;
    }

    console.log(this.selectedCoupon);
    if (this.selectedCoupon.id) {
      let foundIndex = this.dataService.coupons.findIndex(disc => disc.id === this.selectedCoupon.id);
      this.dataService.coupons[foundIndex] = this.selectedCoupon;
      await this.dataService.updateCoupon(this.selectedCoupon);
    } else {
      const newCoupon = await this.dataService.createCoupon(this.selectedCoupon);
      this.dataService.coupons.push(newCoupon);
    }

    this.router.navigate(['settings/offer/cocobuk/3']);
  }

  removeCoupon() {
    const dialogRef = this.dialog.open(ModalRemoveComponent, {
      width: '340px',
      height: '130px',
      data: {
        type: "coupons",
        coupon: this.selectedCoupon
      }
    });

    dialogRef.afterClosed().subscribe(result => {
          console.log(result);
    });
  }

  addEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!this.selectedCoupon.emails) {
      this.selectedCoupon.emails = [];
    }

    // Add our associated name
    if ((value || '').trim()) {
      this.selectedCoupon.emails.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeEmail(name: any): void {
    const index = this.selectedCoupon.emails.indexOf(name);

    if (index >= 0) {
      this.selectedCoupon.emails.splice(index, 1);
    }
  }


  checkMax() {
    console.log(this.selectedCoupon);
  }


}
