import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/providers/data.service';
import { AuthService } from 'src/app/core/providers/auth.service';
import { SettingsService } from 'src/app/core/providers/settings.service';

@Component({
  selector: 'app-modal-license-renewal',
  templateUrl: './modal-license-renewal.component.html',
  styleUrls: ['./modal-license-renewal.component.scss']
})
export class ModalLicenseRenewalComponent implements OnInit {

  step = 1;
  dateString = '';
  licenseData;
  totalAmount = 0;

  constructor(public dialogRef: MatDialogRef<ModalLicenseRenewalComponent>, public dataService: DataService, public authService: AuthService, public settingsService: SettingsService, private router: Router, @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    console.log(this.authService.subscriptions)
    let newLicenseData = this.authService.subscriptions[0];
    this.licenseData = await this.dataService.getSingleSubscription(newLicenseData.id);
    for (let item of this.licenseData.movements) {
      if (!item.paid) {
        this.totalAmount +=item.amount;
      }
    }
    console.log(this.licenseData);
    let timestamp = this.authService.subscription.end;
    let date = new Date(timestamp*1000);

    let dayStart = date.getDate().toString();
    let monthStart = (date.getMonth() + 1).toString();

    if (date.getDate() < 10) {
      dayStart = '0' + dayStart;
    }

    if ((date.getMonth() + 1) < 10) {
      monthStart = '0' + monthStart;
    }
    this.dateString = dayStart + ' ' + this.settingsService.months['it'][(date.getMonth())] + ' ' +date.getFullYear();
    console.log(this.data);
  }

  close(): void {
    this.dialogRef.close();
  }

  quotation() {
    this.close();
    this.router.navigate([+this.dataService.resortData.id+'/license/'+this.data.nextNotPaidSubscription.id+'/quotation/'])
  }

}
