import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-settings-cocobook',
  templateUrl: './settings-cocobook.component.html',
  styleUrls: ['./settings-cocobook.component.scss']
})
export class SettingsCocobookComponent implements OnInit {

  resortData;managerData;pageClicked;prices;submenu;lang = 'it';coupons;bookingIntervals; offers; reviews;integerRating;
  showRatingDetail = false; showLanguageModal1=false;  showLanguageModal2=false; loader; seasonalBookingDate;
  seasonalInterval;
  reviewsByPoints = {
    5: [],
    4: [],
    3: [],
    2: [],
    1: [],
    0: [],
  };
  showResponse = [];

  serializedDates = [];
  ratingValues = {
    5: {
      it: 'Eccellente',
      en: 'Excellent'
    },
    4: {
      it: 'Molto buono',
      en: 'Very good'
    },
    3:{
      it: 'Buono',
      en: 'Good'
    },
    2: {
      it: 'Discreto',
      en: 'Decent'
    },
    1:{
      it: 'Sufficiente',
      en: 'Enough'
    }
  }

  objectKeys = Object.keys;

  constructor(public snackBar: MatSnackBar, private route: ActivatedRoute, public dataService: DataService, private settingsService: SettingsService) {
    this.submenu = 0;
    this.route.params.subscribe(params =>{
      if (params['menuIndex']) {
        this.submenu = params['menuIndex'];
      }
    });
    //bookingSeasonalDateLimit
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.prices = this.dataService.prices;
    this.coupons = this.dataService.coupons;
  }

  async ngOnInit() {
    this.loader = true;
    if (!this.resortData) {
      await this.dataService.initResortData();
    }
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    console.log(this.resortData.averageRating);
    this.integerRating = Math.ceil(this.resortData.averageRating);
    console.log(this.integerRating);
    this.offers = await this.dataService.getOffers();
    this.reviews = await this.dataService.getReviews();
    console.log(this.reviews);

    for (const review of this.reviews) {
      const index =  Math.ceil(review.rating);
      this.reviewsByPoints[index].push(review);
      this.showResponse.push(false);
    }

    this.reviews.sort( (a, b) => {
        const keyA = a.createdAt;
        const keyB = b.createdAt;
        // Compare the 2 dates
        if ( keyA > keyB )  {
          return -1;
        }

        if ( keyA < keyB ) {
          return 1;
        }

        return 0;
    });


    let bookingIntervals = await this.dataService.getBookingIntervals();

    console.log("INTERVALS",bookingIntervals);
    this.bookingIntervals = bookingIntervals.filter(b => b.reservationType !== 'seasonal');
    let seasonalInterval =  bookingIntervals.filter(b => b.reservationType === 'seasonal');

    if (seasonalInterval.length === 0) {
      this.seasonalInterval = null;
    } else {
      this.seasonalInterval = seasonalInterval[0];
    }

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.prices =  this.dataService.prices;
    this.coupons = this.dataService.coupons;
    if (this.resortData.bookingSeasonalDateLimit) {
      this.seasonalBookingDate = new Date(this.resortData.bookingSeasonalDateLimit*1000);
    }
    this.loader = false;

    for (const ts of this.resortData.refundSettings.daysDisabled) {

      this.serializedDates.push(new Date(ts * 1000));
    }
  }

  async changeSeasonalBookingDate(type: string, event: MatDatepickerInputEvent<Date>) {



  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout(()=>{
      this.pageClicked = false;
    },1000)
  }

  async saveResort() {

    try {

      const offset = new Date(this.seasonalBookingDate).getTimezoneOffset() * 60000;
      this.resortData.bookingSeasonalDateLimit = ( (new Date(this.seasonalBookingDate)).setHours(0, 0, 0) - offset ) / 1000;
      await this.dataService.setResort(this.resortData);
      this.openSnackBar('INFORMAZIONI SALVATE CORRETTAMENTE', 'OK');
    } catch (err) {
      console.log(err);
    }
  }

  addRefundDate() {
    this.resortData.refundSettings.daysDisabled.push(1577836800);
    this.serializedDates.push(new Date(1577836800 * 1000));
  }

  removeDate(index) {
    this.resortData.refundSettings.daysDisabled.splice(index,1);
    this.serializedDates.splice(index,1);
  }

  changeRefundDate(index) {
    console.log(this.serializedDates[index])
    const offset= new Date(this.serializedDates[index]).getTimezoneOffset() * 60000;
    this.resortData.refundSettings.daysDisabled[index] = ((new Date(this.serializedDates[index])).setHours(0, 0, 0) - offset ) / 1000;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }


  async removeInterval(index) {
    await this.dataService.deleteBookingInterval(this.bookingIntervals[index]);
    this.bookingIntervals.splice(index,1);
  }

  async removeSeasonalInterval() {
    await this.dataService.deleteBookingInterval(this.seasonalInterval);
    this.seasonalInterval = null;
  }

  setReply(review) {
    review.reply = {
      createdAt: 0,
      text: '',
    }

    console.log(review);
  }

  async publishReview(review) {
    console.log(review);
    review.reply.createdAt = Math.round((new Date()).getTime() / 1000);
    review.reply.createdBy = this.settingsService.getCurrentUserData();
    let obj = {
      id: review.id,
      reply: review.reply
    }

    await this.dataService.replyReview(obj);
  }

}
