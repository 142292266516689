import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthguardService } from '../core/providers/authguard.service';
import { ReservationDetailComponent as BookingReservationDetailComponent} from './pages/booking_reservation-detail/reservation-detail.component';
import { PaymentsRecapComponent } from './pages/payments-recap/payments-recap.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { ReservationDetailComponent } from './pages/reservation-detail/reservation-detail.component';
import { ReservationsComponent } from './pages/reservations/reservations.component';
import { TransfersComponent } from './pages/transfers/transfers.component';
import { MapComponent } from './pages/map/map.component';
import { QuotationLicenseComponent } from './pages/quotation-license/quotation-license.component';
import { AuthguardLicenseService } from '../core/providers/authguard-license.service';
import { QuotationConfirmedComponent } from './pages/quotation-confirmed/quotation-confirmed.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';

const routes: Routes = [

  { path: 'booking/reservation/:id', component: BookingReservationDetailComponent, canActivate: [AuthguardService] },

  { path: 'common/transfers', component: TransfersComponent , canActivate: [AuthguardService]},
  { path: 'common/invoices', component: InvoicesComponent , canActivate: [AuthguardService]},

  // SUITE
  { path: 'suite/payments', component: PaymentsRecapComponent , canActivate: [AuthguardService]},
  { path: 'suite/payments-details', component: PaymentsComponent , canActivate: [AuthguardService]},

  // SUITE RESERVATION
  { path: 'suite/reservations', component: ReservationsComponent , canActivate: [AuthguardService]},
  { path: 'suite/reservation/:id', component: ReservationDetailComponent , canActivate: [AuthguardService]},
  { path: 'suite/reservation/:id/map', component: MapComponent , canActivate: [AuthguardService]},
  { path: 'suite/reservation/:id/map/:start/:end/:mode/:slotOrPackageId', component: MapComponent , canActivate: [AuthguardService]},


  { path: 'license/quotation', component: QuotationLicenseComponent , canActivate: [AuthguardService]},
  { path: ':resortId/license/:licenseId/quotation', component: QuotationLicenseComponent, canActivate: [AuthguardLicenseService] },
  { path: ':resortId/license/:licenseId/quotation/:error', component: QuotationLicenseComponent, canActivate: [AuthguardLicenseService] },
  { path: ':resortId/license/:licenseId/success', component: QuotationConfirmedComponent, canActivate: [AuthguardLicenseService] },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReservationRoutingModule {}
