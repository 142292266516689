import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-checkout',
  templateUrl: './modal-checkout.component.html',
  styleUrls: ['./modal-checkout.component.scss']
})
export class ModalCheckoutComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalCheckoutComponent>) { }

  ngOnInit() {
  }

  close(checkout): void {
    this.dialogRef.close(checkout);
  }
}
