import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from '../../../core/providers/data.service';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';


@Component({
  selector: 'app-dialog-customers',
  templateUrl: './dialog-customers.component.html',
  styleUrls: ['./dialog-customers.component.scss']
})
export class DialogCustomersComponent implements OnInit {

  resortData; pageSize= 25; page=1; customers; loader=false; totalCustomers=0;
  term$ = new Subject<string>(); searchString = null; selectedType = null; selectedPayment = null;

  constructor(public dialogRef: MatDialogRef<DialogCustomersComponent>, public dataService: DataService) {
    this.resortData = this.dataService.resortData;
    this.term$
    .debounceTime(1000)
    .distinctUntilChanged()
    .subscribe(async term => {
      this.searchString = term;

      if (term === '') {
        this.searchString = null;
      }


      await this.getCustomers();

    });

  }

  async getCustomers() {
    this.loader = true;
    const data = await this.dataService.getCustomers( this.searchString, this.selectedType, this.pageSize, this.page, false);
    /* this.nextPage = data.next;
    this.prevPage = data.previous; */

    for (const customer of data.results) {
      if (!customer.totalPayed) {
        customer.totalPayed = 0;
      }

      if (!customer.totalPayedFalse) {
        customer.totalPayedFalse = 0;
      }

      customer.totalNotPayed = - (customer.totalPayed - customer.totalPayedFalse);
    }
    this.customers = data.results;
    this.totalCustomers = data.count;
    this.loader = false;
  }

  async ngOnInit() {
    this.pageSize = 10;
    await this.getCustomers();
    this.pageSize = 25;
  }

  close(): void {
    this.dialogRef.close();
  }

  selectCustomer(customer) {
    this.dialogRef.close(customer);
  }

}
