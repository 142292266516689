import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service';
import { SettingsService } from '../../../../core/providers/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';
import { ModalErrorComponent } from '../../../../shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-season-detail',
  templateUrl: './season-detail.component.html',
  styleUrls: ['./season-detail.component.scss']
})
export class SeasonDetailComponent implements OnInit {

  resortData; managerData; pageClicked; prices; seasonId; lang = 'it';
  selectedSeason; optionals; dataLang = 'it'; rule1 = 0; elements; features; services;
  objectKeys = Object.keys; startSerializedDate; endSerializedDate;  clicked = false;
  startTimestamp; endTimestamp; disableDates = []; showLanguageModal2=false;showLanguageModal1=false; showTip = false; showTipRemove = false;
  timeSlots:boolean=false; timeslotsRepo=[]; seasonTimeslots = []; availableTimeslots=[];

  constructor(public snackBar: MatSnackBar, public router: Router, public dialog: MatDialog, private route: ActivatedRoute, private dataService: DataService, public settingsService: SettingsService) {
    /* this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData; */

    /* console.log(this.timeslotsRepo);
    if (this.resortData) {
      this.initSeason();
    } */
  }

  async ngOnInit() {
    this.dataLang = this.dataService.lang;
    this.timeslotsRepo = this.settingsService.timeslots;
    if (!this.dataService.resortData) {
      await this.dataService.initResortData();
    }

    this.seasonId = this.route.snapshot.paramMap.get('seasonId');
    this.resortData = this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.prices =  this.dataService.prices;
    this.optionals =  this.dataService.optionals;
    this.elements =  this.dataService.elements;
    this.features =  this.dataService.features;
    this.services =  this.dataService.packagesServices;
    if (this.seasonId && this.seasonId !== 'new') {
      //await this.dataService.getResortData();
      this.seasonTimeslots = await this.dataService.getTimeSlots(this.resortData.id, this.seasonId);
    }
    this.initSeason();
    console.log(this.selectedSeason);
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    if (this.startSerializedDate > this.endSerializedDate) {
      this.endSerializedDate = this.startSerializedDate;
    }

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    if (this.endSerializedDate < this.startSerializedDate) {
      this.startSerializedDate = this.endSerializedDate;
    }

  }

  resetHeader() {

  }

  pushTimeslot() {
    let timeslot = {
      "endHour": "13:00",
      "name": {
          "de": "",
          "en": "",
          "fr": "",
          "it": ""
      },
      "startHour": "10:00"
    }

    this.seasonTimeslots.push(timeslot);
    this.clicked = false;
    console.log(this.seasonTimeslots)
  }

  initSeason() {

    if (this.resortData.seasonsByIds[this.seasonId]) {
      this.selectedSeason = JSON.parse(JSON.stringify(this.resortData.seasonsByIds[this.seasonId]));
     
      this.startTimestamp = this.selectedSeason.start * 1000;
      this.startSerializedDate = new Date(this.startTimestamp);
      this.endTimestamp = this.selectedSeason.end * 1000;
      this.endSerializedDate = new Date(this.endTimestamp);

      if (!this.selectedSeason.packageType) {
        this.selectedSeason.packageType = 'package';
      }

    } else {
      this.seasonId = null;

      /* const offsetStart = new Date().getTimezoneOffset() * 60000;
      const offsetEnd = new Date().getTimezoneOffset() * 60000; */

      let endDate =  new Date(new Date().getFullYear(), 0, 1).getTime();
      let startDate = new Date(new Date().getFullYear(), 0, 1).getTime() ;
      const offsetStart = new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset() * 60000;
      const offsetEnd = new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset() * 60000;
      startDate = (startDate - offsetStart ) / 1000;
      endDate = (endDate - offsetEnd ) / 1000;

      this.startSerializedDate = new Date(startDate * 1000);
      this.endSerializedDate = new Date(endDate * 1000);

      this.selectedSeason = {
        name: JSON.parse(JSON.stringify(this.dataService.languages)),
        id: null,
        start: startDate,
        end: endDate,
        special: false,
        packageType: 'package'
      };

    }

    if (!this.selectedSeason.special) {
      this.resortData.seasonsPackages.map(season => {
        if (season.id !== this.selectedSeason.id && !season.special) {

          for (let i = season.start; i <= season.end; i += 24 * 60 * 60) {
              this.disableDates.push(i);
          }
        }

      });
    }

    if (!this.selectedSeason.timeSlotsConfig) {
      this.selectedSeason.timeSlotsConfig= {
        "enabled": false,
        "weeklyActivation": {
            "fr": true,
            "mo": true,
            "sa": true,
            "su": true,
            "th": true,
            "tu": true,
            "we": true
        }
      }
    }
    this.availableTimeslots = this.settingsService.getAvailableTimes(this.seasonTimeslots)
    //this.timeslotsRepo = availableTimeslots;
    console.log(JSON.parse(JSON.stringify(this.selectedSeason)));
    console.log(this.availableTimeslots)
  }

  checkSeasonDates() {
    const offsetStart = new Date(this.startSerializedDate).getTimezoneOffset() * 60000;
    const offsetEnd = new Date(this.endSerializedDate).getTimezoneOffset() * 60000;
    this.selectedSeason.start = ((new Date(this.startSerializedDate)).setHours(0, 0, 0) - offsetStart ) / 1000;
    this.selectedSeason.end = ((new Date(this.endSerializedDate)).setHours(0, 0, 0) - offsetEnd ) / 1000;
  }

  async saveSeasonOnDb() {
    if (this.selectedSeason.id) {
      this.resortData.seasonsByIds[this.seasonId] = this.selectedSeason;
      const foundIndex = this.resortData.seasons.findIndex(disc => disc.id === this.selectedSeason.id);
      this.resortData.seasons[foundIndex] = this.selectedSeason;
      await this.dataService.updateSeason(this.selectedSeason);
    } else {
      await this.dataService.createSeason(this.selectedSeason);
    }
  }

  async saveSeason() {
    try {
      this.checkSeasonDates();
      console.log(this.selectedSeason.special);
      for (const season of this.resortData.seasonsPackages) {
        if (season.special && this.selectedSeason.special && season.id !== this.selectedSeason.id && season.start === this.selectedSeason.start && season.end === this.selectedSeason.end) {
          throw('Date sovrapposte ad altra stagione. Impossibile salvare');
        } 

        if (!season.special && !this.selectedSeason.special && season.id !== this.selectedSeason.id && (season.start === this.selectedSeason.start || season.end === this.selectedSeason.end)) {
          throw('Date sovrapposte ad altra stagione. Impossibile salvare');
        }

        let crossIntervals = ((this.selectedSeason.start <= season.start && this.selectedSeason.end >= season.end) || (this.selectedSeason.start <= season.start && this.selectedSeason.end >= season.start) || (this.selectedSeason.start <= season.end && this.selectedSeason.end >= season.end) || (this.selectedSeason.start >= season.start && this.selectedSeason.end <= season.end));

        console.log(crossIntervals);

        if (!season.special && !this.selectedSeason.special && season.id !== this.selectedSeason.id && crossIntervals) {
          throw('Date sovrapposte ad altra stagione. Impossibile salvare');
        }
      }

      console.log(this.selectedSeason);

      await this.saveSeasonOnDb();

      if (this.seasonTimeslots && this.seasonTimeslots.length && this.selectedSeason.timeSlotsConfig.enabled) {
        console.log(this.seasonTimeslots);
        let foundOverlappedTimeslots = false;
        let pushedIntervals = [];
        for (let timeslot of this.seasonTimeslots) {
          let startTS = parseInt(timeslot.startHour.split(":")[0])*60 + parseInt(timeslot.startHour.split(":")[1]);
          let endTS = parseInt(timeslot.endHour.split(":")[0])*60 + parseInt(timeslot.endHour.split(":")[1]);
          let findInterval = pushedIntervals.find(i => (i.start < startTS && i.end > startTS) || (i.start > startTS && i.start < endTS) || (i.start >= startTS && i.end <= endTS));

          if (findInterval || timeslot.name[this.dataLang] === '') {
            foundOverlappedTimeslots = true;
            break;
          } else {
            pushedIntervals.push({
              start: startTS,
              end: endTS
            })
          }
        }

        if (!foundOverlappedTimeslots) {
          for (let timeslot of this.seasonTimeslots) {
            await this.saveTimeslot(timeslot);
          }
          this.router.navigate(['settings/offer/2']);
        } else {
          let dialogRef = this.dialog.open(ModalErrorComponent, {
            width: '400px',
            data: {
              error: "Fasce orarie sovrapposte o senza nome, non è possibile aggiornare"
            }
          });
          this.clicked = false;
        }
      } else {
        this.router.navigate(['settings/offer/2']);
      }

    } catch(err) {
      let dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          error: err
        }
      })

      const self = this;

      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        self.clicked = false;
      });
    }

  }

  async removeTimeslot(index:number, timeslot:any) {
    this.seasonTimeslots.splice(index,1);

    // REMOVE API
    if (timeslot.id) {
      await this.dataService.deleteTimeSlot(this.resortData.id, this.selectedSeason.id, timeslot);
    }

    this.snackBar.open('Fascia oraria eliminata correttamente', 'OK', {
      duration: 4000,
    });
  }

  async saveTimeslot(timeslot) {
    try {

      /* this.checkSeasonDates();
      for (const season of this.resortData.seasonsPackages) {
        if (season.id !== this.selectedSeason.id && (season.start === this.selectedSeason.start || season.end === this.selectedSeason.end)) {
          throw('Date già esistenti');
        }
      } */

      //await this.saveSeasonOnDb();

      // SAVE API

      if (!timeslot.id) {
        let newTimeslot = await this.dataService.pushTimeSlot(this.resortData.id,this.selectedSeason.id,timeslot);
        timeslot.id = newTimeslot.id;
      } else {
        await this.dataService.updateTimeSlot(this.resortData.id,this.selectedSeason.id,timeslot);
      }

      /* this.snackBar.open('Fascia oraria salvata correttamente', 'OK', {
        duration: 4000,
      }); */
    } catch(err) {
      let dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          error: err
        }
      })

      const self = this;

      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        self.clicked = false;
      });
    }
  }

  removeSeason() {
    const dialogRef = this.dialog.open(ModalRemoveComponent, {
      width: '340px',
      height: '130px',
      data: {
        type: 'seasons',
        season: this.selectedSeason
      }
    });

    dialogRef.afterClosed().subscribe(result => {
          console.log(result);
    });
  }

  disableDatesFilter = (d: Date): boolean => {

    const offset = new Date(d).getTimezoneOffset() * 60000;
    const ts = ((new Date(d)).getTime() - offset)  / 1000;
    return (this.disableDates.indexOf(ts) === -1) ;
  }

  changeDates() {
    this.disableDates = [];
    if (!this.selectedSeason.special) {
      this.resortData.seasonsPackages.map(season => {
        if (season.id !== this.selectedSeason.id && !season.special) {
          for (let i = season.start; i <= season.end; i += 24 * 60 * 60) {
              this.disableDates.push(i);
          }
        }
      });
    }
  }

  checkStartTime(start:string, end:string, timeslot:any) {
    let hourStart = parseInt(start.split(":")[0]);
    let minuteStart = parseInt(start.split(":")[1]);
    let hourEnd = parseInt(end.split(":")[0]);
    let minuteEnd = parseInt(end.split(":")[1]);

    if (hourEnd < hourStart) {
      timeslot.endHour = timeslot.startHour;
    }

    if (hourEnd === hourStart) {
      if (minuteEnd < minuteStart) {
        timeslot.endHour = timeslot.startHour;
      }
    }
  }

  checkEndTime(start:string,end:string, timeslot:any) {
    let hourStart = parseInt(start.split(":")[0]);
    let minuteStart = parseInt(start.split(":")[1]);
    let hourEnd = parseInt(end.split(":")[0]);
    let minuteEnd = parseInt(end.split(":")[1]);

    console.log(hourEnd,hourStart,minuteEnd,minuteStart);
    if (hourEnd < hourStart) {
      timeslot.startHour = timeslot.endHour;
    }

    if (hourEnd === hourStart) {
      if (minuteEnd < minuteStart) {
        timeslot.startHour = timeslot.endHour;
      }
    }

  }

  checkStartHourIfNotAvailable(timeslot:any, time:string) {
    let startHour = parseInt(timeslot.startHour.split(":")[0]);
    let startMinutes = parseInt(timeslot.startHour.split(":")[1]);
    let endHour = parseInt(timeslot.endHour.split(":")[0]);
    let endMinutes = parseInt(timeslot.endHour.split(":")[1]);

    let dropboxTimeHour = parseInt(time.split(":")[0])
    let dropboxTimeMinutes = parseInt(time.split(":")[1])

    let isTimeIncludedInTheInterval = ((dropboxTimeHour*60+dropboxTimeMinutes) >= (startHour*60+startMinutes) && (dropboxTimeHour*60+dropboxTimeMinutes) <= (endHour*60 +endMinutes))

    let disabled = false;

    // SE L'ORARIO NELLA DROPBOX E' MAGGIORE DI ENDHOUR ALLORA DISABILITALO
    if ((dropboxTimeHour*60 + dropboxTimeMinutes) > (endHour*60+endMinutes)) {
      return true;
    }

    if (!isTimeIncludedInTheInterval && this.availableTimeslots.indexOf(time) === -1) {
      return true;
    }

    return false;
  }

  checkEndHourIfNotAvailable(timeslot:any, time:string) {
    let startHour = parseInt(timeslot.startHour.split(":")[0]);
    let startMinutes = parseInt(timeslot.startHour.split(":")[1]);
    let endHour = parseInt(timeslot.endHour.split(":")[0]);
    let endMinutes = parseInt(timeslot.endHour.split(":")[1]);

    let dropboxTimeHour = parseInt(time.split(":")[0])
    let dropboxTimeMinutes = parseInt(time.split(":")[1])

    let isTimeIncludedInTheInterval = ((dropboxTimeHour*60+dropboxTimeMinutes) >= (startHour*60+startMinutes) && (dropboxTimeHour*60+dropboxTimeMinutes) <= (endHour*60 +endMinutes))

    let disabled = false;

    // SE L'ORARIO NELLA DROPBOX E' MINORE DI startHour ALLORA DISABILITALO
    if ((dropboxTimeHour*60 + dropboxTimeMinutes) < (startHour*60+endMinutes)) {
      return true;
    }

    if (!isTimeIncludedInTheInterval && this.availableTimeslots.indexOf(time) === -1) {
      return true;
    }

    return false;
  }
}
