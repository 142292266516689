import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DataStatisticsService } from '../../../core/providers/data-statistics.service';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts/highstock';
require('highcharts/modules/exporting')(Highcharts);

@Component({
  selector: 'app-statistics-revenues',
  templateUrl: './statistics-revenues.component.html',
  styleUrls: ['./statistics-revenues.component.scss']
})
export class StatisticsRevenuesComponent implements OnInit {

  resortData; managerData; pageClicked; prices; submenu; lang = 'it';elements; features;optionals;services; dataLang='it'; lightLoading = false;
  loader = false; selectedZone = null;chart; mostSellingPacks; page=1;

  objectKeys = Object.keys;

  // DATE VARIABLES
  startTimestamp; endTimestamp; startSerializedDate; endSerializedDate;
  startDate; startDay; startMonth; startYear;
  endDate; endDay; endMonth; endYear;statistics;
  showTip2 = false; showTip3 = false;


  totalAttendance;averageStay;dayWithMoreAttendance;averageCart;totalRevenue;mostProfitableDay;totalReservations;totalPayments;

  constructor(private statisticsData: DataStatisticsService, public dataService: DataService, private settingsService: SettingsService) {

    /* let currentMonth = new Date().getMonth()+1;
    let currentYear = new Date().getFullYear();
    let endDay = this.settingsService.daysInMonth(currentMonth,currentYear);
    const offsetStart = new Date(currentYear+'-'+currentMonth+'-01').getTimezoneOffset() * 60000;
    const offsetEnd = new Date(currentYear+'-'+currentMonth+'-'+endDay).getTimezoneOffset() * 60000;

    this.startTimestamp = (new Date(currentYear+'-'+currentMonth+'-01').setHours(0, 0, 0, 0) - offsetStart);
    this.endTimestamp = (new Date(currentYear+'-'+currentMonth+'-'+endDay).setHours(0, 0, 0, 0) - offsetEnd); */


    this.resortData = this.dataService.resortData;

  }

  async ngOnInit() {
    this.lightLoading = true;

    if (!this.resortData) {
      await this.dataService.initResortData();
      this.resortData = this.dataService.resortData;
    }

    this.startTimestamp = this.resortData.openingDate * 1000;
    this.endTimestamp = this.resortData.closingDate * 1000;

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate;

    this.dataLang = this.dataService.lang;

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;

    await this.getStatistics();
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout( () => {
      this.pageClicked = false;
    }, 1000);
  }

  exportPDF() {

  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.lightLoading = true;
    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp)
      this.endTimestamp = this.startTimestamp;

    let endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);

    await this.getStatistics();
  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.lightLoading = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp)
      this.startTimestamp = this.endTimestamp;

    let startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    this.getStatistics();
  }

  async getStatistics() {
    this.lightLoading = true;
    this.mostSellingPacks = [];

    let attendances;
    let averageStay;
    let count;
    let bills;
    let revenues;
    let employmentRate;

    [attendances, averageStay, count, bills, revenues, employmentRate] = await Promise.all([this.statisticsData.getAttendance(this.startTimestamp/1000, this.endTimestamp/1000,null,null,null,null,null), this.statisticsData.getAverageStay(this.startTimestamp/1000, this.endTimestamp/1000,null,null,null,null,null), this.statisticsData.getCount(this.startTimestamp/1000, this.endTimestamp/1000,null,null,null,null,null), this.statisticsData.getBill(this.startTimestamp/1000, this.endTimestamp/1000,null,null,null,null,null), this.statisticsData.getRevenues(this.startTimestamp/1000, this.endTimestamp/1000+24*60*60,null,null), this.statisticsData.getEmploymentRate(this.startTimestamp/1000, this.endTimestamp/1000, null, null, null, null, null, null,null)]);

    this.totalAttendance = 0;
    this.dayWithMoreAttendance = 0;

    let max = -1;
    let maxRevenue = -1;

    this.totalReservations = count.total_reservations;
    this.averageStay = averageStay.average_stay;
    this.totalRevenue = bills.sum_bill;
    this.mostProfitableDay = 0;
    this.averageCart = bills.average_bill;

    let totPacks = 0;

    for (const pack in employmentRate.sellingPackages) {
      if (employmentRate.sellingPackages[pack]) {
        totPacks += employmentRate.sellingPackages[pack];
        this.mostSellingPacks.push({
          id: pack,
          total: employmentRate.sellingPackages[pack]
        })
      }
    }

    const data = [];
    let sum = 0;
    for (const dayRevenue in revenues) {

      if (revenues[dayRevenue] !== null) {
        data.push(revenues[dayRevenue]);
        sum+=revenues[dayRevenue];
        if (revenues[dayRevenue] > maxRevenue) {
          this.mostProfitableDay = dayRevenue;
          maxRevenue = revenues[dayRevenue];
        }
      }
    }

    console.log(this.mostProfitableDay);

    this.totalPayments = sum;

    let self = this;

    let plotBands = [];

    for (let i=this.startTimestamp; i<=this.endTimestamp;i+=24*60*60*1000) {
      let day = new Date(i).getDay();
      let year = new Date(i).getFullYear();
      let month = new Date(i).getMonth();
      let date = new Date(i).getDate();
      if (day == 6) {
        plotBands.push({
          color: '#dcdcdc',
          from: Date.UTC(year, month, date),
          to: Date.UTC(year, month, date+1)
        })
      }
    }

    console.log(plotBands);

    this.chart = new Chart({
      chart: {
        type: 'areaspline'
      },
      exporting: {
        enabled: true
      },
      title: {
        text: 'Incassi'
      },
      credits: {
        enabled: false
      },
      xAxis: {
          type: 'datetime',
          min: self.startTimestamp,
          max: self.endTimestamp,
          tickInterval: 24 * 3600 * 1000,
          labels: {
              overflow: 'justify',
              formatter: function() {
                const date = (new Date(this.value)).getDate();
                const month = (new Date(this.value)).getMonth();
                const monthString = self.statisticsData.shortMonths[month]['it']
                const dateString = date + "<br>" + monthString;
                return dateString;

              }
          },
          dateTimeLabelFormats: {
              day: '%e<br>%b'
          },
          plotBands
      },
      yAxis: [
        { // Primary yAxis
            labels: {
                formatter: function() {
                  return '€ ' + this.value
                },
                /* format: '{value:.,0f}', */
                style: {
                    color: '#11A1FF'
                }
            },
            title: {
                text: 'Incassi'
            },
            opposite: false

        }
      ],
      series: [
        {
          type:'areaspline',
          name: 'Incassi',
          data,
          pointInterval:  24 * 3600 * 1000, // one day
          pointStart: self.startTimestamp
        }
      ],
      plotOptions: {
        areaspline: {
            fillOpacity: 0.1,
            marker: {
                enabled: false
            }
        }
      },
      tooltip: {
        formatter: function() {
            let day = new Date(this.x).getDate();
            let month = new Date(this.x).getMonth() +1;

            let dayString = day.toString();
            let monthString = month.toString();

            if (day < 10) {
              dayString = '0'+dayString;
            }

            if (month < 10) {
              monthString = '0'+monthString;
            }

            return dayString+'/'+monthString+'<br>€ <b>' + this.y.toString().replace('.',',') + '</b>';
        }
      }
    });

    this.lightLoading = false;
  }
}
