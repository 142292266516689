import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modal-states',
  templateUrl: './modal-states.component.html',
  styleUrls: ['./modal-states.component.scss']
})
export class ModalStatesComponent implements OnInit {

  reservation; slots=[]; checkInToday = false; checkInAllDates = false; checkOutToday = false; checkOutAllDates = false;
  todayTS;

  constructor(public router: Router, private dataService: DataService, public dialogRef: MatDialogRef<ModalStatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.reservation = data.reservation;

      const todayTS = new Date().setUTCHours(0, 0, 0, 0)/1000;
      console.log(todayTS);
      this.todayTS = todayTS;

      for (const date of this.reservation.dates) {
        for (const slot of date.order) {
          console.log(slot);
          const noFree = (!slot.free || !slot.free.find(ts => ts.start <=todayTS && ts.end >= todayTS));
          const noCheckIn = (!slot.checkin || (slot.checkin && !slot.checkin.find(ts => ts.start <=todayTS && ts.end >= todayTS)));

          if (noCheckIn && noFree ) {
            this.checkInToday = true;
          }

          const notCheckout = (!slot.checkout || !slot.checkout.find(ts => ts.start <=todayTS && ts.end >= todayTS));
          const checkIn = (slot.checkin && slot.checkin.find(ts => ts.start <=todayTS && ts.end >= todayTS));

          if (checkIn && notCheckout ) {
            this.checkOutToday = true;
          }

          if (!this.checkInAllDates || !this.checkOutAllDates) {

            for (let ts = date.start; ts <= date.end; ts+=24*60*60) {
              if (!slot.checkin || (slot.checkin && !slot.checkin.find(ts1 => ts1.start <=ts && ts1.end >= ts) && (!slot.free || !slot.free.find(ts1 => ts1.start <=ts && ts1.end >= todayTS))) ) {
                this.checkInAllDates = true;
              }

              if (slot.checkin && slot.checkin.find(ts1 => ts1.start <=ts && ts1.end >= ts) && (!slot.checkout || !slot.checkout.find(ts1 => ts1.start <=ts && ts1.end >= ts) ) ) {
                this.checkOutAllDates = true;
              }
            }
          }

        }
      }
    }

  ngOnInit() {
  }

  close(confirm): void {
    this.dialogRef.close(confirm);
  }

}
