import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

// ROUTING
import { ReservationRoutingModule } from './reservation-routing.module';

// COMPONENTS
import { ReservationDetailComponent as BookingReservationDetailComponent} from './pages/booking_reservation-detail/reservation-detail.component';
import { PaymentsRecapComponent } from './pages/payments-recap/payments-recap.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { ReservationDetailComponent } from './pages/reservation-detail/reservation-detail.component';
import { ReservationsComponent } from './pages/reservations/reservations.component';
import { MapComponent } from './pages/map/map.component';
import { TransfersComponent } from './pages/transfers/transfers.component';
import { QuotationLicenseComponent } from './pages/quotation-license/quotation-license.component';
import { RecapComponent } from './components/reservation-detail/recap.component';
import { LogsComponent } from './components/reservation-detail/logs.component';
import { TicketsComponent } from './components/reservation-detail/tickets.component';
import { CustomerBillComponent } from './components/reservation-detail/customer-bill.component';
import { ModalCustomerBillComponent } from '../shared/components/modal-customer-bill/modal-customer-bill.component';
import { ModalLogsComponent } from '../shared/components/modal-logs/modal-logs.component';
import { ModalDatesComponent } from '../shared/components/modal-dates/modal-dates.component';
import { CustomerDataComponent } from './components/reservation-detail/customer-data.component';
import { ActionBarComponent } from './components/reservation-detail/action-bar.component';
import { ModalQuotationComponent } from '../shared/components/modal-quotation/modal-quotation.component';
import { IntervalDetailComponent } from './components/reservation-detail/interval-detail.component';
import { QuotationConfirmedComponent } from './pages/quotation-confirmed/quotation-confirmed.component';
import { ModalOrderComponent } from './components/modal-order/modal-order.component';
import { ModalExtraComponent } from './components/modal-extra/modal-extra.component';
import { TypeOrderRowComponent } from './components/type-order-row/type-order-row.component';
import { TypeExtraRowComponent } from './components/type-extra-row/type-extra-row.component';
import { ModalRefundComponent } from '../shared/components/modal-refund/modal-refund.component';
import { DiscountsComponent } from './components/reservation-detail/discounts.component';
import { ExtrasComponent } from './components/reservation-detail/extras.component';
import { CabinsComponent } from './components/reservation-detail/cabins.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';

import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { FreeDatesBoxComponent } from './components/reservation-detail/free-dates-box.component';

@NgModule({
  declarations: [
    BookingReservationDetailComponent,
    PaymentsRecapComponent,
    PaymentsComponent,
    ReservationDetailComponent,
    ReservationsComponent,
    MapComponent,
    TransfersComponent,
    QuotationLicenseComponent,
    RecapComponent,
    LogsComponent,
    TicketsComponent,
    CustomerBillComponent,
    ModalCustomerBillComponent,
    ModalLogsComponent,
    ModalDatesComponent,
    CustomerDataComponent,
    ActionBarComponent,
    ModalQuotationComponent,
    IntervalDetailComponent,
    QuotationConfirmedComponent,
    ModalOrderComponent,
    ModalExtraComponent,
    TypeOrderRowComponent,
    TypeExtraRowComponent,
    ModalRefundComponent,
    DiscountsComponent,
    ExtrasComponent,
    CabinsComponent,
    InvoicesComponent,
    FreeDatesBoxComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReservationRoutingModule,
    NgxQRCodeModule
  ],
  bootstrap: [ModalCustomerBillComponent, ModalLogsComponent, ModalDatesComponent, ModalQuotationComponent, ModalRefundComponent, ModalOrderComponent, ModalExtraComponent]
})
export class ReservationModule { }
