import { Component, OnInit } from '@angular/core';
import { DataService, Customer } from '../../../core/providers/data.service';
import { ReservationService } from '../../../core/providers/reservation.service';
import { SettingsService } from '../../../core/providers/settings.service';
import { Subject } from 'rxjs/Subject';
import { DataStatisticsService } from '../../../core/providers/data-statistics.service';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { MatSnackBar } from '@angular/material';
import { ModalConfirmComponent } from '../../../shared/components/modal-confirm/modal-confirm.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-extra-availabilities',
  templateUrl: './extra-availabilities.component.html',
  styleUrls: ['./extra-availabilities.component.scss']
})
export class ExtraAvailabilitiesComponent implements OnInit {
  resortData; managerData; data; reservations; optionals;optionalsByIds={}; availabilities = [];soldPackages;

  // FILTERS VARIABLES
  term$ = new Subject<string>();
  selectedPackage = null; selectedSettlement = null; selectedPaymentType = null;
  selectedOptionals = null; selectedStatus = null; onlyArrivals = null;
  selectedType = null; selectedToday = null; onlyDepartures = null;
  selectedState = null; selectedNoSlots = null; selectedZone = null;
  selectedFilter = null; searchString = ''; selectedCabins = null; size = null;
  disableSave = false;

  // DATE VARIABLES
  startTimestamp; endTimestamp;
  startDate; startDay; startMonth; startYear;
  endDate; endDay; endMonth; endYear;
  startSerializedDate; endSerializedDate;extras=[];
  selectedTimeslots=[]; allAvailabilities=[];

  // GLOBAL VARIABLES
  loader = true; nextPage; prevPage; totalReservations = 0; pageSize = 25; page = 1;
  objectKeys = Object.keys; lang = 'it'; totalAmount = 0; pageClicked = false;
  overlayLoader = false; percentage = 0;

  constructor(private statisticsData: DataStatisticsService, public dialog: MatDialog, public snackBar: MatSnackBar, public router: Router,private dataService: DataService, private settingsService: SettingsService, private reservationService: ReservationService) {

    // SETUP DATES
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate;

    // SETUP DATA
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.lang = this.dataService.lang;
  }

  async ngOnInit() {

    if (!this.resortData) {
      this.data = await this.dataService.initResortData();
    }

    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.optionals =  this.dataService.optionals;

    console.log(this.dataService.resortData.extras);
    console.log(this.dataService.resortData.seasonsExtras);
    console.log(this.dataService.resortData.timeSlotsBySeason);
    await this.checkAvailabilities();
  }

  async checkAvailabilities() {
    this.loader = true;
    this.extras = [];
    let dbAvailabilities = await this.dataService.getExtraAvailabilitiesV2(this.startTimestamp/1000, this.endTimestamp/1000);

    let availabilitiesByExtra = this.alignAvailabilityData(dbAvailabilities);

    for (const extra of this.dataService.resortData.extras) {
      let objToPush = JSON.parse(JSON.stringify(extra));
      objToPush.timeSlots = [
        {
          timeSlotId: null,
          residual:0
        }
      ];

      if (availabilitiesByExtra[extra.id+'_null']) {
        objToPush.timeSlots[0].residual =availabilitiesByExtra[extra.id+'_null'].min;
        objToPush.timeSlots[0].id =availabilitiesByExtra[extra.id+'_null'].id;
      }

      // PRENDITI SOLO LE STAGIONI CHE RICADONO NEI TIMESTAMPS SELEZIONATI
      // SE SFORA DUE STAGIONI PRENDI SOLO IL GIORNALIERO PER ORA

      let length = (this.endTimestamp/1000 - this.startTimestamp/1000)/(24*60*60) +1;
      let seasons = this.dataService.getSeasonsInReservation(this.startTimestamp/1000, length , extra.id);

      if (Object.keys(seasons).length === 1) {
        for (let seasonKey in seasons) {

          let timeslots = this.dataService.resortData.timeSlotsBySeason[seasonKey];

          if (timeslots && timeslots.length) {
            for (let timeSlot of timeslots) {
              let objToPushTimeslot = {
                timeSlotId: timeSlot.id,
                startHour: timeSlot.startHour,
                endHour: timeSlot.endHour,
                name: timeSlot.name,
                residual: 0,
                id: null,
                selected:false
              }

              if (availabilitiesByExtra[extra.id+'_'+timeSlot.id]) {
                objToPushTimeslot.residual =availabilitiesByExtra[extra.id+'_'+timeSlot.id].min;
                objToPushTimeslot.id =availabilitiesByExtra[extra.id+'_'+timeSlot.id].id;
              }
              objToPush.timeSlots.push(objToPushTimeslot);
            }
          }
        }
      }
      this.extras.push(objToPush);

    }
    console.log(this.extras);
    this.loader = false;

  }

  alignAvailabilityData(dbAvailabilities) {
    let availabilitiesByExtra = {};
    this.allAvailabilities = [];
    this.allAvailabilities = dbAvailabilities.results.filter( a => {
      if (a.day_timestamp <= this.endTimestamp/1000 && a.day_timestamp >= this.startTimestamp/1000) return a;
    })

    console.log(this.allAvailabilities);

    for (let ts = this.startTimestamp/1000; ts <= this.endTimestamp/1000; ts+=24*60*60) {
      for (let extra of this.dataService.resortData.extras) {
        let findAva = this.allAvailabilities.filter(a => {if (a.day_timestamp === ts && a.extraId === extra.id && !a.timeSlotId) return a})

        if (!findAva.length) {
          this.allAvailabilities.push({
            availability: 0,
            day_timestamp: ts,
            extraId: extra.id,
            id: null,
            timeSlotId: null,
          })
        }

        let length = (this.endTimestamp/1000 - this.startTimestamp/1000)/(24*60*60) +1;
        let seasons = this.dataService.getSeasonsInReservation(this.startTimestamp/1000, length , extra.id);

        for (let seasonKey in seasons) {

          let timeslots = this.dataService.resortData.timeSlotsBySeason[seasonKey];
          for (let timeSlot of timeslots) {

            let findAva2 =  this.allAvailabilities.filter(a => {if (a.day_timestamp === ts && a.extraId === extra.id && a.timeSlotId === timeSlot.id) return a})
            if (!findAva2.length) {
              this.allAvailabilities.push({
                availability: 0,
                day_timestamp: ts,
                extraId: extra.id,
                id: null,
                timeSlotId: timeSlot.id,
              })
            }
          }
        }
      }
    }

    for (let ava of  this.allAvailabilities) {

      if (!availabilitiesByExtra[ava.extraId+'_'+ava.timeSlotId]) {
        availabilitiesByExtra[ava.extraId+'_'+ava.timeSlotId] = {
          min: ava.availability,
          id: ava.id
        }
      } else {
        if (ava.availability < availabilitiesByExtra[ava.extraId+'_'+ava.timeSlotId].min) {
          availabilitiesByExtra[ava.extraId+'_'+ava.timeSlotId].min = ava.availability;
        }
      }
    }

    return availabilitiesByExtra;
  }

  setMinAvailability(combo) {

    // SE STO CAMBIANDO IL GIORNALIERO E QUESTO E' MAGGIORE DELLE ALTRE FASCE ORARIE ALLORA QUESTE ULTIME DEVONO ESSERE ALLINEATE AL GIORNALIERO

    console.log(combo);
    if (!combo.timeSlot.timeSlotId) {
      for (let timeSlot of combo.pack.timeSlots) {
        if (timeSlot.timeSlotId) {

          if (timeSlot.residual > combo.timeSlot.residual)
            timeSlot.residual = combo.timeSlot.residual;
        }
      }
    } else {
      let dailyResidual = combo.pack.timeSlots.find(z => { if (!z.timeSlotId) return z});
      console.log(dailyResidual);

      for (let timeSlot of combo.pack.timeSlots) {
        if (timeSlot.timeSlotId) {
          if (timeSlot.residual > dailyResidual.residual)
           dailyResidual.residual = timeSlot.residual;
        }
      }
    }

  }

  checkSave() {
    console.log("CHECK SAVE");
    console.log(this.extras);
    this.disableSave = true;

    for (const extra of this.extras) {
      if (extra.selected) {
        this.disableSave = false;
        break;
      }
    }
  }

  async saveAvailabilities() {

    this.loader = true;
    const availabilitiesToSave = [];
    console.log(this.allAvailabilities);
    for (const extra of this.extras) {

      if (!extra.alwaysAvailable && (extra.selected || this.startTimestamp === this.endTimestamp)) {

        for (let ts = this.startTimestamp; ts <= this.endTimestamp; ts+=24*60*60*1000) {

          for (let timeSlot of extra.timeSlots) {
            let timeSlotId = timeSlot.timeSlotId;
            if (!timeSlotId) {
              timeSlotId = null;
            }

            console.log(ts/1000, extra.id, timeSlotId)
            let ava = this.allAvailabilities.find(a => a.day_timestamp === ts/1000 && a.extraId === extra.id && a.timeSlotId == timeSlotId);
            let checkIfAlreadyExist = availabilitiesToSave.find(a => a.day_timestamp ===  ts/1000 && a.timesSlotId === timeSlotId && a.extraId === extra.id);

            if (!checkIfAlreadyExist) {

              let obj = {
                "extraId": extra.id,
                "timeSlotId": timeSlotId,
                "availability": timeSlot.residual,
                "day_timestamp": ts/1000,
                "id": ava.id
              }

              availabilitiesToSave.push(obj);
            }
          }
        }
      }
    }

    console.log(JSON.stringify(availabilitiesToSave));
    let response = await this.dataService.setExtraAvailabilitiesV2(availabilitiesToSave);

    console.log(response);
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      width: '340px',
      height: '110px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
          console.log(result);
    });
    //this.availabilities = newAva;
    //this.checkAvailabilities();
    console.log(this.resortData.extras);
    this.loader = false;
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.loader = true;

    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now)) {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    const startTS = (new Date(event.value)).getTime() - offset;
    const startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.endTimestamp = this.startTimestamp;
    }

    const endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);
    await this.checkAvailabilities();

    this.disableSave = false;
    if (this.startTimestamp !== this.endTimestamp) {
      this.disableSave = true;
    }

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.loader = true;

    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    const endTS = (new Date(event.value)).getTime() - offset;
    const endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.startTimestamp = this.endTimestamp;
    }

    const startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    await this.checkAvailabilities();

    this.disableSave = false;
    if (this.startTimestamp !== this.endTimestamp) {
      this.disableSave = true;
    }
  }

  async setTodayDate() {
    this.loader = true;
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startDate = startDate.getDate();

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endDate = endDate.getDate();

    this.startSerializedDate = new Date(this.startTimestamp);
    this.endSerializedDate = new Date(this.endTimestamp);

    await this.checkAvailabilities();
    this.disableSave = false;
    if (this.startTimestamp !== this.endTimestamp) {
      this.disableSave = true;
    }

  }
}
