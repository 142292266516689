import { Injectable } from '@angular/core';
import { DataService, Customer } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private dataService: DataService) { }


  getDateDDMMYYYYFromTs(ts:number) {

    let dateMonth = (new Date(ts*1000)).getMonth()+1;

    let dateDay = (new Date(ts*1000)).getDate()
    let dateYear = (new Date(ts*1000)).getFullYear();

    let dayString = dateDay.toString();
    let monthString = dateMonth.toString();
    
  
    if (dateMonth < 10)
      monthString = '0'+monthString;
  
    if (dateDay < 10)
      dayString = '0'+dayString;

    return dayString+'/'+monthString+'/'+dateYear;
  }
  
}
