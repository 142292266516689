import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../../core/providers/data.service';


@Component({
  selector: 'app-modal-logs',
  templateUrl: './modal-logs.component.html',
  styleUrls: ['./modal-logs.component.scss']
})
export class ModalLogsComponent implements OnInit {

  log;index;logs; overlayLoader=false; error

  constructor(public router: Router, private dataService: DataService, public dialogRef: MatDialogRef<ModalLogsComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.index = data.index;
    this.log = data.log;
    this.logs = data.logs;

    if (this.log && !this.log.shortDetails) {
      this.log.shortDetails = [];
    }

    console.log(this.logs);
  }

  ngOnInit() {
  }

  close(log): void {
    this.dialogRef.close(log);
  }

  async restore(data) {
    this.overlayLoader = true;
    console.log(data);
    let response = await this.dataService.restoreReservation(data.reservation.id, data.date);
    console.log(response);
    this.overlayLoader = false;
    if (response.error) {
      this.error = 'Postazioni non più disponibili';
    } else {
      this.close(response);
    }
  }

  openLogModal(log,i) {
    this.log = JSON.parse(JSON.stringify(log));
    this.index = i;
  }
}
