
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user;
  isSuperManager = false;
  isManager = false;
  isSuperAdmin = false;
  permissions = null;
  subscription = null;
  subscriptionExpired = false;
  subscriptions = [];


  constructor(
    private http: HttpClient,
    public router: Router,
    public ngZone: NgZone,
    public afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private dataService: DataService
  ) {



    this.afAuth.authState.subscribe(async user => {
        this.user = user;

        if (!user) {
          this.SignOutNoRefresh();
        } else {
          this.afAuth.auth.currentUser.getIdTokenResult().then(async userData => {
            console.log(user);
            console.log(userData);
            console.log(userData.claims);
            if (userData.claims.supermanager) {
              //this.dataService.isSuperManager = true;
              this.isSuperManager = true;
              await this.setSubscriptionData();
            } else if (userData.claims.manager) {
              this.isManager = true;
              await this.setSubscriptionData();
            } else {
              this.SignOutNoRefresh();
            }
          });
        }
    });
  }

  async setSubscriptionData() {

    const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

    this.subscriptionExpired = false;
    const api = environment.apiURL + 'users_managers/manager_subscriptions/';
    const token = firebaseUser.token;
    const data = await this.http.get(api, { headers: {'Authorization': token}}).toPromise();

    if (data[`result`] && data[`result`].subscriptions && data[`result`].is_active) {
      this.permissions = data[`result`].permissions;

      data[`result`].subscriptions = data[`result`].subscriptions.sort(this.compareByEnd);

      this.subscription = data[`result`].subscriptions.find(l => l.active);
      this.subscriptions = data[`result`].subscriptions;


      console.log("-------------------");
      console.log("MANAGER", data[`result`]);
      console.log("RESORT ID", data[`result`].resortsIds[0]);
      console.log("LICENZE", this.subscriptions);
      console.log("ULTIMA LICENZA ATTIVA", this.subscription);
      console.log("PLAN ID", this.subscription.planId);
      console.log("-------------------");
      let resortId = data[`result`].resortsIds[0];

      let nowTS = Math.trunc(new Date().getTime() / 1000);
      console.log(nowTS)

      if (!this.subscription || this.subscription.end < nowTS) {
        console.log("SUBSCRIPTION SCADUTA");
        this.subscriptionExpired = true;
        let nextNotPaidSubscription = this.subscriptions.find(s => s.active === false && s.start > this.subscription.start);
        console.log("LICENZA NON PAGATA", nextNotPaidSubscription);

        if (!nextNotPaidSubscription) {
          let resortData = await this.dataService.getResortData();
          nextNotPaidSubscription = await this.dataService.pushUnpaidSubscription(this.subscription);
        }
        console.log(resortId+'/license/'+nextNotPaidSubscription.id+'/quotation');
        this.router.navigate([resortId+'/license/'+nextNotPaidSubscription.id+'/quotation']);
        //this.SignOutNoRefresh();
      } else if (this.subscription && this.subscription.planId === 0 && this.router.url.indexOf('suite') > -1) {
        this.router.navigate(['booking/home']);
      } else if (this.subscription && this.subscription.planId === 3 && this.router.url === '/') {
        this.router.navigate(['suite/home']);
      } else if (this.subscription && this.subscription.planId === 0 && this.router.url === '/') {
        this.router.navigate(['booking/home']);
      } else if (!this.subscription || this.subscription.planId === null) {
        this.SignOutNoRefresh();
      }
    } else {
      if (data[`result`] && !data[`result`].is_active) {
        alert('L\'accesso a questo account non è più attivo, contatta il manager del lido o assistenza cocobuk');
      }
      this.SignOutNoRefresh();
    }


  }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      this.user = this.afAuth.auth.onAuthStateChanged(function(user) {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      });
    });
  }


  compareByEnd( a: any, b: any ) {
    if ( a.end > b.end ){
      return -1;
    }
    if ( a.end < b.end ){
      return 1;
    }
    return 0;
  }

  SignIn(email, password) {
    email = email.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          console.log('OK');
          console.log(result);
          // CONTROLLA CHE TIPO DI UTENTE E'
          this.router.navigate(['suite/home']);
        });
      }).catch((error) => {
        window.alert(error.message);
      });
  }

  // Firebase Logout
  SignOut() {
    this.dataService.resetData();
    window.sessionStorage.clear();
    window.localStorage.clear();

    return this.afAuth.auth.signOut().then(() => {
        this.router.navigate(['']);
        window.location.reload();
    });
  }

  SignOutNoRefresh() {
    //this.db.database.goOffline();
    this.dataService.resetData();
    window.sessionStorage.clear();
    window.localStorage.clear();
    return this.afAuth.auth.signOut().then(() => {
        this.router.navigate(['']);
    });
  }

  async ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      return true
    }).catch((error) => {
      return error;
    });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  SetUserData(user) {
    //const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    /* const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    }) */

    return true;
  }
}
