import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/pages/login/login.component';
import { HomeComponent } from './home/pages/suite_home/home.component';
import { HomeComponent as BookingHomeComponent} from './home/pages/booking_home/home.component';
import { AuthguardService } from './core/providers/authguard.service';


const routes: Routes = [
  { path: '', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
