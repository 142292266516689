
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class DataStatisticsService {

  constructor(private dataService: DataService, private http: HttpClient,
    public afAuth: AngularFireAuth) { }

  shortMonths = {
    '0':{
      en:'jan',
      it:'gen'
    },
    '1':{
      en:'feb',
      it:'feb'
    },
    '2':{
      en:'mar',
      it:'mar'
    },
    '3':{
      en:'apr',
      it:'apr'
    },
    '4':{
      en:'may',
      it:'mag'
    },
    '5':{
      en:'jun',
      it:'giu'
    },
    '6':{
      en:'jul',
      it:'lug'
    },
    '7':{
      en:'aug',
      it:'ago'
    },
    '8':{
      en:'sep',
      it:'set'
    },
    '9':{
      en:'oct',
      it:'ott'
    },
    '10':{
      en:'nov',
      it:'nov'
    },
    '11':{
      en:'dec',
      it:'dic'
    }
  }
  months = {
    '0':{
      en:'january',
      it:'gennaio'
    },
    '1':{
      en:'febraury',
      it:'febbraio'
    },
    '2':{
      en:'march',
      it:'marzo'
    },
    '3':{
      en:'april',
      it:'aprile'
    },
    '4':{
      en:'may',
      it:'maggio'
    },
    '5':{
      en:'june',
      it:'giugno'
    },
    '6':{
      en:'july',
      it:'luglio'
    },
    '7':{
      en:'august',
      it:'agosto'
    },
    '8':{
      en:'septmber',
      it:'settembre'
    },
    '9':{
      en:'october',
      it:'ottobre'
    },
    '10':{
      en:'november',
      it:'novembre'
    },
    '11':{
      en:'december',
      it:'dicembre'
    }
  }

  days = {
    '0':{
      en:'sunday',
      it:'domenica'
    },
    '1':{
      en:'monday',
      it:'lunedì'
    },
    '2':{
      en:'tuesday',
      it:'martedì'
    },
    '3':{
      en:'wednesday',
      it:'mercoledì'
    },
    '4':{
      en:'thurdsay',
      it:'giovedì'
    },
    '5':{
      en:'friday',
      it:'venerdì'
    },
    '6':{
      en:'saturday',
      it:'sabato'
    }
  }

  async getGeneral(start,end) {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/statistics/general/';

      const token = firebaseUser.token;

      let params = '?';

      if (start) {
        params += 'start=' + start + '&';
      }

      if (end) {
        params += 'end=' + end + '&';
      }
      params += 'cart=false&resortId=' + this.dataService.resortData.id + '&';
      console.log(api+params)
      console.log(new Date().getTime())
      const data = await this.http.get(api+params, { headers: {Authorization: token}}).toPromise();
      //this.optionals = data[`results`];
      console.log(data);
      return data;
    } catch (err) {
      return err;
    }
  }

  async getAttendance(start, end, zones, cocoBooking, extras, discounts, coupons) {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/statistics/attendance/';

      const token = firebaseUser.token;

      let params = '?';

      if (start) {
        params += 'start=' + start + '&';
      }

      if (end) {
        params += 'end=' + end + '&';
      }

      if (extras) {
        params += 'extrasIds=' + extras + '&';
      }

      if (discounts) {
        params += 'discountsIds=' + discounts + '&';
      }

      if (coupons) {
        params += 'couponsIds=' + coupons + '&';
      }

      if (zones) {
        params += 'zoneIds=' + zones + '&';
      } /* else {
        const ids = [];
        for (const zone of this.dataService.resortData.zones) {
          ids.push(zone.id);
        }
        params += 'zoneIds='+ids.join()+ '&';
      } */

      if (cocoBooking) {
        params += 'cocoBooking=' + cocoBooking+'&';
      }

      params += 'cart=false&resortId=' + this.dataService.resortData.id + '&';
      console.log(api+params)
      console.log(new Date().getTime())
      const data = await this.http.get(api+params, { headers: {Authorization: token}}).toPromise();
      console.log("RESPONSE "+api);
      console.log(new Date().getTime())
      //this.optionals = data[`results`];
      return data;
    } catch (err) {
      return err;
    }
  }

  async getAverageStay(start, end, zones, cocoBooking, extras, discounts, coupons) {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/statistics/stay/';

      const token = firebaseUser.token;

      let params = '?';

      if (start) {
        params += 'start=' + start + '&';
      }

      if (end) {
        params += 'end=' + end + '&';
      }

      if (extras) {
        params += 'extrasIds=' + extras + '&';
      }

      if (discounts) {
        params += 'discountsIds=' + discounts + '&';
      }

      if (coupons) {
        params += 'couponsIds=' + coupons + '&';
      }

      if (zones) {
        params += 'zoneIds=' + zones + '&';
      } /* else {
        const ids = [];
        for (const zone of this.dataService.resortData.zones) {
          ids.push(zone.id);
        }
        params += 'zoneIds='+ids.join()+ '&';
      } */

      if (cocoBooking) {
        params += 'cocoBooking=' + cocoBooking+'&';
      }

      params += 'cart=false&resortId=' + this.dataService.resortData.id + '&';
      console.log(api+params)
      console.log(new Date().getTime())
      const data = await this.http.get(api+params, { headers: {Authorization: token}}).toPromise();
      //this.optionals = data[`results`];
      
      console.log("RESPONSE "+api);
      console.log(new Date().getTime())
      return data;
    } catch (err) {
      return err;
    }
  }

  async getCount(start, end, zones, cocoBooking, extras, discounts, coupons) {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/count/';

      const token = firebaseUser.token;

      let params = '?';

      if (start) {
        params += 'start=' + start + '&';
      }

      if (end) {
        params += 'end=' + end + '&';
      }

      if (extras) {
        params += 'extrasIds=' + extras + '&';
      }

      if (discounts) {
        params += 'discountsIds=' + discounts + '&';
      }

      if (coupons) {
        params += 'couponsIds=' + coupons + '&';
      }

      if (zones) {
        params += 'zoneIds=' + zones + '&';
      }/*  else {
        const ids = [];
        for (const zone of this.dataService.resortData.zones) {
          ids.push(zone.id);
        }
        params += 'zoneIds='+ids.join()+ '&';
      } */

      if (cocoBooking) {
        params += 'cocoBooking=' + cocoBooking+'&';
      }

      params += 'cart=false&resortId=' + this.dataService.resortData.id + '&';
      console.log(api+params)
      console.log(new Date().getTime())
      const data = await this.http.get(api+params, { headers: {Authorization: token}}).toPromise();
      
      console.log("RESPONSE "+api);
      console.log(new Date().getTime())
      //this.optionals = data[`results`];
      return data;
    } catch (err) {
      return err;
    }
  }

  async getRevenues(start, end, zones, cocoBooking) {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/statistics/revenues/';

      const token = firebaseUser.token;

      let params = '?';

      if (start) {
        params += 'startPayment=' + start + '&';
      }

      if (end) {
        params += 'endPayment=' + end + '&';
      }

      if (zones) {
        params += 'zoneIds=' + zones + '&';
      }/*  else {
        const ids = [];
        for (const zone of this.dataService.resortData.zones) {
          ids.push(zone.id);
        }
        params += 'zoneIds=' + ids.join() + '&';
      } */

      if (cocoBooking) {
        params += 'cocoBooking=' + cocoBooking + '&';
      }

      params += 'cart=false&billsPayed=true&resortId=' + this.dataService.resortData.id + '&';
      console.log(api+params)
      console.log(new Date().getTime())
      const data = await this.http.get(api+params, { headers: {Authorization: token}}).toPromise();
      //this.optionals = data[`results`];

      
      console.log("RESPONSE "+api);
      console.log(new Date().getTime())
      return data;
    } catch (err) {
      return err;
    }
  }

  async getBill(start, end, zones, cocoBooking, extras, discounts, coupons) {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/statistics/bill/';

      const token = firebaseUser.token;

      let params = '?';

      if (start) {
        params += 'start=' + start + '&';
      }

      if (end) {
        params += 'end=' + end + '&';
      }

      if (extras) {
        params += 'extrasIds=' + extras + '&';
      }

      if (discounts) {
        params += 'discountsIds=' + discounts + '&';
      }

      if (coupons) {
        params += 'couponsIds=' + coupons + '&';
      }

      if (zones) {
        params += 'zoneIds=' + zones + '&';
      } /* else {
        const ids = [];
        for (const zone of this.dataService.resortData.zones) {
          ids.push(zone.id);
        }
        params += 'zoneIds='+ids.join()+ '&';
      } */

      if (cocoBooking) {
        params += 'cocoBooking=' + cocoBooking+'&';
      }

      params += 'cart=false&resortId=' + this.dataService.resortData.id + '&';
      console.log(api+params)
      console.log(new Date().getTime())
      const data = await this.http.get(api+params, { headers: {Authorization: token}}).toPromise();

      
      console.log("RESPONSE "+api);
      console.log(new Date().getTime())
      //this.optionals = data[`results`];
      return data;
    } catch (err) {
      return err;
    }
  }

  async getEmploymentRate(start, end, orderType, reservationsIds, extras, discounts, coupons, cocoBooking, zones) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/statistics/employment_rate/';

      const token = firebaseUser.token;

      let params = '?';

      if (extras) {
        params += 'extrasIds=' + extras + '&';
      }

      if (discounts) {
        params += 'discountsIds=' + discounts + '&';
      }

      if (coupons) {
        params += 'couponsIds=' + coupons + '&';
      }

      if (start) {
        params += 'start=' + start + '&';
      }

      if (end) {
        params += 'end=' + end + '&';
      }

      if (reservationsIds) {
        params += 'reservationsIds=' + reservationsIds + '&';
      }


      if (orderType) {
        params += 'orderType=' + orderType + '&';
      }

      if (cocoBooking) {
        params += 'cocoBooking=' + cocoBooking+'&';
      }

      if (zones) {
        params += 'zoneIds=' + zones + '&';
      } /* else {
        const ids = [];
        for (const zone of this.dataService.resortData.zones) {
          ids.push(zone.id);
        }
        params += 'zoneIds=' + ids.join() + '&';
      } */

      params += 'cart=false&resortId=' + this.dataService.resortData.id + '&';
      console.log(api + params);
      console.log(new Date().getTime())
      const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();
      
      console.log("RESPONSE "+api);
      console.log(new Date().getTime())
      return data;
    } catch (err) {
      return err;
    }
  }


}
