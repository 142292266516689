import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepickerInputEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/providers/data.service';
import { SettingsService } from 'src/app/core/providers/settings.service';

@Component({
  selector: 'app-modal-extra',
  templateUrl: './modal-extra.component.html',
  styleUrls: ['./modal-extra.component.scss']
})
export class ModalExtraComponent implements OnInit {

  resortData; selectedPackage=null; quantity = null;
  objectKeys = Object.keys; lang = 'it'; loader = false;
  startTimestamp; endTimestamp; optionalsByIds={}; optionalsByKeys= {};
  startDate; endDate; startMonth; startYear;startSerializedDate;endSerializedDate; packages;
  quantities = [1,2,3,4,5,6,7,8,9,10];selectedTimeslot=0; timeslots = [];intervalTimeSlots=[];
  noPackageAvailable = false; availabilities; residual = 0;extraPackages;extra; reservation;
  selectedExtra = null; oldSelectedExtra = null; seasonal = false;
  today = new Date().getTime()/1000;

  constructor(public settingsService:SettingsService, public router: Router, public dataService: DataService, public dialogRef: MatDialogRef<ModalExtraComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.resortData = this.dataService.resortData;
      // this.lang = this.resortData.lang;
      this.selectedExtra = this.data.extra;
      console.log(this.selectedExtra);
      //this.selectedPackage = this.extra.id;
      this.reservation = this.data.reservation;
      this.startTimestamp = this.data.reservation.start*1000;
      this.endTimestamp = this.data.reservation.end*1000;
      const startDate = new Date(this.startTimestamp);
      startDate.setUTCHours(0, 0, 0, 0);
      this.startSerializedDate = startDate;

      const endDate = new Date(this.endTimestamp);
      endDate.setUTCHours(0, 0, 0, 0);
      this.endSerializedDate = endDate;


  }

  async ngOnInit() {
    await this.initPackages();
  }

  async initPackages() {
    this.getTimeslots();
    this.packages = [];
    /* if (this.resortData.id !== 72 && this.resortData.id !== 65 && this.resortData.id !== 153  && this.resortData.id !== 473  && this.resortData.id !== 205) {
      this.extraPackages = this.resortData.extras.filter(e => e.alwaysAvailable === true);
    } else { */
      this.extraPackages = this.resortData.extras;
    /* } */
    console.log(this.extraPackages);


    this.resetData();

    if (this.selectedExtra) {
      this.oldSelectedExtra = JSON.parse(JSON.stringify(this.selectedExtra));
      console.log(this.selectedExtra);
      this.selectedPackage = this.selectedExtra.id;
      this.getTimeslots();
      if (this.selectedExtra.timeSlots && this.selectedExtra.timeSlots.length) {
        this.selectedTimeslot = this.selectedExtra.timeSlots[0].id
      } else {
        this.selectedTimeslot = 0;
      }
      console.log(this.selectedTimeslot);

      let startDate = new Date(this.selectedExtra.start*1000);
      this.startDate = startDate.getDate();
      this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
      this.startSerializedDate = new Date(this.selectedExtra.start*1000);

      let endDate = new Date(this.selectedExtra.end*1000);
      this.endDate = endDate.getDate();
      this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
      this.endSerializedDate = new Date(this.selectedExtra.end*1000);

      if (this.selectedExtra.start === this.resortData.openingDate && this.selectedExtra.end === this.resortData.closingDate) {
        this.seasonal = true;
      }
    }

    this.availabilities = await this.dataService.getExtraAvailabilitiesV2(this.startTimestamp/1000,this.endTimestamp/1000);
    //this.checkAvailability();
    this.updateTimeslot();

    console.log(this.availabilities);
    console.log(this.selectedPackage);

    if (this.selectedExtra) {
      this.residual += this.selectedExtra.quantity;
      this.quantity = this.selectedExtra.quantity;
    }

  }

  async toggleSeasonal() {
    if (this.seasonal) {
      let startDate = new Date(this.resortData.openingDate*1000);

      this.startDate = startDate.getDate();
      this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
      this.startSerializedDate = new Date(this.resortData.openingDate*1000);

      let endDate = new Date(this.resortData.closingDate*1000);

      this.endDate = endDate.getDate();
      this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
      this.endSerializedDate = new Date(this.resortData.closingDate*1000);

    } else {
      this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
      this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
      const startDate = new Date(this.startTimestamp);
      startDate.setUTCHours(0, 0, 0, 0);
      this.startSerializedDate = startDate;

      const endDate = new Date(this.endTimestamp);
      endDate.setUTCHours(0, 0, 0, 0);
      this.endSerializedDate = endDate;
    }

    this.availabilities = await this.dataService.getExtraAvailabilitiesV2(this.startTimestamp/1000,this.endTimestamp/1000);
    if (!this.selectedExtra) {
      this.resetData();
    }
    this.checkAvailability();
  }

  resetData() {
    this.selectedTimeslot = 0;
    this.selectedPackage = null;
    this.quantity = null;
    this.packages = JSON.parse(JSON.stringify(this.extraPackages));
    this.noPackageAvailable = true;
  }

  changePackage() {
    this.getTimeslots();
    //this.checkAvailability();
    this.updateTimeslot();
  }

  checkAvailability() {
    this.quantity = null;
    for (let pack of this.packages) {
      pack.residual = 1000000;
      pack.available = true;

      if (!pack.alwaysAvailable) {
        let packAvailabilities = this.availabilities.results.filter((p)=> {
          if (p.extraId === pack.id) {
            return p;
          }
        })


        for (let ts = this.startTimestamp/1000; ts <= this.endTimestamp/1000; ts+=24*60*60) {

          if (ts > (this.today-24*60*60) && packAvailabilities.length) {
            let timeSlotId = null;
            if (this.selectedTimeslot) {
              timeSlotId = this.selectedTimeslot;
            }

            let  tsAva = packAvailabilities.find(p => p.day_timestamp === ts && p.timeSlotId === timeSlotId);
            if (tsAva) {
              if (tsAva.availability < pack.residual) {
                pack.residual = tsAva.availability;
              }
            } else {
              pack.residual = 0;
              break;
            }
          } else if (ts > (this.today-24*60*60) && !packAvailabilities.length){
            pack.available = false;
            break;
          }
        }

        if (pack.available) {
          this.noPackageAvailable = false;
        }
      }

    }

    console.log(this.selectedPackage);
    console.log(this.packages);

    if (this.selectedPackage) {
      let findPack = this.packages.find(p => p.id === this.selectedPackage);

      if (findPack)
        this.residual = findPack.residual;
    }

    // SCORPORA DA RESIDUAL GLI EXTRA ALL'INTERNO DELLA PRENOTAZIONE NON ANCORA SALVATI CHE HANNO STESSE DIMENSIONI.
    // PER OGNI GIORNO SELEZIONATO CONTROLLA SE ESISTONO DEGLI INTERVALLI CHE COPRONO QUEL GG
    // E SCALA LA QUANTITA' MAGGIORE TRA TUTTI GLI INTERVALLI SU QUEL PACCHETTO ZONA TIMESLOT
    let maxCounter = 0;

    for (let ts = this.startTimestamp/1000; ts <= this.endTimestamp/1000; ts+=24*60*60) {
      let extrasAlreadySaved = this.reservation.order.products.filter(p => {
        let sameTimeslot =this.selectedTimeslot === 0 || (this.selectedTimeslot && p.timeSlots && p.timeSlots.length && p.timeSlots[0].id === this.selectedTimeslot);
        if (!p.id && p.type === 'extra' && p.extraId === this.selectedPackage && p.startDate <= ts  && p.endDate >= ts && sameTimeslot) {
          return p;
        } else {
          return null;
        }
      });

      let dailyCounter = 0;

      for (let extra of extrasAlreadySaved) {
        dailyCounter+= extra.quantity;
      }

      if (dailyCounter > maxCounter) {
        maxCounter = dailyCounter;
      }

    }

    if (this.residual > 0)
      this.residual-= maxCounter;

    if (this.selectedExtra) {
      if (this.residual >= this.selectedExtra.quantity) {
        this.quantity = this.selectedExtra.quantity;
      } else {
        this.quantity = this.residual;
      }
    }
  }

  close(confirm): void {
    if (confirm) {
      let extra = this.resortData.extras.find(e => e.id === this.selectedPackage);
      let max = null;


      let notEqualTimeSlots = (this.oldSelectedExtra && ((this.oldSelectedExtra.timeSlots.length !== this.intervalTimeSlots.length) || (this.oldSelectedExtra.timeSlots.length && this.intervalTimeSlots.length && this.oldSelectedExtra.timeSlots[0].id !== this.intervalTimeSlots[0].id)))

      console.log(notEqualTimeSlots);
      console.log(this.selectedExtra);
      if (extra.alwaysAvailable) {
        max = null;
      } else if (this.selectedExtra && this.selectedExtra.availableId) {
        max = this.selectedExtra.availableId;
      } else {
        let findExtrasWithId = this.reservation.extras.filter((e) => { return e.availableId !== null })

        if (findExtrasWithId.length) {
          for (let extraWithId of findExtrasWithId) {
            if (!max || extraWithId.availableId > max) {
              max = extraWithId.availableId;
            }
          }
        }

        if (!max) {
          max = 1;
        } else {
          max+=1;
        }
      }

      /* else if (this.selectedExtra && (this.oldSelectedExtra.start !== this.startTimestamp/1000 || this.oldSelectedExtra.end !== this.endTimestamp/1000 || this.oldSelectedExtra.id !== this.selectedPackage || notEqualTimeSlots )) {
        console.log("TIMESLOTS DIVERSI")
        let findExtrasWithId = this.reservation.extras.filter((e) => { return e.availableId !== null })

        if (findExtrasWithId.length) {
          for (let extraWithId of findExtrasWithId) {
            if (!max || extraWithId.availableId > max) {
              max = extraWithId.availableId;
            }
          }
        }

        if (!max) {
          max = 1;
        } else {
          max+=1;
        }
      } */

      const obj = {
        id: this.selectedPackage,
        name: extra.name,
        quantity: this.quantity,
        type:'extra',
        timeSlots: this.intervalTimeSlots,
        start: this.startTimestamp/1000,
        end: this.endTimestamp/1000,
        price: extra.price,
        availableId: max
      };

      this.dialogRef.close(obj);
    } else {
      this.dialogRef.close(null);
    }

  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp)
      this.endTimestamp = this.startTimestamp;

    let endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);

    this.availabilities = await this.dataService.getExtraAvailabilitiesV2(this.startTimestamp/1000,this.endTimestamp/1000);
    if (!this.selectedExtra) {
      this.resetData();
    }
    this.checkAvailability();
    //let setReservations = await this.getReservations();

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.loader = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.startTimestamp = this.endTimestamp;
    }

    let startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    this.availabilities = await this.dataService.getExtraAvailabilitiesV2(this.startTimestamp/1000,this.endTimestamp/1000);
    if (!this.selectedExtra) {
      this.resetData();
    }
    this.checkAvailability();
    //let setReservations = await this.getReservations();
  }

  async setTodayDate() {
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    let startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startDate = startDate.getDate();

    let endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endDate = endDate.getDate();

    this.startSerializedDate = new Date(this.startTimestamp);
    this.endSerializedDate = new Date(this.endTimestamp);

    //let setReservations = await this.getReservations();

    this.availabilities = await this.dataService.getExtraAvailabilitiesV2(this.startTimestamp/1000,this.endTimestamp/1000);
    if (!this.selectedExtra) {
      this.resetData();
    }
    this.checkAvailability();
  }

  updateTimeslot() {
    console.log(this.selectedTimeslot, this.timeslots);
    this.intervalTimeSlots = []
    let now = new Date();
    let offset = now.getTimezoneOffset() * 60;

    if (this.selectedTimeslot) {
      let findTS = this.timeslots.find(t => t.id === this.selectedTimeslot);
      let offset = now.getTimezoneOffset();
      let startSeconds = parseInt(findTS.startHour.split(":")[0])*60*60 + parseInt(findTS.startHour.split(":")[1])*60 + offset*60;
      let endSeconds = parseInt(findTS.endHour.split(":")[0])*60*60 + parseInt(findTS.endHour.split(":")[1])*60 + offset*60;
      findTS.startSeconds = startSeconds;
      findTS.endSeconds = endSeconds;
      findTS.utcOffset = -offset/60;
      this.intervalTimeSlots.push(findTS);
    }

    this.checkAvailability();
  }

  getTimeslots() {
    if (this.selectedPackage) {

      let length = (this.endTimestamp/1000 - this.startTimestamp/1000)/(24*60*60) + 1;
      let seasonsFound = this.dataService.getSeasonsInReservation(this.startTimestamp/1000, length,this.selectedPackage);
      console.log(seasonsFound);
      if (seasonsFound && Object.keys(seasonsFound).length === 1) {
        for (let seasonKey in seasonsFound) {
          this.timeslots = this.resortData.timeSlotsBySeason[seasonKey];
        }
      }

      if (this.intervalTimeSlots && this.intervalTimeSlots.length) {
        let selectedTimeslot = this.intervalTimeSlots[0];
        if (this.timeslots.find(t => t.id === selectedTimeslot.id)) {
        } else {
          this.timeslots.push(selectedTimeslot);
        }

        this.selectedTimeslot = selectedTimeslot.id;
      }

      console.log(this.timeslots);
    } else {
      this.selectedTimeslot = 0;
      this.timeslots = [];
    }
  }

}
