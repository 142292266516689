import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from '../../../core/providers/data.service';
import { ReservationService } from '../../../core/providers/reservation.service';
import { StripeService } from '../../../core/providers/stripe.service'

@Component({
  selector: 'app-modal-transfer',
  templateUrl: './modal-transfer.component.html',
  styleUrls: ['./modal-transfer.component.scss']
})
export class ModalTransferComponent implements OnInit {

  refunds;charges;transfer;reservations;start;end;total=0;

  constructor(public dialogRef: MatDialogRef<ModalTransferComponent>, @Inject(MAT_DIALOG_DATA) public data: any,  private stripeService: StripeService, private dataService: DataService, private reservationService: ReservationService) {

      this.charges = this.data.charges;
      this.refunds = this.data.refunds;
      this.transfer = this.data.transfer;
      this.start = this.data.start;
      this.end = this.data.end;
      console.log(this.data);
      console.log(this.charges)
      console.log(this.transfer);

      for (let c of this.charges.data) {
        c.code = '----';
        c.name = '----';
        this.total += c.amount/100 - c.application_fee_amount/100
      }

  }

  async ngOnInit() {

    let reservations = await this.dataService.getPayments(null, this.start-1000, this.end+1000, null,null, null, null, null, null, true, null, null, null, null, null, null, null, null, null, null, null, null, 100, 1, true );

    for (let res of reservations.results) {
        let customerBillCredit = res.order.customerBill.find(o => o.paymentType === 'credit-card' && o.createdBy.role === 'booking');

        if (customerBillCredit) {
          let findCharge = this.charges.data.find(o => (o.amount/100 - o.application_fee_amount/100) === customerBillCredit.price && Math.abs(o.created- customerBillCredit.date) < 120);

          if (!findCharge) {
            findCharge = this.charges.data.find(o => (o.amount/100 - o.application_fee_amount/100) === customerBillCredit.price && Math.abs(o.created- customerBillCredit.createdAt) < 180);
          }
          if (findCharge) {
            findCharge.code = res.reservationCode;
            findCharge.name = res.name + ' ' + res.surname;
          }
        }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
