import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modal-remove',
  templateUrl: './modal-remove.component.html',
  styleUrls: ['./modal-remove.component.scss']
})
export class ModalRemoveComponent implements OnInit {

  remove = false;

  constructor(public router: Router, private dataService: DataService, public dialogRef: MatDialogRef<ModalRemoveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  close(confirm): void {
    this.dialogRef.close(confirm);

    if (confirm && this.data.type === 'seasons') {
      const found = this.dataService.resortData.seasons.findIndex(season => season.id == this.data.season.id);
      this.dataService.resortData.seasons.splice(found,1);
      this.dataService.resortData.seasonsPackages = this.dataService.resortData.seasons.filter(s => {return (s.packageType === 'package')?true:false; })
      this.dataService.resortData.seasonsExtras = this.dataService.resortData.seasons.filter(s => {return (s.packageType === 'extra')?true:false; })
      for (const seasonObj of this.dataService.resortData.seasons) {
        this.dataService.resortData.seasonsByIds[seasonObj.id] = seasonObj;
      }
      if (!this.data.noRefresh) {
        this.router.navigate(['settings/offer/2']);
      }
    } else if (confirm && this.data.type === 'zones') {
      const foundZone = this.dataService.resortData.zones.findIndex(zone => zone.id == this.data.zone.id);
      this.dataService.resortData.zones.splice(foundZone,1);
      for (const zoneObj of this.dataService.resortData.zones) {
        this.dataService.resortData.zonesByIds[zoneObj.id] = zoneObj;
      }
      this.router.navigate(['settings/offer/3']);
    } else if (confirm && this.data.type === 'packages') {
      const found = this.dataService.resortData.packages.findIndex(pack => pack.id == this.data.package.id);
      this.dataService.resortData.packages.splice(found,1);
      for (const packObj of this.dataService.resortData.packages) {
        this.dataService.resortData.packagesByIds[packObj.id] = packObj;
      }
      this.router.navigate(['settings/offer/4']);
    } else if (confirm && this.data.type === 'discounts') {
      const found = this.dataService.resortData.discounts.findIndex(disc => disc.id == this.data.discount.id);
      this.dataService.resortData.discounts.splice(found,1);
      this.dataService.resortData.discountsByIds = {};
      for (const packObj of this.dataService.resortData.discounts) {
        this.dataService.resortData.discountsByIds[packObj.id] = packObj;
      }
      this.router.navigate(['settings/offer/7']);
    } else if (confirm && this.data.type === 'extras') {
      const found = this.dataService.resortData.extras.findIndex(ext => ext.id == this.data.extra.id);
      this.dataService.resortData.extras.splice(found, 1);
      this.dataService.resortData.extrasByIds = {};
      for (const packObj of this.dataService.resortData.extras) {
        this.dataService.resortData.extrasByIds[packObj.id] = packObj;
      }
      this.router.navigate(['settings/offer/8']);
    } else if (confirm && this.data.type === 'cabins') {
      this.router.navigate(['settings/offer/9']);
    } else if (confirm && this.data.type === 'reservations') {
      if (this.dataService.previousPage) {

        this.router.navigate(['suite/'+this.dataService.previousPage]);
      } else {
        this.router.navigate(['suite/home']);
      }
    } else if (confirm && this.data.type === 'coupons') {
      const foundCoupon = this.dataService.coupons.findIndex(coup => coup.id == this.data.coupon.id);
      this.dataService.coupons.splice(foundCoupon,1);
      this.router.navigate(['settings/offer/cocobuk/3']);
    } else if (confirm && this.data.type === 'customers') {
      this.router.navigate(['common/customers']);
    }

  }

  async delete() {
    try {
      if (this.data.type === 'seasons') {
        this.dataService.deleteSeason(this.data.season);
      } else if (this.data.type === 'zones') {
        this.dataService.deleteZone(this.data.zone);
      } else if (this.data.type === 'packages') {
        this.dataService.deletePackage(this.data.package);
      } else if (this.data.type === 'extras') {
        this.dataService.deleteExtra(this.data.extra);
      } else if (this.data.type === 'discounts') {
        this.dataService.deleteDiscount(this.data.discount);
      } else if (this.data.type === 'customers') {
        this.dataService.deleteCustomer(this.data.customer.id);
      } else if (this.data.type === 'reservations') {

          await this.dataService.removeReservation(this.data.reservation);

          if (this.dataService.previousPage) {
            this.router.navigate(['/suite/' + this.dataService.previousPage]);
          } else {
            this.router.navigate(['/suite/home']);
          }

      } else if (this.data.type === 'coupons') {
        this.dataService.deleteCoupon(this.data.coupon);
      } else if (this.data.type === 'offers') {

      } else if (this.data.type === 'cabins') {
        this.dataService.deleteCabin(this.data.cabin);
      } else if (this.data.type === 'fiscalPrinters') {

      } else if (this.data.type === 'managers') {
        this.dataService.deleteManager(this.data.manager)
      }

      this.remove = true;
    } catch(err) {
      console.log(err)
    }
  }

}
