import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service'
import { SettingsService } from '../../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';

@Component({
  selector: 'app-map-area-detail',
  templateUrl: './map-area-detail.component.html',
  styleUrls: ['./map-area-detail.component.scss']
})
export class MapAreaDetailComponent implements OnInit {

  resortData; managerData; selectedMapArea; pageClicked; areaId; mapAreas; loading;

  constructor(public router: Router, public dialog: MatDialog,private route: ActivatedRoute, private dataService: DataService, private settingsService: SettingsService) {

    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
  }

  async ngOnInit() {
    this.loading = true;
    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.resortData = this.dataService.resortData;
    this.managerData =  this.dataService.userData;

    if (!this.dataService.mapAreas) {
      this.mapAreas = (await this.dataService.getMapAreas()).results;
    }

    this.initArea();
    this.loading = false;
  }

  initArea() {
    this.areaId = this.route.snapshot.paramMap.get('areaId');
    console.log(this.areaId);
    if (this.areaId != 'new') {
      const areaFound = this.dataService.mapAreas.find(area => area.id === parseInt(this.areaId));
      this.selectedMapArea = JSON.parse(JSON.stringify(areaFound));
    } else {
      this.areaId = null;
      this.selectedMapArea = {
        name:'',
        startColumn: null,
        endColumn: null
      };
    }
  }

  async saveArea() {
    if (this.areaId === null) {
      let save = await this.dataService.createMapArea(this.selectedMapArea);
    } else {
      let save = await this.dataService.updateMapArea(this.selectedMapArea);
    }
    this.router.navigate(['settings/advanced/1']);
  }

  async removeArea() {
      await this.dataService.deleteMapArea(this.selectedMapArea);
      this.router.navigate(['settings/advanced/1']);
  }


}
