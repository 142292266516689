import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/providers/data.service';
import { SettingsService } from 'src/app/core/providers/settings.service';
import { ModalCheckoutComponent } from 'src/app/shared/components/modal-checkout/modal-checkout.component';
import { ModalErrorComponent } from 'src/app/shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-reservation-single-box',
  templateUrl: './reservation-single-box.component.html',
  styleUrls: ['./reservation-single-box.component.scss']
})
export class ReservationSingleBoxComponent implements OnChanges {


  @Input() selectedReservation:any;
  @Input() selectedSlot:any;
  @Input() today:any;
  @Input() startDate:any;
  @Input() endDate:any;
  @Input() timeSlots:any;
  @Input() selectedSlots:any;
  @Input() resortData:any;
  @Input() managerData:any;
  @Input() reservationMapData:any;
  @Input() startTimestamp:any;
  @Input() endTimestamp:any;
  @Output() resetDataEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() lightLoadingEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadingEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() openReservationEmitter:  EventEmitter<any> = new EventEmitter<any>();

  objectKeys:any = Object.keys;
  optionals:any;
  optionalsByIds: any = {};
  optionalsByKeys: any= {};
  clicked:boolean = false;
  dailyReserved: boolean = false;
  selectedInterval:any;

  constructor(
    public dialog: MatDialog,
    public dataService:DataService,
    public router: Router,
    private settingsService: SettingsService
  ) {
  }

  ngOnChanges() {
    this.dailyReserved = false;
    if (this.dataService.optionals) {

      this.optionals = this.dataService.optionals;

      if (this.optionals) {
        this.optionals.map(opt => {
          this.optionalsByIds[opt.id] = opt;
          this.optionalsByKeys[opt.optionalId] = opt;
        });
      }
    }

    if (this.selectedReservation && this.selectedReservation.selectedInterval && this.selectedReservation.selectedInterval.timeSlots.length === 0) {
      //let slotFound = this.selectedReservation.selectedInterval.order.find(o => o.slot.slot_key === (this.selectedSlot.row + '_' + this.selectedSlot.col));
      this.dailyReserved = true;
    }

    if (this.selectedReservation) {
      this.selectedReservation.todayState = 2;
      this.selectedReservation.todayCovered = false;
        
      console.log(this.selectedReservation);
      console.log(this.selectedSlot);

      for (let interval of this.selectedReservation.dates) {
        if (interval.start <= this.today/1000 && interval.end >= this.today/1000) {
          this.selectedReservation.todayCovered = true;
          break;
        }
      }
      if (this.selectedReservation.checkInDates.indexOf(this.today/1000) > -1 && this.selectedReservation.checkOutDates.indexOf(this.today/1000) == -1) {
        this.selectedReservation.todayState = 0;
      } else if (this.selectedReservation.checkOutDates.indexOf(this.today/1000) > -1) {
        this.selectedReservation.todayState = -1;
      }
    }
    console.log(this.selectedInterval);
    for (let extra of this.selectedReservation.extras) {
      if (extra.name === 'Extra applicato') {
        extra.name = {
          'it':'Extra applicato',
          'en':'Applied extra',
          'de':'Applied extra',
          'es':'Applied extra',
          'fr':'Applied extra'
        }
      }
    }
  }


  openReservation(url) {
    this.dataService.previousPage = 'home';
    this.router.navigateByUrl(url);
  }

  resetData() {
    this.resetDataEmitter.emit();
  }

  async checkInSlot(reservation, slot) {

    try {
      let slotKeys = [slot.slot_key];

      let httpCall = await this.dataService.checkInSlot(reservation.id, reservation.selectedInterval.date_key, slotKeys, this.startTimestamp / 1000, this.endTimestamp / 1000,
      this.settingsService.getCurrentUserData() )
      console.log(httpCall);
      return this.manageHttpError(httpCall, false);

    } catch(err) {
      console.log(err);
      return  this.manageHttpError(err, true);
    }
  }

  async checkOutSlot(reservation, slot) {
    let slotKeys = [slot.slot_key];
    let httpCall = await this.dataService.checkOutSlot(reservation.id, reservation.selectedInterval.date_key, slotKeys, this.startTimestamp / 1000, this.endTimestamp / 1000, this.settingsService.getCurrentUserData() )

    return this.manageHttpError(httpCall, false);
  }

  async checkIn(reservation) {

    try {

      let slotKeys = [];
      for (const slot of reservation.selectedInterval.order) {

        let findCheckIn = false;
        let findFree = false;

        if (slot.checkin) {
          findCheckIn = slot.checkin.find(ts => ts.start >= this.startTimestamp / 1000 && ts.end <= this.endTimestamp / 1000);
        }

        if (slot.free) {
          findFree = slot.free.find(ts => ts.start >= this.startTimestamp / 1000 && ts.end <= this.endTimestamp / 1000);
        }

        if (!findCheckIn && !findFree && slot.type === 'slot') {
          slotKeys.push(slot.slot_key);
        }
      }

      console.log(slotKeys);

      if (slotKeys.length) {
        let httpCall = await this.dataService.checkInSlot(reservation.id, reservation.selectedInterval.date_key, slotKeys, this.startTimestamp / 1000, this.endTimestamp / 1000, this.settingsService.getCurrentUserData() )
        console.log(httpCall);
        return this.manageHttpError(httpCall, false);
      } else {
        this.clicked = false;
      }
    } catch(err) {
      console.log(err);
      return this.manageHttpError(err, true);
    }

  }

  async checkInToday(reservation) {

    try {
      console.log(this.today);
      let slotKeys = [];
      for (const slot of reservation.selectedInterval.order) {

        let findCheckIn = false;
        let findFree = false;

        if (slot.checkin) {
          findCheckIn = slot.checkin.find(ts => ts.start >= this.today / 1000 && ts.end <= this.today / 1000);
        }

        if (slot.free) {
          findFree = slot.free.find(ts => ts.start >= this.today / 1000 && ts.end <= this.today / 1000);
        }

        if (!findCheckIn && !findFree && slot.type === 'slot') {
          slotKeys.push(slot.slot_key);
        }
      }

      console.log(slotKeys);

      if (slotKeys.length) {
        let httpCall = await this.dataService.checkInSlot(reservation.id, reservation.selectedInterval.date_key, slotKeys, this.today / 1000, this.today / 1000, this.settingsService.getCurrentUserData() )
        console.log(httpCall);
        this.clicked = false;
        return this.manageHttpError(httpCall, false);
      } else {
        this.clicked = false;
      }
    } catch(err) {
      console.log(err);
      return this.manageHttpError(err, true);
    }

  }

  async checkOutAll(reservation) {
    let slotKeys = [];
    for (const slot of reservation.selectedInterval.order) {

      let findCheckout = false;
      let findFree = false;
      let findCheckin = false;

      if (slot.checkin) {
        findCheckin = slot.checkin.find(ts => ts.start >= this.startTimestamp / 1000 && ts.end <= this.endTimestamp / 1000);
      }

      if (slot.checkout) {
        findCheckout = slot.checkout.find(ts => ts.start >= this.startTimestamp / 1000 && ts.end <= this.endTimestamp / 1000);
      }

      if (slot.free) {
        findFree = slot.free.find(ts => ts.start >= this.startTimestamp / 1000 && ts.end <= this.endTimestamp / 1000);
      }

      if (findCheckin && !findCheckout && !findFree) {
        slotKeys.push(slot.slot_key);
      }
    }

    console.log(slotKeys);

    if (slotKeys.length) {
      let httpCall = await this.dataService.checkOutSlot(reservation.id, reservation.selectedInterval.date_key, slotKeys, this.startTimestamp / 1000, this.endTimestamp / 1000, this.settingsService.getCurrentUserData() )
      this.clicked = false;
      return this.manageHttpError(httpCall, true);
    }

  }

  async checkOutToday(reservation) {
    let slotKeys = [];
    for (const slot of reservation.selectedInterval.order) {

      let findCheckout = false;
      let findFree = false;
      let findCheckin = false;

      if (slot.checkin) {
        findCheckin = slot.checkin.find(ts => ts.start >= this.today / 1000 && ts.end <= this.today / 1000);
      }

      if (slot.checkout) {
        findCheckout = slot.checkout.find(ts => ts.start >= this.today / 1000 && ts.end <= this.today / 1000);
      }

      if (slot.free) {
        findFree = slot.free.find(ts => ts.start >= this.today / 1000 && ts.end <= this.today / 1000);
      }

      if (findCheckin && !findCheckout && !findFree) {
        slotKeys.push(slot.slot_key);
      }
    }

    console.log(slotKeys);

    if (slotKeys.length) {
      let httpCall = await this.dataService.checkOutSlot(reservation.id, reservation.selectedInterval.date_key, slotKeys, this.startTimestamp / 1000, this.endTimestamp / 1000, this.settingsService.getCurrentUserData() )
      this.clicked = false;
      return this.manageHttpError(httpCall, true);
    }

  }

  async freeSlot(reservation) {
    let slotKeys = [];
    for (const slot of reservation.selectedInterval.order) {
      slotKeys.push(slot.slot_key);
    }
    let httpCall = await this.dataService.freeSlot(reservation.id, reservation.selectedInterval.date_key, slotKeys, this.startTimestamp / 1000, this.endTimestamp / 1000, this.settingsService.getCurrentUserData(), '').catch(err => {
      return err;
    });

    return this.manageHttpError(httpCall, false);
  }

  async checkout(reservation) {

    const dialogRef = this.dialog.open(ModalCheckoutComponent, {
      width: '340px',
      height: '130px'
    });

    dialogRef.afterClosed().subscribe(async result => {
        console.log(result);
        if (result) {
          /* this.loadingEmitter.emit(true); */
          //await this.checkOutAll(reservation);
          await this.checkOutToday(reservation);
          /* this.loadingEmitter.emit(false); */
        } else {
        }
        this.clicked = false;
    });

  }

  async manageHttpError(httpCall, reset) {
    if (httpCall && httpCall.error) {
      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        height: '130px',
        data: {
          type: 'reservation-error',
          error: httpCall.error.error
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        this.resetData();
        this.lightLoadingEmitter.emit(false);
      });
      return false;
    } else {
      console.log(httpCall);

      if (reset) {
        this.resetData();
      } else {
        const rowIndex = this.selectedSlots[0].split('_')[0];
        const colIndex = this.selectedSlots[0].split('_')[1];
        this.openReservationEmitter.emit(this.reservationMapData[rowIndex][colIndex]);
        this.clicked = false;
      }
      this.lightLoadingEmitter.emit(false);
      return httpCall;
    }
  }

  move(reservation,interval, mode, packageId) { 
    console.log(reservation);
    
    this.dataService.previousPage = 'home';
    this.dataService.selectedReservation = reservation;

    if (interval && packageId) {
      this.router.navigate(['suite/reservation/' + reservation.id + '/map/' + interval.start + '/' + interval.end + '/' + mode + '/' + packageId]);
    } else {
      this.router.navigate(['suite/reservation/' + reservation.id + '/map']);
    }
  }
}
