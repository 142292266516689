import { Component, NgZone, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service';
import { SettingsService } from '../../../core/providers/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog} from '@angular/material';
import { ModalErrorComponent } from '../../../shared/components/modal-error/modal-error.component';
import { ReservationService } from 'src/app/core/providers/reservation.service';
import { ModalOrderComponent } from '../../components/modal-order/modal-order.component';
import { ModalExtraComponent } from '../../components/modal-extra/modal-extra.component';
import { CustomersService } from 'src/app/core/providers/customer.service';
import { UtilitiesService } from 'src/app/core/providers/utilities.service';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.scss']
})
export class ReservationDetailComponent implements OnInit {

  // GLOBAL VARIABLES
  resortData; managerData; reservation = null; lang = 'it'; optionals; mapData;
  objectKeys = Object.keys; prices;  clicked = false;
  loading = true; lightLoading = false; optionalsByIds = {}; optionalsByKeys = {}; today; fiscalPrintFlag = false;
  paymentType = 'cash'; settled = false;  ; nowTS; pageClicked=false; changes=false;
  seasonTicketType:string = 'daily'; refundsAvailable:any[] = [];

  dailyPrice:boolean = false;
  useResortRefund:boolean = false;
  specialSeasonPrice:boolean = false;
  admissionsFlag:boolean  = false;
  todayAdmissions; allAdmissions;

 forcedPrice = false;

  mainCustomer = {
    name: '',
    surname: '',
    email: '',
    phonePrefix: '+39',
    phone: '',
    id: null,
    warning: false,
    showNameTooltip: false,
    showSurnameTooltip: false,
    lotteryCode: '',
    uid:null
  };


  associatedCustomers = [];
  associatedCustomersThroughApp = [];

  constructor(
    public ngZone: NgZone,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public dataService: DataService,
    public settingsService: SettingsService,
    public reservationService: ReservationService,
    public customerService: CustomersService,
    public utilsService: UtilitiesService
    ) {}

  // SETUP DATA
  // SETUP DATA
  // SETUP DATA

  async ngOnInit() {
    try {
      this.loading = true;

      await this.setupResortData();

      const resId = this.route.snapshot.paramMap.get('id');

      if (resId) {
        if (this.dataService.selectedReservation) {

          // GET RESERVATION FROM PROVIDER

          this.setupReservationFromProvider();

        } else {

          // GET RESERVATION FROM DB

          await this.setupReservationFromDB(resId);

        }

        console.log("DATI PRENOTAZIONE")
        console.log(JSON.parse(JSON.stringify(this.reservation)));

        await this.setupReservationData();

        this.loading = false;

      } else {

        // NO RESERVATION FOUND

        this.dataService.selectedReservation = null;
        if (!this.dataService.previousPage ) {
          this.router.navigate(['/suite/home']);
        } else {
          this.router.navigate(['/suite/' + this.dataService.previousPage]);
        }
      }


      console.log("DATI PRENOTAZIONE")
      console.log(JSON.parse(JSON.stringify(this.reservation)));

    } catch(err) {
      console.log(err);
      this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'reservation-error',
          error: 'Errore nel caricamento della prenotazione'
        }
      });
    }

  }

  async initReservation(reservation) {
    this.reservation = reservation;
    this.setupReservationData();
  }

  // DATA SETUP

  async setupResortData() {
    if (!this.dataService.prices || !this.dataService.userData) {
      await this.dataService.initResortData();
    }

    this.lang = this.dataService.lang;
    this.nowTS = (new Date().getTime()) / 1000;
    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.mapData =  this.dataService.mapByRowCol;
    this.today = (new Date()).setUTCHours(0, 0, 0, 0) / 1000;

    if (!this.dataService.startTimestamp) {
      this.dataService.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
      this.dataService.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    }

    this.optionals.map(opt => {
      this.optionalsByIds[opt.id] = opt;
      this.optionalsByKeys[opt.optionalId] = opt;
    });


    for (const extra of this.resortData.extras) {
      extra.quantity = 0;
    }

    for (const discount of this.resortData.discounts) {
      discount.checked = false;
    }

  }

  // RESERVATION UPDATING AND CHECK FUNCTIONS
  // RESERVATION UPDATING AND CHECK FUNCTIONS
  // RESERVATION UPDATING AND CHECK FUNCTIONS

  async setupReservationFromProvider() {
    this.reservation = this.dataService.selectedReservation;

    if (this.dataService.mainCustomer) {
      this.mainCustomer = this.dataService.mainCustomer;
    }

    if (this.dataService.associatedCustomers) {
      this.associatedCustomers = this.dataService.associatedCustomers;
    }

    if (this.dataService.backFromMap) {
      this.settled = this.reservation.order.settled;
      this.dataService.backFromMap = false;
    }


    if (typeof this.reservation.order.dailyPrice !== 'undefined' ) {
      this.dailyPrice = this.reservation.order.dailyPrice;
    }

    if (this.reservation.id && typeof this.reservation.order.specialSeasonPrice !== 'undefined' && this.reservation.order.dailyPrice !== null) {
      this.specialSeasonPrice = this.reservation.order.specialSeasonPrice;
    } else {
      this.specialSeasonPrice = this.resortData.specialSeasonPrice;
    }

    this.updateCart(this.reservation,false);
  }

  async setupReservationFromDB(resId) {
    this.reservation = await this.dataService.getSingleReservation(resId);

    if (this.reservation.error) {
      let message = 'Errore sulla prenotazione';

      if (this.reservation.error.detail) {
        message = this.reservation.error.detail;
      }
      throw new Error(message);
    }
  }

  async validateReservationData() {
    if (!this.reservation.phonePrefix || this.reservation.phonePrefix === '') {
      this.reservation.phonePrefix = '+39';
    } else if (this.reservation.phonePrefix.indexOf('+') === -1) {
      this.reservation.phonePrefix = '+' + this.reservation.phonePrefix;
    }

    console.log(JSON.parse(JSON.stringify(this.dailyPrice)));

    if (typeof this.reservation.order.dailyPrice !== 'undefined' ) {
      this.dailyPrice = this.reservation.order.dailyPrice;
    }

    if (this.reservation.id && typeof this.reservation.order.specialSeasonPrice !== 'undefined' && this.reservation.order.dailyPrice !== null) {
      this.specialSeasonPrice = this.reservation.order.specialSeasonPrice;
    } else {
      this.specialSeasonPrice = this.resortData.specialSeasonPrice;
    }

    console.log(JSON.parse(JSON.stringify(this.specialSeasonPrice)));
    console.log(JSON.parse(JSON.stringify(this.resortData.specialSeasonPrice)));

    if (this.reservation.refundState === 2) {
      if (!this.reservation.refundExpiration ) {
        this.reservation.refundExpiration = this.reservation.start + 24 * 60 * 60 * 60;
      }

      if (!this.reservation.refundAmount ) {
        this.reservation.refundAmount = this.reservation.order.total;
      }
    }

    for (const extra of this.reservation.extras) {
      // CHECK IF THE EXTRA INSIDE THE RESERVATION HAVE BEEN CANCELED
      const foundProduct = this.reservation.order.products.find(prod => prod.type === 'extra' && prod.extraId === extra.id)
      if (foundProduct) {
        if (foundProduct.name) {
          extra.name = foundProduct.name;
        } else {
          extra.name = {
            'it':'Extra non più presente',
            'en':'Extra non più presente'
          }
        }
      }
    }
  }

  async setupReservationData() {
    await this.setReservation();
    this.checkAdmissions();
    this.settingsService.checkDatesIntervalsAndUpdateDisableDates(this.reservation);
  }

  getCustomerFirstLetters(customer) {

    let out = '';
    if (customer) {
      out = customer.name.substring(0,1) + customer.surname.substring(0,1);

      if (out.length === 1) {
        out = customer.name.substring(0,2)
      }
    }

    if (out === '') {
      out = customer.email.substring(0,2);
    }

    return out;
  }

  getCustomerName(customer) {
    let out = '';
    if (customer) {
      out = customer.name + ' ' +customer.surname;
    }

    if (out === '') {
      out = customer.email;
    }

    return out;
  }

  goToCustomer(customer) {
    this.dataService.previousPage =  'suite/reservation/'+this.reservation.id;
    this.router.navigate(['/common/customer/'+customer.id]);
  }

  updateLoader(value:boolean) {
    console.log(value);
    this.lightLoading = value;
  }

  async checkRefunds() {
    console.log("CHECK REFUNDS");
    this.refundsAvailable = [];
    let mainUID = null;
    if (this.mainCustomer && this.mainCustomer.uid && this.mainCustomer.uid !== '') {
        mainUID = this.mainCustomer.uid;
    }
    console.log(mainUID);

    if (mainUID) {
      let refunds = await this.customerService.getRefunds(mainUID,this.resortData.id);
      for (let ref of refunds) {
        if (ref.amount > 0) {
          this.refundsAvailable.push(ref);
        }
      }

      console.log("AVAILABLE REFUNDS");
      console.log(this.refundsAvailable);
    }

    
    if (this.refundsAvailable.length) {
      this.useResortRefund = false;
    } else {
      this.useResortRefund = null;
    }

  }

  async setReservation() {


    if (this.reservation && this.reservation.bookingRetrieveUids && this.reservation.bookingRetrieveUids.length && this.reservation.customers) {
      this.reservation.customers.map( c => {
        if (c.customer.uid && this.reservation.bookingRetrieveUids.indexOf(c.customer.uid) > -1) {
          this.associatedCustomersThroughApp.push(c.customer);
        }
      })
    }


    console.log(this.associatedCustomers);
    console.log(this.associatedCustomersThroughApp);

    this.checkRefunds();
    this.validateReservationData();

    for (const extra of this.reservation.extras) {
      if (extra.name === 'Extra applicato') {
        extra.name = {
          'it':'Extra applicato',
          'en':'Applied extra',
          'de':'Applied extra',
          'es':'Applied extra',
          'fr':'Applied extra'
        }
      }
      // UPDATE EXTRAS QUANTITIES
      const extraFound = this.resortData.extras.find(ext => ext.id === extra.id);
      if (extraFound) {
        extraFound.quantity = extra.quantity;
      }
    }

    for (const discount of this.reservation.discounts) {
      const discountFound = this.resortData.discounts.find(disc => disc.id === discount.id);
      if (discountFound) {
        discountFound.checked = true;
      }
    }


    await this.settingsService.setDateIntervalAvailabilityAndSlotStates(this.reservation,this.mapData);
    await this.settingsService.checkDatesIntervalsAndUpdateDisableDates(this.reservation);
    this.settled = this.reservation.order.settled;

    for (const bill of this.reservation.order.customerBill) {
      if (bill.type === 'settlement') {
        this.paymentType = bill.paymentType;
      }
    }

    if (this.reservation.admissionsDailyMax || this.reservation.admissionsTotalMax ){
      this.admissionsFlag = true;
    }

    if (!this.reservation.id) {
      this.specialSeasonPrice = this.resortData.specialSeasonPrice;
    }


  }

  checkIfAssociatedUserByApp(uid) {
    if (uid && this.reservation.bookingRetrieveUids.length) {
      if (this.reservation.bookingRetrieveUids.indexOf(uid) > -1) {
        return true
      } else {
        return false
      }
    }

    return false
  }


  async getUpdatedReservation() {
    this.reservation = await this.dataService.getSingleReservation(this.reservation.id);
    await this.settingsService.setDateIntervalAvailabilityAndSlotStates(this.reservation,this.mapData);
  }

  orderPackage() {
    let dialogRef = this.dialog.open(ModalOrderComponent, {
      data: {
        reservation:this.reservation,
        order:null
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log(result);

      if (result && result.type == 'add') {

        let timeSlots = [];

        if (result.timeSlot) {
          timeSlots = result.timeSlot;
        }

        const interval = {
          date_key: (result.start) + '_' + (result.end),
          start: result.start,
          startDate: new Date(result.start * 1000),
          end: result.end,
          endDate: new Date(result.end * 1000),
          length: (result.end - result.start)/(24*60*60)+1,
          intervalAmount: 0,
          optionalsAmount: 0,
          order: [],
          timeSlots:timeSlots
        };

        const order = {
          type: 'order',
          slot_key: null,
          zoneKey: result.zoneId,
          packageKey: result.packageId,
          quantity: parseInt(result.quantity),
          optionalsKeys: {},
          col: null,
          row: null,
          coords: '',
          availableId: result.availableId
        }

        console.log(interval);
        this.mergeIntervals(interval,order);

      } else if (result && result.type=== 'update'){
        this.reservation = result.reservation;
      }
      this.updateCart(this.reservation,false);
    })
  }

  mergeIntervals(interval, order) {
    let exactIntervalExist = this.reservation.dates.find(i => i.start === interval.start && i.end === interval.end);
    if(exactIntervalExist ) {
      let bothIntervalHaveNoTimeslots = (!exactIntervalExist.timeSlots.length && !interval.timeSlots.length);
      let intervalsHaveSameTimeslots =  (exactIntervalExist.timeSlots.length && interval.timeSlots.length && exactIntervalExist.timeSlots[0].id === interval.timeSlots[0].id)

      if (bothIntervalHaveNoTimeslots || intervalsHaveSameTimeslots) {
        // PUSH THE ORDER INSIDE THE INTERVAL
        exactIntervalExist.order.push(order);
      } else {
        interval.order.push(order);
        this.reservation.dates.push(interval);
      }
    } else {
      // PUSH THE ORDER IN A NEW INTERVAL
      interval.order.push(order);
      this.reservation.dates.push(interval);
    }

    let index = 0;
    for (let interval of this.reservation.dates) {
      if (interval.order && interval.order.length === 0) {
        this.reservation.dates.splice(index,1);
      }

      index++;
    }
  }

  // CART AND CUSTOMER BILL FUNCTIONS
  // CART AND CUSTOMER BILL FUNCTIONS
  // CART AND CUSTOMER BILL FUNCTIONS

  updateCart(reservation, fromReservedPrice) {



    let totalLength = 0;
    for (let interval of reservation.dates) {
      let index = 0;
      totalLength+= interval.length;
      for (let order of interval.order) {
        if (!order.packageKey) {
          interval.order.splice(index,1);
        }
        index++;
      }
    }

    if (this.specialSeasonPrice && reservation.order.specialSeasonPrice !== this.specialSeasonPrice) {
      this.dailyPrice = false;
    }

    if (this.dailyPrice && reservation.order.dailyPrice !== this.dailyPrice) {
      this.specialSeasonPrice = false;
    }

    console.log(this.specialSeasonPrice);
    reservation.order.dailyPrice = this.dailyPrice;
    reservation.order.specialSeasonPrice = this.specialSeasonPrice;
    reservation.length = totalLength;
    this.settingsService.updateCart(reservation,fromReservedPrice);

    
    if (this.useResortRefund) {
      console.log("USE RESORT REFUND");
      console.log(this.refundsAvailable);
      console.log("RESERVATION TOTALS");
      console.log(reservation.order);
      let residualAmount = reservation.order.total;
      for (let availableRefund of this.refundsAvailable) {
        if (residualAmount > 0) {
          let findDiscountAlreadyApplied = reservation.discounts.find((discount:any)=>discount.id === availableRefund);
          if (!findDiscountAlreadyApplied) {
            let amount = availableRefund.amount;
  
            if (amount > reservation.order.total) {
              amount = reservation.order.total;
            }

            let name = availableRefund.notes;

            if (name === '') {
              let dateString = this.utilsService.getDateDDMMYYYYFromTs(availableRefund.createdAt);
              name = 'Tessera Buono del '+dateString
            }
  
            reservation.discounts.push({
              amount,
              id: availableRefund.id,
              name: {'it':name, 'en':name},
              type: 'refund'
            })

            residualAmount-=amount;

            availableRefund.amount -= amount;
          }
        } else {
          break;
        }
      }
    } else {
      let index = 0;
      for (let discount of reservation.discounts) {
        /* let findDiscountAlreadyApplied = reservation.discounts.findIndex((discount:any)=>discount.id === availableRefund);
        if (findDiscountAlreadyApplied > -1) {
          reservation.discounts.splice(findDiscountAlreadyApplied,1)
        } */
        if (discount.id && discount.id.toString().indexOf('bonus')> -1) {
          reservation.discounts.splice(index,1);
        }
        index ++;
      }
    }

    this.settingsService.updateCart(reservation,fromReservedPrice);
    this.updateCustomerBill(reservation, this.settled);

    this.reservation = reservation;
    this.reservation.order.customerBill = this.reservation.order.customerBill.sort(this.settingsService.sortByDateField);
    this.reservation.dates = this.reservation.dates.sort(this.settingsService.sortByDate);

    if (this.reservation.order.customerBillTotal > 0 && this.reservation.order.customerBillTotal < 0.01) {
      this.reservation.order.customerBillTotal = 0;
    }

    if (this.reservation.order.customerBillTotal == 0) {
      this.settled = true;
      this.reservation.order.settled = true;
    }

    this.changes = !this.changes;
    console.log(this.reservation);
  }

  checkSettled() {

    // console.log(JSON.parse(JSON.stringify(this.reservation.order)))
    if (this.reservation.order.customerBillTotal > 0) {
      const index = -1;
      let newCustomerBill = null;

      for (const customerBill of this.reservation.order.customerBill) {
        if (customerBill.type === 'settlement') {
          newCustomerBill = JSON.parse(JSON.stringify(customerBill));
          newCustomerBill.type = 'movement';
        }
      }

      if (newCustomerBill ) {
        const deleteSettlement = this.reservation.order.customerBill.findIndex(bill => bill.type === 'settlement');
        this.reservation.order.customerBill.splice(deleteSettlement, 1);
        this.reservation.order.customerBill.push(newCustomerBill);
      }

      this.reservation.order.settled = false;
      this.settled = false;
    } else {
      this.reservation.order.settled = true;
      this.settled = true;
    }


  }



  updateCustomerBillFromRecap(object) {
    this.updateCustomerBill(object.reservation, object.settled);
  }

  updatePaymentTypeFromRecap(paymentType) {
    console.log(paymentType);
    this.paymentType = paymentType
  }

  updateSpecialSeasonPriceFromRecap(specialSeason) {
    this.specialSeasonPrice = specialSeason
  }

  checkSettledFromCustomerBill() {
      this.checkSettled();
  }

  updateCustomerBill(reservation, settled) {
    this.settingsService.updateCustomerBill(reservation, settled, this.paymentType);
    this.checkSettled();
  }

  // TICKETS MANAGEMENT - @todo -> move to another component
  // TICKETS MANAGEMENT - @todo -> move to another component
  // TICKETS MANAGEMENT - @todo -> move to another component

  checkDailyAccess() {
    if (this.reservation.admissionsTotalMax < this.reservation.admissionsDailyMax) {
      this.reservation.admissionsTotalMax = this.reservation.admissionsDailyMax;
    }

    if (this.reservation.admissionsTotalMax || this.reservation.admissionsDailyMax) {
      this.admissionsFlag = true;
    }

    this.checkAdmissions();
  }

  checkTotalAccess() {

    if (this.reservation.admissionsTotalMax < this.reservation.admissionsDailyMax) {
      this.reservation.admissionsDailyMax = this.reservation.admissionsTotalMax;
    }

    if (this.reservation.admissionsTotalMax || this.reservation.admissionsDailyMax) {
      this.admissionsFlag = true;
    }

    this.checkAdmissions();
  }

  checkAdmissions() {

    if (this.admissionsFlag) {

      let defaultCounter = 0;


      for (let product of this.reservation.order.products) {
        if (product.type === 'package') {
          defaultCounter+=this.resortData.packagesByIds[product.packageId].seats;
        }
      }

      if (!defaultCounter) {
        defaultCounter = 1;
      }

      if (!this.reservation.admissionsDailyMax) {
        this.reservation.admissionsDailyMax = defaultCounter
      }

      if (!this.reservation.admissionsTotalMax) {
        this.reservation.admissionsTotalMax = defaultCounter
      }

      this.todayAdmissions =  this.reservation.admissionsDailyMax;
      this.allAdmissions = this.reservation.admissionsTotalMax;

      for (const dayKey in this.reservation.admissionsCounter) {
        if (this.reservation.admissionsCounter[dayKey]) {
            const todayTS = (new Date().setUTCHours(0, 0, 0, 0));
            if (parseInt(dayKey) == todayTS / 1000) {
              this.todayAdmissions -= this.reservation.admissionsCounter[dayKey];
            }

            this.allAdmissions -= this.reservation.admissionsCounter[dayKey];
        }
      }
    } else {
      this.reservation.admissionsDailyMax = 0
      this.reservation.admissionsTotalMax = 0
    }

  }

  // DISCOUNTS MANAGEMENT - @todo -> move to another component
  // DISCOUNTS MANAGEMENT - @todo -> move to another component
  // DISCOUNTS MANAGEMENT - @todo -> move to another component

  updateDiscounts(discount) {
    if (discount.checked && (discount.start <= this.reservation.start && this.reservation.start <= discount.end)) {

      const discountObj = {
        id: discount.id,
        percentage: discount.percentage,
        type: 'discount'
      };

      this.reservation.discounts.push(discountObj);
    } else {
      const discountIndex = this.reservation.discounts.findIndex(dis => dis.id === discount.id);
      this.reservation.discounts.splice(discountIndex, 1);
    }

    this.updateCart(this.reservation,false);
  }

  removeDiscount(discount, index) {
      this.reservation.discounts.splice(index, 1);
      const discountFound = this.resortData.discounts.find(dis => dis.id === discount.id);
      discountFound.checked = false;
      this.updateCart(this.reservation,false);
  }

  async seasonalCheck()  {

    // console.log(JSON.parse(JSON.stringify(this.reservation.seasonTicket)))
    if (this.reservation.seasonTicket) {
      for (const interval of JSON.parse(JSON.stringify(this.reservation.dates))) {

        const offsetStart = new Date(this.resortData.openingDate * 1000).getTimezoneOffset() * 60000;
        let startDate = ((new Date(this.resortData.openingDate * 1000)).setHours(0, 0, 0) - offsetStart ) / 1000;
        let nowTS = new Date().setUTCHours(0,0,0,0);

        if (nowTS/1000 > startDate) {
          startDate = nowTS/1000;
        }

        // console.log(startDate);
        const offsetEnd = new Date(this.resortData.closingDate * 1000).getTimezoneOffset() * 60000;
        let endDate = ((new Date(this.resortData.closingDate * 1000)).setHours(0, 0, 0) - offsetEnd ) / 1000;
        startDate = JSON.parse(JSON.stringify(startDate));
        endDate = JSON.parse(JSON.stringify(endDate));
        interval.disableDates = interval.disableDates.filter((ts)=> ts > this.dataService.resortData.openingDate);
        console.log(interval.disableDates);
        const disableStartIndex = interval.disableDates.indexOf(startDate);
        if ( disableStartIndex > -1) {

          for (let i = (startDate + 24 * 60 * 60); i < endDate; i += 24 * 60 * 60) {
            if (interval.disableDates.indexOf(i) == -1) {
              startDate = i;
              break;
            }
          }
        }

        let realInterval = this.reservation.dates.find(int => int.date_key === interval.date_key);
        console.log(startDate,endDate, realInterval);
        this.reservation = this.settingsService.checkOtherReservationsAndSplitIntervals(this.reservation, startDate, endDate, realInterval);
      }
    } else if (!this.reservation.id && this.dataService.previousPage === 'home') {
        this.reservation.dates[0].start = this.dataService.startTimestamp / 1000;
        this.reservation.dates[0].startDate = new Date(this.dataService.startTimestamp);
        this.reservation.dates[0].end = this.dataService.endTimestamp / 1000;
        this.reservation.dates[0].endDate = new Date(this.dataService.endTimestamp);
        this.reservation.dates[0].length = (this.dataService.endTimestamp - this.dataService.startTimestamp) / (24 * 60 * 60 * 1000) + 1;
    }

    await this.settingsService.checkDatesIntervalsAndUpdateDisableDates(this.reservation);
    // console.log(JSON.parse(JSON.stringify(this.reservation)));
    this.updateCart(this.reservation,false);
  }

  checkSeasonalTimeslot() {
    console.log(this.seasonTicketType);
    for (let seasonalInterval of this.reservation.dates) {
      if (this.seasonTicketType === 'daily') {
        seasonalInterval.timeSlots = []
      } else if (this.seasonTicketType === 'morning') {
        console.log("MORNING");

        let now = new Date();
        let offset = now.getTimezoneOffset();
        let startSeconds = 9*60*60 + offset*60;
        let endSeconds = 13*60*60 + offset*60;
        seasonalInterval.timeSlots = [{
            endHour: "13:00",
            name: {
                "de": "",
                "en": "Morning",
                "fr": "",
                "it": "Mattina"
            },
            startHour: "09:00",
            endSeconds: endSeconds,
            startSeconds: startSeconds,
            id:1
        }]
      } else if  (this.seasonTicketType === 'afternoon') {
        console.log("AFTERNOON");

        let now = new Date();
        let offset = now.getTimezoneOffset();
        let startSeconds = 13*60*60 + offset*60;
        let endSeconds = 20*60*60 + offset*60;
        seasonalInterval.timeSlots = [{
            endHour: "20:00",
            name: {
                "de": "",
                "en": "Afternoon",
                "fr": "",
                "it": "Pomeriggio"
            },
            startHour: "13:00",
            endSeconds: endSeconds,
            startSeconds: startSeconds,
            id:2
        }]
      }
    }


    console.log(this.reservation.dates);

    this.updateCart(this.reservation,false);
  }

  map() {
    // mode = 2  -> NEW
    // mode = 1  -> MOVE
    // mode = 0 -> ADD
    this.dataService.previousPage = 'reservation';
    this.dataService.selectedReservation = this.reservation;
    this.dataService.mainCustomer = this.mainCustomer;
    this.dataService.associatedCustomers = this.associatedCustomers;

    if (!this.reservation.id) {
      this.reservation.id = 'new';
    }

    this.router.navigate(['suite/reservation/' + this.reservation.id + '/map']);

  }

  /* ------------ EXTRAs ------------ */
  /* ------------ EXTRAs ------------ */
  /* ------------ EXTRAs ------------ */

  addExtra() {
    let dialogRef = this.dialog.open(ModalExtraComponent, {
      width:'900px',
      data: {
        reservation:this.reservation,
        extra: null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if ( this.reservation.extras.length) {


          let findSameExtra =  this.reservation.extras.find(e => {

            let notEqualTimeSlots = ((e.timeSlots.length !== result.timeSlots.length) || (e.timeSlots.length && result.timeSlots.length && e.timeSlots[0].id !== result.timeSlots[0].id))
            if (e.start === result.start && e.end === result.end && e.id === result.id && !notEqualTimeSlots) {
              return e;
            }

          });
          if (findSameExtra) {
            findSameExtra.quantity+=result.quantity;
          } else {
            this.reservation.extras.push(result)
          }
        } else {
          this.reservation.extras.push(result)
        }

        this.updateCart(this.reservation,false);
      }
    });
  }

}
