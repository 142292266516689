import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { DataStatisticsService } from '../../../core/providers/data-statistics.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-statistics-home',
  templateUrl: './statistics-home.component.html',
  styleUrls: ['./statistics-home.component.scss']
})
export class StatisticsHomeComponent implements OnInit {

  resortData; managerData; pageClicked; prices; submenu; lang = 'it';elements; features;optionals;services; dataLang='it';
  lightLoading = false; occupation; maxOccupation;dailyOccupation;
  // DATE VARIABLES
  startTimestamp; endTimestamp; startSerializedDate; endSerializedDate;
  startDate; startDay; startMonth; startYear; occupationRate;
  endDate; endDay; endMonth; endYear; statistics;stockData;

  showTip1 = false; showTip2 = false; showTip3 = false; showTip4 = false; showTip5 = false; showTip6 = false; showTip7 = false; showTip8 = false;

  totalAttendance;averageStay;dayWithMoreAttendance;averageCart;totalRevenue;mostProfitableDay;totalReservations;

  constructor(private dataService: DataService, private statisticsData: DataStatisticsService, private settingsService: SettingsService) {

    /* let currentMonth = new Date().getMonth()+1;
    let currentYear = new Date().getFullYear();
    let endDay = this.settingsService.daysInMonth(currentMonth,currentYear);
    const offsetStart = new Date(currentYear+'-'+currentMonth+'-01').getTimezoneOffset() * 60000;
    const offsetEnd = new Date(currentYear+'-'+currentMonth+'-'+endDay).getTimezoneOffset() * 60000; */


  }

  async ngOnInit() {
    this.lightLoading = true;
    this.resortData =  this.dataService.resortData;
    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    
    console.log("START");
    console.log(new Date().getTime());
    
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;

    this.startTimestamp = this.resortData.openingDate * 1000;
    this.endTimestamp = this.resortData.closingDate * 1000;


    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate;

    this.dataLang = this.dataService.lang;

    await this.getStatistics();
    this.lightLoading = false;

  }

  async getStatistics() {

    /* let attendances;
    let averageStay;
    let count;
    let bills;
    let revenues;
    let employmentRate;

    [attendances, averageStay, count, bills, revenues, employmentRate] = await Promise.all([this.statisticsData.getAttendance(this.startTimestamp/1000, this.endTimestamp/1000,null,null,null,null,null), this.statisticsData.getAverageStay(this.startTimestamp/1000, this.endTimestamp/1000,null,null,null,null,null), this.statisticsData.getCount(this.startTimestamp/1000, this.endTimestamp/1000+24*60*60,null,null,null,null,null), this.statisticsData.getBill(this.startTimestamp/1000, this.endTimestamp/1000,null,null,null,null,null), this.statisticsData.getRevenues(this.startTimestamp/1000, this.endTimestamp/1000,null,null), this.statisticsData.getEmploymentRate(this.startTimestamp/1000, this.endTimestamp/1000, null, null, null, null, null, null, null)]); */

    let attendances = await this.statisticsData.getAttendance(this.startTimestamp/1000, this.endTimestamp/1000,null,null,null,null,null);
    let averageStay =await this.statisticsData.getAverageStay(this.startTimestamp/1000, this.endTimestamp/1000,null,null,null,null,null);
    let count =await this.statisticsData.getCount(this.startTimestamp/1000, this.endTimestamp/1000+24*60*60,null,null,null,null,null);
    let bills =await this.statisticsData.getBill(this.startTimestamp/1000, this.endTimestamp/1000,null,null,null,null,null);
    let revenues =await this.statisticsData.getRevenues(this.startTimestamp/1000, this.endTimestamp/1000,null,null);
    let employmentRate = await this.statisticsData.getEmploymentRate(this.startTimestamp/1000, this.endTimestamp/1000, null, null, null, null, null, null, null);

    console.log("END 1");
    console.log(new Date().getTime());

    this.totalAttendance = 0;
    this.dayWithMoreAttendance = 0;

    let max = -1;
    let maxRevenue = -1;

    this.totalReservations = count.total_reservations;
    this.averageStay = averageStay.average_stay;
    this.totalRevenue = bills.sum_bill;
    this.mostProfitableDay = 0;
    this.averageCart = bills.average_bill;

    console.log(revenues);
    let totPacks = 0;

    for (const pack in employmentRate.sellingPackages) {
      totPacks+= employmentRate.sellingPackages[pack];
    }

    for (const dayRevenue in revenues) {
      if (revenues[dayRevenue] > maxRevenue) {
        this.mostProfitableDay = dayRevenue;
        maxRevenue = revenues[dayRevenue];
      }
    }

    for (const dayAttendance in attendances) {
      if (attendances[dayAttendance] > max) {
        this.dayWithMoreAttendance = dayAttendance;
        max = attendances[dayAttendance];
      }
      this.totalAttendance+=attendances[dayAttendance];
    }


    console.log(this.totalAttendance);
    console.log(this.mostProfitableDay);
    console.log(totPacks);

    this.dailyOccupation = 0;

    for (const slot of this.dataService.map) {
      if (slot.packagesIds.length > 0 && slot.zoneId) {
        this.dailyOccupation++;
      }
    }

    this.maxOccupation = 0;
    const length = (this.endTimestamp-this.startTimestamp) / (24*60*60*1000) +1;
    this.maxOccupation = this.dailyOccupation*length
    this.occupation = 0;

    // DAILY OCCUPATION -> OK CHECKED
    console.log("DAILY", this.dailyOccupation);

    // MAX OCCUPATION -> OK CHECKED
    console.log("MAX OCCUPATION", this.maxOccupation);

    this.occupationRate = totPacks/this.maxOccupation;
    console.log(this.occupationRate);

    
    console.log("END 2");
    console.log(new Date().getTime());
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout( () => {
      this.pageClicked = false;
    }, 1000);
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.lightLoading = true;
    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp)
      this.endTimestamp = this.startTimestamp;

    let endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);
    await this.getStatistics();
    this.lightLoading = false;
  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.lightLoading = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp)
      this.startTimestamp = this.endTimestamp;

    let startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);
    await this.getStatistics();
    this.lightLoading = false;

  }

  exportPDF() {

  }

}
