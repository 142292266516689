import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/providers/data.service';
import { SettingsService } from 'src/app/core/providers/settings.service';

@Component({
  selector: 'app-tile-modal',
  templateUrl: './tile-modal.component.html',
  styleUrls: ['./tile-modal.component.scss']
})
export class TileModalComponent implements OnInit {

  selectedPackage=null;
  selectedZone=null;
  selectedTile=false;
  zones = [];
  packages=[];

  constructor(
    public settingsService:SettingsService,
    public router: Router,
    public dataService: DataService,
    public dialogRef: MatDialogRef<TileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.zoneId) {
      this.selectedTile = true;
    }
    this.selectedZone = this.data.zoneId;
    this.selectedPackage = this.data.packageId;
    this.packages = this.dataService.resortData.packages.filter((p)=>p.type === 'order')
    this.zones = this.dataService.resortData.zones;
  }

  ngOnInit() {
  }

  changePackage() {

  }

  changeZone() {

  }

  close(confirm, remove) {
    if (confirm) {
      this.dialogRef.close({packageId:this.selectedPackage,zoneId:this.selectedZone, remove});
    } else {
      this.dialogRef.close(null);
    }
  }


}
