import { StatisticsHomeComponent } from './pages/statistics-home/statistics-home.component';
import { StatisticsCocobookComponent } from './pages/statistics-cocobook/statistics-cocobook.component';
import { StatisticsDiscountsComponent } from './pages/statistics-discounts/statistics-discounts.component';
import { StatisticsExtrasComponent } from './pages/statistics-extras/statistics-extras.component';
import { StatisticsRevenuesComponent } from './pages/statistics-revenues/statistics-revenues.component';
import { StatisticsAttendanceComponent } from './pages/statistics-attendance/statistics-attendance.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthguardService } from '../core/providers/authguard.service';

const routes: Routes = [

  { path: 'suite/statistics/home', component: StatisticsHomeComponent , canActivate: [AuthguardService]},
  { path: 'suite/statistics/discounts', component: StatisticsDiscountsComponent , canActivate: [AuthguardService]},
  { path: 'suite/statistics/extras', component: StatisticsExtrasComponent , canActivate: [AuthguardService]},
  { path: 'suite/statistics/revenues', component: StatisticsRevenuesComponent , canActivate: [AuthguardService]},
  { path: 'suite/statistics/attendance', component: StatisticsAttendanceComponent , canActivate: [AuthguardService]},
  { path: 'suite/statistics/cocobook', component: StatisticsCocobookComponent , canActivate: [AuthguardService]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatsRoutingModule {}
