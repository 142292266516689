import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { ModalErrorComponent } from '../../../shared/components/modal-error/modal-error.component';
import { Observable } from 'rxjs';
import { MatDialog} from '@angular/material';
import { SettingsService } from 'src/app/core/providers/settings.service';
import { DataService } from 'src/app/core/providers/data.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  objectKeys = Object.keys;

  // GLOBAL VARIABLES
  loading = false; startTimestamp; endTimestamp; startSerializedDate; endSerializedDate; showBottomBar = false; showRightBar = false;
  startDate; endDate; loader; lang = 'it'; pageClicked = false; selectedReservation; selectedReservations; selectedSlot; selectedSlots = [];
  showQuickReservation = false; reserveFlag = true; optionals; term$ = new Subject<string>(); selectedIndex; showPackageModal;
  availablePackages; selectedPackage = null; selectedPackageIndex; selectedZone = null; selectedPackages = []; commonPackages;

  // RESORTS DATA
  resortData; managerData; mapData; reservationMapData; prices; elements; features; services;

  // MAP VARIABLES
  startMapWidth; startMapHeight; mapHeight; mapWidth; zoomStep = 1;
  slotHeight = 50; slotSpace = 15; startMarginBottomElement = 15; startPaddingLeftRight = 50;
  startOffset = 25; startPaddingTop = 150; startPaddingBottom = 100; startMinWidth = 700; startMinHeight = 700;
  startHeight = 65; startImageHeight = 30; startCircleHeight = 40; startElementHeight = 40;
  lastSlot = null; mode; maxSlots;
  startSlotLabel = 12; startMarginElement = 5; customersFound; lastMapUpdate: Observable<any>;

  // MAP VARIABLES
  grid = [];

  // RESERVATION
  newReservation; reservationTotal; reservationId; reservation; reservationSlots = [];
  newLoad = false; slotToMove; selectedTimeslot:any=0; timeslots = [];intervalTimeSlots = [];
  availableTimeslots = []; enableDailyTimeSlot = false;

  constructor(public dialog: MatDialog,  private route: ActivatedRoute, public router: Router, public dataService: DataService, private settingsService: SettingsService, private db: AngularFireDatabase) {

    if (this.route.snapshot.paramMap.get('start') && this.route.snapshot.paramMap.get('end')) {
      this.startTimestamp = parseInt(this.route.snapshot.paramMap.get('start'));
      this.endTimestamp = parseInt(this.route.snapshot.paramMap.get('end'));
      this.mode = parseInt(this.route.snapshot.paramMap.get('mode'));

      /* let todayTS = Math.trunc(new Date().setUTCHours(0,0,0,0)/1000); */

      /* if (todayTS > this.startTimestamp && todayTS < this.endTimestamp) {
        this.startTimestamp = todayTS;
      } */
    } else {
      this.mode = 2;
    }

    

  
    this.reservationId = this.route.snapshot.paramMap.get('id');

    console.log(this.mode);

    // 2 -> NUOVO PERIODO
    // 1 -> SPOSTA
    // 0 -> AGGIUNGI (DEPRECATO)

    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.mapData = this.dataService.map;
    this.prices = this.dataService.prices;
    this.optionals = this.dataService.optionals;
    this.lang = this.dataService.lang;

   }

  async ngOnInit() {

    if (this.dataService.previousPage === 'reservation' || !this.mapData) {
      this.newLoad = true;
      this.loading = true;
    }

    if (!this.resortData) {

      await this.dataService.initResortData();

      this.resortData =  this.dataService.resortData;
      this.managerData =  this.dataService.userData;
      this.optionals =  this.dataService.optionals;
      this.prices =  this.dataService.prices;
      this.mapData =  this.dataService.map;
      this.services = this.dataService.packagesServices;
      this.features = this.dataService.features;
      this.elements = this.dataService.elements;
    }

    this.zoomStep = this.resortData.zoom;
    this.startMapWidth = (this.resortData.cols * 50 + (this.resortData.cols  - 1) * 15);
    this.startMapHeight = (this.resortData.rows * 50 + (this.resortData.rows - 1) * 15);


    if (!this.dataService.selectedReservation) {
      this.reservation = await this.dataService.getSingleReservation(this.reservationId);
    } else {
      this.reservation = this.dataService.selectedReservation;
    }

    if (this.route.snapshot.paramMap.get('slotOrPackageId') && this.route.snapshot.paramMap.get('slotOrPackageId').indexOf('_') > -1) {
      this.slotToMove = this.route.snapshot.paramMap.get('slotOrPackageId');
    } else {

      if (this.route.snapshot.paramMap.get('slotOrPackageId') && this.route.snapshot.paramMap.get('slotOrPackageId') != 'null') {
        this.selectedPackage= parseInt(this.route.snapshot.paramMap.get('slotOrPackageId'));
      }

      const findInterval = this.reservation.dates.find(res => res.start == this.startTimestamp && res.end == this.endTimestamp);
      if (findInterval) {
        for (let order of findInterval.order) {
          if (order.type === 'slot') {
            if (this.selectedPackage != null) {
              if (order.packageKey === this.selectedPackage) {
                //this.selectedSlots.push(order.row + '_' + order.col);
                this.reservationSlots.push(order.row+'_'+order.col);
              }
            } else {
              //this.selectedSlots.push(order.row + '_' + order.col);
              this.reservationSlots.push(order.row+'_'+order.col);
            }
          }
        }
      } else {
        
        const findInternalInterval = this.reservation.dates.find(res => (res.start <= this.startTimestamp && res.end >= this.endTimestamp));
        console.log(this.reservation.dates, this.startTimestamp,this.endTimestamp);
        if (findInternalInterval) {
          for (let order of findInternalInterval.order) {
            if (order.type === 'slot') {
                this.selectedSlots.push(order.row + '_' + order.col);
                this.reservationSlots.push(order.row+'_'+order.col);
            }
          }
        }
      }
    }
    console.log("selectedSlots ", JSON.parse(JSON.stringify(this.selectedSlots)));

    /* MODE 2 -> MOVE SLOTS ON THE OVERALL INTERVAL */

    if (this.mode === 2) {
      this.startTimestamp = this.reservation.start;
      this.endTimestamp = this.reservation.end;
      const startDate = new Date(this.startTimestamp*1000);
      startDate.setUTCHours(0, 0, 0, 0);
      this.startSerializedDate = startDate;

      const endDate = new Date(this.endTimestamp*1000);
      endDate.setUTCHours(0, 0, 0, 0);
      this.endSerializedDate = endDate;
    }

    this.reservationMapData = await this.dataService.getMapReservations(this.startTimestamp, this.endTimestamp);
    console.log(this.reservationMapData);
    this.getTimeslots();
    console.log("reservationSlots ", JSON.parse(JSON.stringify(this.reservationSlots)));
    this.setupGrid();

    console.log(this.reservation);


    this.db.object('realtimeMap/' + this.resortData.id).valueChanges().subscribe(data => {
      console.log(data);
      this.updateMap(data);
    });
    console.log("mode ", JSON.parse(JSON.stringify(this.mode)));
    console.log("selectedSlots ", JSON.parse(JSON.stringify(this.selectedSlots)));
    console.log("reservationSlots ", JSON.parse(JSON.stringify(this.reservationSlots)));

    if (this.mode == 1 && this.selectedSlots.length === 0) {
      this.maxSlots = this.reservationSlots.length;
      this.checkTempSlotsPushed();
    } else if (this.selectedSlots.length){
      this.maxSlots = this.selectedSlots.length;
    } else{
      this.maxSlots = 1000;
      this.checkTempSlotsPushed();
    }

    console.log("selectedSlots ", JSON.parse(JSON.stringify(this.selectedSlots)));
    this.loading = false;


  }

  checkTempSlotsPushed() {
    for (let interval of this.reservation.dates) {
      if (interval.start >= this.startTimestamp && interval.end <= this.endTimestamp) {
        for (let order of interval.order) {
          if (!order.id && order.type === 'slot') {
            if (this.maxSlots !== 1000) {
              this.grid[order.row][order.col].selected = false;
            } else {
              this.grid[order.row][order.col].zoneId = 0;
            }
          }
        }
      }
    }
  }

  getTimeslots() {
    this.availableTimeslots = [];
    let length = (this.endTimestamp- this.startTimestamp)/(60*60*24) + 1
    console.log(length);
    let seasonsFound = this.dataService.getSeasonsInReservation(this.startTimestamp,length,null);
    console.log(seasonsFound);
    this.enableDailyTimeSlot = true;

    let daysInInterval = this.settingsService.getDaysFromInterval(this.startTimestamp, this.endTimestamp);
    console.log(daysInInterval);
    if (seasonsFound && Object.keys(seasonsFound).length === 1) {
      for (let seasonKey in seasonsFound) {
        if (this.settingsService.checkIfTimeslotsAreEnabled(seasonsFound[seasonKey], daysInInterval)) {
          this.availableTimeslots = this.resortData.timeSlotsBySeason[seasonKey];
        } else {
          this.availableTimeslots = [];
        }
      }
    }

   /*  if (this.intervalTimeSlots && this.intervalTimeSlots.length) {
      let selectedTimeslot = this.intervalTimeSlots[0];
      if (this.availableTimeslots.find(t => t.id === selectedTimeslot.id)) {
      } else {
        this.availableTimeslots.push(selectedTimeslot);
      }

      this.selectedTimeslot = selectedTimeslot.id;
    } */


    for (const interval of this.reservation.dates) {
      // SE ESISTE UN INTERVALLO CON STESSE DATE ALLORA FORZA IL TIMESLOT A QUELLO DELL'INTERVALLO GIA' PRESENTE
      if (interval.start == this.startTimestamp && interval.end == this.endTimestamp && interval.order && interval.order.length) {
        if (!interval.timeSlots.length) {
          this.enableDailyTimeSlot = true;
          this.selectedTimeslot = 0;
        } else {
          this.enableDailyTimeSlot = false;
          this.selectedTimeslot = interval.timeSlots[0].id;
        }

        this.availableTimeslots = interval.timeSlots
        break;
      } else {
        // ALTRIMENTI FORZA A GIORNALIERO
        this.selectedTimeslot = 0;
      }

    }

  }

  updateTimeslot() {
    console.log(this.selectedTimeslot)
    this.intervalTimeSlots = []
    let now = new Date();

    if (this.selectedTimeslot) {
      let findTS = this.availableTimeslots.find(t => t.id === this.selectedTimeslot);
      let offset = now.getTimezoneOffset();
      let startSeconds = parseInt(findTS.startHour.split(":")[0])*60*60 + parseInt(findTS.startHour.split(":")[1])*60 + offset*60;
      let endSeconds = parseInt(findTS.endHour.split(":")[0])*60*60 + parseInt(findTS.endHour.split(":")[1])*60 + offset*60;
      findTS.startSeconds = startSeconds;
      findTS.endSeconds = endSeconds;
      findTS.utcOffset = -offset/60;
      this.intervalTimeSlots.push(findTS);
    }
    console.log(this.reservation)
  }

  setupGrid() {

    this.grid = [];
    if (this.mode === 2 || this.selectedSlots.length) {
      this.reservationSlots = [];
      for (const interval of this.reservation.dates) {
        if ((interval.start <= this.startTimestamp && interval.end >= this.startTimestamp) || (interval.start >= this.startTimestamp && interval.end <= this.endTimestamp) || (interval.start <= this.endTimestamp && interval.end >= this.endTimestamp)) {
          for (const slot of interval.order) {
            if (this.selectedPackage == null || slot.packageKey === this.selectedPackage) {
              this.reservationSlots.push(slot.row+'_'+slot.col);
            }
          }
        }
      }
    } else if (this.mode === 1 && this.slotToMove) {
      this.reservationSlots.push(this.slotToMove)
    }

    console.log("RESERVATION SLOTS", JSON.parse(JSON.stringify(this.reservationSlots)));
    console.log("PACKAGE ID ", JSON.parse(JSON.stringify(this.selectedPackage)));

    let index = 0;

    for (let row = 0; row < this.resortData.rows; row++) {
      for (let col = 0; col < this.resortData.cols; col++) {


        const rowIndex = row.toString();
        const colIndex = col.toString();

        const foundSlot = this.mapData.find(slot => slot.row == row && slot.col == col);

        if (!this.grid[rowIndex]) {
          this.grid[rowIndex] = {};
        }

        if (!this.grid[rowIndex][colIndex]) {
          this.grid[rowIndex][colIndex] = {};
        }

        if (foundSlot) {
          this.grid[rowIndex][colIndex] = JSON.parse(JSON.stringify(foundSlot));
          this.grid[rowIndex][colIndex].oldZoneId = JSON.parse(JSON.stringify(this.grid[rowIndex][colIndex].zoneId));
        }

        this.grid[rowIndex][colIndex].selected = false;
        this.grid[rowIndex][colIndex].matrixIndex = index;
        this.grid[rowIndex][colIndex].startingHeight = this.startHeight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].paddingLeftRight = this.startPaddingLeftRight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].slotElementHeight = this.startHeight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].slotLabel = 'AAA';
        this.grid[rowIndex][colIndex].reservationsKeys = false;
        this.grid[rowIndex][colIndex].state = 1;
        this.grid[rowIndex][colIndex].elementId = null;

        const isReservationSlot = (this.reservationSlots.indexOf(rowIndex + '_' + colIndex) > -1);
        const isReserved = (this.reservationMapData[rowIndex] && this.reservationMapData[rowIndex][colIndex]) || (foundSlot && this.selectedPackage && this.grid[rowIndex][colIndex].packagesIds.indexOf(this.selectedPackage) == -1);

        if (isReservationSlot) {
          this.grid[rowIndex][colIndex].state = 2;
          this.grid[rowIndex][colIndex].selected = true;
        } else if (isReserved) {
          if (this.selectedTimeslot) {

            for (let reservation of this.reservationMapData[rowIndex][colIndex].reservationsKeys) {
              if (!reservation.timeSlot || !reservation.timeSlot.id || reservation.timeSlot.id === this.selectedTimeslot) {
                this.grid[rowIndex][colIndex].zoneId = null;
              } else {
                this.reservationMapData[rowIndex][colIndex] = null;
              }
            }
          } else {
            this.grid[rowIndex][colIndex].zoneId = null;
          }

        }

        this.settingsService.setSlotElement(this.grid[rowIndex][colIndex], this.resortData);

        if (isReservationSlot) {
          
          console.log(this.grid[rowIndex][colIndex]);
        }
        index++;
      }

    }

  }

  ngOnDestroy() {
    this.selectedSlots = [];
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout(() => {
      this.pageClicked = false;
    }, 1000)
  }

  resetMap() {

  }

  updateMap(data) {
    // CHECK IF THE UPDATE IS ON THE SELECTED DATES
    if ((data.startTimestamp <= this.startTimestamp && data.endTimestamp <= this.endTimestamp ) || (data.startTimestamp >= this.startTimestamp  && data.endTimestamp <= this.endTimestamp ) || (data.endTimestamp <= this.startTimestamp && data.endTimestamp >= this.endTimestamp )) {
        // FOR EVERY SLOT UPDATE THE STATE
        if (data.slots) {
          for (const slot of data.slots) {

            const rowKey = slot.split('_')[0];
            const colKey = slot.split('_')[1];

            if (data.state !== 1) {
              if (!this.reservationMapData[rowKey]) {
                this.reservationMapData[rowKey] = {};
              }

              if (!this.reservationMapData[rowKey][colKey]) {
                this.reservationMapData[rowKey][colKey] = {};
              }

              this.reservationMapData[rowKey][colKey].state = data.state;
            } else {
                delete this.reservationMapData[rowKey][colKey];
            }

          }
        }
    }
  }

  async saveReservation() {
    console.log("SELECTED SLOTS", this.selectedSlots);
    console.log("RESERVATION SLOTS", this.reservationSlots);

    const checkedSlots = [];

    // I NUOVI SLOTS PUSHALI CON PACCHETTO DI DEFAULT
    console.log("INTERVALS", this.reservation.dates);

    // SE L'INTERVALLO INIZIA ESATTAMENTE DA STARTIMESTAMP -> MODIFICA POSTAZIONE INTERVALLO
    const findInterval = this.reservation.dates.find(res => res.start == this.startTimestamp && res.end == this.endTimestamp);
    let selectedInterval = null;

    if (findInterval) {
      selectedInterval = JSON.parse(JSON.stringify(findInterval));
    }

    if (selectedInterval) {
      console.log("SELECTED INTERVAL ", selectedInterval);
      selectedInterval.timeSlots = this.intervalTimeSlots
      for (const slot of this.selectedSlots) {

        const row = slot.split('_')[0];
        const col = slot.split('_')[1];

        if (this.reservationSlots.indexOf(slot) === -1) {
          let packageKey = parseInt(this.grid[row][col].defaultPackId);

          if (this.selectedPackage && this.grid[row][col].packagesIds.indexOf(this.selectedPackage) > -1) {
            packageKey = this.selectedPackage;
          }


          let optionalsKeys = {};
          let checkin = [];

          if (this.mode == 1) {
            const sameSlot = selectedInterval.order.find(slot =>  this.grid[row][col].packagesIds.indexOf(slot.packageKey) > -1 && checkedSlots.indexOf(slot.slot_key) == -1 );

            if (sameSlot) {
              packageKey = sameSlot.packageKey;
              optionalsKeys = sameSlot.optionalsKeys;
              checkedSlots.push(sameSlot.slot_key);
              if (sameSlot.checkin) {
                checkin = sameSlot.checkin;
              }
            }
          }

          if (!selectedInterval.order.find(sl => sl.col === parseInt(col) && sl.row === parseInt(row))) {
            console.log("PUSH SLOT");
            selectedInterval.order.push({
              col: parseInt(col),
              row: parseInt(row),
              type: 'slot',
              coords: this.grid[row][col].label,
              zoneKey: this.grid[row][col].zoneId,
              slot_key: slot,
              packageKey,
              checkin,
              optionalsKeys
            });

          }
        }
      }

      console.log(JSON.parse(JSON.stringify(selectedInterval)));
      let findInt = this.reservation.dates.find(d => d.date_key === selectedInterval.date_key);
      findInt.order = selectedInterval.order;
      console.log(JSON.parse(JSON.stringify(this.reservation.dates)));
    } else {
      const newInterval = {
        start: this.startTimestamp,
        end: this.endTimestamp,
        optionalsAmount: 0,
        optionalsCounter: 0,
        length: (this.endTimestamp - this.startTimestamp)/(24*60*60) +1,
        intervalAmount: 0,
        dateKey: this.startTimestamp + '_' + this.endTimestamp,
        order: [],
        timeSlots:this.intervalTimeSlots
      }

      for (const slot of this.selectedSlots) {
        const row = slot.split('_')[0];
        const col = slot.split('_')[1];
        newInterval.order.push({
          col: parseInt(col),
          row: parseInt(row),
          type: 'slot',
          coords: this.grid[row][col].label,
          zoneKey: this.grid[row][col].zoneId,
          slot_key: slot,
          packageKey: this.grid[row][col].defaultPackId,
          optionalsKeys: {}
        });
      }
      console.log(newInterval);
      this.reservation.dates.push(newInterval);
    }


    // ELIMINA IL VECCHIO SLOT DA SPOSTA

    if (this.mode == 1) {
      for (const slot of this.reservationSlots) {
        const row = slot.split('_')[0];
        const col = slot.split('_')[1];

        if (this.selectedSlots.indexOf(slot) === -1) {
            const selectedInterval = this.reservation.dates.find(res => res.start == this.startTimestamp && res.end == this.endTimestamp);
            selectedInterval.order = selectedInterval.order.filter(obj => {
              return obj.slot_key !== slot;
            });
        }
      }
    }

    console.log(JSON.parse(JSON.stringify(this.reservation.dates)));
    this.dataService.selectedReservation = this.reservation;
    this.dataService.slotsToRemove = this.reservationSlots;

    for (let interval of this.reservation.dates) {
      interval.length = (interval.end - interval.start)/(24*60*60) +1
    }

    this.settingsService.updateCart(this.reservation,false);

    if (this.dataService.previousPage === 'reservation' || !this.dataService.previousPage ) {
      this.dataService.backFromMap = true;
      this.router.navigate(['suite/reservation/' + this.reservation.id]);
    } else {
      console.log(this.reservation);
      this.loading=true;
      let httpCall = null;
      this.reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
      this.reservation.updatedBy = this.settingsService.getCurrentUserData()

      this.reservation.version = 4;

      httpCall = await this.dataService.updateReservation(this.reservation).catch(err =>{
        return err;
      });

      if (httpCall.error) {

        let error = httpCall.error;

        if (httpCall.error.error) {
          error = httpCall.error.error;
        }

        this.loading=false;
        const dialogRef = this.dialog.open(ModalErrorComponent, {
          width: '400px',
          data: {
            type: 'reservation-error',
            error: error
          }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
      } else {

        if (httpCall) {
          this.reservation.id = httpCall.id;
          this.reservation.reservationCode = httpCall.reservationCode;
        }

        this.loading=false;
        this.router.navigate(['suite/home']);
      }

    }

  }

  toggleSlot(slot) {
    const isReservationSlot = (this.reservationSlots.indexOf(slot.row + '_' + slot.col) > -1);
    const isReserved = (this.reservationMapData[slot.row] && this.reservationMapData[slot.row][slot.col]);
    console.log(this.selectedSlots.length, this.maxSlots);
    console.log(isReservationSlot);
    /* if (!(isReservationSlot && this.mode == 1)) { */

      /* if ( ((isReservationSlot || !isReserved ) && (this.mode === 1 || this.mode === 2)) || (!isReserved && this.mode === 0) )  { */
        if (!slot.selected && this.selectedSlots.length < this.maxSlots) {

          this.selectedSlot = slot;
          this.selectedSlots.push(slot.row + '_' + slot.col);
          if (this.selectedSlot && (this.mode === 0 || this.mode === 2)) {
            this.showBottomBar = true;
          }

          if (this.selectedSlots.length === 0 && (this.mode === 0 || this.mode === 2)) {
            this.showBottomBar = false;
          }

          slot.selected = !slot.selected;
        } else if (slot.selected) {

          this.selectedSlot = null;
          const index = this.selectedSlots.indexOf(slot.row + '_' + slot.col)
          this.selectedSlots.splice(index, 1);

          if (this.selectedSlot && (this.mode === 0 || this.mode === 2)) {
            this.showBottomBar = true;
          }

          if (this.selectedSlots.length === 0 && (this.mode === 0 || this.mode === 2)) {
            this.showBottomBar = false;
          }

          slot.selected = !slot.selected;
        }

      /* } */

   /*  } */
  }

  zoomPlus() {
    this.zoomStep = this.zoomStep + 0.25;
  }

  zoomMinus() {
    this.zoomStep = this.zoomStep - 0.25;
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now)) {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    const startTS = (new Date(event.value)).getTime() - offset;
    const startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0)/1000;
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.endTimestamp = this.startTimestamp;
    }


    const endDate = new Date(this.endTimestamp*1000);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0)/1000;
    this.endSerializedDate = new Date(this.endTimestamp*1000);
    this.selectedSlots = [];
    this.reservationMapData = await this.dataService.getMapReservations(this.startTimestamp, this.endTimestamp);
    this.getTimeslots();
    this.setupGrid();
  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.loader = true;

    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    const endTS = (new Date(event.value)).getTime() - offset;
    const endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0)/1000;
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.startTimestamp = this.endTimestamp;
    }

    const startDate = new Date(this.startTimestamp*1000);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0)/1000;
    this.startSerializedDate = new Date(this.startTimestamp*1000);
    this.selectedSlots = [];

    this.reservationMapData = await this.dataService.getMapReservations(this.startTimestamp, this.endTimestamp);
    this.getTimeslots();
    this.setupGrid();

  }

  async setTodayDate() {
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0)/1000;
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0)/1000;
    const startDate = new Date(this.startTimestamp*1000);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startDate = startDate.getDate();

    const endDate = new Date(this.endTimestamp*1000);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endDate = endDate.getDate();

    this.startSerializedDate = new Date(this.startTimestamp*1000);
    this.endSerializedDate = new Date(this.endTimestamp*1000);
    this.selectedSlots = [];

    this.reservationMapData = await this.dataService.getMapReservations(this.startTimestamp, this.endTimestamp);
    this.getTimeslots();
    this.setupGrid();

  }

}
