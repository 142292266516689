import { Injectable } from '@angular/core';
import * as jsPDF from 'jspdf';
import { DataService } from './data.service';
import 'jspdf-autotable';
import { SettingsService } from './settings.service';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(public dataService: DataService, public settingsService: SettingsService) { }

  async exportFreeDates(dates, reservation, mainCustomer) {

    for (const key in dates) {
      let slotsIds = '';
      let slotsKeys = '';
      for (let slot of dates[key].slots) {

        if (slotsIds == '') {
          slotsIds += this.dataService.mapByRowCol[slot.row][slot.col].id.toString();
          slotsKeys += slot.row+'_'+slot.col;
        }
        else {
          slotsIds += ','+this.dataService.mapByRowCol[slot.row][slot.col].id.toString();
          slotsKeys += ','+slot.row+'_'+slot.col;
        }
        /* let reservation = await this.dataService.getReservationsBySlots(dates[key].ts,dates[key].ts+24*60*60,slotKey)
        console.log(reservation); */
      }
      let reservation = await this.dataService.getReservationsBySlots(dates[key].ts,(dates[key].ts+24*60*60),slotsKeys);
      dates[key].reservations = reservation.results;
    }

    const doc = new jsPDF('p', 'mm', 'a4');

    const self = this;

    doc.setFont('helvetica');
    doc.setFontSize(16);

    doc.text('Date liberate - Prenotazione ' + reservation.reservationCode + ' | ' + mainCustomer.name + ' ' + mainCustomer.surname, 15, 15);

    const title = 'Postazioni';

    const columns = [
      {
        dataKey: 'dates', title: 'Date'
      },

      {
        dataKey: 'slots', title: 'Postazioni'
      },
      {
        dataKey: 'notes', title: 'Note'
      },
      {
        dataKey: 'reservations', title: 'Rivendita'
      }
    ];

    const columnStyles = {
      'dates' : {
        dataKey: 'dates',cellWidth: 50,valign: 'top', overflow: 'linebreak'

      },
      'slots': {
        dataKey: 'slots',cellWidth: 'auto',valign: 'top', overflow: 'linebreak'
      },
      'notes': {
        dataKey: 'notes',cellWidth: 'auto',valign: 'top', overflow: 'linebreak'
      },
      'reservations': {
        dataKey: 'reservations',cellWidth: 'auto',valign: 'top', overflow: 'linebreak'
      }
    };


    /*
    ------------------------ WIDTH --------> 180 --------------------------
    name: {valign: 'top', overflow: 'linebreak', cellWidth: 40, fontSize: 8, cellPadding: 1},
    state: {valign: 'top', cellWidth: 75, fontSize: 8, cellPadding: 1},
    date: {valign: 'top', overflow: 'linebreak', cellWidth: 30, fontSize: 8, cellPadding: 1},
    payment: {valign: 'top', cellWidth: 10, fontSize: 8, cellPadding: 1},
    reservationType: {valign: 'top', cellWidth: 25, fontSize: 8, cellPadding: 1}, */


    const rows = [];
    const excel = [];

    doc.setFontSize(9);

    let total = 0;
    let totalPaid = 0;
    for (const key in dates) {

      let slotRelease = dates[key];
      let slots = '';
      let notes = '';
      let reservations = '';

      if (slotRelease.infos) {
        notes = slotRelease.infos;
      }

      for (let slot of slotRelease.slots) {
        slots+=' '+slot.label;
      }

      let rowTotal = 0;
      for (let res of slotRelease.reservations) {

        let singleTotal = 0;
        if (res.reservationCode !== reservation.reservationCode) {

          let findInterval = res.dates.find(i => i.start <= slotRelease.ts && i.end >= slotRelease.ts);

          if (findInterval) {
            if (reservations !== '') {
              reservations+='\n';
            }
            console.log(res);

            let settledString = 'NON PAGATA';

            if (res.order.settled) {
              settledString = 'PAGATA';
            }

            for (let order of findInterval.order) {
              if (slots.indexOf(order.coords) > -1) {
                console.log(order.price, findInterval.length);
                total+=(order.price/findInterval.length);
                singleTotal+=(order.price/findInterval.length);
                rowTotal+=(order.price/findInterval.length);

                if (res.order.settled) {
                  totalPaid+=(order.price/findInterval.length);
                }
              }
            }

            reservations+='Prenotazione '+res.reservationCode+ ' - ' ;
            reservations+= '€ '+ (Math.trunc((singleTotal)*100)/100).toFixed(2).replace('.',',');
            reservations+=' ('+settledString+')';

            /* total+=(findInterval.intervalAmount/findInterval.length);
            singleTotal+=(findInterval.intervalAmount/findInterval.length); */
          }

        }
      }


      if (reservations == '') {
        reservations = '--';
      }

      const obj = {
        dates: this.settingsService.days['it'][slotRelease.day] + ' ' + slotRelease.date + ' ' + this.settingsService.months['it'][slotRelease.month],
        slots,
        notes,
        reservations
      };

      const objExcel = {
        date: this.settingsService.days['it'][slotRelease.day] + ' ' + slotRelease.date + ' ' + this.settingsService.months['it'][slotRelease.month],
        postazioni:slots,
        note: notes,
        prenotazioni:reservations,
        totale:rowTotal
      }

      excel.push(objExcel);
      rows.push(obj);
    }


    let totalString = Math.trunc((total*100)/100).toFixed(2);
    /* let lastObj = {
      dates: '',
      slots: '',
      notes: '',
      reservations: 'TOTALE RIVENDITA: € ' + totalString+''
    }

    rows.push(lastObj) */


    let margin = {
      top: 27
    };

    doc.autoTable(columns, rows, {
      headStyles : {
        fillColor: [0, 115, 203],
        textColor: [255, 255, 255]
      },
      margin,
      styles: {overflow: 'linebreak', cellWidth: 'wrap'},
      bodyStyles: {valign: 'top'},
      columnStyles,
      didDrawPage : data => {}
    });

    let totalPaidString = Math.trunc((totalPaid*100)/100).toFixed(2);


    doc.setFont('helvetica');
    doc.setFontSize(16);
    doc.text('Totale Rivendita: € ' + totalString.replace('.',',') + ' di cui € '+ totalPaidString.replace('.',',') + ' pagati', 15, 23);

    doc.save('Date liberate - Prenotazione ' + reservation.reservationCode  + ' - ' + mainCustomer.name + ' ' + mainCustomer.surname);

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excel);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');


    XLSX.writeFile(wb, 'Date liberate - Prenotazione ' + reservation.reservationCode  + ' - ' + mainCustomer.name + ' ' + mainCustomer.surname + '.xlsx');
  }

  exportCustomerBill(reservation,mainCustomer) {

    let customerBill = JSON.parse(JSON.stringify(reservation.order.customerBill));

    customerBill.sort(this.sortByDate);
    const doc = new jsPDF('p', 'mm', 'a4');

    const self = this;

    doc.setFont('helvetica');
    doc.setFontSize(16);



    const date = new Date();

    let dayStart = date.getDate().toString();
    let monthStart = (date.getMonth() + 1).toString();

    if (date.getDate() < 10) {
      dayStart = '0' + dayStart;
    }

    if ((date.getMonth() + 1) < 10) {
      monthStart = '0' + monthStart;
    }

    doc.text('Prenotazione ' + reservation.reservationCode + ' | Conto Cliente al '+dayStart+'/'+monthStart+' | ' + mainCustomer.name + ' ' + mainCustomer.surname, 15, 15);

    const title = 'Conto Cliente';


    const columns = [
      {
        dataKey: 'date', title: 'Data'
      },

      {
        dataKey: 'text', title: 'Causale'
      },

      {
        dataKey: 'price', title: 'Importo'
      },
      {
        dataKey: 'type', title: 'Tipologia'
      }
    ];

    const columnStyles = {
      'date' : {
        dataKey: 'date',cellWidth: 50,valign: 'top', overflow: 'linebreak'

      },
      'text': {
        dataKey: 'text',cellWidth: 'auto',valign: 'top', overflow: 'linebreak'
      },
      'price': {
        dataKey: 'price',cellWidth: 'auto',valign: 'top', align:'right', overflow: 'linebreak'
      },
      'type': {
        dataKey: 'type',cellWidth: 50,valign: 'top', overflow: 'linebreak'
      }
    };

    const rows = [];

    doc.setFontSize(9);

    for (const bill of customerBill) {

      let type = 'Spesa';

      if (bill.payed) {
        type = 'Acconto/Pagamento'
      }



      const date = new Date(bill.date * 1000);

      let dayStart = date.getDate().toString();
      let monthStart = (date.getMonth() + 1).toString();

      if (date.getDate() < 10) {
        dayStart = '0' + dayStart;
      }

      if ((date.getMonth() + 1) < 10) {
        monthStart = '0' + monthStart;
      }


      const from = dayStart + '/' + monthStart;
      let price = bill.price;

      if (!bill.payed) {
        price = -price;
      }

      const obj = {
        date: this.settingsService.days['it'][date.getDay()] + ' ' + dayStart + ' ' + this.settingsService.months['it'][(date.getMonth())],
        text: bill.name,
        price: '€ '+price,
        type
      };


      rows.push(obj);
    }

    rows.push({
      date:'',
      text:'TOTALE RESIDUO',
      price: '€ '+reservation.order.customerBillTotal,
      type:''
    })


    let margin = {
      top: 20
    };

    doc.autoTable(columns, rows, {
      headStyles : {
        fillColor: [0, 115, 203],
        textColor: [255, 255, 255]
      },
      margin,
      styles: {overflow: 'linebreak', cellWidth: 'wrap'},
      bodyStyles: {valign: 'top'},
      columnStyles,
      didDrawPage : data => {}
    });

    doc.save('Conto Cliente - Prenotazione ' + reservation.reservationCode  + ' - ' + mainCustomer.name + ' ' + mainCustomer.surname);

  }

  exportPDF(reservations, startTimestamp, endTimestamp, filters, orderBy, fields,isBooking) {

    console.log(filters);

    const doc = new jsPDF('p', 'mm', 'a4');

    const self = this;

    doc.setFont('helvetica');
    doc.setFontSize(16);

    const startYear = new Date(startTimestamp);
    const startMonth = new Date(startTimestamp).getMonth() + 1;
    const startDay = new Date(startTimestamp).getDate();
    const endMonth = new Date(endTimestamp).getMonth() + 1;
    const endDay = new Date(endTimestamp).getDate();
    doc.text('Elenco Prenotazioni dal ' + startDay + '/' + startMonth + ' al ' + endDay + '/' + endMonth, 15, 15);
    console.log('Elenco Prenotazioni dal ' + startDay + '/' + startMonth + ' al ' + endDay + '/' + endMonth);

    const title = 'Postazioni';

    /* if (this.owner.subscriptionType == 0) {
      title = 'Pacchetti';
    } */

    let columnsKeys = {
      name: {
        it: 'Nome',
        en: 'Name'
      },
      phone: {
        it: 'Telefono',
        en: 'Phone'
      },
      slots: {
        it: 'Postazioni',
        en: 'Slots'
      },

      packages: {
        it: 'Pacchetti',
        en: 'Packages'
      },
      date: {
        it: 'Date',
        en: 'Dates'
      },
      payment: {
        it: 'Pagato',
        en: 'Paid'
      },

      total: {
        it: 'Importo',
        en: 'Total'
      },
      paid: {
        it: 'Incassato',
        en: 'Paid'
      },
      reservationType: {
        it: 'Tipo',
        en: 'Type'
      },
      notes : {
        it: 'Note',
        en: 'Notes'
      },
      extras : {
        it: 'Pacchetti extra',
        en: 'Extras'
      }
    }

    const columns = [
    ];

    const columnStyles = {};
    const maxWidth = 180;

    let fieldsToShow = 0;

    for (const col in fields) {
      if (fields[col] === true) {
        fieldsToShow++;
      }
    }
    const unity = 180/fieldsToShow;

    if (fields.name) {
      columns.push({
        dataKey: 'name', title: columnsKeys.name[this.dataService.lang]
      });

      if (!isBooking) {

        columnStyles['name'] = {
          valign: 'top', cellWidth: 'auto',overflow: 'linebreak',  fontSize: 8, cellPadding: 1
        };
      }

      if (isBooking) {

        columnStyles['name'] = {
          valign: 'top', cellWidth: 50,overflow: 'linebreak',  fontSize: 8, cellPadding: 1
        };
      }
    }

    if (fields.slots && !isBooking) {
      columns.push({
        dataKey: 'slots', title: columnsKeys.slots[this.dataService.lang]
      });

      if (fields.name && fields.notes)  {

        columnStyles['slots'] = {
          valign: 'top', cellWidth: 50, overflow: 'linebreak',  fontSize: 8, cellPadding: 1
        };
      } else {

        columnStyles['slots'] = {
          valign: 'top', cellWidth: 70, overflow: 'linebreak',  fontSize: 8, cellPadding: 1
        };
      }
    }

    if (fields.slots && isBooking) {
      columns.push({
        dataKey: 'slots', title: columnsKeys.packages[this.dataService.lang]
      });

      columnStyles['slots'] = {
        valign: 'top', cellWidth: 'auto', overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      };
    }

    if (fields.date) {
      columns.push({
        dataKey: 'date', title: columnsKeys.date[this.dataService.lang]
      });

      columnStyles['date'] = {
        valign: 'top', cellWidth: 'auto',overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      };
    }

    if (fields.paid && !isBooking) {
      columns.push({
        dataKey: 'payment', title: columnsKeys.payment[this.dataService.lang]
      });

      columnStyles['payment'] = {
        valign: 'top',cellWidth: 'auto', overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      }
    }

    if (fields.settled && !isBooking) {
      columns.push({
        dataKey: 'amountPayed', title: columnsKeys.paid[this.dataService.lang]
      });

      columnStyles['amountPayed'] = {
        valign: 'top',cellWidth: 'auto', overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      }
    }

    if (fields.amount) {
      columns.push({
        dataKey: 'amountTotal', title: columnsKeys.total[this.dataService.lang]
      });

      columnStyles['amountTotal'] = {
        valign: 'top',cellWidth: 'auto', overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      }
    }

    if (fields.type || (isBooking && fields.refunds)) {
      columns.push({
        dataKey: 'reservationType', title: columnsKeys.reservationType[this.dataService.lang]
      });

      columnStyles['reservationType'] = {
        valign: 'top', cellWidth: 25,overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      }
    }

    if (fields.notes) {
      columns.push({
        dataKey: 'notes', title: columnsKeys.notes[this.dataService.lang]
      });

      columnStyles['notes'] = {
        valign: 'top', cellWidth: 'auto', overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      }
    }


    /*
    ------------------------ WIDTH --------> 180 --------------------------
    name: {valign: 'top', overflow: 'linebreak', cellWidth: 40, fontSize: 8, cellPadding: 1},
    state: {valign: 'top', cellWidth: 75, fontSize: 8, cellPadding: 1},
    date: {valign: 'top', overflow: 'linebreak', cellWidth: 30, fontSize: 8, cellPadding: 1},
    payment: {valign: 'top', cellWidth: 10, fontSize: 8, cellPadding: 1},
    reservationType: {valign: 'top', cellWidth: 25, fontSize: 8, cellPadding: 1}, */


    const rows = [];


    doc.setFontSize(9);

    console.log(reservations);

    for (const reservation of reservations) {


      if ((!reservation.refundState && !fields.refunds) || fields.refunds) {

        const splitName = doc.splitTextToSize(reservation.name, 180);
        let state = 'Non arrivato';
        let reservationType = 'Gestionale';
        let payment = 'SI';

        const fromDate = new Date(reservation.start * 1000);
        const toDate = new Date(reservation.end * 1000);

        let dayStart = fromDate.getDate().toString();
        let monthStart = (fromDate.getMonth() + 1).toString();

        let dayEnd = toDate.getDate().toString();
        let monthEnd = (toDate.getMonth() + 1).toString();

        if (fromDate.getDate() < 10) {
          dayStart = '0' + dayStart;
        }

        if ((fromDate.getMonth() + 1) < 10) {
          monthStart = '0' + monthStart;
        }

        if (toDate.getDate() < 10) {
          dayEnd = '0' + dayEnd;
        }

        if ((toDate.getMonth() + 1) < 10) {
          monthEnd = '0' + monthEnd;
        }

        const from = dayStart + '/' + monthStart;
        const to = dayEnd + '/' + monthEnd;

        if (reservation.amountPayed != reservation.amountTotal) {
          payment = 'NO';
        }

        if (reservation.refundState) {
          reservationType = 'Rimborsata';
        } else if (reservation.cocoBooking) {
          reservationType = 'COCO Booking';
        } else if (reservation.seasonTicket) {
          reservationType = 'stagionale';
        }

        if (reservation.refundState) {
          state = 'Rimborsata';
        } else if (reservation.selectedStatus == 1) {
          state = 'Arrivato';
        } else if (reservation.selectedStatus == 2) {
          state = 'Libero';
        }

        let packageSelected = '';
        let slots = '';
        const counter = 0;

        for (const pack in reservation.groupByPacks) {
          if (reservation.groupByPacks[pack]) {

            if (reservation.noSlots === false) {
              if (packageSelected == '') {
                packageSelected += 'Postazioni: ';
              } else {
                packageSelected += '\nPostazioni: ';
              }
            } else {
              if (packageSelected == '') {
                packageSelected += 'Pacchetti: ';
              } else {
                packageSelected += '\nPacchetti: ';
              }
            }

            let counterP = 0;

            if (reservation.noSlots === false) {
              for (const slot of reservation.groupByPacks[pack].products) {

                  if (counterP > 0 ) {
                    packageSelected += ', ' + slot.coords;
                    slots += ', ' + slot.coords;
                  } else {
                    packageSelected += slot.coords;
                    slots += slot.coords;
                  }
                  counterP++;
              }

              if (this.dataService.resortData.packagesByIds[pack]) {
                if (this.dataService.resortData.zonesByIds[reservation.groupByPacks[pack].products[0].zoneKey]) {
                  packageSelected += '\n' + this.dataService.resortData.packagesByIds[pack].name[this.dataService.lang] + ' - ' + this.dataService.resortData.zonesByIds[reservation.groupByPacks[pack].products[0].zoneKey].name[this.dataService.lang];
                } else {
                  packageSelected += '\n' + this.dataService.resortData.packagesByIds[pack].name[this.dataService.lang] + ' - zona non più esistente'
                }
              } else {
                packageSelected += '\nPacchetto non più esistente';
              }
            } else {
              if (this.dataService.resortData.packagesByIds[pack]) {
                for (const singlePackage of reservation.groupByPacks[pack].products) {
                  packageSelected += '\n' + singlePackage.quantity + ' x ' + this.dataService.resortData.packagesByIds[pack].name[this.dataService.lang] + ' - ' + this.dataService.resortData.zonesByIds[singlePackage.zoneKey].name[this.dataService.lang];
                }

              } else {
                packageSelected += '\nPacchetto non più esistente';
              }
            }

            for (const optionalKey in reservation.groupByPacks[pack].optionals) {
              if (reservation.groupByPacks[pack].optionals[optionalKey]) {
                const foundOptional = this.dataService.optionals.find(opt => opt.id == optionalKey);
                if (foundOptional) {
                  packageSelected += '\n' +  reservation.groupByPacks[pack].optionals[optionalKey] + ' x ' + foundOptional.name[this.dataService.lang] + '\n';
                } else {
                  packageSelected += '\n' +  reservation.groupByPacks[pack].optionals[optionalKey] + ' x Seduta aggiuntiva non più esistente' + '\n';
                }
              }
            }
          }
        }

        if (reservation.extras && reservation.extras.length && fields.extras) {
          packageSelected += '\nExtra:';
          reservation.extras.map(extra => {
            packageSelected += '\n';

            if (extra.name && typeof extra.name === 'string') {
              packageSelected += extra.quantity + ' x ' + extra.name;
            } else if (this.dataService.resortData.extrasByIds[extra.id]) {
              packageSelected += extra.quantity + ' x ' + this.dataService.resortData.extrasByIds[extra.id].name[this.dataService.lang];
            } else {
              packageSelected += extra.quantity + ' x ' + 'Extra non più esistente';
            }

          });
        }



        let name = reservation.name;
        let surname = ''

        if (reservation.surname && reservation.surname !== '') {
          name = name +' '+reservation.surname;
          surname = reservation.surname
        }

        if (name.trim() == '') {
          name = '-';
        }

        if (fields.customerInfos && reservation.email && reservation.email !== '') {
          name = name+'\n'+reservation.email;
        }

        if (fields.customerInfos &&reservation.phone && reservation.phone !== ''  && reservation.phone.trim() !== '+39') {
          name = name+'\n'+reservation.phone;
        }

        if (fields.customerInfos && reservation.fiscalCode && reservation.fiscalCode !== '') {
          name = name+'\n'+reservation.fiscalCode;
        }


        const obj = {
          slots: packageSelected,
          start: reservation.start,
          end: reservation.end,
          name,
          surname,
          date: 'dal ' + from + ' al ' + to,
          payment,
          amountPayed: '--',
          amountTotal: '--',
          state,
          reservationType,
          notes: reservation.notes
        };

        if (reservation.amountPayed !== null) {
          obj.amountPayed = reservation.amountPayed.toFixed(2) + '€'
        }

        if (reservation.amountTotal !== null) {
          obj.amountTotal = reservation.amountTotal.toFixed(2) + '€';
        }


        rows.push(obj);
      }

    }


  /* <option value="name">
      Nome prenotazione (Alfabetico)
    </option>
    <option value="slotNameAsc">
      Nome postazione (Crescente)
    </option>
    <option value="slotNameDesc">
      Nome postazione (Decrescente)
    </option>
    <option value="dateAsc">
      Data inizio prenotazione (Crescente)
    </option>
    <option value="dateDesc">
      Data inizio prenotazione (Decrescente)
    </option> */

    if (orderBy === 'name') {
      rows.sort(this.sortByName);
    }


    if (orderBy === 'surname') {
      rows.sort(this.sortBySurname);
    }


    if (orderBy === 'slotNameAsc') {
      rows.sort(this.sortBySlotsAsc);
    }

    if (orderBy === 'slotNameDesc') {
      rows.sort(this.sortBySlotsDesc);
    }

    if (orderBy === 'dateAsc') {
      rows.sort(this.sortByDateAsc);
    }

    if (orderBy === 'dateDesc') {
      rows.sort(this.sortByDateDesc);
    }

    let margin = {
      top: 50
    };

    let printStatus = 'Non arrivato';
    let printType = 'Gestionale';
    let printPayment = 'Pagato';

    let margins = [];


    if (!filters.packages && filters.state == null && filters.settlement == null && filters.zones == null && filters.searchString == '' && filters.cabins == null) {
      margin.top = 25;
    } else {

      margin.top = 25;

      if (filters.state != null) {
          if (filters.state == 1)
            printType = 'COCO Booking';
          else if (filters.state == 2)
            printType = 'stagionale';
          else
            printType = 'Gestionale';

          margins.push(margin.top);
          margin.top += 5;
      }

      if (filters.settlement != null) {
          if (filters.settlement)
            printPayment = 'Pagato';
          else
            printPayment = 'Non pagato';

          margins.push(margin.top);
          margin.top += 5;
      }

      if (filters.searchString) {
        margins.push(margin.top);
        margin.top += 5;
      }

      if (filters.packages) {
        margins.push(margin.top);
        margin.top += 5;
      }

      if (filters.zones) {
        margins.push(margin.top);
        margin.top += 5;
      }

      if (filters.cabins) {
        margins.push(margin.top);
        margin.top += 5;
      }

    }

    console.log(filters);

    doc.autoTable(columns, rows, {
      headStyles : {
        fillColor: [0, 115, 203],
        textColor: [255, 255, 255]
      },
      margin,
      styles: {overflow: 'linebreak', cellWidth: 'wrap'},
      bodyStyles: {valign: 'top'},
      columnStyles,
      didDrawPage : data => {

        let rowIndex = 0;

        if (!filters.packages && filters.searchString == '' && !filters.state && !filters.cabins && !filters.packages && !filters.zones) {
          doc.text('Nessun filtro applicato', 15, 20);
        } else {

          doc.text('Filtri applicati', 15, 20);

          if (filters.searchString) {

            doc.text('Nome o postazione: ' + filters.searchString, 15, margins[rowIndex]);
            rowIndex += 1;

          }

          if (filters.settlement != null) {
            doc.text('Pagamento: ' + printPayment, 15, margins[rowIndex]);
            rowIndex += 1;
          }

          if (filters.state != null)  {
            doc.text('Tipo: ' + printType, 15, margins[rowIndex]);
            rowIndex += 1;
          }

          if (filters.packages) {
            for (const pack of filters.packages.join().split(",")) {
              doc.text('Pacchetti: ' + this.dataService.resortData.packagesByIds[pack].name[this.dataService.lang], 15, margins[rowIndex]);
            }

            rowIndex += 1;
          }

          if (filters.zones) {
            for (const zone of filters.zones.join().split(",")) {
              doc.text('Zone: ' + this.dataService.resortData.zonesByIds[zone].name[this.dataService.lang], 15, margins[rowIndex]);
            }

            rowIndex += 1;
          }

          if (filters.cabins) {
            for (const cabin of filters.cabins.join().split(",")) {
              let foundCabin = this.dataService.resortData.cabins.find(cab => cab.id == cabin);
              doc.text('Cabine: ' + foundCabin.label[this.dataService.lang], 15, margins[rowIndex]);
            }

            rowIndex += 1;
          }

          /* if (self.startDate) {

            let startDate = new Date(self.startTimestamp);
            let startDay = startDate.getDate();
            let startMonth = startDate.getMonth() + 1;


            let endDate = new Date(self.endTimestamp);
            let endDay = endDate.getDate();
            let endMonth = endDate.getMonth() + 1;
            let dateString = 'dal ' + startDay + '/' + startMonth + ' al ' + endDay + '/' + endMonth;
            doc.text('Date: ' + dateString, 15, margins[rowIndex]);

            rowIndex += 1;
          } */


        }


      }
    });

    doc.save('Elenco Prenotazioni dal ' + startDay + '/' + startMonth + ' al ' + endDay + '/' + endMonth);

  }

  exportPeople(reservations, startTimestamp, endTimestamp,orderBy, fields) {

    const doc = new jsPDF('p', 'mm', 'a4');

    const self = this;

    doc.setFont('helvetica');
    doc.setFontSize(16);

    const startYear = new Date(startTimestamp);
    const startMonth = new Date(startTimestamp).getMonth() + 1;
    const startDay = new Date(startTimestamp).getDate();
    const endMonth = new Date(endTimestamp).getMonth() + 1;
    const endDay = new Date(endTimestamp).getDate();
    doc.text('Elenco Presenze dal ' + startDay + '/' + startMonth + ' al ' + endDay + '/' + endMonth, 15, 15);
    console.log('Elenco Presenze dal ' + startDay + '/' + startMonth + ' al ' + endDay + '/' + endMonth);
    const title = 'Presenze';

    /* if (this.owner.subscriptionType == 0) {
      title = 'Pacchetti';
    } */

    /* const columns = [
        {dataKey: 'name', title: 'Nome' },
        {dataKey: 'phone', title: 'Telefono' },
        {dataKey: 'date', title: 'Data'},
        //{dataKey: 'fiscalCode', title: 'CF'},
        {dataKey: 'notes', title: 'Note'}
    ];
 */

    const columnsKeys = {
      name: {
        it: 'Nome',
        en: 'Name'
      },
      phone: {
        it: 'Telefono',
        en: 'Phone'
      },
      email: {
        it: 'Email',
        en: 'Email'
      },
      date: {
        it: 'Date',
        en: 'Dates'
      },
      fiscalCode: {
        it: 'CF',
        en: 'CF'
      },
      notes : {
        it: 'Note',
        en: 'Notes'
      }
    }

    const columns = [
    ];

    const rows = [];

    let fieldsToShow = 0;

    for (const col in fields) {
      if (fields[col] === true) {
        fieldsToShow++;
      }
    }
    const unity = 180/fieldsToShow;
    const columnStyles = {};

    if (fields.name) {
      columns.push({
        dataKey: 'name', title: columnsKeys.name[this.dataService.lang]
      });

      columnStyles['name'] = {
        valign: 'top', cellWidth: 'auto',overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      };
    }

    if (fields.phone) {
      columns.push({
        dataKey: 'phone', title: columnsKeys.phone[this.dataService.lang]
      });

      columnStyles['phone'] = {
        valign: 'top', cellWidth: 'auto',overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      };
    }

    if (fields.email) {
      columns.push({
        dataKey: 'email', title: columnsKeys.email[this.dataService.lang]
      });

      columnStyles['email'] = {
        valign: 'top', cellWidth: 'auto',overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      };
    }


    if (fields.fiscalCode) {
      columns.push({
        dataKey: 'fiscalCode', title: columnsKeys.fiscalCode[this.dataService.lang]
      });

      columnStyles['fiscalCode'] = {
        valign: 'top', cellWidth: 'auto',overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      };
    }


    if (fields.date) {
      columns.push({
        dataKey: 'date', title: columnsKeys.date[this.dataService.lang]
      });

      columnStyles['date'] = {
        valign: 'top', cellWidth: 'auto',overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      };
    }

    if (fields.notes) {
      columns.push({
        dataKey: 'notes', title: columnsKeys.notes[this.dataService.lang]
      });

      columnStyles['notes'] = {
        valign: 'top', cellWidth: 'auto',overflow: 'linebreak',  fontSize: 8, cellPadding: 1
      };
    }

    doc.setFontSize(9);

    console.log(reservations);

    for (const reservation of reservations) {


      if (!reservation.refundState) {

        const fromDate = new Date(reservation.start * 1000);
        const toDate = new Date(reservation.end * 1000);

        let dayStart = fromDate.getDate().toString();
        let monthStart = (fromDate.getMonth() + 1).toString();

        let dayEnd = toDate.getDate().toString();
        let monthEnd = (toDate.getMonth() + 1).toString();

        if (fromDate.getDate() < 10) {
          dayStart = '0' + dayStart;
        }

        if ((fromDate.getMonth() + 1) < 10) {
          monthStart = '0' + monthStart;
        }

        if (toDate.getDate() < 10) {
          dayEnd = '0' + dayEnd;
        }

        if ((toDate.getMonth() + 1) < 10) {
          monthEnd = '0' + monthEnd;
        }

        const from = dayStart + '/' + monthStart;
        const to = dayEnd + '/' + monthEnd;

        let name = reservation.name;
        let surname = '';


        if (reservation.surname && reservation.surname !== '') {
          name = name +' '+reservation.surname;
          surname = reservation.surname;
        }

        if (name.trim() == '') {
          name = '-';
        }


        const obj = {
          name,
          surname,
          start: reservation.start,
          email: reservation.email,
          end: reservation.end,
          phone: reservation.phone,
          date: 'dal ' + from + ' al ' + to,
          fiscalCode: reservation.fiscalCode,
          notes: reservation.notes
        };

        rows.push(obj);
      }

    }

    if (orderBy === 'name') {
      rows.sort(this.sortByName);
    }


    if (orderBy === 'cognome') {
      rows.sort(this.sortBySurname);
    }

    if (orderBy === 'dateAsc') {
      rows.sort(this.sortByDateAsc);
    }

    if (orderBy === 'dateDesc') {
      rows.sort(this.sortByDateDesc);
    }

    let margin = {
      top: 20
    };

    let printStatus = 'Non arrivato';
    let printType = 'Gestionale';
    let printPayment = 'Pagato';

    let margins = [];


    doc.autoTable(columns, rows, {
      headStyles : {
        fillColor: [0, 115, 203],
        textColor: [255, 255, 255]
      },
      margin,
      styles: {overflow: 'linebreak', cellWidth: 'wrap'},
      bodyStyles: {valign: 'top'},
      columnStyles, didDrawPage : data => {

        let rowIndex = 0;
      }
    });

    doc.save('Elenco Presenze dal ' + startDay + '/' + startMonth + ' al ' + endDay + '/' + endMonth);

  }

  exportPaymentsPDF(reservations, startTimestamp, endTimestamp, filters) {

    console.log(filters);

    const doc = new jsPDF('p', 'mm', 'a4');

    const self = this;

    doc.setFont('helvetica');
    doc.setFontSize(16);

    const startYear = new Date(startTimestamp);
    const startMonth = new Date(startTimestamp).getMonth() + 1;
    const startDay = new Date(startTimestamp).getDate();
    const endMonth = new Date(endTimestamp).getMonth() + 1;
    const endDay = new Date(endTimestamp).getDate();
    doc.text('Elenco Incassi dal ' + startDay + '/' + startMonth + ' al ' + endDay + '/' + endMonth, 15, 15);
    console.log('Elenco Incassi dal ' + startDay + '/' + startMonth + ' al ' + endDay + '/' + endMonth);
    const title = 'Postazioni';

    /* if (this.owner.subscriptionType == 0) {
      title = 'Pacchetti';
    } */

    const columns = [
        {dataKey: 'name', title: 'Nome' },
        {dataKey: 'reservationDate', title: 'Data Prenotazione'},
        {dataKey: 'paymentDate', title: 'Data Incasso'},
        {dataKey: 'payment', title: '€'},
        {dataKey: 'paymentType', title: 'Tipo'},
        {dataKey: 'fiscalPrinted', title: 'Fiscale'}
    ];

    const rows = [];


    doc.setFontSize(9);

    for (const reservation of reservations) {


      if (!reservation.refundState) {

        const splitName = doc.splitTextToSize(reservation.name, 180);

        const fromDate = new Date(reservation.start * 1000);
        const toDate = new Date(reservation.end * 1000);

        let dayStart = fromDate.getDate().toString();
        let monthStart = (fromDate.getMonth() + 1).toString();

        let dayEnd = toDate.getDate().toString();
        let monthEnd = (toDate.getMonth() + 1).toString();

        if (fromDate.getDate() < 10) {
          dayStart = '0' + dayStart;
        }

        if ((fromDate.getMonth() + 1) < 10) {
          monthStart = '0' + monthStart;
        }

        if (toDate.getDate() < 10) {
          dayEnd = '0' + dayEnd;
        }

        if ((toDate.getMonth() + 1) < 10) {
          monthEnd = '0' + monthEnd;
        }

        const from = dayStart + '/' + monthStart;
        const to = dayEnd + '/' + monthEnd;

        const paymentDate = new Date(reservation.paymentDate * 1000);
        let dayStartPayment = paymentDate.getDate().toString();
        let monthStartPayment = (paymentDate.getMonth() + 1).toString();

        if (paymentDate.getDate() < 10) {
          dayStartPayment = '0' + dayStartPayment;
        }

        if ((paymentDate.getMonth() + 1) < 10) {
          monthStartPayment = '0' + monthStartPayment;
        }

        const paymentDateString = dayStartPayment + '/' + monthStartPayment;


        /* {dataKey: 'name', title: 'Nome' },
        {dataKey: 'reservationDate', title: 'Data Prenotazione'},
        {dataKey: 'paymentDate', title: 'Data Incasso'},
        {dataKey: 'payment', title: '€'},
        {dataKey: 'paymentType', title: 'Tipo'},
        {dataKey: 'fiscalPrinted', title: 'Fiscale'} */


        let fiscalPrinted = 'NO';

        if (reservation.fiscalPrinted) {
          fiscalPrinted = 'SI';
        }

        let paymentType = 'contanti';

        if (reservation.paymentType == 'cash') {
          paymentType = 'Contanti';
        } else if (reservation.paymentType == 'cheque') {
          paymentType = 'Assegno';
        } else if (reservation.paymentType == 'credit-card') {
          paymentType = 'Carta di credito';
        } else if (reservation.paymentType == 'transfer') {
          paymentType = 'Bonifico';
        } else if (reservation.paymentType == 'coupon') {
          paymentType = 'Coupon';
        } else if (reservation.paymentType == 'forex') {
          paymentType = 'Valuta straniera';
        }


        let name = reservation.name;

        if (name.trim() == '') {
          name = '-';
        }
        let surname = ''

        if (reservation.surname && reservation.surname !== '') {
          name = name +' '+reservation.surname;
          surname = reservation.surname
        }

        const obj = {
          name,
          surname,
          start: reservation.start,
          end: reservation.end,
          reservationDate: 'dal ' + from + ' al ' + to,
          paymentDate: paymentDateString,
          payment: reservation.amount.toFixed(2) + ' €',
          paymentType,
          fiscalPrinted
        };

        rows.push(obj);
      }

    }

    const margin = {
      top: 50
    };

    let printType = 'Gestionale';

    const margins = [];

    /* let filters = {
      settlement: this.selectedSettlement,
      paymentType: this.selectedPaymentType,
      searchString: this.searchString
    } */


    if (filters.paymentType == null && filters.searchString == '') {
      margin.top = 25;
    } else {

      margin.top = 25;

      if (filters.paymentType != null) {
          if (filters.paymentType == 'cash') {
            printType = 'contanti';
          } else if (filters.paymentType == 'cheque') {
            printType = 'assegno';
          } else if (filters.paymentType == 'credit-card') {
            printType = 'carta di credito';
          } else if (filters.paymentType == 'transfer') {
            printType = 'bonifico';
          } else if (filters.paymentType == 'coupon') {
            printType = 'Coupon';
          } else if (filters.paymentType == 'forex') {
            printType = 'Valuta straniera';
          }

          margins.push(margin.top);
          margin.top += 5;
      }


      if (filters.searchString) {
        margins.push(margin.top);
        margin.top += 5;
      }

    }


    doc.autoTable(columns, rows, {
      headStyles : {
        fillColor: [0, 115, 203],
        textColor: [255, 255, 255]
      },
      margin,
      styles: {overflow: 'linebreak', cellWidth: 'wrap'},
      bodyStyles: {valign: 'top'},
      columnStyles: {
        name: {valign: 'top', overflow: 'linebreak', cellWidth: 45, fontSize: 8, cellPadding: 1},
        reservationDate: {valign: 'top', cellWidth: 40, fontSize: 8, cellPadding: 1},
        paymentDate: {valign: 'top', overflow: 'linebreak', cellWidth: 25, fontSize: 8, cellPadding: 1},
        payment: {valign: 'top', cellWidth: 20, fontSize: 8, cellPadding: 1},
        paymentType: {valign: 'top', cellWidth: 30, fontSize: 8, cellPadding: 1},
        fiscalPrinted: {valign: 'top', cellWidth: 20, fontSize: 8, cellPadding: 1},
      }, didDrawPage : data => {

        let rowIndex = 0;

        if (!filters.paymentType && filters.searchString == '') {
          doc.text('Nessun filtro applicato', 15, 20);
        } else {

          doc.text('Filtri applicati', 15, 20);

          if (filters.searchString) {

            doc.text('Nome o postazione: ' + filters.searchString, 15, margins[rowIndex]);
            rowIndex += 1;

          }

          if (filters.paymentType != null) {
            doc.text('Pagamento: ' + printType, 15, margins[rowIndex]);
            rowIndex += 1;
          }

        }


      }
    });

    doc.save('table.pdf');

  }

  exportQRcodePDF(reservation, qrCode) {


    const qrcode = document.getElementById('qrcode');
    let doc = new jsPDF();

    doc.setFont('helvetica');
    doc.setFontSize(16);
    doc.text('QR CODE - PRENOTAZIONE '+ qrCode, 15, 15);
    let imageData= this.getBase64Image(qrcode.firstChild.firstChild);
    doc.addImage(imageData, "JPG", 0, 15);

    doc.save(reservation.reservationCode+ ' - qr code.pdf');

  }

  getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL;
  }


  sortBySlotsAsc(a, b) {
    return a.slots.trim().localeCompare(b.slots.trim(), undefined, {numeric: true, sensitivity: 'base'});
  }

  sortBySlotsDesc(a, b) {
    return b.slots.trim().localeCompare(a.slots.trim(), undefined, {numeric: true, sensitivity: 'base'});
  }

  sortByName( a, b ) {
    if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
      return -1;
    }
    if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
      return 1;
    }
    return 0;
  }

  sortBySurname( a, b ) {
    if ( a.surname.toLowerCase() < b.surname.toLowerCase() ) {
      return -1;
    }
    if ( a.surname.toLowerCase() > b.surname.toLowerCase() ) {
      return 1;
    }
    return 0;
  }

  sortByDateAsc( a, b ) {
    if ( a.start < b.start ) {
      return -1;
    }
    if ( a.start > b.start ) {
      return 1;
    }
    return 0;
  }


  sortByDate( a, b ) {
    if ( a.date < b.date ) {
      return -1;
    }
    if ( a.date > b.date ) {
      return 1;
    }
    return 0;
  }

  sortByDateDesc( a, b ) {
    if ( a.start < b.start ) {
      return 1;
    }
    if ( a.start > b.start ) {
      return -1;
    }
    return 0;
  }

}
