import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DataStatisticsService } from '../../../core/providers/data-statistics.service';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts/highstock';
require('highcharts/modules/exporting')(Highcharts);


@Component({
  selector: 'app-statistics-extras',
  templateUrl: './statistics-extras.component.html',
  styleUrls: ['./statistics-extras.component.scss']
})
export class StatisticsExtrasComponent implements OnInit {

  resortData; managerData; pageClicked; prices; submenu; lang = 'it';elements; features;optionals;services; dataLang='it'; lightLoading = false;
  loader = false; selectedZone = null; dailyOccupation=0;chart; maxOccupation; occupationRate; occupation;allExtrasIds;page;

  objectKeys = Object.keys;

  // DATE VARIABLES
  startTimestamp; endTimestamp; startSerializedDate; endSerializedDate;
  startDate; startDay; startMonth; startYear; stockData;
  endDate; endDay; endMonth; endYear;statistics; selectedExtra= null;

  dayWithMoreAttendance; averageStay; totalAttendance;totalReservations;

  constructor(private statisticsData: DataStatisticsService, public dataService: DataService, private settingsService: SettingsService) {

    this.dataLang = this.dataService.lang;
    this.resortData =  this.dataService.resortData;

  }

  async ngOnInit() {
    this.lightLoading = true;
    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.resortData =  this.dataService.resortData;

    this.startTimestamp = this.resortData.openingDate * 1000;
    this.endTimestamp = this.resortData.closingDate * 1000;

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate;

    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;

    this.allExtrasIds = '';

    for (const extra of this.resortData.extras) {
      if (this.allExtrasIds === '') {
        this.allExtrasIds = extra.id;
      } else {
        this.allExtrasIds += ',' + extra.id;
      }
    }

    this.statistics = await this.getStatistics();

    console.log(this.statistics);
    this.lightLoading = false;
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout( () => {
      this.pageClicked = false;
    }, 1000);
  }

  exportPDF() {

  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.lightLoading = true;
    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp)
      this.endTimestamp = this.startTimestamp;

    let endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);

    this.statistics = await this.getStatistics();
    this.lightLoading = false;
  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.lightLoading = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp)
      this.startTimestamp = this.endTimestamp;

    let startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    this.statistics = await this.getStatistics();
    this.lightLoading = false;
  }

  async getStatistics() {
    this.lightLoading = true;
    let filterExtras = this.selectedExtra;

    if (this.selectedExtra === null) {
      filterExtras = this.allExtrasIds
    }

    this.lightLoading = true;

    /* let attendances;
    let reservations;
    [attendances, reservations] = await Promise.all([this.statisticsData.getAttendance(this.startTimestamp/1000, this.endTimestamp/1000,null,null,filterExtras, null,null), this.statisticsData.getCount(this.startTimestamp/1000, this.endTimestamp/1000,null,null,filterExtras, null,null)]); */

    let attendances = await this.statisticsData.getAttendance(this.startTimestamp/1000, this.endTimestamp/1000,null,null,filterExtras, null,null);
    let reservations = await this.statisticsData.getCount(this.startTimestamp/1000, this.endTimestamp/1000,null,null,filterExtras, null,null);

    console.log(attendances);
    console.log(reservations);

    this.totalAttendance = 0;
    this.dayWithMoreAttendance = 0;

    let max = -1;
    const data = [];
    console.log(reservations)
    this.totalReservations = reservations.total_reservations;

    for (let ts in attendances) {
        if (attendances[ts] > max) {
          this.dayWithMoreAttendance = ts;
          max = attendances[ts];
        }
        data.push(attendances[ts]);
        this.totalAttendance+=attendances[ts];
    }

    let self = this;

    let plotBands = [];

    for (let i=this.startTimestamp; i<=this.endTimestamp;i+=24*60*60*1000) {
      let day = new Date(i).getDay();
      let year = new Date(i).getFullYear();
      let month = new Date(i).getMonth();
      let date = new Date(i).getDate();
      if (day == 6) {
        plotBands.push({
          color: '#dcdcdc',
          from: Date.UTC(year, month, date),
          to: Date.UTC(year, month, date+1)
        })
      }
    }



    this.chart = new Chart({
      chart: {
        type: 'areaspline'
      },
      exporting: {
        enabled: true
      },
      title: {
        text: 'Quantità'
      },
      credits: {
        enabled: false
      },
      xAxis: {
          type: 'datetime',
          min: self.startTimestamp,
          max: self.endTimestamp,
          tickInterval: 24 * 3600 * 1000,
          labels: {
              overflow: 'justify',
              formatter: function() {
                let date = (new Date(this.value)).getDate();
                let month = (new Date(this.value)).getMonth();
                let monthString = self.statisticsData.shortMonths[month]['it']

                let dateString = date+"<br>"+monthString;
                return dateString;

              }
          },
          dateTimeLabelFormats: {
              day: '%e<br>%b'
          },
          plotBands:plotBands
      },
      yAxis: [

        { // Primary yAxis
            labels: {
                format: '{value:.,0f}',
                style: {
                    color: '#11A1FF'
                }
            },
            title: {
                text: 'Quantità'
            },
            opposite: false

        }
      ],
      series: [
        {
          type:'areaspline',
          name: 'Quantità',
          data: data,
          pointInterval:  24 * 3600 * 1000, // one day
          pointStart: self.startTimestamp
        }
      ],
      plotOptions: {
        areaspline: {
            fillOpacity: 0.1,
            marker: {
                enabled: false
            }
        }
      },
    });

    this.lightLoading = false;
  }

  getOccupationRate(employmentRate) {
    console.log(this.selectedZone)
    this.dailyOccupation = 0;
    for (const slot of this.dataService.map) {
      if (slot.packagesIds.length > 0 && slot.zoneId && (!this.selectedZone || parseInt(this.selectedZone) == slot.zoneId)) {
        this.dailyOccupation++;
      }
    }

    console.log(this.dailyOccupation);

    this.maxOccupation = 0;
    const length = (this.endTimestamp-this.startTimestamp) / (24*60*60*1000) +1;
    console.log(length);
    this.maxOccupation = this.dailyOccupation*length
    this.occupation = JSON.parse(JSON.stringify(this.maxOccupation));

    for (const packId in employmentRate.sellingPackagesZones) {
      if (employmentRate.sellingPackagesZones[packId]) {

        if (!this.selectedZone || packId.indexOf(this.selectedZone) > -1 ) {
          this.occupation -= employmentRate.sellingPackagesZones[packId];
        }
      }
    }

    console.log(this.maxOccupation)

    this.occupationRate = 1 - Math.round(this.occupation/this.maxOccupation * 100) / 100;
  }
}
