import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthguardLicenseService implements CanActivate {
  constructor(
      private router: Router,
      private authenticationService: AuthService,
      public afAuth: AngularFireAuth,
      private http: HttpClient,
  ) {}

  canActivate(suite): Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.authenticationService.getCurrentUser()
      .then(async user => {

        if (user) {
          return resolve(true);
        } else {
          return resolve(false);
        }

      }, err => {
        return resolve(false);
      })
    })
  }

}
