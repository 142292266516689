import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { PrintersService } from '../../../core/providers/printers.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modal-printers',
  templateUrl: './modal-printers.component.html',
  styleUrls: ['./modal-printers.component.scss']
})
export class ModalPrintersComponent implements OnInit {

  printers; error = null; selectedPrinter; clicked = false; total; bill;

  constructor(public router: Router, private settingsService: SettingsService, private printerService: PrintersService, private dataService: DataService, public dialogRef: MatDialogRef<ModalPrintersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.printers = this.dataService.resortData.printers;
      this.bill = this.data.bill;
    }

  async ngOnInit() {

    let fiscalPrinter = this.printers[0];
    let noFiscalPrinter = this.printers[0];

    let flagDefaultFiscalPrinter = false;
    let flagDefaultNoFiscalPrinter = false

    if (this.printers.find(p => p.defaultFiscal == true)) {
      fiscalPrinter = this.printers.find(p => p.defaultFiscal == true);
      flagDefaultFiscalPrinter = true;
    }

    if (this.printers.find(p => p.defaultNoFiscal == true)) {
      noFiscalPrinter = this.printers.find(p => p.defaultNoFiscal == true);
      flagDefaultNoFiscalPrinter = true
    }

    if (this.dataService.userData.settingsManager && this.dataService.userData.settingsManager.printers) {
      if (this.dataService.userData.settingsManager.printers.defaultFiscal) {
        fiscalPrinter = this.printers.find(p=> p.name === this.dataService.userData.settingsManager.printers.defaultFiscal);
        flagDefaultFiscalPrinter = true;
      }

      if (this.dataService.userData.settingsManager.printers.defaultNoFiscal) {
        noFiscalPrinter = this.printers.find(p=> p.name === this.dataService.userData.settingsManager.printers.defaultNoFiscal);
        flagDefaultNoFiscalPrinter = true;
      }
    }

    console.log(fiscalPrinter)
    console.log(noFiscalPrinter)

    if (this.printers.length === 1 || (this.data.fiscalPrint && flagDefaultFiscalPrinter) || (!this.data.fiscalPrint && flagDefaultNoFiscalPrinter)) {

      if (this.bill) {
        await this.printSingleVoice(fiscalPrinter,noFiscalPrinter)
      } else {
        await this.print(fiscalPrinter,noFiscalPrinter);
      }
    } else {
      if (this.data.fiscalPrint) {
          this.printers = this.printers.filter(p => p.model !== 'epson_tm_m30_ii')
      } else {
        this.printers = this.printers.filter(p=>p.model !== 'rch_print_f')
      }
    }
  }

  async print(fiscalPrinter,noFiscalPrinter) {
    this.error = null;
    this.clicked = true;
    try {

      let response = null;
      console.log(this.data.paymentType);
      if (this.data.fiscalPrint) {
        if (fiscalPrinter.model == 'epson_fp_81_ii') {
          response = await this.printerService.epsonFiscalprint(this.data.reservation,fiscalPrinter, this.data.paymentType);
          console.log(response);
        } else if (fiscalPrinter.model == 'custom') {
          response = await this.printerService.customFiscalPrint(this.data.reservation,fiscalPrinter, this.data.paymentType);
          await this.printerService.resetCustomPrinter(fiscalPrinter);
          console.log(response);
        } else if (fiscalPrinter.model == 'rch_print_f') {
          response = await this.printerService.rchFiscalPrint(this.data.reservation,fiscalPrinter, this.data.paymentType);
        }

        if (response && (response['success'] == true || response['status']===200)) {
          this.selectedPrinter = fiscalPrinter;
          this.close(true);
        } else {
          this.error = 'Error di stampa<br>'+JSON.stringify(response);
          this.clicked = false;
        }
      } else {
        if (noFiscalPrinter.model == 'epson_fp_81_ii') {
          response = await this.printerService.epsonPrint(this.data.reservation,noFiscalPrinter);
        } else if (noFiscalPrinter.model == 'epson_tm_m30_ii'){
          response = await this.printerService.epsonTM_M30_II(this.data.reservation,noFiscalPrinter);
        } else if (noFiscalPrinter.model == 'custom') {
          response = await this.printerService.customPrint(this.data.reservation, noFiscalPrinter);
          await this.printerService.resetCustomPrinter(noFiscalPrinter);
        }

        console.log(response);

        if (response && (response['success'] == true || response['status'] == 200)) {
          this.selectedPrinter = noFiscalPrinter;
          this.close(false);
        } else {
          this.error = 'Error di stampa<br>'+JSON.stringify(response);
          this.clicked = false;
        }
      }
    } catch(err) {
      if (err.code) {
        this.error = 'Errore di stampa<br>' + err.code
      } else {
        this.error = 'Errore di stampa<br>'+JSON.stringify(err);
      }

      this.clicked = false;
    }


  }

  async printSingleVoice(fiscalPrinter,noFiscalPrinter) {
    this.error = null;
    this.clicked = true;
    try {

      if (this.data.fiscalPrint) {
        let response = null;
        if (fiscalPrinter.model == 'epson_fp_81_ii') {
          response = await this.printerService.epsonFiscalprintSingle(this.data.reservation,fiscalPrinter, this.data.paymentType, this.bill);
          console.log(response);
        } else if (fiscalPrinter.model == 'custom') {
          response = await this.printerService.customFiscalPrintSingle(this.data.reservation,fiscalPrinter, this.data.paymentType, this.bill);
          await this.printerService.resetCustomPrinter(fiscalPrinter);
          console.log(response);
        } else if (fiscalPrinter.model == 'rch_print_f') {
          response = await this.printerService.rchFiscalPrintSingle(this.data.reservation,fiscalPrinter, this.data.paymentType, this.bill);
        }

        if (response && (response['success'] == true || response['status'] === 200)) {
          this.bill.fiscalPrinted = true;
          this.bill.fiscalPrinter = fiscalPrinter.name;
          this.bill.printedBy = this.settingsService.getCurrentUserData();
          this.selectedPrinter = fiscalPrinter;
          this.close(true);
        } else {
          this.error = 'Error di stampa<br>'+JSON.stringify(response);
        }
        this.selectedPrinter = fiscalPrinter;
      } else {
        let response = null;
        if (noFiscalPrinter.model == 'epson_fp_81_ii') {
          response = await this.printerService.epsonPrint(this.data.reservation,noFiscalPrinter);
         
        } else if (fiscalPrinter.model == 'custom') {
          response = await this.printerService.customPrint(this.data.reservation,noFiscalPrinter);
          await this.printerService.resetCustomPrinter(noFiscalPrinter);
          console.log(response);
        }

        if (response && response['success'] == true) {
          this.selectedPrinter = noFiscalPrinter;
          this.close(true);
        } else {
          this.error = 'Error di stampa<br>'+JSON.stringify(response);
        }
      }

    } catch(err) {
      console.log(err);
      if (err.code) {
        this.error = 'Errore di stampa<br>' + err.code
      } else {
        this.error = 'Errore di stampa<br>' + JSON.stringify(err);
      }

      this.clicked = false;
    }
  }

  close(updateReservation): void {
    if (updateReservation) {
      let name = this.selectedPrinter.name;
      if (!name || name === '') {
        name = this.selectedPrinter.model;
      }
      this.dialogRef.close(name);
    } else {
      this.dialogRef.close();
    }
  }
}
