import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service'
import { SettingsService } from '../../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';

@Component({
  selector: 'app-printer-detail',
  templateUrl: './printer-detail.component.html',
  styleUrls: ['./printer-detail.component.scss']
})
export class PrinterDetailComponent implements OnInit {

  resortData;managerData;selectedPrinter;printerIndex;pageClicked;

  constructor(public router: Router, public dialog: MatDialog,private route: ActivatedRoute, private dataService: DataService, private settingsService: SettingsService) {

    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
  }

  async ngOnInit() {

    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.resortData = this.dataService.resortData;
    this.managerData =  this.dataService.userData;

    this.initPrinter();
  }

  initPrinter() {
    this.printerIndex = this.route.snapshot.paramMap.get('printerId');
    console.log(this.printerIndex);
    if (this.printerIndex != 'new') {
      this.printerIndex = parseInt(this.printerIndex);
      const printerFound = this.resortData.printers[this.printerIndex];
      this.selectedPrinter = JSON.parse(JSON.stringify(printerFound));
    } else {
      this.printerIndex = null;
      this.selectedPrinter = {
        ip:'',
        name:'',
        type:'',
        model:'',
        department:'',
        data: null,
        defaultFiscal: false,
        defaultNoFiscal:false
      };
    }

    if (!this.selectedPrinter.defaultFiscal) {
      this.selectedPrinter.defaultFiscal = false;
    }

    if (!this.selectedPrinter.defaultNoFiscal) {
      this.selectedPrinter.defaultNoFiscal = false;
    }

    console.log(this.selectedPrinter);
  }

  async savePrinter() {
    if (this.printerIndex === null) {
      this.resortData.printers.push(this.selectedPrinter);
    } else {
      this.resortData.printers[this.printerIndex] = this.selectedPrinter;
    }

    await this.dataService.setResort(this.resortData);
    this.router.navigate(['/settings/advanced/0']);
  }

  async removePrinter() {
      this.resortData.printers.splice(this.printerIndex,1);
      await this.dataService.setResort(this.resortData);
      this.router.navigate(['/settings/advanced/0']);
  }

}
