
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  resortData; userData; optionals; map; mapReservations;
  elements; services; features; packagesServices; prices;elementsByIds={};
  slotsToRemove = []; reservedSlots; coupons = []; bookingIntervals; mapAreas;
  startDate; endDate; offers; selectedOffer; rules;pricesArray=[];
  lang = 'it'; previousPage = null;managers;mapArea = null;defaultMapArea;
  selectedReservation; mapByRowCol;backFromMap = false;
  startTimestampList; endTimestampList; searchString;
  startTimestamp; endTimestamp; isSuperManager = false;
  mainCustomer; associatedCustomers;
  languages = {
    it: '',
    en: '',
    es: '',
    de: '',
    fr: '',
    ru: '',
    gr: '',
  };
  notifications = [];
  lastReservationsQuery = null;
  lastCustomersQuery = null;

  constructor(
    private http: HttpClient,
    public router: Router,
    public ngZone: NgZone,
    public afAuth: AngularFireAuth
  ) {

  }

  async initResortData() {
    this.resortData = await this.getResortData();
    [this.userData, this.optionals, this.elements, this.features, this.packagesServices, this.prices, this.map, this.coupons, this.services, this.rules, this.managers] = await Promise.all([this.getUserData(), this.getOptionals(), this.getElements(), this.getFeatures(), this.getPackageServices(), this.getPrices(), this.getMap(), this.getCoupons(), this.getServices(), this.getRules(), this.getResortManagers()]);
  }


  resetData() {
    this.resortData = null;
    this.userData = null;
    this.map = null;
    this.prices = null;
  }

  async getResortData() {

      try {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const api = environment.apiURL + 'resorts/resorts_managed/';
        const token = firebaseUser.token;

        console.log(token)
        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        this.resortData = data[`result`][0];

        if (!this.resortData.settings) {
          this.resortData.settings =
          {"booking": {"seasonal": {"enabled": false, "freeSlots": false}, "freeSlots": false, "giftsGroup": false}, "manager": {}}
        }

        if (!this.resortData.mapImages) {
          this.resortData.mapImages = {
            svgImage: '',
            pngImage: '',
            pdfImage: ''
          }
        }


        if (!this.resortData.type) {
          this.resortData.type = {
            test:false,
            freeBeach: this.resortData.freeBeach,
            consortium: false,
            consortiumUrl: ''
          }
        }


        if (!this.resortData.priorityProperties) {
          this.resortData.priorityProperties = {
            sponsored:false,
            showcase: false,
            priority: 300
          }
        }


        this.resortData.seasonsPackages = this.resortData.seasons.filter(s => {return (s.packageType === 'package')?true:false; })
        this.resortData.seasonsExtras = this.resortData.seasons.filter(s => {return (s.packageType === 'extra')?true:false; })

        this.resortData.extras.sort(this.compareBySequence);
        this.resortData.zones.sort(this.compareBySequence);
        this.resortData.packages.sort(this.compareBySequence);
        this.resortData.seasons.sort(this.compareByStart);

        this.resortData.seasonsPackages.sort(this.compareByStart);
        this.resortData.seasonsExtras.sort(this.compareByStart);


        this.resortData.packagesByIds = {};
        this.resortData.cabinsByKeys = {};
        this.resortData.zonesByIds = {};
        this.resortData.specialSeasons = [];
        this.resortData.seasonsByIds = {};
        this.resortData.extrasByIds = {};
        this.resortData.discountsByIds = {};
        this.resortData.timeSlotsBySeason = {};
        this.resortData.timeSlots = [];

        for (const packageObj of this.resortData.packages) {
          if (!packageObj.type) {
            packageObj.type = 'slot';
          }
          this.resortData.packagesByIds[packageObj.id] = packageObj;
        }

        for (const cabin of this.resortData.cabins) {
          this.resortData.cabinsByKeys[cabin.id] = cabin;
        }

        for (const zoneObj of this.resortData.zones) {
          this.resortData.zonesByIds[zoneObj.id] = zoneObj;
        }

        for (const seasonObj of this.resortData.seasons) {
          this.resortData.seasonsByIds[seasonObj.id] = seasonObj;
          await this.updateSeasonTimeslot(seasonObj);
        }


        this.resortData.seasons.sort( (a, b) => {
            return a.special - b.special || a.start - b.start;
        });

        this.resortData.specialSeasons = this.resortData.seasons.filter(seas => seas.special);
        /* this.resortData.seasons = this.resortData.seasons.filter(seas => !seas.special); */

        for (const extraObj of this.resortData.extras) {
          extraObj.quantity = 0;

          if (!extraObj.price) {
            extraObj.price = 0;
          }

          this.resortData.extrasByIds[extraObj.id] = extraObj;
        }

        for (const discountObj of this.resortData.discounts) {
          this.resortData.discountsByIds[discountObj.id] = discountObj;
        }

        const printers = [];

        for (const printer of this.resortData.printers) {
          if (printer.ip != '' && printer.name != '') {
            printers.push(printer);
          }
        }

        this.resortData.printers = printers;

        if (!this.resortData.openingDate) {
          this.resortData.openingDate = new Date(1577836800*1000).setUTCHours(0,0,0)/1000;
        } else {
          this.resortData.openingDate = new Date(this.resortData.openingDate*1000).setUTCHours(0,0,0)/1000;
        }

        if (!this.resortData.closingDate) {
          this.resortData.closingDate = new Date(1609372800*1000).setUTCHours(0,0,0)/1000;
        } else {
          this.resortData.closingDate = new Date(this.resortData.closingDate*1000).setUTCHours(0,0,0)/1000;
        }

        console.log(this.resortData.notifications);

        if (!this.resortData.notifications) {
          this.resortData.notifications = {
            web : {
              cocoBooking: true
            },
            email: {
              cocoBookingCancellation: {
                value: false,
                email: ''
              },
              cocoBooking: {
                email: '',
              }
            },
            sms: {
              cocoBooking: {
                value: false
              }
            }
          };
        }

        if (!this.resortData.notifications.email.cocoBooking) {
          this.resortData.notifications.email.cocoBooking = {
            email: '',
            value: false
          }
        } else if (!this.resortData.notifications.email.cocoBooking.value) {
          this.resortData.notifications.email.cocoBooking.value = false;
        }


        console.log(this.resortData.notifications);

        if (!this.resortData.stripe) {
          this.resortData.stripe ={
            id:null
          }
        }

        console.log('RESORT DATA');
        console.log(this.resortData);

        if (!this.resortData.zoom) {
          this.resortData.zoom = 1;
        }

        if (!this.resortData.coords) {
          this.resortData.coords = {
              latitude: 0.0,
              longitude: 0.0
          }
        }

        if (!this.resortData.childrenRules || typeof this.resortData.childrenRules === 'undefined') {
          this.resortData.childrenRules = this.languages;
        }
        return this.resortData;
      } catch (err) {
        return err;
      }
  }

  async updateSeasonTimeslot(seasonObj) {
    let array = await this.getTimeSlots(this.resortData.id,seasonObj.id);

    this.resortData.timeSlotsBySeason[seasonObj.id] =  array.sort(this.sortByStartHour);
  }

  async getOptionals() {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/optionals/';

      const token = firebaseUser.token;

      const obj = {};
      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();
      this.optionals = data[`results`];
      return this.optionals;
    } catch (err) {
      return err;
    }
  }

  async getTimeSlots(resortId:string, seasonId:string) {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/'+resortId+'/seasons/'+seasonId+'/timeslots/';

      const token = firebaseUser.token;

      let data:any = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

      data = data.sort(this.sortByStartHour);
      return data;
    } catch (err) {
      return err;
    }
  }

  async pushTimeSlot(resortId:string, seasonId:string, timeslot:any) {
    try {
      const now = new Date();

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/'+resortId+'/seasons/'+seasonId+'/timeslots/';

      const token = firebaseUser.token;

      const data = await this.http.post(api,timeslot, { headers: {Authorization: token}}).toPromise();
      console.log(data)
      return data;
    } catch (err) {
      return err;
    }
  }

  async updateTimeSlot(resortId:string, seasonId:string, timeslot:any) {
    try {

      const now = new Date();
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/'+resortId+'/seasons/'+seasonId+'/timeslots/'+timeslot.id+'/';

      const token = firebaseUser.token;

      const data = await this.http.put(api,timeslot, { headers: {Authorization: token}}).toPromise();
      return data;
    } catch (err) {
      return err;
    }
  }

  async deleteTimeSlot(resortId:string, seasonId:string, timeslot:any) {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/'+resortId+'/seasons/'+seasonId+'/timeslots/'+timeslot.id;

      const token = firebaseUser.token;

      const data = await this.http.delete(api, { headers: {Authorization: token}}).toPromise();
      return data[`results`];
    } catch (err) {
      return err;
    }
  }


  async getRules() {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/rules/';

      const token = firebaseUser.token;

      const obj = {};
      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();
      this.rules = data[`results`];
      return this.rules;
    } catch (err) {
      return err;
    }
  }

  async getOffers() {
    try {

      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_offers/';

        const token = firebaseUser.token;

        const obj = {};
        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();
        console.log(data);

        this.offers = data;
        return data;
      } else {
        return 'err';
      }
    } catch (err) {
      return err;
    }
  }

  async getCoupons() {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/coupons/';

        const token = firebaseUser.token;

        const obj = {};
        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();
        return data;
      } else {
        return 'err';
      }
    } catch (err) {
      return err;
    }
  }

  async getElements() {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/elements/';

      const token = firebaseUser.token;

      const obj = {};
      const data = await this.http.get(api, { headers: { Authorization : token}}).toPromise();

      data[`results`].map(element => {
        element.quantity = 0;
        this.elementsByIds[element.id] = element;
      });

      this.elements = data[`results`];
      return this.elements ;
    } catch (err) {
      return err;
    }
  }

  async getFeatures() {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/package_features/';

      const token = firebaseUser.token;

      const obj = {};
      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();
      data[`results`].map(element => {
        element.selected = false;
      });

      this.features = data[`results`];
      return data[`results`];
    } catch (err) {
      return err;
    }
  }

  async getServices() {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/services/';

      const token = firebaseUser.token;

      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();
      data[`results`].map(service => {
        service.selected = false;
      });

      this.services = data[`results`];
      return data[`results`];
    } catch (err) {
      return err;
    }
  }

  async getReviews() {

    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/reviews/';

        const token = firebaseUser.token;
        console.log(api);
        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        return data;
      } else {
        return 'err';
      }
    } catch (err) {
      return err;
    }
  }

  async getPackageServices() {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/package_services/';

      const token = firebaseUser.token;

      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();
      data[`results`].map(service => {
        service.selected = false;
      });

      this.packagesServices = data[`results`];
      return data[`results`];
    } catch (err) {
      return err;
    }
  }

  async getPrices() {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/prices/?resortId=' + this.resortData.id + '&size=100000';

        const token = firebaseUser.token;

        const obj = {};
        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();
        this.pricesArray = data['results'];
        for (const price of data[`results`]) {

          let priceKey = null;

          if (price.package_id && price.optional_id) {
            priceKey = price.package_id + '_' + price.optional_id + '_' + price.zone_id + '_' + price.season_id + '_' + price.timeSlot_id;
          } else if (price.extra_id){
            priceKey = 'extra_' + price.extra_id + '_' + price.season_id+ '_' + price.timeSlot_id;
          } else {
            priceKey = price.package_id + '_' + price.zone_id + '_' + price.season_id+ '_' + price.timeSlot_id;
          }

          if (priceKey) {
            obj[priceKey] = price;
          }
        }

        this.prices = obj;
        console.log(JSON.parse(JSON.stringify(obj)));
        return obj;
      } else {
        return 'err';
      }
    } catch (err) {
      return err;
    }
  }

  async getExtraPrices(extraId) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/extras/' + extraId + '/prices/';

        const token = firebaseUser.token;

        const obj = {};
        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();
        console.log(data);

        for (let i = 0; i < data[`results`].length; i++) {
          const price = data[`results`][i];

          let priceKey = null;

          priceKey = 'extra_' + price.extra_id + '_' + price.season_id+'_'+price.timeSlot_id;

          if (priceKey) {
            obj[priceKey] = price;
          }
        }

        return obj;
      } else {
        return 'err';
      }
    } catch (err) {
      return err;
    }
  }

  async setExtraPrices(extraId, prices) {
    try {
      const api = environment.apiURL + 'resorts/' + this.resortData.id + '/extras/' + extraId + '/prices/';

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
      const token = firebaseUser.token;
      const data = await this.http.post(api, prices, { headers: { Authorization : token}}).toPromise();

      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getUserData() {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'users_managers/manager_subscriptions/';
      const token = firebaseUser.token;

      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

      this.userData = data[`result`];
      this.userData.suite = false;

      if (this.isSuperManager) {
        this.userData.role = 'supermanager';
      }

      if (this.userData.role === 'superowner') {
        this.userData.supermanager = true;
      }

      if (this.userData.subscriptions[0].planId === 3) {
        this.userData.suite = true;
      }

      if (!this.userData.mapLayoutMode) {
        this.userData.mapLayoutMode = 'icons';
      }


      console.log("MANAGER DATA");
      console.log(this.userData);
      return data[`result`];

    } catch (err) {
      return err;
    }
  }

  async getReservationsByUrl(url) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
      const token = firebaseUser.token;
      const data = await this.http.get(url, { headers: {Authorization: token}}).toPromise();

      return data;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getCustomerReservations(customerId) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'reservations/?resortId=' + this.resortData.id + '&customerId=' + customerId;

        const token = firebaseUser.token;
        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        return data;
      } else {
        return 'err';
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getNoSlotsReservations(start, end) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'reservations/';

        const token = firebaseUser.token;

        let params = '?';

        if (start) {
          params += 'start=' + start + '&';
        }

        if (end) {
          params += 'end=' + end + '&';
        }

        params += 'noSlots=true&cart=false&resortId=' + this.resortData.id + '&';

        console.log(api + params);

        const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();

        return data;
      } else {
        return 'err';
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getReservations(start, end, forceStart, forceEnd, searchKey, state, zones, packages, optionals, daily, settled, cabins, paymentType, slotIds, slotLabel, size, page, updateView, extras, discounts, checkInFlag, orderBy, noSlots, refundState, coupons, freeSlots, onlyQuotation, quotationExpirationStart, quotationExpirationEnd) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'reservations/';

        const token = firebaseUser.token;

        let params = '?';

        if (start) {
          params += 'start=' + start + '&';
        }

        if (end) {
          params += 'end=' + end + '&';
        }

        if (forceStart) {
          params += 'forceStart=' + forceStart + '&';
        }

        if (forceEnd) {
          params += 'forceEnd=' + forceEnd + '&';
        }


        if (slotLabel) {
          params += 'slotLabel=' + slotLabel.replace(/ /g, '') + '&';
        }

        if (searchKey) {
          params += 'nameOrSlot=' + searchKey.toLowerCase() + '&';
        }


        if (state && state.length) {
          if (state.indexOf(1) > -1 ) {
            params += 'cocoBooking=true&';
          } else {
            if (state.indexOf(2) === -1 ) {
              params += 'cocoBooking=false&';
            }
          }

          if (state.indexOf(2) > -1 ) {
            params += 'seasonTicket=true&';
          } else {

            if (state.indexOf(1) === -1 ) {
              params += 'seasonTicket=false&';
            }
          }
        }

        /* if (state != null && state == 1) {
          params += 'cocoBooking=true&';
        }

        if (state != null && state === 0) {
          params += 'cocoBooking=false&seasonTicket=false&';
        }

        if (state != null && state == 2) {
          params += 'seasonTicket=true&';
        }
        console.log(state);
        if (state != null && state == 3) {
          params += 'cocoBooking=false&';
        } */

        if (zones) {
          params += 'zoneIds=' + zones + '&';
        }

        if (packages) {
          params += 'packageIds=' + packages + '&';
        }

        if (extras) {
          params += 'extrasIds=' + extras + '&';
        }

        if (discounts) {
          params += 'discountsIds=' + discounts + '&';
        }


        if (coupons) {
          params += 'couponsIds=' + coupons + '&';
        }

        if (optionals) {
          params += 'optionalsCounter=1&';
        }

        if (daily)  {
          params += 'daily=true&';
        }

        if (settled !== null) {
          params += 'settled=' + settled + '&';
        }

        if (noSlots) {
          params += 'noSlots=' + noSlots + '&';
        }

        if (slotIds) {
          params += 'slotIds=' + slotIds + '&';
        }

        if (cabins) {
          params += 'cabins=' + cabins + '&';
        }

        if (paymentType != null) {
          params += 'billsPaymentType=' + paymentType + '&';
        }

        if (checkInFlag === true) {
          params += 'checkInFlag=true&';
        }

        if (orderBy !== null) {
            params += 'ordering='+orderBy+'&';
        }

        if (refundState !== null) {
          params += 'refunds='+refundState+'&';
        }
        
        if (onlyQuotation) {
          params += 'hasQuotation=' + onlyQuotation + '&';

          if (quotationExpirationStart) {
            params+='quotationExpirationStart='+quotationExpirationStart+'&';
            params+='quotationExpirationEnd='+(quotationExpirationEnd)+'&';
          }
        }

        params += 'cart=false&resortId=' + this.resortData.id + '&';

        const sumParams = params;

        if (size) {
          params += 'size=' + size + '&';
        }

        if (page && updateView) {
          params += 'page=' + page;
        }

        console.log(api + params);

        this.lastReservationsQuery = api+params;
        const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();

        return data;
      } else {
        return 'err';
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getTotalAmount(start, end, forceStart, forceEnd, searchKey, state, zones, packages, optionals, daily, settled, cabins, paymentType, extras, discounts, checkInFlag, noSlots, refundState, coupons, freeSlots, onlyQuotation, quotationExpirationStart, quotationExpirationEnd) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'reservations/sum_order_total/';

        const token = firebaseUser.token;

        let params = '?';

        if (start) {
          params += 'start=' + start + '&';
        }

        if (end) {
          params += 'end=' + end + '&';
        }

        if (forceStart) {
          params += 'forceStart=' + forceStart + '&';
        }

        if (forceEnd) {
          params += 'forceEnd=' + forceEnd + '&';
        }

        if (searchKey) {
          params += 'nameOrSlot=' + searchKey.toLowerCase() + '&';
        }

        if (noSlots) {
          params += 'noSlots=' + noSlots + '&';
        }

        
        if (onlyQuotation) {
          params += 'hasQuotation=' + onlyQuotation + '&';
          
          if (quotationExpirationStart) {
            params+='quotationExpirationStart='+quotationExpirationStart+'&';
            params+='quotationExpirationEnd='+quotationExpirationEnd+'&';
          }
        }

        /* if (state != null && state == 1) {
          params += 'cocoBooking=true&';
        }

        if (state != null && state === 0) {
          params += 'cocoBooking=false&seasonTicket=false&';
        }

        if (state != null && state == 2) {
          params += 'seasonTicket=true&';
        }
        */

        if (state && state.length) {
          if (state.indexOf(1) > -1 ) {
            params += 'cocoBooking=true&';
          } else {
            if (state.indexOf(2) === -1 ) {
              params += 'cocoBooking=false&';
            }
          }

          if (state.indexOf(2) > -1 ) {
            params += 'seasonTicket=true&';
          } else {

            if (state.indexOf(1) === -1 ) {
              params += 'seasonTicket=false&';
            }
          }
        }
        if (zones) {
          params += 'zoneIds=' + zones + '&';
        }

        if (packages) {
          params += 'packageIds=' + packages + '&';
        }

        if (extras) {
          params += 'extrasIds=' + extras + '&';
        }

        if (discounts) {
          params += 'discountsIds=' + discounts + '&';
        }

        if (optionals) {
          params += 'optionalsCounter=1&';
        }

        if (daily) {
          params += 'daily=true&';
        }

        if (settled !== null) {
          params += 'settled=' + settled + '&';
        }

        if (cabins) {
          params += 'cabins=' + cabins + '&';
        }

        if (paymentType != null) {
          params += 'billsPaymentType=' + paymentType + '&';
        }

        if (refundState !== null) {
          params += 'refunds='+refundState+'&';
        }

        if (coupons) {
          params += 'couponsIds=' + coupons + '&';
        }

        params += 'cart=false&resortId=' + this.resortData.id + '&';

        const sumParams = params;

        console.log(api + params);

        const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();
        console.log(data);
        return data;
      } else {
        return 'err';
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getPayments(managerUID, start, end, forceStart, forceEnd, searchKey, cocoManagerOrSeason, cocoManagerOrBooking, seasonOrBooking, typeCocobuk, typeSeasonal, zones, packages, optionals, daily, settled, cabins, slotIds, slotLabel, fiscalPrinted, paymentType, fiscalPrinter, size, page, updateView) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'reservations/';

        const token = firebaseUser.token;

        let params = '?';

        if (managerUID && fiscalPrinted) {
          params += 'billsPrintedBy=' + managerUID + '&';
        } else if (managerUID) {
          params += 'billsCreatedBy=' + managerUID + '&';
        }

        if (start) {
          params += 'startPayment=' + start + '&';
        }

        if (end) {
          params += 'endPayment=' + end + '&';
        }

        if (forceStart) {
          params += 'forceStart=' + forceStart + '&';
        }

        if (forceEnd) {
          params += 'forceEnd=' + forceEnd + '&';
        }

        if (slotLabel) {
          params += 'slotLabel=' + slotLabel.replace(/ /g, '') + '&';
        }

        if (searchKey) {
          params += 'nameOrSlot=' + searchKey.toLowerCase() + '&';
        }

        if (cocoManagerOrBooking != null) {
          params += 'cocoManagerOrBooking=' + cocoManagerOrBooking + '&';
        }

        if (cocoManagerOrSeason != null) {
          params += 'cocoManagerOrSeason=' + cocoManagerOrSeason + '&';
        }

        if (typeCocobuk != null) {
          params += 'cocoBooking=' + typeCocobuk + '&';
        }

        if (typeSeasonal != null) {
          params += 'seasonTicket=' + typeSeasonal + '&';
        }

        if(seasonOrBooking != null) {
          params += 'seasonOrBooking=' + seasonOrBooking + '&';
        }

        if (zones) {
          params += 'zoneIds=' + zones + '&';
        }

        if (packages) {
          params += 'packageIds=' + packages + '&';
        }

        if (optionals) {
          params += 'optionalsCounter=1&';
        }

        if (daily)  {
          params += 'daily=true&';
        }

        if (settled !== null) {
          params += 'settled=' + settled + '&';
        }

        if (slotIds) {
          params += 'slotIds=' + slotIds + '&';
        }

        if (cabins) {
          params += 'cabins=' + cabins + '&';
        }

        if (fiscalPrinter) {
          params += 'billsFiscalPrinter=' + fiscalPrinter + '&';
        }

        if (fiscalPrinted != null) {
          params += 'billsFiscalPrinted=' + fiscalPrinted + '&';
        }

        if (paymentType != null) {
          params += 'billsPaymentType=' + paymentType + '&';
        }

        if (cabins) {
          params += 'cabins=' + cabins + '&';
        }

        params += 'cart=false&resortId=' + this.resortData.id + '&billsPayed=true&';

        const sumParams = params;

        if (size) {
          params += 'size=' + size + '&';
        }

        if (page && updateView) {
          params += 'page=' + page;
        }

        console.log(api + params);

        const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();

        return data;
      } else {
        return 'err';
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getTotalCustomerBillNotPaid(start,end,startPayment,endPayment,type) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'reservations/sum_customer_bill/';

        const token = firebaseUser.token;

        let params = '?';

        if (start) {
          params += 'start=' + start + '&';
        }

        if (end) {
          params += 'end=' + end + '&';
        }

        if (startPayment) {
          params += 'startPayment=' + start + '&';
        }

        if (endPayment) {
          params += 'endPayment=' + end + '&';
        }

        if (type) {
          params += 'billsType=' + type + '&';
        }


        params += 'cart=false&resortId=' + this.resortData.id + '&billsPayed=false&';

        console.log(api + params);

        const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();

        return data['total_payed'];

      }
    } catch(err) {
      console.log(err);
      return err;
    }
  }

  async getTotalPaymentsAmount(managerUID, start, end, forceStart, forceEnd, searchKey, cocoManagerOrSeason, cocoManagerOrBooking, seasonOrBooking, typeCocobuk, typeSeasonal, zones, packages, optionals, daily, settled, cabins, slotIds, slotLabel,fiscalPrinted, paymentType, fiscalPrinter, size, page, updateView) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'reservations/sum_customer_bill/';

        const token = firebaseUser.token;

        let params = '?';

        if (managerUID && fiscalPrinted) {
          params += 'billsPrintedBy=' + managerUID + '&';
        } else if (managerUID) {
          params += 'billsCreatedBy=' + managerUID + '&';
        }


        if (start) {
          params += 'startPayment=' + start + '&';
        }

        if (end) {
          params += 'endPayment=' + end + '&';
        }

        if (forceStart) {
          params += 'forceStart=' + forceStart + '&';
        }

        if (forceEnd) {
          params += 'forceEnd=' + forceEnd + '&';
        }

        if (slotLabel) {
          params += 'slotLabel=' + slotLabel.replace(/ /g, '') + '&';
        }

        if (searchKey) {
          params += 'nameOrSlot=' + searchKey.toLowerCase() + '&';
        }

        if (typeCocobuk != null) {
          params += 'cocoBooking=' + typeCocobuk + '&';
        }

        if (typeSeasonal != null) {
          params += 'seasonTicket=' + typeSeasonal + '&';
        }

        if(seasonOrBooking != null) {
          params += 'seasonOrBooking=' + seasonOrBooking + '&';
        }

        if (zones) {
          params += 'zoneIds=' + zones + '&';
        }

        if (packages) {
          params += 'packageIds=' + packages + '&';
        }

        if (optionals) {
          params += 'optionalsCounter=1&';
        }

        if (daily)  {
          params += 'daily=true&';
        }

        if (settled !== null) {
          params += 'settled=' + settled + '&';
        }

        if (slotIds) {
          params += 'slotIds=' + slotIds + '&';
        }

        if (cabins) {
          params += 'cabins=' + cabins + '&';
        }

        if (fiscalPrinter) {
          params += 'billsFiscalPrinter=' + fiscalPrinter + '&';
        }

        if (fiscalPrinted != null) {
          params += 'billsFiscalPrinted=' + fiscalPrinted + '&';
        }

        if (paymentType != null) {
          params += 'billsPaymentType=' + paymentType + '&';
        }

        params += 'cart=false&resortId=' + this.resortData.id + '&billsPayed=true&';

        if (size) {
          params += 'size=' + size + '&';
        }

        if (page && updateView) {
          params += 'page=' + page;
        }

        console.log(api + params);

        const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();

        return data['total_payed'];
      } else {
        return 'err';
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getMapAvailabilitiesBySlotIds(slots) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/map_with_reservations_resort_managed/';

      const token = firebaseUser.token;

      let params = '?';

      if (slots) {
        params += 'slotsIds=' + slots + '&';
      }

      console.log(api + params);

      const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();
      const output = {};
      const timeSlotsOutput = {};

      for (const cell of data[`results`]) {

        if (!output[cell.row + '_' + cell.col] )
          output[cell.row + '_' + cell.col] = [];

        for (const dates of cell.reservationsKeys) {
          if (!timeSlotsOutput[cell.row + '_' + cell.col+'_'+dates.day_timestamp])
            timeSlotsOutput[cell.row + '_' + cell.col+'_'+dates.day_timestamp] = [];

          if (dates.state !== 1) {
            if (output[cell.row + '_' + cell.col].indexOf(dates.day_timestamp) === -1) {
              output[cell.row + '_' + cell.col].push(dates.day_timestamp);
            }
          }

          if (dates.timeSlot && !timeSlotsOutput[cell.row + '_' + cell.col+'_'+dates.day_timestamp].find(t => t.id === dates.timeSlot.id)) {
            timeSlotsOutput[cell.row + '_' + cell.col+'_'+dates.day_timestamp].push(dates.timeSlot);
          }
        }


      }

      return {
        slots_availabilities: output,
        timeSlots_availabilities: timeSlotsOutput
      };

    } catch (err) {
      return err;
    }
  }

  async getMapReservations(start, end) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/map_with_reservations_resort_managed/';

      const token = firebaseUser.token;

      let params = '?';

      if (start) {
        params += 'startDate=' + start + '&';
      }

      if (end) {
        params += 'endDate=' + end + '&';
      }

      console.log(api + params);

      const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();
      const output = {};

      for (const cell of data[`results`]) {

        if (!output[cell.row]) {
          output[cell.row] = {};
        }

        output[cell.row][cell.col] = cell;
        output[cell.row][cell.col].state = null;
        output[cell.row][cell.col].settled = false;


        for (let j = 0; j < cell.reservationsKeys.length; j++) {
            if (output[cell.row][cell.col].state && output[cell.row][cell.col].state != cell.reservationsKeys[j].state) {
              output[cell.row][cell.col].state = 0;
            } else {
              output[cell.row][cell.col].state = cell.reservationsKeys[j].state;
            }

            if (cell.reservationsKeys[j].settled) {
              output[cell.row][cell.col].settled = true;
            }
        }
      }

      this.mapReservations = output;
      return output;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getMapReservationsOptimized(start,end) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/maps_reservation_slots/';

      const token = firebaseUser.token;

      let params = '?';


      if (start) {
        params += 'start=' + start + '&';
      }

      if (end) {
        params += 'end=' + end;
      }

      console.log(api + params);

      const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();
      const output = {};

      let slotsById = [];

      for (const slot of data[`results`]) {
        let obj = JSON.parse(JSON.stringify(slot));
        let findSlot = slotsById.find(s => s.id === slot.id)
        if (!findSlot) {
            slotsById.push(slot);
        } else {
          findSlot.reservations = findSlot.reservations.concat(obj.reservations);
        }

      }


      for (let slot of slotsById) {
        let cell = this.map.find(m => m.id === slot.id);
        if (cell && cell.state !== null) {

          if (!output[cell.row]) {
            output[cell.row] = {};
          }

          output[cell.row][cell.col] = cell;
          output[cell.row][cell.col].state = slot.state;
          output[cell.row][cell.col].settled = slot.settled;
          output[cell.row][cell.col].reservationsKeys = [];
          output[cell.row][cell.col].tooltip = false;
          output[cell.row][cell.col].selectedPackage = '';
          output[cell.row][cell.col].packageShortString = '';

          if (slot.reservations && slot.reservations.length) {

            slot.reservations = this.setRecapParams(slot.reservations, this.lang);
            output[cell.row][cell.col].reservationsKeys = slot.reservations;
            output[cell.row][cell.col] = this.setCellShortParams(output[cell.row][cell.col], slot.reservations[0]);
          }
        }
      }
      console.log(output);
      this.mapReservations = output;
      return output;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getReceipt(code) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.firebaseApiUrl + '/getReceipt/';

      const token = firebaseUser.token;

      let params = '?';

      params+='reservationCode='+code;

      console.log(api + params);

      const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getInvoiceList(start,end) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/fiscal_invoices/';

      const token = firebaseUser.token;

      let params = '?';

      params+='resortId='+this.resortData.id+'&';


      if (start) {
        params += 'start=' + start + '&';
      }

      if (end) {
        params += 'end=' + end+'&';
      }

      console.log(api + params);

      const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }

  }

  async getReservationsBySlots(start,end,slotIds) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/';

      const token = firebaseUser.token;

      let params = '?';

      params+='resortId='+this.resortData.id+'&';


      if (start) {
        params += 'start=' + start + '&';
      }

      if (end) {
        params += 'end=' + end+'&';
      }

      if (slotIds) {
        params += 'slotIds=' + slotIds+'&';
      }

      console.log(api + params);

      const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
          const key = keyGetter(item);
          const collection = map.get(key);
          if (!collection) {
              map.set(key, [item]);
          } else {
              collection.push(item);
          }
      });
      return map;
  }

  async getMap() {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/map_resort_managed/';
      const token = firebaseUser.token;
      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

      const output = {};

      for (const slot of data[`results`]) {
        const row = slot.row;
        const col = slot.col;

        if (!output[row]) {
          output[row] = {};
        }

        if (this.userData && this.userData.zonesDisabled && this.userData.zonesDisabled.indexOf(slot.zoneId) > -1) {
          slot.zoneId = 0;
        }
        output[row][col] = slot;

      }

      this.mapByRowCol = output;

      this.map = data[`results`];
      return data[`results`];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getCustomers(name, booking, size, page, totals) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'users_customers/';

        const token = firebaseUser.token;

        let params = '?';

        if (name)  {
          /* params += 'associatedNames=' + name + '&'; */
          params += 'names=' + name + '&';
        }

        if (booking !== null) {
          params += 'booking=' + booking + '&';
        }

        if (page) {
          params += 'page=' + page + '&';
        }

        if (!totals) {
          params += 'hideTotals=true&';
        }

        params += 'resortId=' + this.resortData.id + '&size=' + size;
        console.log(api+params);
        this.lastCustomersQuery = api+params;
        const data = await this.http.get(api + params, { headers: {Authorization: token}}).toPromise();

        return data;
      } else {
        return 'err';
      }

    } catch (err) {
      return err;
    }
  }

  async getMapAreas() {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/' + this.resortData.id + '/map_areas/';

      const token = firebaseUser.token;

      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

      this.mapAreas = data['results'];
      return data;
    } else {
      return 'err';
    }

    } catch (err) {
      return err;
    }
  }

  async getBookingIntervals() {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/booking_intervals/';

        const token = firebaseUser.token;

        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        this.bookingIntervals = data;
        return data;
      } else {
        return 'err';
      }

    } catch (err) {
      return err;
    }
  }

  async getDataByUrl(url) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
      const token = firebaseUser.token;
      const data = await this.http.get(url, { headers: {Authorization: token}}).toPromise();

      return data;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getSingleCustomer(id) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'users_customers/' + id + '/';

      const token = firebaseUser.token;

      const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

      return data;

    } catch (err) {
      return err;
    }
  }

  async getSingleReservation(id) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      if (id && typeof id !== 'undefined') {
        const api = environment.apiURL + 'reservations/' + id + '/';

        const token = firebaseUser.token;
        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        return data;
      } else {
        return null;
      }
    } catch (err) {
      return err;
    }
  }

  
  async getSingleReservationByCode(code:string) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      if (code && typeof code !== 'undefined') {
        const api = environment.apiURL + 'reservations/?code=' + code;

        const token = firebaseUser.token;
        const data:any = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        return data.results[0];
      } else {
        return null;
      }
    } catch (err) {
      return err;
    }
  }

  async getAvailabilities(start, end) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_free_availability/?start=' + start + '&end=' + end;
        console.log(api);
        const token = firebaseUser.token;

        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        return data;

      } else {
        return 'err';
      }
    } catch (err) {
      return err;
    }
  }

  async getAvailabilitiesV2(start, end) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_order_availability/?start=' + start + '&end=' + end+'&size=200000';
        console.log(api);
        const token = firebaseUser.token;

        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        return data;

      } else {
        return 'err';
      }
    } catch (err) {
      return err;
    }
  }


  async setAvailabilities(combo, start, end) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const token = firebaseUser.token;
        if (combo.id) {
          const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_free_availability/' + combo.id + '/?start=' + start + '&end=' + end;
          console.log(api);
          const data = await this.http.put(api, combo, { headers: { Authorization : token}}).toPromise();
          return data;
        } else {
          const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_free_availability/';
          console.log(api);
          const data = await this.http.post(api, combo, { headers: { Authorization : token}}).toPromise();
          return data;
        }

      } else {
        return 'err';
      }


    } catch (err) {
      return err;
    }
  }

  async getExtraAvailabilitiesV2(start, end) {
    try {
      let size = 2000;
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_extra_availability/?size='+size+'&start=' + start + '&end=' + end;
        console.log(api);
        const token = firebaseUser.token;

        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        return data;

      } else {
        return 'err';
      }
    } catch (err) {
      return err;
    }
  }

  async getPackageAvailability(start,end,packageId) {
    try {
      let size = 2000;
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_order_availability/?size='+size+'&start=' + start + '&end=' + end+'&packageId='+packageId;
        console.log(api);
        const token = firebaseUser.token;

        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        return data;

      } else {
        return 'err';
      }
    } catch (err) {
      return err;
    }
  }

  async getExtraAvailability(start,end,packageId) {
    try {
      let size = 2000;
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_extra_availability/?size='+size+'&start=' + start + '&end=' + end+'&extraId='+packageId;
        console.log(api);
        const token = firebaseUser.token;

        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        return data;

      } else {
        return 'err';
      }
    } catch (err) {
      return err;
    }
  }


  async getExtraAvailabilities(start, end) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/extras/?start=' + start + '&end=' + end;
        console.log(api);
        const token = firebaseUser.token;

        const data = await this.http.get(api, { headers: {Authorization: token}}).toPromise();

        return data;

      } else {
        return 'err';
      }
    } catch (err) {
      return err;
    }
  }

  async setExtraAvailabilities(extra, start, end) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const token = firebaseUser.token;
        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/extras/' + extra.id + '/?start=' + start + '&end=' + end;
        console.log(api);
        const data = await this.http.put(api, extra, { headers: { Authorization : token}}).toPromise();
        return data;
      } else {
        return 'err';
      }


    } catch (err) {
      return err;
    }
  }

  async setExtraAvailabilitiesV2(extra) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const token = firebaseUser.token;
        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_extra_availability/';
        console.log(api);
        const data = await this.http.post(api, extra, { headers: { Authorization : token}}).toPromise();
        return data;
      } else {
        return 'err';
      }


    } catch (err) {
      return err;
    }
  }

  async setPackagesAvailabilitiesV2(pack) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
        const token = firebaseUser.token;
        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_order_availability/';
        console.log(api);
        const data = await this.http.post(api, pack, { headers: { Authorization : token}}).toPromise();
        return data;
      } else {
        return 'err';
      }


    } catch (err) {
      return err;
    }
  }


  // RESERVATION

  async saveAndCheckInReservation(dataObj: Reservation) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/?doCheckIn=true';
      const token = firebaseUser.token;

      if (dataObj.order['reservedPrice'] === null) {
        delete dataObj.order['reservedPrice'];
      }

      const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async checkInSlot(reservationId,dateKey,slotKeys,start,end, createdBy) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/'+reservationId+'/checkin/';
      const token = firebaseUser.token;

      let dataObj = {
        date_key: dateKey,
        slot_keys: slotKeys,
        start,
        end,
        createdBy
      }
      
      console.log(dataObj);
      const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async checkOutSlot(reservationId,dateKey,slotKeys,start,end, createdBy) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/' + reservationId + '/checkout/';
      const token = firebaseUser.token;

      const dataObj = {
        date_key: dateKey,
        slot_keys: slotKeys,
        start,
        end,
        createdBy
      };

      const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();

      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async freeSlot(reservationId,dateKey,slotKeys,start,end, createdBy, infos) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/'+reservationId+'/free/';
      const token = firebaseUser.token;

      let dataObj = {
        date_key: dateKey,
        slot_keys: slotKeys,
        start,
        end,
        createdBy,
        infos
      }

      const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async saveIpraticoReservation(reservation,customer, productId, ipraticoId) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
      const token = firebaseUser.token;
      /* const api = environment.firebaseApiUrl + 'ipraticoPushReservation'; */
      const api = 'https://europe-west1-coco-app.cloudfunctions.net/ipraticoPushReservation';
      let dataObj = {
        reservation,
        customer,
        productId,
        ipraticoId
      }
      const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }

  }

  async setReservation(dataObj: Reservation) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/';
      const token = firebaseUser.token;

      if (!dataObj.name) {
        dataObj.name = '';
      }

      if (!dataObj.phone) {
        dataObj.phone = '';
      }

      if (!dataObj.searchKey) {
        dataObj.searchKey = '';
      }

      if (dataObj.order['reservedPrice'] === null) {
        delete dataObj.order['reservedPrice'];
      }

      if (dataObj.seasonTicket) {
        dataObj.state = 5
      }

      this.setNoSlotsField(dataObj);
      const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updateReservation(dataObj: Reservation) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();


      if (dataObj.id && typeof dataObj.id !== 'undefined') {
        const api = environment.apiURL + 'reservations/' + dataObj.id + '/';

        const token = firebaseUser.token;

        if (!dataObj.name) {
          dataObj.name = '';
        }

        if (!dataObj.phone) {
          dataObj.phone = '';
        }

        if (!dataObj.searchKey) {
          dataObj.searchKey = '';
        }

        /* if (dataObj.order['reservedPrice'] === null) {
          delete dataObj.order['reservedPrice'];
        } */

        if (dataObj.seasonTicket) {
          dataObj.state = 5
        }

        this.setNoSlotsField(dataObj);
        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
        return data;
      } else {
        return null;
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  setNoSlotsField(reservation) {
    reservation.noSlots = true;

    for (let interval of reservation.dates) {
      for (let order of interval.order) {
        if (order.type === 'slot') {
          reservation.noSlots = false;
          break;
        }
      }
    }
  }


  async updateFakeReservation(dataObj: Reservation) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();


      if (dataObj.id && typeof dataObj.id !== 'undefined') {
        const api = environment.apiURL + 'reservationsa/' + dataObj.id + '/';

        const token = firebaseUser.token;

        if (!dataObj.name) {
          dataObj.name = '';
        }

        if (!dataObj.phone) {
          dataObj.phone = '';
        }

        if (!dataObj.searchKey) {
          dataObj.searchKey = '';
        }

        if (dataObj.order['reservedPrice'] === null) {
          delete dataObj.order['reservedPrice'];
        }

        if (dataObj.seasonTicket) {
          dataObj.state = 5
        }

        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
        return data;
      } else {
        return null;
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async removeReservation(dataObj: Reservation) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
      if (dataObj.id && typeof dataObj.id !== 'undefined') {
        const api = environment.apiURL + 'reservations/' + dataObj.id + '/';

        const token = firebaseUser.token;

        const options = {
          headers: new HttpHeaders({
            Authorization : token
          }),
          body: dataObj
        };

        const data = await this.http.delete(api, options).toPromise();

        return data;
      } else {
        return null;
      }

    } catch (err) {
      return err;
    }
  }

  async removeBulkReservations(start,end, state, fiscalPrinted) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/';

      let params = '?';

      if (start) {
        params += 'start=' + start + '&';
      }

      if (end) {
        params += 'end=' + end + '&';
      }

      
      if (state && state.length) {
        if (state.indexOf(1) > -1 ) {
          params += 'cocoBooking=true&';
        } else {
          if (state.indexOf(2) === -1 ) {
            params += 'cocoBooking=false&';
          }
        }

        if (state.indexOf(2) > -1 ) {
          params += 'seasonTicket=true&';
        } else {

          if (state.indexOf(1) === -1 ) {
            params += 'seasonTicket=false&';
          }
        }
      }


      if (fiscalPrinted != null) {
        params += 'billsFiscalPrinted=' + fiscalPrinted + '&';
      }

      params += 'cart=false&strictlyIncluded=true&resortId=' + this.resortData.id + '&';

      const token = firebaseUser.token;

      const options = {
        headers: new HttpHeaders({
          Authorization : token
        })
      };

      console.log("BULK RESERVATIONS")
      console.log(api+params)

      const data = await this.http.delete(api+params, options).toPromise();
      return data;

    } catch (err) {
      return err;
    }
  }

  async refundReservation(dataObj) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
      const api = environment.apiURL + 'reservations/reservation_refund/';

      const token = firebaseUser.token;

      dataObj.updatedBy = {
        name: this.userData.first_name + ' ' + this.userData.last_name,
        id: this.userData.id,
        role: this.userData.role,
        email: this.userData.email,
        uid: this.userData.uid,
        version: environment.version,
        platform: 'manager_web'
      };
  

      const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();

      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async deleteCabin(dataObj) {
    const index = this.resortData.cabins.findIndex(cab => cab.id === dataObj.id);
    this.resortData.cabins.splice(index, 1);
    await this.setResort(this.resortData);
  }


  async replyReview(obj) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const token = firebaseUser.token;
      const api = environment.apiURL + 'resorts/' + this.resortData.id +'/reviews/'+obj.id+'/reply/';
      delete obj.id;
      const data = await this.http.put(api, obj, { headers: { Authorization : token}}).toPromise();
      return data;
    } catch(err) {
      console.log(err);
      return err;

    }
  }

  // OFFERS

  async createOffer(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id +'/packages_offers/';

        console.log(api);

        const token = firebaseUser.token;
        delete dataObj.id;

        const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updateOffer(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_offers/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;


        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async deleteOffer(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages_offers/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;

        const options = {
          headers: new HttpHeaders({
            Authorization : token
          })
        };

        const data = await this.http.delete(api, options).toPromise();
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // BOOKING INTERVALS

  async createBookingInterval(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/booking_intervals/';

        console.log(api);

        const token = firebaseUser.token;
        delete dataObj.id;

        const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updateBookingInterval(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/booking_intervals/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;
        console.log(JSON.stringify(dataObj));


        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async deleteBookingInterval(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/booking_intervals/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;

        const options = {
          headers: new HttpHeaders({
            Authorization : token
          })
        };

        const data = await this.http.delete(api, options).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // MAP AREAS

  async createMapArea(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/map_areas/';

        console.log(api);

        const token = firebaseUser.token;
        delete dataObj.id;
        console.log(JSON.stringify(dataObj));

        const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;


      } else {
        return 'err';
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updateMapArea(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/map_areas/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;
        console.log(JSON.stringify(dataObj));

        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async deleteMapArea(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/map_areas/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;

        const options = {
          headers: new HttpHeaders({
            Authorization : token
          })
        };

        const data = await this.http.delete(api, options).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }


  // SEASONS

  async createSeason(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/seasons/';

        console.log(api);

        const token = firebaseUser.token;
        delete dataObj.id;
        console.log(JSON.stringify(dataObj));

        const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);

        await this.updateSeasonTimeslot(data);

        this.resortData.seasons.push(data);
        for (const seasonObj of this.resortData.seasons) {
          this.resortData.seasonsByIds[seasonObj.id] = seasonObj;
        }
        this.resortData.seasonsPackages = this.resortData.seasons.filter(s => {return (s.packageType === 'package')?true:false; })
        this.resortData.seasonsExtras = this.resortData.seasons.filter(s => {return (s.packageType === 'extra')?true:false; })
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updateSeason(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/seasons/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;
        console.log(JSON.stringify(dataObj));


        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        await this.updateSeasonTimeslot(data);

        this.resortData.seasonsPackages = this.resortData.seasons.filter(s => {return (s.packageType === 'package')?true:false; })
        this.resortData.seasonsExtras = this.resortData.seasons.filter(s => {return (s.packageType === 'extra')?true:false; })
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async deleteSeason(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/seasons/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;

        const options = {
          headers: new HttpHeaders({
            Authorization : token
          })
        };

        const data = await this.http.delete(api, options).toPromise();
        console.log(data);

        this.resortData.seasonsPackages = this.resortData.seasons.filter(s => {return (s.packageType === 'package')?true:false; })
        this.resortData.seasonsExtras = this.resortData.seasons.filter(s => {return (s.packageType === 'extra')?true:false; })
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // ZONES

  async createZone(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/zones/';

        console.log(api);

        const token = firebaseUser.token;
        delete dataObj.id;
        console.log(JSON.stringify(dataObj));

        const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updateZone(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/zones/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;
        console.log(JSON.stringify(dataObj));


        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();

        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err['error'].toString());
      return err;
    }
  }

  async deleteZone(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/zones/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;

        const options = {
          headers: new HttpHeaders({
            Authorization : token
          })
        };

        const data = await this.http.delete(api, options).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // EXTRAS

  async createExtra(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/extras/';

        console.log(api);

        const token = firebaseUser.token;
        delete dataObj.id;
        console.log(JSON.stringify(dataObj));

        const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updateExtra(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/extras/' + dataObj.id + '/';

        console.log(api);

        console.log(JSON.stringify(dataObj));

        const token = firebaseUser.token;

        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async deleteExtra(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/extras/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;

        const options = {
          headers: new HttpHeaders({
            Authorization : token
          })
        };

        const data = await this.http.delete(api, options).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // DISCOUNTS

  async createDiscount(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/discounts/';

        console.log(api);

        const token = firebaseUser.token;
        delete dataObj.id;
        console.log(JSON.stringify(dataObj));

        const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updateDiscount(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/discounts/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;
        console.log(JSON.stringify(dataObj));


        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async deleteDiscount(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/discounts/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;

        const options = {
          headers: new HttpHeaders({
            Authorization : token
          })
        };

        const data = await this.http.delete(api, options).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // COUPONS

  async createCoupon(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/coupons/';

        console.log(api);

        const token = firebaseUser.token;
        delete dataObj.id;
        console.log(JSON.stringify(dataObj));

        const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updateCoupon(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/coupons/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;
        console.log(JSON.stringify(dataObj));


        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async deleteCoupon(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/coupons/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;

        const options = {
          headers: new HttpHeaders({
            Authorization : token
          })
        };

        const data = await this.http.delete(api, options).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // PACKAGES

  async createPackage(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages/';

        console.log(api);

        const token = firebaseUser.token;
        delete dataObj.id;
        console.log(JSON.stringify(dataObj));

        const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }


  async updatePackageSequence(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages/sequences/';
        const token = firebaseUser.token;
        console.log(api);
        console.log(JSON.stringify(dataObj));

        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updatePackage(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages/' + dataObj.id + '/';


        const token = firebaseUser.token;
        // @todo ADD DATA
        //packageToSave.optionals
        console.log(api);
        console.log(JSON.stringify(dataObj));

        const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async deletePackage(dataObj) {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/packages/' + dataObj.id + '/';

        console.log(api);

        const token = firebaseUser.token;

        const options = {
          headers: new HttpHeaders({
            Authorization : token
          })
        };

        const data = await this.http.delete(api, options).toPromise();
        console.log(data);
        return data;

      } else {
        return 'err';
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // RESORT

  async setResort(resortData) {
    try {
      const dataObj = JSON.parse(JSON.stringify(resortData));
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/' + dataObj.id + '/';

      console.log(api);

      const token = firebaseUser.token;

      dataObj.servicesAvailabilities = [];
      dataObj.services.map(serviceId => {
        dataObj.servicesAvailabilities.push({
          service: serviceId
        });
      });

      delete dataObj.seasons;
      delete dataObj.seasonsByIds;
      delete dataObj.zones;
      delete dataObj.zonesByIds;
      delete dataObj.packages;
      delete dataObj.packagesByIds;
      delete dataObj.discounts;
      delete dataObj.reviews;
      delete dataObj.extras;
      delete dataObj.extrasByIds;
      delete dataObj.services;

      const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
      console.log(data);
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // CUSTOMERS

  async setCustomer(dataObj) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'users_customers/';

      console.log(api);

      const token = firebaseUser.token;

      const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
      console.log(data);
      return data;

    } catch (err) {
      console.log(JSON.stringify(err));
      return err;
    }
  }

  async updateCustomer(dataObj) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'users_customers/' + dataObj.id + '/';

      console.log(api);

      const token = firebaseUser.token;
      const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();

      return data;

    } catch (err) {
      return err;
    }
  }

  async deleteCustomer(customerId) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'users_customers/' + customerId + '/';

      console.log(api);

      const token = firebaseUser.token;

      const options = {
        headers: new HttpHeaders({
          Authorization : token
        })
      };

      const data = await this.http.delete(api, options).toPromise();
      console.log(data);
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // RESERVATIONS MAPS

  async setReservationsMaps(dataObj) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/reservation_slots/';

      console.log(api);

      const token = firebaseUser.token;

      if (dataObj.notes === '') {
        delete dataObj.notes;
      }

      dataObj.createdAt = Math.trunc(new Date().getTime()/1000);
      dataObj.createdBy = {
        name: this.userData.first_name + ' ' + this.userData.last_name,
        id: this.userData.id,
        role: this.userData.role,
        email: this.userData.email,
        uid: this.userData.uid,
        version: environment.version,
        platform: 'manager_web'
      };

      const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
      console.log(data);
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updateReservationsMaps(dataObj) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/reservation_slots/';

      console.log(api);
      console.log(JSON.stringify(dataObj));

      const token = firebaseUser.token;

      const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
      return data;

    } catch (err) {
      return err;
    }
  }

  async removeReservationsMaps(dataObj) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/reservation_slots/';

      console.log(api);

      const token = firebaseUser.token;

      const options = {
        headers: new HttpHeaders({
          Authorization : token
        }),
        body: dataObj
      };


      if (dataObj.notes === '') {
        delete dataObj.notes;
      }

      console.log(dataObj);
      const data = await this.http.delete(api, options).toPromise();
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }


  async pushUnpaidSubscription(currentSubscription) {
    try {
      console.log("CREA NUOVA SUBSCRIPTION")
      let managers = await this.getResortManagers();
      const api = environment.apiURL + 'users_subscriptions/';

      let owners = [];
      console.log(managers);

      for (let manager of managers) {
        owners.push({
          "manager":manager.id
        });
      }

      let start = currentSubscription.end + 24*60*60;
      let end = start + 24*60*60*365;
      let amount = 349.99;

      console.log(start,end);
      if (this.resortData.settings.manager && this.resortData.settings.manager.renewalPrice) {
        amount = this.resortData.settings.manager.renewalPrice;
      }

      let subscription = {
          "owners": owners,
          "active": false,
          "duration": 1,
          "end": end,
          "movements": [
              {
                  "amount": amount,
                  "date": Math.trunc(new Date().getTime()/1000),
                  "name": "Rinnovo Licenza CocoManager",
                  "notes": "",
                  "paid": false,
                  "type": "license",
                  "stripe": {}
              }
          ],
          "payments": [],
          "planId": 3,
          "start": start
      }

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
      const token = firebaseUser.token;
      const data = await this.http.post(api, subscription, { headers: { Authorization : token}}).toPromise();

      console.log(data);
      return data;

    } catch(err) {

      console.log(err);
      return err;
    }


  }

  getSeasonsInReservation(start, length, extraId) {
    let seasons = this.resortData.seasonsPackages;

    if (extraId) {
      seasons = this.resortData.seasonsExtras.filter(s => { if(s.extra == extraId) return s});
    }

    const outObj = {};

    if (seasons) {
        for (let i = 0; i < length; i += 1) {

          const thisDateTimestamp = start + i * 24 * 60 * 60;

          for (const season of seasons) {
              if (!season.special && season.start <= thisDateTimestamp && thisDateTimestamp <= season.end) {
                  if (!outObj[season.id]) {
                    outObj[season.id] = {};
                    outObj[season.id].length = 1;
                    outObj[season.id].start = thisDateTimestamp;
                    outObj[season.id].end = thisDateTimestamp;
                    outObj[season.id].timeSlotsConfig = season.timeSlotsConfig;

                    break;

                  } else {

                    if (thisDateTimestamp < outObj[season.id].start) {
                      outObj[season.id].start = thisDateTimestamp;
                    }

                    if (thisDateTimestamp > outObj[season.id].end) {
                      outObj[season.id].end = thisDateTimestamp;
                    }

                    outObj[season.id].length += 1;
                  }

              }
          }
      }
    }

    return outObj;

  }

  checkIfSpecialSeason(start,end) {

    let seasons = this.resortData.seasonsPackages.filter(s => {
      if(s.special && s.start === start && s.end === end) return s
    });

    return seasons;
  }

  getSeason(start, length, extraId) {
    let seasons = this.resortData.seasonsPackages.filter(s => { if(!s.special) return s});

    if (extraId) {
      seasons = this.resortData.seasonsExtras.filter(s => { if(s.extra == extraId) return s});
    }

    const outObj = {};
    if (seasons) {
        for (let i = 0; i < length; i += 1) {

          const thisDateTimestamp = start + i * 24 * 60 * 60;

          for (const season of seasons) {
              if (season.start <= thisDateTimestamp && thisDateTimestamp <= season.end) {
                  if (!outObj[season.id]) {
                    outObj[season.id] = {};
                    outObj[season.id].length = 1;
                    outObj[season.id].start = thisDateTimestamp;
                    outObj[season.id].end = thisDateTimestamp;
                    outObj[season.id].timeSlotsConfig = season.timeSlotsConfig;

                    break;

                  } else {

                    if (thisDateTimestamp < outObj[season.id].start) {
                      outObj[season.id].start = thisDateTimestamp;
                    }

                    if (thisDateTimestamp > outObj[season.id].end) {
                      outObj[season.id].end = thisDateTimestamp;
                    }

                    outObj[season.id].length += 1;
                  }

              }
          }
      }
    }

    return outObj;

  }

  async updateMap(dataObj) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/map_multiple_slots/';

      console.log(api);

      const token = firebaseUser.token;

      const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getSingleSubscription(id) {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
      const api = environment.apiURL + 'users_subscriptions/'+id+'/';
      const token = firebaseUser.token;

      const data = await this.http.get(api,{ headers: { Authorization : token}}).toPromise();

      return data;

    } catch(err) {

    }
  }

  async confirmLicensePayment(id) {
    try {

      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();
      const api = environment.apiURL + 'users_subscriptions_confirm_payment/'+id+'/';
      const token = firebaseUser.token;

      const data = await this.http.put(api, { onlinePayment:true }, { headers: { Authorization : token}}).toPromise();
      console.log(data);
      return data;

    } catch(err) {
      console.log(err);
      return err;
    }
  }



  async setMap(dataObj) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/map_multiple_slots/';

      console.log(api);

      const token = firebaseUser.token;


      const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async deleteMap(dataObj) {

    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'resorts/map_multiple_slots/';

      console.log(api);

      const token = firebaseUser.token;

      const options = {
        headers: new HttpHeaders({
          Authorization : token
        }),
        body: dataObj
      };

      const data = await this.http.delete(api, options).toPromise();
      console.log(data);
      return data;

    } catch (err) {
      console.log(err);
      return err;
    }
  }


  // INPUT

  // --> prices               : OBJECT    -> contiene tutti i prezzi del resort come chiave del tipo
  //                          'pId_zId_sId_timeslotId'  se pacchetto (è una tupla del DB)
  //                          'pId_oId_zId_sId_timeslotId' se optional (è una tupla del DB)
  // --> start                : NUMBER    -> è la data di inizio prenotazione richiesta dall'utente in formato timestamp in secondi
  // --> seasonKeys           : OBJECT    -> è l'oggetto che vi torna la funzione 'getSeasons' applicata nella view precedente per ogni resort
  // --> optionalKey          : STRING    -> è l'id dell'optional in esame (OR esclusivo con packageKey)
  // --> packageKey           : STRING    -> è l'id del pacchetto in esame (OR esclusivo con optionalKey)
  // --> zoneKey              : STRING    -> è la zona del pacchetto in esame
  // --> timeslotId           : NUMBER    -> è il timeslot selezionato (0 = giornaliero)
  // --> weeklyPrice          : BOOLEAN   -> è il campo 'weeklyPrice'
  // --> proportionalPrice    : BOOLEAN   -> è il campo 'proportionalPrice'
  // --> monthlyPrice         : BOOLEAN   -> è il campo 'monthlyPrice'
  // --> seasonTicket         : BOOLEAN   -> indica se una prenotazione è stagionale o meno (su booking sempre FALSE)

  getPrice(prices, start, end, seasonKeys, packageKey, optionalKey, zoneKey, weeklyPrice, proportionalPrice, monthlyPrice, seasonTicket, dailyPrice, extraKey, interval, biweeklyPrice, timeslotId, specialSeason) {
    
    console.log("DAILY PRICE", dailyPrice)
    let price = 0;

    let specialSeasons = [];

    if (specialSeason) {
      specialSeasons = this.checkIfSpecialSeason(start/1000,end/1000);
    }


    if (specialSeasons.length ) {
      if (optionalKey) {
        if (prices[packageKey + '_' + optionalKey + '_' + zoneKey + '_'+specialSeasons[0].id+'_'+timeslotId]) {
          price = prices[packageKey + '_' + optionalKey + '_' + zoneKey + '_'+specialSeasons[0].id+'_'+timeslotId].specialSeason;
        }
      } else {
        if (prices[packageKey + '_' + zoneKey + '_'+specialSeasons[0].id+'_'+timeslotId]) {
          price = prices[packageKey + '_' +  zoneKey + '_'+specialSeasons[0].id+'_'+timeslotId].specialSeason;
        }
      }
    } else if (seasonTicket)  {

      if (extraKey) {
        if (prices['extra_' + extraKey + '_0_'+timeslotId]) {
          price = prices['extra_' + extraKey + '_0_'+timeslotId].seasonal;
        }
      } else if (optionalKey) {
        if (prices[packageKey + '_' + optionalKey + '_' + zoneKey + '_0_'+timeslotId]) {
          price = prices[packageKey + '_' + optionalKey + '_' + zoneKey + '_0_'+timeslotId].seasonal;
        }
      } else {
        if (prices[packageKey + '_' + zoneKey + '_0_'+timeslotId]) {
          price = prices[packageKey + '_' +  zoneKey + '_0_'+timeslotId].seasonal;
        }
      }

    } else {
      let seasonsSelected = [];

      for (const seasonKey in seasonKeys) {
        seasonKeys[seasonKey].key = seasonKey;
        seasonsSelected.push(seasonKeys[seasonKey])
      }

      seasonsSelected = seasonsSelected.sort(this.sortByDate)
      for (const season of seasonsSelected) {
            // NUMERO DI GIORNI DA CONSIDERARE PER QUESTA STAGIONE
            let seasonKey = season.key;
            let seasonPrice = 0;

            if (season.start > start / 1000) {
              start = season.start * 1000;
            }

            let seasonDays = season.length;
            let startMonth = (new Date(season.start * 1000)).getMonth();
            const startYear = (new Date(season.start * 1000)).getFullYear();

            let biweeklyDays = 14;

            if (!biweeklyPrice) {
              biweeklyDays = 15;
            }

            let monthDays = 30;
            let secondMonthDays = 10000;

            if (monthlyPrice) {
              monthDays = new Date(startYear, startMonth + 1, 0).getDate();
            }

            if (seasonDays >= monthDays + 30) {

              startMonth = (new Date(season.start * 1000 + monthDays * 24 * 60 * 60 * 1000)).getMonth();

              secondMonthDays = 30;

              if (monthlyPrice) {
                secondMonthDays = new Date(startYear, startMonth + 1, 0).getDate();
              }
            }

            let findPack = null;
            let selectedPrice = null;

            if (extraKey) {
              selectedPrice = prices['extra_' + extraKey + '_' + seasonKey+'_'+timeslotId];
            } else if (optionalKey) {
              selectedPrice = prices[packageKey + '_' + optionalKey + '_' + zoneKey + '_' + seasonKey+'_'+timeslotId];
              findPack = interval.order.find(pack => pack.packageKey === packageKey && pack.optionalKey === pack.optionalKey);
            } else {
              selectedPrice = prices[packageKey + '_' +  zoneKey + '_' + seasonKey+'_'+timeslotId];
              findPack = interval.order.find(pack => pack.packageKey === packageKey);
            }

            if (findPack && findPack.free) {
              for (const freeInterval of findPack.free) {
                seasonDays -= freeInterval.length;
              }
            }
            // SE NON ESISTE LA STAGIONE ALLORA PREZZO = 0
            if (!seasonKey) {
              return 0;
            }
            // SE NON ESISTE IL PREZZO PER QUEL PACCHETTO-ZONA-STAGIONE ALLORA PREZZO = 0
            if (typeof  selectedPrice == 'undefined') {
                return 0;
            } else {
              if (!dailyPrice) {
                if (seasonDays >= secondMonthDays && selectedPrice[`monthly`] && selectedPrice[`monthly`] != 0) {

                    if (!proportionalPrice) {
                        price += selectedPrice[`monthly`] * 2;
                        seasonPrice += selectedPrice[`monthly`] * 2;
                        seasonDays = seasonDays - monthDays - secondMonthDays;
                    } else {
                        price += (selectedPrice[`monthly`] * 2) / (monthDays + secondMonthDays) * seasonDays;
                        seasonPrice += (selectedPrice[`monthly`] * 2) / (monthDays + secondMonthDays) * seasonDays;
                    }
                } else if (seasonDays >= monthDays && ((seasonDays < secondMonthDays) || price == 0) && selectedPrice[`monthly`] && selectedPrice[`monthly`] != 0) {

                    // CALCOLA PREZZO MENSILE
                    if (!proportionalPrice) {
                        price += selectedPrice[`monthly`];
                        seasonPrice += selectedPrice[`monthly`];
                        seasonDays = seasonDays - monthDays;
                    } else {
                        price += selectedPrice[`monthly`] / monthDays * seasonDays;
                        seasonPrice += selectedPrice[`monthly`] / monthDays * seasonDays;
                    }

                } else if (seasonDays >= 21 && (seasonDays < monthDays || price == 0) && selectedPrice[`triweekly`] && selectedPrice[`triweekly`] != 0) {
                    // CALCOLA PREZZO BISETTIMANALE

                    if (!proportionalPrice) {
                        price += selectedPrice[`triweekly`];
                        seasonDays = seasonDays - 21;
                        seasonPrice += selectedPrice[`triweekly`];
                    } else {
                        price += selectedPrice[`triweekly`] / 21 * seasonDays;
                        seasonPrice += selectedPrice[`triweekly`] / 21 * seasonDays;
                    }

                } else if (seasonDays >= biweeklyDays && (seasonDays < 21 || price == 0)  && selectedPrice[`biweekly`] && selectedPrice[`biweekly`] != 0) {
                    // CALCOLA PREZZO BISETTIMANALE

                    if (!proportionalPrice) {
                        price += selectedPrice[`biweekly`];
                        seasonPrice += selectedPrice[`biweekly`];
                        seasonDays = seasonDays - biweeklyDays;
                    } else {
                        price += selectedPrice[`biweekly`] / biweeklyDays * seasonDays;
                        seasonPrice += selectedPrice[`biweekly`] / biweeklyDays * seasonDays;
                    }

                } else if (seasonDays >= 7 && (seasonDays < biweeklyDays || price == 0)  && selectedPrice[`weekly`] && selectedPrice[`weekly`] != 0) {
                    // CALCOLA PREZZO SETTIMANALE

                    if (!proportionalPrice) {
                      price += selectedPrice[`weekly`];
                      seasonPrice +=  selectedPrice[`weekly`];
                      seasonDays = seasonDays - 7;
                    } else {
                      price += selectedPrice[`weekly`] / 7 * seasonDays;
                      seasonPrice +=  selectedPrice[`weekly`] / 7 * seasonDays;
                    }
                }
              }
              console.log("seasonDays",seasonDays);
              console.log("seasonPrice",JSON.stringify(seasonPrice));

              if (seasonDays < 7 || !proportionalPrice || seasonPrice == 0 ) {

                  // CALCOLO IL PREZZO DEL PACCHETTO IN QUELLA ZONA PER OGNI GIORNO

                  for (let count = 0; count < seasonDays; count += 1) {

                      // PER OGNI TIMESTAMP VERIFICA
                      // SE E' GIORNO WEEKEND -> PREZZO GIORNALIERO WEEKEND
                      const tempTs = start + count * 24 * 60 * 60 * 1000;
                      const date = new Date(tempTs);
                      // OTTENGO IL GIORNO DELLA SETTIMANA ( 0 -> DOMENICA / 1 -> LUNEDI' / 2-> MARTEDI' ... )
                      const dayType = date.getDay();
                      // SE WEEKLY PRICE == TRUE -> CONSIDERO IL PREZZO WEEKEND PER SABATO E DOMENICA
                      if (weeklyPrice) {
                          if (dayType == 6 || dayType == 0) {
                              price += selectedPrice[`weekend`];
                          }

                          if ((dayType != 6 && dayType != 0) || (selectedPrice[`weekend`] == 0)) {
                              price += selectedPrice[`daily`];
                          }
                      } else {

                          if (dayType == 0) {
                              price += selectedPrice[`weekend`];
                          }

                          if ((dayType != 0) || (selectedPrice[`weekend`] == 0)) {
                              price += selectedPrice[`daily`];
                          }
                      }

                  }
              }

            }

            console.log("price",JSON.stringify(price));
      }
    }
    return (Math.round(price*100))/100;
  }


  sortByDate( a, b ) {
    if ( a.start < b.start ) {
      return -1;
    }
    if ( a.start > b.start ) {
      return 1;
    }
    return 0;
  }

  sortByStartHour( a, b ) {
    if ( a.startHour < b.startHour ) {
      return -1;
    }
    if ( a.startHour > b.startHour ) {
      return 1;
    }
    return 0;
  }

  initReservation(start, end, noSlots) {

    const newReservation = new Reservation();

    newReservation.cabins = [];
    newReservation.createdAt = Math.round((new Date()).getTime() / 1000);
    newReservation.updatedAt = Math.round((new Date()).getTime() / 1000);

    newReservation.createdBy = {
      name: this.userData.first_name + ' ' + this.userData.last_name,
      id: this.userData.id,
      role: this.userData.role,
      email: this.userData.email,
      uid: this.userData.uid,
      version: environment.version,
      platform: 'manager_web'
    };

    newReservation.name = '';
    newReservation.phone = '';
    newReservation.phonePrefix = '+39';
    newReservation.customerId = null;
    newReservation.cocoBooking = false;
    newReservation.resortId = this.resortData.id;
    newReservation.discounts = [];
    newReservation.extras = [];
    newReservation.seasonTicket = false;
    newReservation.reservationId = '';
    newReservation.freeDates = [];
    newReservation.checkInDates = [];
    newReservation.checkOutDates = [];
    newReservation.length = ((end - start) / (1000 * 24 * 60 * 60)) + 1;
    newReservation.start = start/1000;
    newReservation.end = end/1000;
    newReservation.noSlots = noSlots;
    newReservation.version = 4;
    newReservation.searchKey = '';
    newReservation.state = 2;
    newReservation.order = {
      settled: false,
      total: 0,
      subtotal: 0,
      discount: 0,
      dailyPrice: false,
      customerBillTotal: 0,
      cocoFee: 0,
      customerBill: [],
      products: [],
      paymentType: 1,
      reservedPrice: null
    };

    return newReservation;
  }

  async getQuotation(postData) {
    try {
      const res = await this.http.post(environment.firebaseApiUrl + '/getQuotation', postData, {headers: {'Content-Type': 'application/json'}}).toPromise();
      console.log(res);
      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getCSVlist(payout) {
    try {
      const res = await this.http.get(environment.firebaseApiUrl + '/generateCSVreservationList?payout='+payout+'&resortId='+this.resortData.id, {headers: {'Content-Type': 'application/json'}}).toPromise();
      console.log(res);
      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async getPDFlist(payout) {
    try {
      console.log(environment.firebaseApiUrl + '/generatePDFreservationList?payout='+payout+'&resortId='+this.resortData.id);
      const res = await this.http.get(environment.firebaseApiUrl + '/generatePDFreservationList?payout='+payout+'&resortId='+this.resortData.id, {headers: {'Content-Type': 'application/json'}}).toPromise();
      console.log(res);
      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }


  async changeManagerPassword(managerData) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const postData = {
        token: firebaseUser.token,
        password: managerData.password,
        uid: managerData.uid
      }

      console.log(postData);
      const res = await this.http.post(environment.firebaseApiUrl + '/changeManagerPassword', postData, {headers: {'Content-Type': 'application/json'}}).toPromise();
      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async deleteManager(dataObj) {
    const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

    const api = environment.apiURL + 'users_managers/' + dataObj.id + '/';

    const token = firebaseUser.token;

    const options = {
      headers: new HttpHeaders({
        Authorization : token
      }),
      body: dataObj
    };

    const data = await this.http.delete(api, options).toPromise();

    return data;
  }

  async restoreReservation(reservationId,date) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/' + reservationId + '/restore/' + date + '/';

      const token = firebaseUser.token;

      console.log(api);
      const data = await this.http.put(api, {}, { headers: { Authorization : token}}).toPromise();

      return data;
    } catch(err) {
      console.log(err);
      return err;
    }
  }

  async updateManager(dataObj) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'users_managers/' + dataObj.id + '/';

      const token = firebaseUser.token;
      let dataToSave =JSON.parse(JSON.stringify(dataObj));

      if (dataToSave.role !== 'supermanager') {
        dataToSave.supermanager = false;
      }

      delete dataToSave.subscriptions;
      delete dataToSave.username;
      delete dataToSave.uid;
      delete dataToSave.resortsIds;
      delete dataToSave.resortsKeys;
      delete dataToSave.deviceRegistrationToken;

      dataToSave.username = dataToSave.email;

      console.log(JSON.stringify(dataToSave));
      console.log(api);
      const data = await this.http.put(api, dataToSave, { headers: { Authorization : token}}).toPromise();

      return data;
    } catch(err) {
      console.log(err);
      return err;
    }
  }

  async createManager(dataObj) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'users/create_new_manager/';

      const token = firebaseUser.token;

      dataObj.username = dataObj.email;
      console.log(JSON.stringify(dataObj));

      const data = await this.http.post(api, dataObj, { headers: { Authorization : token}}).toPromise();

      return data;
    } catch(err) {
      console.log(err);
      return err;
    }
  }

  async getResortManagers() {
    try {
      if (this.resortData.id && typeof this.resortData.id !== 'undefined') {
        const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

        const api = environment.apiURL + 'resorts/' + this.resortData.id + '/';

        const token = firebaseUser.token;

        const data = await this.http.get(api, { headers: { Authorization : token}}).toPromise();

        return data['managers'];

      } else {
        return 'err';
      }
    } catch(err) {

    }
  }

  /* async getSingleCustomerBill(id) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'users_managers/' + dataObj.id + '/';

      const token = firebaseUser.token;

      console.log(JSON.stringify(dataObj));
      console.log(api);
      const data = await this.http.put(api, dataObj, { headers: { Authorization : token}}).toPromise();

      return data;
    } catch(err) {
      console.log(err);
      return err;
    }
  }

  async setSingleCustomerBill(data) {

  }
  */
  async updateSingleCustomerBill(reservationId, customerBill) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/' + reservationId + '/customer_bill/' + customerBill.id+'/';
      const token = firebaseUser.token;

      console.log(api);
      const data = await this.http.put(api, customerBill, { headers: { Authorization : token}}).toPromise();

      return data;
    } catch(err) {
      console.log(err);
      return err;
    }
  }

  async insertSingleCustomerBill(reservationId, customerBill) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/' + reservationId + '/customer_bill/' ;
      const token = firebaseUser.token;

      console.log(api);
      console.log(JSON.stringify(customerBill));  
      const data = await this.http.post(api, customerBill, { headers: { Authorization : token}}).toPromise();

      return data;
    } catch(err) {
      console.log(err);
      return err;
    }
  }

  async deleteSingleCustomerBill(reservationId, customerBillId) {
    try {
      const firebaseUser = await this.afAuth.auth.currentUser.getIdTokenResult();

      const api = environment.apiURL + 'reservations/' + reservationId + '/customer_bill/' + customerBillId+'/'
      const token = firebaseUser.token;

      console.log(api);
      const data = await this.http.delete(api, { headers: { Authorization : token}}).toPromise();

      return data;
    } catch(err) {
      console.log(err);
      return err;
    }
  }

  compareBySequence( a, b ) {
    if ( a.sequence < b.sequence ){
      return -1;
    }
    if ( a.sequence > b.sequence ){
      return 1;
    }
    return 0;
  }

  compareByStart( a, b ) {
    if ( a.start < b.start ){
      return -1;
    }
    if ( a.start > b.start ){
      return 1;
    }
    return 0;
  }

  setRecapParams(reservations, lang ) {
    for (let res of reservations) {

      res.surname = res.name.split(" ")[res.name.split(" ").length - 1];
      res.elements =[];

      if (res.packageId) {
        res.selectedPackage = JSON.parse(JSON.stringify(this.resortData.packagesByIds[res.packageId]));
      }

      if (res.selectedPackage) {
        for (let ele of res.selectedPackage.elements) {
          res.elements.push(ele);
        }

        for (const element of res.selectedPackage.elements) {
          if (element.element.cover) {
            res.selectedPackage.elementId = element.element.id;
            break;
          } else if (!res.selectedPackage.elementId) {
            res.selectedPackage.elementId = element.element.id;
          }
        }
      }


      if (res.optionalsIds) {

        res.optionals = ''
        res.optionalsComplete = ''
        res.optionalsShort = '';
        for (let optId in res.optionalsIds) {
          let optFound = this.optionals.find(o => o.id == optId);
          let string = 'ERR'
          let stringShort = ''
          if (optFound && res.optionalsIds[optId]) {

            string = optFound.name[lang].substring(0,3);
            stringShort = optFound.name[lang].substring(0,1);

            let flag = 0;
            for (let ele of res.elements) {
              if (ele.element.elementId === optId) {
                ele.quantity = ele.quantity + res.optionalsIds[optId];
                flag = 1;
                break;
              }
            }

            if (!flag) {
              res.elements.push({
                quantity: res.optionalsIds[optId],
                element: optFound
              })
            }

            if (res.optionals === '') {
              res.optionals += res.optionalsIds[optId]+' '+string;
              res.optionalsComplete = res.optionalsIds[optId]+' '+ optFound.name[lang];
              //output[cell.row][cell.col].optionalsShort += '&nbsp;'+firstReservation.optionalsIds[optId]+stringShort;
            } else {
              res.optionals += ' + '+res.optionalsIds[optId]+' '+string
              res.optionalsComplete += ' + '+res.optionalsIds[optId]+' '+ optFound.name[lang];
              //output[cell.row][cell.col].optionalsShort += '&nbsp;'+firstReservation.optionalsIds[optId]+stringShort;
            }
          }
        }
      }
      res.packageShortString = '';
      for (let ele of res.elements) {
        if (res.packageShortString === '') {
          res.packageShortString+=ele.quantity+ele.element.name['it'].substring(0,1).toUpperCase();
        } else {
          res.packageShortString+=' '+ele.quantity+ele.element.name['it'].substring(0,1).toUpperCase();
        }
      }
    }

    return reservations

  }

  setCellShortParams(cell, reservation) {
    cell.elementId = reservation.elementId;
    cell.selectedPackage = reservation.selectedPackage;
    cell.optionals = reservation.optionals;
    cell.packageShortString = reservation.packageShortString;

    return cell;
  }

}

export class Customer {
  public address: string;
  public associatedNames: string[];
  public bookingId: string;
  public city: string;
  public email: string;
  public name: string;
  public surname: string;
  public fiscalCode: string;
  public id: number;
  public phone: string;
  public phonePrefix: string;
  public resortId: string;
  public uid: string;
}

class Reservation {
    public cabins: string[];
    public createdAt: number;
    public updatedAt: number;
    public name: string;
    public phone: string;
    public phonePrefix: string;
    public customerId: number;
    public cocoBooking: boolean;
    public resortId: string;
    public discounts: object[];
    public extras: object[];
    public createdBy: object;
    public seasonTicket: boolean;
    public reservationId: string;
    public id: number;
    public checkInDates: number[];
    public freeDates:number[];
    public checkInTimestamps: number[];
    public checkOutDates: number[];
    public length: number;
    public noSlots: boolean;
    public version: number;
    public start: number;
    public end: number;
    public searchKey: string;
    public order: object;
    public dates: object[];
    public state: number;
    public relatedCustomers: object[];

}
