import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { CustomersService } from 'src/app/core/providers/customer.service';
import { DataService } from 'src/app/core/providers/data.service';


@Component({
  selector: 'app-modal-cocobuk-user',
  templateUrl: './modal-cocobuk-user.component.html',
  styleUrls: ['./modal-cocobuk-user.component.scss']
})
export class ModalCocobukUserComponent implements OnInit {

  email:string = '';
  error:any = null;

  constructor(
    public dialogRef: MatDialogRef<ModalCocobukUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomersService) { }

  ngOnInit() {
  }

  
  async check() {
    if (this.email && this.email !== '') {
      let user = await this.customerService.searchCocobukUser(this.email);
      if (user) {
        this.error = null;
        this.close(user);
      } else {
        this.error = 'L\'utente non esiste su Cocobuk';
      }
    }
  }

  
  close(save:any): void {
    if (save) {
      this.dialogRef.close(save);
    } else {
      this.dialogRef.close(null);
    }
  }

}
