import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataService } from 'src/app/core/providers/data.service';
import { DialogCustomersComponent } from 'src/app/shared/components/dialog-customers/dialog-customers.component';
import { prefixes } from '../../../../assets/country-codes.json';

@Component({
  selector: 'app-reservation-customer-data',
  templateUrl: './customer-data.component.html',
  styleUrls: ['./customer-data.component.scss']
})
export class CustomerDataComponent implements OnInit {

  @Input() reservation:any;
  @Input() resortData:any;
  @Input() managerData:any;
  @Input() clicked:any;
  @Input() mainCustomer:any;
  @Input() associatedCustomers:any;
  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateMainCustomer:EventEmitter<any> = new EventEmitter<any>();
  @Output() updateAssociatedCustomers:EventEmitter<any> = new EventEmitter<any>();
  @Output() updateDiscountsEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCustomerEmitter: EventEmitter<any> = new EventEmitter<any>();
  searchTerm$ = new Subject<string>();otherCustomers;customerSelected;
  phonePrefixes = prefixes;
  reservations; name;
  emailValidity:boolean = true;
  formValidity:boolean = false;

  constructor(public router: Router, public snackBar: MatSnackBar,  public dialog: MatDialog, public dataService:DataService) {
    this.searchTerm$
    .debounceTime(500)
    .distinctUntilChanged()
    .subscribe((term) => {
      if (term !== '' && this.customerSelected.name !== '' && this.customerSelected.surname !== '') {
        this.getDuplicateCustomers();
      } else {
        this.customerSelected = null;
        this.otherCustomers = [];
      }
    });
  }

  ngOnInit(): void {
    if (this.reservation && this.reservation.customers) {
      let counter = 0;

      for (const customer of this.reservation.customers) {
        if ((customer.customer.name && customer.customer.name !== '') || (customer.customer.surname && customer.customer.surname !== '')) {
          this.updateCustomersView(customer.customer, customer.principal, counter);
          
          if (!customer.principal) {
            counter++;
          }
        }

      }
    }
   
  }

  ngOnChanges() {
    

   
  }

  goTo(url) {
    this.dataService.previousPage = 'suite/reservation/'+this.reservation.id;
    this.router.navigate([url]);
  }


  openCustomersModal (main, index) {
    const dialogRef = this.dialog.open(DialogCustomersComponent, {
      width: '1040px',
      height: '500px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // console.log(result);

          let alreadySaved = false;

          if (this.mainCustomer.id === result.id) {
            alreadySaved = true;
          }

          for (const c of this.associatedCustomers) {
            if (c.id === result.id) {
              alreadySaved = true;
              break;
            }
          }

          if (!alreadySaved) {

            if (main) {
              this.selectMainCustomer(result);
            } else {
              this.selectCustomer(result, index);
            }
          } else {
            this.openSnackBar('Cliente già presente', 'OK');
          }
        }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }



  async getDuplicateCustomers() {
    // console.log("CERCA DOPPIONI")
    this.otherCustomers = [];

    this.customerSelected.warning = false;

    if (!this.reservation.customerId && typeof this.customerSelected.name !== 'undefined' && this.customerSelected.name !== '' && typeof this.customerSelected.surname !== 'undefined' &&  this.customerSelected.surname !== '') {

      const searchString = this.customerSelected.name.trim() + ' ' + this.customerSelected.surname.trim();

      const data = await this.dataService.getCustomers( searchString.toLowerCase(), null, 10, 0, false);

      this.otherCustomers = [];

      // console.log(data.results);

      for (const customer of data.results) {
        if (customer.name.trim().toLowerCase() === this.customerSelected.name.trim().toLowerCase() && customer.surname.trim().toLowerCase() === this.customerSelected.surname.trim().toLowerCase()) {

          this.otherCustomers.push(customer);
          this.customerSelected.warning = true;
        } else if (customer.name.trim().toLowerCase() === this.customerSelected.name.trim().toLowerCase() + ' ' + this.customerSelected.surname.trim().toLowerCase()) {

          this.otherCustomers.push(customer);
          this.customerSelected.warning = true;
        }
      }
      // console.log(this.otherCustomers);
    }

  }

  checkIfAssociatedUserByApp(uid) {
    if (uid && this.reservation.bookingRetrieveUids && this.reservation.bookingRetrieveUids.length) {
      if (this.reservation.bookingRetrieveUids.indexOf(uid) > -1) {
        return true
      } else {
        return false
      }
    }

    return false
  }


  selectMainCustomer(customer) {
      if (!customer.phonePrefix || customer.phonePrefix === '39' || customer.phonePrefix === '') {
        customer.phonePrefix = '+39';
      } else if (customer.phonePrefix.indexOf('+') === -1){
        customer.phonePrefix = '+'+customer.phonePrefix;
      }

      if (customer.discountId) {
        const foundDiscount = this.resortData.discounts.find(disc => disc.id == customer.discountId);
        foundDiscount.checked = true;
        this.updateDiscountsEmitter.emit(foundDiscount);
      }

      this.updateCustomersView(customer, true, null);
  }

  selectCustomer(customer, index) {

    if (!customer.phonePrefix || customer.phonePrefix === '39' || customer.phonePrefix === '') {
      customer.phonePrefix = '+39';
    }
    console.log("SELECT CUSTOMER")
    this.updateCustomersView(customer, false, index );
  }

  updateCustomersView(customer, main, index) {
    if (main) {
      this.mainCustomer = customer;
      this.reservation.customerId = customer.id;
      console.log("MAIN CUSTOMER")
      console.log(this.mainCustomer)
      this.checkFormValidity();
      this.updateMainCustomer.emit(this.mainCustomer);
    } else {
      this.associatedCustomers[index] = customer;
      this.updateAssociatedCustomers.emit(this.associatedCustomers);
    }
  }

  
  checkFormValidity() {
    let emailRegx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    this.emailValidity = (this.mainCustomer.email.trim() === '' || (this.mainCustomer.email != '' && emailRegx.test(this.mainCustomer.email)));
    if (!this.emailValidity) {
      this.formValidity = false;
    } else {
      this.formValidity = true;
    }
  }

  addAssociatedCustomer() {
    this.associatedCustomers.push({
      name: '',
      surname: '',
      email: '',
      phone: '',
      phonePrefix: '+39'
    });
    this.updateAssociatedCustomers.emit(this.associatedCustomers);
  }

  resetAssociatedCustomer(index) {
    this.associatedCustomers[index] = {
      name: '',
      surname: '',
      email: '',
      phone: '',
      phonePrefix: '+39'
    }

    this.updateAssociatedCustomers.emit(this.associatedCustomers);
  }

  removeAssociatedCustomer(index) {
    this.associatedCustomers.splice(index, 1);
    this.updateAssociatedCustomers.emit(this.associatedCustomers);
  }


}
