import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-checkin',
  templateUrl: './modal-checkin.component.html',
  styleUrls: ['./modal-checkin.component.scss']
})
export class ModalCheckinComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalCheckinComponent>) { }

  ngOnInit() {
  }

  close(total): void {
    this.dialogRef.close(total);
  }

}
