import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-type-extra-row',
  templateUrl: './type-extra-row.component.html',
  styleUrls: ['./type-extra-row.component.scss']
})
export class TypeExtraRowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
