import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { CustomersService } from 'src/app/core/providers/customer.service';
import { DataService } from 'src/app/core/providers/data.service';
import { ReservationService } from 'src/app/core/providers/reservation.service';
import { SettingsService } from 'src/app/core/providers/settings.service';
import { ModalCustomerBillComponent } from 'src/app/shared/components/modal-customer-bill/modal-customer-bill.component';
import { ModalErrorComponent } from 'src/app/shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-reservation-customer-bill',
  templateUrl: './customer-bill.component.html',
  styleUrls: ['./customer-bill.component.scss']
})
export class CustomerBillComponent implements OnInit {

  @Input() reservation:any;
  @Input() mainCustomer:any;
  @Input() managerData:any;
  @Input() paymentType:any;
  @Input() loading:any;
  @Input() resortData:any;
  @Output() checkSettledEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dataService:DataService, public dialog:MatDialog, public reservationService: ReservationService, public settingsService: SettingsService , public translateService: TranslateService, public customerService:CustomersService) {

  }

  ngOnInit(): void {

  }


  async customerBillPdf() {
    this.reservationService.exportCustomerBill(this.reservation,this.mainCustomer);
  }

  async openCustomerBill(paid) {

    const dialogRef = this.dialog.open(ModalCustomerBillComponent, {
      width: '1050px',
      data: {
        customerBill: this.reservation.order.customerBill,
        reservation: this.reservation,
        paid
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
        if (result && result.save) {
          console.log(this.reservation);
          this.loading = true;
          this.reservation.order.customerBill = result.customerBill;
          this.settingsService.updateCustomerBillModal(this.reservation);
          for (const bill of this.reservation.order.customerBill) {
            if (bill.type === 'settlement') {
              this.paymentType = bill.paymentType;
            }

            if (bill.id) {
              await this.dataService.updateSingleCustomerBill(this.reservation.id, bill);
            } else {
              let newBill = await this.dataService.insertSingleCustomerBill(this.reservation.id,bill);
              bill.id = newBill.id;
            }
          }
          this.loading = false;
          this.checkSettledEmitter.emit();
        }
    });
  }

  
  

}
