import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/core/providers/data.service';

@Component({
  selector: 'app-language-popup',
  templateUrl: './language-popup.component.html',
  styleUrls: ['./language-popup.component.scss']
})
export class LanguagePopupComponent implements OnInit {

  @Input() dataLang:any;
  @Input() showModal:any;
  @Output() onLangChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dataService:DataService) {

  }

  ngOnInit(): void {

  }

  changeLang(lang:string) {
    this.dataLang=lang;
    this.onLangChange.emit(lang);
  }

}
