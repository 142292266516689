import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service'
import { Subject } from 'rxjs/Subject';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import 'rxjs/add/operator/debounceTime';
import { ReservationService } from '../../../core/providers/reservation.service';
import 'rxjs/add/operator/distinctUntilChanged';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-payments-recap',
  templateUrl: './payments-recap.component.html',
  styleUrls: ['./payments-recap.component.scss']
})
export class PaymentsRecapComponent implements OnInit {

  // RESORTS DATA
  resortData; managerData; reservations = null; optionals;optionalsByIds ={};
  typeCocoManager=true; typeCocobuk=true;typeSeasonal=true; lightLoader= false;

  // FILTERS VARIABLES
  term$ = new Subject<string>();
  selectedPackage = null; selectedSettlement = null; selectedPaymentType = null;
  selectedOptionals = null; selectedStatus = null; onlyArrivals = null;
  selectedType = null; selectedToday = null; onlyDepartures = null;
  selectedState = null; selectedNoSlots = null; selectedZone = null;
  selectedFilter = null; searchString = ''; selectedFiscalPrint = true; size = null;
  selectedFiscalPrinter = null; selectedManager = null; cashPayments; cardPayments; chequePayments; transferPayments;

  // DATE VARIABLES
  startTimestamp; endTimestamp;
  startDate; startDay; startMonth; startYear;
  endDate; endDay; endMonth; endYear;
  startSerializedDate; endSerializedDate; managers = null;

  totalAmount = 0;
  totalManagerAmount = 0;
  totalBookingAmount = 0;

  totalManagerFiscalAmount = 0;
  totalManagerNoFiscalAmount =0;

  // GLOBAL VARIABLES
  loader = true; nextPage; prevPage; totalReservations = 0; pageSize = 25; page = 1;
  objectKeys = Object.keys;lang='it'; pageClicked = false;
  overlayLoader = false; percentage = 0;

  constructor(public router: Router, private reservationService: ReservationService, private dataService: DataService, private settingsService: SettingsService) {
      // SETUP DATES

      this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
      this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

      const startDate = new Date(this.startTimestamp);
      startDate.setUTCHours(0, 0, 0, 0);
      this.startSerializedDate = startDate;

      const endDate = new Date(this.endTimestamp);
      endDate.setUTCHours(0, 0, 0, 0);
      this.endSerializedDate = endDate;

      console.log(JSON.parse(JSON.stringify(this.startTimestamp)))
      console.log(JSON.parse(JSON.stringify(this.endTimestamp)))

      // SETUP DATA
      this.resortData = this.dataService.resortData;
      this.managerData = this.dataService.userData;
      this.lang = this.dataService.lang;


  }

  async ngOnInit() {
    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.managers = await this.dataService.getResortManagers();
    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;

    console.log(this.managers);

    for (const opt of this.optionals) {
      this.optionalsByIds[opt.id] = opt;
    }
    console.log(this.optionals);

    // GET RESERVATIONS FOR TODAY

    await this.getData();
    await this.getPayments();
  }


  async getData() {
      this.loader = true;
      let start = (this.startTimestamp/1000)
      let end = (this.endTimestamp / 1000) + 24*60*60 -1;


      let now = new Date();
      let offset = now.getTimezoneOffset() * 60;

      

      this.totalAmount = await this.dataService.getTotalPaymentsAmount(null, start, end, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,null, this.size, 1, true );

      this.totalManagerAmount = await this.dataService.getTotalPaymentsAmount(null, start, end, null, null, null, null, null, null, false, null, null, null, null, null, null, null, null, null,null, null,null, this.size, 1, true );

      this.totalBookingAmount = await this.dataService.getTotalPaymentsAmount(null, start, end, null, null, null, null, null, null, true, null, null, null, null, null, null, null, null, null, null, null,null, this.size, 1, true );

      this.totalManagerFiscalAmount = await this.dataService.getTotalPaymentsAmount(null, start, end, null, null, null, null, null, null, false, null, null, null, null, null, null, null, null, null, true, null, null, this.size, 1, true );

      this.totalManagerNoFiscalAmount = await this.dataService.getTotalPaymentsAmount(null, start, end, null, null, null, null, null, null, false, null, null, null, null, null, null, null, null, null, false, null,null, this.size, 1, true );

      console.log(this.totalAmount);
      this.loader = false;
  }

  async getPayments() {
    this.lightLoader = true;
    let start = (this.startTimestamp/1000)
    let end = (this.endTimestamp / 1000) + 24*60*60 -1;


    let typeCocobuk = null;

    if (this.typeCocobuk && !this.typeCocoManager) {
      typeCocobuk = true;
    } else if (!this.typeCocobuk && this.typeCocoManager) {
      typeCocobuk = false;
    }

    if (!this.typeCocobuk && !this.typeCocoManager) {
      this.cashPayments = 0;
      this.cardPayments = 0;
      this.transferPayments= 0;
      this.chequePayments= 0;
    } else {
      this.cashPayments = await this.dataService.getTotalPaymentsAmount(this.selectedManager, start, end, null, null, null, null, null, null, typeCocobuk, null, null, null, null, null, null, null, null, null, null, 'cash', this.selectedFiscalPrinter, this.size, 1, true );

      this.cardPayments = await this.dataService.getTotalPaymentsAmount(this.selectedManager, start, end, null, null, null, null, null, null, typeCocobuk, null, null, null, null, null, null, null, null, null, null, 'credit-card', this.selectedFiscalPrinter, this.size, 1, true );

      this.transferPayments = await this.dataService.getTotalPaymentsAmount(this.selectedManager, start, end, null, null, null, null, null, null, typeCocobuk, null, null, null, null, null, null, null, null, null, null, 'transfer', this.selectedFiscalPrinter, this.size, 1, true );

      this.chequePayments = await this.dataService.getTotalPaymentsAmount(this.selectedManager, start, end, null, null, null, null, null, null, typeCocobuk, null, null, null, null, null, null, null, null, null, null, 'cheque', this.selectedFiscalPrinter, this.size, 1, true );
    }

    this.lightLoader = false;
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp)
      this.endTimestamp = this.startTimestamp;

    let endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);

    await this.getData();
    await this.getPayments();

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.loader = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    console.log(endTS)
    console.log(endDate)

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);


    console.log( this.endSerializedDate)
    console.log(this.endTimestamp)

    if (this.endTimestamp < this.startTimestamp)
      this.startTimestamp = this.endTimestamp;

    let startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    await this.getData()
    await this.getPayments();
  }

  async setTodayDate() {
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    let startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startDate = startDate.getDate();

    let endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endDate = endDate.getDate();

    this.startSerializedDate = new Date(this.startTimestamp);
    this.endSerializedDate = new Date(this.endTimestamp);

    await this.getData()
    await this.getPayments();

  }

}
