import { NgModule } from "@angular/core";
import { HeaderComponent as HeaderBookingComponent } from "./components/header_booking/header.component";
import { HeaderComponent as HeaderSuiteComponent} from "./components/header_suite/header.component";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgGUDCoreModule } from 'ngvforlib/ng-vfor-lib';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragScrollModule } from 'ngx-drag-scroll';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { TranslateLoader, TranslateModule} from '@ngx-translate/core'
import { SettingsSidenavComponent } from "./components/settings-sidenav/settings-sidenav.component";
import { MaterialModule } from "./material.module";
import { LanguagePopupComponent } from "./components/language-popup/language-popup.component";
import { ModalRemoveComponent } from "./components/modal-remove/modal-remove.component";
import { ModalErrorComponent } from "./components/modal-error/modal-error.component";
import { ModalConfirmBulkDeleteComponent } from "./components/modal-confirm-bulk-delete/modal-confirm-bulk-delete.component";
import { DialogCustomersComponent } from "./components/dialog-customers/dialog-customers.component";
import { ModalLicenseRenewalComponent } from "./components/modal-license-renewal/modal-license-renewal.component";
import { ModalBroadcastComponent } from "./components/modal-broadcast/modal-broadcast.component";
import { ModalConfirmComponent } from "./components/modal-confirm/modal-confirm.component";
import { ModalReserveComponent } from "./components/modal-reserve/modal-reserve.component";
import { SlotComponent } from "./components/slot/slot.component";
import { ModalPrintersComponent } from "./components/modal-printers/modal-printers.component";
import { ModalCheckoutComponent } from "./components/modal-checkout/modal-checkout.component";
import { ModalTextComponent } from "./components/modal-text/modal-text.component";
import { ModalStatesComponent } from "./components/modal-states/modal-states.component";
import { ModalTransferComponent } from "./components/modal-transfer/modal-transfer.component";
import { ModalCheckinComponent } from "./components/modal-checkin/modal-checkin.component";
import { TileModalComponent } from "../settings/components/tile-modal/tile-modal.component";
import { ModalCocobukUserComponent } from "../customer/components/modal-cocobuk-user/modal-cocobuk-user.component";
import { ModalConfirmDeleteComponent } from "./components/modal-confirm-delete/modal-confirm-delete.component";


// Components

@NgModule({
  declarations: [
    HeaderBookingComponent,
    HeaderSuiteComponent,
    SettingsSidenavComponent,
    LanguagePopupComponent,
    ModalRemoveComponent,
    ModalErrorComponent,
    ModalConfirmBulkDeleteComponent,
    DialogCustomersComponent,
    ModalLicenseRenewalComponent,
    ModalBroadcastComponent,
    ModalConfirmComponent,
    ModalReserveComponent,
    SlotComponent,
    ModalPrintersComponent,
    ModalCheckoutComponent,
    ModalTextComponent,
    ModalStatesComponent,
    ModalTransferComponent,
    ModalCheckinComponent,
    ModalConfirmDeleteComponent
  ],
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ScrollingModule,
    DragDropModule,
    DragScrollModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgGUDCoreModule,
    TranslateModule.forRoot({
      loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    MaterialModule
  ],
  exports: [
    LanguagePopupComponent,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ScrollingModule,
    DragDropModule,
    DragScrollModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgGUDCoreModule,
    // --- Public components --- //
    HeaderBookingComponent,
    HeaderSuiteComponent,
    TranslateModule,
    SettingsSidenavComponent,
    MaterialModule,
    SlotComponent
  ],
  bootstrap: [ModalConfirmDeleteComponent, ModalRemoveComponent, ModalErrorComponent, ModalConfirmBulkDeleteComponent, DialogCustomersComponent, ModalLicenseRenewalComponent, ModalBroadcastComponent, ModalConfirmComponent, ModalReserveComponent, ModalPrintersComponent, ModalCheckoutComponent, ModalTextComponent, ModalStatesComponent, ModalTransferComponent, ModalCheckinComponent,TileModalComponent, ModalCocobukUserComponent]
})
export class SharedModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

