import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

// ROUTING
import { CustomerRoutingModule } from './customer-routing.module';

// COMPONENTS
import { CustomersComponent as SuiteCustomersComponent} from './pages/customers/customers.component';
import { CustomerDetailComponent as SuiteCustomerDetailComponent} from './pages/customer-detail/customer-detail.component';
import { ModalRefundDetailComponent } from './components/modal-refund-detail/modal-refund-detail.component';
import { ModalCocobukUserComponent } from './components/modal-cocobuk-user/modal-cocobuk-user.component';

@NgModule({
  declarations: [
    SuiteCustomersComponent,
    SuiteCustomerDetailComponent,
    ModalRefundDetailComponent,
    ModalCocobukUserComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CustomerRoutingModule
  ],
  bootstrap:[ModalRefundDetailComponent]
})
export class CustomerModule { }
