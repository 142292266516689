import { Component, OnInit } from '@angular/core';
import { DataService, Customer } from '../../../core/providers/data.service';
import { ReservationService } from '../../../core/providers/reservation.service';
import { SettingsService } from '../../../core/providers/settings.service';
import { Subject } from 'rxjs/Subject';
import { DataStatisticsService } from '../../../core/providers/data-statistics.service';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { MatSnackBar } from '@angular/material';
import { ModalConfirmComponent } from '../../../shared/components/modal-confirm/modal-confirm.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { F } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-availabilities',
  templateUrl: './availabilities.component.html',
  styleUrls: ['./availabilities.component.scss']
})
export class AvailabilitiesComponent implements OnInit {

  resortData; managerData; data; reservations; optionals; availabilities = [];

  // FILTERS VARIABLES
  term$ = new Subject<string>();
  selectedPackage = null; selectedSettlement = null; selectedPaymentType = null;
  selectedOptionals = null; selectedStatus = null; onlyArrivals = null;
  selectedType = null; selectedToday = null; onlyDepartures = null;
  selectedState = null; selectedNoSlots = null; selectedZone = null;
  selectedFilter = null; searchString = ''; selectedCabins = null; size = null;

  // DATE VARIABLES
  startTimestamp; endTimestamp;
  startDate; startDay; startMonth; startYear;
  endDate; endDay; endMonth; endYear;
  startSerializedDate; endSerializedDate;packages =[];allAvailabilities=[];

  // GLOBAL VARIABLES
  loader = true; nextPage; prevPage; totalReservations = 0; pageSize = 25; page = 1;
  objectKeys = Object.keys; lang = 'it'; totalAmount = 0; pageClicked = false;
  overlayLoader = false; percentage = 0;

  disableSave = false;

  constructor(private statisticsData: DataStatisticsService, public dialog: MatDialog, public snackBar: MatSnackBar, public router: Router,private dataService: DataService, private settingsService: SettingsService, private reservationService: ReservationService) {

    // SETUP DATES
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate;

    // SETUP DATA
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.lang = this.dataService.lang;


  }

  async ngOnInit() {

    if (!this.resortData) {
      this.data = await this.dataService.initResortData();
    }

    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.optionals =  this.dataService.optionals;

    await this.checkAvailabilities();
  }


  checkSave(value:boolean,index:number) {
    console.log(value,index);
    this.disableSave = true;
    this.packages[index].selected = value;

    for (const pack of this.packages) {
      if (pack.selected) {
        this.disableSave = false;
        break;
      }
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  async saveAvailabilities() {

    this.loader = true;
    const availabilitiesToSave = [];
    for (const pack of this.packages) {

      if ((pack.selected || this.startTimestamp === this.endTimestamp)) {

        for (let ts = this.startTimestamp; ts <= this.endTimestamp; ts+=24*60*60*1000) {
          for (let zone of pack.zones) {

            for (let timeSlot of zone.timeSlots) {

              let timeSlotId = timeSlot.timeSlotId;
              if (!timeSlotId) {
                timeSlotId = null;
              }

              let ava = this.allAvailabilities.find(a => a.day_timestamp === ts/1000 && a.packageId === pack.id  && a.zoneId === zone.id && a.timeSlotId == timeSlotId);

              let obj = {
                "packageId": pack.id,
                "zoneId": zone.id,
                "timeSlotId": timeSlotId,
                "id":ava.id,
                "availability": timeSlot.residual,
                "day_timestamp": ts/1000
              }

              availabilitiesToSave.push(obj);
            }
          }
        }
      }
    }

    console.log(JSON.stringify(availabilitiesToSave));
    let response = await this.dataService.setPackagesAvailabilitiesV2(availabilitiesToSave);

    console.log(response);
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      width: '340px',
      height: '110px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
          console.log(result);
    });
    //this.availabilities = newAva;
    //this.checkAvailabilities();
    console.log(this.resortData.extras);
    await this.checkAvailabilities();
    this.loader = false;
  }

  setMinAvailability(combo) {

    // SE STO CAMBIANDO IL GIORNALIERO E QUESTO E' MAGGIORE DELLE ALTRE FASCE ORARIE ALLORA QUESTE ULTIME DEVONO ESSERE ALLINEATE AL GIORNALIERO

    if (!combo.timeSlot.timeSlotId) {
      for (let timeSlot of combo.zone.timeSlots) {
        if (timeSlot.timeSlotId) {
          if (timeSlot.residual > combo.timeSlot.residual)
            timeSlot.residual = combo.timeSlot.residual;
        }
      }
    } else {
      let dailyResidual = combo.zone.timeSlots.find(z => { if (!z.timeSlotId) return z});

      for (let timeSlot of combo.zone.timeSlots) {
        if (timeSlot.timeSlotId) {
          if (timeSlot.residual > dailyResidual.residual)
           dailyResidual.residual = timeSlot.residual;
        }
      }
    }

  }

  async checkAvailabilities() {
    this.loader = true;
    this.packages = [];
    const dbAvailabilities = await this.dataService.getAvailabilitiesV2(this.startTimestamp/1000, this.endTimestamp/1000);
    console.log(dbAvailabilities);
    let availabilitiesByPack = this.alignAvailabilityData(dbAvailabilities);
    console.log(availabilitiesByPack);
    console.log(this.allAvailabilities);
    let packagesNoSlots = this.resortData.packages.filter((p) => {
      if (p.type === 'order') {
        return p;
      }
    })

    for (const pack of packagesNoSlots) {
      let objToPush = JSON.parse(JSON.stringify(pack));
      objToPush.zones = [];
      for (const zone of this.resortData.zones) {
        let zoneObj = JSON.parse(JSON.stringify(zone));
        zoneObj.timeSlots = [];

        let objToPushTimeslotZero = {
          timeSlotId: null,
          residual: 0
        }

        if (availabilitiesByPack[pack.id+'_'+zoneObj.id+'_null']) {
          objToPushTimeslotZero.residual =availabilitiesByPack[pack.id+'_'+zoneObj.id+'_null'].min;
        }

        zoneObj.timeSlots.push(objToPushTimeslotZero);

        // PRENDITI SOLO LE STAGIONI CHE RICADONO NEI TIMESTAMPS SELEZIONATI
        // SE SFORA DUE STAGIONI PRENDI SOLO IL GIORNALIERO PER ORA

        let length = (this.endTimestamp - this.startTimestamp)/(24*60*60*1000) +1;
        let seasons = this.dataService.getSeasonsInReservation(this.startTimestamp/1000, length , null);

        if (Object.keys(seasons).length === 1) {
          for (let seasonKey in seasons) {
            let timeslots = this.dataService.resortData.timeSlotsBySeason[seasonKey];
            if (timeslots && timeslots.length) {
              for (let timeSlot of timeslots) {
                let objToPushTimeslot = {
                  timeSlotId: timeSlot.id,
                  startHour: timeSlot.startHour,
                  endHour: timeSlot.endHour,
                  name: timeSlot.name,
                  residual: 0
                }
                if (availabilitiesByPack[pack.id+'_'+zoneObj.id+'_'+timeSlot.id]) {
                  objToPushTimeslot.residual =availabilitiesByPack[pack.id+'_'+zoneObj.id+'_'+timeSlot.id].min;
                }
                zoneObj.timeSlots.push(objToPushTimeslot);
              }
            }
          }
        }
        objToPush.zones.push(zoneObj);

      }

      this.packages.push(objToPush);
    }

    console.log(this.packages);
    this.loader = false;

  }

  alignAvailabilityData(dbAvailabilities) {

    try {
      let availabilitiesByPack = {};
      this.allAvailabilities = [];
      this.allAvailabilities = dbAvailabilities.results.filter( a => {
        if (a.day_timestamp <= this.endTimestamp/1000 && a.day_timestamp >= this.startTimestamp/1000) return a;
      })
  
      console.log(this.allAvailabilities);
      
      let packagesNoSlots = this.resortData.packages.filter((p) => {
        if (p.type === 'order') {
          return p;
        }
      })
      let count = 0;
      for (let ts = this.startTimestamp/1000; ts <= this.endTimestamp/1000; ts+=24*60*60) {
        for (let pack of packagesNoSlots) {
  
          for (let zone of this.resortData.zones) {
            let findAva = this.allAvailabilities.filter(a => {if (a.day_timestamp === ts && a.zoneId === zone.id  && a.packageId === pack.id && !a.timeSlotId) return a})
  
            if (!findAva.length) {
              this.allAvailabilities.push({
                availability: 0,
                day_timestamp: ts,
                packageId: pack.id,
                zoneId: zone.id,
                id: null,
                timeSlotId: null,
              })
            }
  
            let length = (this.endTimestamp/1000 - this.startTimestamp/1000)/(24*60*60) +1;
            let seasons = this.dataService.getSeasonsInReservation(this.startTimestamp/1000, length , null);
  
            for (let seasonKey in seasons) {
  
              let timeslots = this.dataService.resortData.timeSlotsBySeason[seasonKey];
              for (let timeSlot of timeslots) {
  
                let findAva2 =  this.allAvailabilities.filter(a => {if (a.day_timestamp === ts && a.packageId === pack.id && a.zoneId === zone.id && a.timeSlotId === timeSlot.id) return a})
                if (!findAva2.length) {
                  this.allAvailabilities.push({
                    availability: 0,
                    day_timestamp: ts,
                    packageId: pack.id,
                    zoneId: zone.id,
                    id: null,
                    timeSlotId: timeSlot.id,
                  })
                }
              }
            } 
          }
        } 
      }
  
      for (let ava of  this.allAvailabilities) {
  
        if (!availabilitiesByPack[ava.packageId+'_'+ava.zoneId+'_'+ava.timeSlotId]) {
          availabilitiesByPack[ava.packageId+'_'+ava.zoneId+'_'+ava.timeSlotId] = {
            min: ava.availability,
            id: ava.id
          }
        } else {
          if (ava.availability < availabilitiesByPack[ava.packageId+'_'+ava.zoneId+'_'+ava.timeSlotId].min) {
            availabilitiesByPack[ava.packageId+'_'+ava.zoneId+'_'+ava.timeSlotId].min = ava.availability;
          }
        }
      }
  
      return availabilitiesByPack;
    } catch(err) {
      console.log(err);
    }
    
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.loader = true;

    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now)) {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    const startTS = (new Date(event.value)).getTime() - offset;
    const startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.endTimestamp = this.startTimestamp;
    }

    const endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);
    await this.checkAvailabilities();


    this.disableSave = false;
    if (this.startTimestamp !== this.endTimestamp) {
      this.disableSave = true;
    }

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.loader = true;

    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    const endTS = (new Date(event.value)).getTime() - offset;
    const endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.startTimestamp = this.endTimestamp;
    }

    const startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    await this.checkAvailabilities();


    this.disableSave = false;
    if (this.startTimestamp !== this.endTimestamp) {
      this.disableSave = true;
    }
  }

  async setTodayDate() {
    this.loader = true;
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startDate = startDate.getDate();

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endDate = endDate.getDate();

    this.startSerializedDate = new Date(this.startTimestamp);
    this.endSerializedDate = new Date(this.endTimestamp);

    this.checkAvailabilities();


    this.disableSave = false;
    if (this.startTimestamp !== this.endTimestamp) {
      this.disableSave = true;
    }
  }

}
