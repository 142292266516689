import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges } from '@angular/core';
import { DataService } from '../../../core/providers/data.service';
import { AuthService } from '../../../core/providers/auth.service';
import { Subject } from 'rxjs/Subject';
import { AngularFireDatabase } from '@angular/fire/database';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import {  Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() managerData;
  @Input() start;
  @Input() end;
  @Input() pageClicked;

  public onlineOffline: boolean = navigator.onLine;
  online$: Observable<boolean>;
  isOnline = false;


  @Output() onReservationChanged: EventEmitter<any> = new EventEmitter<any>();

  // GLOBAL VARIABLES
  showUserSubmenu = false; size = 100; showStatistics = false;mapSubscription; notifications; showNotifications = false;elementsByIds; lang; firstLoad = true;
  showPayments = false; showLanguageModal1=false; dataLang='it';
  // FILTERS VARIABLES
  term$ = new Subject<string>(); searchString = null; reservations; customers;

  constructor(private translate: TranslateService, public router: Router, public dataService: DataService, public authService: AuthService, private db: AngularFireDatabase) {
      if (typeof this.translate.currentLang === 'undefined') {
        this.translate.currentLang = 'it';
      }
      this.lang = this.translate.currentLang;

      this.online$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      );

      this.networkStatus();
  }

  showOnMap(reservation) {
    this.onReservationChanged.emit(reservation);
  }

  public networkStatus() {
    this.online$.subscribe(value => {
      console.log("CONNECTION "+value);
      this.isOnline = value;
    })
  }


  useLanguage(language: string) {
      this.translate.use(language);
      this.dataService.lang = language;
  }

  async ngOnInit() {
    this.notifications = this.dataService.notifications;

    if (!this.dataService.userData) {
      this.managerData = await this.dataService.getUserData();
    }

  }

  ngOnChanges(changes: SimpleChanges) {
      if (this.pageClicked) {
        this.customers = null;
        this.reservations = null;
        this.searchString = null;
        this.showUserSubmenu = false;
        this.showNotifications = false;
      }

      if (this.dataService.resortData && !this.mapSubscription) {
        this.elementsByIds = this.dataService.elementsByIds;
        this.mapSubscription = this.db.object('realtimeMap/' + this.dataService.resortData.id).valueChanges().subscribe(data => {
          console.log("GET DATA FROM RTMAP");
          if (!this.firstLoad) {
            this.checkNotifications(data);
          } else {
            this.firstLoad = false;
          }
        });
      }
  }

  async checkNotifications(data) {
    if (data[0].state === 3) {
      const slotIds = data[0].slot;
      const reservations = await this.dataService.getReservations(data[0].timestamps[0], data[0].timestamps[0], null, null, null, null, null, null, null, null, null, null, null, slotIds, null, 30, 1,null,null,null, null, null, null, null, null, null,null,null,null)

      if (reservations.results) {
        for (const reservation of reservations.results) {
          const foundNotification = this.dataService.notifications.find(not => (not.type === 'reservation' || not.type === 'refund') && not.data.reservation.id === reservation.id);
          if (!foundNotification) {
            if (!reservation.refundState) {
              this.dataService.notifications.push({
                type: 'reservation',
                data: {
                  reservation: {
                    id: reservation.id
                  }
                },
                date: Math.trunc(new Date().getTime() / 1000)
              });
            } else if (reservation.refundType == 1){
              this.dataService.notifications.push({
                type: 'refund',
                data: {
                  reservation: {
                    id: reservation.id
                  }
                },
                date: Math.trunc(new Date().getTime() / 1000)
              });
            }
          }
        }
      }
    }
  }

  openReservation(id) {
    this.router.navigate(['suite/reservation/' + id]);
  }

}
