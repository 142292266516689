import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SettingsService } from '../../../core/providers/settings.service';
import { ModalPrintersComponent } from '../../../shared/components/modal-printers/modal-printers.component';
import { DataService, Customer } from '../../../core/providers/data.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-modal-confirm-bulk-delete',
  templateUrl: './modal-confirm-bulk-delete.component.html',
  styleUrls: ['./modal-confirm-bulk-delete.component.scss']
})
export class ModalConfirmBulkDeleteComponent implements OnInit {

  downloadData=true;optionalsByIds={};optionalsByKeys={};overlayLoader;percentage;totalReservations;size; pageSize = 1; page = 1;nextPage;prevPage;confirmed = false;error;

  constructor(public dataService: DataService, public dialog: MatDialog, public dialogRef: MatDialogRef<ModalConfirmBulkDeleteComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    this.overlayLoader = true;
    for (const opt of this.dataService.optionals) {
      this.optionalsByIds[opt.id] = opt;
      this.optionalsByKeys[opt.optionalId] = opt;
    }

    if (this.data.type == null) {
      this.data.type = 3;
    }

    console.log(this.data.type)

    let resData = await this.dataService.getReservations(this.data.start/1000, this.data.end/1000, null, null, null, this.data.type,null, null, null, null, null, null, null, null, null, this.pageSize, this.page, true, null, null, null, 'start' , null, null, null, null,null,null,null);
    this.nextPage = resData.next;
    this.prevPage = resData.previous;
    this.totalReservations = resData.count;
    console.log(resData);
    this.overlayLoader = false;
  }

  async deleteBulk(){
    console.log(this.data);

    try {
      this.overlayLoader = true;

      if (this.downloadData) {
        await this.exportXLS();
      }

      await this.dataService.removeBulkReservations(this.data.start/1000, this.data.end/1000, this.data.type,this.data.selectedFiscalPrint);
      this.confirmed = true;
      this.overlayLoader = false;
    } catch(err) {
      this.confirmed = false;
      this.overlayLoader = false;
      this.error = err;
    }


  }


  close(): void {
    this.dialogRef.close({save:false});
  }

  async exportXLS() {

    let outputObjs = [];

    this.overlayLoader = true;
    this.percentage = 0;

    let nextPage = null;
    this.size = this.totalReservations;
    let reservationsToSave = [];

    const start = this.data.start / 1000;
    const end = this.data.end / 1000;

    console.log(this.totalReservations)

    for (let i = 0; i < this.totalReservations; i += 500) {

      let data = null;
      if (i==0) {

        data = await this.dataService.getReservations(start, end, null, null,null, this.data.type, null,  null, null, null, null, null, null, null, null, this.size, this.page, true, null, null, null, 'start', null, null, null, null ,null,null,null);
        nextPage = data.next;
      } else if (nextPage) {
        data = await this.dataService.getReservationsByUrl(nextPage);
        nextPage = data.next;
      } else {
        break;
      }

      console.log(data);

      if (data && data.results) {
        const partialReservations = this.processReservations(data.results);
        for (const res of partialReservations) {
          reservationsToSave.push(res);
        }
      }
    }

    reservationsToSave.map(reservation => {


          let name = reservation.name;

          if (reservation.email && reservation.email !== '') {
            name = name+'\n'+reservation.email;
          }

          if (reservation.phone && reservation.phone !== ''  && reservation.phone.trim() !== '+39') {
            name = name+'\n'+reservation.phone;
          }

          if (reservation.fiscalCode && reservation.fiscalCode !== '') {
            name = name+'\n'+reservation.fiscalCode;
          }

          const obj = {
            id: reservation.id,
            nome: name,
            note: reservation.notes,
            inizio: new Date(reservation.start * 1000),
            fine: new Date(reservation.end * 1000),
            tipo: '',
            pagato: 'NO',
            totale: reservation.amountTotal.toFixed(2).replace(',',';').replace('.',',').replace(';',''),
            incassato: reservation.amountPayed.toFixed(2).replace(',',';').replace('.',',').replace(';',''),
            postazioni: '',
            pacchetti: '',
            cabine: '',
            extras: ''
          };


          for (const packKey in reservation.groupByPacks) {


            if (reservation.groupByPacks[packKey]) {

              for (const element of reservation.groupByPacks[packKey].products) {
                if (obj.postazioni == '') {
                  obj.postazioni += element.coords;
                } else {
                  obj.postazioni += ', ' + element.coords;
                }

              }

              if (obj.postazioni != '') {
                obj.postazioni += '\n';
              }

              if (this.dataService.resortData.packagesByIds[packKey]) {
                if (obj.pacchetti == '') {
                  obj.pacchetti += reservation.groupByPacks[packKey].products.length + ' x ' + this.dataService.resortData.packagesByIds[packKey].name[this.dataService.lang] ;
                } else {
                  obj.pacchetti += ', ' + reservation.groupByPacks[packKey].products.length + ' x ' + this.dataService.resortData.packagesByIds[packKey].name[this.dataService.lang] ;
                }
              } else {
                if (obj.pacchetti == '') {
                  obj.pacchetti += reservation.groupByPacks[packKey].products.length + ' x Pacchetto non più esistente';
                } else {
                  obj.pacchetti += ', ' + reservation.groupByPacks[packKey].products.length + ' x Pacchetto non più esistente';
                }
              }

              for (const optionalKey in reservation.groupByPacks[packKey].optionals) {
                if (reservation.groupByPacks[packKey].optionals[optionalKey]) {
                  const foundOptional = this.dataService.optionals.find(opt => opt.id == optionalKey);
                  if (foundOptional) {
                    obj.pacchetti += ', ' + foundOptional.name[this.dataService.lang] + ' x ' + reservation.groupByPacks[packKey].optionals[optionalKey];
                  }
                }
              }
            }

          }

          if (reservation.extras) {
            for (const extra of reservation.extras) {

              if (obj.extras != '') {
                obj.extras += ', ';
              }

              if (extra.name && typeof extra.name === 'string') {
                obj.extras += extra.quantity + ' x ' + extra.name;
              } else if (this.dataService.resortData.extrasByIds[extra.id]) {
                obj.extras += extra.quantity + ' x ' + this.dataService.resortData.extrasByIds[extra.id].name[this.dataService.lang];
              }  else {
                obj.extras += extra.quantity + ' x ' + 'Extra non più esistente';
              }
            }
          }

          if (reservation.cabins) {
            for (const cabin of reservation.cabins) {
              if (this.dataService.resortData.cabinsByKeys[cabin]) {
                if (obj.cabine == '') {
                  obj.cabine += this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
                } else {
                  obj.cabine += ', ' + this.dataService.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
                }
              } else {
                if (obj.cabine == '') {
                  obj.cabine += '\n Cabina non più esistente';
                } else {
                  obj.cabine += ', Cabina non più esistente';
                }
              }
            }
          }

          if (reservation.refundState) {
            obj.tipo = 'RIMBORSATA';
          } else if (reservation.cocoBooking) {
            obj.tipo = 'COCO BOOKING';
          } else if (reservation.seasonTicket){
            obj.tipo = 'STAGIONALE';
          } else {
            obj.tipo = 'MANAGER';
          }

          if (reservation.order.settled) {
            obj.pagato = 'SI';
            obj.incassato = reservation.total;
          }


          outputObjs.push(obj);

    });

    console.log(outputObjs);

    outputObjs.sort(this.sortByDateAsc);

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(outputObjs);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    var startDate = new Date(this.data.start);
    var endDate = new Date(this.data.end);

    var startDay = startDate.getDate();
    var startMonth = startDate.getMonth() + 1;

    var endDay = endDate.getDate();
    var endMonth = endDate.getMonth() + 1;


    XLSX.writeFile(wb, 'Prenotazioni_' + startDay + '-' + startMonth + '_' + endDay + '-' + endMonth + '.xlsx');

    this.size = null;
  }

  processReservations(data) {
    const output = [];

    for (const reservation of data) {

      for (const singleInterval of reservation.dates) {
        if ((singleInterval.start <= this.data.start / 1000 && singleInterval.end >= this.data.start / 1000 && singleInterval.end <= this.data.end / 1000) || (singleInterval.start >= this.data.start / 1000 && singleInterval.end <= this.data.end / 1000) || (singleInterval.start <= this.data.end / 1000 && singleInterval.end >= this.data.end / 1000)) {

            if (reservation.version === 3) {
              for (const interval of reservation.dates) {
                for (const order of interval.order) {
                    for (const optionalId in order.optionalsKeys) {
                      if (order.optionalsKeys[optionalId] && this.optionalsByKeys[optionalId]) {
                        order.optionalsKeys[this.optionalsByKeys[optionalId].id] = order.optionalsKeys[optionalId];
                        delete order.optionalsKeys[optionalId];
                      }
                    }
                }
              }
            }


            let checkout = true;
            let checkin = true;

            for (let ts = this.data.start/1000; ts <= this.data.end/1000; ts += 24*60*60) {
              if (!reservation.checkOutDates || reservation.checkOutDates.length === 0 || reservation.checkOutDates.indexOf(ts) === -1) {
                checkout = false;
              }

              if (!reservation.checkInDates || reservation.checkInDates.length === 0 || reservation.checkInDates.indexOf(ts) === -1) {
                checkin = false;
              }
            }

            if (!reservation.phonePrefix || reservation.phonePrefix === '') {
              reservation.phonePrefix = '+39';
            }

            const obj = {
              refundState: reservation.refundState,
              noSlots: reservation.noSlots,
              code: reservation.reservationCode,
              cabins: reservation.cabins,
              name: reservation.name + ' ' + reservation.surname,
              order: reservation.order,
              start: singleInterval.start,
              end: singleInterval.end,
              fiscalCode: reservation.fiscalCode,
              groupByPacks: {},
              checkout,
              checkin,
              extras: reservation.extras,
              notes: reservation.notes,
              amountPayed: 0,
              amountTotal: 0,
              phone: reservation.phonePrefix + ' '+reservation.phone,
              email: reservation.email,
              id: reservation.id,
              seasonTicket: reservation.seasonTicket,
              cocoBooking: reservation.cocoBooking
            };

            if (reservation.order.customerBill) {

              for (const billObj of reservation.order.customerBill) {
                if (billObj.payed) {
                    obj.amountPayed += parseFloat(billObj.price);
                } else {
                    obj.amountTotal += parseFloat(billObj.price);
                }
              }
            }

            for (const product of singleInterval.order) {


              if (!obj.groupByPacks[product.packageKey]) {
                obj.groupByPacks[product.packageKey] = {
                  products: [],
                  optionals: {}
                };
              }

              obj.groupByPacks[product.packageKey].products.push(product);

              if (product.optionalsKeys) {
                for (let optKey in product.optionalsKeys) {
                  if (product.optionalsKeys[optKey]) {
                    if (!this.dataService.optionals[optKey]) {
                      for (const optId in this.dataService.optionals ) {
                        if (this.dataService.optionals[optId].id == optKey) {
                          optKey = this.dataService.optionals[optId].id;
                          break;
                        }
                      }
                    }

                    if (!obj.groupByPacks[product.packageKey].optionals[optKey]) {
                      obj.groupByPacks[product.packageKey].optionals[optKey] = 0;
                    }

                    obj.groupByPacks[product.packageKey].optionals[optKey] += product.optionalsKeys[optKey];
                  }

                }

              }
            }

            output.push(obj);
        }
      }
    }

    return output;
  }

  sortByDateAsc( a, b ) {
    if ( a.inizio < b.inizio ) {
      return -1;
    }
    if ( a.inizio > b.inizio ) {
      return 1;
    }
    return 0;
  }

}
