import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { ActivatedRoute,Router, RoutesRecognized } from '@angular/router';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalRemoveComponent } from '../../../shared/components/modal-remove/modal-remove.component';
import { ModalTextComponent } from '../../../shared/components/modal-text/modal-text.component';
import { prefixes } from '../../../../assets/country-codes.json';
import { CustomersService } from 'src/app/core/providers/customer.service';
import { ModalRefundDetailComponent } from '../../components/modal-refund-detail/modal-refund-detail.component';
import { ModalCocobukUserComponent } from '../../components/modal-cocobuk-user/modal-cocobuk-user.component';
import { ReservationService } from 'src/app/core/providers/reservation.service';
import { ModalPrintersComponent } from 'src/app/shared/components/modal-printers/modal-printers.component';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {

  // GLOBAL VARIABLES
  pageClicked = false; resortData; managerData; customer = null; phonePrefixes;
  menuIndex = 0; removable = true; addOnBlur = true; optionals; otherCustomers;
  separatorKeysCodes = [ENTER, COMMA]; reservations = []; lang = 'it'; originalReservations;
  refunds:any[] = []; loader:boolean = false; 
  emailValidity = true;
  formValidity = false;

  objectKeys = Object.keys;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public dataService: DataService,
    private settingsService: SettingsService,
    private customerService: CustomersService,
    private reservationService: ReservationService,
    private cdr: ChangeDetectorRef
    ) {

    this.managerData = this.dataService.userData;
    this.resortData = this.dataService.resortData;
  }

  async ngOnInit() {
    this.loader = true;
    this.phonePrefixes = prefixes;
    const params = await new Promise(resolve => this.route.params.subscribe( params =>{
      resolve(params);
    }));

    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;

    if (params['id']) {
      console.log(params['id'])

      if (params['id'] != 'new') {
        this.customer = await this.dataService.getSingleCustomer(params['id']);

        if (!this.customer.phonePrefix || this.customer.phonePrefix == '') {
          if (this.customer.phone.split("+")[1]) {
            this.customer.phonePrefix = this.customer.phone.substring(0,3);
            this.customer.phone = this.customer.phone.replace(this.customer.phonePrefix, '');
          } else {
            this.customer.phonePrefix = '+39';
          }
        } else if (this.customer.phonePrefix === '39') {
          this.customer.phonePrefix = '+39';

        }

        if (!this.customer.totalPayed) {
          this.customer.totalPayed = 0;
        }

        let searchKey = this.customer.name;

        if (this.customer.surname && this.customer.surname !== '') {
          searchKey += ' ' + this.customer.surname;
        }

        if (this.customer.uid && this.customer.bookingId !== 'none' && this.customer.email && this.resortData.id !== 125 && this.resortData.id !==383 && this.resortData.id !== 522 )  {

          this.customer.email = this.customer.email[0] + this.customer.email.split('@')[0].replace(/.*/,'*') + '@' + this.customer.email.split('@')[1]
        }

        if (this.customer.uid && this.customer.bookingId !== 'none' && this.customer.phone && this.resortData.id !== 125 && this.resortData.id !==383 && this.resortData.id !== 522)  {

          this.customer.phone = this.customer.phone[0]+this.customer.phone[1]+this.customer.phone[2]+this.customer.phone.replace(/.*/g, '*');;
        }

        const data = await this.dataService.getCustomerReservations(params['id']);

        if (this.customer.uid && this.customer.uid !== '') {
          this.refunds = await this.customerService.getRefunds(this.customer.uid,this.resortData.id);
        } else if (this.customer.email && this.customer.email !== ''){
          let cocobukUser:any = await this.customerService.searchCocobukUser(this.customer.email);
          console.log(cocobukUser);
          if (cocobukUser) {
            this.customer.uid = cocobukUser.uid;
            this.customer.bookingId = 'none';
            await this.saveCustomer(false);
          }
        }

        console.log(this.refunds);
        console.log(this.customer)
        this.reservations = this.processReservations(data.results);
        this.reservations.sort(this.sortByDateDesc);
        this.originalReservations = data.results;
        await this.getCustomers(searchKey);
        this.loader = false;

        this.checkFormValidity();
      } else {
        this.customer = {
          name: '',
          surname: '',
          address: '',
          associatedNames: [],
          fiscalCode: '',
          city: '',
          email: '',
          phonePrefix: '+39',
          phone: '',
          uid: '',
          discountId: null,
          bookingId: '',
          resortId: this.resortData.id
        }
        this.loader = false;
      }


    }


  }

  checkFormValidity() {
    let emailRegx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    this.emailValidity = (this.customer.email.trim() === '' || (this.customer.email != '' && emailRegx.test(this.customer.email)));
    if ((this.customer.name.trim()== '' && this.customer.surname.trim() == '') || !this.emailValidity) {
      this.formValidity = false;
    } else {
      this.formValidity = true;
    }
  }

  pushRefund() {
    const currentYear = new Date().getFullYear();
    let createdAt =  Math.trunc(new Date().getTime()/1000);
    let expiration = Math.trunc(new Date(currentYear, 11, 31).getTime()/1000);
    let dialogRef = this.dialog.open(ModalRefundDetailComponent, {
      data: {
        amount:0,
        totalAmount: 0,
        end: expiration,
        new:true,
        refundNotes: "Tessera Buono",
        paid:false,
        reservationCode:null
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log(result);
        this.loader = true;
        // CREATE RESERVATION ONLY WITH CUSTOMER BILL AND TOTAL (NO PRODUCTS)
        let reservation = null;
        let reservationCode = null;

        if (result.paid) {
          reservation = await this.saveReservation(createdAt,result);
          reservationCode = reservation.reservationCode;
        }   

        if (!this.customer.uid || this.customer.uid === '') {
          let newData = {
            name: this.customer.name,
            surname: this.customer.surname,
            email: this.customer.email,
            phone: this.customer.phone,
            phonePrefix: this.customer.phonePrefix
          }
          let newCustomer = await this.customerService.pushFirebaseUser(newData);
          console.log(newCustomer);
          this.customer.bookingId = 'none';
          this.customer.uid = newCustomer.uid;
          await this.saveCustomer(false);
        }
        
        let refund = {
          "notes":result.refundNotes,
          "amount":result.amount,
          "expiration":result.expiration,
          "resortId":this.resortData.id,     
          "resortName":this.resortData.name,
          "uid":this.customer.uid,
          "reservationId":reservationCode,
          "paid":result.paid
        }

        await this.customerService.pushRefund(refund);

        this.refunds = await this.customerService.getRefunds(this.customer.uid,this.resortData.id);

        if (result.print) {
          await this.print(reservation,result.paymentType, refund.notes);
        }

        this.loader = false;
      }
    });
  }

  editRefund(refund) {
    console.log(refund);
    let paid = false;

    if (refund.paid) {
      paid = true;
    }


    let dialogRef = this.dialog.open(ModalRefundDetailComponent, {
      data: {
        amount:refund.amount,
        totalAmount: refund.totalAmount,
        end: refund.expiration,
        refundNotes: refund.notes,
        new:false,
        paid:paid,
        reservationCode:refund.reservationId
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
       console.log(result);
       if (result) {
        this.loader = true;

        let createdAt =  Math.trunc(new Date().getTime()/1000);
        let reservation = null;
        let reservationCode = null;
        if (!refund.reservationId && result.paid) {
          reservation = await this.saveReservation(createdAt,result);
          reservationCode = reservation.reservationCode;
        } else if (result.reservation) {
          reservation = result.reservation;
          reservationCode = reservation.reservationCode;
        }

        if (result.print && reservation) {
          await this.print(reservation,refund.paymentType, refund.notes);
        }

        refund.amount = result.amount;
        refund.totalAmount = result.totalAmount;
        refund.expiration = refund.expiration;
        refund.notes = result.refundNotes;
        refund.reservationId = reservationCode;
        refund.paid = result.paid;

        await this.customerService.updateRefund(refund);
        this.refunds = await this.customerService.getRefunds(this.customer.uid,this.resortData.id);
        
        this.loader = false;
       }
    });
  }

  async print(reservation:any, paymentType:any, name:any) {
    
    const dialogRef = this.dialog.open(ModalPrintersComponent, {
      width: '400px',
      data: {
        fiscalPrint: true,
        reservation: reservation,
        paymentType: paymentType,
        bill:{
          createdAt: Math.trunc(new Date().getTime() / 1000),
          updatedAt: Math.trunc(new Date().getTime() / 1000),
          createdBy: this.settingsService.getCurrentUserData(),
          updatedBy: this.settingsService.getCurrentUserData(),
          date: Math.trunc(new Date().getTime() / 1000),
          serializedDate: new Date(),
          name: name,
          fiscalPrinter: '',
          fiscalPrinted: false,
          settledFlag: false,
          type: 'movement',
          payed: true,
          paymentType: paymentType,
          price: reservation.order.total,
          new: true
        }
      }
    });

    dialogRef.afterClosed().subscribe(async result => {

      if (result) {

        this.settingsService.updateCustomerBill(reservation, true, paymentType);

        for (const bill of reservation.order.customerBill) {
          if (bill.payed && bill.type == 'settlement') {
            bill.type = 'movement'
            bill.settledFlag = false;
            bill.fiscalPrinter = result;
            bill.fiscalPrinted = true;
            bill.printedBy = this.settingsService.getCurrentUserData();
          }

        }
        // console.log(JSON.parse(JSON.stringify(this.reservation.order)));
        await this.updateReservation(reservation);
      }
    });
  }

  async saveReservation(createdAt,result) {
    const reservation:any = this.dataService.initReservation(createdAt*1000, createdAt*1000, false);
    console.log(reservation);

    reservation.order.total = result.amount;
    reservation.order.subtotal = result.amount;
    this.settingsService.updateCustomerBill(reservation, true, result.paymentType);
      
    // -> PUSH order total and subtotal
    // -> PUSH customerBill

    console.log(reservation);

    // SAVE CUSTOMER DATAs
    reservation.relatedCustomers = [];
    reservation.dates = [];
    reservation.customerId = this.customer.id;
    reservation.relatedCustomers.push({
      customer: reservation.customerId,
      principal: true
    });

    let pushReservation = await this.dataService.setReservation(reservation);

    console.log(pushReservation);

    return pushReservation;
  }

  async updateReservation(reservation) {
    
    reservation.relatedCustomers = [];
    reservation.dates = [];
    reservation.customerId = this.customer.id;
    reservation.relatedCustomers.push({
      customer: reservation.customerId,
      principal: true
    });

    reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
    reservation.updatedBy = this.settingsService.getCurrentUserData();
    reservation.version = 4;
    await this.dataService.updateReservation(reservation);
  }

  async removeRefund(refund) {
    this.loader = true;
    await this.customerService.removeRefund(refund);
    this.refunds = await this.customerService.getRefunds(this.customer.uid,this.resortData.id);
    this.loader = false;
  }

  async associateCocobukAccount() {
    let dialogRef = this.dialog.open(ModalCocobukUserComponent, {
      data: {
        
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log(result);
        this.customer.uid = result.uid;
        this.customer.bookingId = 'none';
        await this.saveCustomer(false);
      }
    });
  }

  sortByDateDesc( a, b ) {
    if ( a.end < b.end ) {
      return 1;
    }
    if ( a.end > b.end ) {
      return -1;
    }
    return 0;
  }

  showPrivacyModal() {

    let dialogRef = this.dialog.open(ModalTextComponent, {
      width: '800px',
      data: {
        title: "Perchè queste informazioni sono bloccate o mascherate.",
        text: "Questo campo risulta bloccato e non esportabile a causa delle normative stabilite dalla GDPR Regulation, regolamento definito dalla comunità europea al fine di garantire il rispetto della privacy degli utenti.<br><br>L’utente infatti, iscrivendosi e utilizzando Cocobuk.com accetta un contratto di privacy e trattamento dati con Coco s.r.l. Quest’ultimo stabilisce che, se non espressamente esplicitato in fase di iscrizione alla piattaforma, i dati dell’utente possono essere utilizzati unicamente per fini inerenti alla gestione della prenotazione (comunicazione di un cambiamento, spostamento ecc.).<br><br>L’utente quindi afferma in maniera esplicita di non acconsentire all’utilizzo delle informazioni personali a soggetti terzi diversi da Cocobuk per fini di promozione e marketing.<br><br>Per questi motivi quindi non ti è possibile esportare in maniera massiva i dati degli utenti nè di copiarli. Entrambe queste operazioni sarebbero associate a fini promozionali e quindi vietate dalla GDPR in quanto diverse dalla volontà dell’uente.<br><br>Puoi trovare maggiori informazioni su <a href='https://www.cocobusiness.it/privacy-policy/' target='blank'>https://www.cocobusiness.it/privacy-policy/</a>"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
          console.log(result);
    });
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout(() => {
      this.pageClicked = false;
    },1000)
  }

  changeContent(index, el: HTMLElement) {
    this.menuIndex = index;
    el.scrollIntoView();
  }

  addName(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!this.customer.associatedNames) {
      this.customer.associatedNames = [];
    }

    // Add our associated name
    if ((value || '').trim()) {
      this.customer.associatedNames.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeName(name: any): void {
    const index = this.customer.associatedNames.indexOf(name);

    if (index >= 0) {
      this.customer.associatedNames.splice(index, 1);
    }
  }

  removeCustomer() {
    let dialogRef = this.dialog.open(ModalRemoveComponent, {
      width: '340px',
      height: '130px',
      data: {
        type: "customers",
        customer: this.customer
      }
    });

    dialogRef.afterClosed().subscribe(result => {
          console.log(result);
    });
  }

  processReservations(data) {
    let output = [];

    for (const reservation of data) {
        for (const singleInterval of reservation.dates) {

              const obj = {
                name: reservation.name,
                order: reservation.order,
                start: singleInterval.start,
                end: singleInterval.end,
                groupByPacks: {},
                extras: reservation.extras,
                amountPayed: 0,
                amountTotal: 0,
                id: reservation.id,
                seasonTicket: reservation.seasonTicket,
                cocoBooking: reservation.cocoBooking,
                noSlots: reservation.noSlots
              };

              if (reservation.order.customerBill) {

                for (const billObj of reservation.order.customerBill) {

                  if (billObj.payed) {
                    obj.amountPayed += parseFloat(billObj.price);
                  } else {
                    obj.amountTotal += parseFloat(billObj.price);
                  }
                }
              }

              for (const product of singleInterval.order) {

                if (!obj.groupByPacks[product.packageKey]) {
                  obj.groupByPacks[product.packageKey] = {
                    products: [],
                    optionals: {}
                  }
                }

                obj.groupByPacks[product.packageKey].products.push(product);

                if (product.optionalsKeys) {
                  for (let optKey in product.optionalsKeys) {
                    if (product.optionalsKeys[optKey]) {
                      if (!this.optionals[optKey]) {

                        for (const optId in this.optionals ) {
                          if (this.optionals[optId].id == optKey) {
                            optKey = this.optionals[optId].id;
                            break;
                          }
                        }

                      }

                      if (!obj.groupByPacks[product.packageKey].optionals[optKey]) {
                        obj.groupByPacks[product.packageKey].optionals[optKey] = 0;
                      }

                      obj.groupByPacks[product.packageKey].optionals[optKey] += product.optionalsKeys[optKey]
                    }
                  }
                }
              }

              output.push(obj);

        }
    }

    return output;
  }

  async saveCustomer(redirect:boolean) {
    if (!this.customer.id || this.customer.id === 'new') {
      await this.dataService.setCustomer(this.customer);
    } else {
      let associatedNames = '';

      if (this.customer.associatedNames) {

        for (const associated of this.customer.associatedNames) {
          associatedNames += '_' + associated;
        }
      }

      if (redirect) {
        for (const reservation of this.originalReservations) {
          if (associatedNames != '') {
            if (this.customer.surname && this.customer.surname !== '') {
              reservation.searchKey = this.customer.name + ' ' + this.customer.surname + '_' + associatedNames;
            } else {
              reservation.searchKey = this.customer.name  + '_' + associatedNames;
            }
          } else {
            if (this.customer.surname && this.customer.surname !== '') {
              reservation.searchKey = this.customer.name + ' ' + this.customer.surname;
            } else {
              reservation.searchKey = this.customer.name;
            }
          }
          reservation.relatedCustomers = [];
          console.log(reservation);
          for (const customer of reservation.customers) {

            reservation.relatedCustomers.push({
              customer: customer.customer.id,
              principal: customer.principal
            });
          }
          const result = await this.dataService.updateReservation(reservation);
        }
      }
      this.customer.resortId = this.dataService.resortData.id;
      await this.dataService.updateCustomer(this.customer);
    }

    if (redirect) {
      if (!this.dataService.previousPage) {
        this.router.navigate(['/common/customers']);
      } else {
        this.router.navigate(['/'+this.dataService.previousPage]);
      }
    }
  }

  async getCustomers(searchString) {

    if (this.customer.name !== '' && this.customer.surname !== '') {
      console.log(searchString);
      let oldQuery = JSON.parse(JSON.stringify(this.dataService.lastCustomersQuery));
      const data = await this.dataService.getCustomers( searchString, null, 10, 0, false);
      this.dataService.lastCustomersQuery = oldQuery;
      this.otherCustomers = [];

      for (const customer of data.results) {
        customer.name = customer.name.charAt(0).toUpperCase() + customer.name.slice(1).toLowerCase(); 
        customer.surname = customer.surname.charAt(0).toUpperCase() + customer.surname.slice(1).toLowerCase(); 
        if (customer.name.trim() === this.customer.name.trim() && customer.surname.trim() === this.customer.surname.trim()) {
          this.otherCustomers.push(customer);
        }
      }
      console.log(this.otherCustomers);
    }

  }

}
