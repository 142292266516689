import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service'
import { SettingsService } from '../../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';
import { ModalErrorComponent } from '../../../../shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-zone-detail',
  templateUrl: './zone-detail.component.html',
  styleUrls: ['./zone-detail.component.scss']
})
export class ZoneDetailComponent implements OnInit {

  resortData; managerData; pageClicked; prices; zoneId; lang = 'it';  clicked = false;
  selectedZone; optionals; dataLang='it'; rule1=0; elements; features;services; showLanguageModal1=false;
  objectKeys = Object.keys; showTip = false; showTipRemove = false;

  constructor(public router: Router, public dialog: MatDialog,private route: ActivatedRoute, private dataService: DataService, private settingsService: SettingsService) {
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.dataLang = this.dataService.lang;

    if (this.resortData) {
      this.initZone();
    }
  }

  async ngOnInit() {
    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.resortData = this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.prices =  this.dataService.prices;
    this.optionals =  this.dataService.optionals;
    this.elements =  this.dataService.elements;
    this.features =  this.dataService.features;
    this.services =  this.dataService.packagesServices;

    console.log(this.prices)
    console.log(this.optionals);

    console.log("RESORT DATA");
    console.log(this.resortData);

    this.initZone();
  }

  initZone() {
    this.zoneId = this.route.snapshot.paramMap.get('zoneId');

    if (this.resortData.zonesByIds[this.zoneId]) {
      this.selectedZone = JSON.parse(JSON.stringify(this.resortData.zonesByIds[this.zoneId]));
    } else {
      this.zoneId = null;
      this.selectedZone = {
        name: JSON.parse(JSON.stringify(this.dataService.languages)),
        id: null
      }
    }
  }

  resetHeader() {

  }

  async saveZone() {

    console.log(this.selectedZone);

    if (this.selectedZone.id) {
      this.resortData.zonesByIds[this.zoneId] = this.selectedZone;
      let foundIndex = this.resortData.zones.findIndex(disc => disc.id === this.selectedZone.id);
      this.resortData.zones[foundIndex] = this.selectedZone;
      await this.dataService.updateZone(this.selectedZone);
    } else {
      const newZone = await this.dataService.createZone(this.selectedZone);
      this.resortData.zones.push(newZone);
      for (const zoneObj of this.resortData.zones) {
        this.resortData.zonesByIds[zoneObj.id] = zoneObj;
      }
    }

    this.router.navigate(['settings/offer/3']);
  }

  removeZone() {

    let zoneFound = false;

    for (const slot of this.dataService.map) {
      if (slot.packagesIds.length > 0 && slot.zoneId == this.selectedZone.id) {
        zoneFound = true;
        break;
      }
    }

    if (zoneFound) {
      let dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'remove-error',
          error: 'zones'
        }
      })

      const self = this;

      dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            self.clicked = false;
      });

    } else {

      let dialogRef = this.dialog.open(ModalRemoveComponent, {
        width: '340px',
        height: '130px',
        data: {
          type:"zones",
          zone: this.selectedZone
        }
      })

      dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.clicked = false;
      });
    }
  }

}
