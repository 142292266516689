import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service'
import { SettingsService } from '../../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';

@Component({
  selector: 'app-cabin-detail',
  templateUrl: './cabin-detail.component.html',
  styleUrls: ['./cabin-detail.component.scss']
})
export class CabinDetailComponent implements OnInit {

  resortData; managerData; pageClicked; prices; cabinId; lang = 'it';
  selectedCabin; optionals; dataLang='it'; rule1=0; elements; features;services;
  objectKeys = Object.keys; clicked=false;showLanguageModal1=false;

  constructor(public router: Router, public dialog: MatDialog,private route: ActivatedRoute, private dataService: DataService, private settingsService: SettingsService) {
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.dataLang = this.dataService.lang;

    if (this.resortData) {
      this.initCabin();
    }
  }

  async ngOnInit() {
    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;
    this.prices = this.dataService.prices;

    this.initCabin();
  }

  initCabin() {
    this.cabinId = this.route.snapshot.paramMap.get('cabinId');
    const cabFound = this.resortData.cabins.find(cab => cab.id === this.cabinId);
    if (cabFound) {
      this.selectedCabin = JSON.parse(JSON.stringify(cabFound));
    } else {
      this.cabinId = null;
      this.selectedCabin = {
        label: JSON.parse(JSON.stringify(this.dataService.languages)),
        id: null
      };
    }
  }

  resetHeader() {

  }

  async saveCabin() {

    if (this.selectedCabin.id) {
      let cabFound = this.resortData.cabins.findIndex(cab => cab.id === this.cabinId);
      this.resortData.cabins[cabFound] = this.selectedCabin;
      await this.dataService.setResort(this.resortData);
    } else {
      this.selectedCabin.id = this.generateHexString(5);
      this.cabinId = this.selectedCabin.id;
      const cabFound = this.resortData.cabins.find(cab => cab.id === this.cabinId);
      if (!cabFound) {
        this.resortData.cabins.push(this.selectedCabin);
        await this.dataService.setResort(this.resortData);
      } else {
        this.selectedCabin.id = null;
        this.cabinId = this.selectedCabin.id;
        this.saveCabin();
      }
    }

    this.router.navigate(['settings/offer/9']);
  }

  removeCabin() {
    const dialogRef = this.dialog.open(ModalRemoveComponent, {
      width: '340px',
      height: '130px',
      data: {
        type: 'cabins',
        cabin: this.selectedCabin
      }
    });

    dialogRef.afterClosed().subscribe(result => {
          console.log(result);
    });
  }

  generateHexString(length) {
    let ret = '';
    while (ret.length < length) {
      ret += Math.random().toString(16).substring(2);
    }
    return ret.substring(0, length);
  }

}
