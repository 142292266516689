import { Injectable } from '@angular/core';
import { DataService, Customer } from './data.service';
import { TranslateService } from '@ngx-translate/core';
import { NativeDateAdapter } from '@angular/material';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  months = {
    it: ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'],
    en: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
  };

  days = {
    it: ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'],
    en: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  };

  daysIndexToLabel = [
    'su',
    'mo',
    'tu',
    'we',
    'th',
    'fr',
    'sa'
  ]

  timeslots= ['00:00', '00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30']

  seasonDays = [{
      en:'monday',
      it:'lunedì',
      key: 'mo'
    },{
      en:'tuesday',
      it:'martedì',
      key: 'tu'
    },{
      en:'wednesday',
      it:'mercoledì',
      key: 'we'
    },{
      en:'thurdsay',
      it:'giovedì',
      key: 'th'
    },{
      en:'friday',
      it:'venerdì',
      key: 'fr'
    },{
      en:'saturday',
      it:'sabato',
      key: 'sa'
    },{
      en:'sunday',
      it:'domenica',
      key: 'su'
    }];

  constructor(private dataService: DataService, private translateService: TranslateService) { }

  stdTimezoneOffset (now) {
    const jan = new Date(now.getFullYear(), 0, 1);
    const jul = new Date(now.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  }

  daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
  }

  isDstObserved(now) {
      return now.getTimezoneOffset() < this.stdTimezoneOffset(now);
  }

  updateCart(reservation,fromReservedPrice) {
    
    if (reservation.order.forcedPrice) {
      fromReservedPrice = true;
    }

    const pushedSlots = [];
    const oldProducts = JSON.parse(JSON.stringify(reservation.order.products));
    const oldReservation = JSON.parse(JSON.stringify(reservation));
    reservation.order.products = [];
    reservation.order.subtotal = 0;
    reservation.order.total = 0;
    reservation.order.discount = 0;
    let reservedDiscount = null;
    let seasonPriceFlag = false;

    reservation.dates = [];

    for (const interval of oldReservation.dates) {
      interval.intervalAmount = 0;
      interval.optionalsAmount = 0;
      interval.optionalsCounter = 0;

      let timeslotId = 0;

      if (interval.timeSlots && interval.timeSlots.length) {
        timeslotId = interval.timeSlots[0].id;
      }

      for (let order of interval.order) {
        let productPrice = 0;

        if (reservation.order.specialSeasonPrice) {
          for (const season of this.dataService.resortData.seasons) {
            if (season.special && this.dataService.prices[order.packageKey + '_' + order.zoneKey + '_' + season.id] && season.start === interval.start && season.end === interval.end) {
              productPrice = this.dataService.prices[order.packageKey + '_' + order.zoneKey + '_' + season.id].specialSeason;
            }
          }
        }

        const seasons = this.dataService.getSeason(interval.start, interval.length, null);
        if (!productPrice) {
          productPrice =  this.dataService.getPrice(this.dataService.prices, interval.start * 1000,interval.end * 1000, seasons, order.packageKey, null, order.zoneKey, this.dataService.resortData.weeklyPrice, this.dataService.resortData.proportionalPrice, this.dataService.resortData.monthlyPrice, reservation.seasonTicket, reservation.order.dailyPrice, null, interval, this.dataService.resortData.biweeklyPrice,timeslotId, reservation.order.specialSeasonPrice);
        }

        if (!reservation.noSlots && pushedSlots.indexOf(order.slot_key) > -1 && reservation.seasonTicket) {
          productPrice = 0;
        } else if (!reservation.noSlots && pushedSlots.indexOf(order.slot_key) == -1 && reservation.seasonTicket) {
          pushedSlots.push(order.slot_key);
        }

        order.price = JSON.parse(JSON.stringify(productPrice));
        order.optionalsPrices = {};

        if (order.type === 'order') {

          interval.intervalAmount += productPrice * order.quantity;
          reservation.order.subtotal += productPrice * order.quantity;
        } else {
          interval.intervalAmount += productPrice;
          reservation.order.subtotal += productPrice;
        }

        const packageId = order.packageKey;
        const zoneId = order.zoneKey;

        order.zoneKey = zoneId;
        order.packageKey = packageId;

        let oldProduct = oldProducts.find(prod => {
          if (prod.type == 'package' && prod.packageId ==  packageId && prod.row == order.row && prod.col == order.col && prod.startDate == interval.start && prod.endDate == interval.end) {
              return prod;
          } else {
            return null;
          }
        });

        let quantity = 1;

        if (order.type === 'order') {
            quantity = order.quantity;
        }

        let packageName = 'PACCHETTO NON PIU ESISTENTE';
        let zoneName = 'ZONA NON PIU ESISTENTE';

        if (this.dataService.resortData.packagesByIds[packageId]) {
          packageName = this.dataService.resortData.packagesByIds[packageId].name;
        }

        if (this.dataService.resortData.zonesByIds[zoneId]) {
          zoneName = this.dataService.resortData.zonesByIds[zoneId].name;
        }

        if (!interval.timeSlots) {
          interval.timeSlots = [];
        }

        const product = {
          col: order.col,
          row: order.row,
          startDate: interval.start,
          endDate: interval.end,
          length: interval.length,
          timeSlots: interval.timeSlots,
          name: packageName,
          packageId,
          price: productPrice,
          type: 'package',
          percentage: null,
          label: order.coords,
          quantity,
          discountId: null,
          extraId: null,
          zone: zoneName,
          zoneId,
          createdAt: Math.trunc(new Date().getTime() / 1000),
          createdBy: this.getCurrentUserData(),
          updatedAt: Math.trunc(new Date().getTime() / 1000),
          updatedBy: this.getCurrentUserData()
        };

        if (oldProduct) {
          product.createdAt = oldProduct.createdAt;
          product.createdBy = oldProduct.createdBy;
        }

        reservation.order.products.push(product);

        for (const optionalKey in order.optionalsKeys) {
          if (order.optionalsKeys.hasOwnProperty(optionalKey)) {

            const optionalQuantity = order.optionalsKeys[optionalKey];
            interval.optionalsCounter += optionalQuantity;

            let optionalPrice = 0;

            if (reservation.order.specialSeasonPrice) {

              for (const season of this.dataService.resortData.seasons) {
                if (season.special && this.dataService.prices[order.packageKey+ '_' + optionalKey + '_' + order.zoneKey  +'_' + season.id] && season.start === interval.start && season.end === interval.end) {
                  optionalPrice = this.dataService.prices[order.packageKey + '_' + optionalKey + '_' + order.zoneKey  +'_' + season.id].specialSeason;
                }
              }
            }

            if (!optionalPrice) {
              optionalPrice = this.dataService.getPrice(this.dataService.prices, interval.start * 1000, interval.end * 1000,seasons, order.packageKey, optionalKey, order.zoneKey, this.dataService.resortData.weeklyPrice, this.dataService.resortData.proportionalPrice, this.dataService.resortData.monthlyPrice, reservation.seasonTicket, reservation.order.dailyPrice, null, interval, this.dataService.resortData.biweeklyPrice,timeslotId, reservation.order.specialSeasonPrice);
            }


            if (seasonPriceFlag && reservation.seasonTicket) {
              optionalPrice = 0;
            }

            order.optionalsPrices[optionalKey] = optionalPrice;

            if (optionalPrice === undefined || optionalPrice === null) {
              optionalPrice = 0;
            }

            interval.optionalsAmount += optionalPrice * optionalQuantity;
            reservation.order.subtotal += optionalPrice * optionalQuantity;

            oldProduct = oldProducts.find(prod => {
              if (prod.type == 'optional' && prod.packageId ==  order.packageKey && prod.optionalId == optionalKey && prod.row == order.row && prod.col == order.col && prod.startDate == interval.start && prod.endDate == interval.end) {
                  return prod;
              } else {
                return null;
              }
            });

            const foundOptional = this.dataService.resortData.packagesByIds[order.packageKey].optionals.find(opt => opt.optional.id == parseInt(optionalKey))

            if (foundOptional) {
              const optProduct = {
                col: order.col,
                row: order.row,
                startDate: interval.start,
                endDate: interval.end,
                length: interval.length,
                name: foundOptional.optional.name,
                packageId: order.packageKey,
                price: optionalPrice,
                timeSlots: interval.timeSlots,
                type: 'optional',
                percentage: null,
                discountId: null,
                extraId: null,
                optionalId: optionalKey,
                label: order.coords,
                quantity: optionalQuantity,
                zone: this.dataService.resortData.zonesByIds[order.zoneKey],
                zoneId: order.zoneKey,
                createdAt: Math.trunc(new Date().getTime() / 1000),
                createdBy: this.getCurrentUserData(),
                updatedAt: Math.trunc(new Date().getTime() / 1000),
                updatedBy: this.getCurrentUserData(),
              };

              if (oldProduct) {
                optProduct.createdAt = oldProduct.createdAt;
                optProduct.createdBy = oldProduct.createdBy;
              }

              reservation.order.products.push(optProduct);
            }


          }
        }

        order.price = JSON.parse(JSON.stringify(productPrice));
      }

      reservation.dates.push(JSON.parse(JSON.stringify(interval)));
      seasonPriceFlag = true;
    }

    for (const extra of reservation.extras) {
      if (!extra.type || extra.type !== 'reserved-extra') {

        const oldProduct = oldProducts.find(prod => {
          if (prod.type == 'extra' && extra.id ===  prod.extraId) {
              return prod;
          } else {
            return null;
          }
        });


        const foundExtra = this.dataService.resortData.extrasByIds[extra.id];
        let extraPrice = 0;

        if (foundExtra.priceType === 'timetable') {
          let length = (extra.end-extra.start)/(24*60*60) + 1;
          const seasons = this.dataService.getSeason(extra.start, length, extra.id);
          let timeSlotId = 0;
          if (extra.timeSlots.length) {
            timeSlotId = extra.timeSlots[0].id;
          }

          let extraSeasonal = false;

          if (extra.start === this.dataService.resortData.openingDate && extra.end === this.dataService.resortData.closingDate) {
            extraSeasonal = true;
          }

          const price = this.dataService.getPrice(this.dataService.prices, extra.start * 1000,extra.end * 1000, seasons, null, null, null, this.dataService.resortData.weeklyPrice, this.dataService.resortData.proportionalPrice, this.dataService.resortData.monthlyPrice, extraSeasonal, reservation.order.dailyPrice, extra.id, null, this.dataService.resortData.biweeklyPrice,timeSlotId, reservation.order.specialSeasonPrice);
          console.log("EXTRA PRICE ",price)
          extraPrice += price;

        } else {

          extraPrice += foundExtra.price;
        }


        const extraProduct = {
          col: null,
          row: null,
          startDate: reservation.start,
          endDate: reservation.end,
          length: reservation.length,
          name: foundExtra.name,
          percentage: null,
          packageId: null,
          discountId: null,
          extraId: extra.id,
          price: extraPrice,
          type: 'extra',
          optionalId: null,
          label: '',
          quantity: extra.quantity,
          timeSlots: extra.timeSlots,
          zone: null,
          zoneId: null,
          createdAt: Math.trunc(new Date().getTime() / 1000),
          createdBy: this.getCurrentUserData(),
          updatedAt: Math.trunc(new Date().getTime() / 1000),
          updatedBy: this.getCurrentUserData()
        };

        if (oldProduct) {
          extraProduct.createdAt = oldProduct.createdAt;
          extraProduct.createdBy = oldProduct.createdBy;
        }

        reservation.order.products.push(extraProduct);
        extra.price = extraPrice;
        reservation.order.subtotal += extraPrice * extra.quantity;
      }
    }

    for (const discount of reservation.discounts) {
      
      if (discount.type === 'discount') {
        reservation.order.discount += Math.round((reservation.order.subtotal / 100) * discount.percentage * 100) / 100;

        const oldProduct = oldProducts.find(prod => {
          if (prod.type == 'discount' && prod.discountId ===  discount.id) {
              return prod;
          } else {
            return null;
          }
        });

        let foundDiscount = this.dataService.resortData.discountsByIds[discount.id];

        if (!foundDiscount) {
          foundDiscount = {};
          foundDiscount.name = 'Sconto non più esistente';
        }

        const optProduct = {
          col: null,
          row: null,
          startDate: reservation.start,
          endDate: reservation.end,
          length: reservation.length,
          name: foundDiscount.name,
          packageId: null,
          percentage: discount.percentage,
          price: discount.percentage,
          type: 'discount',
          discountId: discount.id,
          optionalId: null,
          label: '',
          quantity: 1,
          zone: null,
          zoneId: null,
          createdAt: Math.trunc(new Date().getTime() / 1000),
          createdBy: this.getCurrentUserData(),
          updatedAt: Math.trunc(new Date().getTime() / 1000),
          updatedBy: this.getCurrentUserData(),
        };

        if (oldProduct) {
          optProduct.createdAt = oldProduct.createdAt;
          optProduct.createdBy = oldProduct.createdBy;
        }

        reservation.order.products.push(optProduct);
      } else if (discount.type === 'refund' && (!discount.id || discount.id.indexOf('bonus_') === -1)) {
        reservation.order.discount += discount.amount;
      }
    }

    const foundReservedDiscount = reservation.discounts.findIndex(disc => disc.type === 'reserved-discount');
    console.log(foundReservedDiscount);

    if (foundReservedDiscount > -1) {
      reservedDiscount = JSON.parse(JSON.stringify(reservation.discounts[foundReservedDiscount]));
      reservation.discounts.splice(foundReservedDiscount, 1);
    }

    const foundReservedExtra = reservation.extras.findIndex(extra => extra.type === 'reserved-extra');

    if (foundReservedExtra > -1) {
      reservation.extras.splice(foundReservedExtra, 1);
    }

    const foundReservedDiscountProduct = reservation.order.products.findIndex(disc => disc.type === 'reserved-discount');
    if (foundReservedDiscountProduct > -1) {
      reservation.order.products.splice(foundReservedDiscountProduct, 1);
    }

    const foundReservedExtraProduct = reservation.order.products.findIndex(extra => extra.type === 'reserved-extra');

    if (foundReservedExtraProduct > -1) {
      reservation.order.products.splice(foundReservedExtraProduct, 1);
    }


    reservation.order.total = reservation.order.subtotal - reservation.order.discount;

    if (fromReservedPrice == true) {
        
      if (typeof reservation.order.reservedPrice !== 'undefined' && reservation.order.reservedPrice !== null && reservation.order.reservedPrice !== reservation.order.total ) {
        let compare = reservation.order.reservedPrice;
        if (compare > reservation.order.total) {
          const newExtra = {
            id: null,
            start: reservation.start,
            end: reservation.end,
            price: compare - reservation.order.total,
            quantity: 1,
            type: 'reserved-extra',
            name: {
              'it':'Extra applicato',
              'en':'Applied extra',
              'de':'Applied extra',
              'es':'Applied extra',
              'fr':'Applied extra'
            },
            timeSlots:[],
            availableId: null
          };

          const optProduct = {
            col: null,
            row: null,
            startDate: reservation.start,
            endDate: reservation.end,
            length: reservation.length,
            name: {
              'it':'Extra applicato',
              'en':'Applied extra',
              'de':'Applied extra',
              'es':'Applied extra',
              'fr':'Applied extra'
            },
            percentage: null,
            packageId: null,
            discountId: null,
            extraId: null,
            price: compare - reservation.order.total,
            type: 'reserved-extra',
            optionalId: null,
            label: '',
            quantity: 1,
            zone: null,
            zoneId: null,
            createdAt: Math.trunc(new Date().getTime() / 1000),
            createdBy: this.getCurrentUserData(),
            updatedAt: Math.trunc(new Date().getTime() / 1000),
            updatedBy: this.getCurrentUserData()
          };

          reservation.extras.push(newExtra);
          reservation.order.products.push(optProduct);
          reservation.order.subtotal += (compare - reservation.order.total);
        } else {
          const newDiscount = {
            id: null,
            name: this.translateService.instant('appliedDiscount'),
            price: reservation.order.total - compare,
            quantity: 1,
            type: 'reserved-discount'
          };

          const discount = {
            col: null,
            row: null,
            startDate: reservation.start,
            endDate: reservation.end,
            length: reservation.length,
            name: this.translateService.instant('appliedDiscount'),
            packageId: null,
            percentage: null,
            price: reservation.order.total - compare,
            type: 'reserved-discount',
            discountId: null,
            optionalId: null,
            label: '',
            quantity: 1,
            zone: null,
            zoneId: null,
            createdAt: Math.trunc(new Date().getTime() / 1000),
            createdBy: this.getCurrentUserData(),
            updatedAt: Math.trunc(new Date().getTime() / 1000),
            updatedBy: this.getCurrentUserData(),
          };

          reservation.discounts.push(newDiscount);
          reservation.order.products.push(discount);
          reservation.order.discount += (reservation.order.total - compare);
        }
      } else if (reservation.order.reservedPrice === reservation.order.total) {
        reservation.order.reservedPrice = null;
      }

    } else if (typeof reservation.order.reservedPrice !== 'undefined' && reservation.order.reservedPrice !== null ) {
      console.log("ADD RESERVED PRICE");
      if (reservedDiscount !== null) {
        console.log(reservedDiscount);
        reservation.order.reservedPrice = null;
        const newDiscount = {
          id: null,
          name: this.translateService.instant('appliedDiscount'),
          price: reservedDiscount.price,
          quantity: 1,
          type: 'reserved-discount'
        };
  
        const discount = {
          col: null,
          row: null,
          startDate: reservation.start,
          endDate: reservation.end,
          length: reservation.length,
          name: this.translateService.instant('appliedDiscount'),
          packageId: null,
          percentage: null,
          price: reservedDiscount.price,
          type: 'reserved-discount',
          discountId: null,
          optionalId: null,
          label: '',
          quantity: 1,
          zone: null,
          zoneId: null,
          createdAt: Math.trunc(new Date().getTime() / 1000),
          createdBy: this.getCurrentUserData(),
          updatedAt: Math.trunc(new Date().getTime() / 1000),
          updatedBy: this.getCurrentUserData(),
        };
  
        reservation.discounts.push(newDiscount);
        reservation.order.products.push(discount);
        reservation.order.discount += (reservedDiscount.price);
      }
    }

    for (const discount of reservation.discounts) {
      
      if (discount.type === 'refund' && discount.id && discount.id.indexOf('bonus_') > -1) {
        console.log(discount);
        reservation.order.discount += discount.amount;
      }
    }
    reservation.order.total = reservation.order.subtotal - reservation.order.discount;

    if (reservedDiscount !== null) {
      reservation.order.reservedPrice = reservation.order.total;
    }
  }

  arraysEqual(a, b, field) {
    if (!a && !b) return true;
    if (!a && b) return false;
    if (a && !b) return false;
    if (a.length && !b.length) return false;
    if (!a.length && b.length) return false;
    if (a.length !== b.length) return false;

    a = a.sort(this.sortById);
    b = b.sort(this.sortById);

    for (var i = 0; i < a.length; ++i) {
      if (a[i][field] !== b[i][field]) return false;
    }
    return true;
  }

  updateCustomerBillModal(reservation) {
    let customerBillTotal = 0;

    for (const bill of reservation.order.customerBill) {
      if (!bill.payed) {
        customerBillTotal += bill.price;
      } else {
        customerBillTotal -= bill.price;
      }
    }

    reservation.order.customerBillTotal = customerBillTotal;

  }

  updateCustomerBill(reservation, settled, paymentType) {

      //const totalReservationBill = reservation.order.customerBill.findIndex(bill => bill.type === 'reservation-total');
      reservation.order.customerBill = reservation.order.customerBill.filter(o => o.type !== 'reservation-total');

      console.log(JSON.parse(JSON.stringify(reservation.order.customerBill)));

      if (settled && !reservation.order.settled) {
        const settlementBill = reservation.order.customerBill.findIndex(bill => bill.settledFlag);

        if (settlementBill > -1) {
          let settlement = reservation.order.customerBill.find(bill => bill.settledFlag);
          settlement.type = 'movement'
          settlement.name = 'Pagamento online'
          //reservation.order.customerBill.splice(settlementBill, 1);
        }

        let name = this.translateService.instant('cocoManagerReservation');

        if (reservation.cocoBooking) {
          name = this.translateService.instant('cocobukReservation');
        }

        reservation.order.customerBill.push({
          date: Math.round((new Date()).getTime() / 1000),
          createdAt: Math.trunc(new Date().getTime() / 1000),
          updatedAt: Math.trunc(new Date().getTime() / 1000),
          createdBy: this.getCurrentUserData(),
          updatedBy: this.getCurrentUserData(),
          name,
          fiscalPrinter: '',
          fiscalPrinted: false,
          settledFlag: false,
          type: 'reservation-total',
          payed: false,
          price: Math.round((reservation.order.total) * 100) / 100
        });

        let customerBillTotal = 0;

        for (const bill of reservation.order.customerBill) {
          if (!bill.payed) {
            customerBillTotal += bill.price;
          } else {
            bill.updatedBy = this.getCurrentUserData();
            bill.updatedAt = Math.trunc(new Date().getTime() / 1000);
            customerBillTotal -= bill.price;
          }
        }

        if (customerBillTotal > 0) {
          reservation.order.customerBill.push({
            createdAt: Math.trunc(new Date().getTime() / 1000),
            updatedAt: Math.trunc(new Date().getTime() / 1000),
            createdBy: this.getCurrentUserData(),
            updatedBy: this.getCurrentUserData(),
            date: Math.round((new Date()).getTime() / 1000),
            name: this.translateService.instant('settlement'),
            fiscalPrinter: '',
            fiscalPrinted: false,
            settledFlag: true,
            type: 'settlement',
            payed: true,
            price: customerBillTotal,
            paymentType
          });
        }

        reservation.order.customerBillTotal = 0;
      } else {

        let name = this.translateService.instant('cocoManagerReservation');

        if (reservation.cocoBooking) {
          name = this.translateService.instant('cocobukReservation');
        }

        reservation.order.customerBill.push({
          createdAt: Math.trunc(new Date().getTime() / 1000),
          updatedAt: Math.trunc(new Date().getTime() / 1000),
          createdBy: this.getCurrentUserData(),
          updatedBy: this.getCurrentUserData(),
          date: Math.round((new Date()).getTime() / 1000),
          name,
          fiscalPrinter: '',
          fiscalPrinted: false,
          settledFlag: false,
          type: 'reservation-total',
          payed: false,
          price: Math.round((reservation.order.total) * 100) / 100
        });

        if (!settled && reservation.order.settled) {

          const totalReservationBill = reservation.order.customerBill.findIndex(bill => bill.type === 'settlement');

          if (totalReservationBill > -1) {
            reservation.order.customerBill.splice(totalReservationBill, 1);
          }
        }

        let customerBillTotal = 0;

        for (const bill of reservation.order.customerBill) {
          if (!bill.payed) {
            customerBillTotal += bill.price;
          } else {
            customerBillTotal -= bill.price;
          }
        }

        reservation.order.customerBillTotal = customerBillTotal;
      }
      /* console.log(JSON.parse(JSON.stringify(reservation.order.customerBill))); */
      reservation.order.settled = settled;
      reservation.order.customerBill = reservation.order.customerBill.sort(this.sortByDateField);

  }

  getStringDateFromTimestamp(ts) {
    const fromDate = new Date(ts* 1000);

    let dayStart = fromDate.getDate().toString();
    let monthStart = (fromDate.getMonth() + 1).toString();

    if (fromDate.getDate() < 10) {
      dayStart = '0' + dayStart;
    }

    if ((fromDate.getMonth() + 1) < 10) {
      monthStart = '0' + monthStart;
    }


    return dayStart + '/' + monthStart;
  }

  setupReservationsMaps(slots, state, insert, timestamps, reservationMapData, startTimestamp, endTimestamp, selectedReservation) {
    const slotIds = [];

    slots.map(slotKey => {
        const rowKey = slotKey.split('_')[0];
        const colKey = slotKey.split('_')[1];

        slotIds.push(this.dataService.mapByRowCol[rowKey][colKey].id);

        if (insert) {
          if (!reservationMapData[rowKey]) {
            reservationMapData[rowKey] = {};
          }

          reservationMapData[rowKey][colKey] = {
              state,
              reservationsKeys: []
          };
        } else {
          delete reservationMapData[rowKey][colKey];
        }

        if (insert) {
          const length = (endTimestamp - startTimestamp) / (24 * 60 * 60 * 1000) + 1;

          for (let i = 0; i < length; i++) {
            const ts = startTimestamp / 1000 + i * 24 * 60 * 60;
            reservationMapData[rowKey][colKey].reservationsKeys.push({
              day_timestamp: ts,
              state
            });
          }
        }
    });


    // CHIAMA API CON OGGETTO

    const apiObj = {
      timestamps,
      state,
      resortId: this.dataService.resortData.id,
      slotIds,
      reservationId: null,
      notes: ''
    };

    if (selectedReservation) {
        apiObj.reservationId = selectedReservation.id;
    }

    return apiObj;

  }

  getCurrentUserData() {
    let obj = {
      name: this.dataService.userData.first_name + ' ' + this.dataService.userData.last_name,
      id: this.dataService.userData.id,
      role: this.dataService.userData.role,
      email: this.dataService.userData.email,
      uid: this.dataService.userData.uid,
      version: environment.version,
      platform: 'manager_web'
    };

    return obj;
  }

  // CONTROLLA SE ESISTONO INTERVALLI VUOTI (senza orders) O INTERVALLI CON STESSE DATE
  // INOLTRE AGGIORNA LE DISABLEDATES DEGLI INTERVALLI AVENTI STESSE POSTAZIONI

  async checkDatesIntervalsAndUpdateDisableDates(reservation) {
    reservation.start = null;

    let index1 = 0;
    let intervals = JSON.parse(JSON.stringify(reservation.dates))
    for (const interval1 of intervals) {

      // VERIFICA SE L'INTERVALLO E' VUOTO ALLORA ELIMINALO
      if (intervals.order && intervals.order.length === 0) {
        intervals.splice(index1,1);
      } else {


        interval1.date_key = interval1.start + '_' + interval1.end;
        if (interval1.id === null) {
          delete interval1.id;
        }

        let index = 0;

        for (const interval2 of intervals) {
          interval2.date_key = interval2.start + '_' + interval2.end;

          // AGGIORNA LE DATE DA DISABILITARE PER INTERVALLI DIVERSI CHE PERO' HANNO STESSE POSTAZIONI

          if (interval1.date_key !== interval2.date_key) {
            let flag = false;

            for (const slot of interval1.order) {
                if (interval2.order.find(slot2 => slot2.slot_key === slot.slot_key)) {
                  flag = true;
                  break;
                }
            }

            if (flag && interval2.disableDates) {
              for (let i = interval1.start; i <= interval1.end; i += 24 * 60 * 60) {
                const foundIndex = interval2.disableDates.findIndex(date => date === i);
                if (foundIndex === -1) {
                  interval2.disableDates.push(i);
                }
              }
              interval2.disableDates.sort();
            }

            reservation.dates[index] = interval2;
          } else if (interval1.date_key === interval2.date_key && interval1.id !== interval2.id) {

            // SE GLI INTERVALLI HANNO LE STESSE DATE MA ID DIVERSO ALLORA FAI MERGE

            for (const order of interval2.order) {
              interval1.order.push(order);
            }

            intervals.splice(index,1);
          }

          index++;
        }
        index1++;
      }
    }

    reservation.dates = intervals;
    reservation.dates = reservation.dates.sort(this.sortByDate);
    this.updateRootStartEndDate(reservation);
    console.log(reservation.dates);
  }

  updateRootStartEndDate(reservation) {
    for (const interval of reservation.dates) {

      if (!reservation.start) {
        reservation.start = interval.start;
        reservation.end = interval.end;
      }

      if (interval.start < reservation.start) {
        reservation.start = interval.start;
      }

      if (interval.end > reservation.end) {
        reservation.end = interval.end;
      }
    }
  }

  sortByStartDate ( a, b ) {
    if ( a.startDate < b.startDate ) {
      return -1;
    }
    if ( a.startDate > b.startDate ) {
      return 1;
    }
    return 0;
  }

  sortByDate ( a, b ) {
    if ( a.start < b.start ) {
      return -1;
    }
    if ( a.start > b.start ) {
      return 1;
    }
    return 0;
  }

  sortByDateField ( a, b ) {
    if ( a.date < b.date ) {
      return -1;
    }
    if ( a.date > b.date ) {
      return 1;
    }
    return 0;
  }

  sortById ( a, b ) {
    if ( a.id < b.id ) {
      return -1;
    }
    if ( a.id > b.id ) {
      return 1;
    }
    return 0;
  }

  getIntervalsFromSingleTs(dates) {
    dates = dates.sort();
    let output = [];
    let length = dates.length;
    let startTS = null;
    let endTS = null;
    let index = 0;
    for (let ts of dates) {
      if (!startTS) {
        startTS = ts;
        endTS = ts;
      }


      if (index === (length-1)) {
        endTS = ts;
        output.push({
          start: startTS,
          end: endTS
        })
      } else {
        if (dates[index+1] > (dates[index]+24*60*60)) {
          endTS = ts;
          output.push({
            start: startTS,
            end: endTS
          })
          startTS = null;
          endTS = null;
        } else {
          endTS = ts;
        }
      }

      index++;
    }


    return output;
  }

  getDaysFromInterval(start, end) {
    let days = [];
    for (let i=start; i<=end; i+=24*60*60) {
      let day = new Date(i*1000).getDay();
      if (days.indexOf(day) === -1) {
        days.push(day)
      }
    }

    return days;
  }

  checkIfTimeslotsAreEnabled(season, days) {
    let output = true;
    if (season.timeSlotsConfig && season.timeSlotsConfig.enabled) {
      for (let day of days) {
        if (!season.timeSlotsConfig.weeklyActivation[this.daysIndexToLabel[day]]) {
          output = false;
          break;
        }
      }
    } else {
      output = false;
    }

    return output;
  }

  getAvailableTimes(timeAlreadyPushed) {
    let output = JSON.parse(JSON.stringify(this.timeslots));
    for (let time of timeAlreadyPushed) {
      let start = output.findIndex(a => a === time.startHour);
      let end =output.findIndex(a => a === time.endHour);
      let length = end-start;
      let workingArray = [];
      for (let i=0; i< output.length; i++) {
        if (i <= start || i >= end) {
          workingArray.push(output[i])
        }
      }

      output = workingArray;
    }

    return output;
  }


  // Quando faccio check su stagionale mi crea intervalli considerando le prenotazioni già presenti su DB nelle stesse postazioni della prenotazione.
  // Pertanto crea dei "buchi" in base alle prenotazioni già esistenti

  checkOtherReservationsAndSplitIntervals(reservation, startTS, endTS, interval) {

      // CONTROLLA SE E' DA SPLITTARE
      console.log("checkOtherReservationsAndSplitIntervals", JSON.parse(JSON.stringify(interval)))
      let startTimeSlot = interval.timeSlots;
      let startSeason = this.dataService.getSeasonsInReservation(interval.start,interval.length,null);
      const splitDates = [];
      
      let lastTS = null;
      let outputDates = [];
      // CONTROLLA PER OGNI DATA DISABILITATA
      for (let ts = startTS; ts<= endTS; ts+=24*60*60) {
        if (interval.disableDates.indexOf(ts) === -1) {
          outputDates.push(ts);
        }
      }

      let splitStart = outputDates[0];
      for (let ts of outputDates) {
        if (lastTS && (ts-lastTS) > 24*60*60) {
          splitDates.push({
            start: splitStart,
            end: lastTS,
            startDate: new Date(splitStart * 1000),
            endDate: new Date(lastTS* 1000),
          });

          splitStart = ts;
        } else if (lastTS && (ts-lastTS) == 24*60*60){

        } else {
          splitStart = ts;
        }

        lastTS = ts;
      }

      splitDates.push({
        start: splitStart,
        end: lastTS,
        startDate: new Date(splitStart * 1000),
        endDate: new Date(lastTS* 1000),
      });

      // CHECK DATES 
      /* 
      let splitStart = startTS;
      
      for (const ts of interval.disableDates) {
        if (ts > startTS && ts <= endTS) {

          if (lastTS) {
            splitStart = lastTS;
          }

          if (!lastTS || (lastTS && interval.disableDates.indexOf(lastTS) === -1 )) {
            if ((ts - 24 * 60 * 60) >= splitStart) {
              splitDates.push({
                start: splitStart,
                end: ts - 24 * 60 * 60,
                startDate: new Date(splitStart * 1000),
                endDate: new Date((ts - 24 * 60 * 60) * 1000),
              });
              console.log(JSON.parse(JSON.stringify(splitDates)));
            } else {
              console.log(new Date(ts*1000));
              splitDates.push({
                start: ts - 24 * 60 * 60,
                end: splitStart,
                startDate: new Date((ts - 24 * 60 * 60) * 1000),
                endDate: new Date(splitStart * 1000)
              });
              console.log(JSON.parse(JSON.stringify(splitDates)));
            }
          }

          lastTS = ts + 24 * 60 * 60;
          console.log(lastTS);
        }
      } */

     /*  if (!lastTS) {
        lastTS = startTS;
      }

      if (endTS >= lastTS && interval.disableDates.indexOf(endTS) === -1) {
        console.log(new Date(lastTS*1000));
        splitDates.push({
          start: lastTS,
          end: endTS,
          startDate: new Date(lastTS * 1000),
          endDate: new Date(endTS * 1000)
        });
        console.log(JSON.parse(JSON.stringify(splitDates)));
      } else if (interval.disableDates.indexOf(endTS) === -1) {
        console.log(new Date(endTS*1000));
        splitDates.push({
          start: endTS,
          end: lastTS,
          startDate: new Date(endTS * 1000),
          endDate: new Date(lastTS * 1000)
        });
        console.log(JSON.parse(JSON.stringify(splitDates)));

      } */

      console.log(splitDates);
      if (splitDates.length > 1) {
        //console.log('CREA NUOVO INTERVALLO');
        for (const dateObj of splitDates) {
          //console.log(new Date(dateObj.end*1000));
          const newInterval = JSON.parse(JSON.stringify(interval));
          newInterval.id = null;
          newInterval.start = dateObj.start;
          newInterval.end = dateObj.end;
          newInterval.dateKey = dateObj.start + '_' + dateObj.end;
          newInterval.length = (newInterval.end - newInterval.start) / (24 * 60 * 60) + 1;
          newInterval.startDate = dateObj.startDate;
          newInterval.endDate = dateObj.endDate;
          newInterval.timeSlots = [];

          reservation.dates.push(newInterval);
        }

        const removeIndex = reservation.dates.findIndex(dateInterval => dateInterval.start === interval.start && dateInterval.end === interval.end);
        reservation.dates.splice(removeIndex, 1);
      } else {

        const updateInterval = reservation.dates.find(dateInterval => dateInterval.start === interval.start && dateInterval.end === interval.end);
        updateInterval.start = startTS;
        updateInterval.end = endTS;
        updateInterval.startDate = new Date(startTS * 1000);
        updateInterval.endDate = new Date(endTS * 1000);
        updateInterval.dateKey = interval.start + '_' + interval.end;
        updateInterval.length = ((endTS - startTS) / (24 * 60 * 60)) + 1;
      }

      // CONTROLLA SE LA FASCIA ORARIA DI PARTENZA ERA DIVERSA DA GIORNALIERO E DIVIDI ULTERIORMENTE OVE LA STAGIONE NON COPRE QUELLA FASCIA ORARIA

      if (startTimeSlot && startTimeSlot.length) {

        for (let singleInterval of reservation.dates) {
          let seasons = this.dataService.getSeasonsInReservation(singleInterval.start, singleInterval.length,null);
          let end = JSON.parse(JSON.stringify(singleInterval.end));
          if (Object.keys(seasons).length > 1) {
            if (!seasons[Object.keys(startSeason)[0]]) {
              singleInterval.timeSlots = [];
            } else {
              // SPLITTA INTERVALLO TRA STAGIONE CON FASCIA ORARIA E TUTTO IL RESTO E ASSEGNA AGLI ALTRI INTERVALLI TIMESLOT GIORNALIERO
              console.log("SPLITTA")
              singleInterval.end = seasons[Object.keys(startSeason)[0]].end;
              singleInterval.endDate = new Date(singleInterval.end * 1000);
              singleInterval.dateKey = singleInterval.start + '_' + singleInterval.end;
              singleInterval.length = (singleInterval.end - singleInterval.start) / (24 * 60 * 60) + 1;
              console.log(singleInterval)
              for (let seasonKey in seasons) {

                if (seasonKey !== Object.keys(startSeason)[0]) {
                  let currentSeason = seasons[seasonKey];
                  let newIntervalEnd = end;
                  if (currentSeason.end < end) {
                    newIntervalEnd = currentSeason.end;
                  }
                  const newInterval = JSON.parse(JSON.stringify(singleInterval));
                  newInterval.id = null;
                  newInterval.start = currentSeason.start;
                  newInterval.end = newIntervalEnd;
                  newInterval.dateKey = currentSeason.start + '_' + newIntervalEnd;
                  newInterval.length = (newInterval.end - newInterval.start) / (24 * 60 * 60) + 1;
                  newInterval.startDate = new Date(newInterval.start * 1000);
                  newInterval.endDate = new Date(newInterval.end * 1000);
                  newInterval.timeSlots = [];
                  reservation.dates.push(newInterval);
                }
              }

            }
          } else if (!seasons[Object.keys(startSeason)[0]]) {
            singleInterval.timeSlots = [];
          }
        }
      }

      this.checkDatesIntervalsAndUpdateDisableDates(reservation);
      return reservation;
  }

  checkTimeSlotsConsistency(reservation) {

  }

  async setDateIntervalAvailabilityAndSlotStates(reservation, mapData) {

    //this.enableSeasonPrice = true;
    let nowTS = (new Date().getTime()) / 1000;
    let today = (new Date()).setUTCHours(0, 0, 0, 0) / 1000;
    const slotsIds = [];

    // OTTIENI DISPONIBILITA' DEI SINGOLI SLOTS PER LE MODALI 'ASSENZA' E 'LIBERA'

    for (const interval of reservation.dates) {
      for (const slot of interval.order) {

        if (mapData[slot.row] && mapData[slot.row][slot.col] && slot.type === 'slot' && slotsIds.indexOf(mapData[slot.row][slot.col].id) === -1) {
          slotsIds.push(mapData[slot.row][slot.col].id);
        }
      }
    }


    let slotsNotAvailable = [];
    let timeSlotsNotAvailable = [];

    if (slotsIds.length) {
      let ava = await this.dataService.getMapAvailabilitiesBySlotIds(slotsIds.join());
      slotsNotAvailable = ava.slots_availabilities;
      timeSlotsNotAvailable= ava.timeSlots_availabilities;
    }

    for (let interval of reservation.dates) {
      interval.startDate = new Date(interval.start * 1000);
      interval.endDate = new Date(interval.end * 1000);

      let disableDates = [];
      let disableTimeSlots = [];

      // DEFINISCI LO STATO ODIERNO DELLO SLOT (checkinato, checkouto, liberato)

      for (const slot of interval.order) {
        if (slot.type === 'slot') {

          if (mapData[slot.row][slot.col]) {
            slot.coords = mapData[slot.row][slot.col].label;
          }

          if (!slot.optionalsKeys) {
            slot.optionalsKeys = {};
            slot.optionalsPrices = {};
          }

          slot.todayState = 2;

          if (slot.checkout && slot.checkout.find(ts => ts.start <= today && today <= ts.end)) {
            slot.todayState = 1;
          } else if (slot.free &&  slot.free.find(ts => ts.start <= today && today <= ts.end)) {
            slot.todayState = -1;
            const infos = slot.free.find(ts => ts.start <= today && today <= ts.end)
            slot.infos = infos.infos;
          } else if (slot.checkin &&  slot.checkin.find(ts => ts.start <= today && today <= ts.end)) {
            slot.todayState = 0;
          }

          if (slotsNotAvailable[slot.row + '_' + slot.col]) {
            disableDates = disableDates.concat(slotsNotAvailable[slot.row + '_' + slot.col]);
          }

          for (let ts = interval.start; ts <= interval.end; ts+= 24*60*60) {
            if (timeSlotsNotAvailable[slot.row+'_'+slot.col+'_'+ts]) {
              disableTimeSlots = disableTimeSlots.concat(timeSlotsNotAvailable[slot.row+'_'+slot.col+'_'+ts])
            }
          }


        }
      }

      let datesAvailable = [];

      for (let i = interval.start; i <= interval.end; i += 24 * 60 * 60) {

        const foundIndex = disableDates.findIndex(date => date === i);

        if (foundIndex > -1) {
          datesAvailable.push(i);
        }
      }

      // ELIMINA LE DATE DELL'INTERVALLO DALLE DATE NON DISPONIBILI

      disableDates = disableDates.filter(d => datesAvailable.indexOf(d) === -1);

      disableDates.sort();
      if ((interval.start <= nowTS && interval.end+24*60*60 >= nowTS) || (reservation.dates.length === 1)){
        interval.clicked = true;
      }

      // ELIMINA I TIMESLOTS DELL'INTERVALLO DAI TIMESLOTS NON DISPONIBILI

      disableTimeSlots = disableTimeSlots.filter(t => {
        if (interval.timeSlots && interval.timeSlots.find(ts => ts.id === t.id)) {
          return false;
        } else {
          return true;
        }
      })

      interval.disableDates = JSON.parse(JSON.stringify(disableDates));
      interval.disableTimeSlots = JSON.parse(JSON.stringify(disableTimeSlots));
    }

  }

  setSlotElement(slot, resortData) {
    if (slot.packagesIds && slot.packagesIds.length) {
      let selectedPack = resortData.packagesByIds[slot.packagesIds[0]];

      if (slot.defaultPackId) {
        selectedPack = resortData.packagesByIds[slot.defaultPackId];
      }

      let slotElement = this.dataService.elements.find(e => e.icon === selectedPack.icon);

      if (slotElement) {
        slot.elementId = slotElement.id;
      } else {
        for (const element of selectedPack.elements) {
          if (element.element.cover) {
            slot.elementId = element.element.id;
            break;
          } else if (!slot.elementId) {
            slot.elementId = element.element.id;
          }
        }
      }


    }

    /* if (!slot.elementId) {
      slot.elementId = 1;
    } */
  }

}


@Injectable()
export class CocoDateAdapter extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }

}
