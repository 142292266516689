import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service';

@Component({
  selector: 'app-settings-resort',
  templateUrl: './settings-resort.component.html',
  styleUrls: ['./settings-resort.component.scss']
})
export class SettingsResortComponent implements OnInit {

  resortData;managerData;pageClicked;prices;

  constructor(private dataService: DataService, private settingsService: SettingsService) {

  }

  async ngOnInit() {
    this.resortData = await this.dataService.getResortData();
    this.managerData = await this.dataService.getUserData();
    this.prices = await this.dataService.getPrices();
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout(()=>{
      this.pageClicked = false;
    },1000)
  }

}
