
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { ActivatedRoute,Router, RoutesRecognized } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import * as XLSX from 'xlsx';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalTextComponent } from '../../../shared/components/modal-text/modal-text.component';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { ModalConfirmDeleteComponent } from 'src/app/shared/components/modal-confirm-delete/modal-confirm-delete.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  // GLOBAL VARIABLES
  pageClicked = false; resortData; managerData; customers = null; overlayLoader;
  nextPage; prevPage; totalCustomers; loader = false; percentage = 0;
  pageSize = 10; page = 1; objectKeys = Object.keys; lang = 'it';
  size = null;
  selectedCustomers=[];
  // FILTERS VARIABLES
  term$ = new Subject<string>(); searchString = null; selectedType = null; selectedPayment = null;

  customersImported = null;

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private router: Router, private dataService: DataService, private settingsService: SettingsService) {

    this.managerData = this.dataService.userData;
    this.resortData = this.dataService.resortData;
    this.term$
    .debounceTime(1000)
    .distinctUntilChanged()
    .subscribe(async term => {
      this.searchString = term;

      if (term === '') {
        this.searchString = null;
      }

      await this.getCustomers();

    });

  }

  async ngOnInit() {
    this.loader = true;

    if (!this.resortData) {
      await this.dataService.initResortData();
      this.managerData = this.dataService.userData;
      this.resortData = this.dataService.resortData;
    }

    if (this.dataService.lastCustomersQuery) {
      /* if (name)  {
        params += 'names=' + name + '&';
      }

      if (booking !== null) {
        params += 'booking=' + booking + '&';
      }

      if (page) {
        params += 'page=' + page + '&';
      }

      if (!totals) {
        params += 'hideTotals=true&';
      } */

      let searchQuery = this.dataService.lastCustomersQuery.split("?")[1].split("&");

      let nameParam = searchQuery.find(s => s.indexOf('names=') > -1);
      let bookingParam = searchQuery.find(s => s.indexOf('booking=') > -1);

      if (nameParam) {
        this.searchString = nameParam.replace('names=','');
      }

      if (bookingParam) {
        this.selectedType = bookingParam.replace('booking=','');
      }


      await this.getPrevCustomers();
    } else {
      await this.getCustomers();
    }


  }

  async importCustomers() {
    // OTTIENI JSON DA CSV

   /*  for (let customer of this.customersImported) {
      customer.resortId = this.resortData.id;
      let ok = await this.dataService.setCustomer(customer);
    } */
  }

  async getPrevCustomers() {
    this.loader = true;
    let data =  await this.dataService.getDataByUrl(this.dataService.lastCustomersQuery)

    this.nextPage = data.next;
    this.prevPage = data.previous;

    for (let customer of data.results) {
      if (!customer.totalPayed) {
        customer.totalPayed = 0;
      }

      if (!customer.totalPayedFalse) {
        customer.totalPayedFalse = 0;
      }

      customer.totalNotPayed = - (customer.totalPayed - customer.totalPayedFalse);

      if (customer.uid && customer.email && customer.bookingId !== 'none')  {

        customer.email = customer.email[0] + customer.email.split('@')[0].replace(/.*/,'*') + '@' + customer.email.split('@')[1]
      }

      if (customer.uid && customer.phone && customer.bookingId !== 'none')  {

        customer.phone = customer.phone[0]+customer.phone[1]+customer.phone[2]+customer.phone.replace(/.*/g, '*');;
      }
    }
    this.customers = data.results;
    this.totalCustomers = data.count;
    this.loader = false;
  }

  async getCustomers() {
    this.loader = true;
    let data =  await this.dataService.getCustomers( this.searchString, this.selectedType, this.pageSize, this.page, true);

    this.nextPage = data.next;
    this.prevPage = data.previous;

    for (let customer of data.results) {
      if (!customer.totalPayed) {
        customer.totalPayed = 0;
      }

      if (!customer.totalPayedFalse) {
        customer.totalPayedFalse = 0;
      }

      customer.totalNotPayed = - (customer.totalPayed - customer.totalPayedFalse);

      if (customer.uid && customer.email && this.resortData.id !== 125)  {

        customer.email = customer.email[0] + customer.email.split('@')[0].replace(/.*/,'*') + '@' + customer.email.split('@')[1]
      }

      if (customer.uid && customer.phone && this.resortData.id !== 125)  {

        customer.phone = customer.phone[0]+customer.phone[1]+customer.phone[2]+customer.phone.replace(/.*/g, '*');;
      }

      customer.name = customer.name.toLowerCase();
      customer.surname = customer.surname.toLowerCase();

      customer.selected = false;
      if (this.selectedCustomers.indexOf(customer.id) > -1) {
        customer.selected = true;
      }
    }

    this.customers = data.results;
    this.totalCustomers = data.count;
    this.loader = false;
  }

  async changePage(url) {
    this.loader = true;
    const data = await this.dataService.getDataByUrl(url);
    this.nextPage = data.next;
    this.prevPage = data.previous;

    for (const customer of data.results) {
      if (!customer.totalPayed) {
        customer.totalPayed = 0;
      }

      if (!customer.totalPayedFalse) {
        customer.totalPayedFalse = 0;
      }

      customer.totalNotPayed = - (customer.totalPayed - customer.totalPayedFalse);

      if (customer.uid && customer.email && this.resortData.id !== 125)  {

        customer.email = customer.email[0] + customer.email.split('@')[0].replace(/.*/,'*') + '@' + customer.email.split('@')[1]
      }

      if (customer.uid && customer.phone && this.resortData.id !== 125)  {

        customer.phone = customer.phone[0]+customer.phone[1]+customer.phone[2]+customer.phone.replace(/.*/g, '*');;
      }
      customer.name = customer.name.toLowerCase();
      customer.surname = customer.surname.toLowerCase();

      customer.selected = false;
      if (this.selectedCustomers.indexOf(customer.id) > -1) {
        customer.selected = true;
      }
    }

    this.customers = data.results;
    this.totalCustomers = data.count;
    this.loader = false;
  }

  async exportXLS() {
    let outputObjs = [];
    this.overlayLoader = true;
    this.size = this.totalCustomers;

    this.percentage = 0;

    let customersToSave = [];

    console.log(this.totalCustomers);
    console.log(this.size);

    this.size = this.totalCustomers;
    let nextPage = null;
    for (let i = 0; i < this.totalCustomers; i += 500) {
     
      let data = null;
      if (i==0) {
        data = await this.dataService.getCustomers( this.searchString, this.selectedType, 500, 0, false);
        nextPage = data.next;
      } else if (nextPage) {
        data = await this.dataService.getReservationsByUrl(nextPage);
        nextPage = data.next;
      } else {
        break;
      }
      if (data && data.results) {
        for (const res of data.results) {
          customersToSave.push(res);
        }
      }
    }

    console.log(customersToSave.length);

    customersToSave.map(customer => {
      
      customer.name = customer.name.toLowerCase();
      customer.surname = customer.surname.toLowerCase();

      if (!customer.totalPayed) {
        customer.totalPayed = 0;
      }

      if (!customer.totalPayedFalse) {
        customer.totalPayedFalse = 0;
      }

      customer.totalNotPayed = - (customer.totalPayed - customer.totalPayedFalse);

      if (customer.uid && customer.email && this.resortData.id !== 125 && this.resortData.id !==383 && this.resortData.id !== 522)  {

        customer.email = customer.email[0] + customer.email.split('@')[0].replace(/.*/,'*') + '@' + customer.email.split('@')[1]
      }

      if (customer.uid && customer.phone && this.resortData.id !== 125 && this.resortData.id !==383 && this.resortData.id !== 522)  {

        customer.phone = customer.phone[0]+customer.phone[1]+customer.phone[2]+customer.phone.replace(/.*/g, '*');;
      }

      if (customer.phone && !customer.phonePrefix && customer.phone.indexOf('+39') === -1 && customer.phone.indexOf('+ 39') === -1 ) {
        customer.phonePrefix = '+39';
      }

      let type = 'manager';

      if (customer.uid) {
        type = 'booking';
      }

      const obj = {
        id: customer.id,
        nome: customer.name + ' ' + customer.surname,
        email: customer.email,
        telefono: customer.phonePrefix + ' ' + customer.phone,
        tipologia: type,
        /* totale_da_pagare: Math.trunc(customer.totalNotPayed*100)/100,
        totale_prenotazioni: Math.trunc(customer.totalPayedFalse*100)/100 */
      };

      outputObjs.push(obj);

    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(outputObjs);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');


    XLSX.writeFile(wb, 'Anagrafica' + '.xlsx');
    this.page = 1;
    this.size = this.pageSize;
    this.overlayLoader = false;
  }

  removeCustomers() {
    console.log(this.selectedCustomers);
    
    let dialogRef = this.dialog.open(ModalConfirmDeleteComponent, {
      width: '340px',
      height: '160px',
      data: {
        title:'Sei sicuro di voler eliminare '+this.selectedCustomers.length+ ' clienti?'
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
          console.log(result);
          this.loader = true;
          if (result) {
            for (let customerId of this.selectedCustomers) {
              await this.dataService.deleteCustomer(customerId);
            }
          }
          this.selectedCustomers = [];
          await this.getCustomers();
          this.loader = false;
    });
  }

  toggleSelectedCustomer(index:number) {
    let selectedCustomer = this.customers[index];

    if (this.selectedCustomers.indexOf(selectedCustomer.id) > -1) {
      this.selectedCustomers.splice(this.selectedCustomers.indexOf(selectedCustomer.id),1);
    } else {
      this.selectedCustomers.push(selectedCustomer.id);
    }
  }

  resetFilters() {
    this.selectedType = null;
    this.selectedPayment = null;
    this.searchString = null;
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout( () => {
      this.pageClicked = false;
    }, 1000);
  }

  showPrivacyModal() {

    let dialogRef = this.dialog.open(ModalTextComponent, {
      width: '800px',
      data: {
        title: "Perchè queste informazioni sono bloccate o mascherate.",
        text: "Questo campo risulta bloccato e non esportabile a causa delle normative stabilite dalla GDPR Regulation, regolamento definito dalla comunità europea al fine di garantire il rispetto della privacy degli utenti.<br><br>L’utente infatti, iscrivendosi e utilizzando Cocobuk.com accetta un contratto di privacy e trattamento dati con Coco s.r.l. Quest’ultimo stabilisce che, se non espressamente esplicitato in fase di iscrizione alla piattaforma, i dati dell’utente possono essere utilizzati unicamente per fini inerenti alla gestione della prenotazione (comunicazione di un cambiamento, spostamento ecc.).<br><br>L’utente quindi afferma in maniera esplicita di non acconsentire all’utilizzo delle informazioni personali a soggetti terzi diversi da Cocobuk per fini di promozione e marketing.<br><br>Per questi motivi quindi non ti è possibile esportare in maniera massiva i dati degli utenti nè di copiarli. Entrambe queste operazioni sarebbero associate a fini promozionali e quindi vietate dalla GDPR in quanto diverse dalla volontà dell’uente.<br><br>Puoi trovare maggiori informazioni su <a href='https://www.cocobusiness.it/privacy-policy/' target='blank'>https://www.cocobusiness.it/privacy-policy/</a>"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
          console.log(result);
    });
  }

  goTo(url) {
    this.dataService.previousPage = '/common/customers';
    this.router.navigate([url]);
  }



}
