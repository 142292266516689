import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service'
import { SettingsService } from '../../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { ModalErrorComponent } from '../../../../shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-extra-detail',
  templateUrl: './extra-detail.component.html',
  styleUrls: ['./extra-detail.component.scss']
})
export class ExtraDetailComponent implements OnInit {

  resortData; managerData; pageClicked; prices; extraId; lang = 'it';loading = false;
  selectedExtra; optionals; dataLang='it'; rule1=0; elements; features;services;packages;
  objectKeys = Object.keys;uploadPercent; clicked= false;showLanguageModal1=false;showLanguageModal2=false;
  seasonExtra:any=[];timeslotsRepo=[];

  constructor(public snackBar: MatSnackBar, private storage: AngularFireStorage, public router: Router, public dialog: MatDialog,private route: ActivatedRoute, public dataService: DataService, private settingsService: SettingsService) {
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.dataLang = this.dataService.lang;
  }

  async ngOnInit() {

    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;

    this.timeslotsRepo = this.settingsService.timeslots;
    console.log(this.timeslotsRepo);
    this.packages = JSON.parse(JSON.stringify(this.resortData.packages));

    for (let pack of this.packages) {
      pack.selected = false;
    }
    this.initExtra(this.route.snapshot.paramMap.get('extraId'));

    console.log(this.prices);

  }

  initExtra(extraId:any) {
    this.extraId = extraId;

    if (this.resortData.extrasByIds[this.extraId]) {
      this.selectedExtra = JSON.parse(JSON.stringify(this.resortData.extrasByIds[this.extraId]));
      this.getExtraSeasons();
    } else {
      this.extraId = null;
      this.selectedExtra = {
        name: JSON.parse(JSON.stringify(this.dataService.languages)),
        description: JSON.parse(JSON.stringify(this.dataService.languages)),
        active:true,
        alwaysAvailable:false,
        availabilities:[],
        cocoBooking:false,
        cocoSuite:false,
        image:'',
        priceType: 'timetable',
        price: 0,
        quantity:0,
        id: null,
        packagesIds: [],
        sequence: this.resortData.extras.length+1
      }
    }

    if (!this.selectedExtra.packagesIds) {
      this.selectedExtra.packagesIds = [];
    }

    for (const pack of this.selectedExtra.packagesIds) {
      let findPack = this.packages.find(p => p.id === pack);
      if (findPack) {
        findPack.selected = true;
      }
    }

    this.initPrices();

  }

  async removeTimeslot(season:any, index:number, timeslot:any) {
    season.seasonTimeslots.splice(index,1);

    // REMOVE API
    if (timeslot.id) {
      await this.dataService.deleteTimeSlot(this.resortData.id, season.id, timeslot);
    }


    season.availableTimeslots = this.settingsService.getAvailableTimes(season.seasonTimeslots)

    this.snackBar.open('Fascia oraria eliminata correttamente', 'OK', {
      duration: 4000,
    });
  }

  initPrices() {
    this.seasonExtra.map(season => {

      // DAILY PRICE

      if (!this.selectedExtra.id || !this.prices['extra_'+this.selectedExtra.id  + '_' + season.id+'_0']) {
        this.setPriceZero(this.selectedExtra.id,season.id,0);
      }

      // TIMESLOTS PRICE if exists
      if (this.resortData.timeSlotsBySeason[season.id].length) {
        for (let timeslot of this.resortData.timeSlotsBySeason[season.id]) {
          if (!this.selectedExtra.id || !this.prices['extra_'+this.selectedExtra.id + '_' + season.id + '_' + timeslot.id]) {
            this.setPriceZero(this.selectedExtra.id,season.id,timeslot.id);
          }
        }
      }

    });

    if (!this.selectedExtra.id || !this.prices['extra_'+this.selectedExtra.id  + '_0_0']) {
      this.setPriceZero(this.selectedExtra.id,0,0);
    }

    if (!this.selectedExtra.id || !this.prices['extra_'+this.selectedExtra.id  + '_0_1']) {
      this.setPriceZero(this.selectedExtra.id,0,1);
    }

    if (!this.selectedExtra.id || !this.prices['extra_'+this.selectedExtra.id  + '_0_2']) {
      this.setPriceZero(this.selectedExtra.id,0,2);
    }
  }

  async getExtraSeasons() {

    this.seasonExtra = this.dataService.resortData.seasonsExtras.filter(s => {return (s.extra == this.selectedExtra.id?true:false)});
    for (let season of this.seasonExtra) {
      season.startSerializedDate =  new Date(season.start * 1000);
      season.endSerializedDate = new Date(season.end * 1000);
      season.disableDates = []
      season.seasonTimeslots = await this.dataService.getTimeSlots(this.resortData.id, season.id);
      season.availableTimeslots = this.settingsService.getAvailableTimes(season.seasonTimeslots)
    }


    this.checkDisableDates();
  }

  async pushTimeslot(season) {
    let timeslot = {
      "endHour": "00:00",
      "name": {
          "de": "",
          "en": "",
          "fr": "",
          "it": ""
      },
      "startHour": "00:00"
    }

    let newTimeslot = await this.dataService.pushTimeSlot(this.resortData.id,season.id,timeslot);
    season.seasonTimeslots.push(newTimeslot);
    this.setPriceZero(this.selectedExtra.id,season.id,newTimeslot.id);

    season.availableTimeslots = this.settingsService.getAvailableTimes(season.seasonTimeslots)
    this.clicked = false;
  }


  checkSeasonalPrice(price) {
    if (!price || price < 0) {
      price = 0;
    }
  }

  checkDisableDates() {

    for (let season of this.resortData.seasonsExtras) {
      this.resortData.seasonsExtras.map(s => {
        if (season.id !== s.id ) {
          if (!season.disableDates) {
            season.disableDates = [];
          }
          for (let i = season.start; i <= season.end; i += 24 * 60 * 60) {
            season.disableDates.push(i);
          }
        }
      });
    }
  }

  checkSeasonDates(season) {

    if (season.startSerializedDate > season.endSerializedDate) {
      season.endSerializedDate = season.startSerializedDate;
    }

    const offsetStart = new Date(season.startSerializedDate).getTimezoneOffset() * 60000;
    const offsetEnd = new Date(season.endSerializedDate).getTimezoneOffset() * 60000;
    season.start = ((new Date(season.startSerializedDate)).setHours(0, 0, 0) - offsetStart ) / 1000;
    season.end = ((new Date(season.endSerializedDate)).setHours(0, 0, 0) - offsetEnd ) / 1000;

    this.checkDisableDates();
  }

  setPriceZero(extraId, seasonId, timeslotId) {
    this.prices['extra_' + extraId + '_'+seasonId+'_'+timeslotId] = {
      daily: 0,
      lastMinute: 0,
      weekend: 0,
      weekly: 0,
      biweekly: 0,
      triweekly: 0,
      monthly: 0,
      specialSeason: 0,
      seasonal: 0,
      type:'extra',
      extra_id:extraId
    }
  }

  async addSeason() {

    let endDate =  new Date(new Date().getFullYear(), 0, 1).getTime();
    let startDate = new Date(new Date().getFullYear(), 0, 1).getTime() ;
    const offsetStart = new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset() * 60000;
    const offsetEnd = new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset() * 60000;
    startDate = (startDate - offsetStart ) / 1000;
    endDate = (endDate - offsetEnd ) / 1000;

    let newSeason =  {
      name: JSON.parse(JSON.stringify(this.dataService.languages)),
      id: null,
      start: startDate,
      end: endDate,
      special: false,
      packageType: 'extra',
      extra: this.selectedExtra.id,
    };

    if (!this.selectedExtra.id) {
      await this.saveExtra(false);
      newSeason.extra = this.selectedExtra.id;
    }

    await this.dataService.createSeason(newSeason);
    this.initExtra(this.selectedExtra.id);
  }

  async removeSeason(season:any) {
    const dialogRef = this.dialog.open(ModalRemoveComponent, {
      width: '340px',
      height: '130px',
      data: {
        type: 'seasons',
        season,
        noRefresh: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.initExtra(this.selectedExtra.id);
    });
  }

  resetHeader() {

  }

  async saveExtra(redirect:boolean) {

    this.selectedExtra.packagesIds = [];
    for (const pack of this.packages) {
        if (pack.selected) {
          this.selectedExtra.packagesIds.push(pack.id);
        }
    }

    if (this.selectedExtra.id) {
      this.resortData.extrasByIds[this.extraId] = this.selectedExtra;
      const foundIndex = this.resortData.extras.findIndex(disc => disc.id === this.selectedExtra.id);
      this.resortData.extras[foundIndex] = this.selectedExtra;
      await this.dataService.updateExtra(this.selectedExtra);
    } else {
      const newExtra = await this.dataService.createExtra(this.selectedExtra);
      this.selectedExtra.id = newExtra.id;
      this.resortData.extras.push(this.selectedExtra);
      this.resortData.extrasByIds[this.selectedExtra.id] = this.selectedExtra;
    }

    for (let season of this.seasonExtra) {

      let foundOverlappedTimeslots = false;
      let pushedIntervals = [];
      for (let timeslot of season.seasonTimeslots) {
        let startTS = parseInt(timeslot.startHour.split(":")[0])*60 + parseInt(timeslot.startHour.split(":")[1]);
        let endTS = parseInt(timeslot.endHour.split(":")[0])*60 + parseInt(timeslot.endHour.split(":")[1]);
        let findInterval = pushedIntervals.find(i => (i.start < startTS && i.end > startTS) || (i.start > startTS && i.start < endTS) || (i.start >= startTS && i.end <= endTS));

        if (findInterval) {
          foundOverlappedTimeslots = true;
          break;
        } else {
          pushedIntervals.push({
            start: startTS,
            end: endTS
          })
        }
      }

      if (!foundOverlappedTimeslots) {

        await this.dataService.updateSeason(season);
        for (let timeslot of season.seasonTimeslots) {
          await this.dataService.updateTimeSlot(this.resortData.id,season.id,timeslot);
        }
      } else {
        let dialogRef = this.dialog.open(ModalErrorComponent, {
          width: '400px',
          data: {
            error: "Fasce orarie sovrapposte, non è possibile aggiornare"
          }
        });
        this.clicked = false;
        redirect = false;
      }

    }

    if (this.selectedExtra.priceType === 'timetable') {

      const prices = [];

      for (const priceKey in this.prices) {
        if (this.prices[priceKey] && priceKey.split('_')[0] === 'extra' ) {

          const extraId = priceKey.split('_')[1];
          const seasonId = parseInt(priceKey.split('_')[2]);
          const timeslotId = parseInt(priceKey.split('_')[3]);
          console.log(extraId,seasonId, timeslotId)
          if (parseInt(extraId) == this.selectedExtra.id || extraId == 'null') {
            console.log(priceKey);
            prices.push({
              season_id: seasonId,
              version: 4,
              zone_id: 0,
              lastMinute: this.prices[priceKey].lastMinute,
              weekly: this.prices[priceKey].weekly,
              biweekly: this.prices[priceKey].biweekly,
              triweekly: this.prices[priceKey].triweekly,
              daily: this.prices[priceKey].daily,
              monthly: this.prices[priceKey].monthly,
              weekend: this.prices[priceKey].weekend,
              seasonal: this.prices[priceKey].seasonal,
              specialSeason: this.prices[priceKey].specialSeason,
              offer: 0,
              type: 'extra',
              extra_id: this.selectedExtra.id,
              timeSlot_id: timeslotId
            })
          }
        }
      }

      console.log(prices);
      await this.dataService.setExtraPrices(this.selectedExtra.id,prices);

    }
    console.log(redirect);
    if (redirect) {
      this.selectedExtra = null;
      this.router.navigate(['settings/offer/8']);
    }
  }


  async removeExtra() {


    this.loading = true;
    const data = await this.dataService.getReservations(null, null, null, null, null, null,null, null, null, null, null, null, null, null, null, 1, 1, true,this.selectedExtra.id, null, null, null, null, null , null, null,null,null,null);

    this.loading = false;
    if (data.count) {
      let dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'remove-error',
          error: 'reservations'
        }
      })

      const self = this;

      dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            self.clicked = false;
      });

    } else {


      let dialogRef = this.dialog.open(ModalRemoveComponent, {
        width: '340px',
        height: '130px',
        data: {
          type:"extras",
          extra: this.selectedExtra
        }
      })

      dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.clicked = false;
      });
    }

  }

  async uploadFile(event) {
    const file = event.target.files[0];
    console.log(file)

    if (file) {
      const filePath = 'resorts/' + this.dataService.resortData.id + '/extras/' + file.name;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      // observe percentage changes
      this.uploadPercent = task.percentageChanges();
      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(async () => {
            this.uploadPercent = null;
            this.selectedExtra.image = await fileRef.getDownloadURL().toPromise();
            //this.saveExtra();
          })
       )
      .subscribe();
    }
  }

  removeImage() {
    this.selectedExtra.image = '';
    this.uploadPercent = null;
  }

  checkStartTime(start:string, end:string, timeslot:any, season:any) {
    let hourStart = parseInt(start.split(":")[0]);
    let minuteStart = parseInt(start.split(":")[1]);
    let hourEnd = parseInt(end.split(":")[0]);
    let minuteEnd = parseInt(end.split(":")[1]);

    if (hourEnd < hourStart) {
      timeslot.endHour = timeslot.startHour;
    }

    if (hourEnd === hourStart) {
      if (minuteEnd < minuteStart) {
        timeslot.endHour = timeslot.startHour;
      }
    }

  }

  checkEndTime(start:string,end:string, timeslot:any, season:any) {
    let hourStart = parseInt(start.split(":")[0]);
    let minuteStart = parseInt(start.split(":")[1]);
    let hourEnd = parseInt(end.split(":")[0]);
    let minuteEnd = parseInt(end.split(":")[1]);

    console.log(hourEnd,hourStart,minuteEnd,minuteStart);
    if (hourEnd < hourStart) {
      timeslot.startHour = timeslot.endHour;
    }

    if (hourEnd === hourStart) {
      if (minuteEnd < minuteStart) {
        timeslot.startHour = timeslot.endHour;
      }
    }
  }

  /* checkStartHourIfNotAvailable(timeslot:any, time:string, season:any) {
    let startHour = parseInt(timeslot.startHour.split(":")[0]);
    let startMinutes = parseInt(timeslot.startHour.split(":")[1]);
    let endHour = parseInt(timeslot.endHour.split(":")[0]);
    let endMinutes = parseInt(timeslot.endHour.split(":")[1]);

    let dropboxTimeHour = parseInt(time.split(":")[0])
    let dropboxTimeMinutes = parseInt(time.split(":")[1])

    let isTimeIncludedInTheInterval = ((dropboxTimeHour*60+dropboxTimeMinutes) >= (startHour*60+startMinutes) && (dropboxTimeHour*60+dropboxTimeMinutes) <= (endHour*60 +endMinutes))

    let disabled = false;

    // SE L'ORARIO NELLA DROPBOX E' MAGGIORE DI ENDHOUR ALLORA DISABILITALO
    if ((dropboxTimeHour*60 + dropboxTimeMinutes) > (endHour*60+endMinutes)) {
      return true;
    }

    if (!isTimeIncludedInTheInterval && season.availableTimeslots.indexOf(time) === -1) {
      return true;
    }

    return false;
  }

  checkEndHourIfNotAvailable(timeslot:any, time:string, season:any) {
    let startHour = parseInt(timeslot.startHour.split(":")[0]);
    let startMinutes = parseInt(timeslot.startHour.split(":")[1]);
    let endHour = parseInt(timeslot.endHour.split(":")[0]);
    let endMinutes = parseInt(timeslot.endHour.split(":")[1]);

    let dropboxTimeHour = parseInt(time.split(":")[0])
    let dropboxTimeMinutes = parseInt(time.split(":")[1])

    let isTimeIncludedInTheInterval = ((dropboxTimeHour*60+dropboxTimeMinutes) >= (startHour*60+startMinutes) && (dropboxTimeHour*60+dropboxTimeMinutes) <= (endHour*60 +endMinutes))

    let disabled = false;

    // SE L'ORARIO NELLA DROPBOX E' MINORE DI startHour ALLORA DISABILITALO
    if ((dropboxTimeHour*60 + dropboxTimeMinutes) < (startHour*60+endMinutes)) {
      return true;
    }

    if (!isTimeIncludedInTheInterval && season.availableTimeslots.indexOf(time) === -1) {
      return true;
    }

    return false;
  } */


}
