import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from '../../../core/providers/data.service';


@Component({
  selector: 'app-modal-reserve',
  templateUrl: './modal-reserve.component.html',
  styleUrls: ['./modal-reserve.component.scss']
})
export class ModalReserveComponent implements OnInit {

  notes:any = '';
  user: any;

  constructor(public dataService: DataService, public dialogRef: MatDialogRef<ModalReserveComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.user = this.dataService.userData.first_name + ' ' + this.dataService.userData.last_name;
  }

  ngOnInit() {
  }


  close(confirm): void {
    this.dialogRef.close({confirm,notes: this.notes});
  }
}
