import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service'
import { SettingsService } from '../../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ModalErrorComponent } from '../../../../shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-discount-detail',
  templateUrl: './discount-detail.component.html',
  styleUrls: ['./discount-detail.component.scss']
})
export class DiscountDetailComponent implements OnInit {

  resortData; managerData; pageClicked; prices; discountId; lang = 'it';loading=false;
  selectedDiscount; optionals; dataLang='it'; rule1=0; elements; features;services;
  objectKeys = Object.keys;startSerializedDate;endSerializedDate;
  startTimestamp;endTimestamp; clicked = false; showLanguageModal1=false;
  noColor=true;

  constructor(public router: Router, public dialog: MatDialog, private route: ActivatedRoute, private dataService: DataService, private settingsService: SettingsService) {
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.dataLang = this.dataService.lang;

    if (this.resortData) {
      this.initDiscount();
    }
  }

  async ngOnInit() {

    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;
    this.prices = this.dataService.prices;

    this.initDiscount();
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    if (this.startSerializedDate > this.endSerializedDate) {
      this.endSerializedDate = this.startSerializedDate;
    }

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    if (this.endSerializedDate < this.startSerializedDate) {
      this.startSerializedDate = this.endSerializedDate;
    }

  }

  resetHeader() {

  }

  initDiscount() {
    this.discountId = this.route.snapshot.paramMap.get('discountId');

    if (this.resortData.discountsByIds[this.discountId]) {
      this.selectedDiscount = JSON.parse(JSON.stringify(this.resortData.discountsByIds[this.discountId]));
      this.startTimestamp = this.selectedDiscount.start * 1000;
      this.startSerializedDate = new Date(this.startTimestamp);
      this.endTimestamp = this.selectedDiscount.end * 1000;
      this.endSerializedDate = new Date(this.endTimestamp);
      const timezone = new Date(this.selectedDiscount.start * 1000).getTimezoneOffset();

      if (timezone !== 0) {
        const start = (new Date(this.selectedDiscount.start * 1000).getTime() - timezone * 60000)/1000;
        const end = (new Date(this.selectedDiscount.end * 1000).getTime() - timezone * 60000)/1000;
        this.selectedDiscount.start = start;
        this.selectedDiscount.end = end;
      }

      if (!this.selectedDiscount.info) {
        this.selectedDiscount.info = {}
      } else if (!this.selectedDiscount.info.mapColor) {
        this.selectedDiscount.info.mapColor = null;
      } else if (this.selectedDiscount.info.mapColor) {
        this.noColor = false;
      }
      

    } else {

      const offsetStart = new Date().getTimezoneOffset() * 60000;
      const offsetEnd = new Date().getTimezoneOffset() * 60000;

      const endDate = ((new Date()).setHours(0, 0, 0, 0) - offsetEnd )/1000 ;
      const startDate = ((new Date()).setHours(0, 0, 0, 0) - offsetStart )/1000 ;

      this.startSerializedDate = new Date(startDate * 1000);
      this.endSerializedDate = new Date(endDate * 1000);

      this.discountId = null;
      this.selectedDiscount = {
        name: JSON.parse(JSON.stringify(this.dataService.languages)),
        id: null,
        start: startDate,
        end: endDate,
        info: {
          "mapColor":null
        }
      }

    }

    console.log(this.selectedDiscount);
  }

  setNullColor() {
    if (this.noColor)
      this.selectedDiscount.info.mapColor = null;
  }

  async saveDiscount() {
    console.log(this.startSerializedDate);

    const offsetStart = new Date(this.startSerializedDate).getTimezoneOffset() * 60000;
    const offsetEnd = new Date(this.endSerializedDate).getTimezoneOffset() * 60000;
    console.log((new Date(this.endSerializedDate)).setHours(0, 0, 0));
    console.log(offsetEnd);
    console.log((new Date(this.endSerializedDate)).setHours(0, 0, 0) - offsetEnd);
    console.log((new Date(this.endSerializedDate)).setHours(0, 0, 0) + offsetEnd);
    this.selectedDiscount.end = ((new Date(this.endSerializedDate)).setHours(0, 0, 0) - offsetEnd ) / 1000;
    this.selectedDiscount.start = ((new Date(this.startSerializedDate)).setHours(0, 0, 0) - offsetStart ) / 1000;
    console.log(((new Date(this.endSerializedDate)).setHours(0, 0, 0) - offsetEnd ) / 1000);

    console.log(this.selectedDiscount);
    if (this.selectedDiscount.id) {
      this.resortData.discountsByIds[this.discountId] = this.selectedDiscount;
      let foundIndex = this.resortData.discounts.findIndex(disc => disc.id === this.selectedDiscount.id);
      this.resortData.discounts[foundIndex] = this.selectedDiscount;

      await this.dataService.updateDiscount(this.selectedDiscount);
    } else {
      const newDiscount = await this.dataService.createDiscount(this.selectedDiscount);
      this.resortData.discounts.push(newDiscount);
      for (const discountObj of this.resortData.discounts) {
        this.resortData.discountsByIds[discountObj.id] = discountObj;
      }
    }

    this.router.navigate(['settings/offer/7']);
  }

  async removeDiscount() {


    this.loading = true;
    const data = await this.dataService.getReservations(null, null, null, null, null, null,null, null, null, null, null, null, null, null, null, 1, 1, true,null, this.selectedDiscount.id, null, null, null, null , null, null,null,null,null);

    this.loading = false;
    if (data.count) {
      let dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'remove-error',
          error: 'reservations'
        }
      })

      const self = this;

      dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            self.clicked = false;
      });

    } else {


      let dialogRef = this.dialog.open(ModalRemoveComponent, {
        width: '340px',
        height: '130px',
        data: {
          type: "discounts",
          discount: this.selectedDiscount
        }
      });

      dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.clicked = false;
      });
    }

  }

  checkPercentage() {
    if (this.selectedDiscount.percentage < 0 ) {
      this.selectedDiscount.percentage = 0;
    }
  }


}
