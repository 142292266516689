import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service'
import { SettingsService } from '../../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ModalErrorComponent } from '../../../../shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss']
})
export class OfferDetailComponent implements OnInit {

  resortData; managerData; pageClicked; prices; offerId; lang = 'it';
  selectedOffer; optionals; dataLang='it'; rule1=0; elements; features;services;
  objectKeys = Object.keys;startSerializedDate;endSerializedDate;
  startTimestamp; endTimestamp; disableDates=[]; offers; loading=false;showLanguageModal1=false;

  constructor(public router: Router, public dialog: MatDialog,private route: ActivatedRoute, public dataService: DataService, private settingsService: SettingsService) {
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.dataLang = this.dataService.lang;
    this.initOffer();

  }

  ngOnInit() {
  }

  async initOffer() {

    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;
    this.offers = await this.dataService.getOffers();

    this.offerId = this.route.snapshot.paramMap.get('offerId');
    console.log(this.offerId);
    const foundOffer = this.dataService.offers.find(coup => coup.id == this.offerId);
    if (foundOffer) {
      this.selectedOffer = JSON.parse(JSON.stringify(foundOffer));
    } else if (this.dataService.selectedOffer) {
      this.selectedOffer = JSON.parse(JSON.stringify(this.dataService.selectedOffer));
    } else {
      this.offerId = null;
      this.selectedOffer = {
        name: JSON.parse(JSON.stringify(this.dataService.languages)),
        id: null,
        dates: [],
        active: false,
        price: 0,
        zonesIds: [],
        packageId: null
      };

    }

    console.log(this.selectedOffer);
  }

  newInterval() {
    this.dataService.selectedOffer = this.selectedOffer;
    const offset = new Date().getTimezoneOffset() * 60000;
    const nowTS = ((new Date()).setHours(0, 0, 0, 0) - offset )/1000;

    const newInterval = {
      start: nowTS,
      end: nowTS,
      slots: []
    };

    for (const slot of this.dataService.map) {
        if (slot.packagesIds.indexOf(this.selectedOffer.packageId) > -1) {
          newInterval.slots.push(slot.row + '_' + slot.col);

          if (this.selectedOffer.zonesIds.indexOf(slot.zoneId) == -1) {
            this.selectedOffer.zonesIds.push(slot.zoneId);
          }
        }
    }

    this.selectedOffer.dates.push(newInterval);

    console.log(this.selectedOffer);
    //this.router.navigateByUrl('/settings/offer/cocobuk/map-offer/'+this.offerId+'/new');
  }

  removeInterval(index) {
    this.selectedOffer.dates.splice(index, 1);

    const selectedSlots = [];
    this.selectedOffer.zonesIds = [];
    if (this.selectedOffer.dates.length > 0) {
      for (const interval of this.selectedOffer.dates) {
        for (const slot of interval.slots) {
          if (selectedSlots.indexOf(slot) == -1) {
            selectedSlots.push(slot);
          }
        }
      }

      for (const slot of selectedSlots) {
        const zoneId = this.dataService.mapByRowCol[slot.split('_')[0]][slot.split('_')[1]].zoneId;

        if (this.selectedOffer.zonesIds.indexOf(zoneId) == -1) {
          this.selectedOffer.zonesIds.push(zoneId);
        }
      }
    }
  }

  async removeOffer() {

    await this.dataService.deleteOffer(this.selectedOffer);
    this.router.navigate(['settings/offer/cocobuk/1']);

  }

  async saveOffer() {

    const offerToSave = JSON.parse(JSON.stringify(this.selectedOffer));
    if (this.selectedOffer.id) {
      await this.dataService.updateOffer(this.selectedOffer);
    } else {
      const newOffer = await this.dataService.createOffer(offerToSave);
      this.selectedOffer.id = newOffer.id;
      this.dataService.offers.push(offerToSave);
    }

    this.dataService.selectedOffer = null;
    this.router.navigate(['settings/offer/cocobuk/1']);

  }

  changeZone() {

  }

  editSlots(interval) {
    console.log(interval);
    this.dataService.selectedOffer = this.selectedOffer;
    this.router.navigate(['/settings/offer/cocobuk/map-offer/'+this.selectedOffer.id+'/'+interval.start+'_'+interval.end])
  }

}
