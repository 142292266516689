import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepickerInputEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/providers/data.service';
import { SettingsService } from 'src/app/core/providers/settings.service';

@Component({
  selector: 'app-reservation-smart',
  templateUrl: './reservation-smart.component.html',
  styleUrls: ['./reservation-smart.component.scss']
})
export class ReservationSmartComponent implements OnInit {

  resortData; selectedPackage=null; quantity = null;
  objectKeys = Object.keys; lang = 'it'; loader = false;
  startTimestamp; endTimestamp; optionalsByIds={}; optionalsByKeys= {};
  startDate; endDate; startMonth; startYear;startSerializedDate;endSerializedDate; packages;
  quantities = [1,2,3,4,5,6,7,8,9,10];selectedTimeslot=0; timeslots = [];intervalTimeSlots=[];
  noPackageAvailable = false; availabilities; residual = 0;extraPackages;extra; reservation;seasonal = false;
  selectedPackages=[];packagesAvailabilities=[];reservationMapData;
  today = new Date().getTime()/1000;step=1;mapData=[];simpleComboByPackage=[];selectedZone=0;daysLength=1;
  complexComboByPackage = []; complexComboByDate = [];

  constructor(public settingsService:SettingsService, public router: Router, public dataService: DataService, public dialogRef: MatDialogRef<ReservationSmartComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    ) {

      this.resortData = this.dataService.resortData;
      this.reservation = this.data.reservation;
      this.startTimestamp = this.data.reservation.start*1000;
      this.endTimestamp = this.data.reservation.end*1000;
      const startDate = new Date(this.startTimestamp);
      startDate.setUTCHours(0, 0, 0, 0);
      this.startSerializedDate = startDate;
      const endDate = new Date(this.endTimestamp);
      endDate.setUTCHours(0, 0, 0, 0);
      this.endSerializedDate = endDate;
      this.daysLength = (this.endTimestamp-this.startTimestamp)/(24*60*60*1000) + 1;
  } 

  async ngOnInit() {
    await this.initPackages();
  }

  
  async resetData() {
    this.selectedTimeslot = 0;
    this.selectedPackage = null;
    this.quantity = null;
    this.noPackageAvailable = false;
    this.simpleComboByPackage=[];
    this.complexComboByPackage = [];
    this.complexComboByDate = [];
    this.selectedPackages = [];
    this.step=1;
    await this.initPackages();
  }

  async initPackages() {
    this.getTimeslots();
    
    console.log(this.data.reservationMapData);
    this.checkAvailability(true);

  }

  close(confirm): void {
    if (confirm) {
      this.dialogRef.close({standardCombo:this.selectedPackages, complexCombo: this.complexComboByPackage});
    } else {
      this.dialogRef.close(null);
    }

  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp)
      this.endTimestamp = this.startTimestamp;

    let endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);
    this.daysLength = (this.endTimestamp-this.startTimestamp)/(24*60*60*1000) + 1;

    this.checkAvailability(true);
    //let setReservations = await this.getReservations();
    
  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.loader = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.startTimestamp = this.endTimestamp;
    }

    let startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);
    this.daysLength = (this.endTimestamp-this.startTimestamp)/(24*60*60*1000) + 1;

    this.checkAvailability(true);
    //let setReservations = await this.getReservations();
  }

  async setTodayDate() {
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    let startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startDate = startDate.getDate();

    let endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endDate = endDate.getDate();

    this.startSerializedDate = new Date(this.startTimestamp);
    this.endSerializedDate = new Date(this.endTimestamp);

    this.daysLength = (this.endTimestamp-this.startTimestamp)/(24*60*60*1000) + 1;
    //let setReservations = await this.getReservations();
  
    this.checkAvailability(true);
  }

  updateTimeslot() {
    console.log(this.selectedTimeslot, this.timeslots);
    this.intervalTimeSlots = []
    let now = new Date();
    let offset = now.getTimezoneOffset() * 60;

    if (this.selectedTimeslot) {
      let findTS = this.timeslots.find(t => t.id === this.selectedTimeslot);
      let offset = now.getTimezoneOffset();
      let startSeconds = parseInt(findTS.startHour.split(":")[0])*60*60 + parseInt(findTS.startHour.split(":")[1])*60 + offset*60;
      let endSeconds = parseInt(findTS.endHour.split(":")[0])*60*60 + parseInt(findTS.endHour.split(":")[1])*60 + offset*60;
      findTS.startSeconds = startSeconds;
      findTS.endSeconds = endSeconds;
      findTS.utcOffset = -offset/60;
      this.intervalTimeSlots.push(findTS);
    }

    this.checkAvailability(true);
  }


  minusQuantity(pack) {
    pack.quantity--;
    if (pack.quantity < 0) {
      pack.quantity = 0;
    }

    this.updateSelectedPackages();
  }
  
  plusQuantity(pack) {
    pack.quantity++;
    let max = pack.max;
    if (pack.daysAvailability.length > max) {
      max = pack.daysAvailability.length;
    }

    let min = 100000;
    for (let dailyAvailability of pack.daysAvailability) {
      if (dailyAvailability.slots.length < min) {
        min = dailyAvailability.slots.length;
      }
    }

    if (min < max) {
      max = min;
    }

    if (pack.quantity > max) {
      pack.quantity = max;
    }

    this.updateSelectedPackages();
  }

  getTempMax(pack) {
    let max = pack.max;
    if (pack.daysAvailability.length > max) {
      max = pack.daysAvailability.length;
    }

    let min = 100000;
    if (pack.daysAvailability.length === this.daysLength) {
      for (let dailyAvailability of pack.daysAvailability) {
        if (dailyAvailability.slots.length < min) {
          min = dailyAvailability.slots.length;
        }
      }
    } else {
      min = 0;
    }

    if (min < max) {
      max = min;
    }
    return max;
  }
  
  async checkAvailability(downloadData) {
    if (downloadData) {
      this.reservationMapData = await this.dataService.getMapReservations(this.startTimestamp / 1000, this.endTimestamp / 1000);
    }
    console.log(this.reservationMapData);
    this.packages = [];
    this.packagesAvailabilities = [];

    this.resortData.packages.map(pack => {
      if (pack.type === 'slot') {
        const packCopy = JSON.parse(JSON.stringify(pack));
        packCopy.quantity = null;
        packCopy.max = 0;
        packCopy.selected = true;
        packCopy.daysAvailability = [];
        packCopy.slotsOccurrencies=[];
        this.packagesAvailabilities.push(packCopy);
      }
    });

    console.log(this.packagesAvailabilities);
    console.log(this.dataService.map)
    this.mapData = JSON.parse(JSON.stringify(this.dataService.map));
    for (let slot of this.mapData) {

      if (slot.zoneId && slot.defaultPackId && slot.packagesIds.length > 0 && (this.selectedZone == 0 || this.selectedZone != 0 && slot.zoneId === this.selectedZone)) {
        // CHECK IF SLOT EXIST AND COVER THE SELECTED ZONE

        // CHECK IF COMBO AVAILABLE
        for(let ts = this.startTimestamp/1000; ts <= this.endTimestamp/1000; ts+=24*60*60) {
          // INSERISCI IN OGNI PACCHETTO DELLO SLOT I GG DI DISPONIBILITA'
          let selectedPacks = this.packagesAvailabilities.filter((p:any)=>slot.packagesIds.indexOf(p.id) > -1);
          for (let p of selectedPacks) {
            // CONTROLLA SE LO SLOT E' DISPONIBILE PER QUESTO GG

            let findDay = p.daysAvailability.find((d:any)=>d.ts === ts);
            let saveSlot = false;
            if (this.reservationMapData[slot.row] && this.reservationMapData[slot.row][slot.col]) {
              let findTS = this.reservationMapData[slot.row][slot.col].reservationsKeys.find((t:any)=>t.day_timestamp === ts);

              if (!findTS) {
                saveSlot = true;
              }
            } else {
              saveSlot = true;
            }

            if (saveSlot) {
              if (!findDay) {
                let objTs = {
                  ts: ts,
                  slots:[slot]
                }
                p.daysAvailability.push(objTs);
              } else {

                findDay.slots.push(slot);
              }

              let findOccurency = p.slotsOccurrencies.find((s:any)=>s.row === slot.row && s.col == slot.col);
              if (!findOccurency) {
                
                let slotObj = {
                  id: slot.id,
                  key: slot.row+'_'+slot.col,
                  row: slot.row,
                  col: slot.col,
                  occurencies:1,
                  daysAvailable:[ts],
                  zoneId:slot.zoneId,
                  label:slot.label
                }
                p.slotsOccurrencies.push(slotObj)
              } else {
                findOccurency.occurencies++;
                findOccurency.daysAvailable.push(ts);
              }
            }
          }
          
        }

        if (!(this.reservationMapData[slot.row] && this.reservationMapData[slot.row][slot.col] && this.reservationMapData[slot.row][slot.col].state != 1)) {
          let selectedPacks = this.packagesAvailabilities.filter((p:any)=>slot.packagesIds.indexOf(p.id) > -1);

          for (let p of selectedPacks) {
            p.max+=1;
          }
          slot.busy = false;
        } else {
          slot.busy = true;
        }
      }
    }

    for (let p of this.packagesAvailabilities) {
      p.slotsOccurrencies = p.slotsOccurrencies.sort(this.sortByOccurency)
    }
    console.log(this.packagesAvailabilities)

  }

  getTimeslots() {
    if (this.selectedPackage) {

      let length = (this.endTimestamp/1000 - this.startTimestamp/1000)/(24*60*60) + 1;
      let seasonsFound = this.dataService.getSeasonsInReservation(this.startTimestamp/1000, length,this.selectedPackage);
      console.log(seasonsFound);
      if (seasonsFound && Object.keys(seasonsFound).length === 1) {
        for (let seasonKey in seasonsFound) {
          this.timeslots = this.resortData.timeSlotsBySeason[seasonKey];
        }
      }

      if (this.intervalTimeSlots && this.intervalTimeSlots.length) {
        let selectedTimeslot = this.intervalTimeSlots[0];
        if (this.timeslots.find(t => t.id === selectedTimeslot.id)) {
        } else {
          this.timeslots.push(selectedTimeslot);
        }

        this.selectedTimeslot = selectedTimeslot.id;
      }

      console.log(this.timeslots);
    } else {
      this.selectedTimeslot = 0;
      this.timeslots = [];
    }
  }

  updateSelectedPackages() {
    this.selectedPackages = [];
    this.step = 1;
    for (let pack of this.packagesAvailabilities) {
      if (pack.quantity > 0) {
        for (let i=0;i<pack.quantity;i++) {
          this.selectedPackages.push({
            col: null,
            coords: "",
            optionalsKeys: {},
            optionalsPrices: {},
            packageKey: pack.id,
            price: 0,
            row: null,
            slot_key: "",
            type: "slot",
            zoneKey: null,
            start:0,
            end:0,
            simpleComboAvailable:false
          })

        }
      }
    }

    console.log(this.selectedPackages);
  }

  updateZone() {
    this.checkAvailability(false);
  }

  goToStep2() {
    this.simpleComboByPackage= [];
    for (let pack of this.packagesAvailabilities) {
      if (pack.quantity > 0) {

        let combo = JSON.parse(JSON.stringify(pack));
        combo.order = [];
        combo.simpleComboAvailable = false;
        this.simpleComboByPackage.push(combo)
      }
    }
    
    console.log("simpleComboByPackage",this.simpleComboByPackage);
    console.log("selectedPackages",this.selectedPackages);
    for (let order of this.selectedPackages) {
      order.simpleComboAvailable = false;
      
      // PER OGNI PACCHETTO RICHIESTO VERIFICA SE ESISTE UNA POSTAZIONE COMPLETAMENTE LIBERA O PIU' COMBO

      let findFirstFreeSlot = this.mapData.find((slot:any)=> slot.packagesIds.indexOf(order.packageKey) > -1 && slot.busy == false);
      if (findFirstFreeSlot) {
        order.simpleComboAvailable = true;
        // POSTAZIONE COMPLETAMENTE LIBERA
        findFirstFreeSlot.busy = true;
        order.row = findFirstFreeSlot.row;
        order.col = findFirstFreeSlot.col;
        order.slot_key = findFirstFreeSlot.row+'_'+findFirstFreeSlot.col;
        order.coords = findFirstFreeSlot.label;
        order.zoneKey = findFirstFreeSlot.zoneId;
        order.start = this.startTimestamp/1000;
        order.end = this.endTimestamp/1000;

        let findCombo = this.simpleComboByPackage.find((p:any)=>p.id === order.packageKey);
        if (findCombo) {
          findCombo.simpleComboAvailable = true;
          findCombo.order.push(order);
        }
      }
    }

    let selectedCombo = [];
    this.complexComboByPackage = [];

    /* GENERA COMBO CHE PREVEDONO POSTAZIONI DIVERSE */
      
    for (let order of this.selectedPackages) {
      let tsCover = [];
      let selectedPack = this.packagesAvailabilities.find((p:any)=>p.id === order.packageKey);
          
      console.log(selectedPack);
      if (selectedPack) {
        let availableOccurencies = selectedPack.slotsOccurrencies.filter((o:any)=>o.occurencies > 0);
        console.log(availableOccurencies);
        if (availableOccurencies.length) {

          let findFirstFreeSlot = null;
          for (let ts = this.startTimestamp/1000; ts<=this.endTimestamp/1000; ts+=24*60*60) {
            findFirstFreeSlot = availableOccurencies.find((o:any)=>o.daysAvailable.indexOf(ts) > -1);
            console.log(findFirstFreeSlot);
            if (findFirstFreeSlot && findFirstFreeSlot.occurencies) {
              findFirstFreeSlot.occurencies-=1;
              let tsIndex = findFirstFreeSlot.daysAvailable.findIndex((t:any)=>t === ts);
              findFirstFreeSlot.daysAvailable.splice(tsIndex,1);
              tsCover.push({
                ts:ts,
                slot: findFirstFreeSlot
              })
            }

            
          }
        } 
        let packObj = {
          id: selectedPack.id,
          dates: tsCover,
          simpleComboAvailable:order.simpleComboAvailable
        }
        this.complexComboByPackage.push(packObj);
      }
    }
    console.log(this.selectedPackages);
    console.log(this.simpleComboByPackage);
    console.log(this.complexComboByPackage);

    this.complexComboByDate = [];

    for (let pack of this.complexComboByPackage) {
      if (!pack.simpleComboAvailable) {
        let pushCombo = false;
        let findCombo = this.complexComboByDate.find((p:any)=>p.id === pack.id);
        if (!findCombo) {

          findCombo = {
            id: pack.id,
            dates:[],
          };

          pushCombo= true;
        }

        for (let dailySlot of pack.dates) {
          let findTS = findCombo.dates.find((o:any)=>o.ts === dailySlot.ts);

          if (!findTS) {
            findTS = {
              ts: dailySlot.ts,
              order: []
            }
            findTS.order.push(dailySlot.slot)
            findCombo.dates.push(findTS);
          } else {
            findTS.order.push(dailySlot.slot)
          }
        }

        if (pushCombo)
          this.complexComboByDate.push(findCombo)
      }
    }

    console.log(this.complexComboByDate );

    this.step = 2;
  }
    
  sortByOccurency( a, b ) {
    if ( a.occurencies > b.occurencies ) {
      return -1;
    }
    if ( a.occurencies < b.occurencies ) {
      return 1;
    }
    return 0;
  }

}
