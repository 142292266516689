import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { DataService } from 'src/app/core/providers/data.service';
import { ModalLogsComponent } from '../../../shared/components/modal-logs/modal-logs.component';
import { MatDialog} from '@angular/material';
import { ReservationService } from 'src/app/core/providers/reservation.service';
import { SettingsService } from 'src/app/core/providers/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-reservation-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnChanges  {

  @Input() reservation:any;
  @Input() resortData:any;
  @Input() mainCustomer:any;
  @Input() lang:any;
  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();
  logs; shortLogs; logSub;
  objectKeys = Object.keys;

  constructor( private db: AngularFireDatabase , public dataService:DataService, public dialog:MatDialog, public reservationService: ReservationService, public settingsService: SettingsService , public translateService: TranslateService ) {

  }

  ngOnChanges(): void {
    console.log("DATI CAMBIATI")
    if (this.db) {
      this.logSub = this.db.object('logs/reservations/' + this.resortData.id + '/' + this.reservation.reservationCode).valueChanges().subscribe(data => {
        console.log("CHECK LOGS");
        this.processLogs(data);
      });
    }
  }

  ngOnDestroy() {
    this.logSub.unsubscribe();
  }

  processLogs(rtdbData) {
    // console.log(rtdbData);
    this.logs = [];
    let prevReservation = null;

    for (const ts in rtdbData) {

      if (rtdbData[ts] && rtdbData[ts].state !== 6) {

        const selectedReservation = rtdbData[ts];

        if (selectedReservation.order && selectedReservation.order.products) {


          const obj = {
            date: parseInt(ts),
            operation: 'create',
            author: selectedReservation.createdBy,
            details: [],
            shortDetails: [],
            reservation: selectedReservation,
            showDetails: false
          };

          if (!prevReservation) {
            for (const prod of selectedReservation.order.products) {
              let detail = {
                label: ''
              }

              if (prod.type === 'reserved-discount') {
                detail.label = 'Applicato sconto ' + Math.round(prod.price*100)/100 + '€';
              }

              if (prod.type === 'reserved-extra') {
                detail.label = 'Applicato extra ' + Math.round(prod.price*100)/100+ '€';
              }

              if (prod.type === 'package' && prod.name && prod.name[this.lang]) {
                detail.label = 'Aggiunto pacchetto ' + prod.name[this.lang] + ' su ' + prod.label;
              }

              if (prod.type === 'optional' && prod.name && prod.name[this.lang]) {
                detail.label = 'Aggiunto ' + prod.name[this.lang] + ' x ' + prod.quantity + ' su ' + prod.label;
              }

              if (prod.type === 'extra' && prod.name && prod.name[this.lang]) {
                detail.label = 'Aggiunto ' + prod.name[this.lang] + ' x ' + prod.quantity;
              }

              if (prod.type === 'discount' && prod.name && prod.name[this.lang]) {
                detail.label = 'Aggiunto ' + prod.name[this.lang];
              }

              if (detail.label != '') {
                obj.details.push(detail)
              }
            }

            if (selectedReservation.order.customerBill) {

              for (const bill of selectedReservation.order.customerBill) {

                let detail = {
                  label: ''
                }

                if (bill.fiscalPrinted && bill.settledFlag) {
                  detail.label = 'Stampa fiscale "' + bill.name + '" €' + Math.round(bill.price*100)/100;
                } else if (!bill.fiscalPrinted && bill.settledFlag) {
                  detail.label = 'Saldo conto cliente €' + Math.round(bill.price*100)/100;
                } else if (bill.type === 'movement' && !bill.fiscalPrinted) {
                  detail.label = 'Aggiunta voce "' + bill.name + '" €' + Math.round(bill.price*100)/100;
                } else if (bill.type === 'movement' && bill.fiscalPrinted) {
                  detail.label = 'Aggiunta voce "' + bill.name + '" € ' + Math.round(bill.price*100)/100 + ' e stampata fiscalmente';
                }


                if (detail.label != '') {
                  obj.details.push(detail)
                }


              }
            }

            if (selectedReservation.seasonTicket) {
              const detail = {
                label: 'Prenotazione stagionale'
              };

              obj.details.push(detail);

            }

            if (selectedReservation.customers) {
              const currentMainCustomer = selectedReservation.customers.find(cust => cust.principal === true);

              if (currentMainCustomer) {
                const detail = {
                  label: 'Inserito nome principale: ' + currentMainCustomer.customer.name + ' ' + currentMainCustomer.customer.surname
                };

                obj.details.push(detail);
              }
            }
          } else {
            obj.operation = 'update';
            obj.author = selectedReservation.updatedBy;

            // TROVA PRODOTTI ELIMINATI RISPETTO AL SALVATAGGIO PRECEDENTE

            if (prevReservation.order && prevReservation.order.products) {

              for (const prod of prevReservation.order.products) {
                let exist = false;
                let detail = {
                  label: ''
                }

                if (selectedReservation.order && selectedReservation.order.products) {

                  if (prod.type === 'extra') {
                    detail.label = 'Eliminato ' + prod.name[this.lang];
                    exist = selectedReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.extraId === prod.extraId);
                  } else if (prod.type === 'package') {
                    detail.label = 'Eliminato pacchetto ' + prod.name[this.lang] + ' su ' + prod.label;
                    exist = selectedReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.packageId === prod.packageId && currentProd.row === prod.row && currentProd.col === prod.col);
                  } else if (prod.type === 'optional') {
                    detail.label = 'Eliminato ' + prod.name[this.lang] + ' su ' + prod.label;
                    exist = selectedReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.packageId === prod.packageId && currentProd.optionalId === prod.optionalId && currentProd.row === prod.row && currentProd.col === prod.col);
                  } else if (prod.type === 'discount') {
                    detail.label = 'Eliminato ' + prod.name[this.lang];
                    exist = selectedReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.discountId === prod.discountId);
                  } else if (prod.type === 'reserved-discount') {
                    detail.label = 'Eliminato sconto riservato ' + Math.round(prod.price*100)/100 + '€';
                    exist = selectedReservation.order.products.find(currentProd => currentProd.type === 'reserved-discount' && currentProd.price === prod.price);
                  } else if (prod.type === 'reserved-extra') {
                    detail.label = 'Eliminato extra ' + Math.round(prod.price*100)/100 + '€';
                    exist = selectedReservation.order.products.find(currentProd => currentProd.type === 'reserved-extra' && currentProd.price === prod.price);
                  }
                }

                if (!exist && detail.label !== '') {
                  obj.details.push(detail)
                }
              }

            }

            if (selectedReservation.order && selectedReservation.order.products) {

              // TROVA PRODOTTI AGGIUNTI RISPETTO AL SALVATAGGIO PRECEDENTE

              for (const prod of selectedReservation.order.products) {
                let exist = false;
                let detail = {
                  label: ''
                }

                if (prod.type === 'extra') {
                  detail.label = 'Aggiunto ' + prod.name[this.lang] + ' x ' + prod.quantity;
                  exist = prevReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.extraId === prod.extraId);
                } else if (prod.type === 'package') {
                  const fromDate = new Date(prod.startDate * 1000);
                  const toDate = new Date(prod.endDate * 1000);

                  let dayStart = fromDate.getDate().toString();
                  let monthStart = (fromDate.getMonth() + 1).toString();

                  let dayEnd = toDate.getDate().toString();
                  let monthEnd = (toDate.getMonth() + 1).toString();

                  if (fromDate.getDate() < 10) {
                    dayStart = '0' + dayStart;
                  }

                  if ((fromDate.getMonth() + 1) < 10) {
                    monthStart = '0' + monthStart;
                  }

                  if (toDate.getDate() < 10) {
                    dayEnd = '0' + dayEnd;
                  }

                  if ((toDate.getMonth() + 1) < 10) {
                    monthEnd = '0' + monthEnd;
                  }

                  const from = dayStart + '/' + monthStart;
                  const to = dayEnd + '/' + monthEnd;

                  detail.label = 'Aggiunto pacchetto ' + prod.name[this.lang] + ' su ' + prod.label + ' dal ' + from + ' al ' + to;
                  exist = prevReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.packageId === prod.packageId && currentProd.row === prod.row && currentProd.col === prod.col);
                } else if (prod.type === 'optional') {
                  detail.label = 'Aggiunto ' + prod.name[this.lang] + ' x ' + prod.quantity + ' su ' + prod.label;
                  exist = prevReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.packageId === prod.packageId && currentProd.optionalId === prod.optionalId  && currentProd.startDate === prod.startDate && currentProd.row === prod.row && currentProd.col === prod.col);
                } else if (prod.type === 'discount') {
                  detail.label = 'Aggiunto ' + prod.name[this.lang];
                  exist = prevReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.discountId === prod.discountId);
                } else if (prod.type === 'reserved-discount') {
                  detail.label = 'Applicato sconto riservato ' + Math.round(prod.price*100)/100 + '€';
                  exist = prevReservation.order.products.find(currentProd => currentProd.type === 'reserved-discount');
                } else if (prod.type === 'reserved-extra') {
                  detail.label = 'Applicato extra ' + Math.round(prod.price*100)/100 + '€';
                  exist = prevReservation.order.products.find(currentProd => currentProd.type === 'reserved-extra');
                }

                if (!exist && detail.label !== '') {
                  obj.details.push(detail);
                }

              }

              // TROVA PRODOTTI MODIFICATI RISPETTO AL SALVATAGGIO PRECEDENTE

              for (const prod of selectedReservation.order.products) {
                let exist = null;

                let detail = {
                  label: ''
                }

                if (prod.type === 'extra') {

                  exist = prevReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.quantity === prod.quantity && currentProd.extraId === prod.extraId);

                  if (!exist) {

                    let quantityMod = prevReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.quantity !== prod.quantity && currentProd.extraId === prod.extraId)
                    if (quantityMod) {
                      let difference = prod.quantity - quantityMod.quantity;
                      if (difference > 0) {
                        detail.label = 'Modificata quantità ' + prod.name[this.lang] + ' (+' + difference + ')';
                      } else {
                        detail.label = 'Modificata quantità ' + prod.name[this.lang] + ' (' + difference + ')';
                      }
                    }
                  }

                } else if (prod.type === 'package') {

                  // CASO 1 -> IL GESTORE HA CAMBIATO DATE INTERVALLO PACCHETTO

                  let intervalMod = prevReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.packageId === prod.packageId && (currentProd.startDate !== prod.startDate || currentProd.endDate !== prod.endDate) && currentProd.row === prod.row && currentProd.col === prod.col && currentProd.updatedAt !== prod.updatedAt);

                  if (intervalMod) {
                    const fromDate = new Date(prod.startDate * 1000);
                    const toDate = new Date(prod.endDate * 1000);

                    let dayStart = fromDate.getDate().toString();
                    let monthStart = (fromDate.getMonth() + 1).toString();

                    let dayEnd = toDate.getDate().toString();
                    let monthEnd = (toDate.getMonth() + 1).toString();

                    if (fromDate.getDate() < 10) {
                      dayStart = '0' + dayStart;
                    }

                    if ((fromDate.getMonth() + 1) < 10) {
                      monthStart = '0' + monthStart;
                    }

                    if (toDate.getDate() < 10) {
                      dayEnd = '0' + dayEnd;
                    }

                    if ((toDate.getMonth() + 1) < 10) {
                      monthEnd = '0' + monthEnd;
                    }

                    const from = dayStart + '/' + monthStart;
                    const to = dayEnd + '/' + monthEnd;
                    detail.label = 'Modificate date pacchetto ' + prod.name[this.lang] + ' su ' + prod.label + ' (nuovo intervallo dal ' + from + ' al ' + to + ')';
                  }

                  // CASO 2 -> IL GESTORE HA CAMBIATO IL PACKAGE ID ASSOCIATO A QUELL'INTERVALLO e A QUELLA POSTAZIONE

                  let packageMod = prevReservation.order.products.find(currentProd => currentProd.type === prod.type && currentProd.packageId !== prod.packageId && (currentProd.startDate === prod.startDate || currentProd.endDate === prod.endDate) && currentProd.row === prod.row && currentProd.col === prod.col && currentProd.updatedAt !== prod.updatedAt);

                  if (packageMod) {
                    detail.label = 'Modificato pacchetto ' + prod.name[this.lang] + ' su ' + prod.label;
                  }

                } else if (prod.type === 'optional') {
                } else if (prod.type === 'discount') {
                }

                if (detail.label !== '') {
                  obj.details.push(detail)
                }
              }
            }
            // TROVA CUSTOMER BILL AGGIUNTI RISPETTO AL SALVATAGGIO PRECEDENTE

            if (selectedReservation.order.customerBill) {
              for (const bill of selectedReservation.order.customerBill) {
                let exist = null;

                if (prevReservation.order.customerBill) {
                  exist = prevReservation.order.customerBill.find(bill2 => bill2.name === bill.name);
                }

                let detail = {
                  label: ''
                }

                if (!exist) {
                  let currentExist = this.reservation.order.customerBill.find(bill2 => bill2.name === bill.name);
                  if (currentExist) {
                    if (bill.fiscalPrinted && bill.settledFlag) {
                      detail.label = 'Stampa fiscale "' + bill.name + '" €' + Math.round(currentExist.price*100)/100;
                    } else if (!bill.fiscalPrinted && bill.settledFlag) {
                      detail.label = 'Saldo conto cliente €' + Math.round(currentExist.price*100)/100;
                    } else if (bill.type === 'movement' && !bill.fiscalPrinted) {
                      detail.label = 'Aggiunta voce "' + bill.name + '" €' + Math.round(currentExist.price*100)/100;
                    } else if (bill.type === 'movement' && bill.fiscalPrinted) {
                      detail.label = 'Aggiunta voce "' + bill.name + '" € ' + Math.round(currentExist.price*100)/100 + ' e stampata fiscalmente';
                    }
                  }
                }


                if (detail.label != '') {
                  obj.details.push(detail)
                }

              }
            }

            // TROVA CUSTOMER BILL ELIMINATI RISPETTO AL SALVATAGGIO PRECEDENTE

            if (prevReservation.order.customerBill) {

              for (const bill of prevReservation.order.customerBill) {

                let exist = false;

                if (selectedReservation.order.customerBill)
                  exist = selectedReservation.order.customerBill.find(bill2 => bill2.name === bill.name);

                let detail = {
                  label: ''
                }

                if (!exist) {
                  if (bill.fiscalPrinted && bill.settledFlag) {
                    detail.label = 'Stampa fiscale "' + bill.name + '" €' + bill.price;
                  } else if (!bill.fiscalPrinted && bill.settledFlag) {
                    detail.label = 'Saldo conto cliente €' + bill.price;
                  } else if (bill.type === 'movement' && !bill.fiscalPrinted) {
                    detail.label = 'Eliminata voce "' + bill.name + '" €' + bill.price;
                  } else if (bill.type === 'movement' && bill.fiscalPrinted) {
                    detail.label = 'Eliminata voce "' + bill.name + '" € ' + bill.price + ' e stampata fiscalmente';
                  }
                }

                if (detail.label != '') {
                  obj.details.push(detail)
                }

              }
            }

            // TROVA CAMBI NOME

            if (prevReservation.customers && selectedReservation.customers) {
              const oldMainCustomer = prevReservation.customers.find(cust => cust.principal === true);
              const currentMainCustomer = selectedReservation.customers.find(cust => cust.principal === true);

              if (oldMainCustomer && currentMainCustomer && oldMainCustomer.customer.id !== currentMainCustomer.customer.id) {
                const detail = {
                  label: 'Aggiornato nome principale: ' + currentMainCustomer.customer.name + ' ' + currentMainCustomer.customer.surname
                };

                obj.details.push(detail);
              }


              const oldNoMainCustomers = prevReservation.customers.filter(cust => cust.principal === false);
              const currentNoMainCustomers = selectedReservation.customers.filter(cust => cust.principal === false);

              for (const cust of oldNoMainCustomers) {
                let stillExist = currentNoMainCustomers.find(c => c.customer.id === cust.customer.id);
                if (!stillExist) {
                  let detail = {
                    label: 'Eliminato nome aggiuntivo: ' + cust.customer.name + ' ' + cust.customer.surname
                  };
                  obj.details.push(detail);
                }
              }

              for (const cust of currentNoMainCustomers) {
                let didntExist = oldNoMainCustomers.find(c => c.customer.id === cust.customer.id);
                if (!didntExist) {
                  let detail = {
                    label: 'Aggiunto nome aggiuntivo: ' + cust.customer.name + ' ' + cust.customer.surname
                  };
                  obj.details.push(detail);
                }
              }

            } else if (!prevReservation.customers && selectedReservation.customers && selectedReservation.customers.length) {
              const currentMainCustomer = selectedReservation.customers.find(cust => cust.principal === true);
              const currentNoMainCustomers = selectedReservation.customers.filter(cust => cust.principal === false);
              let detail = {
                label: 'Inserito nome principale: ' + currentMainCustomer.customer.name + ' ' + currentMainCustomer.customer.surname
              };

              obj.details.push(detail);
              for (let newCust of currentNoMainCustomers) {
                detail = {
                  label: 'Inserito nome aggiuntivo: ' + newCust.customer.name + ' ' + newCust.customer.surname
                };
              }

              obj.details.push(detail);
            } else if (!selectedReservation.customers && prevReservation.customers && prevReservation.customers.length) {
              const oldMainCustomer = prevReservation.customers.find(cust => cust.principal === true);
              const oldNoMainCustomers = prevReservation.customers.filter(cust => cust.principal === false);
              let detail = {
                label: 'Eliminato nome principale: ' + oldMainCustomer.customer.name + ' ' + oldMainCustomer.customer.surname
              };

              obj.details.push(detail);

              for (let newCust of oldNoMainCustomers) {
                detail = {
                  label: 'Eliminato nome aggiuntivo: ' + newCust.customer.name + ' ' + newCust.customer.surname
                };
                obj.details.push(detail);
              }

            }




            // CONTROLLA SE CAMBIO STAGIONALE

            if (selectedReservation.seasonTicket && !prevReservation.seasonTicket) {
              const detail = {
                label: 'Attivata prenotazione stagionale'
              };

              obj.details.push(detail);

            }

            // CONTROLLA AGGIUNTA RIMOZIONE CABINE

            if (selectedReservation.cabins) {

              for (let cabin of selectedReservation.cabins) {
                if (!prevReservation.cabins || prevReservation.cabins.indexOf(cabin) === -1) {
                  let findCabin = this.resortData.cabins.find(c => c.id === cabin);
                  const detail = {
                    label: 'Aggiunta cabina ' + findCabin.label[this.dataService.lang]
                  };

                  obj.details.push(detail);
                }
              }
            }

            if (prevReservation.cabins) {

              for (let cabin of prevReservation.cabins) {
                if (!selectedReservation.cabins || selectedReservation.cabins.indexOf(cabin) === -1) {
                  let findCabin = this.resortData.cabins.find(c => c.id === cabin);
                  const detail = {
                    label: 'Rimossa cabina ' + findCabin.label[this.dataService.lang]
                  };

                  obj.details.push(detail);
                }
              }
            }



            if (selectedReservation.cabins && !prevReservation.cabins) {
              const detail = {
                label: 'Attivata prenotazione stagionale'
              };

              obj.details.push(detail);

            }

            if (!selectedReservation.seasonTicket && prevReservation.seasonTicket) {
              const detail = {
                label: 'Disattivata prenotazione stagionale'
              };

              obj.details.push(detail);

            }

            // CONTROLLA SE E' STATA RIMBORSATA

            if (selectedReservation.refundState !== prevReservation.refundState) {

              let detail = {
                label: ''
              };

              if (selectedReservation.refundState === 2) {
                detail.label = 'Prenotazione cancellata e rimborsata';
              }

              if (detail.label !== '') {
                obj.details.push(detail);
              }
            }

          }

          obj.details.reverse();
          if (obj.details.length){
            prevReservation = rtdbData[ts];
            let length = 2;
            if (obj.details.length < length) {
              length = obj.details.length;
            }

            for (let i = 0; i<length; i++) {
              obj.shortDetails.push(obj.details[i]);
            }

            this.logs.push(obj);
          }
        }

      }
    }

    // VERIFICA CHECKIN CHECKOUT

    for (const interval of this.reservation.dates) {
      for (const slot of interval.order) {

        if (slot.checkin) {
          for (const checkin of slot.checkin) {
            let findLog = this.logs.find(l => l.date === checkin.createdAt);
            let label = 'Check-in effettuato sulla postazione ' + slot.coords + ' per il '+ this.settingsService.getStringDateFromTimestamp(checkin.start)
            if (checkin.start !== checkin.end) {
              label = 'Check-in effettuato sulla postazione ' + slot.coords + ' dal '+ this.settingsService.getStringDateFromTimestamp(checkin.start) + ' al ' + this.settingsService.getStringDateFromTimestamp(checkin.end)
            }
            if (findLog) {
              findLog.details.push({
                label
              })
            } else {
              const obj = {
                date: parseInt(checkin.createdAt),
                operation: 'slot',
                author: checkin.createdBy,
                details: [{label}],
                shortDetails: [{label}],
                reservation: null,
                showDetails: false
              };

              this.logs.push(obj);
            }
          }
        }

        if (slot.checkout) {

          for (const checkout of slot.checkout) {
            let findLog = this.logs.find(l => l.date === checkout.createdAt);
            let label = 'Check-out effettuato sulla postazione ' + slot.coords + ' per il '+ this.settingsService.getStringDateFromTimestamp(checkout.start)
            if (checkout.start !== checkout.end) {
              label = 'Check-out effettuato sulla postazione ' + slot.coords + ' dal '+ this.settingsService.getStringDateFromTimestamp(checkout.start) + ' al ' + this.settingsService.getStringDateFromTimestamp(checkout.end)
            }
            if (findLog) {
              findLog.details.push({
                label
              })
            } else {
              const obj = {
                date: parseInt(checkout.createdAt),
                operation: 'slot',
                author: checkout.createdBy,
                details: [{label}],
                shortDetails: [{label}],
                reservation: null,
                showDetails: false
              };

              this.logs.push(obj);
            }
          }
        }

        if (slot.free) {
          for (const free of slot.free) {
            let findLog = this.logs.find(l => l.date === free.createdAt);
            let label = 'Liberata postazione ' + slot.coords + ' per il '+ this.settingsService.getStringDateFromTimestamp(free.start)
            if (free.start !== free.end) {
              label = 'Liberata postazione ' + slot.coords + ' dal '+ this.settingsService.getStringDateFromTimestamp(free.start) + ' al ' + this.settingsService.getStringDateFromTimestamp(free.end)
            }
            if (findLog) {
              findLog.details.push({
                label
              })
            } else {
              const obj = {
                date: parseInt(free.createdAt),
                operation: 'slot',
                author: free.createdBy,
                details: [{label}],
                shortDetails: [{label}],
                reservation: null,
                showDetails: false
              };

              this.logs.push(obj);
            }
          }
        }
      }
    }

    this.logs.reverse();

    // SE NUOVA PRENOTAZIONE

    if (this.logs.length === 0) {
      console.log(this.reservation.createdAt)
      this.logs.push({
        date: parseInt(this.reservation.createdAt),
        operation: 'create',
        author: this.reservation.createdBy,
        details: [{
          label: this.translateService.instant('reservationCreated')
        }],
        reservation: null,
        shortDetails: [{ label: this.translateService.instant('reservationCreated')}],
        tooltip: false
      });

      for (const product of this.reservation.order.products) {

        if (product.type !== 'reserved-extra' && product.type !== 'reserved-discount' && product.type !== 'discount') {

          if (product.createdAt <= this.reservation.createdAt) {
            product.createdAt = this.reservation.createdAt + 1;
          }

          /* let findLog = this.logs.find(act => act.operation === 'update' && act.date === product.createdAt);


          if (!findLog) {
            findLog = this.logs.find(log => log.operation === 'create' && log.date === product.createdAt);
          }

          // console.log(findLog); */

          let label = 'Aggiunto ' + product.quantity + ' x ' + product.name[this.lang];

          if (product.type === 'package') {
            label = 'Aggiunto o spostato ' + product.quantity + ' x ' + product.name[this.lang];
          }

          /* if (findLog) { */
            this.logs[0].details.push({label});
          /* } else {

            this.logs.push({
              date: product.updatedAt,
              operation: 'update',
              author: product.updatedBy,
              details: [{
                label
              }],
              reservation: null,
              tooltip: false,
              shortDetails: [{label}],
            });
          } */
        }

      }

      for (const bill of this.reservation.order.customerBill) {

        /* let findLog = this.logs.find(log => log.operation === 'update' && log.date === bill.date);

        if (!findLog) {
          findLog = this.logs.find(log => log.operation === 'create' && log.date === bill.date);
        } */
        let label = '';

        if (bill.fiscalPrinted && bill.settledFlag) {
          label = 'Stampa fiscale "' + bill.name + '" €' + bill.price;
        } else if (!bill.fiscalPrinted && bill.settledFlag) {
          label = 'Saldo conto cliente €' + bill.price;
        } else if (bill.type === 'movement' && !bill.fiscalPrinted) {
          label = 'Aggiunta voce "' + bill.name + '" €' + bill.price;
        } else if (bill.type === 'movement' && bill.fiscalPrinted) {
          label = 'Aggiunta voce "' + bill.name + '" € ' + bill.price + ' e stampata fiscalmente';
        }

        if (label != '') {
          /* if (findLog) { */
            this.logs[0].details.push({label});
          /* } else {
            // console.log(bill.date);
            // console.log(this.reservation.createdAt)
            let author = bill.createdBy;
            if (bill.date === this.reservation.createdAt && this.reservation.cocoBooking && !bill.createdBy) {
              author = this.reservation.createdBy;
            }
            if (bill.date <= this.reservation.createdAt) {
              bill.date = this.reservation.createdAt + 2;
            }

            this.logs.push({
              date: bill.date,
              operation: 'update',
              details: [{label}],
              author,
              shortDetails: [{label}],
              reservation: null,
              tooltip: false
            });
          } */
        }

      }

      let creationLog = this.logs.find(o => o.operation === 'create');

      if (creationLog) {
        creationLog.date = creationLog.date-1;
      }


    }

    this.logs.sort(function(a, b) {
        const keyA = new Date(a.date);
        const keyB = new Date(b.date);
        // Compare the 2 dates
        if (keyA > keyB) { return -1; }
        if (keyA < keyB) { return 1; }
        return 0;
    });

    this.shortLogs = this.logs.slice(0, 3);

    // console.log(this.logs);
  }



  openLogModal(log, index) {

    const dialogRef = this.dialog.open(ModalLogsComponent, {
      width: '360px',
      data: {
        log: JSON.parse(JSON.stringify(log)),
        index,
        logs: this.logs
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          //window.location.reload();
          for (const extra of this.resortData.extras) {
              extra.quantity = 0;
          }

          for (const discount of this.resortData.discounts) {
            discount.checked = false;
          }
          // console.log(JSON.parse(JSON.stringify(result)));
          this.reservation = result;
          this.setReservation();
          let logSub = this.db.object('logs/reservations/' + this.resortData.id + '/' + this.reservation.reservationCode).valueChanges().subscribe(data => {
            this.processLogs(data);
            logSub.unsubscribe();
          });
          // console.log(JSON.parse(JSON.stringify(this.reservation)));
        }

    })
  }

  setReservation() {

  }

  openLogsModal() {

      const dialogRef = this.dialog.open(ModalLogsComponent, {
        width: '400px',
        data: {
          logs: this.logs
        }
      });

      dialogRef.afterClosed().subscribe(async result => {
          if (result) {
          }
      })
  }




}
