import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service'
import { Subject } from 'rxjs/Subject';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import 'rxjs/add/operator/debounceTime';
import { ReservationService } from '../../../core/providers/reservation.service';
import 'rxjs/add/operator/distinctUntilChanged';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

   // RESORTS DATA
   resortData; managerData; reservations = null; optionals;optionalsByIds ={};
   typeCocoManager=true; typeCocobuk=true;typeSeasonal=true;

   // FILTERS VARIABLES
   term$ = new Subject<string>();
   selectedPackage = null; selectedSettlement = null; selectedPaymentType = null;
   selectedOptionals = null; selectedStatus = null; onlyArrivals = null;
   selectedType = null; selectedToday = null; onlyDepartures = null;
   selectedState = null; selectedNoSlots = null; selectedZone = null;
   selectedFilter = null; searchString = ''; selectedFiscalPrint = null; size = null;
   selectedFiscalPrinter = null; selectedManager = null;

   // DATE VARIABLES
   startTimestamp; endTimestamp;
   startDate; startDay; startMonth; startYear;
   endDate; endDay; endMonth; endYear;
   startSerializedDate; endSerializedDate; managers = null;

   // GLOBAL VARIABLES
   loader = true; nextPage; prevPage; totalReservations = 0; pageSize = 25; page = 1;
   objectKeys = Object.keys;lang='it';totalAmount = 0; pageClicked = false;
   overlayLoader = false; percentage = 0;

   constructor(public router: Router, private reservationService: ReservationService, private dataService: DataService, private settingsService: SettingsService) {

     // SETUP DATES
     this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
     this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

     const startDate = new Date(this.startTimestamp);
     startDate.setUTCHours(0, 0, 0, 0);
     this.startSerializedDate = startDate;

     const endDate = new Date(this.endTimestamp);
     endDate.setUTCHours(0, 0, 0, 0);
     this.endSerializedDate = endDate;

     // SETUP DATA
     this.resortData = this.dataService.resortData;
     this.managerData = this.dataService.userData;
     this.lang = this.dataService.lang;

     // SEARCH STRING

     this.term$
         .debounceTime(1000)
         .distinctUntilChanged()
         .subscribe(term => {
           this.searchString = term;

           if (term === '') {
             this.searchString = null;
           }

           this.page = 1;

           this.getReservations();
         });

   }

   async ngOnInit() {

    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.managers = await this.dataService.getResortManagers();
    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;

    console.log(this.managers);

    for (const opt of this.optionals) {
      this.optionalsByIds[opt.id] = opt;
    }
    console.log(this.optionals);

    // GET RESERVATIONS FOR TODAY

    await this.getReservations();

   }

   openReservation(url) {
      this.dataService.previousPage = 'payments';
      this.router.navigateByUrl(url);
   }

   async changePage(url) {
     this.loader = true;
     const data = await this.dataService.getReservationsByUrl(url);
     this.nextPage = data.next;
     this.prevPage = data.previous;
     this.reservations= this.processReservations(data.results);
     this.totalReservations = data.count;
     this.loader = false;
   }

   resetFilters() {
     this.selectedFilter = null;
     this.searchString = null;
     this.page = 1;
     this.selectedFiscalPrint = null;
     this.selectedPaymentType = null;
     this.selectedFiscalPrinter = null;
     this.selectedManager = null;
     this.typeCocoManager = true;
     this.typeCocobuk = true;

     this.getReservations();
   }

   async getReservations() {

     this.loader = true;
     const start = this.startTimestamp / 1000;
     const end = (this.endTimestamp / 1000) + 24*60*60 -1;

     console.log("START: " + start);
     console.log("END: " + end);

/* 
     let now = new Date();
     let offset = now.getTimezoneOffset() * 60;

     const startBooking = start+offset;
     const endBooking = end+offset;


     console.log("START BOOKING: " + startBooking);
     console.log("END BOOKING: " + endBooking); */

     let typeCocobuk = null;
     let cocoManagerOrBooking = null;

     if (this.typeCocoManager && !this.typeCocobuk) {
        typeCocobuk = false;
     } else if (this.typeCocoManager && this.typeCocobuk) {
        cocoManagerOrBooking = true;
     } else if (!this.typeCocoManager && this.typeCocobuk) {
        typeCocobuk = true;
     }

     if (this.typeCocoManager !== false || this.typeCocobuk !== false) {

        const data = await this.dataService.getPayments(this.selectedManager, start, end, this.onlyArrivals, this.onlyDepartures, this.searchString, null, cocoManagerOrBooking, null, typeCocobuk, null, this.selectedZone, this.selectedPackage, this.selectedOptionals, this.selectedToday, null, null, null, null, this.selectedFiscalPrint, this.selectedPaymentType, this.selectedFiscalPrinter, this.size, 1, true );

        console.log(data.results);

        this.reservations = this.processReservations(data.results);
        this.nextPage = data.next;
        this.prevPage = data.previous;
        this.totalReservations = data.count;
        this.loader = false;

        this.totalAmount = await this.dataService.getTotalPaymentsAmount(this.selectedManager, start, end, this.onlyArrivals, this.onlyDepartures, this.searchString, null, cocoManagerOrBooking, null, typeCocobuk, null, this.selectedZone, this.selectedPackage, this.selectedOptionals, this.selectedToday, null, null, null, null, this.selectedFiscalPrint, this.selectedPaymentType, this.selectedFiscalPrinter, this.size, 1, true );

        console.log(this.reservations);
      } else {
        this.reservations = [];
        this.totalReservations = 0;
      }

     this.loader = false;
   }

   processReservations(data) {
     let output = [];

     let reservationsIds = [];

     for (const reservation of data) {
          let name = '';
          let surname = '';
          let associated = [];

          for (const customer of reservation.customers) {
            if (customer.principal) {
              name = customer.customer.name;
              surname = customer.customer.surname;
            } else {
              associated.push({
                name: customer.customer.name,
                surname: customer.customer.surname
              });
            }
          }


        let obj = {
          noSlots: reservation.noSlots,
          name: name  + ' ' + surname,
          code: reservation.reservationCode,
          order: reservation.order,
          start: reservation.start,
          end: reservation.end,
          amount: 0,
          bookingInfo: reservation.bookingInfo,
          id: reservation.id,
          seasonTicket: reservation.seasonTicket,
          cocoBooking: reservation.cocoBooking,
          paymentDate: reservation.start,
          fiscalPrinted: false,
          createdBy: null,
          printedBy: null,
          paymentType: 'cash',
          associated,
          label: '',
          dates: reservation.dates,
          extras: reservation.extras
        };

        if (reservation.order.customerBill) {

          for (const billObj of reservation.order.customerBill) {

            if (billObj.payed && billObj.date * 1000 >= this.startTimestamp && billObj.date * 1000 <= (this.endTimestamp + (24 * 60 * 60 * 1000))) {
                obj.paymentDate = billObj.date;
                obj.fiscalPrinted = billObj.fiscalPrinted;
                obj.paymentType = billObj.paymentType;
                obj.label = billObj.name;
                obj.amount = billObj.price;
                obj.createdBy = billObj.createdBy;
                obj.printedBy = billObj.printedBy;

                const checkFiscalPrint = (this.selectedFiscalPrint == null || (this.selectedFiscalPrint != null && billObj.fiscalPrinted== this.selectedFiscalPrint));

                const checkPrinter = (this.selectedFiscalPrinter == null || (this.selectedFiscalPrinter != null && this.selectedFiscalPrinter == billObj.fiscalPrinter))

                const checkPaymentType = (this.selectedPaymentType == null || (this.selectedPaymentType != null && this.selectedPaymentType == billObj.paymentType));

                if  (checkPaymentType && checkFiscalPrint && checkPrinter) {
                  output.push(JSON.parse(JSON.stringify(obj)));
                }
            }
          }
        }

     }

     return output;
   }

   async exportPDF() {

    this.overlayLoader = true;
    this.percentage = 0;

    let reservationsToSave = [];

    const offset = new Date().getTimezoneOffset() * 60000;
    const start = (this.startTimestamp + offset) / 1000;
    const end = (this.endTimestamp + offset) / 1000 + 24*60*60 - 1;

    console.log(this.totalReservations)

    for (let i = 0; i < this.totalReservations; i += 500) {
      this.size = 500;

      if ((i + 500) >= this.totalReservations) {
        console.log(i)
        this.size = this.totalReservations % (i+500);
      }

      this.page = i/500 + 1;

      console.log(this.size);

      let typeCocobuk = null;
      let typeSeasonal = null;
      let seasonOrBooking = null;
      let cocoManagerOrSeason = null;
      let cocoManagerOrBooking = null;

      if (this.typeCocoManager && !this.typeCocobuk && this.typeSeasonal) {
          cocoManagerOrSeason = true;
      } else if (this.typeCocoManager &&  this.typeCocobuk && !this.typeSeasonal) {
          cocoManagerOrBooking = true;
      } else if (this.typeCocoManager && !this.typeCocobuk && !this.typeSeasonal) {
          typeCocobuk = false;
          typeSeasonal = false;
      } else if (!this.typeCocoManager && this.typeCocobuk && this.typeSeasonal) {
          seasonOrBooking = true;
      } else if (!this.typeCocoManager && !this.typeCocobuk && this.typeSeasonal) {
          typeSeasonal = true;
      } else if (!this.typeCocoManager && this.typeCocobuk && !this.typeSeasonal) {
          typeCocobuk = true;
      }


      const data = await this.dataService.getPayments(this.selectedManager, start, end, this.onlyArrivals, this.onlyDepartures, this.searchString, cocoManagerOrSeason, cocoManagerOrBooking, seasonOrBooking, typeCocobuk, typeSeasonal, this.selectedZone, this.selectedPackage, this.selectedOptionals, this.selectedToday, null, null, null, null, this.selectedFiscalPrint, this.selectedPaymentType, this.selectedFiscalPrinter, this.size, 1, true );

      const partialReservations = this.processReservations(data.results);
      reservationsToSave = reservationsToSave.concat(partialReservations);
    }

    console.log(reservationsToSave);

    const filters = {
      fiscalPrinted: this.selectedFiscalPrint,
      paymentType: this.selectedPaymentType,
      searchString: this.searchString
    };

    this.reservationService.exportPaymentsPDF(reservationsToSave,this.startTimestamp,this.endTimestamp, filters);

    this.size = null;
    this.overlayLoader = false;

  }

   async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

     let now = new Date();
     let offset = now.getTimezoneOffset() * 60000;

     if (!this.settingsService.isDstObserved(now))
     {
       offset = (now.getTimezoneOffset() - 60) * 60000;
     }

     let startTS = (new Date(event.value)).getTime() - offset;
     let startDate = new Date(startTS);

     this.startDate = startDate.getDate();
     this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
     this.startSerializedDate = new Date(startTS);

     if (this.endTimestamp < this.startTimestamp)
       this.endTimestamp = this.startTimestamp;

     let endDate = new Date(this.endTimestamp);
     this.endDate = endDate.getDate();
     this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
     this.endSerializedDate = new Date(this.endTimestamp);

     let setReservations = await this.getReservations()

   }

   async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

     this.loader = true;

     let now = new Date();
     let offset = now.getTimezoneOffset() * 60000;

     if (!this.settingsService.isDstObserved(now))
     {
       offset = (now.getTimezoneOffset() - 60) * 60000;
     }

     let endTS = (new Date(event.value)).getTime() - offset;
     let endDate = new Date(endTS);

     this.endDate = endDate.getDate();
     this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
     this.endSerializedDate = new Date(endTS);

     if (this.endTimestamp < this.startTimestamp)
       this.startTimestamp = this.endTimestamp;

     let startDate = new Date(this.startTimestamp);
     this.startDate = startDate.getDate();
     this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
     this.startSerializedDate = new Date(this.startTimestamp);

     await this.getReservations()
   }

   async setTodayDate() {
     this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
     this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

     let startDate = new Date(this.startTimestamp);
     startDate.setUTCHours(0, 0, 0, 0);
     this.startDate = startDate.getDate();

     let endDate = new Date(this.endTimestamp);
     endDate.setUTCHours(0, 0, 0, 0);
     this.endDate = endDate.getDate();

     this.startSerializedDate = new Date(this.startTimestamp);
     this.endSerializedDate = new Date(this.endTimestamp);

     await this.getReservations()

   }

   async exportXLS() {

     let outputObjs = [];

     this.size = this.totalReservations;

     this.overlayLoader = true;
     this.percentage = 0;

     this.size = this.totalReservations;
     let reservationsToSave = [];

     for (let i=0; i<this.totalReservations;i+=500){
       this.size = 500;

       if (i>0 && (i+500) >= this.totalReservations) {
        this.size = this.totalReservations%i;
       }

       this.page = i/500 + 1;

       await this.getReservations();
       reservationsToSave = reservationsToSave.concat(this.reservations);
     }

     reservationsToSave.map(reservation => {

        let fiscalPrinted = 'NO';

        if (reservation.fiscalPrinted) {
          fiscalPrinted = 'SI';
        }

        let paymentType = 'contanti';

        if (reservation.paymentType == 'cash') {
          paymentType = 'Contanti';
        } else if (reservation.paymentType == 'cheque') {
          paymentType = 'Assegno';
        } else if (reservation.paymentType == 'credit-card') {
          paymentType = 'Carta di credito';
        } else if (reservation.paymentType == 'transfer') {
          paymentType = 'Bonifico';
        } else if (reservation.paymentType == 'coupon') {
          paymentType = 'Coupon';
        } else if (reservation.paymentType == 'forex') {
          paymentType = 'Valuta straniera';
        }

        let obj = {
          id: reservation.id,
          nome: reservation.name,
          inizio: new Date(reservation.start*1000),
          fine: new Date(reservation.end*1000),
          data_incasso: new Date(reservation.paymentDate*1000),
          valore_incasso: reservation.amount,
          tipo_pagamento: paymentType,
          stampa_fiscale: fiscalPrinted,
          tipo: ''
        };

        if (reservation.cocoBooking) {
          obj.tipo = 'COCO BOOKING';
        } else {
          obj.tipo = 'MANAGER';
        }

        outputObjs.push(obj);

     });

     const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(outputObjs);
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     var startDate = new Date(this.startTimestamp);
     var endDate = new Date(this.endTimestamp);

     var startDay = startDate.getDate();
     var startMonth = startDate.getMonth() + 1;

     var endDay = endDate.getDate();
     var endMonth = endDate.getMonth() + 1;

     XLSX.writeFile(wb, 'Prenotazioni_' + startDay + '-' + startMonth + '_' + endDay + '-' + endMonth + '.xlsx');

     this.size = null;
   }

   resetHeader() {
     this.pageClicked = true;
     setTimeout(()=>{
       this.pageClicked = false;
     },1000)
   }

}
