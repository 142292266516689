import { Component, OnInit, HostListener, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';
import { DataService, Customer } from '../../../core/providers/data.service';
import { SettingsService } from '../../../core/providers/settings.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { MatDialog} from '@angular/material';
import { ModalErrorComponent } from '../../../shared/components/modal-error/modal-error.component';
import { Observable } from 'rxjs';
import { ModalPrintersComponent } from '../../../shared/components/modal-printers/modal-printers.component';
import { DialogCustomersComponent } from '../../../shared/components/dialog-customers/dialog-customers.component';
import { ModalReserveComponent } from '../../../shared/components/modal-reserve/modal-reserve.component';
import { ModalLicenseRenewalComponent } from '../../../shared/components/modal-license-renewal/modal-license-renewal.component';
import { ModalBroadcastComponent } from 'src/app/shared/components/modal-broadcast/modal-broadcast.component';
import { AuthService } from 'src/app/core/providers/auth.service';
import {ScrollingModule} from '@angular/cdk/scrolling'
import { interval } from 'rxjs';
import { bufferCount } from 'rxjs/operators';
import { ReservationSmartComponent } from '../../components/reservation-smart/reservation-smart.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  objectKeys = Object.keys; resetMapFlag = false;
  packagesOrder = [];
  tiles= {};

  // GLOBAL VARIABLES
  loading = false; startTimestamp; endTimestamp; startSerializedDate; endSerializedDate; showBottomBar = false; showRightBar = false; data; customers; reservations; lightLoading = false; packagesFiltered = false;  peopleCurrentlyPresent = 0; peopleTotalPresent = 0;
  startDate; endDate; loader; lang = 'it'; pageClicked = false; selectedReservation; selectedReservations; selectedSlot; selectedSlots = []; searchString;
  showQuickReservation = false; reserveFlag = true; optionals; searchTerm$ = new Subject<string>(); term$ = new Subject<string>(); selectedIndex; showPackageModal;
  availablePackages; selectedPackage; selectedPackageIndex; packagesAvailabilities; mapSubscription; associatedNames = ''; name; moveMode = false; lastSlot = null;
  newLoad = false; unsettledReservations; unsettledShown = false; paymentType = 'cash'; clicked = false; stockData; customersByIds = {};
  offsetRows = 0; offsetColStart = 0; offsetColEnd; maxCols = 50; maxRows = 50; otherCustomers; customerTerm$ = new Subject<string>();
  mapAreas; defaultMapArea = 1;availableTimeslots=[];enableDailyTimeslot=false;
  timeSlots:any[] = []; selectedTimeslot:any = 0; selectedTimeslotQuickReservation:any = 0;availabilitiesByPack;

  // RESORTS DATA
  resortData; managerData; mapData; reservationMapData; prices; elements; features; services; elementsByIds = {}; selectedArea = 0;packagesNoSlots=[];

  // MAP VARIABLES
  startMapWidth; startMapHeight; mapHeight; mapWidth; zoomStep = 1; mapByRowCol; settled = false;
  slotHeight = 50; slotSpace = 15; startMarginBottomElement = 15; startPaddingLeftRight = 50;
  startOffset = 25; startPaddingTop = 150; startPaddingBottom = 100; startMinWidth = 700; startMinHeight = 700;
  startHeight = 65; startImageHeight = 30; startCircleHeight = 40; startElementHeight = 40; optionalsByIds = {};  optionalsByKeys = {};
  startSlotLabel = 12; startMarginElement = 5; customersFound; lastMapUpdate: Observable<any>;mapLayoutMode='icons'

  // MAP VARIABLES
  grid = [];warningText1=false;warningText2=false;broadcastSubscription;

  // RESERVATION
  newReservation; reservationTotal; today;

  @ViewChild('slotsContainer', { read: ViewContainerRef, static:false }) container: ViewContainerRef | null = null;
  @ViewChild('slot', { read: TemplateRef, static:false }) template: TemplateRef<any> | null = null;
  @ViewChild('emptySlot', { read: TemplateRef, static:false }) emptyTemplate: TemplateRef<any> | null = null;

  constructor(public dialog: MatDialog,  public router: Router, public dataService: DataService, private settingsService: SettingsService, private db: AngularFireDatabase, public authService: AuthService) {

      // SETUP DATES

      if (!this.dataService.startTimestamp) {
        this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
        this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
        this.dataService.startTimestamp = this.startTimestamp;
        this.dataService.endTimestamp = this.endTimestamp;
      } else {
        this.startTimestamp = this.dataService.startTimestamp;
        this.endTimestamp = this.dataService.endTimestamp;
      }

      this.dataService.selectedReservation = null;
      this.dataService.associatedCustomers = null;
      this.dataService.mainCustomer = null;
      this.dataService.lastCustomersQuery = null;
      this.dataService.lastReservationsQuery = null;

      const startDate = new Date(this.startTimestamp);
      startDate.setUTCHours(0, 0, 0, 0);
      this.startSerializedDate = startDate;

      const endDate = new Date(this.endTimestamp);
      endDate.setUTCHours(0, 0, 0, 0);
      this.endSerializedDate = endDate;

      // SETUP DATA
      this.mapData = this.dataService.map;

      this.reservationMapData = this.dataService.mapReservations;
      this.resortData = this.dataService.resortData;
      this.mapAreas = this.dataService.mapAreas;
      this.today = new Date().setUTCHours(0,0,0,0);
  }

  ngOnDestroy() {
    if (this.mapSubscription) {
      this.mapSubscription.unsubscribe();
    }


    if (this.broadcastSubscription) {
      this.broadcastSubscription.unsubscribe();
    }
  }



  async ngOnInit() {

    this.loading = true;

    let now = new Date().getTime();

    if (!this.mapData || !this.reservationMapData) {
      this.newLoad = true;
    }

    if (!this.resortData) {
      this.data = await this.dataService.initResortData();
    }

    if (!this.dataService.mapAreas) {
      this.mapAreas = (await this.dataService.getMapAreas()).results;
    }

    
    if ((this.authService.subscription.end*1000 - now) < 2592000000) {
      let nextNotPaidSubscription = this.authService.subscriptions.find(s => s.active === false && s.start > this.authService.subscription.start);
      console.log("LICENZA NON PAGATA", nextNotPaidSubscription);

      if (!nextNotPaidSubscription) {
        nextNotPaidSubscription = await this.dataService.pushUnpaidSubscription(this.authService.subscription);
      }

      const dialogRef = this.dialog.open(ModalLicenseRenewalComponent, {
        width: '800px',
        data: {
          resort: this.resortData,
          nextNotPaidSubscription
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      })

    }

    this.mapAreas.sort(this.sortByCol);


    if (!this.dataService.mapArea) {
      this.defaultMapArea = this.dataService.userData.defaultMapArea;
    } else {
      this.defaultMapArea = this.dataService.mapArea;
    }


    if (this.defaultMapArea) {
      const selectedArea = this.mapAreas.find(area => area.id === this.defaultMapArea);
      console.log(selectedArea);
      this.selectedArea = selectedArea;
      const filteredAreas = [];
      for (const mapArea of this.mapAreas) {
        if (this.dataService.userData.mapAreas && this.dataService.userData.mapAreas.indexOf(mapArea.id) > -1) {
          filteredAreas.push(mapArea);
        } else if (!this.dataService.userData.mapAreas) {
          filteredAreas.push(mapArea);
        }
      }
      this.mapAreas = filteredAreas;
      this.offsetColStart = selectedArea.startColumn;
      this.offsetColEnd = selectedArea.endColumn;
    } else {
      this.offsetColStart = 0;
      this.offsetColEnd = this.dataService.resortData.cols;
    }

    if (this.reservationMapData) {
      this.zoomStep = this.resortData.zoom;
      this.startMapWidth = (this.offsetColEnd * 50 + (this.offsetColEnd - 1) * 15);
      this.startMapHeight = (this.resortData.rows * 50 + (this.resortData.rows - 1) * 15);
      this.setupGrid();
      this.loading = false;
    }


    this.reservationMapData = await this.dataService.getMapReservationsOptimized(this.startTimestamp / 1000, this.endTimestamp / 1000);
    
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.mapData = this.dataService.map;
    this.mapByRowCol = this.dataService.mapByRowCol;
    this.prices = this.dataService.prices;
    this.optionals = this.dataService.optionals;
    this.elements = this.dataService.elements;
    this.features = this.dataService.features;
    this.services = this.dataService.services;

    
    console.log(JSON.stringify(this.mapData));
    

    if (this.managerData) {
      this.mapLayoutMode = this.managerData.mapLayoutMode;
    }

    if (this.optionals) {
      this.optionals.map(opt => {
        this.optionalsByIds[opt.id] = opt;
        this.optionalsByKeys[opt.optionalId] = opt;
      });
    }

    for (const element of this.elements) {
      this.elementsByIds[element.id] = element;
    }

    this.mapSubscription = this.db.object('realtimeMap/' + this.resortData.id).valueChanges().subscribe(data => {
      console.log(data);
      this.updateMap(data);
      this.getDiscountedReservations();
    });


    if (this.newLoad) {
      this.zoomStep = this.resortData.zoom;
      this.startMapWidth = (this.offsetColEnd * 50 + (this.offsetColEnd - 1) * 15);
      this.startMapHeight = (this.resortData.rows * 50 + (this.resortData.rows - 1) * 15);
      this.setupGrid();
    }

    this.packagesNoSlots = this.resortData.packages.filter((p) => {
      if (p.type === 'order') {
        return p;
      }
    })

    for (let p of this.packagesNoSlots) {
      let cover = p.elements.find(e => e.element.cover === true);
      if (cover) {
        p.cover = cover.element.id;
      } else {
        p.cover = p.elements[0].element.id;
      }
    }


    this.getNoMapAvailabilities();

    const self = this;

    this.searchTerm$
      .debounceTime(300)
      .distinctUntilChanged()
      .subscribe(term => {

        if (term === '') {
          this.searchString = null;
        }

        if (this.searchString) {
          this.getCustomersFromSearch();
          this.getReservationsFromSearch();
        } else {
          this.customers = null;
          this.customersByIds = {};
          this.reservations = null;
        }
      });

    this.term$
        .debounceTime(500)
        .distinctUntilChanged()
        .subscribe(async term => {
          self.customersFound = [];

          if ( term !== '') {
            self.getCustomers(term);
          }
        });

    this.customerTerm$
      .debounceTime(500)
      .distinctUntilChanged()
      .subscribe(term => {

        if (term !== '' && this.newReservation.name !== '' && this.newReservation.surname !== '') {
          this.getDuplicateCustomers();
        } else {
          this.otherCustomers = [];
        }
      });

    this.broadcastSubscription = this.db.object('resorts_messages/broadcast/settings').valueChanges().subscribe(broadcastData => {
      if (broadcastData['recipients']['manager'] && broadcastData['enabled']) {

        let userSub =  this.db.object('resorts_messages/broadcast/receivers/'+this.managerData.id).valueChanges().subscribe(managerBroadcast => {
          userSub.unsubscribe();
          if (!managerBroadcast || managerBroadcast['readAt'] < broadcastData['createdAt']) {

              const dialogRef = this.dialog.open(ModalBroadcastComponent, {
                width: '800px',
                data: broadcastData
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                }
              })
          }


        })
      }
    });


    this.getAndCheckTimeslots();
    this.setTiles();
    setTimeout(async ()=>{
      await this.getDiscountedReservations();
    },1000)
  }


  async getDiscountedReservations() {
    let slotsIds = [];
    for (let row in this.reservationMapData) {
      for (let col in this.reservationMapData[row]) {
        this.reservationMapData[row][col].discountMapColor = null;
        if (this.reservationMapData[row][col].reservationsKeys.length === 1) {
          slotsIds.push(row+'_'+col);
        }
      }
    }

    if (slotsIds.length) {

      let discountsIds = Object.keys(this.resortData.discountsByIds);
      if (discountsIds.length) {
        let discountedReservations = await this.dataService.getReservations(this.startTimestamp / 1000, this.endTimestamp / 1000, null, null, null, null, null, null, null, null, null, null, null,slotsIds.join(), null, 300, 1, true, null, discountsIds.join(), null, 'start' ,false, null, null, null,null,null,null);
        for (let res of discountedReservations.results) {
          let selectedDiscount = res.discounts.find((d:any)=>d.type === 'discount');
          let findInterval = res.dates.find(interval => interval.start <= this.startTimestamp/1000 && interval.end >= this.startTimestamp/1000);
          if (findInterval && selectedDiscount) {
            for (let slot of findInterval.order) {
              if (this.resortData.discountsByIds[selectedDiscount.id].info && this.resortData.discountsByIds[selectedDiscount.id].info.mapColor)
                this.reservationMapData[slot.row][slot.col].discountMapColor = this.resortData.discountsByIds[selectedDiscount.id].info.mapColor;
            }
          }
        }
      }

    }
  }

  setTiles() {

    this.packagesOrder = this.resortData.packages.filter(p=> { return (p.type === 'order')?true:false;  });

    for (let p of this.packagesOrder) {
      let cover = p.elements.find(e => e.element.cover === true);
      if (cover) {
        p.cover = cover.element.id;
      } else {
        p.cover = p.elements[0].element.id;
      }
    }
    this.tiles = {};
    for (let pack of this.packagesOrder) {
      if (pack.settings && pack.settings.mapTile) {
        console.log(pack.id);
        for (let zone of pack.settings.mapTile.zones) {
          this.tiles[zone.row+'_'+zone.col] ={
            zoneId:zone.zoneId,
            row: zone.row,
            col: zone.col,
            packageId: pack.id,
            cover: this.resortData.packagesByIds[pack.id].cover
          }
        }
      }
    }

  }

  async getNoMapAvailabilities() {
    const dbAvailabilities = await this.dataService.getAvailabilitiesV2(this.startTimestamp/1000, this.endTimestamp/1000);
    this.availabilitiesByPack = this.alignAvailabilityData(dbAvailabilities);
  }

  alignAvailabilityData(dbAvailabilities) {
    let availabilitiesByPack = {};
    let allAvailabilities = [];
    allAvailabilities = dbAvailabilities.results.filter( a => {
      if (a.day_timestamp <= this.endTimestamp/1000 && a.day_timestamp >= this.startTimestamp/1000) return a;
    })

    for (let ts = this.startTimestamp/1000; ts <= this.endTimestamp/1000; ts+=24*60*60) {
      for (let pack of this.packagesNoSlots) {

        for (let zone of this.resortData.zones) {
          let findAva =allAvailabilities.filter(a => {if (a.day_timestamp === ts && a.zoneId === zone.id  && a.packageId === pack.id && !a.timeSlotId) return a})

          if (!findAva.length) {
           allAvailabilities.push({
              availability: 0,
              day_timestamp: ts,
              packageId: pack.id,
              zoneId: zone.id,
              id: null,
              timeSlotId: null,
            })
          }

          let length = (this.endTimestamp/1000 - this.startTimestamp/1000)/(24*60*60) +1;
          let seasons = this.dataService.getSeasonsInReservation(this.startTimestamp/1000, length , null);

          for (let seasonKey in seasons) {

            let timeslots = this.dataService.resortData.timeSlotsBySeason[seasonKey];
            for (let timeSlot of timeslots) {

              let findAva2 = allAvailabilities.filter(a => {if (a.day_timestamp === ts && a.packageId === pack.id && a.zoneId === zone.id && a.timeSlotId === timeSlot.id) return a})
              if (!findAva2.length) {
               allAvailabilities.push({
                  availability: 0,
                  day_timestamp: ts,
                  packageId: pack.id,
                  zoneId: zone.id,
                  id: null,
                  timeSlotId: timeSlot.id,
                })
              }
            }
          }
        }
      }
    }

    for (let ava of allAvailabilities) {

      if (!availabilitiesByPack[ava.packageId+'_'+ava.zoneId+'_'+ava.timeSlotId]) {
        availabilitiesByPack[ava.packageId+'_'+ava.zoneId+'_'+ava.timeSlotId] = {
          min: ava.availability,
          id: ava.id
        }
      } else {
        if (ava.availability < availabilitiesByPack[ava.packageId+'_'+ava.zoneId+'_'+ava.timeSlotId].min) {
          availabilitiesByPack[ava.packageId+'_'+ava.zoneId+'_'+ava.timeSlotId].min = ava.availability;
        }
      }
    }

    return availabilitiesByPack;
  }

  async getDuplicateCustomers() {
    this.otherCustomers = [];

    if (!this.newReservation.customerId && typeof this.newReservation.name !== 'undefined' && this.newReservation.name !== '' && typeof this.newReservation.surname !== 'undefined' &&  this.newReservation.surname !== '') {

      const searchString = this.newReservation.name.trim() + ' ' + this.newReservation.surname.trim();

      const data = await this.dataService.getCustomers( searchString.toLowerCase(), null, 10, 0, false);

      this.otherCustomers = [];

      for (const customer of data.results) {
        if (customer.name.trim().toLowerCase() === this.newReservation.name.trim().toLowerCase() && customer.surname.trim().toLowerCase() === this.newReservation.surname.trim().toLowerCase()) {

          this.otherCustomers.push(customer);
        } else if (customer.name.trim().toLowerCase() === this.newReservation.name.trim().toLowerCase() + ' ' + this.newReservation.surname.trim().toLowerCase()) {

          this.otherCustomers.push(customer);
        }
      }
    }

  }

  openCustomersModal () {
    const dialogRef = this.dialog.open(DialogCustomersComponent, {
      width: '1040px',
      height: '500px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.selectCustomer(result);
        }
    })
  }

  async noFiscalPrint() {

    const paymentType = JSON.parse(JSON.stringify(this.paymentType));
    const reservation = await this.saveAndCheckInReservation(this.newReservation);

    const dialogRef = this.dialog.open(ModalPrintersComponent, {
      width: '400px',
      data: {
        fiscalPrint: false,
        reservation,
        paymentType
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
    });
  }

  async fiscalPrint() {
    const paymentType = JSON.parse(JSON.stringify(this.paymentType));
    const reservation = await this.saveAndCheckInReservation( this.newReservation);
    if (reservation.id && reservation.id !== 'undefined' && reservation.id !== null) {

      const dialogRef = this.dialog.open(ModalPrintersComponent, {
        width: '400px',
        data: {
          fiscalPrint: true,
          reservation,
          paymentType
        }
      });

      dialogRef.afterClosed().subscribe(async result => {

        if (result) {

          this.updateCustomerBill(reservation, true);

          for (const bill of reservation.order.customerBill) {
            if (bill.payed && bill.type == 'settlement') {
              bill.type = 'movement'
              bill.settledFlag = false;
              bill.fiscalPrinter = result;
              bill.fiscalPrinted = true;
              bill.printedBy = this.settingsService.getCurrentUserData();
            }
          }
          this.settled = true;
          await this.saveReservation(reservation);
        }
      });
    } else {
      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'reservation-error',
          error: "C'è stato un errore nel salvataggio. Verifica che i dati siano coerenti."
        }
      });
    }
  }

  async getCustomers(searchString) {
    this.loader = true;
    const data = await this.dataService.getCustomers(searchString, null, 25, 1, false);
    this.customersFound = data.results;
    this.loader = false;
  }

  async loadMapArea() {
    this.loading = true;
    let counter = 0;

    for (let row of this.mapMatrix) {
      this.container.remove(counter);
      counter++;
    }

    if (this.selectedArea) {
      this.dataService.mapArea = this.selectedArea['id'];
      this.offsetColStart = this.selectedArea['startColumn'];
      this.offsetColEnd = this.selectedArea['endColumn'];
    } else {
      this.dataService.mapArea = null;
      this.offsetColStart = 0;
      this.offsetColEnd = this.resortData.cols;
    }

    this.zoomStep = this.resortData.zoom;
    this.startMapWidth = ((this.offsetColEnd - this.offsetColStart) * 50 + ((this.offsetColEnd - this.offsetColStart) - 1) * 15);
    this.startMapHeight = (this.resortData.rows * 50 + (this.resortData.rows - 1) * 15);

    this.setupGrid();
    this.reservationMapData = await this.dataService.getMapReservationsOptimized(this.startTimestamp / 1000, this.endTimestamp / 1000);
    this.loading = false;
  }

  selectCustomer(customer) {
    this.newReservation.name = customer.name;
    this.newReservation.surname = customer.surname;
    this.newReservation.phone = customer.phone;
    this.newReservation.phonePrefix = customer.phonePrefix;
    this.newReservation.customerId = customer.id;
    this.newReservation.email = customer.email;

    if (!this.newReservation.phonePrefix || this.newReservation.phonePrefix === '') {
      this.newReservation.phonePrefix = '+39';
    }
    this.customersFound = null;
  }

  smartReservation() {
    
    try {
      this.dataService.previousPage = 'home';
      const reservation = this.dataService.initReservation(this.startTimestamp, this.endTimestamp, false);
      console.log(reservation);
      const dialogRef = this.dialog.open(ReservationSmartComponent, {
        width: '600px',
        data: {
          reservation: reservation,
          reservationMapData: this.reservationMapData
        }
      });

      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          const length = (this.endTimestamp - this.startTimestamp) / (24 * 60 * 60 * 1000) + 1;
          console.log(result);
          reservation.dates = [];

          if (result.complexCombo) {
            console.log(result.complexCombo)
            for (let combo of result.complexCombo) {
              console.log("PUSH COMBO COMPLEX")
              if (combo.simpleComboAvailable == false) {
                for (let interval of combo.dates) {

                  let findInterval:any = reservation.dates.find((d:any)=>d.start === interval.ts && d.end === interval.ts);

                  if (findInterval) {
                    findInterval.order.push({
                      col:interval.slot.col,
                      row:interval.slot.row,
                      coords:interval.slot.label,
                      start:interval.ts,
                      end:interval.ts,
                      optionalsKeys:{},
                      optionalsPrices:{},
                      packageKey:combo.id,
                      type:'slot',
                      slot_key:interval.slot.row+'_'+interval.slot.col,
                      zoneKey:interval.slot.zoneId
                    })
                  } else {

                    const dateInterval = {
                      date_key: interval.ts + '_' + interval.ts,
                      start: interval.ts,
                      end: interval.ts,
                      length:1,
                      intervalAmount: 0,
                      optionalsAmount: 0,
                      order: [{
                        col:interval.slot.col,
                        row:interval.slot.row,
                        coords:interval.slot.label,
                        start:interval.ts,
                        end:interval.ts,
                        optionalsKeys:{},
                        optionalsPrices:{},
                        packageKey:combo.id,
                        type:'slot',
                        slot_key:interval.slot.row+'_'+interval.slot.col,
                        zoneKey:interval.slot.zoneId
                      }],
                      timeSlots: []
                    };
                
                    reservation.dates.push(dateInterval); 
                  }
                }
              }
            }
          }

          if (result.standardCombo) {
            let orders = []

            for (let order of result.standardCombo) {
              if (order.col !== null) {
                orders.push(order);
              }
            }
            let startTS = this.startTimestamp/1000;
            let newInterval = true;
            for (let ts = this.startTimestamp/1000; ts <= (this.endTimestamp/1000+24*60*60); ts+=24*60*60) {
              console.log(JSON.parse(JSON.stringify(reservation.dates)),ts);
              let findInterval:any = reservation.dates.find((d:any)=>d.start === startTS && d.end === ts);

              if (findInterval) {
                newInterval = false;
                startTS = ts+24*60*60;
                findInterval.order = findInterval.order.concat(orders)
              } 
            }

            if (newInterval === true) {

              const dateInterval = {
                date_key: (this.startTimestamp / 1000) + '_' + (this.endTimestamp / 1000),
                start: this.startTimestamp / 1000,
                end: this.endTimestamp / 1000,
                length:length,
                intervalAmount: 0,
                optionalsAmount: 0,
                order: orders,
                timeSlots: []
              };
          
              reservation.dates.push(dateInterval);
            }

          }
      
          this.dataService.selectedReservation = reservation;
          this.router.navigate(['/suite/reservation/new']);
        }
      });
    } catch(err) {
      console.log(err);
    }
  }

  
  move(reservation, mode, packageId) { 
    console.log(reservation);
    
    this.dataService.previousPage = 'home';
    this.dataService.selectedReservation = reservation;

    let interval = this.selectedReservation.dates.find((d:any)=> (d.start <= this.startTimestamp/1000 && d.end >= this.endTimestamp/1000));
    if (interval) {
      this.router.navigate(['suite/reservation/' + reservation.id + '/map/' + interval.start + '/' + interval.end + '/' + mode + '/' + packageId]);
    } else {
      this.router.navigate(['suite/reservation/' + reservation.id + '/map']);
    }
  }

  emptyReservation() {

    const reservation = this.dataService.initReservation(this.startTimestamp, this.endTimestamp, true);
    const length = (this.endTimestamp - this.startTimestamp) / (24 * 60 * 60 * 1000) + 1;

    const dateInterval = {
      date_key: (this.startTimestamp / 1000) + '_' + (this.endTimestamp / 1000),
      start: this.startTimestamp / 1000,
      end: this.endTimestamp / 1000,
      length,
      intervalAmount: 0,
      optionalsAmount: 0,
      order: [],
      timeSlots: []
    };

    reservation.dates = [];
    reservation.dates.push(dateInterval);

    this.dataService.selectedReservation = reservation;
    this.router.navigate(['/suite/reservation/new']);
  }

  sortByTimestamp( a, b ) {
    if ( a.day_timestamp < b.day_timestamp ) {
      return -1;
    }
    if ( a.day_timestamp > b.day_timestamp ) {
      return 1;
    }
    return 0;
  }

  sortByCol( a, b ) {
    if ( a.startColumn < b.startColumn ) {
      return -1;
    }
    if ( a.startColumn > b.startColumn ) {
      return 1;
    }
    return 0;
  }

  async getCustomersFromSearch() {
    this.customersByIds = {};
    this.customers = [];
    const data = await this.dataService.getCustomers(this.searchString.toLowerCase(), null, 25, 1, false);
    this.customers = data.results;

    for (const customer of this.customers) {
      this.customersByIds[customer.id] = customer;
    }
  }

  async getReservationsFromSearch() {
    const start = this.startTimestamp / 1000;
    const end = this.endTimestamp / 1000;

    const data = await this.dataService.getReservations(start, end, false, false, this.searchString.trim(), null, null, null, null, null, null, null, null, null, null, 100, 1, true , null, null, null, null, null, null, null, null,null,null,null);

    this.reservations = this.processReservations(data[`results`]);
  }

  openReservation(url) {
    this.dataService.previousPage = 'home';
    this.router.navigateByUrl(url);
  }

  processReservations(data) {
    const output = [];
    for (const reservation of data) {

      reservation.state = 1;
      for (let ts = this.dataService.startTimestamp / 1000; ts <= this.dataService.endTimestamp / 1000; ts += 24 * 60 * 60) {
          if (reservation.checkInDates.indexOf(ts) > -1 && reservation.checkOutDates.indexOf(ts) === -1) {
            reservation.state = 0;
            break;
          } else if (reservation.checkOutDates.indexOf(ts) === -1) {
            if (reservation.cocoBooking) {
              reservation.state = 3;
            } else if (reservation.seasonTicket) {
              reservation.state = 5;
            } else {
              reservation.state = 2;
            }
          }
      }

      if (reservation.state != 1) {
        for (const singleInterval of reservation.dates) {
          if ((singleInterval.start <= this.startTimestamp / 1000 && singleInterval.end >= this.startTimestamp / 1000 && singleInterval.end <= this.endTimestamp / 1000) || (singleInterval.start >= this.startTimestamp / 1000 && singleInterval.end <= this.endTimestamp / 1000) || (singleInterval.start <= this.endTimestamp / 1000 && singleInterval.end >= this.endTimestamp / 1000)) {

              if (reservation.version === 3) {
                for (const interval of reservation.dates) {
                  for (const order of interval.order) {
                      for (const optionalId in order.optionalsKeys) {
                        if (order.optionalsKeys[optionalId] && this.optionalsByKeys[optionalId]) {
                          order.optionalsKeys[this.optionalsByKeys[optionalId].id] = order.optionalsKeys[optionalId];
                          delete order.optionalsKeys[optionalId];
                        }
                      }
                  }
                }
              }


              const associated = [];
              let checkout = true;
              let checkin = true;

              for (let ts = this.startTimestamp / 1000; ts <= this.endTimestamp / 1000; ts += 24 * 60 * 60) {
                if (!reservation.checkOutDates || reservation.checkOutDates.length === 0 || reservation.checkOutDates.indexOf(ts) === -1) {
                  checkout = false;
                }

                if (!reservation.checkInDates || reservation.checkInDates.length === 0 || reservation.checkInDates.indexOf(ts) === -1) {
                  checkin = false;
                }
              }


              let name = '';
              let surname = '';

              for (const customer of reservation.customers) {
                if (customer.principal) {
                  name = customer.customer.name;
                  surname = customer.customer.surname;
                } else {
                  associated.push({
                    name: customer.customer.name,
                    surname: customer.customer.surname
                  })
                }
              }

              const obj = {
                refundState: reservation.refundState,
                dates: reservation.dates,
                noSlots: reservation.noSlots,
                code: reservation.reservationCode,
                cabins: reservation.cabins,
                name,
                surname,
                timeSlots: singleInterval.timeSlots,
                order: reservation.order,
                start: singleInterval.start,
                end: singleInterval.end,
                groupByPacks: {},
                extras: reservation.extras,
                amountPayed: 0,
                amountTotal: 0,
                id: reservation.id,
                seasonTicket: reservation.seasonTicket,
                cocoBooking: reservation.cocoBooking,
                state: reservation.state,
                reservationCode: reservation.reservationCode,
                associated,
                customerId: reservation.customerId,
                checkin,
                checkout
              };

              if (reservation.order.customerBill) {

                for (const billObj of reservation.order.customerBill) {
                  if (billObj.payed) {
                      obj.amountPayed += parseFloat(billObj.price);
                  } else {
                      obj.amountTotal += parseFloat(billObj.price);
                  }
                }
              }

              for (const product of singleInterval.order) {

                if (!obj.groupByPacks[product.packageKey]) {
                  obj.groupByPacks[product.packageKey] = {
                    products: [],
                    optionals: {}
                  };
                }

                obj.groupByPacks[product.packageKey].products.push(product);

                if (product.optionalsKeys) {
                  for (let optKey in product.optionalsKeys) {
                    if (product.optionalsKeys[optKey]) {
                      if (!this.optionals[optKey]) {
                        for (const optId in this.optionals ) {
                          if (this.optionals[optId].id == optKey) {
                            optKey = this.optionals[optId].id;
                            break;
                          }
                        }
                      }

                      if (!obj.groupByPacks[product.packageKey].optionals[optKey]) {
                        obj.groupByPacks[product.packageKey].optionals[optKey] = 0;
                      }

                      obj.groupByPacks[product.packageKey].optionals[optKey] += product.optionalsKeys[optKey];
                    }

                  }

                }
              }

              output.push(obj);
          }
        }
      }

    }

    return output;
  }

  async openReservationsModal(slotData) {
    console.log(slotData);
    this.showRightBar = false;
    this.lightLoading = true;
    this.resetMapFlag = false;
    this.selectedReservations = [];
    const slotIds = [];
    const row = slotData.row;
    const col = slotData.col;

    const slotId = row + '_' + col;

    const reservationsData = await this.dataService.getReservations(this.startTimestamp / 1000, this.endTimestamp / 1000, false, false, null, null, null, null, null, null, null, null, null, slotId, null, 100, 1, false, null, null, null, null, null, null, null, null,null,null,null);
    const selectedReservations = reservationsData[`results`];

    console.log(reservationsData);
    let lastTS = null;
    let state = slotData.state;
    if (slotData.reservationsKeys) {

      slotData.reservationsKeys.sort( this.sortByTimestamp );
      for (const reservationKey of slotData.reservationsKeys) {
        if (state != 6) {
          if (reservationKey.state == 4) {

            if (!lastTS || (lastTS && lastTS + 24 * 60 * 60 < reservationKey.day_timestamp)) {
              selectedReservations.push({
                name: '',
                notes: reservationKey.notes,
                start: reservationKey.day_timestamp,
                end: reservationKey.day_timestamp,
                state: 4
              });
            } else if (lastTS && lastTS + 24 * 60 * 60 == reservationKey.day_timestamp) {
              const lastIndex = selectedReservations.length - 1;
              selectedReservations[lastIndex].end = reservationKey.day_timestamp;
            }

            lastTS = reservationKey.day_timestamp;

          }
        }
      }

      selectedReservations.sort( (a, b) => {
          const keyA = a.start;
          const keyB = b.start;
          // Compare the 2 dates
          if ( keyA < keyB )  {
            return -1;
          }

          if ( keyA > keyB ) {
            return 1;
          }

          return 0;
      });

      for (const reservation of selectedReservations) {
        if (reservation.state != 4 ) {
          let customer = reservation.customers.find(c => c.principal === true);

          if (customer) {
            reservation.name = customer.customer.name;
            reservation.surname = customer.customer.surname;
          }

          reservation.state = 1;
          let allCheckoutDates = true;
          for (let ts = this.startTimestamp / 1000; ts <= this.endTimestamp / 1000; ts += 24 * 60 * 60) {
              let resFlag = false;
              for (const interval of reservation.dates) {
                if (interval.start <= ts && ts <= interval.end) {
                  resFlag = true;
                  break;
                }
              }

              if (resFlag) {
                if (reservation.checkOutDates && reservation.checkOutDates.indexOf(ts) === -1) {
                  allCheckoutDates = false;
                }

                if (reservation.checkInDates && reservation.checkInDates.indexOf(ts) > -1) {
                  reservation.state = 0;
                } else {
                  if (reservation.cocoBooking) {
                    reservation.state = 3;
                  } else if (reservation.seasonTicket) {
                    reservation.state = 5;
                  } else {
                    reservation.state = 2;
                  }
                }
              }
          }

          if (allCheckoutDates) {
            reservation.state = 1;
          }
        }

        if (reservation.dates) {

          for (let interval of reservation.dates) {

            if (( interval.start <= this.startTimestamp / 1000  &&  interval.end >= this.startTimestamp / 1000 ) || (    interval.start >= this.startTimestamp / 1000 && interval.end  <= this.endTimestamp / 1000  ) || ( interval.end >= this.endTimestamp / 1000  &&  interval.start <= this.endTimestamp / 1000 )) {
              reservation.selectedInterval = interval;

            }

            interval.groupByCombo = {};
            for (const product of interval.order) {

              if (!interval.groupByCombo[product.packageKey + '_' + product.zoneKey]) {
                interval.groupByCombo[product.packageKey + '_' + product.zoneKey] = {
                  products: [],
                  quantity: 0,
                  optionals: {},
                  packageId: product.packageKey,
                  zoneId: product.zoneKey
                };
              }

              if (product.quantity)
                interval.groupByCombo[product.packageKey + '_' + product.zoneKey].quantity += product.quantity;
              else
                interval.groupByCombo[product.packageKey + '_' + product.zoneKey].quantity += 1

              interval.groupByCombo[product.packageKey + '_' + product.zoneKey].products.push(product);

              if (product.optionalsKeys) {
                for (let optKey in product.optionalsKeys) {
                  if (product.optionalsKeys[optKey]) {
                    if (!this.optionals[optKey]) {
                      for (const optId in this.optionals ) {
                        if (this.optionals[optId].id == optKey) {
                          optKey = this.optionals[optId].id;
                          break;
                        }
                      }
                    }

                    if (!interval.groupByCombo[product.packageKey + '_' + product.zoneKey].optionals[optKey]) {
                      interval.groupByCombo[product.packageKey + '_' + product.zoneKey].optionals[optKey] = 0;
                    }

                    interval.groupByCombo[product.packageKey + '_' + product.zoneKey].optionals[optKey] += product.optionalsKeys[optKey];
                  }

                }

              }
            }
          }
        }


        if ((reservation.state != 1 || selectedReservations.length === 1) && !reservation.refundState) {
          if (!this.selectedReservations) {
            this.selectedReservations = [];
          }
          this.selectedReservations.push(reservation);
        }
      }

      this.selectedReservations = this.selectedReservations.filter(r => {
        if (!r.timeSlots || r.timesSlots.length === 0 || r.timeSlots[0].id === this.selectedTimeslot || !this.selectedTimeslot ) {
          return r;
        } else {
          return null;
        }
      })

      let isDailyFull = true;

      if (this.selectedReservations.length === 1) {
        for (let interval of this.selectedReservations[0].dates) {
          if (( interval.start <= this.startTimestamp / 1000  &&  interval.end >= this.startTimestamp / 1000 ) || (    interval.start >= this.startTimestamp / 1000 && interval.end  <= this.endTimestamp / 1000  ) || ( interval.end >= this.endTimestamp / 1000  &&  interval.start <= this.endTimestamp / 1000 )) {
            if (interval.timeSlots.length > 0) {
              isDailyFull = false;
              break;
            }
          }
        }
      }

      if (this.selectedReservations.length === 1 && this.selectedTimeslot) {
        isDailyFull = true;
      }

      //
      if (this.selectedReservations && this.selectedReservations.length === 1 && this.selectedReservations[0].state != 4 && isDailyFull) {


        let flagOrder = false;
        let counter = 0;
        for (const interval of this.selectedReservations[0].dates) {
          if (( interval.start <= this.startTimestamp / 1000  &&  interval.end >= this.startTimestamp / 1000 ) || (    interval.start >= this.startTimestamp / 1000 && interval.end  <= this.endTimestamp / 1000  ) || ( interval.end >= this.endTimestamp / 1000  &&  interval.start <= this.endTimestamp / 1000 )) {
            this.selectedReservations[0].selectedInterval = interval;

            for (const order of interval.order) {

              if (!order.optionalsKeys) {
                order.optionalsKeys = {}
              }
              order.todayState = 2;
              let today = new Date().setUTCHours(0,0,0,0)/1000;
              if (this.startTimestamp === this.endTimestamp) {
                today = this.startTimestamp/1000;
                if (order.checkout && order.checkout.find(ts => ts.start <= today && today <= ts.end)) {
                  order.todayState = 1;
                } else if (order.free &&  order.free.find(ts => ts.start <= today && today <= ts.end)) {
                  order.todayState = -1;
                  const infos = order.free.find(ts => ts.start <= today && today <= ts.end)
                  order.infos = infos.infos;
                } else if (order.checkin &&  order.checkin.find(ts => ts.start <= today && today <= ts.end)) {
                  order.todayState = 0;
                }

              }

              if (order.type === 'slot' && this.selectedSlots.indexOf(order.row + '_' + order.col) === -1 && order.todayState !== 1 && order.todayState !== -1) {
                this.selectedSlots.push(order.row + '_' + order.col);
                if (this.grid[order.row][order.col] && this.reservationMapData[order.row] && this.reservationMapData[order.row][order.col] && this.reservationMapData[order.row][order.col].state !== null && this.reservationMapData[order.row][order.col].state != 1 && this.reservationMapData[order.row][order.col].state != 4) {
                  this.grid[order.row][order.col].selected = true;
                }
                order.selected = false;

                if (!flagOrder) {
                  order.selected = true;
                  flagOrder = true;
                }
              }
            }
          }
        }

        this.selectedReservation = this.selectedReservations[0];

        this.setupSelectedReservationData(state);

      }

    }

    this.lightLoading = false;
  }

  setupSelectedReservationData(state) {
    this.selectedReservation.payed = 0;
    this.selectedReservation.notPayed = 0;

    for (const customerBill of this.selectedReservation.order.customerBill) {
      if (customerBill.payed) {
        this.selectedReservation.payed += customerBill.price;
      } else {
        this.selectedReservation.notPayed += customerBill.price;
      }
    }

    this.selectedReservation.state = state;
    let selectedReservation = JSON.parse(JSON.stringify(this.selectedReservation));
    this.selectedReservation.dates.sort((x, y) => { return x.date_key == selectedReservation.selectedInterval.date_key ? -1 : y.date_key == selectedReservation.selectedInterval.date_key ? 1 : 0; });
    this.selectedReservations = [];

    if (this.selectedReservation.customers) {
      const findPrincipal = this.selectedReservation.customers.find(cust => cust.principal);

      if (findPrincipal) {
        this.selectedReservation.name = findPrincipal.customer.name;
        this.selectedReservation.surname = findPrincipal.customer.surname;
      }
    }
  }

  openSingleReservationModal(reservation) {
    this.selectedReservations = [];
    this.selectedReservation = reservation;
    this.setupSelectedReservationData(this.selectedReservation.state);
  }

  selectOrder(order) {

    for (const orderInterval of this.selectedReservation.selectedInterval.order) {
      orderInterval.selected = false;
    }
    order.selected = true;
  }

  async updateMap(data) {

    if (data && data.length) {

      for (const slot of data) {
        for (let i = this.startTimestamp / 1000; i < (this.endTimestamp / 1000 + 24 * 60 * 60) ; i += 24 * 60 * 60) {

          if (slot && slot.timestamps.indexOf(i) > -1) {
              // FOR EVERY SLOT UPDATE THE STATE
              if (slot.slot) {

                  const rowKey = slot.slot.split('_')[0];
                  const colKey = slot.slot.split('_')[1];

                  if (slot.state != 1) {
                    if (!this.reservationMapData[rowKey]) {
                      this.reservationMapData[rowKey] = {};
                    }

                    if (!this.reservationMapData[rowKey][colKey]) {
                      this.reservationMapData[rowKey][colKey] = {};
                      this.reservationMapData[rowKey][colKey].reservationsKeys = [];
                    }

                    if (!this.reservationMapData[rowKey][colKey].reservationsKeys) {
                      this.reservationMapData[rowKey][colKey].reservationsKeys = [];
                    }

                    this.reservationMapData[rowKey][colKey].state = slot.state;
                    /* this.reservationMapData[rowKey][colKey].reservationsKeys =
                      day_timestamp: i,
                      state: slot.state
                    }); */


                  } else if (this.reservationMapData[rowKey] && this.reservationMapData[rowKey][colKey]){
                    delete this.reservationMapData[rowKey][colKey];
                  }

              }
          }

        }
      }


      if (this.unsettledShown) {
        this.showUnsettled();
      }

      //this.reservationMapData = this.startTimestamp / 1000, this.endTimestamp / 1000);

      this.reservationMapData = await this.dataService.getMapReservationsOptimized(this.startTimestamp / 1000, this.endTimestamp / 1000);



      /* if (this.startTimestamp === this.endTimestamp) {
        this.stockData = await this.statisticsData.getEmploymentRate(this.startTimestamp / 1000, this.endTimestamp / 1000, null, null, null, null, null, null, null);
      } else {
        this.stockData = null;
      } */

      //this.updateMapStates();

      if (!this.packagesFiltered) {
        this.setupPackageAvailability();
      }
    }
  }

  minusExtra(extra) {
    const extraFoundIndex = this.newReservation.extras.findIndex(ex => ex.id == extra.id);

    if (extraFoundIndex >= 0) {
      this.newReservation.extras[extraFoundIndex].quantity -= 1;
      extra.quantity -= 1;

      if (this.newReservation.extras[extraFoundIndex].quantity <= 0) {
        this.newReservation.extras.splice(extraFoundIndex, 1);
        extra.quantity = 0;
      }

    }
    this.updateCart(this.newReservation);
  }

  plusExtra(extra) {

    const extraFound = this.newReservation.extras.find(ex => ex.id === extra.id);
    extra.quantity += 1;

    if (extraFound) {
        extraFound.quantity += 1;
    } else {
      const extraObj = {
        id: extra.id,
        quantity: extra.quantity,
        price: extra.price,
        name: extra.name,
        type:'extra',
      };

      this.newReservation.extras.push(extraObj);
    }



    this.updateCart(this.newReservation);
  }

  showUnsettled() {
    for (const pack of this.packagesAvailabilities) {
      pack.selected = true;
    }
    this.unsettledShown = true;
    for (const slot of this.mapData) {

      const rowIndex = slot.row;
      const colIndex = slot.col;
      this.grid[rowIndex][colIndex].show = false;

      if (this.reservationMapData[rowIndex] && this.reservationMapData[rowIndex][colIndex] && !this.reservationMapData[rowIndex][colIndex].settled && this.reservationMapData[rowIndex][colIndex].state != 4) {
        this.grid[rowIndex][colIndex].show = true;
      }
    }

  }

  updatePackages() {
    this.packagesFiltered = true;
    let allSelected = true;
    for (const pack of this.packagesAvailabilities) {
      if (!pack.selected) {
        allSelected = false;
        break;
      }
    }

    if (allSelected) {
      this.packagesFiltered = false;
    }

    for (const slot of this.mapData) {

      const rowIndex = slot.row;
      const colIndex = slot.col;
      let flag = false;

      for (const pack of this.grid[rowIndex][colIndex].packagesIds) {
        const found = this.packagesAvailabilities.find(packFound => packFound.id === pack);

        if (found.selected && (!this.reservationMapData || !this.reservationMapData[rowIndex] || !this.reservationMapData[rowIndex][colIndex])) {
          flag = true;
        }
      }


      if (!flag && !allSelected) {
        this.grid[rowIndex][colIndex].show = false;
      } else {
        this.grid[rowIndex][colIndex].show = true;
      }

    }
    this.resetData();
  }

  expandReservation() {
    this.dataService.selectedReservation = this.newReservation;

    this.dataService.mainCustomer = {

      id: this.newReservation.customerId,
      name: this.newReservation.name,
      surname: this.newReservation.surname,
      email: '',
      phone: '',
      phonePrefix: '+39',
      warning: false,
      showNameTooltip: false,
      showSurnameTooltip: false
    };

    this.dataService.associatedCustomers = null;
    this.router.navigate(['/suite/reservation/new']);
  }

  goToReservations() {
    this.dataService.searchString = this.searchString;
    this.dataService.startTimestampList = this.startTimestamp;
    this.dataService.endTimestampList = this.endTimestamp;
    this.router.navigate(['/suite/reservations']);
  }

  setSelectedReservation(reservation) {

    this.unsettledShown = false;
    this.resetHeader();
    this.resetData();
    let selectedDate = null;
    for (const date of reservation.dates) {

      if ((date.start * 1000 <= this.startTimestamp && date.end * 1000 <= this.endTimestamp) || (date.start * 1000 >= this.startTimestamp && date.end * 1000 <= this.endTimestamp) || (date.start * 1000 <= this.endTimestamp && date.end * 1000 >= this.endTimestamp)) {
        selectedDate = date;
        break;
      }
    }

    if (selectedDate && !reservation.noSlots) {
        for (const slot of selectedDate.order) {

          this.selectedSlot = slot;
          this.selectedSlots.push(slot.row + '_' + slot.col);

          if (!this.selectedSlot.label && slot.coords) {
            this.selectedSlot.label = slot.coords;
          }

          this.grid[slot.row][slot.col].selected = true;
          this.openReservationsModal(this.reservationMapData[slot.row][slot.col]);
          break;
        }
        /* this.selectedReservation = reservation; */
    } else {
      this.router.navigate(['/suite/reservation/'+reservation.id]);
    }
  }

  async reserveSlots() {

    try {


      const dialogRef = this.dialog.open(ModalReserveComponent, {
        width: '340px'
      });

      dialogRef.afterClosed().subscribe(async result => {
          if (result && result.confirm) {
            const timestamps = [];

            const length = (this.endTimestamp - this.startTimestamp) / (24 * 60 * 60 * 1000) + 1;

            for (let i = 0; i < length ; i++) {
              const ts = this.startTimestamp / 1000 + 24 * 60 * 60 * i;
              timestamps.push(ts);
            }


            const apiObj = this.settingsService.setupReservationsMaps(this.selectedSlots, 4, true, timestamps, this.reservationMapData, this.startTimestamp, this.endTimestamp, this.selectedReservation);
            apiObj.notes = result.notes;
            const output = await this.dataService.setReservationsMaps(apiObj);

            this.resetData();
          } else {
          }
      });
    } catch (err) {

    }

  }

  async freeSlots() {

    try {
      // CHIAMA API CON OGGETTO

      const timestamps = [];

      const length = (this.endTimestamp - this.startTimestamp) / (24 * 60 * 60 * 1000) + 1;

      for (let i = 0; i < length ; i++) {
        const ts = this.startTimestamp / 1000 + 24 * 60 * 60 * i;
        timestamps.push(ts);
      }
      const apiObj = this.settingsService.setupReservationsMaps(this.selectedSlots, 4, false, timestamps, this.reservationMapData, this.startTimestamp, this.endTimestamp, this.selectedReservation);
      await this.dataService.removeReservationsMaps(apiObj);
      this.resetData();
    } catch (err) {

    }

  }

  showPackages(order, i) {

      if (!this.showPackageModal) {


        this.availablePackages = [];

        if (!this.showPackageModal) {
          for (const packId of this.grid[order.row][order.col].packagesIds) {
            if (order.packageKey === packId) {
              this.selectedPackage = packId;
            }
            this.availablePackages.push(packId);
          }
        }

        this.showPackageModal = true;
        this.selectedPackageIndex = i;
      }
  }

  setPackage(order, i) {

    order.packageKey = parseInt(this.selectedPackage);
    order.optionalsKeys = {};
    order.optionalsPrices = {};
    this.updateCart(this.newReservation);
    this.closePackageModal();
  }

  closePackageModal() {
    setTimeout(() => {
      this.showPackageModal = false;
      this.selectedPackageIndex = 0;
    }, 100);
  }


  async saveAndCheckInReservation(reservation) {

    if (!reservation.id) {
      this.updateCart(reservation);
      reservation.searchKey = '';
      reservation.relatedCustomers= [];
      if (!reservation.customerId && ((reservation.name && reservation.name.trim() !== '') || (reservation.surname && reservation.surname.trim() !== ''))) {

        if (!reservation.surname) {
          reservation.surname = '';
        }

        if (!reservation.name) {
          reservation.name = '';
        }

        const nC = {
          name: reservation.name,
          surname: reservation.surname,
          resortId: this.resortData.id
        }
        nC.resortId = this.resortData.id;
        const newCustomer = await this.dataService.setCustomer(nC);
        reservation.customerId = newCustomer.id;
        reservation.searchKey += reservation.name + ' ' + reservation.surname;
      }



      if (reservation.customerId ) {
        reservation.relatedCustomers.push({
          customer: reservation.customerId,
          principal: true
        });
      }

    }

    reservation.start = this.startTimestamp / 1000;
    reservation.end = this.endTimestamp / 1000;

    reservation.createdAt = Math.trunc(new Date().getTime() / 1000);
    reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
    reservation.createdBy = this.settingsService.getCurrentUserData()
    reservation.updatedBy = this.settingsService.getCurrentUserData();

    let httpCall = await this.dataService.saveAndCheckInReservation(reservation).catch(err => {
      return err;
    });

    return this.manageHttpError(httpCall, true);
  }


  async saveReservation(reservation) {
    
      this.updateCart(reservation);
      this.lightLoading = true;
      this.showQuickReservation = false;

      const state = 2;

      const length = (this.endTimestamp - this.startTimestamp) / (24 * 60 * 60 * 1000) + 1;
      const now = Math.round((new Date()).getTime() / 1000);

      let httpCall = null;

      if (!reservation.id) {

        await this.updateCustomers();
        reservation.start = this.startTimestamp / 1000;
        reservation.end = this.endTimestamp / 1000;

        reservation.createdAt = Math.trunc(new Date().getTime() / 1000);
        reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
        reservation.createdBy = this.settingsService.getCurrentUserData()
        reservation.updatedBy = this.settingsService.getCurrentUserData();
        httpCall = await this.dataService.setReservation(reservation).catch(err => {
          return err;
        });

      } else {
        reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
        reservation.updatedBy = this.settingsService.getCurrentUserData();

        httpCall = await this.dataService.updateReservation(reservation).catch(err => {
          return err;
        });

      }

      return this.manageHttpError(httpCall, true);

  }

  async updateCustomers() {
    this.newReservation.searchKey = '';
    this.newReservation.relatedCustomers = [];

    if (!this.newReservation.surname) {
      this.newReservation.surname = '';
    }

    if (!this.newReservation.name ) {
      this.newReservation.name = '';
    }

    if (!this.newReservation.customerId && (this.newReservation.name.trim() !== '' || this.newReservation.surname.trim() !== '')) {
      let nC = JSON.parse(JSON.stringify(this.newReservation));
      nC.resortId = this.resortData.id;
      const newCustomer = await this.dataService.setCustomer(nC);
      this.newReservation.customerId = newCustomer.id;
    } else if (this.newReservation.customerId) {

      let customerToSave = {
        id: this.newReservation.customerId,
        name: this.newReservation.name,
        surname:this.newReservation.surname,
        phone:this.newReservation.phone,
        phonePrefix:this.newReservation.phonePrefix,
        email: this.newReservation.email
      }
      await this.dataService.updateCustomer(customerToSave);
    }

    if (this.newReservation.customerId ) {
      this.newReservation.relatedCustomers.push({
        customer: this.newReservation.customerId,
        principal: true
      });
    }

    if (this.newReservation.name !== '' || this.newReservation.surname !== '') {
      this.newReservation.searchKey += this.newReservation.name + ' ' + this.newReservation.surname + '_';
    }

  }

  async manageHttpError(httpCall, reset) {
    if (httpCall && httpCall.error) {
      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '800px',
        height: '130px',
        data: {
          type: 'reservation-error',
          error: httpCall.error.error
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        this.resetData();
        this.lightLoading = false;
      });
      return false;
    } else {

      if (reset) {
        this.resetData();
      } else {
        const rowIndex = this.selectedSlots[0].split('_')[0];
        const colIndex = this.selectedSlots[0].split('_')[1];
        await this.openReservationsModal(this.reservationMapData[rowIndex][colIndex]);
        this.clicked = false;
      }
      this.lightLoading = false;
      return httpCall;
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
        // Do things
        this.resetData();
        this.resetHeader();
    }
}

  zoomPlus() {
    this.zoomStep = this.zoomStep + 0.10;
  }

  zoomMinus() {
    this.zoomStep = this.zoomStep - 0.10;
  }


  async setupPackageAvailability() {
    this.packagesAvailabilities = [];
    if (this.resortData.elementsThreshold) {

      for (const ele of this.resortData.elementsThreshold) {
        let elementFound = this.dataService.elements.find(e => e.id === ele.id);
        ele.optionalId = elementFound.elementId
      }
    }

    let debugPackages = {};

    const potentialElementsByPackages = {};

    this.resortData.packages.map(pack => {
      const packCopy = JSON.parse(JSON.stringify(pack));
      packCopy.quantity = 0;

      if (!this.packagesFiltered) {
        packCopy.selected = true;
      }
      this.packagesAvailabilities.push(packCopy);

      potentialElementsByPackages[pack.id] = {};

      for (const element of pack.elements) {
        potentialElementsByPackages[pack.id][element.element.id] = element.quantity;
      }

    });


    if (this.startTimestamp === this.endTimestamp) {

      for (const element of this.resortData.elementsThreshold) {
        element.quantity = element.dailyQuantity;
        element.busy = 0;
      }

      this.peopleCurrentlyPresent = 0;

      for (const rowKey in this.reservationMapData) {
        if (this.reservationMapData[rowKey]){

          // AGGIORNA PRESENZE

          for (const colKey in this.reservationMapData[rowKey]) {
            if (this.reservationMapData[rowKey][colKey] && this.reservationMapData[rowKey][colKey].state === 0) {
              for (const res of this.reservationMapData[rowKey][colKey].reservationsKeys) {
                if (this.resortData.packagesByIds[res.packageId]) {

                  this.peopleCurrentlyPresent += this.resortData.packagesByIds[res.packageId].seats;

                  if (res.optionalsIds) {
                    for (const optId in res.optionalsIds) {
                      this.peopleCurrentlyPresent += res.optionalsIds[optId];
                    }
                  }

                }


              }
            }


            // SCALCOLA MAGAZZINO

            if (this.reservationMapData[rowKey][colKey] && this.reservationMapData[rowKey][colKey].state !== 4) {
              for (const res of this.reservationMapData[rowKey][colKey].reservationsKeys) {
                if (res.packageId) {

                  let pack = this.resortData.packagesByIds[res.packageId];



                  // AGGIUNGI ELEMENTI DEL PACCHETTO
                  for (const ele of pack.elements) {
                    let findEle = this.resortData.elementsThreshold.find(e => e.id === ele.element.id);

                    if (!debugPackages[pack.id]) {
                      debugPackages[pack.id] = {
                        name: pack.name,
                        sum: 0
                      }
                    }

                    if (findEle) {
                      findEle.busy += ele.quantity;
                      if (findEle.id === 6) {
                        debugPackages[pack.id].sum += ele.quantity
                      }
                    }
                  }

                  // AGGIUNGI SEDUTE AGGIUNTIVE
                  if (res.optionalsIds) {
                    for (const optId in res.optionalsIds) {
                      let findEle = this.resortData.elementsThreshold.find(e => e.optionalId === optId);

                      if (findEle) {

                        if (!debugPackages[optId]) {
                          debugPackages[optId] = {
                            name: this.optionalsByIds[optId].name,
                            sum: 0,
                            type: 'optional'
                          }
                        }

                        findEle.busy += res.optionalsIds[optId];
                        debugPackages[optId].sum += res.optionalsIds[optId]
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }


      const noSlotsReservations = await this.dataService.getNoSlotsReservations(this.startTimestamp / 1000, this.endTimestamp / 1000);
      if (noSlotsReservations.results) {
        for (const res of noSlotsReservations.results) {
          let dateFlag = true;
          if (res.checkInDates.length) {
            if (res.checkInDates.indexOf(this.startTimestamp / 1000) === -1 || res.checkOutDates.indexOf(this.startTimestamp / 1000) > -1) {
              dateFlag = false;
            }
          } else {
            dateFlag = false;
          }

            let findInterval = res.dates.find(interval => interval.start <= this.startTimestamp/1000 && interval.end >= this.startTimestamp/1000);
            if (findInterval) {
              for (const order of findInterval.order) {

                // AGGIUNGI PERSONE

                if (dateFlag) {
                  this.peopleCurrentlyPresent += this.resortData.packagesByIds[order.packageKey].seats*order.quantity;

                  if (order.optionalsKeys) {
                    for (const optId in order.optionalsKeys) {
                      this.peopleCurrentlyPresent += order.optionalsKeys[optId];
                    }
                  }
                }

                // SCALCOLA MAGAZZINO

                let pack = this.resortData.packagesByIds[order.packageKey];

                // AGGIUNGI ELEMENTI DEL PACCHETTO

                for (const ele of pack.elements) {
                  let findEle = this.resortData.elementsThreshold.find(e => e.id === ele.element.id);
                  if (findEle) {
                    findEle.busy += order.quantity;
                  }
                }

                // AGGIUNGI SEDUTE AGGIUNTIVE

                if (order.optionalsIds) {
                  for (const optId in res.optionalsIds) {
                    let findEle = this.resortData.elementsThreshold.find(e => e.id === optId);
                    if (findEle) {
                      findEle.busy += res.optionalsIds[optId]*order.quantity;
                    }
                  }
                }

              }
            }


        }
      }

      // CHECK NOTIFICHE

      for (const element of this.resortData.elementsThreshold) {
        element.quantity = element.dailyQuantity;

        if ((element.quantity - element.busy) <= element.threshold) {

          const foundElementNofication = this.dataService.notifications.find(ele => ele.type === 'stock' && ele.data.element.id === element.id);

          if (foundElementNofication) {
            foundElementNofication.data.element.left = element.quantity - element.busy;
            foundElementNofication.date = Math.trunc(new Date().getTime() / 1000);
          } else {
            this.dataService.notifications.push({
              type: 'stock',
              data: {
                element: {
                  id: element.id,
                  left: element.quantity - element.busy
                }
              },
              date: Math.trunc(new Date().getTime() / 1000)
            });
          }

        } else {
          const foundElementNofication = this.dataService.notifications.findIndex(ele => ele.type === 'stock' && ele.data.element.id === element.id);

          if (foundElementNofication > -1) {
            this.dataService.notifications.splice(foundElementNofication, 1);
          }
        }

      }

    }

    for (const slot of this.mapData) {
      const rowIndex = slot.row;
      const colIndex = slot.col;

      let singleState = null;

      if (this.reservationMapData[rowIndex] && this.reservationMapData[rowIndex][colIndex] && this.reservationMapData[rowIndex][colIndex].reservationsKeys) {


        for (const resKey of this.reservationMapData[rowIndex][colIndex].reservationsKeys) {

          if (!singleState) {
            singleState = resKey.state;
          }

          if (singleState && resKey.state != singleState) {
            singleState = 0;
            break;
          }
        }
      }

      if (singleState === null) {
        for (const packId of slot.packagesIds) {
          const ind = this.packagesAvailabilities.findIndex(pack => pack.id === packId);
          if (ind > -1) {
            this.packagesAvailabilities[ind].quantity++;
          }
        }
      }
    }


  }

  mapMatrix: any[][];
  matrix$: Observable<any[][]>;

  setupGrid() {
    this.grid = [];
    this.mapMatrix = [];
    this.packagesAvailabilities = [];

    this.resortData.packages.map(pack => {
      const packCopy = JSON.parse(JSON.stringify(pack));
      packCopy.quantity = 0;
      packCopy.selected = true;
      this.packagesAvailabilities.push(packCopy);
    });

    let index = 0;


    let zonesFound= {};

    for (let row = 0; row < this.resortData.rows; row++) {
      let colArray = [];
      for (let col = this.offsetColStart; col < this.offsetColEnd; col++) {

        const rowIndex = row.toString();
        const colIndex = col.toString();
        const foundSlot = this.mapData.find(slot => slot.row == row && slot.col == col);


        let slot = {
          row,
          col,
          zoneKey: null,
          packagesKeys: null,
          version: null,
          zoneId: 0,
          packagesIds: [],
          defaultPackId: 0
        }

        if (foundSlot) {
          slot = foundSlot;

          if (!zonesFound[slot.zoneId]) {
            zonesFound[slot.zoneId] = this.resortData.zonesByIds[slot.zoneId];
          }
        }


        if (!this.grid[rowIndex]) {
          this.grid[rowIndex] = {};
        }

        if (!this.grid[rowIndex][colIndex]) {
          this.grid[rowIndex][colIndex] = {};
        }

        this.grid[rowIndex][colIndex] = slot;
        this.grid[rowIndex][colIndex].show = true;
        this.grid[rowIndex][colIndex].selected = false;
        this.grid[rowIndex][colIndex].matrixIndex = index;
        this.grid[rowIndex][colIndex].startingHeight = this.startHeight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].paddingLeftRight = this.startPaddingLeftRight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].slotElementHeight = this.startHeight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].slotLabel = 'AAA';
        this.grid[rowIndex][colIndex].reservationsKeys = false;
        this.grid[rowIndex][colIndex].elementId = null;


        if (this.reservationMapData[rowIndex] && this.reservationMapData[rowIndex][colIndex] && this.reservationMapData[rowIndex][colIndex].reservationsKeys) {
          this.grid[rowIndex][colIndex].reservationsKeys = this.reservationMapData[rowIndex][colIndex].reservationsKeys;
        }

        this.settingsService.setSlotElement(this.grid[rowIndex][colIndex], this.dataService.resortData);

        let singleState = null;


        for (const resKey of this.grid[rowIndex][colIndex].reservationsKeys) {

          if (!singleState) {
            singleState = resKey.state;
          }

          if (singleState && resKey.state != singleState) {
            singleState = 0;
            break;
          }

        }

        if (singleState === null) {
          for (const packId of slot.packagesIds) {
            const ind = this.packagesAvailabilities.findIndex(pack => pack.id === packId);
            if (ind > -1) {
              this.packagesAvailabilities[ind].quantity++;
            }
          }
        }

        index++;
        colArray.push(this.grid[rowIndex][colIndex]);
      }
      this.mapMatrix.push(colArray);
    }


    this.buildMap();


    this.loading = false;
  }
  rows = [];
  reloading = true;
  buildMap() {
    let counter = 0;


    setTimeout(()=>{
      let counter = 0;

      for (let row of this.mapMatrix) {
        this.rows[counter] = false;
        if (this.container && this.template) {
          this.container.createEmbeddedView(this.template, { row: row, show:false, index:counter });
        }
        counter++;
      }

      for (let i=0; i< counter; i+=10) {

        setTimeout(()=>{
          this.showNewData(i, i+20);
        },i*10)
      }

    },200)
  }

  showNewData(from:number,to:number) {
    for (let i=from; i<to;i++) {
      if (this.container) {
        let view = this.container.get(i);
        this.rows[i]=true;
        if (view) {
          view.detectChanges();
        }
      }
    }
  }


  trackById(index, slot) {
    return slot.id;
  }

  trackByIndex(index, slot) {
    return index;
  }

  confirmMove() {
    // mode = 1  -> MOVE
    // mode = 0 -> ADD
    this.dataService.previousPage = 'home';
    const interval = this.selectedReservation.selectedInterval;
    this.dataService.selectedReservation = this.selectedReservation;
    this.router.navigate(['suite/reservation/' + this.selectedReservation.id + '/map/' + interval.start + '/' + interval.end + '/1']);
  }

  enableMove() {

    this.moveMode = true;
    this.showBottomBar = true;
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.packagesFiltered = false;
    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now)) {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    const startTS = (new Date(event.value)).getTime() - offset;
    const startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.endTimestamp = this.startTimestamp;
    }


    const endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);

    this.dataService.startTimestamp = this.startTimestamp;
    this.dataService.endTimestamp = this.endTimestamp;

    this.reservationMapData = await this.dataService.getMapReservationsOptimized(this.startTimestamp / 1000, this.endTimestamp / 1000);


    this.setupPackageAvailability();
    this.resetData();
    // CONTROLLA LA CONSISTENZA DELLE FASCE ORARIE
    this.getAndCheckTimeslots();
    this.getNoMapAvailabilities();
    this.getDiscountedReservations();
  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.packagesFiltered = false;
    this.loader = true;

    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    const endTS = (new Date(event.value)).getTime() - offset;
    const endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.startTimestamp = this.endTimestamp;
    }

    const startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    this.dataService.startTimestamp = this.startTimestamp;
    this.dataService.endTimestamp = this.endTimestamp;

    //this.reservationMapData = this.startTimestamp / 1000, this.endTimestamp / 1000);
    this.reservationMapData = await this.dataService.getMapReservationsOptimized(this.startTimestamp / 1000, this.endTimestamp / 1000);


    /* if (this.startTimestamp === this.endTimestamp) {
      this.stockData = await this.statisticsData.getEmploymentRate(this.startTimestamp / 1000, this.endTimestamp / 1000, null, null, null, null, null, null, null);
    } else {
      this.stockData = null;
    } */

    console.log(this.reservationMapData);
    this.setupPackageAvailability();
    this.resetData();
    // CONTROLLA LA CONSISTENZA DELLE FASCE ORARIE
    this.getAndCheckTimeslots();
    this.getNoMapAvailabilities();
    this.getDiscountedReservations();
  }

  async setTodayDate() {
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.packagesFiltered = false;
    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startDate = startDate.getDate();

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endDate = endDate.getDate();

    this.startSerializedDate = new Date(this.startTimestamp);
    this.endSerializedDate = new Date(this.endTimestamp);

    this.reservationMapData = await this.dataService.getMapReservationsOptimized(this.startTimestamp / 1000, this.endTimestamp / 1000);


    /* if (this.startTimestamp === this.endTimestamp) {
      this.stockData = await this.statisticsData.getEmploymentRate(this.startTimestamp / 1000, this.endTimestamp / 1000, null, null, null, null, null, null, null);
    } else {
      this.stockData = null;
    } */

    this.dataService.startTimestamp = this.startTimestamp;
    this.dataService.endTimestamp = this.endTimestamp;

    this.setupPackageAvailability();
    this.resetData();
    // CONTROLLA LA CONSISTENZA DELLE FASCE ORARIE
    this.getAndCheckTimeslots();

    this.getNoMapAvailabilities();
    this.getDiscountedReservations();
  }


  getAndCheckTimeslots() {
    let length = (this.endTimestamp-this.startTimestamp)/(24*60*60*1000) + 1;
    let seasonsFound = this.dataService.getSeasonsInReservation(this.startTimestamp/1000, length,null);
    let daysInInterval = this.settingsService.getDaysFromInterval(this.startTimestamp/1000, this.endTimestamp/1000);
    console.log(seasonsFound);
    if (seasonsFound && Object.keys(seasonsFound).length === 1) {
      for (let seasonKey in seasonsFound) {
        if (this.settingsService.checkIfTimeslotsAreEnabled(seasonsFound[seasonKey], daysInInterval)) {
          this.timeSlots = this.resortData.timeSlotsBySeason[seasonKey];
        } else {
          this.timeSlots = [];
        }
      }
    }

  }


  showAllSlots() {
    for (const pack of this.packagesAvailabilities) {
      pack.selected = true;
    }

    for (const slot of this.mapData) {

      const rowIndex = slot.row;
      const colIndex = slot.col;

      if (this.grid[rowIndex] && this.grid[rowIndex][colIndex]) {
        this.grid[rowIndex][colIndex].show = true;
      }

    }

    this.unsettledShown = false;
  }

  resetData() {
    this.selectedReservation = null;
    this.selectedReservations = null;
    this.showQuickReservation = false;
    this.showBottomBar = false;
    this.newReservation = null;
    this.reserveFlag = true;
    this.settled = false;
    this.searchString = null;
    this.reservations = null;
    this.customers = null;
    this.customersByIds = {};
    this.paymentType = 'cash';
    this.clicked = false;
    this.associatedNames = '';
    this.availableTimeslots = [];

    for (const slot of this.mapData) {

      const rowIndex = slot.row;
      const colIndex = slot.col;

      if (this.grid[rowIndex] && this.grid[rowIndex][colIndex]) {
        this.grid[rowIndex][colIndex].selected = false;
      }

    }

    if (!this.unsettledShown && !this.packagesFiltered) {
      this.showAllSlots();
    }

    for (const extra of this.resortData.extras) {
      extra.quantity = 0;
    }

    this.selectedSlots = [];
    this.selectedSlot = null;
  }


  resetLighMap() {

  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout(() => {
      this.pageClicked = false;
    }, 1000);
  }

  openNewReservation() {
    this.dataService.previousPage = 'home';
    const reservation = this.dataService.initReservation(this.startTimestamp, this.endTimestamp, false);
    const order = [];

    const length = (this.endTimestamp - this.startTimestamp) / (24 * 60 * 60 * 1000) + 1;

    for (let i = 0; i < this.selectedSlots.length; i++) {
      const rowIndex = this.selectedSlots[i].split('_')[0];
      const colIndex = this.selectedSlots[i].split('_')[1];
      let selected = false;

      if (i === 0) {
        selected = true;
      }


      order.push({
        selected: selected,
        col: parseInt(colIndex),
        row: parseInt(rowIndex),
        type: 'slot',
        coords: this.grid[rowIndex][colIndex].label,
        zoneKey: this.grid[rowIndex][colIndex].zoneId,
        slot_key: this.selectedSlots[i],
        packageKey: parseInt(this.grid[rowIndex][colIndex].defaultPackId),
        optionalsKeys: {},
      });

    }

    let timeSlots = [];

    const dateInterval = {
      date_key: (this.startTimestamp / 1000) + '_' + (this.endTimestamp / 1000),
      start: this.startTimestamp / 1000,
      end: this.endTimestamp / 1000,
      length: length,
      intervalAmount: 0,
      optionalsAmount: 0,
      order: order,
      timeSlots
    };

    reservation.dates = [];
    reservation.dates.push(dateInterval);

    this.dataService.selectedReservation = reservation;
    this.dataService.mainCustomer = null;
    this.dataService.associatedCustomers = null;
    this.updateTimeslot(this.selectedTimeslot,reservation, this.timeSlots);
    this.router.navigate(['/suite/reservation/new']);
  }

  isNumber(val) { return typeof val === 'number'; }

  isString(val) { return typeof val === 'string'; }

  quickReservation() {
    this.enableDailyTimeslot = true;
    this.showQuickReservation = true;
    this.selectedTimeslotQuickReservation = JSON.parse(JSON.stringify(this.selectedTimeslot));
    this.showRightBar = false;
    this.newReservation = {};
    this.showBottomBar = false;
    this.resetMapFlag = false;
    this.newReservation = this.dataService.initReservation(this.startTimestamp, this.endTimestamp, false);

    const newOrder = [];


    let timeSlotsToRemove = [];

    const orderLength = (this.endTimestamp - this.startTimestamp) / (24 * 60 * 60 * 1000) + 1;

    for (let i = 0; i < this.selectedSlots.length; i++) {
      const rowIndex = this.selectedSlots[i].split('_')[0];
      const colIndex = this.selectedSlots[i].split('_')[1];
      let selectedOrder = false;
      if (i == 0) {
        selectedOrder = true;
      }

      newOrder.push({
        selected: selectedOrder,
        col: parseInt(colIndex),
        row: parseInt(rowIndex),
        type: 'slot',
        coords: this.grid[rowIndex][colIndex].label,
        zoneKey: this.grid[rowIndex][colIndex].zoneId,
        slot_key: this.selectedSlots[i],
        packageKey: parseInt(this.grid[rowIndex][colIndex].defaultPackId),
        optionalsKeys: {},
      });


      if (this.reservationMapData[rowIndex] && this.reservationMapData[rowIndex][colIndex] && this.reservationMapData[rowIndex][colIndex].reservationsKeys) {
        for (let reservation of this.reservationMapData[rowIndex][colIndex].reservationsKeys) {
          if (reservation.timeSlot) {
            timeSlotsToRemove.push(reservation.timeSlot)
          }
        }
      }

    }


    let timeSlots = [];
    this.availableTimeslots = [];
    let seasons = this.dataService.getSeasonsInReservation(this.startTimestamp/1000, orderLength, null);

    if (Object.keys(seasons).length && Object.keys(seasons).length === 1) {
      let daysInInterval = this.settingsService.getDaysFromInterval(this.startTimestamp / 1000, this.endTimestamp / 1000);
      let seasonKey = Object.keys(seasons)[0];
      if (this.settingsService.checkIfTimeslotsAreEnabled(seasons[seasonKey], daysInInterval)) {
        this.availableTimeslots = JSON.parse(JSON.stringify(this.resortData.timeSlotsBySeason[seasonKey]));
      } else {
        this.availableTimeslots = [];
      }
      //this.availableTimeslots = this.resortData.timeSlotsBySeason[seasonKey];
    }


    if (timeSlotsToRemove.length) {
      this.enableDailyTimeslot = false;
      for (let ts of timeSlotsToRemove) {
        let findTS = this.availableTimeslots.findIndex(t => t.id === ts.id);
        if (findTS > -1) {
          this.availableTimeslots.splice(findTS,1);
        }
      }
    }


    const dateInterval = {
      date_key: (this.startTimestamp / 1000) + '_' + (this.endTimestamp / 1000),
      start: this.startTimestamp / 1000,
      end: this.endTimestamp / 1000,
      length: orderLength,
      intervalAmount: 0,
      optionalsAmount: 0,
      order: newOrder,
      timeSlots
    };

    this.newReservation.dates = [];
    this.newReservation.dates.push(dateInterval);
    this.updateTimeslot(this.selectedTimeslotQuickReservation,this.newReservation, this.availableTimeslots);
  }

  updateTimeslot(timeSlotId,reservation, timeSlots) {

    reservation.dates[0].timeSlots = []
    let now = new Date();
    //let offset = now.getTimezoneOffset() * 60;

    if (timeSlotId) {
      let findTS = timeSlots.find(t => t.id === timeSlotId);
      let offset = now.getTimezoneOffset();
      let startSeconds = parseInt(findTS.startHour.split(":")[0])*60*60 + parseInt(findTS.startHour.split(":")[1])*60 + offset*60;
      let endSeconds = parseInt(findTS.endHour.split(":")[0])*60*60 + parseInt(findTS.endHour.split(":")[1])*60 + offset*60;
      findTS.startSeconds = startSeconds;
      findTS.endSeconds = endSeconds;
      findTS.utcOffset = -offset/60;
      reservation.dates[0].timeSlots.push(findTS);
    }

    this.updateCart(reservation);

    // AGGIORNA POSTAZIONI DA VISUALIZZARE
  }

  syncMapDataWithTimeslot() {

  }

  plusOptional(order, optionalId) {
    if (!order.optionalsKeys[optionalId]) {
      order.optionalsKeys[optionalId] = 1;
    }  else {
      order.optionalsKeys[optionalId] += 1;
    }

    this.updateCart(this.newReservation);
  }

  minusOptional(order, optionalId) {
    let quantity = JSON.parse(JSON.stringify(order.optionalsKeys[optionalId]))
    quantity -= 1;

    if (quantity <= 0) {
      quantity = 0;
    }

    if (quantity == 0) {
      delete order.optionalsKeys[optionalId];
    } else {
      order.optionalsKeys[optionalId] = quantity;
    }

    this.updateCart(this.newReservation);
  }

  updateCart(reservation) {
    let totalLength = 0;
    for (let interval of reservation.dates) {
      totalLength+=interval.length;
      let index = 0;
      for (let order of interval.order) {
        if (!order.packageKey) {
          interval.order.splice(index,1);
        }
        index++;
      }
    }

    reservation.order.specialSeasonPrice = this.resortData.specialSeasonPrice;
    reservation.order.dailyPrice = false;
    reservation.length = totalLength;
    if (reservation.order.reservedPrice < 0 ) {
      reservation.order.reservedPrice = 0;
    }

    this.settingsService.updateCart(reservation,true);
    this.updateCustomerBill(reservation, this.settled);
  }

  updateCustomerBill(reservation, settled) {
    this.settingsService.updateCustomerBill(reservation, settled, this.paymentType);
  }

  updatePaymentType(reservation) {
    for (const bill of reservation.order.customerBill) {
      if (bill.type === 'settlement') {
        bill.paymentType = this.paymentType;
      }
    }
  }

  selectSlot(slot) {
    for (const order of this.newReservation.dates[0].order ) {
      if ( slot.slot_key === order.slot_key ) {
        order.selected = true;
      } else {
        order.selected = false;
      }
    }
  }

  removeSlot(slot) {

    for (const order of this.newReservation.dates[0].order) {

      if (slot.slot_key === order.slot_key) {
        this.newReservation.dates[0].order = this.newReservation.dates[0].order.filter(obj => {
          return obj.slot_key !== slot.slot_key;
        });
        this.grid[slot.row][slot.col].selected = false;
        break;
      }
    }

    if (this.newReservation.dates[0].order.length) {
      this.newReservation.dates[0].order[0].selected = true;
    }
  }

}

