import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SettingsService } from '../../../core/providers/settings.service';
import { ModalPrintersComponent } from '../../components/modal-printers/modal-printers.component';
import { DataService, Customer } from '../../../core/providers/data.service';
import { ModalErrorComponent } from '../modal-error/modal-error.component';

@Component({
  selector: 'app-modal-customer-bill',
  templateUrl: './modal-customer-bill.component.html',
  styleUrls: ['./modal-customer-bill.component.scss']
})
export class ModalCustomerBillComponent implements OnInit {

  customerBill;
  noLabels = true;

  constructor(public dataService: DataService, public dialog: MatDialog, private settingsService: SettingsService, public dialogRef: MatDialogRef<ModalCustomerBillComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.customerBill = JSON.parse(JSON.stringify(this.data.customerBill));
    console.log(this.customerBill);

    for (const bill of this.customerBill) {
      bill.serializedDate = new Date(bill.date * 1000);
    }

    this.checkLabels();
    this.customerBill = this.customerBill.sort(this.sortByDate);
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close({save:false});
  }

  checkLabels() {
    this.noLabels = true;
    let noLabels = this.customerBill.filter(ele => (ele.name == '' || !ele.name));

    if (noLabels.length == 0) {
      this.noLabels = false;
    }
  }

  save(): void {

    this.customerBill = this.customerBill.filter(ele => (ele.name != '' || ele.name));

    this.customerBill.map(ele =>{
      delete ele.new;
    });

    this.dialogRef.close({
      save: true,
      customerBill: this.customerBill
    });
  }

  checkBillPrice(index) {
    if (this.customerBill[index].price < 0) {
      this.customerBill[index].price = 0;
    }
  }

  updateAuthor(bill) {
    bill.updatedBy = this.settingsService.getCurrentUserData();
    bill.updatedAt = Math.trunc(new Date().getTime() / 1000);
  }

  addBill() {

    const offset = new Date().getTimezoneOffset() * 60000;
    const startDate = ((new Date()).setHours(0, 0, 0, 0) - offset )/1000 ;

    this.customerBill.push({
      createdAt: Math.trunc(new Date().getTime() / 1000),
      updatedAt: Math.trunc(new Date().getTime() / 1000),
      createdBy: this.settingsService.getCurrentUserData(),
      updatedBy: this.settingsService.getCurrentUserData(),
      date: startDate,
      serializedDate: new Date(startDate * 1000),
      name: '',
      fiscalPrinter: '',
      fiscalPrinted: false,
      settledFlag: false,
      type: 'movement',
      payed: this.data.paid,
      paymentType: 'cash',
      price: 0,
      new: true
    });

    this.checkLabels();

    this.customerBill = this.customerBill.sort(this.sortByDate);
  }

  async removeBill(index, bill) {
    this.customerBill.splice(index,1);
    if (bill.id) {
      await this.dataService.deleteSingleCustomerBill(this.data.reservation.id, bill.id)
    }
    this.checkLabels();
    this.customerBill = this.customerBill.sort(this.sortByDate);
  }


  async changeBillDate(event: MatDatepickerInputEvent<Date>,bill) {
    const offset= new Date(bill.serializedDate).getTimezoneOffset() * 60000;
    bill.date = ((new Date(bill.serializedDate)).setHours(0, 0, 0) - offset ) / 1000;
    console.log(bill.date);
  }

  async fiscalPrint(bill) {
    if (!this.data.reservation.id || this.data.reservation.id === 'new')  {
      // SAVE RESERVATION !!
      await this.saveReservation();

    }
    bill.payed = true;

    const dialogRef = this.dialog.open(ModalPrintersComponent, {
      width: '400px',
      data: {
        fiscalPrint: true,
        bill,
        reservation: this.data.reservation,
        paymentType: bill.paymentType
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dialogRef.close({
        save: true,
        customerBill: this.customerBill
      });
    })
  }


  sortByDate(a,b) {
    if ( a.date > b.date ) {
      return -1;
    }
    if ( a.date < b.date ) {
      return 1;
    }
    return 0;
  }

  async saveReservation() {

    try {

      // SAVE RESERVATIONS MAPS

      await this.settingsService.checkDatesIntervalsAndUpdateDisableDates(this.data.reservation);
      let httpCall = null;

      this.data.reservation.createdAt = Math.trunc(new Date().getTime() / 1000);
      this.data.reservation.updatedAt = Math.trunc(new Date().getTime() / 1000);
      this.data.reservation.createdBy = this.settingsService.getCurrentUserData();
      this.data.reservation.updatedBy = this.settingsService.getCurrentUserData();
    
      console.log(JSON.stringify(this.data.reservation));
    
      httpCall = await this.dataService.setReservation(this.data.reservation).catch(err => {
        return err;
      });

      if (!httpCall.error) {
        this.data.reservation.id = httpCall.id;
        this.data.reservation.reservationCode = httpCall.reservationCode;
      }

      return this.manageHttpError(httpCall);

    } catch (err) {
      console.log(err);
      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'reservation-error',
          error: "C'è stato un errore nel salvataggio. Verifica che i dati siano coerenti. In caso di stampa fiscale, verifica che questa sia stata tracciata nella prenotazione."
        }
      });
      return err;
    }

  }

  
  manageHttpError(httpCall) {

    if (httpCall.error) {

      let error = httpCall.error;

      if (httpCall.error.error) {
        error = httpCall.error.error;
      }

      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'reservation-error',
          error: error
        }
      });

      dialogRef.afterClosed().subscribe(result => {
       
      });
      return false;
    } else {

      if (httpCall.id) {
        this.data.reservation.id = httpCall.id;
        this.data.reservation.reservationCode = httpCall.reservationCode;
      }

    }
  }

}
