import { Component, OnInit } from '@angular/core';
import { DataService} from '../../../../core/providers/data.service';
import { SettingsService } from '../../../../core/providers/settings.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs';
import { MatDialog} from '@angular/material';

@Component({
  selector: 'app-map-booking',
  templateUrl: './map-booking.component.html',
  styleUrls: ['./map-booking.component.scss']
})
export class MapBookingComponent implements OnInit {

  objectKeys = Object.keys;

  // GLOBAL VARIABLES
  loading=false;startTimestamp;endTimestamp;startSerializedDate;endSerializedDate;showBottomBar=false;showRightBar=false;
  startDate;endDate;loader;lang='it';pageClicked=false;selectedReservation;selectedReservations;selectedSlot;selectedSlots=[];
  showQuickReservation =false;reserveFlag=true;optionals; term$ = new Subject<string>();selectedIndex;showPackageModal;
  availablePackages;selectedPackage = null;selectedPackageIndex;selectedZone = null;selectedPackages = [];commonPackages;

  // RESORTS DATA
  resortData;managerData;mapData;reservationMapData;prices;elements;features;services;

  // MAP VARIABLES
  startMapWidth; startMapHeight; mapHeight; mapWidth; zoomStep=1; disableDatesFilter;
  slotHeight=50; slotSpace=15; startMarginBottomElement = 15;startPaddingLeftRight = 50;
  startOffset = 25;startPaddingTop = 150; startPaddingBottom = 100; startMinWidth = 700;startMinHeight = 700;
  startHeight = 65; startImageHeight = 30; startCircleHeight = 40; startElementHeight = 40;intervalId;
  lastSlot = null;
  startSlotLabel = 12; startMarginElement = 5; customersFound; lastMapUpdate: Observable<any>;

  // MAP VARIABLES
  grid = [];

  // INTERVAL
  bookingIntervals;
  selectedInterval;
  newLoad = false;
  disableSave = true;
  isSeasonal = false;

  constructor(public dialog: MatDialog,  private route: ActivatedRoute, public router: Router, private dataService: DataService, private settingsService: SettingsService, private db: AngularFireDatabase) {

    this.intervalId = this.route.snapshot.paramMap.get('intervalId');
    this.isSeasonal = (this.route.snapshot.paramMap.get('seasonal') == 'true');
    console.log("SEASONAL", this.isSeasonal);
    console.log(this.intervalId);
  }

  async ngOnInit() {

    this.newLoad = true;
    this.disableSave = true;

    if (!this.mapData) {
      this.loading = true;
    }

    if (this.mapData) {
      this.newLoad = false;
    }

    if (!this.resortData) {

      await this.dataService.initResortData();
    }

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.mapData = this.dataService.map;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;
    this.prices = this.dataService.prices;

    if (!this.dataService.bookingIntervals) {
      this.bookingIntervals = await this.dataService.getBookingIntervals();
    } else {
      this.bookingIntervals = this.dataService.bookingIntervals;
    }

    this.selectedInterval = this.bookingIntervals.find(book => book.id ==  this.intervalId);
    console.log(this.bookingIntervals);
    console.log(this.selectedInterval);

    if (this.selectedInterval) {

      if (this.selectedInterval.slots === null) {
        this.selectedInterval.slots = [];
      }

      this.startTimestamp = this.selectedInterval.start * 1000;
      this.startSerializedDate = new Date(this.startTimestamp);
      this.endTimestamp = this.selectedInterval.end * 1000;
      this.endSerializedDate = new Date(this.endTimestamp);

      const offsetStart = new Date(this.startSerializedDate).getTimezoneOffset() * 60000;
      const offsetEnd = new Date(this.endSerializedDate).getTimezoneOffset() * 60000;
      this.selectedInterval.end = ((new Date(this.endSerializedDate)).setHours(0, 0, 0) - offsetEnd ) / 1000;
      this.selectedInterval.start = ((new Date(this.startSerializedDate)).setHours(0, 0, 0) - offsetStart ) / 1000;

    } else {


      let reservationType = 'booking'


      const offsetStart = new Date().getTimezoneOffset() * 60000;
      const offsetEnd = new Date().getTimezoneOffset() * 60000;

      let endDate = ((new Date()).setHours(0, 0, 0, 0) - offsetEnd )/1000 ;
      let startDate = ((new Date()).setHours(0, 0, 0, 0) - offsetStart )/1000 ;


      if (this.isSeasonal) {
        reservationType = 'seasonal';
        console.log(this.resortData.openingDate);
        endDate = this.resortData.closingDate;
        startDate = this.resortData.openingDate;
      }

      this.startSerializedDate = new Date(startDate * 1000);
      this.endSerializedDate = new Date(endDate * 1000);

      this.selectedInterval = {
        slots: [],
        start: startDate,
        end: endDate,
        reservationType
      }
    }

    console.log(this.selectedInterval);

    if (this.mapData) {
      this.zoomStep = this.resortData.zoom;
      this.startMapWidth = (this.resortData.cols * 50 + (this.resortData.cols  - 1) * 15);
      this.startMapHeight = (this.resortData.rows * 50 + (this.resortData.rows - 1) * 15);
      this.setupGrid();
    }


    if (this.newLoad) {
      this.zoomStep = this.resortData.zoom;
      this.startMapWidth = (this.resortData.cols*50 + (this.resortData.cols-1)*15);
      this.startMapHeight = (this.resortData.rows*50 + (this.resortData.rows-1)*15);
      this.setupGrid();
      this.loading = false;
    }

    if (this.managerData.supermanager || this.managerData.role === 'owner') {
      this.disableSave = false;
    }
  }

  setupGrid() {

    this.grid = [];

    for (let i=0; i < this.resortData.rows; i++) {
      for (let j=0; j < this.resortData.cols; j++) {

          let foundMap = this.mapData.find(slot => slot.col == j && slot.row == i);

          if (!foundMap) {
            this.mapData.push({
              row :  i,
              col : j,
              defaultPackKey: '',
              defaultPackId: 0,
              label: "",
              resortKey: '',
              resortId: this.resortData.id,
              packagesKeys: [],
              packagesIds: [],
              zoneKey: '',
              zoneId: 0,
              version: 4,
              new: true
            });
          }
      }
    }

    for (let index = 0; index < this.mapData.length; index += 1) {

        const rowIndex = this.mapData[index].row;
        const colIndex = this.mapData[index].col;


        if (!this.grid[rowIndex]) {
          this.grid[rowIndex] = {};
        }

        if (!this.grid[rowIndex][colIndex]) {
          this.grid[rowIndex][colIndex] = {};
        }

        this.grid[rowIndex][colIndex] = this.mapData[index];
        this.grid[rowIndex][colIndex].selected = false;
        this.grid[rowIndex][colIndex].matrixIndex = index;
        this.grid[rowIndex][colIndex].startingHeight = this.startHeight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].paddingLeftRight = this.startPaddingLeftRight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].slotElementHeight = this.startHeight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].slotLabel = 'AAA';
        this.grid[rowIndex][colIndex].reservationsKeys = false;
        this.grid[rowIndex][colIndex].state = 1;
        this.grid[rowIndex][colIndex].elementId = null;

        if (this.grid[rowIndex][colIndex].packagesIds && this.grid[rowIndex][colIndex].packagesIds.length) {
          let selectedPack = this.resortData.packagesByIds[this.grid[rowIndex][colIndex].packagesIds[0]];

          if (this.grid[rowIndex][colIndex].defaultPackId) {
             selectedPack = this.resortData.packagesByIds[this.grid[rowIndex][colIndex].defaultPackId];
          }

          for (const element of selectedPack.elements) {
            if (element.element.cover) {
              this.grid[rowIndex][colIndex].elementId = element.element.id;
              break;
            } else if (!this.grid[rowIndex][colIndex].elementId) {
              this.grid[rowIndex][colIndex].elementId = element.element.id;
            }
          }
        }

        if (!this.grid[rowIndex][colIndex].elementId) {
          this.grid[rowIndex][colIndex].elementId = 1;
        }

    }

  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout(()=>{
      this.pageClicked = false;
    }, 1000)
  }

  resetMap() {

  }

  async saveInterval() {
    this.loading = true;
    const offsetStart = new Date(this.startSerializedDate).getTimezoneOffset() * 60000;
    const offsetEnd = new Date(this.endSerializedDate).getTimezoneOffset() * 60000;
    this.selectedInterval.end = ((new Date(this.endSerializedDate)).setHours(0, 0, 0) - offsetEnd ) / 1000;
    this.selectedInterval.start = ((new Date(this.startSerializedDate)).setHours(0, 0, 0) - offsetStart ) / 1000;

    console.log(this.selectedInterval)

    if (this.selectedInterval.id) {
      await this.dataService.updateBookingInterval(this.selectedInterval);
    } else {
      await this.dataService.createBookingInterval(this.selectedInterval);
    }

    this.loading = false;
    this.router.navigate(['settings/offer/cocobuk/0']);
  }

  selectAll() {

    for (const rowKey in this.grid) {
      if (this.grid[rowKey]) {
        for (const colKey in this.grid[rowKey]) {
          if (this.grid[rowKey][colKey] && this.grid[rowKey][colKey].zoneId && this.grid[rowKey][colKey].packagesIds.length) {

            if (this.selectedInterval.slots.indexOf(rowKey + '_' + colKey) === -1) {
              this.selectedInterval.slots.push(rowKey + '_' + colKey)
              this.selectedSlots.push(rowKey + '_' + colKey);
            }
          }
        }
      }
    }
  }

  deselectAll() {

    for (const rowKey in this.grid) {
      if (this.grid[rowKey]) {
        for (const colKey in this.grid[rowKey]) {

            if (this.selectedInterval.slots.indexOf(rowKey + '_' + colKey) > -1) {
              this.selectedSlot = null;
              let index = this.selectedSlots.indexOf(rowKey + '_' + colKey);
              this.selectedSlots.splice(index, 1);
              index = this.selectedInterval.slots.indexOf(rowKey + '_' + colKey);
              this.selectedInterval.slots.splice(index, 1);
            }
        }
      }
    }
  }

  toggleSlot(slot, evt) {
    console.log(slot);

    if (evt.shiftKey) {
      console.log('SHIFT PRESSED');
      console.log(this.lastSlot);

      if (slot.row >= this.lastSlot.row && slot.col >= this.lastSlot.col) {
        const rowDifference = (slot.row - this.lastSlot.row) + 1;
        const colDifference = (slot.col - this.lastSlot.col) + 1;


        for (let i = 0; i < rowDifference; i++) {
          const selectedRow = this.lastSlot.row + i;

          for (let j = 0; j < colDifference; j++) {
            const selectedCol = this.lastSlot.col + j;

            if (this.grid[selectedRow][selectedCol].packagesIds.length > 0 && this.grid[selectedRow][selectedCol].zoneId && !((selectedRow == this.lastSlot.row && selectedCol == this.lastSlot.col) || (selectedRow == slot.row && selectedCol == slot.col))) {

              if (this.selectedInterval.slots.indexOf(selectedRow + '_' + selectedCol) === -1) {
                this.selectedInterval.slots.push(selectedRow + '_' + selectedCol)
                this.selectedSlots.push(selectedRow + '_' + selectedCol);
              } else {
                this.selectedSlot = null;
                let index = this.selectedSlots.indexOf(selectedRow + '_' + selectedCol);
                this.selectedSlots.splice(index, 1);
                index = this.selectedInterval.slots.indexOf(selectedRow + '_' + selectedCol);
                this.selectedInterval.slots.splice(index, 1);
              }
            }
          }
        }

      }
    }

    if (this.selectedInterval.slots.indexOf(slot.row + '_' + slot.col) === -1) {
      this.selectedInterval.slots.push(slot.row + '_' + slot.col)
      this.selectedSlots.push(slot.row + '_' + slot.col);
    } else {
      this.selectedSlot = null;
      let index = this.selectedSlots.indexOf(slot.row + '_' + slot.col);
      this.selectedSlots.splice(index, 1);
      index = this.selectedInterval.slots.indexOf(slot.row + '_' + slot.col);
      this.selectedInterval.slots.splice(index, 1);
    }

    slot.selected = !slot.selected;

    if (slot.selected) {
      this.lastSlot = slot;
    }


  }

  zoomPlus() {
    this.zoomStep = this.zoomStep + 0.25;
  }

  zoomMinus() {
    this.zoomStep = this.zoomStep - 0.25;
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    if (this.startSerializedDate > this.endSerializedDate) {
      this.endSerializedDate = this.startSerializedDate;
    }

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    if (this.endSerializedDate < this.startSerializedDate) {
      this.startSerializedDate = this.endSerializedDate;
    }

  }

}
