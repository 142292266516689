import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../core/providers/data.service';
import { SettingsService } from '../../../../core/providers/settings.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs';
import { MatDialog, MatSnackBar} from '@angular/material';
import { ModalConfirmComponent } from '../../../../shared/components/modal-confirm/modal-confirm.component';
import { ModalRemoveComponent } from '../../../../shared/components/modal-remove/modal-remove.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { ModalErrorComponent } from 'src/app/shared/components/modal-error/modal-error.component';
import { TileModalComponent } from 'src/app/settings/components/tile-modal/tile-modal.component';

@Component({
  selector: 'app-map-detail',
  templateUrl: './map-detail.component.html',
  styleUrls: ['./map-detail.component.scss']
})
export class MapDetailComponent implements OnInit {

  objectKeys = Object.keys;

  // GLOBAL VARIABLES
  loading=false;startTimestamp;endTimestamp;startSerializedDate;endSerializedDate;showBottomBar=false;showRightBar=false; oldResortData;
  startDate;endDate;loader;lang='it';pageClicked=false;selectedReservation;selectedReservations;selectedSlot;selectedSlots=[];
  showQuickReservation =false;reserveFlag=true;optionals; term$ = new Subject<string>();selectedIndex;showPackageModal;zero=0;
  availablePackages;selectedPackage = null;selectedPackageIndex;selectedZone = null;selectedPackages = [];commonPackages;labelsLogic=null;
  showTip = false; showTipRemove = false;

  // RESORTS DATA
  resortData;managerData;mapData;prices;elements;features;services; showGridBox = false; showTemplateBox = false;
  errorFlag = false;startNumber=0;startString='';endString='';packagesSlot:any = [];


  // MAP VARIABLES
  startMapWidth;startMapHeight;mapHeight;mapWidth;zoomStep=1;uploadPercentSvg;uploadPercentSvgOptimized;uploadPercentPng; uploadPercentPdf;
  slotHeight=50;slotSpace=15;startMarginBottomElement = 15;startPaddingLeftRight = 50;
  startOffset = 25;startPaddingTop = 150;startPaddingBottom = 100;startMinWidth = 700;startMinHeight = 700;
  startHeight = 65;startImageHeight = 30;startCircleHeight = 40;startElementHeight = 40;
  lastSlot = null;
  startSlotLabel = 12;startMarginElement = 5;customersFound;lastMapUpdate: Observable<any>;

  // MAP VARIABLES
  grid = [];
  alphabetLookup = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y",
  "AA","AB","AC","AD","AE","AF","AG","AH","AI","AJ","AK","AL","AM","AN","AO","AP","AQ","AR","AS","AT","AU","AV","AW","AX","AY",
  "BA","BB","BC","BD","BE","BF","BG","BH","BI","BJ","BK","BL","BM","BN","BO","BP","BQ","BR","BS","BT","BU","BV","BW","BX","BY",
  "CA","CB","CC","CD","CE","CF","CG","CH","CI","CJ","CK","CL","CM","CN","CO","CP","CQ","CR","CS","CT","CU","CV","CW","CX","CY",
  "DA","DB","DD","DD","DE","DF","DG","DH","DI","DJ","DK","DL","DM","DN","DO","DP","DQ","DR","DS","DT","DU","DV","DW","DX","DY",
  "EA","EB","ED","ED","EE","EF","EG","EH","EI","EJ","EK","EL","EM","EN","EO","EP","EQ","ER","ES","ET","EU","EV","EW","EX","EY",
  "FA","FB","FD","FD","FE","FF","FG","FH","FI","FJ","FK","FL","FM","FN","FO","FP","FQ","FR","FS","FT","FU","FV","FW","FX","FY",
  "GA","GB","GD","GD","GE","GF","GG","GH","GI","GJ","GK","GL","GM","GN","GO","GP","GQ","GR","GS","GT","GU","GV","GW","GX","GY",
  "HA","HB","HD","HD","HE","HF","HG","HH","HI","HJ","HK","HL","HM","HN","HO","HP","HQ","HR","HS","HT","HU","HV","HW","HX","HY",
  "IA","IB","ID","ID","IE","IF","IG","IH","II","IJ","IK","IL","IM","IN","IO","IP","IQ","IR","IS","IT","IU","IV","IW","IX","IY",
  "JA","JB","JD","JD","JE","JF","JG","JH","JI","JJ","JK","JL","JM","JN","JO","JP","JQ","JR","JS","JT","JU","JV","JW","JX","JY",
  "KA","KB","KD","KD","KE","KF","KG","KH","KI","KJ","KK","KL","KM","KN","KO","KP","KQ","KR","KS","KT","KU","KV","KW","KX","KY"]

  // RESERVATION
  newReservation;reservationTotal;
  newLoad = false;packagesOrder=[];
  tiles={};

  constructor(public snackBar: MatSnackBar,  private storage: AngularFireStorage,  public dialog: MatDialog, public router: Router, public dataService: DataService, private settingsService: SettingsService, private db: AngularFireDatabase) {


      this.resortData = this.dataService.resortData;
      this.managerData = this.dataService.userData;
      this.mapData = this.dataService.map;
      this.prices = this.dataService.prices;
      this.optionals = this.dataService.optionals;
      this.lang = this.dataService.lang;

      if (this.mapData) {
        this.zoomStep = this.resortData.zoom;
        this.startMapWidth = (this.resortData.cols*50 + (this.resortData.cols-1)*15);
        this.startMapHeight = (this.resortData.rows*50 + (this.resortData.rows-1)*15);
        console.log("start setup grid");
        this.setupGrid();
      }

  }

  async ngOnInit() {
    this.newLoad = true;

    if (!this.mapData) {
      this.loading = true;
    }

    if (this.mapData) {
      this.newLoad = false;
    }

    if (!this.resortData) {
      await this.dataService.initResortData();
    }

    this.resortData = this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.prices =  this.dataService.prices;
    this.optionals =  this.dataService.optionals;
    this.elements =  this.dataService.elements;
    this.features =  this.dataService.features;
    this.services =  this.dataService.services;
    this.mapData = this.dataService.map;
    this.oldResortData = JSON.parse(JSON.stringify(this.resortData));
    this.packagesSlot = this.resortData.packages.filter(p=> { return (p.type === 'slot')?true:false;  });
    this.packagesOrder = this.resortData.packages.filter(p=> { return (p.type === 'order')?true:false;  });

    


    if (this.newLoad) {
      this.zoomStep = this.resortData.zoom;
      this.startMapWidth = (this.resortData.cols * 50 + (this.resortData.cols - 1) * 15);
      this.startMapHeight = (this.resortData.rows * 50 + (this.resortData.rows - 1) * 15);

      this.setupGrid();
      this.loading = false;
    }

    this.updateTiles();
  }

  updateTiles() {
    console.log(this.resortData.packages);

    this.packagesOrder = this.resortData.packages.filter(p=> { return (p.type === 'order')?true:false;  });

    for (let p of this.packagesOrder) {
      let cover = p.elements.find(e => e.element.cover === true);
      if (cover) {
        p.cover = cover.element.id;
      } else {
        p.cover = p.elements[0].element.id;
      }
    }
    this.tiles = {};
    for (let pack of this.packagesOrder) {
      if (pack.settings && pack.settings.mapTile) {
        console.log(pack.id);
        for (let zone of pack.settings.mapTile.zones) {
          this.tiles[zone.row+'_'+zone.col] ={
            zoneId:zone.zoneId,
            row: zone.row,
            col: zone.col,
            packageId: pack.id,
            cover: this.resortData.packagesByIds[pack.id].cover
          }
        }
      }
    }

  }

  setAutoLabel() {
    /* <option [ngValue]="null">Nessun criterio</option>
    <option value="ascNumbers"> Numeri crescenti</option>
    <option value="descNumbers"> Numeri decrescenti</option>
    <option value="rowsLettersColsNumbers">Righe lettere, colonne numeri</option>
    <option value="colsNumbersRowsLetters">Righe numeri, colonne lettere</option> */

    let counter = this.startNumber;
    for (const slot of this.selectedSlots) {
      const rowKey = slot.split("_")[0];
      const colKey = slot.split("_")[1];

      const gridSlot = this.grid[rowKey][colKey];

      if (gridSlot.defaultPackId && gridSlot.zoneId ) {
        if (this.labelsLogic === 'ascNumbers') {
          gridSlot.label = this.startString + counter.toString() + this.endString;
          counter++;
        } else if (this.labelsLogic === 'descNumbers') {
          gridSlot.label = this.startString + counter.toString() + this.endString;
          counter--;
        }
      }
    }
  }

  setupGrid() {

    this.grid = [];

    
    for (let pack of this.resortData.packages) {
      pack.selected = null;
    }

    let counter = 0;

    for (let i=0; i < this.resortData.rows; i++) {
      for (let j=0; j < this.resortData.cols; j++) {

          let foundMap = this.mapData.find(slot => slot.col == j && slot.row == i);

          if (!foundMap) {
            this.mapData.push({
              row :  i,
              col : j,
              defaultPackKey: '',
              defaultPackId: 0,
              label: this.alphabetLookup[i] + " " + j,
              resortKey: '',
              resortId: this.resortData.id,
              packagesKeys: [],
              packagesIds: [],
              zoneKey: '',
              zoneId: 0,
              version: 4,
              new: true
            });
          }
      }
    }
    console.log("STEP 1 END")
    for (let index = 0; index < this.mapData.length; index += 1) {

        const rowIndex = this.mapData[index].row;
        const colIndex = this.mapData[index].col;


        if (!this.grid[rowIndex]) {
          this.grid[rowIndex] = {};
        }

        if (!this.grid[rowIndex][colIndex]) {
          this.grid[rowIndex][colIndex] = {};
        }

        this.grid[rowIndex][colIndex] = this.mapData[index];
        this.grid[rowIndex][colIndex].selected = false;
        this.grid[rowIndex][colIndex].matrixIndex = index;
        this.grid[rowIndex][colIndex].startingHeight = this.startHeight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].paddingLeftRight = this.startPaddingLeftRight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].slotElementHeight = this.startHeight * 0.65 * this.zoomStep;
        this.grid[rowIndex][colIndex].slotLabel = 'AAA';
        this.grid[rowIndex][colIndex].reservationsKeys = false;
        this.grid[rowIndex][colIndex].state = 1;
        this.grid[rowIndex][colIndex].elementId = null;

        const newPacks = JSON.parse(JSON.stringify(this.grid[rowIndex][colIndex].packagesIds));

        for (const pack of this.grid[rowIndex][colIndex].packagesIds) {
          if (!this.resortData.packagesByIds[pack]) {
            let ind = newPacks.indexOf(pack);
            newPacks.splice(ind,1);
          } else if (this.resortData.packagesByIds[pack].type === 'order') {
            let ind = newPacks.indexOf(pack);
            newPacks.splice(ind,1);
          }
        }

        this.grid[rowIndex][colIndex].packagesIds = newPacks;

        this.settingsService.setSlotElement(this.grid[rowIndex][colIndex],this.dataService.resortData);

        /* if (this.grid[rowIndex][colIndex].elementId === 1) {
          this.grid[rowIndex][colIndex].elementId = null;
        } */

        if (this.grid[rowIndex][colIndex].packagesIds.length && (!this.grid[rowIndex][colIndex].zoneId || !this.grid[rowIndex][colIndex].defaultPackId)) {
          console.log("FLAG FALSE ", this.grid[rowIndex][colIndex])
          this.grid[rowIndex][colIndex].error = true;
          this.errorFlag = true;
        } else if (this.grid[rowIndex][colIndex].zoneId && this.grid[rowIndex][colIndex].packagesIds.length == 0) {

          console.log("FLAG FALSE ", this.grid[rowIndex][colIndex])
          this.grid[rowIndex][colIndex].error = true;
          this.errorFlag = true;
        }

    }
    console.log("STEP 2 END")

  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout(()=>{
      this.pageClicked = false;
    },1000)
  }


  toggleSlot(slot, evt) {
    console.log(slot);
    if (evt.shiftKey) {

      if (slot.row >= this.lastSlot.row && slot.col >= this.lastSlot.col) {
        const rowDifference = (slot.row - this.lastSlot.row) + 1;
        const colDifference = (slot.col - this.lastSlot.col) + 1;


        for (let i = 0; i < rowDifference; i++) {
          const selectedRow = this.lastSlot.row + i;

          for (let j = 0; j < colDifference; j++) {
            const selectedCol = this.lastSlot.col + j;

            if (!((selectedRow == this.lastSlot.row && selectedCol == this.lastSlot.col) || (selectedRow == slot.row && selectedCol == slot.col))) {
              this.grid[selectedRow][selectedCol].selected = !this.grid[selectedRow][selectedCol].selected;

              if (this.grid[selectedRow][selectedCol].selected) {
                  this.selectedSlots.push(selectedRow + '_' + selectedCol);
              } else {
                const index = this.selectedSlots.indexOf(selectedRow + '_' + selectedCol);
                this.selectedSlots.splice(index, 1);
              }
            }
          }

        }
      } else if (slot.row < this.lastSlot.row && slot.col < this.lastSlot.col) {
        const rowDifference = ( this.lastSlot.row - slot.row) + 1;
        const colDifference = (this.lastSlot.col - slot.col) + 1;
        for (let i = 0; i < rowDifference; i++) {
          const selectedRow = this.lastSlot.row - i;

          for (let j = 0; j < colDifference; j++) {
            const selectedCol = this.lastSlot.col - j;

            if (!((selectedRow == this.lastSlot.row && selectedCol == this.lastSlot.col) || (selectedRow == slot.row && selectedCol == slot.col))) {
              this.grid[selectedRow][selectedCol].selected = !this.grid[selectedRow][selectedCol].selected;

              if (this.grid[selectedRow][selectedCol].selected) {
                  this.selectedSlots.push(selectedRow + '_' + selectedCol);
              } else {
                const index = this.selectedSlots.indexOf(selectedRow + '_' + selectedCol);
                this.selectedSlots.splice(index, 1);
              }
            }
          }

        }
      }
    }

    if (!slot.selected) {
      this.selectedSlot = slot;
      this.selectedSlots.push(slot.row + '_' + slot.col);
    } else {
      this.selectedSlot = null;
      const index = this.selectedSlots.indexOf(slot.row + '_' + slot.col)
      this.selectedSlots.splice(index,1);
    }

    if (this.selectedSlot) {
      this.showBottomBar = true;
    }

    if (this.selectedSlots.length === 0) {
      this.showBottomBar = false;
    }

    if (this.selectedSlots.length === 1) {
      const row = this.selectedSlots[0].split('_')[0];
      const col = this.selectedSlots[0].split('_')[1];

      this.selectedSlot = this.grid[row][col];
    }

    slot.selected = !slot.selected;

    if (slot.selected) {
      this.lastSlot = slot;
    }

    this.checkZone();
    this.checkDefaultPackage();
    this.checkPackages();

  }

  checkZone() {
    this.selectedZone = null;

    for (const slot of this.selectedSlots) {

      const row = slot.split('_')[0];
      const col = slot.split('_')[1];

      if (this.selectedZone === null) {
        this.selectedZone = this.grid[row][col].zoneId;
      } else if (this.selectedZone !== this.grid[row][col].zoneId) {
        this.selectedZone = null;
        break;
      }
    }

    if (!this.selectedZone) {
      this.selectedZone = null;
    }

  }

  updateDefaultPackage() {
    for (const slot of this.selectedSlots) {
      const row = slot.split('_')[0];
      const col = slot.split('_')[1];
      this.grid[row][col].defaultPackId = this.selectedPackage;
      this.changeIcon(row,col);
    }
    this.checkData();
  }

  changeIcon(row,col)  {
    this.grid[row][col].elementId = null;

    console.log(this.grid[row][col]);
    if (this.grid[row][col].packagesIds && this.grid[row][col].packagesIds.length) {
      let selectedPack = this.resortData.packagesByIds[this.grid[row][col].packagesIds[0]];

      if (this.grid[row][col].defaultPackId) {
         selectedPack = this.resortData.packagesByIds[this.grid[row][col].defaultPackId];
      }

      if (selectedPack && selectedPack.elements) {
        for (const element of selectedPack.elements) {
          if (element.element.cover) {
            this.grid[row][col].elementId = element.element.id;
            break;
          } else if (!this.grid[row][col].elementId) {
            this.grid[row][col].elementId = element.element.id;
          }
        }
      }
    }
  }

  async saveMap() {

    try {
      this.loading = true;
      const self = this;
      const postArray = [];
      const putArray = [];


      for (const slotObj of this.mapData) {
        let slot = JSON.parse(JSON.stringify(slotObj));

        slot.packagesIds = this.grid[slot.row][slot.col].packagesIds;
        slot.slotLabel = this.grid[slot.row][slot.col].slotLabel;
        slot.zoneId = this.grid[slot.row][slot.col].zoneId;
        slot.zoneKey = this.grid[slot.row][slot.col].zoneKey;
        slot.label = this.grid[slot.row][slot.col].label;
        slot.elementId = this.grid[slot.row][slot.col].elementId;


        slot.defaultPackId = parseInt(this.grid[slot.row][slot.col].defaultPackId);
        if (slot.defaultPackId && slot.packagesIds.indexOf(slot.defaultPackId) === -1) {
          slot.packagesIds.push(slot.defaultPackId);
        }
        if (!slot.id && slot.packagesIds.length && slot.zoneId && slot.defaultPackId && slot.label && slot.label != '') {
          postArray.push(slot);
        } else if (slot.id) {
          if (slot.id === 624561) {
            console.log("UPDATE SLOT", slot);
            console.log("UPDATE SLOT", this.grid[slot.row][slot.col]);
          }

          putArray.push(slot);
        }

      }

      let response = null;
      let error = false;

      if (postArray.length) {
        response = await this.dataService.setMap(postArray);
      }

      if (putArray.length) {
        response = await this.dataService.updateMap(putArray);
      }

      console.log(response);

      if (response && response.status) {
        error = true;
      } else {
        await this.dataService.getMap();
        this.mapData = this.dataService.map;
      }

      this.loading = false;

      let dialogRef = this.dialog.open(ModalConfirmComponent, {
        width: '340px',
        height: '110px',
        data: {
          error
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.setupGrid();
        self.resetMap();
      });

    } catch(err) {

      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'save-error',
          error: JSON.stringify(err)
        }
      });
    }
  }

  async saveResort() {
    try {
      this.loading = true;
      await this.dataService.setResort(this.resortData);
      this.showTemplateBox = false;
      this.loading = false;
      this.openSnackBar('INFORMAZIONI SALVATE CORRETTAMENTE', 'OK');
    } catch(err) {

      const dialogRef = this.dialog.open(ModalErrorComponent, {
        width: '400px',
        data: {
          type: 'save-error',
          error: JSON.stringify(err)
        }
      });
    }
  }

  addTile(packageId,zoneId,slot) {

    let dialogRef = this.dialog.open(TileModalComponent, {
      data: {
        packageId:packageId,
        zoneId: zoneId,
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      try {
        console.log(result, this.resortData.packages);
        if (result) {
          let packageToSave = this.resortData.packages.find((p:any)=>p.id === result.packageId);

            // PUSHA TILE NEL PACKAGE NO SLOTS
            // SALVA RESORTDATA
            if (packageToSave) {

              if (!result.remove) {
                if (!packageToSave.settings) {
                  packageToSave.settings = {
                    mapTile: {
                      zones:[]
                    }
                  }
                } else  if (!packageToSave.settings.mapTile) {
                  packageToSave.settings.mapTile = {
                    zones:[]
                  }
                }

                if (packageId) {
                  let foundZone = packageToSave.settings.mapTile.zones.find(z => z.zoneId === zoneId);
                  foundZone.row = parseInt(slot.split("_")[0]);
                  foundZone.col = parseInt(slot.split("_")[1]);
                  foundZone.zoneId = result.zoneId
                } else {
                  packageToSave.settings.mapTile.zones.push({
                    row: parseInt(slot.split("_")[0]),
                    col:parseInt(slot.split("_")[1]),
                    zoneId:  result.zoneId
                  })
                }
              } else {
                let index = packageToSave.settings.mapTile.zones.findIndex(z => z.zoneId === zoneId)
                packageToSave.settings.mapTile.zones.splice(index,1)
              }
              // packageFeaturesAvailabilities
              packageToSave.packageFeaturesAvailabilities = [];

              for (let feature of packageToSave.package_features) {
                packageToSave.packageFeaturesAvailabilities.push({
                  package_feature : feature.id
                });
              }


              // packageServicesAvailabilities
              packageToSave.packageServicesAvailabilities = [];
              for (let service of packageToSave.package_services) {
                packageToSave.packageServicesAvailabilities.push({
                  package_service : service.id
                });
              }

              // elementsAvailabilities
              packageToSave.elementsAvailabilities = [];
              for (let element of packageToSave.elements) {
                packageToSave.elementsAvailabilities.push({
                  element : element.element.id,
                  quantity : element.quantity
                });
              }

              // rulesAvailabilities
              packageToSave.rulesAvailabilities = [];

              if (packageToSave.rules.length) {
                packageToSave.rulesAvailabilities.push({
                  rule : 1,
                  value : packageToSave.rules[0].value
                });
              }

              // optionalsAvailabilities
              packageToSave.optionalsAvailabilities = [];

              for (let optional of packageToSave.optionals) {
                let pricesAvailabilities = this.dataService.pricesArray.filter((p)=>p.package_id === packageToSave.id && p.optional_id === optional.optional.id);
                packageToSave.optionalsAvailabilities.push({
                  optionalAvailable : { optional: optional.optional.id },
                  pricesAvailables : pricesAvailabilities
                });
              }

              // pricesAvailabilities - TUTTI I PREZZI DEL PACCHETTO
              packageToSave.pricesAvailabilities = this.dataService.pricesArray.filter((p)=>p.package_id === packageToSave.id && !p.optional_id);
              packageToSave = await this.dataService.updatePackage(packageToSave);
              console.log(packageToSave);
              let index = this.resortData.packages.findIndex(p => p.id === packageToSave.id);
              if (index > -1) {
                console.log(index,this.resortData.packages);
                this.resortData.packages[index] = packageToSave;
              }
              this.resortData.packagesByIds[packageToSave.id] = packageToSave;
              this.resetMap();
              this.openSnackBar('INFORMAZIONI SALVATE CORRETTAMENTE', 'OK');
            } else {
              alert("PACCHETTO NON TROVATO")
            }

        }

        this.updateTiles();
      } catch(err) {

        alert(err)
      }
    })
  }

  checkDefaultPackage() {

    this.selectedPackage = null;

    for (const slot of this.selectedSlots) {

      const row = slot.split('_')[0];
      const col = slot.split('_')[1];
      if (this.selectedPackage === null) {
        this.selectedPackage = this.grid[row][col].defaultPackId;
      } else if (this.selectedPackage !== this.grid[row][col].defaultPackId) {
        this.selectedPackage = null;
        break;
      }

      this.changeIcon(row,col);
    }

    if (!this.selectedPackage) {
      this.selectedPackage = null;
    }

    this.checkData();
  }

  checkPackages() {
    this.selectedPackages = [];
    this.commonPackages = [];

    for (const slot of this.selectedSlots) {

      const row = slot.split('_')[0];
      const col = slot.split('_')[1];

      if (this.selectedPackages.length === 0) {
        if (this.grid[row][col].packagesIds.length > 0) {
          for (const packId of this.grid[row][col].packagesIds) {
            this.selectedPackages.push(packId);
            this.commonPackages.push(packId);
          }
        } else {
          this.selectedPackages.push(null);
        }

      } else {
        if (this.grid[row][col].packagesIds.length > 0) {
          for (const packId of this.grid[row][col].packagesIds) {
            if (this.selectedPackages.indexOf(packId) === -1) {
              this.selectedPackages.push(packId);
            }
          }
        } else {
          if (this.selectedPackages.indexOf(null) === -1) {
            this.selectedPackages.push(null);
          }
        }
        this.commonPackages.filter(value => this.grid[row][col].packagesIds.includes(value));
      }

    }

    for (let pack of this.resortData.packages) {

      pack.selected = false;
      pack.indeterminate = false;

      if (this.commonPackages.indexOf(pack.id) > -1 && this.selectedPackages.indexOf(pack.id) > -1) {
        pack.selected = true;

        if (this.selectedPackages.indexOf(null) > -1 ) {
          pack.indeterminate = true;
        }
      } else if (this.commonPackages.indexOf(pack.id) === -1 && this.selectedPackages.indexOf(pack.id) > -1) {
        pack.indeterminate = true;
      }
    }


  }

  updatePackages() {
    console.log(this.selectedSlots);
    console.log(this.resortData.packages);
    for (const slot of this.selectedSlots) {
      const row = slot.split('_')[0];
      const col = slot.split('_')[1];
      for (const pack of this.resortData.packages) {

        if (pack.selected && this.grid[row][col].packagesIds.indexOf(pack.id) === -1) {
          this.grid[row][col].packagesIds.push(pack.id);
        } else if (!pack.selected && this.grid[row][col].packagesIds.indexOf(pack.id) > -1) {
          
          for (let packId of this.grid[row][col].packagesIds) {
            packId = parseInt(packId);
          }

          this.grid[row][col].packagesIds = this.grid[row][col].packagesIds.filter((item)=> {return item !== pack.id});

          if (this.grid[row][col].packagesIds.length === 0) {
            this.grid[row][col].defaultPackId = 0;

            if (this.selectedPackage) {
              this.selectedPackage = 0;
            }
          } else if (this.grid[row][col].defaultPackId === pack.id) {
            console.log("AGGIORNA DEFAULT PACK");
            this.grid[row][col].defaultPackId = this.grid[row][col].packagesIds[0];
            this.selectedPackage = this.grid[row][col].packagesIds[0];
          }
        }
      }

      this.changeIcon(row, col);
    }

    this.checkData();
  }


  checkData() {
    this.errorFlag = false;
    for (let i = 0; i < this.resortData.rows; i++) {
      for (let j = 0; j < this.resortData.cols; j++) {
          const row = i;
          const col = j;
          this.grid[row][col].error = false;
          if (this.grid[row][col].packagesIds.length && (!this.grid[row][col].zoneId || !this.grid[row][col].defaultPackId)) {
            console.log(this.grid[row][col]);
            this.grid[row][col].error = true;
            this.errorFlag = true;
          } else if (this.grid[row][col].zoneId && this.grid[row][col].packagesIds.length == 0) {
            console.log(this.grid[row][col]);
            this.grid[row][col].error = true;
            this.errorFlag = true;
          }
      }
    }
  }

  updateDefaultPackages() {
    for (const slot of this.selectedSlots) {
      const row = slot.split('_')[0];
      const col = slot.split('_')[1];
      this.grid[row][col].defaultPackId = this.selectedPackage;
      this.changeIcon(row, col);
    }

    this.checkData();
  }

  updateZone() {
    for (const slot of this.selectedSlots) {
      const row = slot.split('_')[0];
      const col = slot.split('_')[1];
      this.grid[row][col].zoneId = parseInt(this.selectedZone);
      this.grid[row][col].zoneKey =  this.grid[row][col].zoneId.toString();
      console.log(this.selectedZone)
    }

    this.checkData();
  }

  resetMap() {
    this.showBottomBar = false;
    this.lastSlot = null;
    this.showGridBox = false;
    this.showTemplateBox = false;

    for (const slot of this.selectedSlots) {

      const rowIndex = slot.split('_')[0];
      const colIndex = slot.split('_')[1];
      this.grid[rowIndex][colIndex].selected = false;

    }

    this.selectedSlots = [];
    this.selectedSlot = null;
  }

  zoomPlus() {
    this.zoomStep = this.zoomStep + 0.10;
  }

  zoomMinus() {
    this.zoomStep = this.zoomStep - 0.10;
  }

  async uploadPdf(event) {
    const file = event.target.files[0];
    console.log(file)

    if (file) {
      const filePath = 'resorts/' + this.dataService.resortData.id + '/map/' + file.name;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      // observe percentage changes
      this.uploadPercentPdf = task.percentageChanges();
      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(async () => {
            this.uploadPercentPdf = null;
            this.resortData.mapImages.pdfImage = await fileRef.getDownloadURL().toPromise();
            //this.saveExtra();
          })
       )
      .subscribe();
    }
  }

  removePdf() {
    this.resortData.mapImages.pdfImage = '';
    this.uploadPercentPdf = null;
  }

  async uploadPng(event) {
    const file = event.target.files[0];
    console.log(file)

    if (file) {
      const filePath = 'resorts/' + this.dataService.resortData.id + '/map/' + file.name;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      // observe percentage changes
      this.uploadPercentPng = task.percentageChanges();
      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(async () => {
            this.uploadPercentPng = null;
            this.resortData.mapImages.pngImage = await fileRef.getDownloadURL().toPromise();
            //this.saveExtra();
          })
       )
      .subscribe();
    }
  }

  removePng() {
    this.resortData.mapImages.pngImage = '';
    this.uploadPercentPng = null;
  }

  async uploadSvg(event) {
    const file = event.target.files[0];
    console.log(file)

    if (file) {
      const filePath = 'resorts/' + this.dataService.resortData.id + '/map/' + file.name;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      // observe percentage changes
      this.uploadPercentSvg = task.percentageChanges();
      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(async () => {
            this.uploadPercentSvg = null;
            this.resortData.mapImages.svgImage = await fileRef.getDownloadURL().toPromise();
            //this.saveExtra();
          })
       )
      .subscribe();
    }
  }

  removeSvg() {
    this.resortData.mapImages.svgImage = '';
    this.uploadPercentSvg = null;
  }

  async uploadSvgOptimized(event) {
    const file = event.target.files[0];
    console.log(file)

    if (file) {
      const filePath = 'resorts/' + this.dataService.resortData.id + '/map/' + file.name;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      // observe percentage changes
      this.uploadPercentSvgOptimized = task.percentageChanges();
      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(async () => {
            this.uploadPercentSvgOptimized = null;
            this.resortData.mapImages.svgImageOptimized = await fileRef.getDownloadURL().toPromise();
            //this.saveExtra();
          })
       )
      .subscribe();
    }
  }

  removeSvgOptimized() {
    this.resortData.mapImages.svgImageOptimized = '';
    this.uploadPercentSvgOptimized = null;
  }

  async updateGrid() {

    const newRows = this.resortData.rows - this.oldResortData.rows;
    const newCols = this.resortData.cols - this.oldResortData.cols;

    let dialogRef = this.dialog.open(ModalRemoveComponent, {
      width: '340px',
      data: {
        newRows: this.resortData.rows,
        newCols: this.resortData.cols,
        type: 'updateMap'
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      try {
        console.log(result);

        if (result) {
          this.loading = true;
          await this.saveResort();
          const removeSlots = [];
          if (newRows > 0) {
            for (let i=0; i < newRows; i++) {
                const rowIndex = this.oldResortData.rows + i;

                for (let j=0; j<this.resortData.cols; j++) {

                  const newSlot = {
                    row : rowIndex,
                    col : j,
                    defaultPackKey: '',
                    defaultPackId: 0,
                    label: this.alphabetLookup[rowIndex] + " " + j,
                    resortKey: '',
                    resortId: this.resortData.id,
                    packagesKeys: [],
                    packagesIds: [],
                    zoneKey: '',
                    zoneId: 0,
                    version: 4,
                  }

                  if (!this.mapData.find(sl => sl.row === newSlot.row && sl.col === newSlot.col)) {
                    this.mapData.push(newSlot);
                  }
                }

            }
          }

          if (newCols > 0) {
            for (let i=0; i < newCols; i++) {
                const colIndex = this.oldResortData.cols + i;

                for (let j=0; j<this.resortData.rows; j++) {
                  const newSlot = {
                    row : j,
                    col : colIndex,
                    defaultPackKey: '',
                    defaultPackId: 0,
                    label: this.alphabetLookup[j] + " " + colIndex,
                    resortKey: '',
                    resortId: this.resortData.id,
                    packagesKeys: [],
                    packagesIds: [],
                    zoneKey: '',
                    zoneId: 0,
                    version: 4
                  }

                  if (!this.mapData.find(sl => sl.row === newSlot.row && sl.col === newSlot.col)) {
                    this.mapData.push(newSlot);
                  }
                }

            }
          }

          if (newRows < 0) {
            for (let i=1; i<=(-newRows); i++) {
                let rowIndex = this.oldResortData.rows - i;

                for (let j=0; j<this.oldResortData.cols; j++) {

                  let slotFound = this.mapData.find(slot => slot.row == rowIndex && slot.col == j)

                  if (slotFound) {
                    let slotFoundIndex = this.mapData.findIndex(slot => slot.row == rowIndex && slot.col == j)
                    removeSlots.push({
                      id: slotFound.id
                    });
                    this.mapData.splice(slotFoundIndex,1)
                  }
                }

            }
          }

          if (newCols < 0 ) {
            for (let i=1; i<=(-newCols); i++) {
                let colIndex = this.oldResortData.cols - i;

                for (let j=0; j<this.oldResortData.rows; j++) {

                  let slotFound = this.mapData.find(slot => slot.col == colIndex && slot.row == j)
                  if (slotFound) {
                    let slotFoundIndex = this.mapData.findIndex(slot => slot.col == colIndex && slot.row == j)
                    removeSlots.push({
                      id: slotFound.id
                    });
                    this.mapData.splice(slotFoundIndex,1)
                  }
                }

            }
          }

          const mapCopy = JSON.parse(JSON.stringify(this.mapData));
          this.mapData = [];
          for (const slot of mapCopy) {

            if (slot.row >= this.resortData.rows || slot.col >= this.resortData.cols) {
              if (slot.id && !removeSlots.find(sl => sl.id === slot.id)) {
                removeSlots.push({
                  id: slot.id
                });
              }
            } else {
              if (!this.mapData.find(sl => sl.row === slot.row && sl.col === slot.col)) {
                this.mapData.push(slot);
              }
            }
          }

          let mapResponse;

          if (removeSlots.length > 0) {
          mapResponse = await this.dataService.deleteMap(removeSlots);
          }

          console.log(mapResponse);

          this.oldResortData = JSON.parse(JSON.stringify(this.resortData));

          this.startMapWidth = (this.resortData.cols * 50 + (this.resortData.cols - 1) * 15);
          this.startMapHeight = (this.resortData.rows * 50 + (this.resortData.rows - 1) * 15);
          this.setupGrid();
          this.loading = false;
          this.showGridBox = false;
        } else {
          this.loading = false;
          this.showGridBox = false;
        }


        this.openSnackBar('INFORMAZIONI SALVATE CORRETTAMENTE', 'OK');
      }
      catch(err) {

        const dialogRef = this.dialog.open(ModalErrorComponent, {
          width: '400px',
          data: {
            type: 'save-error',
            error: JSON.stringify(err)
          }
        });
      }
    });

  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
