import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/providers/auth.service';
import { DataService, Customer } from '../../../core/providers/data.service'
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email;password;managerData;emailConfirm;confirmMessage;errorMessage;showPwdReset=false; hide = true;
  subscriptionExpired = true;

  constructor(private router: Router, private dataService: DataService,public authService: AuthService) {


    if (this.dataService.userData && this.authService.subscription && this.authService) {

      if (this.authService.subscription.planId === 0 ) {
        this.router.navigate(['booking/home']);
      } else if (this.authService.subscription.planId === 3) {
        this.router.navigate(['suite/home']);
      } else if (this.authService.subscription.planId === 0) {
        this.router.navigate(['booking/home']);
      }
    }
  }

  async ngOnInit() {
  }

  async resetPassword(email) {
    console.log(email)
    let res = await this.authService.ForgotPassword(email);
    console.log(res);
    if (res == true) {
      this.confirmMessage = 'Ti abbiamo inviato una e-mail. Controlla la casella di posta';
    } else {
      this.errorMessage = res;
    }
  }

}
