import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts/highstock';
require('highcharts/modules/exporting')(Highcharts);
import { DataStatisticsService } from '../../../core/providers/data-statistics.service';


@Component({
  selector: 'app-statistics-cocobook',
  templateUrl: './statistics-cocobook.component.html',
  styleUrls: ['./statistics-cocobook.component.scss']
})
export class StatisticsCocobookComponent implements OnInit {

  resortData; managerData; pageClicked; prices; submenu; lang = 'it';elements; features;optionals;services; dataLang='it'; lightLoading = false;chart;
  loader = false; selectedZone = null;

  objectKeys = Object.keys;

  // DATE VARIABLES
  startTimestamp; endTimestamp; startSerializedDate; endSerializedDate;
  startDate; startDay; startMonth; startYear;
  endDate; endDay; endMonth; endYear;statistics;

  dayWithMoreAttendance;totalAttendance; averageStay;

  constructor(private statisticsData: DataStatisticsService, private dataService: DataService, private settingsService: SettingsService) {

    let currentMonth = new Date().getMonth()+1;
    let currentYear = new Date().getFullYear();
    let endDay = this.settingsService.daysInMonth(currentMonth,currentYear);
    this.dataLang = this.dataService.lang;

    // SETUP DATES
    const offsetStart = new Date(currentYear+'-'+currentMonth+'-01').getTimezoneOffset() * 60000;
    const offsetEnd = new Date(currentYear+'-'+currentMonth+'-'+endDay).getTimezoneOffset() * 60000;

    /* this.startTimestamp = (new Date(currentYear+'-'+currentMonth+'-01').setHours(0, 0, 0, 0) - offsetStart);
    this.endTimestamp = (new Date(currentYear+'-'+currentMonth+'-'+endDay).setHours(0, 0, 0, 0) - offsetEnd);

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate; */
    this.resortData = this.dataService.resortData;

  }

  async ngOnInit() {
    this.lightLoading = true;

    if (!this.resortData) {
      await this.dataService.initResortData();
      this.resortData = this.dataService.resortData;
    }


    this.startTimestamp = this.resortData.openingDate * 1000;
    this.endTimestamp = this.resortData.closingDate * 1000;

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate;

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;
    this.prices =  this.dataService.prices;
    this.services = this.dataService.packagesServices;
    this.features = this.dataService.features;
    this.elements = this.dataService.elements;

    await this.getStatistics();
    this.lightLoading = false;
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout( () => {
      this.pageClicked = false;
    }, 1000);
  }

  exportPDF() {

  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.lightLoading = true;
    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp)
      this.endTimestamp = this.startTimestamp;

    let endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);

    await this.getStatistics();
    this.lightLoading = false;
  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.lightLoading = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.startTimestamp = this.endTimestamp;
    }

    let startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    await this.getStatistics();
    this.lightLoading = false;
  }


  async getStatistics() {
    this.lightLoading = true;
    //this.mostSellingPacks = [];

    /* let attendances;
    let averageStay;
    let count;
    let bills;
    let revenues;
    let employmentRate;

    [attendances, averageStay, employmentRate, revenues] = await Promise.all([this.statisticsData.getAttendance(this.startTimestamp/1000, this.endTimestamp/1000,null,true,null,null,null), this.statisticsData.getAverageStay(this.startTimestamp/1000, this.endTimestamp/1000,null,true,null,null,null), this.statisticsData.getEmploymentRate(this.startTimestamp/1000, this.endTimestamp/1000, 'slot', null, null, null, null, true, this.selectedZone), this.statisticsData.getRevenues(this.startTimestamp/1000, this.endTimestamp/1000, null, true)]); */

    let attendances = await this.statisticsData.getAttendance(this.startTimestamp/1000, this.endTimestamp/1000,null,true,null,null,null);
    let averageStay= await this.statisticsData.getAverageStay(this.startTimestamp/1000, this.endTimestamp/1000,null,true,null,null,null);
    let revenues= await this.statisticsData.getEmploymentRate(this.startTimestamp/1000, this.endTimestamp/1000, 'slot', null, null, null, null, true, this.selectedZone);
    let employmentRate= await this.statisticsData.getRevenues(this.startTimestamp/1000, this.endTimestamp/1000, null, true)

    this.totalAttendance = 0;
    this.dayWithMoreAttendance = 0;

    let max = -1;
    let maxRevenue = -1;

    this.averageStay = averageStay.average_stay;

    console.log(employmentRate);
    let totPacks = 0;

    for (const pack in employmentRate.sellingPackages) {
      totPacks+= employmentRate.sellingPackages[pack];
    }


    for (const dayAttendance in attendances) {
      if (attendances[dayAttendance] > max) {
        this.dayWithMoreAttendance = dayAttendance;
        max = attendances[dayAttendance];
      }
      this.totalAttendance+=attendances[dayAttendance];
    }


    console.log(this.totalAttendance);
    console.log(this.dayWithMoreAttendance);
    console.log(totPacks);

    const data = [];

    for (let valueKey in revenues) {
      data.push(revenues[valueKey]);
    }

    let self = this;

    let plotBands = [];

    for (let i=this.startTimestamp; i<=this.endTimestamp;i+=24*60*60*1000) {
      let day = new Date(i).getDay();
      let year = new Date(i).getFullYear();
      let month = new Date(i).getMonth();
      let date = new Date(i).getDate();
      if (day == 6) {
        plotBands.push({
          color: '#dcdcdc',
          from: Date.UTC(year, month, date),
          to: Date.UTC(year, month, date+1)
        })
      }
    }

    console.log(plotBands);

    this.chart = new Chart({
      chart: {
        type: 'areaspline'
      },
      exporting: {
        enabled: true
      },
      title: {
        text: 'Ricavi'
      },
      credits: {
        enabled: false
      },
      xAxis: {
          type: 'datetime',
          min: self.startTimestamp,
          max: self.endTimestamp,
          tickInterval: 24 * 3600 * 1000,
          labels: {
              overflow: 'justify',
              formatter: function() {
                let date = (new Date(this.value)).getDate();
                let month = (new Date(this.value)).getMonth();
                let monthString = self.statisticsData.shortMonths[month]['it']

                let dateString = date+"<br>"+monthString;
                return dateString;

              }
          },
          dateTimeLabelFormats: {
              day: '%e<br>%b'
          },
          plotBands:plotBands
      },
      yAxis: [

        { // Primary yAxis
            labels: {
                format: '{value:.,0f} €',
                style: {
                    color: '#11A1FF'
                }
            },
            title: {
                text: 'Ricavi'
            },
            opposite: false

        }
      ],
      series: [
        {
          type:'areaspline',
          name: 'Ricavi',
          data: data,
          pointInterval:  24 * 3600 * 1000, // one day
          pointStart: self.startTimestamp
        }
      ],
      plotOptions: {
        areaspline: {
            fillOpacity: 0.1,
            marker: {
                enabled: false
            }
        }
      },
      tooltip: {
        formatter: function() {
            let day = new Date(this.x).getDate();
            let month = new Date(this.x).getMonth() +1;

            let dayString = day.toString();
            let monthString = month.toString();

            if (day < 10) {
              dayString = '0'+dayString;
            }

            if (month < 10) {
              monthString = '0'+monthString;
            }

            return dayString+'/'+monthString+'<br>€ <b>' + this.y.toString().replace('.',',') + '</b>';
        }
      }
    });

    this.lightLoading = false;
  }

}
