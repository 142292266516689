import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/providers/data.service';

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.scss']
})
export class PriceTableComponent implements OnInit {

  @Input() selectedPackage:any
  @Input() prices:any
  @Input() isOptional:boolean = false;
  @Input() optional:any;

  constructor(public dataService:DataService) {

  }

  ngOnInit(): void {

  }

  checkPrice(packId,zoneId,seasonId,timeslotId, type) {

    if (!this.prices[packId + '_' + zoneId + '_' + seasonId+ '_' + timeslotId][type] || this.prices[packId + '_' + zoneId + '_' + seasonId+ '_' + timeslotId][type] < 0) {
      this.prices[packId + '_' + zoneId + '_' + seasonId+ '_' + timeslotId][type] = 0;
    }
  }

  checkOptionalPrice(packId,optionalId,zoneId,seasonId,timeslotId, type) {
    if (!this.prices[packId + '_' + optionalId + '_' + zoneId + '_' + seasonId+ '_' + timeslotId][type] || this.prices[packId + '_' + optionalId + '_' + zoneId + '_' + seasonId+ '_' + timeslotId][type] < 0) {
      this.prices[packId + '_' + optionalId + '_' + zoneId + '_' + seasonId+ '_' + timeslotId][type] = 0;
    }
  }
}
