import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service'
import { SettingsService } from '../../../core/providers/settings.service';
import { PrintersService } from '../../../core/providers/printers.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { MatDialog} from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ModalConfirmBulkDeleteComponent } from '../../../shared/components/modal-confirm-bulk-delete/modal-confirm-bulk-delete.component';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { ModalErrorComponent } from 'src/app/shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-settings-advanced',
  templateUrl: './settings-advanced.component.html',
  styleUrls: ['./settings-advanced.component.scss']
})
export class SettingsAdvancedComponent implements OnInit {

  resortData;managerData;pageClicked;prices;submenu; mapAreas; loader; selectedState=null;
  startDate; endDate;startTimestamp;endTimestamp;startSerializedDate;endSerializedDate;
  firestoreData:any;uploadPercent;xml=null;selectedFiscalPrint=null;

  constructor(private storage: AngularFireStorage, private afs: AngularFirestore, public dialog: MatDialog, public snackBar: MatSnackBar, private route: ActivatedRoute, private dataService: DataService, public printerService: PrintersService , private settingsService: SettingsService) {
    this.submenu = 0;
    this.route.params.subscribe(params =>{
      if (params['menuIndex']) {
        this.submenu = params['menuIndex'];
      }
    });


  }

  async ngOnInit() {
    this.loader = true;
    if (!this.resortData) {
      await this.dataService.initResortData();
    }
    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.mapAreas = (await this.dataService.getMapAreas()).results;
    this.mapAreas.sort(this.sortByCol);
    this.startTimestamp = this.resortData.openingDate * 1000;
    this.endTimestamp = this.resortData.closingDate * 1000;

    const startDate = new Date(this.startTimestamp);
    this.startDate = startDate;
    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    this.endDate = endDate;
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate;

    if (this.resortData.id === 70) {


      let ref = firebase.firestore().collection('resorts').doc("70");
      const snap = await (await ref.get()).data();
      this.firestoreData = snap;
    }

    this.loader = false;
  }

  resetHeader() {
    this.pageClicked = true;
    setTimeout(()=>{
      this.pageClicked = false;
    },1000)
  }

  async fiscalClosing(index) {
    try {
      let response = null;
      let printer = this.resortData.printers[index];
      if (printer.model == 'epson_fp_81_ii') {
        this.loader = true;
        response = await this.printerService.epsonFiscalClosing(printer);
        
      } else if (printer.model === 'custom') {
        this.loader = true;
        response = await this.printerService.customFiscalClosing(printer);
        console.log(response);
      }

      if (response && response['success']) {
        let dialogRef = this.dialog.open(ModalConfirmComponent, {
          width: '340px',
          height: '110px',
          data: {
          }
        });
      } else {
        let dialogRef = this.dialog.open(ModalErrorComponent, {
          height: '110px',
          data: {
            error: JSON.stringify(response)
          }
        });
      }

      this.loader = false;
    } catch(err) {
      let dialogRef = this.dialog.open(ModalErrorComponent, {
        height: '110px',
        data: {
          error: JSON.stringify(err)
        }
      });
      this.loader = false;
    }
  }

  async setNewNoDefault(printerSelected) {
    this.loader = true;
    for (const printer of this.resortData.printers) {
      if (printer.ip !== printerSelected.ip) {
        printer.defaultNoFiscal = false;
      }
    }

    await this.saveResort();
  }

  async setNewDefault(printerSelected) {
    this.loader = true;
    for (const printer of this.resortData.printers) {
      if (printer.ip !== printerSelected.ip) {
        printer.defaultFiscal = false;
      }
    }
    await this.saveResort();
  }

  async saveResort() {

    try {
      await this.dataService.setResort(this.resortData);
      await this.dataService.updateManager(this.managerData);
      this.openSnackBar('INFORMAZIONI SALVATE CORRETTAMENTE','OK');

      this.loader = false;
    } catch (err) {
      console.log(err);
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  sortByCol( a, b ) {
    if ( a.startColumn < b.startColumn ) {
      return -1;
    }
    if ( a.startColumn > b.startColumn ) {
      return 1;
    }
    return 0;
  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp)
      this.endTimestamp = this.startTimestamp;

    let endDate = new Date(this.endTimestamp);
    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);
  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    this.endDate = endDate.getDate();
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp)
      this.startTimestamp = this.endTimestamp;

    let startDate = new Date(this.startTimestamp);
    this.startDate = startDate.getDate();
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

  }

  confirmBulk() {
    const dialogRef = this.dialog.open(ModalConfirmBulkDeleteComponent, {
      width: '330px',
      data: {
        start: this.startTimestamp,
        end: this.endTimestamp,
        type: this.selectedState,
        selectedFiscalPrint:this.selectedFiscalPrint,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log(result);
        }
    })
  }

  async saveFirestoreData() {
    let ref = firebase.firestore().collection('resorts').doc("70");
    await ref.update(this.firestoreData);
    this.openSnackBar('INFORMAZIONI SALVATE CORRETTAMENTE','OK');
  }


  async uploadFile(event) {
    const file = event.target.files[0];
    console.log(file)

    if (file) {
      const filePath = 'resorts/' + this.dataService.resortData.id + '/banner/' + file.name;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      // observe percentage changes
      this.uploadPercent = task.percentageChanges();
      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(async () => {
            this.uploadPercent = null;
            this.firestoreData.banner_image = await fileRef.getDownloadURL().toPromise();
            //this.saveExtra();
          })
       )
      .subscribe();
    }
  }

  removeImage() {
    this.firestoreData.banner_image = '';
    this.uploadPercent = null;
  }
}
