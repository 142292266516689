import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data.service';
import { ReservationService } from '../../../core/providers/reservation.service';
import { SettingsService } from '../../../core/providers/settings.service';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent implements OnInit {

  // RESORTS DATA
  resortData; managerData; reservations = null; optionals;

  // FILTERS VARIABLES
  term$ = new Subject<string>();
  selectedPackage = null; selectedSettlement = null; selectedPaymentType = null;
  selectedOptionals = null; selectedStatus = null; onlyArrivals = null;
  selectedType = null; selectedToday = null; onlyDepartures = null;
  selectedState = null; selectedNoSlots = null; selectedZone = null;
  selectedRefund = null; selectedFilter = null; searchString = ''; selectedCabins = null; size = null;
  selectedDiscount = null; selectedExtra = null; orderBy = null; orderTotalAsc = null;selectedQuotation=false;
  selectedCoupons = null;
  orderNameAsc = null; orderDateAsc = null; reservationsOrderType = 'name';
  showRightBarList = false; showRightBarPeople = false;
  customerBillAmount = 0;
  attendanceOrderType = 'name'; savePreferencesAttendance; savePreferencesReservations;

  listFields = {
    name: true,
    slots: true,
    date: false,
    paid: true,
    type: true,
    notes: false,
    extras: false,
    customerInfos: false,
    refunds: false,
    amount: false,
    settled: false
  }

  attendanceFields = {
    name: true,
    phone: true,
    email: true,
    fiscalCode: true,
    date: true,
    notes: true
  }



  // DATE VARIABLES
  startTimestamp; endTimestamp; optionalsByIds={}; optionalsByKeys= {};
  startDay; startMonth; startYear;
  quotationExpirationStart;quotationExpirationEnd;quotationExpirationStartTimestamp;quotationExpirationEndTimestamp;quotationExpirationStartSerializedDate;quotationExpirationEndSerializedDate;
  endDay; endMonth; endYear;
  startSerializedDate; endSerializedDate;
  onlyCheckout; onlyCheckin;

  // GLOBAL VARIABLES
  loader = true; nextPage; prevPage; totalReservations = 0; pageSize = 25; page = 1;
  objectKeys = Object.keys; lang = 'it'; totalAmount = 0; pageClicked = false; intervalAmount = 0;
  overlayLoader = false; percentage = 0;

  constructor(public router: Router,public dataService: DataService, private settingsService: SettingsService, private reservationService: ReservationService) {

    // SETUP DATES

    if (this.dataService.searchString) {
      this.searchString = this.dataService.searchString;
    }

    console.log(this.dataService.startTimestampList);

    if (this.dataService.startTimestampList) {
      this.startTimestamp = this.dataService.startTimestampList;
      this.endTimestamp = this.dataService.endTimestampList;
    } else {
      this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
      this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    }

    this.quotationExpirationStartTimestamp = (new Date(new Date().getFullYear(), 0, 1)).setUTCHours(0, 0, 0, 0);
    this.quotationExpirationEndTimestamp = this.endTimestamp;

    console.log(this.quotationExpirationStartTimestamp, this.quotationExpirationEndTimestamp);

    const startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = startDate;

    const endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = endDate;

    
    const startDateQ = new Date(this.quotationExpirationStartTimestamp);
    startDateQ.setUTCHours(0, 0, 0, 0);
    this.quotationExpirationStartSerializedDate = startDateQ;

    const endDateQ = new Date(this.quotationExpirationEndTimestamp);
    endDateQ.setUTCHours(0, 0, 0, 0);
    this.quotationExpirationEndSerializedDate = endDateQ;

    // SETUP DATA
    this.resortData = this.dataService.resortData;
    this.managerData = this.dataService.userData;
    this.lang = this.dataService.lang;

    // SEARCH STRING

    this.term$
        .debounceTime(1000)
        .distinctUntilChanged()
        .subscribe(term => {
          this.searchString = term;

          if (term === '') {
            this.searchString = null;
          }

          this.page = 1;

          this.getReservations();
        });

  }

  ngOnDestroy() {
    this.dataService.searchString = null;
  }

  async ngOnInit() {

    if (!this.resortData || !this.optionals) {
      await this.dataService.initResortData();
    }

    this.resortData =  this.dataService.resortData;
    this.managerData =  this.dataService.userData;
    this.optionals =  this.dataService.optionals;

    for (const opt of this.optionals) {
      this.optionalsByIds[opt.id] = opt;
      this.optionalsByKeys[opt.optionalId] = opt;
    }
    console.log(this.optionals);

    if (this.managerData && this.managerData.settingsManager) {
      if (this.managerData.settingsManager.reservation_list) {
        this.reservationsOrderType = this.managerData.settingsManager.reservation_list.sortBy;
        this.listFields = this.managerData.settingsManager.reservation_list.fields;
      }

      if (this.managerData.settingsManager.attendance_list) {
        this.attendanceOrderType = this.managerData.settingsManager.attendance_list.sortBy;
        this.attendanceFields = this.managerData.settingsManager.attendance_list.fields;
      }

    } else if (this.managerData) {
      this.managerData.settingsManager = {
        reservation_list: {
          sortBy: '',
          fields: {}
        },
        attendance_list: {
          sortBy: '',
          fields: {}
        }
      }
    }

    // GET RESERVATIONS FOR TODAY


    await this.getReservations();

  }

  async changePage(url) {
    this.loader = true;
    const data = await this.dataService.getReservationsByUrl(url);
    this.nextPage = data.next;
    this.prevPage = data.previous;
    this.reservations = this.processReservations(data.results);
    this.totalReservations = data.count;
    this.loader = false;
  }

  resetFilters() {
    this.selectedCabins = null;
    this.selectedFilter = null;
    this.selectedOptionals = null;
    this.searchString = null;
    this.selectedPackage = null;
    this.selectedState = null;
    this.selectedRefund = null;
    this.selectedPaymentType = null;
    this.page = 1;
    this.selectedZone = null;
    this.selectedSettlement = null;


    this.getReservations();
  }

  openReservation(url) {
    this.dataService.previousPage = 'reservations'
    this.router.navigateByUrl(url);
  }

  async getReservations() {

    console.log(new Date(), 'GET RESERVATIONS START');
    console.log(this.selectedCoupons)
    const start = this.startTimestamp / 1000;
    const end = this.endTimestamp / 1000;
    const data = await this.dataService.getReservations(start, end, this.onlyArrivals, this.onlyDepartures, this.searchString, this.selectedState, this.selectedZone, this.selectedPackage, this.selectedOptionals, this.selectedToday, this.selectedSettlement, this.selectedCabins, this.selectedPaymentType, null, null, this.size, 1, true, this.selectedExtra, this.selectedDiscount, this.onlyCheckin, this.orderBy , this.selectedNoSlots, this.selectedRefund, this.selectedCoupons, null, this.selectedQuotation,this.quotationExpirationStart,this.quotationExpirationEnd);


    console.log(new Date(), 'RESERVATIONS READY');
    this.reservations = this.processReservations(data.results);
    this.nextPage = data.next;
    this.prevPage = data.previous;
    this.totalReservations = data.count;
    this.loader = false;
    console.log(new Date(), 'RESERVATIONS READY PROCESS');
    console.log(this.reservations);

    let amounts =  await this.dataService.getTotalAmount(start, end, this.onlyArrivals, this.onlyDepartures, this.searchString, this.selectedState, this.selectedZone, this.selectedPackage, this.selectedOptionals, this.selectedToday, this.selectedSettlement, this.selectedCabins, this.selectedPaymentType, this.selectedExtra, this.selectedDiscount, this.onlyCheckin , this.selectedNoSlots, this.selectedRefund, this.selectedCoupons, null, this.selectedQuotation,this.quotationExpirationStart,this.quotationExpirationEnd);
    this.totalAmount = amounts.total_order;
    this.intervalAmount = amounts.interval_order;

    this.customerBillAmount = await this.dataService.getTotalCustomerBillNotPaid(start,end,null,null,'movement');
    console.log(this.customerBillAmount);
  }

  processReservations(data) {
    const output = [];


    for (const reservation of data) {

      let length = 0;
      for (const singleInterval of reservation.dates) {
        length+=singleInterval.length;
      }

      for (const singleInterval of reservation.dates) {
        if ((singleInterval.start <= this.startTimestamp / 1000 && singleInterval.end >= this.startTimestamp / 1000 && singleInterval.end <= this.endTimestamp / 1000) || (singleInterval.start >= this.startTimestamp / 1000 && singleInterval.end <= this.endTimestamp / 1000) || (singleInterval.start <= this.endTimestamp / 1000 && singleInterval.end >= this.endTimestamp / 1000)) {

            if (reservation.version === 3) {
              for (const interval of reservation.dates) {
                for (const order of interval.order) {
                    for (const optionalId in order.optionalsKeys) {
                      if (order.optionalsKeys[optionalId] && this.optionalsByKeys[optionalId]) {
                        order.optionalsKeys[this.optionalsByKeys[optionalId].id] = order.optionalsKeys[optionalId];
                        delete order.optionalsKeys[optionalId];
                      }
                    }
                }
              }
            }


            let checkout = true;
            let checkin = true;

            for (let ts = this.startTimestamp/1000; ts <= this.endTimestamp/1000; ts += 24*60*60) {
              if (!reservation.checkOutDates || reservation.checkOutDates.length === 0 || reservation.checkOutDates.indexOf(ts) === -1) {
                checkout = false;
              }

              if (!reservation.checkInDates || reservation.checkInDates.length === 0 || reservation.checkInDates.indexOf(ts) === -1) {
                checkin = false;
              }
            }

            if (!reservation.phonePrefix || reservation.phonePrefix === '') {
              reservation.phonePrefix = '+39';
            }

            let name = '';
            let surname = '';
            let associated = [];

            for (const customer of reservation.customers) {
              if (customer.principal) {
                name = customer.customer.name;
                surname = customer.customer.surname;
              } else {
                associated.push({
                  name: customer.customer.name,
                  surname: customer.customer.surname
                });
              }
            }

            if (name === '' && surname === '' && reservation.searchKey !== '') {
                name = reservation.searchKey.replace(/_/g, ' ');
            }

            let customerBillFound = reservation.order.customerBill.find(bill => bill.type === 'movement');


            const obj = {
              refundState: reservation.refundState,
              noSlots: reservation.noSlots,
              code: reservation.reservationCode,
              cabins: reservation.cabins,
              name,
              surname,
              length: length,
              order: reservation.order,
              start: singleInterval.start,
              end: singleInterval.end,
              timeSlots: singleInterval.timeSlots,
              fiscalCode: reservation.fiscalCode,
              groupByPacks: {},
              associated,
              checkout,
              checkin,
              extras: reservation.extras,
              notes: reservation.notes,
              amountPayed: 0,
              amountTotal: 0,
              bookingInfo:reservation.bookingInfo,
              phone: reservation.phonePrefix + ' '+reservation.phone,
              email: reservation.email,
              id: reservation.id,
              seasonTicket: reservation.seasonTicket,
              cocoBooking: reservation.cocoBooking,
              customerBillFound: customerBillFound,
              quotation:reservation.quotation
            };

            if (reservation.order.customerBill && !output.find(res => res.id === reservation.id)) {

              for (const billObj of reservation.order.customerBill) {
                if (billObj.payed) {
                    obj.amountPayed += parseFloat(billObj.price);
                } else {
                    obj.amountTotal += parseFloat(billObj.price);
                }
              }
            }

            if (output.find(res => res.id === reservation.id)) {
              obj.amountPayed = null
              obj.amountTotal = null
            }

            for (const product of singleInterval.order) {

              if (!obj.groupByPacks[product.packageKey]) {
                obj.groupByPacks[product.packageKey] = {
                  products: [],
                  optionals: {}
                };
              }

              obj.groupByPacks[product.packageKey].products.push(product);

              if (product.optionalsKeys) {
                for (let optKey in product.optionalsKeys) {
                  if (product.optionalsKeys[optKey]) {
                    if (this.optionals && !this.optionals[optKey]) {
                      for (const optId in this.optionals ) {
                        if (this.optionals[optId].id == optKey) {
                          optKey = this.optionals[optId].id;
                          break;
                        }
                      }
                    }

                    if (!obj.groupByPacks[product.packageKey].optionals[optKey]) {
                      obj.groupByPacks[product.packageKey].optionals[optKey] = 0;
                    }

                    obj.groupByPacks[product.packageKey].optionals[optKey] += product.optionalsKeys[optKey];
                  }

                }

              }
            }

            output.push(obj);
        }
      }
    }

    return output;
  }

  async orderByDate() {
    this.loader = true;
    this.orderNameAsc = null;
    this.orderTotalAsc = null;
    if (this.orderDateAsc === null) {
      this.orderDateAsc = true;
    } else {
      this.orderDateAsc = !this.orderDateAsc;
    }

    console.log(this.orderDateAsc);

    if (this.orderDateAsc) {
      this.orderBy = 'start';
    } else {
      this.orderBy = '-start';
    }
    await this.getReservations();
    this.loader = false;
  }

  async orderByName() {
    this.loader = true;
    this.orderDateAsc = null;
    this.orderTotalAsc = null;

    if (this.orderNameAsc === null) {
      this.orderNameAsc = true;
    } else {
      this.orderNameAsc = !this.orderNameAsc;
    }

    console.log(this.orderNameAsc);

    if (this.orderNameAsc) {
      this.orderBy = 'searchKey';
    } else {
      this.orderBy = '-searchKey';
    }
    await this.getReservations();
    this.loader = false;
  }

  async orderByTotal() {

    this.loader = true;
    this.orderNameAsc = null;
    this.orderDateAsc = null;

    if (this.orderTotalAsc === null) {
      this.orderTotalAsc = true;
    } else {
      this.orderTotalAsc = !this.orderTotalAsc;
    }

    console.log(this.orderTotalAsc);

    if (this.orderTotalAsc) {
      this.orderBy = 'order_total';
    } else {
      this.orderBy = '-order_total';
    }
    await this.getReservations();
    this.loader = false;
  }

  async exportPeople() {

    this.overlayLoader = true;
    if (this.savePreferencesAttendance) {
        this.managerData.settingsManager.attendance_list.sortBy = this.attendanceOrderType;
        this.managerData.settingsManager.attendance_list.fields = this.attendanceFields;
        await this.dataService.updateManager(this.managerData);
    }

    this.percentage = 0;

    let reservationsToSave = [];

    const start = this.startTimestamp / 1000;
    const end = this.endTimestamp / 1000;

    console.log(this.totalReservations);

    let nextPage = null;
    this.size = 500;
    for (let i = 0; i < this.totalReservations; i += 500) {

      let data = null;
      if (i==0) {

        data = await this.dataService.getReservations(start, end, null, null, null, null, null, null, null, null, null, null, null, null, null, this.size, this.page, true, null, null, null, 'start', null,null, null, null,null,null,null);
        nextPage = data.next;
      } else if (nextPage) {
        data = await this.dataService.getReservationsByUrl(nextPage);
        nextPage = data.next;
      } else {
        break;
      }

      console.log(data);

      if (data && data.results) {
        const partialReservations = this.processReservations(data.results);
        for (const res of partialReservations) {
          reservationsToSave.push(res);
        }
      }
    }

    this.reservationService.exportPeople(reservationsToSave,this.startTimestamp,this.endTimestamp,this.attendanceOrderType, this.attendanceFields);

    this.size = null;
    this.overlayLoader = false;
  }

  async exportPDF() {

    if (this.savePreferencesReservations) {
        this.managerData.settingsManager.reservation_list.sortBy = this.reservationsOrderType;
        this.managerData.settingsManager.reservation_list.fields = this.listFields;
        await this.dataService.updateManager(this.managerData);
    }

    this.overlayLoader = true;
    this.percentage = 0;

    let reservationsToSave = [];

    const start = this.startTimestamp / 1000;
    const end = this.endTimestamp / 1000;

    console.log(this.totalReservations);

    let nextPage = null;
    this.size = 500;
    for (let i = 0; i < this.totalReservations; i += 500) {

      let data = null;
      if (i==0) {

        data = await this.dataService.getReservations(start, end, this.onlyArrivals, this.onlyDepartures, this.searchString, this.selectedState, this.selectedZone, this.selectedPackage, this.selectedOptionals, this.selectedToday, this.selectedSettlement, this.selectedCabins, this.selectedPaymentType, null, null, this.size, this.page, true, this.selectedExtra, this.selectedDiscount, this.onlyCheckin, 'start' , this.selectedNoSlots, this.selectedRefund, this.selectedCoupons, null, this.selectedQuotation,this.quotationExpirationStart,this.quotationExpirationEnd);
        nextPage = data.next;
      } else if (nextPage) {
        data = await this.dataService.getReservationsByUrl(nextPage);
        nextPage = data.next;
      } else {
        break;
      }

      console.log(data);

      if (data && data.results) {
        const partialReservations = this.processReservations(data.results);
        for (const res of partialReservations) {
          reservationsToSave.push(res);
        }
      }
    }

    const filters = {
      packages: this.selectedPackage,
      settlement: this.selectedSettlement,
      zones: this.selectedZone,
      cabins: this.selectedCabins,
      state: this.selectedState,
      refund: this.selectedRefund,
      searchString: this.searchString
    }

    this.reservationService.exportPDF(reservationsToSave,this.startTimestamp,this.endTimestamp, filters, this.reservationsOrderType, this.listFields, false);

    this.size = null;
    this.overlayLoader = false;

  }

  async exportXLS() {

    let outputObjs = [];

    this.size = this.totalReservations;

    this.overlayLoader = true;
    this.percentage = 0;
    let size = 500;
    let nextPage = null;
    let reservationsToSave = [];

    const start = this.startTimestamp / 1000;
    const end = this.endTimestamp / 1000;

    for (let i = 0; i < this.totalReservations; i += 500) {

      let data = null;
      if (i==0) {

        data = await this.dataService.getReservations(start, end, this.onlyArrivals, this.onlyDepartures, this.searchString, this.selectedState, this.selectedZone, this.selectedPackage, this.selectedOptionals, this.selectedToday, this.selectedSettlement, this.selectedCabins, this.selectedPaymentType, null, null, size, 0, true, this.selectedExtra, this.selectedDiscount, this.onlyCheckin, 'start', this.selectedNoSlots , this.selectedRefund, this.selectedCoupons, null, this.selectedQuotation,this.quotationExpirationStart,this.quotationExpirationEnd);
        nextPage = data.next;
      } else if (nextPage) {
        console.log(nextPage);
        data = await this.dataService.getReservationsByUrl(nextPage);
        nextPage = data.next;
      } else {
        break;
      }

      this.percentage = ((i+500)/this.totalReservations)*100;

      console.log(data);

      if (data && data.results) {
        const partialReservations = this.processReservations(data.results);
        for (const res of partialReservations) {
          reservationsToSave.push(res);
        }
      }
    }

    reservationsToSave.map(reservation => {

        if ((!reservation.refundState && !this.listFields.refunds) || this.listFields.refunds) {

          let name = reservation.name+ ' '+ reservation.surname;

          if (this.listFields.customerInfos && reservation.email && reservation.email !== '') {
            name = name+'\n'+reservation.email;
          }

          if (this.listFields.customerInfos &&reservation.phone && reservation.phone !== ''  && reservation.phone.trim() !== '+39') {
            name = name+'\n'+reservation.phone;
          }

          if (this.listFields.customerInfos && reservation.fiscalCode && reservation.fiscalCode !== '') {
            name = name+'\n'+reservation.fiscalCode;
          }

          const obj = {
            id: reservation.id,
            nome: name,
            cognome: reservation.surname,
            note: reservation.notes,
            inizio: new Date(reservation.start * 1000),
            fine: new Date(reservation.end * 1000),
            tipo: '',
            pagato: 'NO',
            totale: 0,
            incassato: 0,
            postazioni: '',
            pacchetti: '',
            cabine: '',
            extras: '',
            refundState: reservation.refundState
          };

          if (reservation.amountTotal !== null) {
            obj.totale = reservation.amountTotal;
          }

          if (reservation.amountPayed !== null) {
            obj.incassato = reservation.amountPayed;
          }

          for (const packKey in reservation.groupByPacks) {


            if (reservation.groupByPacks[packKey]) {

              for (const element of reservation.groupByPacks[packKey].products) {
                if (obj.postazioni == '') {
                  obj.postazioni += element.coords;
                } else {
                  obj.postazioni += ', ' + element.coords;
                }

              }

              if (obj.postazioni != '') {
                obj.postazioni += '\n';
              }

              if (this.resortData.packagesByIds[packKey]) {
                if (obj.pacchetti == '') {
                  obj.pacchetti += reservation.groupByPacks[packKey].products.length + ' x ' + this.resortData.packagesByIds[packKey].name[this.dataService.lang] ;
                } else {
                  obj.pacchetti += ', ' + reservation.groupByPacks[packKey].products.length + ' x ' + this.resortData.packagesByIds[packKey].name[this.dataService.lang] ;
                }
              } else {
                if (obj.pacchetti == '') {
                  obj.pacchetti += reservation.groupByPacks[packKey].products.length + ' x Pacchetto non più esistente';
                } else {
                  obj.pacchetti += ', ' + reservation.groupByPacks[packKey].products.length + ' x Pacchetto non più esistente';
                }
              }

              for (const optionalKey in reservation.groupByPacks[packKey].optionals) {
                if (reservation.groupByPacks[packKey].optionals[optionalKey]) {
                  const foundOptional = this.optionals.find(opt => opt.id == optionalKey);
                  if (foundOptional) {
                    obj.pacchetti += ', ' + foundOptional.name[this.dataService.lang] + ' x ' + reservation.groupByPacks[packKey].optionals[optionalKey];
                  }
                }
              }
            }

          }

          if (reservation.extras) {
            for (const extra of reservation.extras) {

              if (obj.extras != '') {
                obj.extras += ', ';
              }

              if (extra.name && typeof extra.name === 'string') {
                obj.extras += extra.quantity + ' x ' + extra.name;
              } else if (this.dataService.resortData.extrasByIds[extra.id]) {
                obj.extras += extra.quantity + ' x ' + this.dataService.resortData.extrasByIds[extra.id].name[this.dataService.lang];
              }  else {
                obj.extras += extra.quantity + ' x ' + 'Extra non più esistente';
              }
            }
          }

          if (reservation.cabins) {
            for (const cabin of reservation.cabins) {
              if (this.resortData.cabinsByKeys[cabin]) {
                if (obj.cabine == '') {
                  obj.cabine += this.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
                } else {
                  obj.cabine += ', ' + this.resortData.cabinsByKeys[cabin].label[this.dataService.lang];
                }
              } else {
                if (obj.cabine == '') {
                  obj.cabine += '\n Cabina non più esistente';
                } else {
                  obj.cabine += ', Cabina non più esistente';
                }
              }
            }
          }

          if (reservation.refundState) {
            obj.tipo = 'RIMBORSATA';
          } else if (reservation.cocoBooking) {
            obj.tipo = 'COCO BOOKING';
          } else {
            obj.tipo = 'MANAGER';
          }

          let amount = 0;
          if (reservation.amountTotal) {
            amount = reservation.amountTotal;
          }
          
          if (reservation.order.settled) {
            obj.pagato = 'SI';
            obj.incassato =  amount;
          }

          let outObj = {};

          if (this.listFields.name) {
            outObj['nome'] = obj.nome;
          }

          if (this.listFields.notes) {
            outObj['note'] = obj.note;
          }

          if (this.listFields.type) {
            outObj['tipo'] = obj.tipo;
          }

          if (this.listFields.paid) {
            outObj['incassato'] = obj.incassato;
          }

          if (this.listFields.amount) {
            outObj['totale'] = obj.totale;
          }

          if (this.listFields.settled) {
            outObj['pagato'] = obj.pagato;
          }

          if (this.listFields.date) {
            outObj['inizio'] = obj.inizio;
            outObj['fine'] = obj.fine;
          }

          if (this.listFields.slots) {
            outObj['postazioni'] = obj.postazioni;
            outObj['pacchetti'] = obj.pacchetti;
            outObj['cabine'] = obj.cabine;
          }

          if (this.listFields.extras) {
            outObj['extras'] = obj.extras;
          }

          outputObjs.push(outObj);
        }
    });

    console.log(outputObjs);

    if (this.reservationsOrderType === 'name') {
      outputObjs.sort(this.sortByName);
    }


    if (this.reservationsOrderType === 'surname') {
      outputObjs.sort(this.sortBySurname);
    }

    if (this.reservationsOrderType === 'slotNameAsc') {
      outputObjs.sort(this.sortBySlotsAsc);
    }

    if (this.reservationsOrderType === 'slotNameDesc') {
      outputObjs.sort(this.sortBySlotsDesc);
    }

    if (this.reservationsOrderType === 'dateAsc') {
      outputObjs.sort(this.sortByDateAsc);
    }

    if (this.reservationsOrderType === 'dateDesc') {
      outputObjs.sort(this.sortByDateDesc);
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(outputObjs);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    var startDate = new Date(this.startTimestamp);
    var endDate = new Date(this.endTimestamp);

    var startDay = startDate.getDate();
    var startMonth = startDate.getMonth() + 1;

    var endDay = endDate.getDate();
    var endMonth = endDate.getMonth() + 1;


    XLSX.writeFile(wb, 'Prenotazioni_' + startDay + '-' + startMonth + '_' + endDay + '-' + endMonth + '.xlsx');

    this.size = null;
    this.overlayLoader = false;
  }

  async exportXLSpeople() {

    let outputObjs = [];

    this.size = this.totalReservations;

    this.overlayLoader = true;
    this.percentage = 0;

    let nextPage = null;
    this.size = this.totalReservations;
    let reservationsToSave = [];
    let size = 500;
    const start = this.startTimestamp / 1000;
    const end = this.endTimestamp / 1000;

    for (let i = 0; i < this.totalReservations; i += 500) {

      let data = null;
      if (i==0) {

        data = await this.dataService.getReservations(start, end,null, null, null, null, null, null, null, null, null, null, null, null, null, size, 0, true, null, null, null, 'start', null, null, null, null,null,null,null);
        nextPage = data.next;
      } else if (nextPage) {
        data = await this.dataService.getReservationsByUrl(nextPage);
        nextPage = data.next;
      } else {
        break;
      }

      this.percentage = ((i+500)/this.totalReservations)*100;
      console.log(data);

      if (data && data.results) {
        const partialReservations = this.processReservations(data.results);
        for (const res of partialReservations) {
          reservationsToSave.push(res);
        }
      }
    }

    reservationsToSave.map(reservation => {

        if (!reservation.refundState) {

          const obj = {
            nome: reservation.name,
            telefono: reservation.phone,
            email: reservation.email,
            note: reservation.notes,
            cf: reservation.fiscalCode,
            inizio: new Date(reservation.start * 1000),
            fine: new Date(reservation.end * 1000),
          };


          let outObj = {};

          if (this.attendanceFields.name) {
            outObj['nome'] = obj.nome;
          }

          if (this.attendanceFields.phone) {
            outObj['telefono'] = obj.telefono;
          }

          if (this.attendanceFields.email) {
            outObj['email'] = obj.email;
          }

          if (this.attendanceFields.date) {
            outObj['inizio'] = obj.inizio;
            outObj['fine'] = obj.fine;
          }

          if (this.attendanceFields.fiscalCode) {
            outObj['cf'] = obj.cf;
          }

          if (this.attendanceFields.notes) {
            outObj['note'] = obj.note;
          }

          outputObjs.push(outObj);
        }
    });

    console.log(outputObjs);

    if (this.attendanceOrderType === 'name') {
      outputObjs.sort(this.sortByName);
    }

    if (this.attendanceOrderType === 'dateAsc') {
      outputObjs.sort(this.sortByDateAsc);
    }

    if (this.attendanceOrderType === 'dateDesc') {
      outputObjs.sort(this.sortByDateDesc);
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(outputObjs);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    var startDate = new Date(this.startTimestamp);
    var endDate = new Date(this.endTimestamp);

    var startDay = startDate.getDate();
    var startMonth = startDate.getMonth() + 1;

    var endDay = endDate.getDate();
    var endMonth = endDate.getMonth() + 1;


    XLSX.writeFile(wb, 'Presenze_' + startDay + '-' + startMonth + '_' + endDay + '-' + endMonth + '.xlsx');

    this.size = null;
    this.overlayLoader = false;
  }

  
  async changeQuotationStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    
    this.startTimestamp = this.resortData.openingDate;
    this.endTimestamp = this.resortData.closingDate;

    console.log(this.quotationExpirationStartTimestamp, this.quotationExpirationEndTimestamp);

    const sDate = new Date(this.startTimestamp);
    sDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = sDate;

    const eDate = new Date(this.endTimestamp);
    eDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = eDate;


    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.quotationExpirationStartTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.quotationExpirationStartSerializedDate = new Date(startTS);

    if (this.quotationExpirationEndTimestamp < this.quotationExpirationStartTimestamp)
      this.quotationExpirationEndTimestamp = this.quotationExpirationStartTimestamp;

    let endDate = new Date(this.quotationExpirationEndTimestamp);
    this.quotationExpirationEndTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.quotationExpirationEndSerializedDate = new Date(this.quotationExpirationEndTimestamp);

    this.quotationExpirationEnd = this.quotationExpirationEndTimestamp/1000;
    this.quotationExpirationStart = this.quotationExpirationStartTimestamp/1000;

    let setReservations = await this.getReservations();
  }

  async changeQuotationEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    
    this.startTimestamp = this.resortData.openingDate*1000;
    this.endTimestamp = this.resortData.closingDate*1000;

    console.log(this.quotationExpirationStartTimestamp, this.quotationExpirationEndTimestamp);

    const sDate = new Date(this.startTimestamp);
    sDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = sDate;

    const eDate = new Date(this.endTimestamp);
    eDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = eDate;

    this.loader = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);
    this.quotationExpirationEndTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.quotationExpirationEndSerializedDate = new Date(endTS);

    if (this.quotationExpirationEndTimestamp < this.quotationExpirationStartTimestamp) {
      this.quotationExpirationStartTimestamp = this.quotationExpirationEndTimestamp;
    }

    let startDate = new Date(this.quotationExpirationStartTimestamp);
    this.quotationExpirationStartTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.quotationExpirationStartSerializedDate = new Date(this.quotationExpirationStartTimestamp);
    this.quotationExpirationStart = this.quotationExpirationStartTimestamp/1000;
    this.quotationExpirationEnd = this.quotationExpirationEndTimestamp/1000;

    let setReservations = await this.getReservations();

  }

  async changeStartDate(type: string, event: MatDatepickerInputEvent<Date>) {

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let startTS = (new Date(event.value)).getTime() - offset;
    let startDate = new Date(startTS);

    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(startTS);

    if (this.endTimestamp < this.startTimestamp)
      this.endTimestamp = this.startTimestamp;

    let endDate = new Date(this.endTimestamp);
    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(this.endTimestamp);

    let setReservations = await this.getReservations();


    this.dataService.startTimestampList = this.startTimestamp;
    this.dataService.endTimestampList = this.endTimestamp;

  }

  async changeEndDate(type: string, event: MatDatepickerInputEvent<Date>) {

    this.loader = true;

    let now = new Date();
    let offset = now.getTimezoneOffset() * 60000;

    if (!this.settingsService.isDstObserved(now))
    {
      offset = (now.getTimezoneOffset() - 60) * 60000;
    }

    let endTS = (new Date(event.value)).getTime() - offset;
    let endDate = new Date(endTS);

    this.endTimestamp = endDate.setUTCHours(0, 0, 0, 0);
    this.endSerializedDate = new Date(endTS);

    if (this.endTimestamp < this.startTimestamp) {
      this.startTimestamp = this.endTimestamp;
    }

    let startDate = new Date(this.startTimestamp);
    this.startTimestamp = startDate.setUTCHours(0, 0, 0, 0);
    this.startSerializedDate = new Date(this.startTimestamp);

    let setReservations = await this.getReservations();

    this.dataService.startTimestampList = this.startTimestamp;
    this.dataService.endTimestampList = this.endTimestamp;
  }

  async setTodayDate() {
    this.startTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);
    this.endTimestamp = (new Date()).setUTCHours(0, 0, 0, 0);

    let startDate = new Date(this.startTimestamp);
    startDate.setUTCHours(0, 0, 0, 0);

    let endDate = new Date(this.endTimestamp);
    endDate.setUTCHours(0, 0, 0, 0);

    this.startSerializedDate = new Date(this.startTimestamp);
    this.endSerializedDate = new Date(this.endTimestamp);

    let setReservations = await this.getReservations();
    this.dataService.startTimestampList = this.startTimestamp;
    this.dataService.endTimestampList = this.endTimestamp;

  }


  resetHeader() {
    this.pageClicked = true;
    setTimeout(() => {
      this.pageClicked = false;
    }, 1000);
  }

  sortBySlotsAsc(a, b) {
    if (a.postazioni && b.postazioni) {
      return a.postazioni.trim().localeCompare(b.postazioni.trim(), undefined, {numeric: true, sensitivity: 'base'});
    } else {
      return 0;
    }
  }

  sortBySlotsDesc(a, b) {
    if (a.postazioni && b.postazioni) {
     return b.postazioni.trim().localeCompare(a.postazioni.trim(), undefined, {numeric: true, sensitivity: 'base'});
    } else {
      return 0;
    }
  }

  sortByName( a, b ) {
    if ( a.nome.toLowerCase() < b.nome.toLowerCase() ) {
      return -1;
    }
    if ( a.nome.toLowerCase() > b.nome.toLowerCase() ) {
      return 1;
    }
    return 0;
  }


  sortBySurname( a, b ) {
    if ( a.cognome.toLowerCase() < b.cognome.toLowerCase() ) {
      return -1;
    }
    if ( a.cognome.toLowerCase() > b.cognome.toLowerCase() ) {
      return 1;
    }
    return 0;
  }

  sortByDateAsc( a, b ) {
    if ( a.inizio < b.inizio ) {
      return -1;
    }
    if ( a.inizio > b.inizio ) {
      return 1;
    }
    return 0;
  }

  sortByDateDesc( a, b ) {
    if ( a.inizio < b.inizio ) {
      return 1;
    }
    if ( a.inizio > b.inizio ) {
      return -1;
    }
    return 0;
  }

}
