import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule  } from '@angular/fire/firestore';
import { AngularFireDatabaseModule  } from '@angular/fire/database';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

// ENVIRONMENT
import { environment } from '../environments/environment';

// HIGHCHARTS
import { SettingsModule } from './settings/settings.module';
import { CoreModule } from '@angular/flex-layout';
import { ReservationModule } from './reservation/reservation.module';
import { StatsModule } from './stats/stats.module';
import { CustomerModule } from './customer/customer.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './core/pages/login/login.component';
import { HomeModule } from './home/home.module';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    AngularFireAnalyticsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AppRoutingModule,
    SharedModule,
    HomeModule,
    SettingsModule,
    ReservationModule,
    StatsModule,
    CustomerModule,
    CoreModule
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
