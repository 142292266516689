import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/pages/suite_home/home.component';
import { HomeComponent as BookingHomeComponent} from '../home/pages/booking_home/home.component';
import { AuthguardService } from '../core/providers/authguard.service';
import { PlannerComponent } from './pages/planner/planner.component';
import { PlannerNewComponent } from './pages/planner-new/planner-new.component';

const routes: Routes = [
    { path: 'booking/home', component: BookingHomeComponent, canActivate: [AuthguardService] },
    { path: 'suite/home', component: HomeComponent, canActivate: [AuthguardService] },
    { path: 'suite/planner', component: PlannerComponent , canActivate: [AuthguardService]},
    { path: 'suite/planner/new', component: PlannerNewComponent , canActivate: [AuthguardService]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {}
