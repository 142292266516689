import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/providers/data.service';
import { SettingsService } from 'src/app/core/providers/settings.service';

@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss']
})
export class SlotComponent implements OnChanges {

  @Input() tiles:any;
  @Input() availabilitiesByPack:any;
  @Input() resortData:any;
  @Input() lang:any;
  @Input() packagesOrder:any;
  @Input() selectedTimeslot:any;
  @Input() availableTimeslots:any;
  @Input() row:any;
  @Input() col:any;
  @Input() startTimestamp:any;
  @Input() endTimestamp:any;
  @Input() reservationMapData:any;
  @Input() mapLayoutMode:any;
  @Input() zoomStep:any;
  @Input() slotHeight:any;
  @Input() managerData:any;
  @Input() lastSlot:any;
  @Input() selectedSlots:any;
  @Input() grid:any;
  @Input() reserveFlag:any;
  @Input() showQuickReservation:any;
  @Input() newReservation:any;
  @Input() slot:any;
  @Output() resetMapEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCartEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() openReservationsModalEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() showBottomBarEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetMapFunctionEmitter:EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedSlotEmitter:EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedSlotsEmitter:EventEmitter<any> = new EventEmitter<any>();
  @Output() lastSlotEmitter:EventEmitter<any> = new EventEmitter<any>();
  @Output() reserveFlagEmitter:EventEmitter<any> = new EventEmitter<any>();


  slotReservations:any = null;;
  divideBy:number = 2;
  isDailyBusy:boolean = false;
  isMultiReservation: boolean = false;
  timeSlots:any[] = [];
  showReservations:boolean = false;
  selectedReservation:any = null;

  constructor(public dataService:DataService, public settingsService: SettingsService, public router:Router) { }

  ngOnChanges() {
    if (!this.slot.elementId) {
      this.settingsService.setSlotElement(this.slot, this.dataService.resortData);
    }

    this.availableTimeslots = this.availableTimeslots.sort(this.compareByEnd)

    this.slotReservations = null;
    this.showReservations = false;
    this.isDailyBusy = false;
    this.isMultiReservation = false;
    this.timeSlots = [];
    this.divideBy = 2;
    if (this.reservationMapData[this.row] && this.reservationMapData[this.row][this.col]) {
      this.slotReservations = this.reservationMapData[this.row][this.col];
      this.slotReservations.settled = true;
      let reservationId = null;
      for (let reservation of this.slotReservations.reservationsKeys) {
      
        if (!reservation.settled) {
          this.slotReservations.settled = false;
        }

        if (this.slotReservations.state === -1) {
          this.slotReservations.state = reservation.state;
        }

        if (!reservationId) {
          reservationId = reservation.id
        }

        if (reservation.name)
        reservation.name = reservation.name.replace('undefined','');

        if (reservation.surname)
        reservation.surname = reservation.surname.replace('undefined','');

        if (!reservation.timeSlot || !reservation.timeSlot.id) {
          this.selectedReservation = reservation;
          this.isDailyBusy = true;
          break;
        } else if (this.selectedTimeslot) {

          let selectedTimeslot = this.availableTimeslots.find(t => t.id === this.selectedTimeslot);
          let startTimeslot = parseInt(reservation.timeSlot.startHour.split(":")[0])*60+ parseInt(reservation.timeSlot.startHour.split(":")[1])
          let endTimeslot = parseInt(reservation.timeSlot.endHour.split(":")[0]) *60+ parseInt(reservation.timeSlot.endHour.split(":")[1])
          let startSelectedTimeslot = parseInt(selectedTimeslot.startHour.split(":")[0]) *60+ parseInt(selectedTimeslot.startHour.split(":")[1])
          let endSelectedTimeslot = parseInt(selectedTimeslot.endHour.split(":")[0]) *60+ parseInt(selectedTimeslot.endHour.split(":")[1])
          let isTimeslotIncluded = (!selectedTimeslot || (startTimeslot <= startSelectedTimeslot && endTimeslot > startSelectedTimeslot) || (startTimeslot >= startSelectedTimeslot && endTimeslot <= endSelectedTimeslot) || (startTimeslot < endSelectedTimeslot && endTimeslot >= endSelectedTimeslot));

          if (isTimeslotIncluded) {
            this.timeSlots.push(reservation);
          }
        } else {
          this.timeSlots.push(reservation);
        }

        if (reservationId !== reservation.id) {
          this.isMultiReservation = true;
        }

      }

      // CHECK NON MI PIACE

      if (this.startTimestamp !== this.endTimestamp) {
        this.isDailyBusy = true;
      }


      if (this.timeSlots.length > 1) {
        this.divideBy = this.timeSlots.length
      } else if(this.selectedTimeslot && this.timeSlots.length == 1) {
        this.selectedReservation = this.timeSlots[0];
        this.isDailyBusy = true
      }
    }

    if (this.slotReservations && (this.isDailyBusy || this.timeSlots.length > 0)) {
      this.showReservations = true;
    }
  }

  compareByEnd( a: any, b: any ) {
    let startA = parseInt(a.startHour.split(':')[0])*60 + parseInt(a.startHour.split(':')[1]);
    let startB = parseInt(b.startHour.split(':')[0])*60 + parseInt(b.startHour.split(':')[1]);

    if (startA < startB){
      return -1;
    }

    if (startA > startB){
      return 1;
    }

    return 0;
  }

  getTimeSlotTop(timeslot, index) {
    let findIndex = this.availableTimeslots.findIndex(t => t.id === timeslot.timeSlot.id);
    if (findIndex > -1) {
      return (findIndex*(110/this.availableTimeslots.length))
    } else {
      return (index*(110/this.availableTimeslots.length))
    }
  }

  slotEnter() {

    /* console.log("LABEL ", this.slot)
    console.log("DAILY", this.isDailyBusy)
    console.log("TIMESLOTS FOUND", this.timeSlots)
    console.log("RESERVATION IDS", this.slotReservations)
    console.log("RESERVATIONS IDS", this.slotReservations.reservationsKeys)
    console.log("MULTI", this.isMultiReservation) */
    if (this.startTimestamp === this.endTimestamp && this.slotReservations && this.slotReservations.state != 6) {

      this.slotReservations.otherSlots = '';

      for (const rowScan in this.reservationMapData) {
        for (const colScan in this.reservationMapData[rowScan]) {
          if (this.reservationMapData[rowScan][colScan] ) {
            if (this.reservationMapData[rowScan][colScan].reservationsKeys[0].id === this.slotReservations.reservationsKeys[0].id) {
              if (this.reservationMapData[rowScan][colScan].label != this.slotReservations.label) {
                if (this.slotReservations.otherSlots === '') {
                  this.slotReservations.otherSlots += this.reservationMapData[rowScan][colScan].label;
                } else {
                  this.slotReservations.otherSlots += ", "+this.reservationMapData[rowScan][colScan].label;
                }
              }
            }
          }
        }
      }

      this.slotReservations.tooltip = true;
    }
  }

  checkIfTimeslotIsSelected(reservation) {
    if (!reservation.timeSlot || this.timeSlots.length === 0){
      return true;
    } else {

      let found = this.timeSlots.find(r => r.timeSlot.id === reservation.timeSlot.id);

      if (found) {
        return true;
      } else {
        return false;
      }
    }
  }

  slotLeave() {
    if (this.startTimestamp === this.endTimestamp &&  this.slotReservations) {
      this.slotReservations.tooltip = false;
    }
  }

  resetMap() {
    this.selectedSlots = [];
    this.resetMapFunctionEmitter.emit()
  }


  async toggleSlot(evt) {
    let selectedSlot = null;
    if (evt.shiftKey) {
      if (this.slot.row >= this.lastSlot.row && this.slot.col >= this.lastSlot.col) {
        const rowDifference = (this.slot.row - this.lastSlot.row) + 1;
        const colDifference = (this.slot.col - this.lastSlot.col) + 1;

        for (let i = 0; i < rowDifference; i++) {
          const selectedRow = this.lastSlot.row + i;

          for (let j = 0; j < colDifference; j++) {
            const selectedCol = this.lastSlot.col + j;

            if (!this.reservationMapData || !this.reservationMapData[selectedRow] || typeof this.reservationMapData[selectedRow][selectedCol] === 'undefined') {

              if (!((selectedRow == this.lastSlot.row && selectedCol == this.lastSlot.col) || (selectedRow == this.slot.row && selectedCol == this.slot.col))) {
                this.grid[selectedRow][selectedCol].selected = !this.grid[selectedRow][selectedCol].selected;

                if (this.grid[selectedRow][selectedCol].selected && this.grid[selectedRow][selectedCol].packagesIds.length > 0 && this.grid[selectedRow][selectedCol].zoneId ) {
                    this.selectedSlots.push(selectedRow + '_' + selectedCol);
                } else {
                  const index = this.selectedSlots.indexOf(selectedRow + '_' + selectedCol);
                  if (index > -1)
                  this.selectedSlots.splice(index, 1);
                }
              }
            }
          }

        }
      }
    }

    //this.resetMapFlag = false;
    //this.resetMapEmitter.emit(false);

    // SE SONO GIA' SELEZIONATI DEGLI SLOTS
    if (this.selectedSlots.length > 0) {

      const rowIndex = this.selectedSlots[0].split('_')[0];
      const colIndex = this.selectedSlots[0].split('_')[1];

      //console.log(this.grid[rowIndex][colIndex], this.slot);

      // SE QUESTI PRESENTANO UNA PRENOTAZIONE OPPURE SE LO SLOT ATTUALMENTE SELEZIONATO PRESENTA UNA PRENOTAZIONE
      if (this.reservationMapData && this.reservationMapData[rowIndex] && this.reservationMapData[rowIndex][colIndex] && this.reservationMapData[rowIndex][colIndex].state != 4) {
        this.resetMap();
      } else if (this.showReservations) {
        this.resetMap();
      } else if (this.grid[rowIndex][colIndex].state === 4 && this.slot.state !== 4) {
        this.resetMap();
      }

    }

    if (this.slotReservations && this.slotReservations.state === 4) {
      this.reserveFlagEmitter.emit(false);
      this.reserveFlag = false;
    }

    if (!this.slot.selected) {
      selectedSlot = this.slot;
      this.selectedSlots.push(this.slot.row + '_' + this.slot.col);
    } else {
      selectedSlot = null;
      const index = this.selectedSlots.indexOf(this.slot.row + '_' + this.slot.col);
      this.selectedSlots.splice(index, 1);
    }


    if (this.reserveFlag && selectedSlot && this.showReservations) {
      if (this.slotReservations.state != 6) {
        this.slotReservations.col = this.slot.col;
        this.slotReservations.row = this.slot.row;
        //await this.openReservationsModal(this.reservationMapData[this.slot.row][this.slot.col]);
        //this.resetMap();
        this.openReservationsModalEmitter.emit(this.reservationMapData[this.slot.row][this.slot.col])

        if (!this.dataService.userData.permissions.all && !this.dataService.userData.permissions.reservations.reserve && !this.dataService.userData.permissions.reservations.create) {
          this.slot.selected = !this.slot.selected;
        }
      }
    } else if (selectedSlot && (this.dataService.userData.permissions.all || this.dataService.userData.permissions.reservations.reserve || this.dataService.userData.permissions.reservations.create)) {

      this.showBottomBarEmitter.emit(true);
    }

    if (this.selectedSlots.length === 0) {
      this.showBottomBarEmitter.emit(false);
      this.resetMap();
    }

    if (this.dataService.userData.permissions.all || this.dataService.userData.permissions.reservations.reserve || this.dataService.userData.permissions.reservations.create) {
      this.slot.selected = !this.slot.selected;
    }

    if (this.showQuickReservation && this.slot.selected) {
      this.showBottomBarEmitter.emit(false);
        this.newReservation.dates[0].order.push({
          selected: false,
          col: parseInt(this.slot.col),
          row: parseInt(this.slot.row),
          type: 'slot',
          coords: this.slot.label,
          zoneKey: this.slot.zoneId,
          slot_key: this.slot.row + '_' + this.slot.col,
          packageKey: parseInt(this.slot.defaultPackId),
          optionalsKeys: {},
        });

        if (this.newReservation.dates[0].order.length === 1) {
          this.newReservation.dates[0].order[0].selected = true;
        }
        this.updateCartEmitter.emit(this.newReservation);

    } else if (this.showQuickReservation && !this.slot.selected) {
      this.showBottomBarEmitter.emit(false);

      this.newReservation.dates[0].order = this.newReservation.dates[0].order.filter( obj => {
        return obj.slot_key !== (this.slot.row + '_' + this.slot.col);
      });

      let flagSelected = false;

      for (let order of this.newReservation.dates[0].order) {
        if (order.selected) {
          flagSelected = true;
          break;
        }
      }

      if (!flagSelected) {
        this.newReservation.dates[0].order[0].selected = true;
      }
      this.updateCartEmitter.emit(this.newReservation);
    }

    if (this.slot.selected) {
      this.lastSlotEmitter.emit(JSON.parse(JSON.stringify(this.slot)));
    }


    this.selectedSlotsEmitter.emit(this.selectedSlots);
    this.selectedSlotEmitter.emit(selectedSlot);

    if (!selectedSlot) {
      this.slot.selected = false;
    }

  }


  getAvailability(packageId,zoneId) {
    let timeslotId = this.selectedTimeslot;
    if (!this.selectedTimeslot) {
      timeslotId = null;
    }
    return this.availabilitiesByPack[packageId+'_'+zoneId+'_'+timeslotId].min;
  }

  openNoSlotReservation(tile) {

    const reservation = this.dataService.initReservation(this.startTimestamp, this.endTimestamp, true);
    const length = (this.endTimestamp - this.startTimestamp) / (24 * 60 * 60 * 1000) + 1;

    const dateInterval = {
      date_key: (this.startTimestamp / 1000) + '_' + (this.endTimestamp / 1000),
      start: this.startTimestamp / 1000,
      end: this.endTimestamp / 1000,
      length,
      intervalAmount: 0,
      optionalsAmount: 0,
      order: [{
        col: null,
        coords: "",
        optionalsKeys: {},
        optionalsPrices: {},
        packageKey: tile.packageId,
        price: 0,
        quantity: 1,
        row: null,
        slot_key: null,
        type: "order",
        zoneKey: tile.zoneId
      }],
      timeSlots: []
    };

    reservation.dates = [];
    reservation.dates.push(dateInterval);

    this.dataService.selectedReservation = reservation;
    this.router.navigate(['/suite/reservation/new']);
  }

}

